import React from 'react';
import classNames from 'classnames';
import { Field } from 'formik';

class MaterialSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: this.props.value,
    };
  }

    handleChange = (e) => {
      this.props.onChange(e.target.value);
    }

    render() {
      const {
        name,
        label,
        placeholder,
        options,
        value,
        error,
        touched,
        locked,
        disabled,
        nullable,
        optional,
      } = this.props;
      const { isFocused } = this.state;
      const classes = classNames({
        'material-input': true,
        'material-input--focused': (isFocused || locked),
        'material-input--error': (touched && error),
        'material-input--disabled': disabled,
        'material-input--optional': optional,
      });
      return (
        <div className={classes}>
          <label className="material-input__label" htmlFor={name}>
            {label}
          </label>
          <Field
            component="select"
            className="material-input__input material-input__input--select"
            id={name}
            placeholder={placeholder}
            name={name}
            onChange={this.handleChange}
            onFocus={() => this.setState({ isFocused: true })}
            onBlur={() => {
              if (!value) {
                this.setState({ isFocused: false });
              }
            }}
            value={value}
          >
            {nullable && (
              <option value="">Aucun</option>
            )}
            {value && value === 'xxxxxxxx' && <option value={value}>Détails masqués</option>}
            {options?.map((option, i) => (
              <option
                key={`option-${i}`}
                value={option.value !== undefined ? option.value : option}
              >
                {option.name !== undefined ? option.name : option}
              </option>
            ))}
          </Field>
          {touched && error && <p className="material-input__error">{error}</p>}
        </div>
      );
    }
}

export default MaterialSelect;
