import React from 'react';

const pve = (props) => (
  <div className="user-pve">
    <h2>{window.i18('TRAVEL_POLICY')}</h2>
    <p dangerouslySetInnerHTML={{ __html: props.pve }} />
  </div>
);

export default pve;
