import React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';

class MaterialInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: this.props.value,
    };
  }

    handleChange = (e) => {
      this.props.onChange(e.target.value);
    }

    render() {
      const {
        name,
        label,
        placeholder,
        type,
        value,
        error,
        touched,
        disabled,
        optional,
        required = false,
        autocomplete,
      } = this.props;
      const { isFocused } = this.state;

      const classes = classNames({
        'material-input': true,
        'material-input--focused': isFocused,
        'material-input--error': (touched && error),
        'material-input--disabled': disabled,
        'material-input--optional': optional,
      });

      return (
        <div
          className={classes}
        >
          <label className="material-input__label" htmlFor={name}>
            {label}
          </label>
          <Field
            data-testid={`${name}-input`}
            className="material-input__input"
            type={type}
            id={name}
            placeholder={placeholder}
            name={name}
            value={value}
            disabled={disabled}
            onChange={this.handleChange}
            onFocus={() => {
              this.setState({ isFocused: true });
            }}
            onBlur={() => {
              if (!value) {
                this.setState({ isFocused: false });
              }
            }}
            required={required}
            autoComplete={autocomplete}
          />
          {touched && error && (
            <p className="material-input__error">{error}</p>
          )}
        </div>
      );
    }
}

export default MaterialInput;
