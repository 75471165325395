import { CLOSE_IMAGE, SET_IMAGE, SET_GALLERY } from './constants';

const initialState = { img: null, imgs: null };

export default function imgViewerReducer(state = initialState, { type, img, imgs }) {
  switch (type) {
    case CLOSE_IMAGE:
      return initialState;
    case SET_IMAGE:
      return {
        img,
        imgs: null,
      };
    case SET_GALLERY:
      return {
        img,
        imgs,
      };
    default:
      return state;
  }
}
