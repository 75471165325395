import { combineReducers } from 'redux';
import {
  SET_USER,
  FETCHING_CARDS,
  SET_CARDS,
  SET_IS_TRAVEL_MANAGER,
  SET_TOKEN,
  SET_AGE_CATEGORIES,
  FETCHING_AGE_CATEGORIES,
  FETCH_TRAVELERS_BEGIN,
  FETCH_TRAVELERS_ERROR,
  FETCH_TRAVELERS_SUCCESS,
  FETCH_COMPANIES_BEGIN,
  FETCH_COMPANIES_ERROR,
  FETCH_COMPANIES_SUCCESS,
  CHANGE_COMPANY,
  FETCH_CO2_STATS_SUCCESS,
  FETCH_TREEPS_STATS_SUCCESS,
  SET_MOBILE_DEVICE,
  SET_HANDICAP_TYPES,
  SET_WHEELCHAIR_TYPES,
  SET_APPROVERS,
  USER_NOT_LOGGED,
  SET_HAS_TRAVELERS_SEARCH,
  ACTIVATE_SANDBOX_MODE,
  SET_SANDBOX_PARAMS,
  SET_LANG,
} from './constants';

function userReducer(state = null, action) {
  switch (action.type) {
    case SET_USER:
      return action.user;
    case CHANGE_COMPANY:
      return {
        ...state,
        company: action.company,
        company_id: action.company.uid,
      };
    case SET_APPROVERS:
      return {
        ...state,
        approvers: action.approvers,
      };
    default:
      return state;
  }
}

function isCheckingUserReducer(state = true, action) {
  switch (action.type) {
    case SET_USER:
    case USER_NOT_LOGGED:
      return false;
    default:
      return state;
  }
}

function hasTravelersSearchReducer(state = null, action) {
  switch (action.type) {
    case SET_HAS_TRAVELERS_SEARCH:
      return action.hasTravelersSearch;
    default:
      return state;
  }
}

function isTravelManagerReducer(state = null, action) {
  switch (action.type) {
    case SET_IS_TRAVEL_MANAGER:
      return action.isTravelManager;
    default:
      return state;
  }
}

function cardsReducer(state = null, action) {
  switch (action.type) {
    case SET_CARDS:
      return action.cards;
    default:
      return state;
  }
}

function handicapsReducer(state = null, action) {
  switch (action.type) {
    case SET_HANDICAP_TYPES:
      return {
        ...state,
        types: action.handicaps,
      };
    case SET_WHEELCHAIR_TYPES:
      return {
        ...state,
        wheelchairs: action.wheelchairs,
      };
    default:
      return state;
  }
}

function ageCategoriesReducer(state = null, action) {
  switch (action.type) {
    case SET_AGE_CATEGORIES:
      return action.ageCategories;
    default:
      return state;
  }
}

function ageCategoriesNamesReducer(state = null, action) {
  switch (action.type) {
    case SET_AGE_CATEGORIES:
      state = new Map();
      action.ageCategories.forEach((ageCat) => {
        state.set(
          ageCat.age_cat,
          ageCat.name.replace(/([a-zA-Z0-9\s]+)\(?.*\)?/g, '$1'),
        ); // Removing age between ()
      });
      return state;
    default:
      return state;
  }
}

function isFetchingAgeCategoriesReducer(state = false, action) {
  switch (action.type) {
    case FETCHING_AGE_CATEGORIES:
      return true;
    case SET_AGE_CATEGORIES:
      return false;
    default:
      return state;
  }
}

function tokenReducer(state = null, action) {
  switch (action.type) {
    case SET_TOKEN:
      return action.token;
    default:
      return state;
  }
}

function isFetchingCardsReducer(state = false, action) {
  switch (action.type) {
    case FETCHING_CARDS:
      return true;
    case SET_CARDS:
      return false;
    default:
      return state;
  }
}

function travelersReducer(state = null, action) {
  switch (action.type) {
    case FETCH_TRAVELERS_BEGIN:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_TRAVELERS_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    case FETCH_TRAVELERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        list: action.travelers,
      };
    default:
      return state;
  }
}

function companiesReducer(state = null, action) {
  switch (action.type) {
    case FETCH_COMPANIES_BEGIN:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        fetching: false,
        list: action.companies,
      };
    case FETCH_COMPANIES_ERROR:
      return {
        ...state,
        fetching: false,
        // error: action.error, we are not passing error anymore here
      };
    default:
      return state;
  }
}

function entitiesReducer(state = [], action) {
  switch (action.type) {
    case CHANGE_COMPANY:
      return action.company && action.company.entities != null ? action.company.entities : [];
    case SET_USER:
      return action.user && action.user.entities != null ? action.user.entities : [];
    default:
      return state;
  }
}

function statsReducer(state = null, action) {
  switch (action.type) {
    case FETCH_CO2_STATS_SUCCESS:
      return {
        ...state,
        co2: action.stats,
      };
    case FETCH_TREEPS_STATS_SUCCESS:
      return {
        ...state,
        treeps: action.stats,
      };
    default:
      return state;
  }
}

function mobileDeviceReducer(state = false, action) {
  switch (action.type) {
    case SET_MOBILE_DEVICE:
      return action.mobileDevice;
    default:
      return state;
  }
}

function sandboxModeReducer(state = false, { type }) {
  switch (type) {
    case ACTIVATE_SANDBOX_MODE:
      return true;
    default:
      return state;
  }
}

function sandboxParamsReducer(state = {}, { type, params }) {
  switch (type) {
    case SET_SANDBOX_PARAMS:
      return params;
    default:
      return state;
  }
}

function langReducer(state = null, { type, lang }) {
  switch (type) {
    case SET_LANG:
      return lang;
    default:
      return state;
  }
}
const appReducer = combineReducers({
  hasTravelersSearch: hasTravelersSearchReducer,
  user: userReducer,
  isCheckingUser: isCheckingUserReducer,
  userPermissions: combineReducers({
    isTravelManager: isTravelManagerReducer,
  }),
  isFetchingCards: isFetchingCardsReducer,
  cards: cardsReducer,
  handicaps: handicapsReducer,
  ageCategories: ageCategoriesReducer,
  ageCategoriesNames: ageCategoriesNamesReducer,
  isFetchingAgeCategories: isFetchingAgeCategoriesReducer,
  token: tokenReducer,
  travelers: travelersReducer,
  companies: companiesReducer,
  entities: entitiesReducer,
  stats: statsReducer,
  isMobileDevice: mobileDeviceReducer,
  sandboxParams: sandboxParamsReducer,
  sandboxMode: sandboxModeReducer,
  lang: langReducer,
});

export default appReducer;
