import React from 'react';
import { Link, Route } from 'react-router-dom';

const headerLink = (props) => {
  // We can pass an 'activeOn' prop to the link, with a regex or an array containings routes on which the link should be active
  // This allows us to have a "Search" link active on both the index (/) and the search page (/search)
  const matchingPaths = props.activeOn ? props.activeOn : props.to;

  return (
    <Route
      path={matchingPaths}
      exact={props.exact}
      // eslint-disable-next-line react/no-children-prop
      children={({ match }) => (
        <Link
          to={props.to}
          className={props.disabled ? 'disabled' : ''}
        >
          <li
            data-testid={`link-to-${props.to}`}
            className={`${match ? 'active ' : ''}header-link`}
          >
            <span
              dangerouslySetInnerHTML={createSvg(props.svg)}
              className="svg-wrapper common-svg inline"
            />
            <span className="link-label">{props.label}</span>
          </li>
        </Link>
      )}
    />
  );
};

function createSvg(svg) {
  return { __html: svg };
}

export default headerLink;
