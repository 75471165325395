import React from 'react';
// import UserForm from "../../../components/UserForm"
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import UserForm from '../../../components/UserForm/new';
import Input from '../../../components/Inputs/Material';
import { signup } from '../../App/actions';

import { formatDateForAPI } from '../../../utils/converters';
import Leaf from '../../../components/GreenScore/Leaf';

class SignupModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      email: '',
    };
  }

  render() {
    if (this.state.success) {
      return (
        <div className="user-modal">
          <div className="modal-inner">
            <section className="modal-header">
              <h3 className="common-sub-title create-account-sucess-title">
                {window.i18('WELCOME')}
                <br />
                {window.i18('AT_THE_TREEP')}
                {' '}
                !
              </h3>
            </section>
            <section className="modal-section big-leaf">
              <Leaf completion={1} />
            </section>
            <section className="modal-section create-account-success-text">
              <span className="common-small-font">
                {window.i18('ACCOUNT_CREATED_VALIDATE_EMAIL')}
                {' '}
                <strong>{this.state.email}</strong>
                .
                {' '}
                {window.i18('SEE_YOU')}
              </span>
            </section>
            <br />
            <a
              className="button-main"
              onClick={() => this.props.closeModal()}
              alt=""
            >
              {window.i18('I_GOT_IT')}
              {' '}
              !
            </a>
          </div>
        </div>
      );
    }

    return (
      <div className="user-modal">
        <div className="modal-with-tagline">
          <header className="tagline">
            <h1 className="common-title">{window.i18('REGISTER')}</h1>
          </header>
        </div>
        <div className="modal-inner">
          <Formik
            initialValues={{
              gender: 'MS',
              firstname: '',
              lastname: '',
              username: '',
              email: '',
              phone: '',
              birthdate: '',
              address: {
                description: '',
              },
              password: '',
              rgpd: false,
            }}
            onSubmit={(values) => {
              values.birthdate = formatDateForAPI(
                values.birthdate,
              );

              return this.props.signup(values).then(() => {
                this.setState({
                  success: true,
                  email: values.email,
                });
              }).catch((err) => {
                this.setState({ error: err.response?.data?.errorMessage || window.i18('ERROR_GENERIC') });
              });
            }}
            validationSchema={() => yup.object().shape({
              gender: yup.string().required(window.i18('PLEASE_FILL')),
              firstname: yup.string().required(window.i18('PLEASE_FILL')),
              lastname: yup.string().required(window.i18('PLEASE_FILL')),
              username: yup.string().required(window.i18('PLEASE_FILL')),
              email: yup
                .string()
                .email()
                .required(window.i18('PLEASE_FILL')),
              phone: yup
                .string()
                .matches(
                  /\b0[0-9]{9}\b/,
                  window.i18('PHONE_NUMBER_FORMAT'),
                )
                .required(window.i18('PLEASE_FILL')),
              birthdate: yup
                .date()
                .required(window.i18('PLEASE_FILL'))
                .min('1900-01-01')
                .max(new Date().toISOString().slice(0, 10)),
              password: yup
                .string()
                .min(8, 'Au moins 8 caractères requis')
                .matches(/^(?=.*[A-Z]).*$/, 'Au moins une majuscule requise')
                .matches(/^(?=.*[0-9]).*$/, 'Au moins un chiffre requis')
                .matches(/^(?=.*[a-z]).*$/, 'Au moins une minuscule requise')
                .required(window.i18('PLEASE_FILL')),
              address: yup.object().shape({
                description: yup.string().required(window.i18('PLEASE_FILL')),
              }),
            })}
          >
            {({
              values,
              touched,
              errors,
              setFieldValue,
            }) => (
              <Form className="signup-form">
                {this.state.error && (
                  <p className="error-message">{this.state.error}</p>
                )}
                <UserForm
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  withPhone
                  hideHandicap
                  withAddress
                  withBirthdate
                  withUsername
                />
                <div className="row">
                  <div className="col-12">
                    <Input
                      onChange={(value) => setFieldValue(
                        'password',
                        value,
                      )}
                      name="password"
                      value={values.password}
                      placeholder="••••••••••"
                      type="password"
                      label={window.i18('PASSWORD')}
                      error={errors.password}
                      touched={touched.password}
                    />
                  </div>
                </div>
                <div className="row rgpd m-top">
                  <div className="col col-12">
                    <label className="" htmlFor="rgpd">
                      <p>
                        {window.i18('I_ACCEPT_MY_DATA_WILL_BE_USED')}
                      </p>
                      <input
                        type="checkbox"
                        className="m-left"
                        name="rgpd"
                        id="rgpd"
                        checked={values.rgpd}
                        onChange={() => setFieldValue(
                          'rgpd',
                          !values.rgpd,
                        )}
                      />
                    </label>
                  </div>
                </div>
                <div className="row m-top">
                  <div className="col col-12">
                    <button
                      type="submit"
                      disabled={!values.rgpd}
                      className="button-main m-auto"
                    >
                      {window.i18('CONFIRM')}
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-12 rgpd">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.thetreep.com/politiqueconfidentialite.html"
                    >
                      {window.i18('CONSULT_PRIVACY_POLICY')}
                    </a>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className="button-group">
            <a
              className="create-account"
              onClick={() => this.props.openModal('Login')}
            >
              {window.i18('LOG_IN')}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  signup: (user) => dispatch(signup(user)),
});

export default connect(
  null,
  mapDispatchToProps,
)(SignupModal);
