import React from 'react';

import moment from 'moment';
import { formatSeconds } from '../../utils/converters';
import Carrier from '../Carrier';
import { capitalize } from '../../utils/functions';

export default function FromToDetails({
  sections, places, carriers, selectedTransportMode,
}) {
  if (!sections) return null;

  return sections.map((section, index) => {
    let transition = null;
    if (index + 1 < sections.length) {
      transition = moment(sections[index + 1].departure).diff(
        section.arrival,
        'seconds',
      );
    }
    const duration = moment.duration(section.duration, 'seconds');

    return (
      <div className="trip-details" key={index}>
        <div className="trip-details__upper">
          <div>
            <p className="trip-details__time">{moment(section.departure).format('HH:mm')}</p>
            <p className="trip-details__location">{places[section.from].name}</p>
          </div>
          <div className="trip-details__operator">
            <span className="m-right">
              {`${duration.hours()}h${duration.minutes() < 10 ? '0' : ''}${duration.minutes()}`}
            </span>
            <Carrier carrier={section.operatingCarrier} carriers={carriers} transport={selectedTransportMode} size="square" />
            <span>
              {section.type === 'long_train' ? `${section.transportModel} ` : `${capitalize(window.i18('FLIGHT'))} n°`}
              {section.transportNumber}
            </span>
          </div>
          <div>
            <p className="trip-details__time">{moment(section.arrival).format('HH:mm')}</p>
            <p className="trip-details__location">{places[section.to].name}</p>
          </div>
        </div>
        {transition && (
          <p className="trip-details__transition">
            {formatSeconds(transition)}
            {' '}
            {window.i18('CONNECTION_TIME')}
          </p>
        )}
      </div>
    );
  });
}
