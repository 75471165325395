import React from 'react';
import { getSvgIcon } from '../../utils/icons';

class CNAMContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="cnam-contact">
        <div className="row">
          <div className="col col-12">
            <h1 className="title">Contact</h1>

            <div className="cnam-contact__image">
              {getSvgIcon('support', 'ui/')}
            </div>
            <div className="cnam-contact__content">
              <p>
                Vous pouvez contacter l'agence de voyage
              </p>

              <div className="cnam-contact__content__elem">
                <button className="button-main" onClick={() => window.$crisp && window.$crisp.push(['do', 'chat:open'])}>par le Tchat</button>
              </div>
              <div className="cnam-contact__content___elem">
                {' '}
                ou par téléphone du lundi au vendredi de 09h00 à 18h30 heure métropole au
                {' '}
                <strong>01 49 92 63 01</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CNAMContact;
