import React from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Redirect } from 'react-router-dom';

import CheckoutForm from './CheckoutForm';
import EnterpriseForm from './EnterpriseForm';
import Error from './Error';
import Success from './Success';
import {
  payTickets, resetCheckout, analyticPageRequired,
} from './actions';
import LeavesLoader from '../../components/Loader/LeavesLoader';
import MissionOrderForm from './MissionOrderForm';

let promise = null;

if (process.env.REACT_APP_STRIPE_API_PUBLIC_KEY) {
  promise = loadStripe(process.env.REACT_APP_STRIPE_API_PUBLIC_KEY);
}

class Checkout extends React.Component {
  componentWillUnmount() {
    this.props.resetCheckout();
  }

  // This books directly the tickets (enterprise payment)
  bookTickets = (fields) => {
    // We pass "null" as a payment token
    this.props.payTickets(null, fields);
  }

  render() {
    const {
      isPaying, amount, requiredFields, optionalFields, paymentMethods, tickets, missionOrders,
    } = this.props.checkout;
    if (!isPaying || !amount || !amount.incl_taxes_amount || amount.incl_taxes_amount === 0) { return <Redirect to="/cart" />; }

    // Payment success
    if (this.props.checkout.success) {
      return (<Success message={this.props.checkout.success.message} status={this.props.checkout.action.status} date={this.props.checkout.success.date} co2={this.props.checkout.success.co2} extraInfo={this.props.checkout.success.extraInfo} sandboxMode={this.props.checkout.success.sandboxMode} />);
    }
    if (this.props.checkout.error) {
      return (<Error errors={this.props.checkout.error} />);
    }

    // During payment processing
    if (this.props.checkout.isProcessing) { return <LeavesLoader sentence={this.props.checkout.action.loadingMessage} />; }

    // If we have a special enterprise payment going on (bypassing the whole CB tunnel)
    // We load the Enterprise form instead.
    // The user can still opt out of this and pay by card, so the rest is still needed.

    if (missionOrders) {
      return <MissionOrderForm missionOrders={missionOrders} />;
    }
    if (analyticPageRequired({ required_fields: requiredFields, optional_fields: optionalFields, payment_methods: paymentMethods })) {
      return <EnterpriseForm confirmTickets={(fields) => this.props.confirmTickets(null, fields)} payTickets={this.bookTickets} price={amount.excl_taxes_amount} />;
    }

    // if (!this.state.stripe) {
    //   return (
    //     <LeavesLoader
    //       sentence="Nous préparons le paiement"
    //     />
    //   );
    // }

    if (!promise) {
      return <p>Stripe n'est pas configuré.</p>;
    }

    return (
      <Elements stripe={promise}>
        <CheckoutForm ticketIDs={tickets} error={this.props.checkout.paymentError} />
      </Elements>
    );
  }
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
  user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
  payTickets: (token, fields) => dispatch(payTickets(token, fields)),
  resetCheckout: () => dispatch(resetCheckout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Checkout);
