import React from 'react';
import classNames from 'classnames';
import { levelToColor } from '../../utils/converters';
import { getSvgIcon, matchTransportIcons } from '../../utils/icons';

export default function TransportSwitcher({ items, selected, setSelected }) {
  return (
    <div className="transport-switcher">
      {items?.map((item) => (
        <button key={item.type} className={classNames('transport-switcher__item', { selected: selected === item.type })} onClick={() => setSelected(item.type)}>
          {getSvgIcon(matchTransportIcons(item.type), 'transport/svg/')}
          <div>
            <p>
              {item.title}
              {' '}
              <span style={{ color: levelToColor(item.co2?.level) }}>
                {item.co2?.text}
              </span>
            </p>
            <p className="transport-switcher__price">
              {window.i18('STARTING_FROM')}
              {' '}
              {item.price?.text}
            </p>
          </div>
        </button>
      ))}
    </div>
  );
}
