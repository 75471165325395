import React from 'react';

// Components
import Input from '../Inputs/Material';
import Select from '../Inputs/MaterialSelect';
import MaterialDate from '../Inputs/MaterialDate';
import countries from '../SelectCountry/ISO-3166';

const options = countries.map((c) => ({
  value: c.value,
  name: c.label,
}));

const LicenseForm = (props) => {
  const {
    values, setFieldValue, errors, touched, withName,
  } = props;
  return (
    <>
      {withName && (
        <div className="row m-bot">
          <div className="col col-6">
            <Input
              onChange={(value) => setFieldValue('firstname', value)}
              name="firstname"
              value={values.firstname}
              placeholder="John"
              type="text"
              label={window.i18('FIRSTNAME')}
              error={errors.firstname}
              touched={touched.firstname}
            />
          </div>
          <div className="col col-6">
            <Input
              onChange={(value) => setFieldValue('lastname', value)}
              name="lastname"
              value={values.lastname}
              placeholder="Snow"
              type="text"
              label={window.i18('LASTNAME')}
              error={errors.lastname}
              touched={touched.lastname}
            />
          </div>
        </div>
      )}
      <div className="row m-bot">
        <Select
          name="country_of_issue"
          value={values.country_of_issue}
          placeholder="France"
          label={window.i18('COUNTRY_OF_ISSUE')}
          onChange={(value) => setFieldValue('country_of_issue', value)}
          error={errors.country_of_issue}
          touched={touched.country_of_issue}
          options={options}
        />
      </div>
      <div className="row m-bot">
        <MaterialDate
          name="effective_date"
          value={values.effective_date}
          placeholder="01/01/1970"
          label={window.i18('EFFECTIVE_DATE')}
          error={errors.effective_date}
          touched={touched.effective_date}
          onChange={(value) => setFieldValue('effective_date', value)}
          withYearSelect
        />
      </div>
      <div className="row m-bot">
        <Input
          onChange={(value) => setFieldValue('number', value)}
          name="number"
          value={values.number}
          placeholder="92121600N7492"
          type="text"
          label={window.i18('NUMBER')}
          error={errors.number}
          touched={touched.number}
        />
      </div>
    </>
  );
};

export default LicenseForm;
