import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TripTicket from '../../components/TripTicket';
import { changeOutwardChoice, changeReturnChoice } from './actions';

export default function Timeline() {
  const dispatch = useDispatch();
  const { results } = useSelector((state) => state);
  const outwardTrip = {};
  outwardTrip.proposal = results.outwardTrip.proposals.find((proposal) => proposal.id === results.outwardTrip.selectedProposal[results.outwardTrip.selectedTransportMode]);
  if (!outwardTrip.proposal) {
    return null;
  }
  outwardTrip.price = outwardTrip.proposal.choices.find((choice) => choice.id === results.outwardTrip.selectedChoice[results.outwardTrip.selectedTransportMode])?.price;

  const returnTrip = {};
  if (results.isRoundTrip && results.returnTrip !== null && results.returnTrip.validated) {
    returnTrip.proposal = results.returnTrip.proposals.find((proposal) => proposal.id === results.returnTrip.selectedProposal[results.returnTrip.selectedTransportMode]);
    returnTrip.price = returnTrip.proposal.choices.find((choice) => choice.id === results.returnTrip.selectedChoice[results.returnTrip.selectedTransportMode])?.price;
  }
  return (
    <>
      <h2>
        {window.i18('MY_TRIP_ON_THE')}
        {' '}
        {moment(results.search.date.a.date).format('DD/MM/YYYY')}
        {results.search.isRT ? ` ${window.i18('TO_THE')} ${moment(results.search.date.r.date).format('DD/MM/YYYY')}` : ''}
      </h2>
      <div className="trip-timeline">
        <TripTicket proposal={outwardTrip.proposal} price={outwardTrip.price} onClick={() => dispatch(changeOutwardChoice())} carriers={results.outwardTrip.carriers} places={results.outwardTrip.places} />
        {Object.keys(returnTrip).length !== 0 && (
          <TripTicket proposal={returnTrip.proposal} price={returnTrip.price} onClick={() => dispatch(changeReturnChoice())} carriers={results.returnTrip.carriers} places={results.returnTrip.places} />
        )}
      </div>
    </>
  );
}
