import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Components
import { v1 as uuidv1 } from 'uuid';

const propTypes = {
  children: PropTypes.node.isRequired,
  btnText: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

export default class FilterContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
    };
    this.id = uuidv1();
  }

  componentDidMount() {
    document.addEventListener('click', (e) => {
      if (e.target.id !== this.id) {
        this.setState({ isOpened: false });
      }
    });
  }

  render() {
    const { children, btnText, active } = this.props;
    const { isOpened } = this.state;
    return (
      <div className="hotel-results-filter">
        <button
          className={classNames('hotel-results-filter__btn', { active, opened: isOpened })}
          onClick={() => this.setState({ isOpened: !isOpened })}
          id={this.id}
        >
          {btnText}
        </button>
        {isOpened
          && (
          <div className="hotel-results-filter__modal" onClick={(e) => { e.nativeEvent.stopImmediatePropagation(); }}>
            {children}
          </div>
          )}
      </div>
    );
  }
}

FilterContainer.propTypes = propTypes;
