import React from 'react';
import moment from 'moment';
import { getSvgIcon } from '../../utils/icons';

export default function SubsetTicket(props) {
  return (
    <>
      <div className="cart-block icon-price">
        <span className="icon">{getSvgIcon(props.icon, 'transport/svg/')}</span>
        <span className="carrier">{props.carrier && props.carrier.name}</span>
        {!(props.type === 'flight' || props.type === 'long_train') && props.linked_subsets_price != null && props.price > 0 && (
        <span className="price">
          <span className="infobulle" aria-label={`${window.i18('PRICE_CAN_CHANGE_OF')} ${props.linked_subsets_price}€ ${window.i18('WILL_STAY_SAME')}`}>
            {' '}
            {`${props.price}€`}
          </span>
        </span>
        )}
        {!(props.type === 'flight' || props.type === 'long_train') && props.linked_subsets_price == null && props.price > 0 && (<span className="price">{`${props.price}€`}</span>)}
        {!(props.type === 'flight' || props.type === 'long_train') && props.priceDetails && props.priceDetails[0] && (
        <span>
          (
          {props.priceDetails}
          )
        </span>
        )}
      </div>
      {((props.type === 'flight' || props.type === 'long_train'))
        ? <PricePerTraveler {...props} />
        : <p className="common-small-font fare-conditions">{props.tickets[0].offer && props.tickets[0].offer.fare_conditions}</p>}
    </>
  );
}

const PricePerTraveler = (props) => (
  <div className="price-per-travelers">
    {props.tickets.map((ticket) => (
      ticket.offer ? <TravelerPrice traveler={ticket.ticket_travelers[0]} price={ticket.price} fare={ticket.offer.fare_requires_card} flex={ticket.offer.fare_category} luggages={ticket.offer.luggages} key={ticket.uid} /> : ''
    ))}
  </div>
);

const TravelerPrice = ({
  traveler, price, flex, luggages,
}) => (
  <div className="traveler">
    <div className="traveler__name">
      <div className="common-tooltip__parent">
        {traveler.firstname}
        {' '}
        {traveler.lastname}
        <TooltipSubset traveler={traveler} />
      </div>
      <div className="fare">
        {traveler.cards ? traveler.cards.map((card) => `${card.cardtype?.name} ${card.cardnumber ? 'n°' : ''}${card.cardnumber ?? ''} `) : `${window.i18('NO_CARDS')} `}
        {window.i18('INTO')}
        {' '}
        {flex}
        {luggages?.length && (
        <div className="trip-offer__luggage common-tooltip__parent">
          {getSvgIcon('loc-baggage', 'ui')}
          {' '}
          x
          {' '}
          {luggages.length}
          <div className="common-tooltip">
            <div className="common-tooltip__content">
              {luggages?.map((l) => (
                <div className="luggage-item">
                  1
                  {' '}
                  {l.type === 'hold' ? window.i18('HOLD_LUGGAGE') : window.i18('CABIN_LUGGAGE')}
                  <ul>
                    {l.conditions.map((condition) => (
                      <li>{condition}</li>
                    ))}
                  </ul>

                </div>
              ))}
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
    <p className="traveler__price">
      {price.toFixed(2)}
      €
    </p>
  </div>
);

export const TooltipSubset = ({ traveler }) => (
  <div className="common-tooltip">
    <div className="common-tooltip__content">
      <p>
        Nom:
        &nbsp;
        <span>
          {traveler.firstname}
          {' '}
          {traveler.lastname}
        </span>
      </p>
      <p>
        {window.i18('BIRTHDATE')}
        :
        &nbsp;
        <span>{moment(traveler.birthdate).format('DD/MM/YYYY')}</span>
      </p>
      <p>
        {window.i18('PHONE')}
        :
        &nbsp;
        <span>{traveler.phone}</span>
      </p>
    </div>
  </div>
);

export function NbNight(depart, arrival, text) {
  depart = moment(depart);
  arrival = moment(arrival);
  const nb = Math.round(moment.duration(arrival.diff(depart)).asDays());
  if (text) {
    if (nb > 1) {
      return `${nb} ${window.i18('NIGHTS')}`;
    }
    return `${nb} ${window.i18('NIGHT')}`;
  }
  return nb;
}
