import React from 'react';
import { getSvgIcon } from '../../utils/icons';

const LuggagesTooltip = ({ luggages }) => (
  <div className="trip-offer__luggage common-tooltip__parent">
    {getSvgIcon('loc-baggage', 'ui')}
    {' '}
    x
    {' '}
    {luggages.length}
    <div className="common-tooltip">
      <div className="common-tooltip__content">
        {luggages?.map((luggage, i) => (
          <div className="luggage-item" key={i}>
            1
            {' '}
            {luggage.type === 'hold' ? window.i18('HOLD_LUGGAGE') : window.i18('CABIN_LUGGAGE')}
            <ul>
              {luggage.conditions?.map((condition, j) => (
                <li key={`condition-${j}`}>{condition}</li>
              ))}
            </ul>

          </div>
        ))}
      </div>
    </div>
  </div>
);

export default LuggagesTooltip;
