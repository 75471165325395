import React from 'react';

const switcher = ({
  first,
  second,
  value,
  handleClick,
  small = false,
  disabled = false,
}) => (
  <div
    className={`switcher ${small ? 'small' : ''} ${disabled
      ? 'disabled'
      : ''}`}
  >
    <button
      type="button"
      onClick={value ? undefined : () => handleClick(1)}
      className={`switcher__item ${value && !disabled ? 'active' : ''}`}
    >
      {first}
    </button>
    <button
      type="button"
      onClick={disabled || value ? () => handleClick(2) : undefined}
      className={`switcher__item ${value || disabled ? '' : 'active'}`}
    >
      {second}
    </button>
  </div>
);

export default switcher;
