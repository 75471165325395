import React from 'react';
import classNames from 'classnames';
import { formatTicketTags } from '../../utils/converters';

export default function TicketTagsTooltip({ tags }) {
  if (!tags || tags.length === 0) {
    return null;
  }

  const isNegociatedFare = tags.indexOf('negociated-fare') > -1;
  const isMissionaryFare = tags.indexOf('ngo-fare') > -1;
  const isCompliant = tags.indexOf('compliant') > -1;
  const isNotCompliant = tags.indexOf('not-compliant') > -1;

  if (!isNegociatedFare && !isCompliant && !isNotCompliant) {
    return null;
  }

  // Make sure the parent element contains this className: "common-tooltip__parent"
  return (
    <div className="common-tooltip common-tooltip--centered common-tooltip--big">
      <div
        className={
            classNames(
              'common-tooltip__content',
              { 'common-tooltip__content--blue': isNegociatedFare || isMissionaryFare },
              { 'common-tooltip__content--green': isCompliant },
              { 'common-tooltip__content--red': isNotCompliant },
            )
          }
      >
        {tags && tags.map((tag, i) => formatTicketTags(tag) && <div key={i}>{formatTicketTags(tag)}</div>)}
      </div>
    </div>
  );
}
