export const SET_SEARCHED_HOTELS = 'hotelResults/SET_SEARCHED_HOTELS';
export const RESET_RESULTS = 'hotelResults/RESET_RESULTS';
export const SEARCH_HOTEL_START = 'hotelResults/SEARCH_HOTEL_START';
export const SEARCH_HOTEL_ERROR = 'hotelResults/SEARCH_HOTEL_ERROR';
export const SEARCH_HOTEL_SUCCESS = 'hotelResults/SEARCH_HOTEL_SUCCESS';
export const SET_SELECTED_RESULT = 'hotelResults/SET_SELECTED_RESULT';
export const SET_SELECTED_DETAILS_BEGIN = 'hotelResults/SET_SELECTED_DETAILS_BEGIN';
export const SET_SELECTED_DETAILS_SUCCESS = 'hotelResults/SET_SELECTED_DETAILS_SUCCESS';
export const SET_SELECTED_DETAILS_ERROR = 'hotelResults/SET_SELECTED_DETAILS_ERROR';
export const SET_STARS = 'hotelResults/SET_STARS';
export const TOGGLE_BREAKFAST = 'hotelResults/TOGGLE_BREAKFAST';
export const TOGGLE_LOYALTY_ENABLED = 'hotelResults/TOGGLE_LOYALTY_ENABLED';
export const SET_PRICE_RANGE = 'hotelResults/SET_PRICE_RANGE';
export const SET_PRICES = 'hotelResults/SET_PRICES';
export const ADD_SELECTED_OFFER = 'hotelResults/ADD_SELECTED_OFFERS';
export const REMOVE_SELECTED_OFFER = 'hotelResults/REMOVE_SELECTED_OFFER';
export const TOGGLE_TRAVELERS_DISTRIBUTION = 'hotelResults/TOGGLE_TRAVELERS_DISTRIBUTION';
export const FILL_TRAVELER = 'hotelResults/FILL_TRAVELER';
export const ADD_TO_CART_BEGIN = 'hotelResults/ADD_TO_CART_BEGIN';
export const ADD_TO_CART_ERROR = 'hotelResults/ADD_TO_CART_ERROR';
export const ADD_TO_CART_SUCCESS = 'hotelResults/ADD_TO_CART_SUCCESS';
export const ASSIGN_TRAVELER_TO_OFFER = 'hotelResults/ASSIGN_TRAVELER_TO_OFFER';
export const TOGGLE_MAP = 'hotelResults/TOGGLE_MAP';
export const SET_SELECTED_MARKER = 'hotelResults/SET_SELECTED_MARKER';
export const TOGGLE_ACCESSIBLE = 'hotelResults/TOGGLE_ACCESSIBLE';
export const SET_SORTING = 'carRentalResults/SET_SORTING';
