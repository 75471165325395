/* eslint-disable global-require */
import React from 'react';

// We are matching a transport name to an icon in - images/icons/transport/svg/
export function matchTransportIcons(input) {
  switch (input) {
    case 'public_transport-walking':
      return 'walking';
    case 'eurostar':
      return 'tgv';
    case 'train':
      return 'tgv';
    case 'long_train':
      return 'tgv';
    case 'thalys':
      return 'tgv';
    case 'walking':
      return 'walking';
    case 'public_transport-urban_skating':
      return 'urban_skating';
    case 'public_transport-bike':
      return 'bike';
    case 'scooter-electrical':
      return 'e_scooter';
    case 'scooter-thermal':
      return 'scooter';
    case 'car-electrical':
      return 'e_car';
    case 'car-hybrid':
      return 'hybrid_car';
    case 'car-thermal':
      return 'car';
    case 'bike-electrical':
      return 'e_bike';
    case 'bike-mechanic':
      return 'bike';
    case 'driver-hybrid':
      return 'hybrid_cab';
    case 'driver-thermal':
      return 'cab';
    case 'pedestrian-urban_skating':
      return 'urban_skating';
    case 'pedestrian-walking':
      return 'walking';
    case 'hybrid_cab':
      return 'hybrid_cab';
    case 'tgv':
      return 'tgv';
    case 'airplane':
      return 'airplane';
    case 'car':
      return 'car';
    case 'cab':
      return 'cab';
    case 'street_network':
      return 'street_network';
    case 'public_transport':
      return 'street_network';
    case 'rail':
      return 'tgv';
    case 'flight':
      return 'airplane';
    case 'flying':
      return 'airplane';
    case 'hotel':
      return 'hotel';
    case 'car_rental':
      return 'car';
    default:
      return 'Bus';
  }
}

export function matchTripResultIcons(subset) {
  // Street network gets an exception (Public Transport)
  switch (subset.type) {
    case 'street_network':
      return 'street_network';
    default:
      return subset.transport_mode.name;
  }
}

// Getting an svg icon
// We return a svg React component
// Only loading the specific asset
export function getSvgIcon(iconName, folder) {
  let asset = null;

  // If no folder is provided, we also return the default-icon
  if (folder == null) {
    asset = require('../../images/icons/transport/svg/default-icon.svg');
    return <asset.ReactComponent />;
  }
  if (folder.substring(folder.length - 1) !== '/') { folder += '/'; }

  try {
    // eslint-disable-next-line import/no-dynamic-require
    asset = require(`../../images/icons/${folder}${iconName}.svg`);
  } catch (err) {
    asset = require('../../images/icons/transport/svg/default-icon.svg');
  }

  return <asset.ReactComponent />;
}
export function getAirlineLogo(airlineLogo) {
  try {
    // eslint-disable-next-line import/no-dynamic-require
    return <img className="svg-footer" src={airlineLogo} alt="airline_logo" />;
  } catch (err) {
    return null;
  }
}

export function getAirlineSquareLogo(squareLogo) {
  try {
    // eslint-disable-next-line import/no-dynamic-require
    return <img className="square-airlines" src={squareLogo} alt="airlines_square_logo" />;
  } catch (err) {
    return null;
  }
}
export function getTrainLogo(trainLogo) {
  try {
    // eslint-disable-next-line import/no-dynamic-require
    return <img className="square-trains" src={trainLogo} alt="trainLogo" />;
  } catch (err) {
    return null;
  }
}
