import React from 'react';
import moment from 'moment';

const displayTimes = (time, i) => (
  <p key={i}>
    {i > 0 ? '-' : ''}
    <span>{moment(time.start, 'HH:mm:ss').format('HH:mm')}</span>

    <span>{moment(time.end, 'HH:mm:ss').format('HH:mm')}</span>
  </p>
);

const Schedule = ({ place }) => (
  <table>
    <thead>
      <tr>
        <td colSpan="7" className="title">{window.i18('OPENING_SCHEDULE')}</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th><p>{window.i18('MON')}</p></th>
        <th><p>{window.i18('TUE')}</p></th>
        <th><p>{window.i18('WED')}</p></th>
        <th><p>{window.i18('THU')}</p></th>
        <th><p>{window.i18('FRI')}</p></th>
        <th><p>{window.i18('SAT')}</p></th>
        <th><p>{window.i18('SUN')}</p></th>
      </tr>
      <tr>
        <td>
          {place.opening_periods.monday.map(displayTimes)}
        </td>
        <td>
          {place.opening_periods.tuesday.map(displayTimes)}
        </td>
        <td>
          {place.opening_periods.wednesday.map(displayTimes)}
        </td>
        <td>
          {place.opening_periods.thursday.map(displayTimes)}
        </td>
        <td>
          {place.opening_periods.friday.map(displayTimes)}
        </td>
        <td>
          {place.opening_periods.saturday.map(displayTimes)}
        </td>
        <td>
          {place.opening_periods.sunday.map(displayTimes)}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Schedule;
