import React, { useState } from 'react';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { sendLogin } from '../../App/actions';

import Input from '../../../components/Inputs/Material';
import api from '../../../utils/api';

function LoginModal({ closeModal, openModal, shake }) {
  const { options } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [username, setUsername] = useState(null);

  const handleSubmit = (password) => dispatch(sendLogin({ username, password })).then(() => {
    closeModal();
  });

  return (
    <div data-testid="login-modal" className="user-modal">
      <div className="modal-with-tagline">
        <header className="tagline">
          <h1 className="common-title">{window.i18('LOG_YOURSELF_IN')}</h1>
        </header>
      </div>
      <div className="modal-inner">
        <div className="user-form">
          {!username && <Username setUsername={setUsername} shake={shake} />}
          {username && (
            <>
              <div className="row">
                <p className="m-auto">{username}</p>
              </div>
              <div className="row m-top">
                <div className="col col-12">
                  <Password onSubmit={handleSubmit} username={username} shake={shake} />
                </div>
              </div>
            </>
          )}
          <div className="button-group">
            {username && (
              <>
                <a className="lost-password" onClick={() => openModal('ResetPassword', { ...options, username })}>
                  {window.i18('FORGOTTEN_PASSWORD')}
                  {' '}
                  ?
                </a>
                <a className="create-account" onClick={() => setUsername(null)}>{window.i18('UPDATE_USER')}</a>
              </>
            )}

            {!username && <a className="create-account" onClick={() => openModal('Signup', options)}>{window.i18('CREATE_ACCOUNT')}</a>}
          </div>
        </div>
      </div>
    </div>
  );
}

const Username = ({ shake, setUsername }) => {
  const logUser = (username) => api.get(`/user/auth-strategy/${encodeURIComponent(username)}`);
  return (
    <Formik
      initialValues={{
        username: '',
      }}
      validationSchema={yup.object().shape({
        username: yup.string().required(window.i18('PLEASE_FILL')),
      })}
      onSubmit={({ username }, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        const usr = encodeURIComponent(username);
        logUser(usr).then((res) => {
          const actions = {
            password: () => setUsername(username),
            sso: () => window.location.replace(res.data.result.redirectURL),
          };
          const next = actions[res?.data?.result?.strategy] ?? actions.password;
          next();
        }).catch(() => {
          setSubmitting(false);
          shake();
          setErrors({ username: window.i18('USERNAME_NOT_RECOGNIZED') });
        });
      }}
    >
      {({
        setFieldValue, values, isSubmitting, errors, touched,
      }) => (
        <Form>
          <div className="row m-bot">
            <Input
              name="username"
              error={errors.username}
              touched={touched.username}
              value={values.username}
              label={window.i18('ENTER_YOUR_USERNAME')}
              onChange={(value) => setFieldValue('username', value)}
              disabled={isSubmitting}
            />
          </div>
          <button data-testid="login-modal-username-button" className="button-main m-top" disabled={isSubmitting}>{window.i18('NEXT')}</button>
        </Form>
      )}
    </Formik>
  );
};

const Password = ({ onSubmit, username, shake }) => (
  <Formik
    initialValues={{
      password: '',
    }}
    onSubmit={(values, { setErrors, setSubmitting }) => {
      onSubmit(values.password).catch(() => {
        shake();
        setErrors({ password: 'Mauvais mot de passe.' });
        setSubmitting(false);
      });
    }}
  >
    {({
      setFieldValue, values, isSubmitting, errors, touched,
    }) => (
      <Form>
        <div className="row m-bot">
          <input name="username" value={username} type="username" style={{ display: 'none' }} />
          <Input
            name="password"
            error={errors.password}
            touched={touched.password}
            value={values.password}
            label={window.i18('PASSWORD')}
            onChange={(value) => setFieldValue('password', value)}
            disabled={isSubmitting}
            type="password"
            autocomplete="current-password"
          />
        </div>
        <button data-testid="login-modal-password-button" className="button-main m-top" disabled={isSubmitting}>{window.i18('LOG_IN')}</button>
      </Form>
    )}
  </Formik>
);

export default LoginModal;
