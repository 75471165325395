import React from 'react';
import PropTypes from 'prop-types';
// Components
import FilterContainer from './FilterContainer';
import PriceRange from '../../../../components/PriceRange';

const propTypes = {
  priceRange: PropTypes.shape({}).isRequired,
  setPriceRange: PropTypes.func.isRequired,
  prices: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
};

export default class PriceFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      min: props.priceRange.min,
      max: props.priceRange.max,
    };
    this.minForm = React.createRef();
    this.maxForm = React.createRef();
    this.minInput = React.createRef();
    this.maxInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.priceRange !== this.props.priceRange) {
      this.setState({
        min: this.props.priceRange.min,
        max: this.props.priceRange.max,
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { min, max } = this.state;
    this.props.setPriceRange(min, max);
  }

  handleMinBlur = () => {
    if (this.minInput.current.checkValidity()) {
      this.minForm.current.dispatchEvent(new Event('submit'));
    } else {
      this.minInput.current.value = this.props.priceRange.min;
    }
  }

  handleMaxBlur = () => {
    if (this.maxInput.current.checkValidity()) {
      this.maxForm.current.dispatchEvent(new Event('submit'));
    } else {
      this.maxInput.current.value = this.props.priceRange.max;
    }
  }

  render() {
    const { priceRange, setPriceRange, prices } = this.props;
    const { min, max } = this.state;

    return (
      <FilterContainer btnText={`${window.i18('BETWEEN')} ${priceRange.min} ${window.i18('AND')} ${priceRange.max}€/${window.i18('DAY')}`} active={priceRange.max !== prices.max || priceRange.min !== prices.min}>
        <>
          <p>{window.i18('PRICE_RANGE')}</p>
          <PriceRange onFinalChange={setPriceRange} onChange={(min, max) => this.setState({ min, max })} priceRange={priceRange} prices={prices} />
          <div className="manuel-price-range">
            <form onSubmit={this.onSubmit} ref={this.minForm}>
              <input
                ref={this.minInput}
                type="number"
                onChange={({ target: { value } }) => !Number.isNaN(value) && this.setState({ min: parseInt(value, 10) })}
                min={prices.min}
                max={max}
                value={min}
                onBlur={this.handleMinBlur}
              />
            </form>
            <div className="price-filter-dash">-</div>
            <form onSubmit={this.onSubmit} ref={this.maxForm}>
              <input
                ref={this.maxInput}
                type="number"
                onChange={({ target: { value } }) => !Number.isNaN(value) && this.setState({ max: parseInt(value, 10) })}
                min={min}
                max={prices.max}
                value={max}
                onBlur={this.handleMaxBlur}
              />
            </form>
          </div>
        </>
      </FilterContainer>
    );
  }
}

PriceFilter.propTypes = propTypes;
