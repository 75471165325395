import * as yup from 'yup';

export default function dataToYupSchema(data) {
  return yup.object().shape(data.reduce((obj, item) => {
    const { travelerID } = item;
    const fields = item.fieldsGroups.map((g) => g.fields).flat();
    const items = fields.reduce((obj, field) => {
      let schema = yup.string();
      if (field.regex) {
        schema = schema.matches(new RegExp(field.regex), field.regexDesc);
      }
      if (field.required) {
        schema = schema.required('Ce champ est requis');
      }
      return ({ ...obj, [field.name]: schema });
    }, {});
    return {
      ...obj,
      [travelerID]: yup.object().shape(items),
    };
  }, {}));
}
