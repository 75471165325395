import React from 'react';
import { FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
// Components
import DocumentForm from '../DocumentForm';
// Functions
import { getSvgIcon } from '../../utils/icons';
import { formatDateForAPI, formatDocumentType } from '../../utils/converters';

class DocumentsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: null,
    };
  }

  render() {
    const {
      documents, values, userDocuments, isMobileDevice,
    } = this.props;

    const addDocument = (values, arrayHelpers) => {
      if (values != null) {
        values.expiration_date = formatDateForAPI(values.expiration_date);
        values.effective_date = formatDateForAPI(values.effective_date);
      }
      if (this.state.editing === 'new') {
        arrayHelpers.push(values);
      } else {
        arrayHelpers.replace(this.state.editing, values);
      }
      this.setState({ editing: null });
    };

    const removeDocument = (index, arrayHelpers) => {
      arrayHelpers.remove(index);
    };

    return (
      <FieldArray
        name="documents"
        render={(arrayHelpers) => (
          <>
            {documents && documents.map((doc, i) => {
              if (this.state.editing === i) {
                return null;
              }
              return (
                <div className="row" data-testid={`document-${doc.document_name}`} key={`document-${i}`}>
                  <div data-testid={`document-${doc.document_name}-name`} className="col col-sm-8 col-md-4">
                    <p>{doc.document_name ? doc.document_name : formatDocumentType(doc.type)}</p>
                  </div>
                  {!isMobileDevice && (
                  <div data-testid={`document-${doc.document_name}-name`} className="col col-md-4">
                    <p>{doc.number}</p>
                  </div>
                  )}
                  {!isMobileDevice && (
                  <div data-testid={`document-${doc.document_name}-expiration-date`} className="col col-md-2">
                    <p>{doc.expiration_date ? moment(doc.expiration_date).format('DD/MM/YYYY') : '/'}</p>
                  </div>
                  )}
                  <div className="col col-sm-2 col-md-1">
                    <button data-testid={`document-${doc.document_name}-edit-button`} className="button-icon" type="button" onClick={() => this.setState({ editing: i })}>
                      {getSvgIcon('edit', 'ui/')}
                    </button>
                  </div>
                  <div className="col col-sm-2 col-md-1">
                    <button data-testid={`document-${doc.document_name}-delete-button`} className="button-icon" type="button" onClick={() => removeDocument(i, arrayHelpers)}>
                      {getSvgIcon('dustbin', 'ui/')}
                    </button>
                  </div>
                </div>
              );
            })}
            {userDocuments && documents.length === 0 && !this.state.editing && <p className="user-documents__none">{window.i18('NO_DOCUMENTS')}</p>}
            {this.state.editing !== null && (
              <Formik
                initialValues={this.state.editing === 'new' ? {
                  type: '',
                  civility: values.civility,
                  firstname: values.firstname,
                  lastname: values.lastname,
                  nationality: '',
                  document_name: '',
                  effective_date: '',
                  expiration_date: '',
                  number: '',
                  country_of_issue: '',
                } : documents[this.state.editing]}
                validationSchema={yup.object().shape({
                  type: yup.string().required(window.i18('PLEASE_FILL')),
                  civility: yup.string().required(window.i18('PLEASE_FILL')),
                  firstname: yup.string().required(window.i18('PLEASE_FILL')),
                  lastname: yup.string().required(window.i18('PLEASE_FILL')),
                  nationality: yup.string().required(window.i18('PLEASE_FILL')),
                  document_name: yup.string(),
                  effective_date: yup
                    .date()
                    .required(window.i18('PLEASE_FILL'))
                    .typeError(window.i18('PLEASE_FILL'))
                    .max(new Date().toISOString(), window.i18('ENTER_PASSED_DATE')),
                  expiration_date: yup
                    .date()
                    .min(new Date().toISOString(), window.i18('ENTER_FUTURE_DATE'))
                    .when('type', {
                      is: 'driver_license',
                      then: yup.date(),
                      otherwise: yup.date().required(),
                    })
                    .typeError(window.i18('ENTER_A_DATE')),
                  number: yup.string().required(window.i18('PLEASE_FILL')),
                  country_of_issue: yup.string().required(window.i18('PLEASE_FILL')),
                })}
                onSubmit={(values) => addDocument(values, arrayHelpers)}
              >
                {({
                  values, touched, errors, setFieldValue, handleSubmit,
                }) => (
                  <div className="bordered-block m-top p-all">
                    <DocumentForm values={values} touched={touched} errors={errors} setFieldValue={setFieldValue} />
                    <div className="row m-top">
                      <div className="col col-6">
                        <button className="button-secondary" type="button" onClick={() => this.setState({ editing: null })}>{window.i18('CANCEL')}</button>
                      </div>
                      <div className="col col-6">
                        <button className="button-main" type="button" onClick={handleSubmit}>{window.i18('CONFIRM')}</button>
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            )}
            <div className="row m-top">
              <button className="button-secondary" type="button" disabled={this.state.editing} onClick={() => this.setState({ editing: 'new' })}>{window.i18('ADD_DOCUMENT')}</button>
            </div>
          </>
        )}
      />

    );
  }
}

export default DocumentsForm;
