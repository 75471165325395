import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { connect } from 'react-redux';
import * as yup from 'yup';
import moment from 'moment';

import UserForm from '../../../components/UserForm/new';
import MaterialDate from '../../../components/Inputs/MaterialDate';
import Checkbox from '../../../components/Checkbox';
import CardForm from '../../../components/ModalCardForm';

import { addPassenger as addTripPassenger, editPassenger as editTripPassenger } from '../../TripSearch/actions';
import { addPassenger as addHotelPassenger, editPassenger as editHotelPassenger } from '../../HotelSearch/actions';
import { formatDateForAPI } from '../../../utils/converters';
import { validationSchemaBirthdateOrHidden } from '../../../utils/functions';

class PassengerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addingCard: false,
      edittingCard: null,
      isSimple: this.props.passenger.isSimple,
      dataAccuracy: false,
    };
  }

  toggleForm = () => {
    this.setState({
      addingCard: !this.state.addingCard,
      edittingCard: null,
    });
  }

  editCard = (index) => {
    this.setState({
      edittingCard: index,
      addingCard: true,
    });
  }

  toggleDataAccuracy = () => this.setState({ dataAccuracy: !this.state.dataAccuracy })

  render() {
    const {
      passenger,
      cardsList,
      closeModal,
      addTripPassenger,
      editTripPassenger,
      addHotelPassenger,
      editHotelPassenger,
      handicaps,
      isHotel,
    } = this.props;
    let birthdate = '';
    if (passenger.birthdate) {
      if (passenger.birthdate === 'xx/xx/xxxx') {
        birthdate = passenger.birthdate;
      } else {
        birthdate = moment(passenger.birthdate).toDate();
      }
    }
    return (
      <div className="passenger-profile">
        <div className="modal-with-tagline">
          <header className="tagline">
            <h1 className="common-title">
              {passenger ? window.i18('UPDATE_PASSENGER') : window.i18('ADD_PASSENGER')}
            </h1>
          </header>
        </div>
        <Formik
          initialValues={passenger
            ? {
              gender: '',
              firstname: '',
              lastname: '',
              phone: '',
              email: '',
              uid: '',
              ...passenger,
              birthdate,
            }
            : {
              gender: '',
              firstname: '',
              lastname: '',
              phone: '',
              birthdate: '',
              email: '',
            }}
          validationSchema={
            this.state.isSimple
              ? yup.object().shape({
                birthdate: validationSchemaBirthdateOrHidden(),
              })
              : yup.object().shape({
                gender: yup.string().required(window.i18('PLEASE_FILL')),
                firstname: yup.string().required(window.i18('PLEASE_FILL')),
                lastname: yup.string().required(window.i18('PLEASE_FILL')),
                email: yup
                  .string()
                  .email()
                  .required(window.i18('PLEASE_FILL')),
                phone: isHotel
                  ? yup
                    .string()
                    .matches(
                      /\b0[0-9]{9}\b|xxxxxxxx/,
                      window.i18('PHONE_NUMBER_FORMAT'),
                    )
                    .required(window.i18('PLEASE_FILL'))
                  : yup.string(),
                birthdate: validationSchemaBirthdateOrHidden(),
              })
          }
          onSubmit={(values) => {
            if (values.birthdate && values.birthdate !== 'xx/xx/xxxx') {
              values.birthdate = formatDateForAPI(`${moment(values.birthdate).format('YYYY-MM-DD')}T120000`);
            }
            values.isSimple = this.state.isSimple;
            if (passenger) {
              if (isHotel) {
                editHotelPassenger({
                  ...values,
                });
              } else {
                editTripPassenger({
                  ...values,
                });
              }
              closeModal();
            } else {
              if (isHotel) {
                addHotelPassenger({
                  ...values,
                });
              } else {
                addTripPassenger({
                  ...values,
                });
              }
              closeModal();
            }
          }}
          validateOnChange
        >
          {({
            values,
            setFieldValue,
            errors,
            setFieldTouched,
            touched,
          }) => {
            if (this.state.addingCard) {
              return (
                <FieldArray
                  name="cards"
                  render={(arrayHelpers) => (
                    <CardForm
                      toggleForm={this.toggleForm}
                      cardsList={this.props.cardsList}
                      index={this.state.edittingCard}
                      arrayHelpers={arrayHelpers}
                      cards={values.cards}
                    />
                  )}
                />
              );
            }
            return (
              <Form className="passenger-profile__body">
                {this.state.isSimple ? (
                  <Simple
                    errors={errors}
                    values={values}
                    cardsList={cardsList}
                    editCard={this.editCard}
                    toggleForm={this.toggleForm}
                    setFieldValue={setFieldValue}
                    touched={touched}
                  />
                ) : (
                  <Complete
                    errors={errors}
                    values={values}
                    cardsList={cardsList}
                    editCard={this.editCard}
                    toggleForm={this.toggleForm}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    handicaps={handicaps}
                    isHotel={isHotel}
                    dataAccuracy={this.state.dataAccuracy}
                    toggleDataAccuracy={this.toggleDataAccuracy}
                  />
                )}
                {
                  this.state.isSimple
                  && (
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({
                          isSimple: !this.state.isSimple,
                        });
                        if (!this.state.isSimple) {
                          setFieldTouched('birthdate', false);
                        }
                      }}
                      className="button-tertiary"
                    >
                      {window.i18('CREATE_COMPLETE_PROFILE')}
                    </button>
                  )
                }
                <div className="row passenger-profile__footer">
                  <div className="col col-12">
                    <button type="submit" className="button-main m-auto" disabled={!this.state.dataAccuracy && !this.state.isSimple}>
                      {window.i18('CONFIRM')}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const Complete = ({
  errors,
  values,
  cardsList,
  editCard,
  toggleForm,
  setFieldValue,
  touched,
  dataAccuracy,
  toggleDataAccuracy,
  handicaps,
}) => (
  <>
    <UserForm
      values={values}
      errors={errors}
      setFieldValue={setFieldValue}
      touched={touched}
      // withPhone={isHotel}
      withPhone
      withBirthdate
      withHandicap
      handicaps={handicaps}
    />
    <div className="row m-top m-bot">
      <label className="common-title" htmlFor="card">
        {window.i18('LOYALTY_AND_SUBSCRIBTION_CARDS')}
      </label>
    </div>
    <FieldArray
      name="cards"
      render={(arrayHelpers) => (
        <ul className="cards-list row m-bot">
          {values.cards
            && values.cards.map((card, index) => (
              <li key={`card-${index}`} className="card">
                <button onClick={() => editCard(index)}>
                  {cardsList && cardsList.find((c) => c.uid === card.cardtype_id)?.name}
                </button>
                <button
                  className="cards-cancel"
                  type="button"
                  onClick={() => arrayHelpers.remove(index)}
                >
                  <span className="common-cross" />
                </button>
              </li>
            ))}
          <li className="card-add">
            <button type="button" onClick={toggleForm}>
              {' '}
              +
              {' '}
              {window.i18('ADD_CARD')}
            </button>
          </li>
        </ul>
      )}
    />
    <div className="row">
      <Checkbox
        small
        checked={dataAccuracy}
        onChange={toggleDataAccuracy}
        value={window.i18('AGGREE_FORM_RESPONSABILITY')}
      />
    </div>
  </>
);

const Simple = ({
  toggleForm,
  cardsList,
  values,
  editCard,
  setFieldValue,
  errors,
  touched,
}) => (
  <>
    <div className="row m-bot">
      <div className="col col-6">
        <MaterialDate
          name="birthdate"
          value={values.birthdate}
          placeholder="01/01/2021"
          label={window.i18('BIRTHDATE')}
          error={errors.birthdate}
          touched={touched.birthdate}
          onChange={(value) => setFieldValue('birthdate', value)}
          withYearSelect
        />
      </div>
      <div className="col col-6 justify-end">
        <p className="common-small-font italic">
          {window.i18('CAN_INFLUENCE_PRICE')}
        </p>
      </div>
    </div>
    <div className="row m-bot">
      <label className="common-title" htmlFor="card">
        {window.i18('LOYALTY_AND_SUBSCRIBTION_CARDS')}
      </label>
    </div>
    <FieldArray
      name="cards"
      render={(arrayHelpers) => (
        <ul className="cards-list row m-bot">
          {values.cards
            && values.cards.map((card, index) => (
              <li key={`card-${index}`} className="card">
                <button onClick={() => editCard(index)}>
                  {cardsList.find((c) => c.uid === card.cardtype_id)?.name}
                </button>
                <button
                  className="cards-cancel"
                  type="button"
                  onClick={() => arrayHelpers.remove(index)}
                >
                  <span className="common-cross" />
                </button>
              </li>
            ))}
          <li className="card-add">
            <button type="button" onClick={toggleForm}>
              {' '}
              +
              {' '}
              {window.i18('ADD_CARD')}
            </button>
          </li>
        </ul>
      )}
    />
  </>
);

const mapStateToProps = (state) => ({
  cardsList: state.app.cards,
  handicaps: state.app.handicaps,
});

const mapDispatchToProps = (dispatch) => ({
  addTripPassenger: (passenger) => dispatch(addTripPassenger(passenger)),
  editTripPassenger: (passenger) => dispatch(editTripPassenger(passenger)),
  addHotelPassenger: (passenger) => dispatch(addHotelPassenger(passenger)),
  editHotelPassenger: (passenger) => dispatch(editHotelPassenger(passenger)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PassengerModal);
