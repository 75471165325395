import React from 'react';
import { getSvgIcon } from '../../../utils/icons';

function CNAMContact() {
  return (
    <div className="modal-inner contact-modal">
      <section className="modal-header">
        <h3 className="common-sub-title">Contact</h3>
      </section>
      <div className="modal-content">
        <div className="cnam-contact__image">
          {getSvgIcon('support', 'ui/')}
        </div>
        <div className="cnam-contact">
          <div className="row">
            <div className="col col-12">
              <div className="cnam-contact">
                <p>
                  <strong>Pensez à consulter régulièrement votre espace personnel pour connaître l’état de vos réservations (rubrique « Mon espace ») et vérifier vos transports. </strong>
                </p>
                <div className="cnam-contact__content__elem" />
                <p>
                  <strong> Si vous ne trouvez pas votre réponse dans notre rubrique « Aide », vous pouvez contacter l'agence de voyage:</strong>
                </p>
                <div className="cnam-contact__content___elem">
                  <ul className="info-contact-cnam">
                    <li>
                      En envoyant un email à
                      {' '}
                      <a className="strong-info" href="mailto:helpdesk@vaironvoyages.com">helpdesk@vaironvoyages.com</a>
                    </li>
                    <li>
                      Par téléphone : appelez un agent de voyage dédié à l’Assurance maladie, à votre écoute du lundi au vendredi de 9:00 à 18:30 :
                      {' '}
                      <a className="strong-info" href="tel:0149926301">01 49 92 63 01</a>
                    </li>
                    <li>
                      En contactant un agent de voyage directement via le
                      {' '}
                      <button className="button-link p-0" onClick={() => window.$crisp && window.$crisp.push(['do', 'chat:open'])}>Tchat</button>
                      {' '}
                      du site
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CNAMContact;
