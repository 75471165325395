import React from 'react';
import PropTypes from 'prop-types';
import { getSvgIcon } from '../../../utils/icons';
import { formatStatus } from '../../../utils/converters';

const propTypes = {
  toggleFilter: PropTypes.func.isRequired,
  filter: PropTypes.bool.isRequired,
  setFilterKeyword: PropTypes.func.isRequired,
  status: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Filter = (props) => (
  <div className="user-tickets-filter">
    <button
      className={`user-tickets-filter__toggle common-sub-title ${
        props.filter ? 'on' : 'off'
      }`}
      onClick={props.toggleFilter}
    >
      {getSvgIcon('filter', 'ui/')}
      {window.i18('FILTER')}
    </button>
    <div
      className={`user-tickets-filter__container ${
        props.filter ? 'show' : 'hide'
      }`}
    >
      <div className="user-tickets-filter__item">
        <label className="user-tickets-filter__label" htmlFor="search_name">
          {window.i18('BY_NAME')}
          :
        </label>
        <input
          type="search"
          name="search_name"
          id="search_name"
          className="user-tickets-filter__search"
          placeholder="Jean"
          onChange={(e) => props.setFilterKeyword(e.target.value, 'name')}
        />
      </div>
      <div className="user-tickets-filter__item">
        <label className="user-tickets-filter__label" htmlFor="search_place">
          {window.i18('BY_PLACE')}
          :
        </label>
        <input
          type="search"
          name="search_place"
          id="search_place"
          className="user-tickets-filter__search"
          placeholder="Lyon"
          onChange={(e) => props.setFilterKeyword(e.target.value, 'place')}
        />
      </div>
      <div className="user-tickets-filter__item">
        <label className="user-tickets-filter__label" htmlFor="search_date">
          {window.i18('BY_DATE')}
          :
        </label>
        <input
          type="date"
          name="search_date"
          id="search_date"
          className="user-tickets-filter__search"
          placeholder="Lyon"
          onChange={(e) => props.setFilterKeyword(e.target.value, 'date')}
        />
      </div>
      <div className="user-tickets-filter__item">
        <label className="user-tickets-filter__label" htmlFor="search_status">
          {window.i18('BY_STATUS')}
          :
        </label>
        <select
          name="search_status"
          id="search_status"
          className="user-tickets-filter__search"
          onChange={(e) => props.setFilterKeyword(e.target.value, 'status')}
        >
          <option value="">{window.i18('ALL')}</option>
          {props.status && props.status.map((status, i) => {
            const content = formatStatus(status).name;
            if (status === 'cart') return;
            return (
              <option key={`option-${i}`} value={status}>
                {content}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  </div>
);

Filter.propTypes = propTypes;

export default Filter;
