export const searchSvg = `<svg class="line" width='44' height='44' viewBox='0 0 44 44' xmlns='http://www.w3.org/2000/svg'>
<g id='44px-Line' fill='none' fillRule='evenodd'>
    <path d='M30.077,0 C22.4,0 16.154,6.246 16.154,13.923 C16.154,16.573 16.911,19.043 18.201,21.154 L16.418,22.937 C13.996,22.19 11.25,22.77 9.337,24.683 L2.068,31.953 C0.735,33.286 0.001,35.057 0.001,36.943 C0.001,38.829 0.735,40.6 2.068,41.933 C3.401,43.266 5.173,44 7.059,44 C8.944,44 10.716,43.266 12.049,41.933 L19.318,34.663 C20.651,33.33 21.385,31.559 21.385,29.673 C21.385,28.951 21.276,28.247 21.068,27.577 L22.846,25.799 C24.957,27.09 27.427,27.847 30.077,27.847 C37.754,27.847 44,21.601 44,13.923 C44,6.246 37.754,0 30.077,0 L30.077,0 Z M17.903,33.249 L10.634,40.519 C9.678,41.474 8.408,42 7.058,42 C5.707,42 4.437,41.474 3.481,40.519 C2.526,39.564 2,38.293 2,36.943 C2,35.593 2.526,34.322 3.481,33.367 L10.75,26.097 C11.736,25.112 13.031,24.618 14.326,24.618 C15.621,24.618 16.917,25.111 17.903,26.097 C18.858,27.052 19.384,28.323 19.384,29.673 C19.384,31.023 18.858,32.294 17.903,33.249 L17.903,33.249 Z M20.141,25.676 C19.899,25.325 19.626,24.991 19.318,24.683 C19.007,24.372 18.672,24.102 18.323,23.862 L19.372,22.813 C19.921,23.473 20.529,24.081 21.189,24.629 L20.141,25.676 L20.141,25.676 Z M30.077,25.847 C23.503,25.847 18.154,20.498 18.154,13.923 C18.154,7.349 23.503,2 30.077,2 C36.652,2 42,7.349 42,13.923 C42,20.498 36.651,25.847 30.077,25.847 L30.077,25.847 Z'
    id='Shape' fill='#000' />
    <path d='M30,4 C24.486,4 20,8.486 20,14 C20,14.553 20.448,15 21,15 C21.552,15 22,14.553 22,14 C22,9.589 25.589,6 30,6 C30.552,6 31,5.553 31,5 C31,4.447 30.552,4 30,4 L30,4 Z'
    id='Shape' fill='#000' />
</g>
</svg>
<svg class="filled" width='44' height='44' viewBox='0 0 44 44' xmlns='http://www.w3.org/2000/svg'>
    <g id='44px-Glyph' fill='none' fillRule='evenodd'>
        <g id='des-magnify-fat' fill='#000'>
            <path d='M30.0002,6.001 C25.5892,6.001 22.0002,9.59 22.0002,14.001 C22.0002,14.554 21.5532,15.001 21.0002,15.001 C20.4472,15.001 20.0002,14.554 20.0002,14.001 C20.0002,8.487 24.4862,4.001 30.0002,4.001 C30.5532,4.001 31.0002,4.448 31.0002,5.001 C31.0002,5.554 30.5532,6.001 30.0002,6.001 M30.0772,0.001 C22.4002,0.001 16.1542,6.247 16.1542,13.924 C16.1542,16.573 16.9122,19.043 18.2022,21.154 L17.9672,21.39 C18.9702,21.829 19.9122,22.45 20.7312,23.27 C21.5512,24.089 22.1722,25.032 22.6122,26.035 L22.8472,25.799 C24.9582,27.089 27.4282,27.847 30.0772,27.847 C37.7542,27.847 44.0002,21.601 44.0002,13.924 C44.0002,6.247 37.7542,0.001 30.0772,0.001'
            id='Fill-29' />
            <path d='M7.0578,44.001 C5.1718,44.001 3.4008,43.267 2.0678,41.934 C0.7348,40.601 -0.0002,38.829 -0.0002,36.943 C-0.0002,35.058 0.7348,33.286 2.0678,31.953 L9.3368,24.684 C12.0888,21.932 16.5658,21.932 19.3178,24.684 C22.0698,27.436 22.0698,31.912 19.3178,34.664 L12.0478,41.934 C10.7148,43.267 8.9438,44.001 7.0578,44.001'
            id='Fill-31' />
        </g>
    </g>
</svg>`;

export const cartSvg = `<svg class="line" width='44' height='44' viewBox='0 0 44 44' xmlns='http://www.w3.org/2000/svg'>
<g id='44px-Line' fill='none' fillRule='evenodd'>
    <path d='M33.001,18 C33.209,18 33.42,17.935 33.6,17.8 C34.042,17.469 34.131,16.842 33.8,16.4 L24.796,4.394 C24.466,3.954 23.84,3.862 23.396,4.194 C22.954,4.525 22.865,5.152 23.196,5.594 L32.2,17.6 C32.396,17.862 32.697,18 33.001,18 L33.001,18 Z'
    id='Shape' fill='#000' />
    <path d='M20.6,4.194 C20.157,3.862 19.531,3.954 19.2,4.394 L10.196,16.4 C9.864,16.842 9.954,17.469 10.396,17.8 C10.576,17.935 10.787,18 10.995,18 C11.299,18 11.599,17.862 11.796,17.6 L20.8,5.594 C21.131,5.152 21.042,4.525 20.6,4.194 L20.6,4.194 Z'
    id='Shape' fill='#000' />
    <path d='M17,16 L27,16 C27.552,16 28,15.552 28,15 C28,14.448 27.552,14 27,14 L17,14 C16.448,14 16,14.448 16,15 C16,15.552 16.448,16 17,16 L17,16 Z'
    id='Shape' fill='#000' />
    <path d='M43,14 L36,14 C35.448,14 35,14.448 35,15 C35,15.552 35.448,16 36,16 L42,16 L42,17.93 C41.843,18.971 41.17,20 40,20 L4,20 C2.817,20 2.084,18.925 2,18 L2,16 L8,16 C8.552,16 9,15.552 9,15 C9,14.448 8.552,14 8,14 L1,14 C0.448,14 0,14.448 0,15 L0.004,18.087 C0.132,19.558 0.93,20.778 2.051,21.447 L4.004,36.087 C4.199,38.318 5.917,40 8,40 L36,40 C38.049,40 39.69,38.409 39.991,36.132 L41.949,21.448 C43.025,20.797 43.795,19.615 43.991,18.131 C43.997,18.088 44,18.044 44,18 L44,15 C44,14.448 43.552,14 43,14 L43,14 Z M38.009,35.869 C37.869,36.93 37.193,38 36,38 L8,38 C6.817,38 6.084,36.925 5.991,35.868 L4.142,22 L39.858,22 L38.009,35.869 L38.009,35.869 Z'
    id='Shape' fill='#000' />
</g>
</svg>
<svg class="filled" width='44' height='44' viewBox='0 0 44 44' xmlns='http://www.w3.org/2000/svg'>
    <g id='44px-Glyph' fill='none' fillRule='evenodd'>
        <g id='sho-basket' fill='#000'>
            <path d='M43,14 L36,14 L34.512,14 L36.312,16.4 C36.644,16.842 36.554,17.469 36.112,17.8 C35.932,17.935 35.722,18 35.513,18 C35.208,18 34.908,17.862 34.712,17.6 L24.796,4.394 C24.465,3.953 23.838,3.862 23.396,4.194 C22.954,4.525 22.864,5.152 23.196,5.594 L29.5,14 L14.496,14 L20.8,5.594 C21.132,5.152 21.042,4.525 20.6,4.194 C20.158,3.863 19.531,3.954 19.2,4.394 L9.246,17.6 C9.05,17.862 8.75,18 8.445,18 C8.236,18 8.026,17.935 7.847,17.8 C7.404,17.469 7.314,16.842 7.646,16.4 L9.446,14 L8,14 L1,14 C0.447,14 0,14.448 0,15 L0.004,16.087 C0.198,18.318 1.917,20 4,20 L40,20 C42.049,20 43.69,18.409 43.991,16.131 C43.997,16.087 44,16.044 44,16 L44,15 C44,14.448 43.553,14 43,14'
            id='Fill-168' />
            <path d='M40,22 L4,22 C3.328,22 2.684,21.88 2.08,21.669 L4.004,36.087 C4.198,38.318 5.917,40 8,40 L36,40 C38.049,40 39.69,38.409 39.991,36.132 L41.922,21.65 C41.316,21.867 40.674,22 40,22'
            id='Fill-170' />
        </g>
    </g>
</svg>
`;

export const accountSvg = `<svg class="line" width='44' height='44' viewBox='0 0 44 44' xmlns='http://www.w3.org/2000/svg'>
<g id='44px-Line' fill='none' fillRule='evenodd'>
    <path d='M17,30 L9,30 C8.447,30 8,30.447 8,31 C8,31.553 8.447,32 9,32 L17,32 C17.553,32 18,31.553 18,31 C18,30.447 17.553,30 17,30 L17,30 Z'
    id='Shape' fill='#000' />
    <path d='M43.884,6.555 C43.846,6.479 43.8,6.411 43.744,6.347 C43.721,6.321 43.701,6.295 43.675,6.271 C43.598,6.199 43.511,6.138 43.412,6.094 C43.402,6.09 43.395,6.082 43.385,6.078 C43.384,6.078 43.383,6.077 43.383,6.077 C43.27,6.03 43.152,6.012 43.035,6.008 C43.023,6.008 43.013,6.001 43,6.001 L40,6.001 L40,1.001 C40,0.448 39.553,0.001 39,0.001 L13,0.001 C12.447,0.001 12,0.448 12,1.001 C12,1.554 12.447,2.001 13,2.001 L38,2.001 L38,10.587 L36,12.587 L36,5 C36,4.447 35.553,4 35,4 L9,4 C8.447,4 8,4.447 8,5 C8,5.553 8.447,6 9,6 L34,6 L34,14.586 L32,16.586 L32,9 C32,8.447 31.553,8 31,8 L5,8 C4.447,8 4,8.447 4,9 L4,18.586 L0.294,22.292 C0.201,22.384 0.128,22.495 0.077,22.618 C0.026,22.74 0,22.87 0,23 L0,43 C0,43.553 0.447,44 1,44 L27,44 C27.134,44 27.264,43.973 27.385,43.922 C27.507,43.871 27.617,43.797 27.708,43.705 L43.707,27.706 C43.895,27.52 44,27.266 44,27 L44,7 C44,6.877 43.971,6.761 43.93,6.651 C43.917,6.617 43.901,6.587 43.884,6.555 L43.884,6.555 Z M40,8 L40.586,8 L40,8.586 L40,8 L40,8 Z M6,10 L30,10 L30,18.586 L26.586,22 L6,22 L6,10 L6,10 Z M4,22 L3.414,22 L4,21.414 L4,22 L4,22 Z M2,24 L26,24 L26,42 L2,42 L2,24 L2,24 Z M42,26.586 L28,40.586 L28,23.414 L31.706,19.708 C31.707,19.707 31.708,19.706 31.709,19.705 L35.706,15.708 C35.707,15.707 35.708,15.706 35.709,15.705 L39.706,11.708 C39.707,11.707 39.708,11.706 39.709,11.705 L42.001,9.413 L42.001,26.586 L42,26.586 Z'
    id='Shape' fill='#000' />
</g>
</svg>
<svg class="filled" width='44' height='44' viewBox='0 0 44 44' xmlns='http://www.w3.org/2000/svg'>
    <g id='44px-Glyph' fill='none' fillRule='evenodd'>
        <g id='doc-box' fill='#000'>
            <path d='M15.0044,2.0005 L36.0044,2.0005 L36.0044,12.5865 L38.0044,10.5865 L38.0044,6.0005 L38.0044,1.0005 C38.0044,0.4485 37.5574,0.0005 37.0044,0.0005 L15.0044,0.0005 C14.4514,0.0005 14.0044,0.4485 14.0044,1.0005 C14.0044,1.5525 14.4514,2.0005 15.0044,2.0005'
            id='Fill-21' />
            <path d='M11.0044,6.0005 L32.0044,6.0005 L32.0044,16.5865 L34.0044,14.5865 L34.0044,5.0005 C34.0044,4.4485 33.5574,4.0005 33.0044,4.0005 L11.0044,4.0005 C10.4514,4.0005 10.0044,4.4485 10.0044,5.0005 C10.0044,5.5525 10.4514,6.0005 11.0044,6.0005'
            id='Fill-23' />
            <path d='M17.0044,32.0005 L9.0044,32.0005 C8.4514,32.0005 8.0044,31.5525 8.0044,31.0005 C8.0044,30.4485 8.4514,30.0005 9.0044,30.0005 L17.0044,30.0005 C17.5574,30.0005 18.0044,30.4485 18.0044,31.0005 C18.0044,31.5525 17.5574,32.0005 17.0044,32.0005 L17.0044,32.0005 Z M26.0044,24.0005 L0.0044,24.0005 L0.0044,43.0005 C0.0044,43.5525 0.4514,44.0005 1.0044,44.0005 L26.0044,44.0005 L26.0044,24.0005 Z'
            id='Fill-25' />
            <polygon id='Fill-27' points='40.0044 6.0005 40.0044 8.5865 42.5904 6.0005'
            />
            <path d='M28.0044,23.4146 L28.0044,43.4146 L43.7114,27.7076 C43.8984,27.5196 44.0044,27.2656 44.0044,27.0006 L44.0044,7.4146 L28.0044,23.4146 Z'
            id='Fill-29' />
            <polygon id='Fill-31' points='4.0044 18.5864 0.5904 22.0004 4.0044 22.0004'
            />
            <path d='M6.0044,22.0005 L26.5904,22.0005 L30.0044,18.5865 L30.0044,9.0005 C30.0044,8.4485 29.5574,8.0005 29.0044,8.0005 L7.0044,8.0005 C6.4514,8.0005 6.0044,8.4485 6.0044,9.0005 L6.0044,16.5865 L6.0044,22.0005 Z'
            id='Fill-33' />
        </g>
    </g>
</svg>`;

export const helpSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260" width='44' height='44'>
<style type="text/css">
.st0{enable-background:new    ;}
.st1{fill:#FFFFFF;}
.st2{fill:none;stroke:#FFFFFF;stroke-width:10;}
</style>
<title>Help icon</title>
<g id="cnam">
<g id="Desktop-HD" transform="translate(-410.000000, -275.000000)">
   <g id="Group" transform="translate(415.000000, 280.000000)">
       <g class="st0">
           <path class="st1" d="M88,66.3c4.7-6.7,10.5-11.8,17.5-15.3c6.9-3.5,14.5-5.2,22.7-5.2c7.7,0,14.7,1.6,20.9,4.8
               c6.2,3.2,11.2,7.9,14.9,14.1c3.7,6.2,5.6,13.3,5.6,21.3c0,3.7-0.4,7.1-1.2,10.5s-1.7,6.2-2.8,8.8c-1.1,2.5-2.6,5.2-4.6,7.9
               c-2,2.8-3.7,5.1-5.4,6.9c-1.6,1.8-3.7,4.1-6.2,6.7c-0.8,0.8-2.1,2.1-4,4c-1.9,1.9-3.2,3.2-4.1,4.1c-0.9,0.9-2,2.2-3.5,3.9
               c-1.5,1.7-2.6,3.2-3.4,4.4c-0.7,1.3-1.5,2.7-2.4,4.4c-0.9,1.6-1.5,3.3-1.9,5c-0.4,1.7-0.6,3.4-0.6,5.2l-0.1,8.4h-10.4l-0.1-12.1
               c0-2.1,0.3-4.3,0.9-6.4c0.6-2.1,1.2-3.9,2-5.5c0.7-1.5,1.9-3.4,3.5-5.5c1.6-2.1,2.9-3.8,4-5c1.1-1.3,2.7-3.1,5-5.6
               c2.3-2.5,4-4.4,5.1-5.8c0.6-0.6,1.8-2,3.7-4.2c1.9-2.1,3.2-3.6,3.8-4.4c0.6-0.8,1.7-2.1,3.1-4c1.4-1.9,2.4-3.5,3-4.7
               c0.6-1.2,1.3-2.8,2.1-4.6c0.8-1.9,1.3-3.7,1.6-5.6c0.3-1.9,0.4-3.9,0.4-6.1c0-9.1-2.8-16.2-8.4-21.3c-5.6-5.1-12.8-7.6-21.4-7.6
               c-5.8,0-11.4,1.4-16.9,4.3c-5.5,2.9-10,6.9-13.6,12.1L88,66.3z M115,200.5c0-2.8,0.9-5.1,2.8-7c1.9-1.9,4.2-2.8,7-2.8
               c2.7,0,5,0.9,6.9,2.8c1.9,1.9,2.8,4.2,2.8,7c0,2.7-0.9,4.9-2.8,6.8s-4.2,2.8-6.9,2.8c-2.8,0-5.1-0.9-7-2.7
               C115.9,205.5,115,203.2,115,200.5z"/>
       </g>
       <circle id="Oval" class="st2" cx="125" cy="125" r="125"/>
   </g>
</g>
</g>
</svg>
`;
