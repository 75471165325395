import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import Results from './Results';
import Header from './Header';
import Loader from '../../components/Loader';

const propTypes = {
  loading: PropTypes.bool.isRequired,
};

const HotelResults = (props) => {
  const {
    loading,
    search,
  } = props;

  const isMobileDevice = useSelector((state) => state.app.isMobileDevice);

  if (loading) {
    return (
      <Loader
        sentences={[]}
        destination={search.place.location.city || search.place.location.name}
      />
    );
  }

  // If we don't have any search result to display (because no search has been done), we redirect
  if (!props.results) {
    return <Redirect to="/" />;
  }

  return (
    <div className="hotel-results-container">
      <Header isMobileDevice={isMobileDevice} />
      <Results />
    </div>
  );
};

HotelResults.propTypes = propTypes;

const mapStateToProps = (state) => ({
  loading: state.hotelResults.loading,
  results: state.hotelResults.results,
  search: state.hotelResults.search,
});

export default connect(
  mapStateToProps,
)(HotelResults);
