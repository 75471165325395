import React from 'react';

const SkeletonLoader = ({ children }) => (
  <div className="loading-screen">
    <div className="loading-menu">
      <div className="loading-logo">
        <div className="loading-logo__leaf" />
        <div className="loading-menu__block" />
      </div>
      <div className="loading-menu__group">
        <div className="loading-menu__block" />
        <div className="loading-menu__block" />
        <div className="loading-menu__block" />
      </div>
      <div className="loading-menu__block" />
    </div>
    {children}
  </div>
);

export default SkeletonLoader;
