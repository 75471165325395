import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import API from '../../utils/api';

import { isValidDate } from '../../utils/functions';
import Form from './Form';
import Details from './Details';

import {
  setPlaceLocation,
  setPlaceRadius,
  addPassenger,
  editPassenger,
  removePassenger,
  setArrivalDate,
  setDepartureDate,
  searchHotels,
  checkForm,
  toggleSelectedPassenger,
  addRoom,
  removeRoom,
  setNameContains,
} from './actions';

import {
  setStars,
  toggleBreakfast,
  toggleLoyaltyEnabled,
  toggleAccessible,
} from '../HotelResults/actions';

import { openModal } from '../ModalWrapper/actions';
import { setEntity } from '../Search/actions';
import { searchTravelers } from '../App/actions';

const source = axios.CancelToken.source();

class SearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      place: { value: '', places: null, searching: false },
      dates: {
        arrival: this.props.search.date.arrival
          ? moment(this.props.search.date.arrival).format('DD/MM/YYYY')
          : '',
        departure: this.props.search.date.departure
          ? moment(this.props.search.date.departure).format('DD/MM/YYYY')
          : '',
      },
    };

    this.typingTimeout = null;
  }

  componentDidMount() {
    if (this.props.search.place.location) {
      this.setState({
        place: {
          value: this.props.search.place.location.description ? this.props.search.place.location.description : this.props.search.place.location.name,
          places: null,
          searching: null,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search.place.location !== this.props.search.place.location) {
      this.changeSearchLocation(
        this.props.search.place.location.description ? this.props.search.place.location.description : this.props.search.place.location.name,
        false,
      );
    }
  }

  setSelected = (field) => {
    this.setState({
      selected: field,
    });
  };

  changeSearchLocation = (value, search = true) => {
    if (!value) {
      this.setState({
        place: { value: '' },
      });
      return;
    }
    this.setState({
      place: { ...this.state.place, value },
    });
    clearTimeout(this.typingTimeout);
    if (value.length && search) {
      this.typingTimeout = setTimeout(async () => {
        this.setState({ place: { ...this.state.place, searching: true } });
        const places = await this.getPlaces(value);
        this.setState({
          place: { ...this.state.place, places, searching: false },
        });
      }, 300);
    } else if (!search) {
      this.setState({
        place: { ...this.state.place, value, searching: false },
      });
    } else {
      this.setState({ place: { value, places: null } });
    }
  };

  getPlaces = (q) => new Promise((resolve) => {
    const params = { q };
    API.get('/places', {
      cancelToken: source.token,
      params,
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;

        resolve([]);
      });
  });

  onBlur = (field) => {
    if (this.props.search[field] !== this.state[field].value) {
      this.setState({
        [field]: {
          ...this.state[field],
          value: this.props.search[field].description ? this.props.search[field].description : this.props.search[field].name,
        },
      });
    }
  };

  changeDate = (date, trip) => {
    date = moment(date, ['DD-MM-YYYY', 'D/M/YYYY']); // We have to parse it first because if we get the raw input, it's gonna look like 20/08/2018 while the whole thing expects a moment object
    if (!isValidDate(date)) return;
    if (trip === 'arrival') {
      this.props.setArrivalDate(date);
      // If the return date is set before the outward date, we need to change it
      if (moment(this.props.search.date.departure).isBefore(date)) {
        this.props.setDepartureDate(moment(date).add(1, 'days'));
      }
      this.setSelected('return');
    } else {
      this.props.setDepartureDate(date);
    }
  };

  changeDateInput = (field, date) => {
    if (date) {
      this.setState({
        dates: {
          ...this.state.dates,
          [field]: date,
        },
      });
      this.changeDate(date, field);
    } else {
      this.setState({
        dates: {
          ...this.state.dates,
          [field]: moment(this.props.search.date[field].date).format(
            'DD/MM/YYYY',
          ),
        },
      });
    }
  };

  render() {
    return (
      <div className="search-form">
        <Form
          {...this.props}
          setSelected={this.setSelected}
          selected={this.state.selected}
          changeSearchLocation={this.changeSearchLocation}
          place={this.state.place}
          radius={this.props.search.place.radius}
          dates={this.state.dates}
          handleSearchBlur={this.onBlur}
          changeDate={this.changeDate}
          changeDateInput={this.changeDateInput}
        />
        <Details
          {...this.props}
          selected={this.state.selected}
          changeSearchLocation={this.changeSearchLocation}
          place={this.state.place}
          changeDate={this.changeDate}
          changeDateInput={this.changeDateInput}
          co2={this.props.co2}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  search: state.hotelSearch,
  travelers: state.app.travelers,
  hasTravelersSearch: state.app.hasTravelersSearch,
  co2: state.app.stats && state.app.stats.co2,
  userPermissions: state.app.userPermissions,
  filters: state.hotelResults.filters,
  entities: state.app.entities,
  entityID: state.search.entityID,
  isMobileDevice: state.app.isMobileDevice,
  cardsList: state.app.cards,
  suggestedAddresses: state.app.user?.company?.config?.suggestedAddresses,
});

const mapDispatchToProps = (dispatch) => ({
  setPlaceLocation: (location) => dispatch(setPlaceLocation(location)),
  setPlaceRadius: (radius) => dispatch(setPlaceRadius(radius)),
  addPassenger: (passenger) => dispatch(addPassenger(passenger)),
  editPassenger: (passenger) => dispatch(editPassenger(passenger)),
  removePassenger: (index) => dispatch(removePassenger(index)),
  setArrivalDate: (trip, date) => dispatch(setArrivalDate(trip, date)),
  setDepartureDate: (trip, date) => dispatch(setDepartureDate(trip, date)),
  searchHotels: () => dispatch(searchHotels()),
  checkForm: () => dispatch(checkForm()),
  toggleSelectedPassenger: (index) => dispatch(toggleSelectedPassenger(index)),
  openModal: (options = null) => dispatch(openModal('Passenger', options)),
  addRoom: () => dispatch(addRoom()),
  removeRoom: () => dispatch(removeRoom()),
  setStars: (n) => dispatch(setStars(n)),
  toggleBreakfast: () => dispatch(toggleBreakfast()),
  toggleLoyaltyEnabled: () => dispatch(toggleLoyaltyEnabled()),
  setNameContains: (name) => dispatch(setNameContains(name)),
  setEntity: (entity) => dispatch(setEntity(entity)),
  searchTravelers: (query) => dispatch(searchTravelers(query)),
  toggleAccessible: () => dispatch(toggleAccessible()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchForm);
