import React from 'react';
import { Link } from 'react-router-dom';
import Leaf from '../../components/GreenScore/Leaf';

const Error = ({ errors }) => (
  <div className="common-wrapper checkout-success">
    <section className="big-leaf">
      <Leaf completion={1} />
    </section>
    {errors.map((error) => (
      <p className="common-small-font" dangerouslySetInnerHTML={{ __html: error }} />
    ))}
    <Link to="/account/tickets/" className="button-main">
      {window.i18('RETURN_TO_TICKET_LIST')}
    </Link>
  </div>
);

export default Error;
