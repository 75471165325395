export const SET_OUTWARD_TRIP = 'tripResults/SET_OUTWARD_TRIP';
export const SET_RETURN_TRIP = 'tripResults/SET_RETURN_TRIP';
export const SET_SEARCHING_TRIP = 'tripResults/SET_SEARCHING_TRIP';
export const SET_IS_ROUND_TRIP = 'tripResults/SET_IS_ROUND_TRIP';
export const SET_SEARCHED_TRIP = 'tripResults/SET_SEARCHED_TRIP';
export const SET_PASSENGERS = 'tripResults/SET_PASSENGERS';
export const RESET_RESULTS = 'tripResults/RESET_RESULTS';
export const FILL_PASSENGER_INFO = 'tripResults/FILL_PASSENGER_INFO';
export const SET_SELECTED_OUTWARD_TRANSPORT_MODE = 'tripResults/SET_SELECTED_OUTWARD_TRANSPORT_MODE';
export const SET_SELECTED_RETURN_TRANSPORT_MODE = 'tripResults/SET_SELECTED_RETURN_TRANSPORT_MODE';
export const SET_SELECTED_OUTWARD_PROPOSAL = 'tripResults/SET_SELECTED_OUTWARD_PROPOSAL';
export const SET_SELECTED_RETURN_PROPOSAL = 'tripResults/SET_SELECTED_RETURN_PROPOSAL';
export const SET_SELECTED_OUTWARD_CABIN = 'tripResults/SET_SELECTED_OUTWARD_CABIN';
export const SET_SELECTED_RETURN_CABIN = 'tripResults/SET_SELECTED_RETURN_CABIN';
export const SET_SELECTED_OUTWARD_CHOICE = 'tripResults/SET_SELECTED_OUTWARD_CHOICE';
export const SET_SELECTED_RETURN_CHOICE = 'tripResults/SET_SELECTED_RETURN_CHOICE';
export const VALIDATE_OUTWARD_CHOICE = 'tripResults/VALIDATE_OUTWARD_CHOICE';
export const VALIDATE_RETURN_CHOICE = 'tripResults/VALIDATE_RETURN_CHOICE';
export const SET_TRAVELERS_REQUIRED_INFOS = 'tripResults/SET_TRAVELERS_REQUIRED_INFOS';
export const LOADING_TRAVELERS_REQUIRED_INFOS = 'tripResults/LOADING_TRAVELERS_REQUIRED_INFOS';
export const ADD_TO_CART_BEGIN = 'tripResults/ADD_TO_CART_BEGIN';
export const ADD_TO_CART_SUCCESS = 'tripResults/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'tripResults/ADD_TO_CART_ERROR';
export const TOGGLE_FILTER = 'tripResults/TOGGLE_FILTER';
export const CHANGE_OUTWARD_CHOICE = 'tripResults/CHANGE_OUTWARD_CHOICE';
export const CHANGE_RETURN_CHOICE = 'tripResults/CHANGE_RETURN_CHOICE';
export const SET_SORTING = 'tripResults/SET_SORTING';

export const FILTER_TRANSIT_FLIGHT = 'FILTER_TRANSIT_FLIGHT';
export const FILTER_TRANSIT_RAIL = 'FILTER_TRANSIT_RAIL';
export const FILTER_LUGGAGE = 'FILTER_LUGGAGE';
export const FILTER_NO_FLEX = 'FILTER_NO_FLEX';
export const FILTER_SEMI_FLEX = 'FILTER_SEMI_FLEX';
export const FILTER_FULL_FLEX = 'FILTER_FULL_FLEX';
