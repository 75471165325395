import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
// Components
import { Field } from 'formik';
import Input from '../Inputs/Material';
import DateInput from '../Inputs/MaterialDate';
import Select from '../Inputs/MaterialSelect';
import Radio from '../Radio';

export default function UserFormFromAPI({
  requiredInfos, values, setFieldValue, errors, touched,
}) {
  const { isMobileDevice } = useSelector((state) => state.app);
  const dataToField = (data, i, prefix) => {
    const {
      name, required, regex, label, placeholder, type, options,
    } = data;
    const fieldName = prefix ? `${prefix}.${name}` : name;
    const value = prefix ? values?.[prefix]?.[name] : values?.[name];
    const error = prefix ? errors?.[prefix]?.[name] : errors?.[name];
    const fieldTouched = prefix ? touched?.[prefix]?.[name] : touched?.[name];
    if (type === 'date') {
      return (
        <div className={classNames('col', isMobileDevice ? 'col-12' : 'col-6', { 'm-top': i > 1 })} key={i}>
          <DateInput name={fieldName} label={label} placeholder={placeholder} onChange={(value) => setFieldValue(fieldName, value)} value={value} optional={!required} regex={regex} error={error} touched={fieldTouched} withYearSelect />
        </div>
      );
    }
    if (type === 'list') {
      return (
        <div className={classNames('col', isMobileDevice ? 'col-12' : 'col-6', { 'm-top': i > 1 })} key={i}>
          <Select name={fieldName} label={label} placeholder={placeholder} onChange={(value) => setFieldValue(fieldName, value)} value={value} optional={!required} regex={regex} error={error} touched={fieldTouched} options={options} />
        </div>
      );
    }
    if (type === 'radio') {
      return (
        <div className={classNames('col col-12 material-input--focused', { 'm-top': i > 1 })} key={i}>
          <p className="material-input__label m-0 m-bot">{label}</p>
          <div className="row justify-start">
            {options.map((option, i) => (
              <div className="col m-right" key={i}>
                <Field
                  component={Radio}
                  name={fieldName}
                  id={option.value}
                  label={option.label}
                  value={option.value}
                />
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div className={classNames('col', isMobileDevice ? 'col-12' : 'col-6', { 'm-top': i > 1 })} key={i}>
        <Input name={fieldName} label={label} placeholder={placeholder} onChange={(value) => setFieldValue(fieldName, value)} value={value} optional={!required} regex={regex} error={error} touched={fieldTouched} />
      </div>
    );
  };
  return (
    <div className="row">
      {requiredInfos.map((traveler, i) => (
        <div className={classNames('col card card--small p-all m-bot', isMobileDevice ? 'col-12' : 'col-6')} key={i}>
          {traveler.fieldsGroups.map((fieldGroup, i) => (
            <React.Fragment key={i}>
              <p style={{ marginTop: i === 0 ? 0 : 'initial' }}>{fieldGroup.label}</p>
              <div className="row">
                {fieldGroup.fields.map((data, i) => dataToField(data, i, traveler.travelerID))}
              </div>
            </React.Fragment>
          ))}
          {traveler.cards && (
            <>
              <p>{window.i18('CARDS')}</p>
              <ul className="list">
                {traveler.cards.map((card, i) => (
                  <li className="m-0 m-bot" key={i}>
                    {card.cardtype?.name ?? ''}
                    {' '}
                    {card.cardnumber ? 'n°' : ''}
                    {card.cardnumber ?? ''}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      ))}
    </div>
  );
}
