import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import UserFormFromApi from '../../../components/DynamicForm';
import dataToYupSchema from '../../../components/DynamicForm/validation';
import { addSelectedOffersToCart, getTravelersRequiredInfos } from '../actions';

export default function Passengers() {
  const { user } = useSelector((state) => state.app);
  const { requiredInfos: { data, loading }, addingToCart } = useSelector((state) => state.results);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data === 'NOT_LOGGED_IN' && user !== null) {
      dispatch(getTravelersRequiredInfos());
    }
  }, [data, dispatch, user]);

  if (loading) {
    return (
      <p>
        {window.i18('LOADING')}
        ...
      </p>
    );
  }

  if (data === 'NOT_LOGGED_IN') {
    return (
      <p>{window.i18('LOGIN_TO_CONTINUE')}</p>
    );
  }

  const initialValues = data.reduce((obj, item) => {
    const {
      travelerID, cards, guestID = null, profileID = null,
    } = item;
    const fields = item.fieldsGroups.map((g) => g.fields).flat();
    const items = fields.reduce((obj, field) => ({ ...obj, [field.name]: field.value ? field.value : '' }), {});
    return {
      ...obj,
      [travelerID]: {
        ...items, cards, guest_id: guestID, profile_id: profileID,
      },
    };
  }, {});

  const validationSchema = dataToYupSchema(data);

  return (
    <>
      <h2>Passagers</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => dispatch(addSelectedOffersToCart(values))}
      >
        {({
          values, setFieldValue, errors, touched,
        }) => (
          <Form>
            <UserFormFromApi requiredInfos={data} values={values} setFieldValue={setFieldValue} errors={errors} touched={touched} />
            <div className="flex justify-end p-y">
              <button className={classNames('button-main', { loading: addingToCart })} disabled={addingToCart} type="submit" style={{ width: 200 }}>
                <span className="text">
                  {addingToCart
                    ? window.i18('PUTTING_OPTION')
                    : window.i18('ADD_TO_CART')}
                </span>
                <div className="loading" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
