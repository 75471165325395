import React from 'react';
import { isMultipleTicket } from '../../containers/Cart/selectors';
import SubsetHotelTicket from './SubsetHotelTicket';
import { NbNight } from './SubsetTicket';

const SubsetHotel = (props) => {
  const priceDetails = props.tickets && props.tickets.map((ticket) => {
    if (isMultipleTicket(ticket)) { return `${ticket.travelers_count}x ${ticket.price}€`; }
    return null;
  });

  return (
    <div className="cart-row">
      <div className="cart-block informations">
        <div className="from-to-wrapper">
          <div>
            <div className="reservation-details">
              <span className="information name">
                <span>{props.hotelName}</span>
                {' '}
                -
                {' '}
                {NbNight(props.departure, props.arrival, true)}
              </span>
              <span className="information adresse">
                {props.adress}
              </span>
            </div>
          </div>
        </div>
      </div>
      <SubsetHotelTicket {...props} priceDetails={priceDetails} />
    </div>
  );
};

export default SubsetHotel;
