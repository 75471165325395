import API from '../../utils/api';
import { SET_APPROVAL_ERROR, SET_APPROVAL_LOADING, SET_APPROVAL_RESPONSE } from './constants';

export function isLoading() {
  return {
    type: SET_APPROVAL_LOADING,
  };
}

export function approvalError(error) {
  return {
    type: SET_APPROVAL_ERROR,
    error,
  };
}

export function approvalReponse(response) {
  return {
    type: SET_APPROVAL_RESPONSE,
    response,
  };
}

// Send the approve request to API server
export function sendApproveRequest(token) {
  return (dispatch) => {
    if (token) {
      API.post('/ticket/approve', { token }).then(
        (response) => {
          if (response.data.result) {
            dispatch(approvalReponse(response.data.result));
          }
        },
      ).catch((error) => {
        if (error?.data?.result) {
          dispatch(approvalError(error.data.result));
        } else {
          dispatch(approvalError(error));
        }
      });
    }
  };
}
