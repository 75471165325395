import React from 'react';
import moment from 'moment';
import { getAirlineLogo, getTrainLogo } from '../../utils/icons';
import { displayPrice } from '../../utils/converters';

export default function TripTicket({
  onClick,
  proposal: {
    from, to, departure, arrival, type, marketingCarrier,
  },
  price,
  carriers,
  places,
}) {
  return (
    <button className="trip-ticket" onClick={onClick}>
      <div>
        <p>{type === 'rail' ? places[from].name : from}</p>
        <p className="trip-ticket__logo">{type === 'rail' ? getTrainLogo(carriers[type][marketingCarrier]?.logo) || marketingCarrier : getAirlineLogo(carriers[type][marketingCarrier]?.logo) || marketingCarrier}</p>
        <p>{type === 'rail' ? places[to].name : to}</p>
      </div>
      <div className="trip-ticket__timeline">
        <span />
        <span />
        <span />
      </div>
      <div>
        <p>{moment(departure).format('HH:mm')}</p>
        <p>{moment(arrival).format('HH:mm')}</p>
      </div>
      <div className="trip-ticket__bottom">
        <p>
          {displayPrice(price / 100)}
        </p>
        <p>{window.i18('EDIT')}</p>
      </div>
    </button>
  );
}
