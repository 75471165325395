import React from 'react';
import whiteCard from '../../../images/icons/white-card.png';

const cardsList = ({
  cards,
  cardsList,
  isMobileDevice,
}) => (
  <span className="card common-tooltip__parent">
    {cards.length}
    {' '}
    {isMobileDevice ? (
      <img
        src={whiteCard}
        alt="carte"
      />
    ) : window.i18('CARD')}
    {cards.length > 1 && !isMobileDevice && 's'}
    <div className="common-tooltip">
      <div className="common-tooltip__content">
        {cards.map((card, i) => (
          <p key={`cards-${i}`}>
            {cardsList
            && cardsList.find((c) => c.uid === card.cardtype_id) ? cardsList.find((c) => c.uid === card.cardtype_id).name : window.i18('UNKNOWN_CARD')}
          </p>
        ))}
      </div>
    </div>
  </span>
);

export default cardsList;
