import React from 'react';
import classNames from 'classnames';
import { Formik, Form, FieldArray } from 'formik';
import * as yup from 'yup';
// Components
import CardInput from '../../../components/CardInput';
import UserForm from '../../../components/UserForm/new';

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

function formatPassenger(p) {
  p.cards = p.cards?.filter((c) => c.cardtype_id !== '');
  p.cards = p.cards?.map((card) => {
    card.cardtype_id = parseInt(card.cardtype_id, 10);
    if (card.cardnumber === null) delete card.cardnumber;
    return card;
  });
  p.selected = true;
  return p;
}

function Passengers({
  active, passengers, cardtypes, handleSubmit, setActive, passed, nbAccompanying,
}) {
  let passengersList = [...passengers];
  const mainPassenger = passengersList.shift();
  if (nbAccompanying) {
    passengersList = passengersList.slice(0, nbAccompanying);
  } else {
    passengersList = [];
  }

  return (
    <div className={classNames('step-block', { active })}>
      <h2 data-step="3" className="step-block__title">Je remplis les informations passager(s)</h2>
      {active && (
        <Formik
          initialValues={{ mainPassenger: { ...mainPassenger, cards: mainPassenger.cards?.length > 0 ? [...mainPassenger.cards] : [{ cardtype_id: '' }] }, passengers: passengersList }}
          onSubmit={(values) => {
            const mainPassenger = formatPassenger(values.mainPassenger);
            const accompanying = values.passengers.length ? values.passengers.map((p) => formatPassenger(p)) : [];

            const passengers = [
              mainPassenger,
              ...accompanying,
            ];

            handleSubmit(passengers.map((p, i) => {
              p.id = i;
              return p;
            }));
            setActive('search');
          }}
          validationSchema={yup.object().shape({
            mainPassenger: yup.object().shape({
              cards: yup.array().of(yup.object().shape({
                cardtype_id: yup.number().transform(emptyStringToNull).nullable(),
                cardnumber: yup.string().when('cardtype_id', (cardtypeId, schema) => {
                  const cardType = cardtypes.find((c) => parseInt(c.uid, 10) === parseInt(cardtypeId, 10));
                  if (cardType && cardType.card_type === 'loyalty_card') {
                    return schema.required('Veuillez entrer un numéro');
                  }
                  return schema;
                }),
              })),
            }),
          })}
        >
          {({
            values, setFieldValue, errors, touched,
          }) => (
            <Form className="step-block__form">
              <p>
                {values.mainPassenger.firstname}
                {' '}
                {values.mainPassenger.lastname}
              </p>
              {values.mainPassenger.cards.map((card, i) => (
                <CardInput cardtypes={cardtypes} card={card} values={values.mainPassenger.cards} errors={errors} touched={touched} setFieldValue={setFieldValue} index={i} key={card.uid} name="mainPassenger.cards" />
              ))}
              <button className="button-secondary" disabled={values.mainPassenger.cards.find((card) => card.cardtype_id === '') !== undefined} onClick={() => setFieldValue('mainPassenger.cards', [...values.mainPassenger.cards, { cardtype_id: '', cardnumber: '' }])}>Ajouter une carte</button>
              {nbAccompanying > 0 && (
                <>
                  <p>
                    {nbAccompanying}
                    {' '}
                    accompagnant
                    {nbAccompanying > 1 ? 's' : null}
                    {' '}
                    pris en charge
                  </p>
                  <FieldArray
                    name="passengers"
                    render={(arrayHelpers) => (
                      <>
                        {values.passengers.map((passenger, i) => {
                          if (i > nbAccompanying) return null;
                          return (
                            <div className="step-block__accompanying m-top">
                              <UserForm
                                values={passenger}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                withPhone
                                withBirthdate
                                prefix={`passengers[${i}].`}
                              />
                              {passenger.cards?.map((card, j) => (
                                <CardInput cardtypes={cardtypes} card={card} values={passenger.cards} errors={errors} touched={touched} setFieldValue={setFieldValue} index={j} key={card.uid} name={`passengers[${i}].cards`} />
                              ))}
                              <button type="button" className="button-secondary" disabled={passenger.cards?.find((card) => card.cardtype_id === '') !== undefined} onClick={() => setFieldValue(`passengers[${i}].cards`, [...values.passengers[i].cards, { cardtype_id: '', cardnumber: '' }])}>Ajouter une carte</button>
                              <div className="row">
                                <button type="button" className="button-tertiary" onClick={() => arrayHelpers.remove(i)}>Supprimer ce passager</button>
                              </div>
                            </div>
                          );
                        })}
                        <button className="button-secondary m-top" disabled={values.passengers.length === nbAccompanying} onClick={() => arrayHelpers.push({ cards: [{ cardtype_id: '' }] })}>Ajouter passager</button>
                      </>
                    )}
                  />
                </>
              )}
              <button className="button-main m-top" type="submit">Valider</button>
            </Form>
          )}
        </Formik>
      )}
      {passed && (
        <div className="step-block__completed">
          {passengers?.map((p, i) => (
            <p>
              {i >= 1 ? <br /> : ''}
              <strong>
                {p.firstname}
                {' '}
                {p.lastname}
              </strong>
              {' '}
              {p.cards && p.cards.length > 0 && (
                <>
                  (
                  {mainPassenger.cards.length}
                  {' '}
                  carte
                  {p.cards.length > 1 && 's'}
                  )
                </>
              )}
            </p>
          ))}
          <button className="button-link" onClick={() => setActive('passengers')}>Modifier</button>
        </div>
      )}
    </div>
  );
}

export default Passengers;
