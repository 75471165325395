import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Components

const propTypes = {
  value: PropTypes.bool.isRequired,
  toggleValue: PropTypes.func.isRequired,
};

export default function toggleFilter({ value, toggleValue, name }) {
  const className = classNames('hotel-results-filter__btn', { active: value });
  return (
    <button className={className} onClick={toggleValue}>
      {name}
    </button>
  );
}

toggleFilter.propTypes = propTypes;
