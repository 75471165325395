import React from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Leaf from '../../components/GreenScore/Leaf';
import { TICKET_APPROVAL_PATH } from '../App/constants';
import { sendApproveRequest } from './actions';
import LeavesLoader from '../../components/Loader/LeavesLoader';
import { capitalize } from '../../utils/functions';

class Approval extends React.Component {
  constructor(props) {
    super(props);

    if (props.match.path === TICKET_APPROVAL_PATH) {
      props.sendApproveRequest(props.match.params.token);
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <LeavesLoader
          sentence=""
        />
      );
    }
    let pageContent = null;

    if (this.props.response != null) {
      let requestor = '';
      if (this.props.response.requestor != null && this.props.response.requestor.email != null) {
        requestor = (
          <>
            {' '}
            {window.i18('FROM')}
            {' '}
            <strong>{this.props.response.requestor.email}</strong>
          </>
        );
      }
      if (this.props.response.status === 'denied') {
        pageContent = (
          <p className="common-small-font">
            {capitalize(window.i18('YOU'))}
            {' '}
            <strong>{window.i18('DID_NOT_APPROVE')}</strong>
            {' '}
            {window.i18('THE_TRAVEL_REQUEST')}
            {requestor}
            .
          </p>
        );
      } else if (this.props.response.status === 'allowed') {
        pageContent = (
          <p className="common-small-font">
            {capitalize(window.i18('YOU'))}
            {' '}
            <strong>{window.i18('DID_APPROVE')}</strong>
            {' '}
            {window.i18('THE_TRAVEL_REQUEST')}
            {requestor}
            {' '}
            !
          </p>
        );
      }
    }

    if (this.props.error != null) {
      let error = this.props.error.status;
      if (error === 400) {
        error = window.i18('APPROVAL_VALIDATION_EXPIRED_ERROR');
      }
      if (this.props.error.warnings != null && this.props.error.warnings.length > 0) {
        error = (
          <ul>
            {this.props.error.warnings.map((warning) => (<li>{warning}</li>))}
          </ul>
        );
      }
      pageContent = (
        <p className="common-small-font">
          {window.i18('APPROVAL_VALIDATION_ERROR')}
          {error}
        </p>
      );
    }
    return (
      <div className="common-wrapper checkout-success">
        <section className="big-leaf">
          <Leaf completion={1} />
        </section>
        {pageContent}
        <Link className="button-main" to="/">{window.i18('DISCOVER_THE_TREEP')}</Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.approval.isLoading,
  error: state.approval.error,
  response: state.approval.response,
});

const mapDispatchToProps = (dispatch) => ({
  sendApproveRequest: (token) => dispatch(sendApproveRequest(token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Approval);
