import { combineReducers } from 'redux';

import {
  DOING_CHECKOUT,
  PROCESSING_START,
  PROCESSING_SUCCESS,
  PROCESSING_ERROR,
  RESET_CHECKOUT,
} from './constants';

function isPayingReducer(state = false, { type }) {
  switch (type) {
    case RESET_CHECKOUT:
      return false;
    case DOING_CHECKOUT:
      return true;
    default:
      return state;
  }
}

function actionReducer(state = null, { type, action, success }) {
  switch (type) {
    case PROCESSING_START:
      return action;
    case PROCESSING_SUCCESS:
      return success && success.status ? { ...state, status: success.status } : state;
    default:
      return state;
  }
}

function isProcessingReducer(state = false, { type }) {
  switch (type) {
    case PROCESSING_START:
      return true;
    case PROCESSING_SUCCESS:
    case PROCESSING_ERROR:
    case RESET_CHECKOUT:
      return false;
    default:
      return state;
  }
}

function isConfirmingOption(state = null, { type, isConfirmingOption }) {
  switch (type) {
    case DOING_CHECKOUT:
      return isConfirmingOption;
    default:
      return state;
  }
}

function successReducer(state = null, { type, success }) {
  switch (type) {
    case RESET_CHECKOUT:
      return null;
    case PROCESSING_SUCCESS:
      return success;
    default:
      return state;
  }
}

function errorReducer(state = null, { type, error }) {
  switch (type) {
    case RESET_CHECKOUT:
      return null;
    case PROCESSING_ERROR:
      return error;
    default:
      return state;
  }
}

function ticketsReducer(state = [], action) {
  switch (action.type) {
    case RESET_CHECKOUT:
      return [];
    case DOING_CHECKOUT:
      return action.tickets;
    default:
      return state;
  }
}

function amountReducer(state = null, action) {
  switch (action.type) {
    case RESET_CHECKOUT:
      return null;
    case DOING_CHECKOUT:
      return action.amount;
    default:
      return state;
  }
}

function needApprovalReducer(state = null, action) {
  switch (action.type) {
    case RESET_CHECKOUT:
      return null;
    case DOING_CHECKOUT:
      return action.need_approval;
    default:
      return state;
  }
}

function isCompliantReducer(state = null, action) {
  switch (action.type) {
    case RESET_CHECKOUT:
      return null;
    case DOING_CHECKOUT:
      return action.is_compliant;
    default:
      return state;
  }
}

function travelPolicyReducer(state = null, action) {
  switch (action.type) {
    case RESET_CHECKOUT:
      return null;
    case DOING_CHECKOUT:
      return action.travel_policy;
    default:
      return state;
  }
}

function methodsReducer(state = null, action) {
  switch (action.type) {
    case RESET_CHECKOUT:
      return null;
    case DOING_CHECKOUT:
      return action.payment_methods;
    default:
      return state;
  }
}

function requiredFieldsReducer(state = null, action) {
  switch (action.type) {
    case RESET_CHECKOUT:
      return null;
    case DOING_CHECKOUT:
      return action.required_fields;
    default:
      return state;
  }
}

function optionalFieldsReducer(state = null, action) {
  switch (action.type) {
    case RESET_CHECKOUT:
      return null;
    case DOING_CHECKOUT:
      return action.optional_fields;
    default:
      return state;
  }
}

function missionOrdersReducer(state = null, action) {
  switch (action.type) {
    case RESET_CHECKOUT:
      return null;
    case DOING_CHECKOUT:
      return action.missionOrders || null;
    default:
      return state;
  }
}

const checkoutReducer = combineReducers({
  isPaying: isPayingReducer,
  action: actionReducer,
  isProcessing: isProcessingReducer,
  success: successReducer,
  error: errorReducer,
  tickets: ticketsReducer,
  amount: amountReducer,
  paymentMethods: methodsReducer,
  requiredFields: requiredFieldsReducer,
  optionalFields: optionalFieldsReducer,
  needApproval: needApprovalReducer,
  isCompliant: isCompliantReducer,
  travelPolicy: travelPolicyReducer,
  missionOrders: missionOrdersReducer,
  isConfirmingOption,
});

export default checkoutReducer;
