import React from 'react';
import PropTypes from 'prop-types';
import { TweenLite } from 'gsap';
import classNames from 'classnames';
// Component
import Stars from '../../../components/Stars';
import Leaf from '../../../components/GreenScore/Leaf';
import TicketTagsTooltip from '../../../components/TicketTagsTooltip';
import HotelTags from '../../../components/HotelTags';

const propTypes = {
  selectedResult: PropTypes.shape({}).isRequired,
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedResult: PropTypes.func.isRequired,
};

export default class HotelsMobile extends React.Component {
  constructor(props) {
    super(props);
    this.block = React.createRef();
  }

  componentDidMount() {
    if (this.props.selectedResult.travelersDistribution) {
      TweenLite.set(this.block.current, { xPercent: -100, x: -16, opacity: 0 });
    } else if (!this.props.selectedResult.travelersDistribution) {
      TweenLite.set(this.block.current, { xPercent: 0, x: 0, opacity: 1 });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(prevProps) {
    if (prevProps.selectedResult.travelersDistribution && !this.props.selectedResult.travelersDistribution) {
      TweenLite.to(this.block.current, 0.5, { xPercent: -100, x: -16, opacity: 0 });
    } else if (!prevProps.selectedResult.travelersDistribution && this.props.selectedResult.travelersDistribution) {
      TweenLite.to(this.block.current, 0.5, { xPercent: 0, x: 0, opacity: 1 });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedMarker !== this.props.selectedMarker && this.props.selectedMarker) {
      const selected = document.getElementById(this.props.selectedMarker);
      selected.scrollIntoView({
        behavior: 'instant',
        block: 'center',
      });
    }
  }

  handleClick = (id) => {
    this.props.setSelectedResult(id);
  }

  render() {
    const {
      results, selectedResult, displayMap, selectedMarker, filters,
    } = this.props;
    return (
      <ul className="hotel-sm-results-list">
        <h3>
          {results.length}
          {' '}
          {results.length > 1 ? window.i18('HOTELS') : window.i18('HOTEL')}
          {' '}
          {results.length > 1 ? window.i18('FOUND_PLURAL') : window.i18('FOUND')}
        </h3>
        <div ref={this.block}>
          <div className="hotel-sm-results-list">
            {results.map((result) => (
              <li className={classNames('hotel-sm-results-list__item', { selected: displayMap && selectedMarker === result.id })} id={result.id} key={result.id}>
                <div>
                  <img src={result.img_url} alt="hotel" />
                  <div className="hotel-sm-preview">
                    <Stars count={result.stars} />
                    <h3>
                      <span>{result.name}</span>
                      {result.hotel_tags.has_eco_friendly
                      && (
                        <span className="common-tooltip__parent">
                          <Leaf completion={1} />
                          <div className="common-tooltip ">
                            <div className="common-tooltip__content common-tooltip__content--green">
                              {window.i18('ENV_FRIENDLY_HOTEL')}
                            </div>
                          </div>
                        </span>
                      )}
                    </h3>
                    <p>{result.room_offers?.[0]?.name || ''}</p>
                  </div>
                </div>
                <div className="hotel-sm-footer">
                  <HotelTags tags={result.hotel_tags} />
                  <div>
                    <p className="hotel-price-taxes">{result.room_offers?.[0]?.tax_included ? window.i18('TAX_INCLUDED') : window.i18('TAX_NOT_INCLUDED')}</p>
                    <div className="common-tooltip__parent hotel-price-tooltip__parent">
                      <button
                        onClick={() => this.handleClick(result.id)}
                        className={
                        classNames(
                          'hotel-price-btn',
                          'm-top',
                          { selected: result.id === selectedResult.id && !displayMap },
                          { 'hotel-price-btn--blue': result.room_offers?.[0]?.tags && result.room_offers?.[0]?.tags.indexOf('negociated-fare') > -1 },
                          { 'hotel-price-btn--green': result.room_offers?.[0]?.tags && result.room_offers?.[0]?.tags.indexOf('compliant') > -1 },
                        )
                      }
                      >
                        {result.room_offers.filter((offer) => {
                          if (filters.breakfast === true) {
                            return offer.breakfast_included;
                          }
                          return offer;
                        })[0].incl_taxes_amount.toFixed(2)}
                        €
                      </button>
                      <TicketTagsTooltip tags={result.room_offers?.[0]?.tags} />
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </div>
        </div>
      </ul>
    );
  }
}

HotelsMobile.propTypes = propTypes;
