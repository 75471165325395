import React from 'react';
import { connect } from 'react-redux';
import { gsap } from 'gsap';

import ModalLoader from './Loader';
import { openModal, closeModal } from './actions';

const ModalWrapper = class extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
  }

  shake = () => {
    const tl = gsap.timeline();
    // We need to use raw pixel values here instead of percentages, because the browser converts
    // the percent values we use in our css into raw values, which messes with gsap animations.
    // See here for more info :
    // https://greensock.com/forums/topic/16093-relationship-between-css-transform-translate-and-gsap-x/
    tl.to(this.modal.current, {
      duration: 0.08,
      x: '+60',
    });
    tl.to(this.modal.current, {
      duration: 0.07,
      x: '-30',
    });
    tl.to(this.modal.current, {
      duration: 0.05,
      x: '+15',
    });
    tl.to(this.modal.current, {
      duration: 0.05,
      x: '-7.5',
    });
    tl.to(this.modal.current, {
      duration: 0.05,
      x: '0',
    });
  };

  render() {
    const {
      props,
    } = this;

    if (!props.modal.isOpen) return null;

    return (
      <>
        <div
          className="modal-overlay"
          onClick={() => {
            if (!(props.modal.options
              && props.modal.options.noClose)) { props.closeModal(); }
          }}
        />
        <section
          className="modal-wrapper"
          ref={this.modal}
        >
          {!(props.modal.options && props.modal.options.noClose)
          && (
            <span
              className="close-modal"
              onClick={props.closeModal}
            />
          )}
          <ModalLoader
            key={props.modal.name}
            modal={props.modal}
            closeModal={props.closeModal}
            openModal={props.openModal}
            noClose={props.modal.options && props.modal.options.noClose}
            shake={this.shake}
          />
        </section>
      </>
    );
  }
};

const mapStateToProps = (state) => ({
  modal: state.modal,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (name, options) => {
    dispatch(openModal(name, options));
  },
  closeModal: () => {
    dispatch(closeModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalWrapper);
