import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import API from '../../utils/api';

import { isValidDate } from '../../utils/functions';
import Form from './Form';
import Details from './Details';
// import TripParams from "../TripParams/";
// import TripPassengers from "../TripPassengers/";

import {
  setFromLocation,
  setToLocation,
  invertFromToLocation,
  toggleRT,
  addPassenger,
  editPassenger,
  removePassenger,
  setTripDate,
  setTripTime,
  setTripTimeMode,
  searchNewTrip,
  checkForm,
  toggleDoorToDoor,
  setSpecialFare,
  toggleSelectedPassenger,
} from './actions';

import { openModal } from '../ModalWrapper/actions';
import { setEntity } from '../Search/actions';
import { searchTravelers } from '../App/actions';

const source = axios.CancelToken.source();

class SearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.result ? 'from' : null,
      from: { value: '', places: null, searching: false },
      to: { value: '', places: null, searching: false },
      dates: {
        a: this.props.search.date.a.date
          ? moment(this.props.search.date.a.date).format('DD/MM/YYYY')
          : '',
        r: this.props.search.date.r.date
          ? moment(this.props.search.date.r.date).format('DD/MM/YYYY')
          : '',
      },
    };

    this.typingTimeout = null;
  }

  componentDidMount() {
    if (this.props.search.from.description || this.props.search.from.name) {
      this.setState({
        from: {
          value: this.props.search.from.description || this.props.search.from.name,
          places: null,
          searching: null,
        },
      });
    }
    if (this.props.search.to.description || this.props.search.to.name) {
      this.setState({
        to: {
          value: this.props.search.to.description || this.props.search.to.name,
          places: null,
          searching: null,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search.from !== this.props.search.from) {
      this.changeSearchLocation(
        this.props.search.from.description || this.props.search.from.name,
        'from',
        false,
      );
    }
    if (prevProps.search.to !== this.props.search.to) {
      this.changeSearchLocation(this.props.search.to.description || this.props.search.to.name, 'to', false);
    }
  }

  handleSubmit = () => {
    this.props.searchNewTrip();
  }

  setSelected = (field) => {
    this.setState({
      selected: field,
    });
  }

  changeSearchLocation = (value, field, search = true) => {
    if (!value) {
      this.setState({
        [field]: { value: '' },
      });
      return;
    }
    this.setState({
      [field]: { ...this.state[field], value },
    });
    clearTimeout(this.typingTimeout);
    if (value.length && search) {
      this.typingTimeout = setTimeout(async () => {
        this.setState({ [field]: { ...this.state[field], searching: true } });
        const places = await this.getPlaces(value);
        this.setState({
          [field]: { ...this.state[field], places, searching: false },
        });
      }, 300);
    } else {
      if (field === 'from' && this.state.selected === 'from') {
        this.setSelected('to');
        document.querySelector('#to').focus();
      } else if (field === 'to' && this.state.selected === 'to') {
        this.setSelected('out');
      }
      if (!search) {
        this.setState({
          [field]: { ...this.state[field], value, searching: false },
        });
      } else {
        this.setState({ [field]: { value, places: null } });
      }
      if (document.querySelector('#to') === document.activeElement) {
        document.querySelector('#to').addEventListener('keydown', (e) => {
          if (e.which === 13) document.querySelector('#to').blur();
        });
      }
    }
  }

  getPlaces = (q) => new Promise((resolve) => {
    const params = { q };
    if (!this.props.search.isDoorToDoor) {
      params.types = 'city,airport,station'; // search only stations and airports
    }
    API.get('/places', {
      cancelToken: source.token,
      params,
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;

        resolve([]);
      });
  })

  onBlur = (field) => {
    if (this.props.search[field] !== this.state[field].value) {
      this.setState({
        [field]: {
          ...this.state[field],
          value: this.props.search[field].description || this.props.search[field].name,
        },
      });
    }
  }

  changeDate = (date, trip) => {
    date = moment(date, ['DD-MM-YYYY', 'D/M/YYYY']); // We have to parse it first because if we get the raw input, it's gonna look like 20/08/2018 while the whole thing expects a moment object
    if (!isValidDate(date)) return;
    this.props.setTripDate(trip, date);
    if (trip === 'a') {
      // If the return date is set before the outward date, we need to change it
      if (
        this.props.search.date.r.date
        && this.props.search.date.r.date.isBefore(date)
      ) {
        this.props.setTripDate('r', null);
        this.props.toggleRT();
      }
    }
  }

  changeDateInput = (field, date) => {
    if (date) {
      this.setState({
        dates: {
          ...this.state.dates,
          [field]: date,
        },
      });
      this.changeDate(date, field);
    } else {
      this.setState({
        dates: {
          ...this.state.dates,
          [field]: moment(this.props.search.date[field].date).format(
            'DD/MM/YYYY',
          ),
        },
      });
    }
  }

  render() {
    const totalCards = this.props.search.passengers
      .filter((p) => p.selected)
      .reduce((i, passenger) => {
        if (passenger.cards) {
          return i + passenger.cards.length;
        } return i;
      }, 0);
    return (
      <div className="search-form">
        <Form
          {...this.props}
          setSelected={this.setSelected}
          selected={this.state.selected}
          changeSearchLocation={this.changeSearchLocation}
          to={this.state.to}
          dates={this.state.dates}
          from={this.state.from}
          handleSearchBlur={this.onBlur}
          changeDate={this.changeDate}
          changeDateInput={this.changeDateInput}
          totalCards={totalCards}
        />
        <Details
          {...this.props}
          selected={this.state.selected}
          changeSearchLocation={this.changeSearchLocation}
          to={this.state.to}
          from={this.state.from}
          changeDate={this.changeDate}
          changeDateInput={this.changeDateInput}
          co2={this.props.co2}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userPermissions: state.app.userPermissions,
  travelers: state.app.travelers,
  search: state.tripSearch,
  cardsList: state.app.cards,
  isFetchingCards: state.app.isFetchingCards,
  isFetchingAgeCategories: state.app.isFetchingAgeCategories,
  ageCategories: state.app.ageCategories,
  specialFares: state.app.user && state.app.user.special_fares,
  co2: state.app.stats && state.app.stats.co2,
  entities: state.app.entities,
  entityID: state.search.entityID,
  hasTravelersSearch: state.app.hasTravelersSearch,
  isMobileDevice: state.app.isMobileDevice,
  suggestedAddresses: state.app.user?.company?.config?.suggestedAddresses,
});

const mapDispatchToProps = (dispatch) => ({
  setFromLocation: (location) => dispatch(setFromLocation(location)),
  setToLocation: (location) => dispatch(setToLocation(location)),
  invertFromToLocation: () => dispatch(invertFromToLocation()),
  toggleRT: () => dispatch(toggleRT()),
  addPassenger: (passenger) => dispatch(addPassenger(passenger)),
  editPassenger: (passenger) => dispatch(editPassenger(passenger)),
  removePassenger: (index) => dispatch(removePassenger(index)),
  setTripDate: (trip, date) => dispatch(setTripDate(trip, date)),
  setTripTime: (trip, time) => dispatch(setTripTime(trip, time)),
  setTripTimeMode: (trip, mode) => dispatch(setTripTimeMode(trip, mode)),
  searchNewTrip: () => dispatch(searchNewTrip()),
  checkForm: () => dispatch(checkForm()),
  toggleDoorToDoor: () => dispatch(toggleDoorToDoor()),
  setSpecialFare: (specialFare) => dispatch(setSpecialFare(specialFare)),
  setEntity: (entity) => dispatch(setEntity(entity)),
  toggleSelectedPassenger: (index) => dispatch(toggleSelectedPassenger(index)),
  openModal: (options = null) => dispatch(openModal('Passenger', options)),
  searchTravelers: (query) => dispatch(searchTravelers(query)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchForm);
