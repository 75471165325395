import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from './Header';
import Results from './Results';
import Loader from '../../components/Loader';

const propTypes = {
  results: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
};

class CarRentalResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.loading) {
      return (
        <Loader
          sentences={[]}
          destination={this.props.search.returnLocation.location.city || this.props.search.returnLocation.location.name}
        />
      );
    }

    if (!this.props.results) {
      return <Redirect to="/" />;
    }

    return (
      <div className="hotel-results-container">
        <Header />
        <Results />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  results: state.carRentalResults.results,
  search: state.carRentalResults.search,
  loading: state.carRentalResults.loading,
});

export default connect(mapStateToProps)(CarRentalResults);

CarRentalResults.propTypes = propTypes;
