import { combineReducers } from 'redux';
import moment from 'moment';

import {
  SET_PLACE_LOCATION,
  SET_PLACE_RADIUS,
  SET_PLACE_TOUCHED,
  SET_ARRIVAL_DATE,
  SET_ARRIVAL_TOUCHED,
  SET_DEPARTURE_DATE,
  SET_DEPARTURE_TOUCHED,
  ADD_PASSENGER,
  EDIT_PASSENGER,
  REMOVE_PASSENGER,
  SET_FORM_ERRORS,
  FILL_USER_INFO,
  TOGGLE_SELECTED_PASSENGER,
  ADD_ROOM,
  REMOVE_ROOM,
  SET_NAME_CONTAINS,
  ADD_LAST_TRAVELERS,
  SET_ENTITY_TOUCHED,
  SET_SEARCH_PARAMS,
} from './constants';
import { SET_USER_CARDS_SUCCESS } from '../UserAccount/constants';

function placeReducer(state = { location: null, radius: 10 }, { type, location, radius }) {
  switch (type) {
    case SET_PLACE_LOCATION:
    case SET_SEARCH_PARAMS:
      return {
        ...state,
        location,
      };
    case SET_PLACE_RADIUS:
      return {
        ...state,
        radius,
      };
    default: return state;
  }
}

// Each trip gets a key (a & r), with the same reducer
function dateReducer(
  state = {
    arrival: moment(),
    departure: null,
  },
  action,
) {
  switch (action.type) {
    case SET_ARRIVAL_DATE:
      return {
        ...state,
        arrival: action.date,
      };
    case SET_DEPARTURE_DATE:
      return {
        ...state,
        departure: action.date,
      };
    case SET_SEARCH_PARAMS:
      return {
        arrival: action.date.arrival,
        departure: action.date.departure,
      };
    default:
      return state;
  }
}

function passengersReducer(
  state = [{
    id: 0, cards: null, selected: true, isSimple: true,
  }],
  action,
) {
  switch (action.type) {
    case ADD_PASSENGER: {
      const existingIndex = state.findIndex((p) => p.profile_id && p.profile_id === action.passenger.profile_id);
      if (existingIndex > -1 && action.passenger.profile_id) {
        state.splice(existingIndex, 1);
      }
      return [
        {
          id: nextPassengerUid(),
          selected: true,
          isSimple: true,
          ...action.passenger,
        },
        ...state,
      ];
    }
    case REMOVE_PASSENGER:
      return state.filter((passenger) => action.id !== passenger.id);
    case EDIT_PASSENGER:
      return state.map((passenger) => (passenger.id === action.passenger.id
        ? action.passenger
        : passenger));
    case ADD_LAST_TRAVELERS:
      action.travelers.forEach((traveler) => {
        state.push({
          id: nextPassengerUid(),
          ...traveler,
        });
      });
      return state;
    case FILL_USER_INFO:
      return state.map((passenger, index) => {
        if (index === 0) {
          passenger = {
            ...passenger,
            ...action.user,
            isSimple: false,
          };
        }

        return passenger;
      });
    case TOGGLE_SELECTED_PASSENGER:
      return state.map((passenger, i) => {
        if (i === action.index) {
          passenger = {
            ...passenger,
            selected: !passenger.selected,
          };
        }
        return passenger;
      });
    case SET_SEARCH_PARAMS:
      return action.passengers;
    case SET_USER_CARDS_SUCCESS:
      return state.map((passenger) => {
        if (action.uid === passenger.uid) {
          return {
            ...passenger,
            cards: action.cards,
          };
        }
        return passenger;
      });
    default:
      return state;
  }
}

function errorsReducer(state = null, action) {
  switch (action.type) {
    case SET_FORM_ERRORS:
      return action.errors;
    default:
      return state;
  }
}

function nameContainsReducer(state = null, action) {
  switch (action.type) {
    case SET_NAME_CONTAINS:
      return action.name_contains;
    default:
      return state;
  }
}

// We store a kind of uid for passenger ids.
// Starts at 0 because the passenger with id 0 is instancied in the reducer above
// And the functions starts with returning an incremented number
let uid = 0;

export function nextPassengerUid() {
  uid += 1;
  return uid;
}

function touchedReducer(state = {}, { type }) {
  switch (type) {
    case SET_PLACE_TOUCHED:
      return {
        ...state,
        from: true,
      };
    case SET_ARRIVAL_TOUCHED:
      return {
        ...state,
        arrival: true,
      };
    case SET_DEPARTURE_TOUCHED:
      return {
        ...state,
        departure: true,
      };
    case SET_ENTITY_TOUCHED:
      return {
        ...state,
        entityID: true,
      };
    default:
      return state;
  }
}

function roomsReducer(state = 1, action) {
  switch (action.type) {
    case ADD_ROOM:
      return state + 1;
    case REMOVE_ROOM:
      return state - 1;
    default:
      return state;
  }
}

const defaultActionsState = {
  userFilled: false,
  lastTravelersAdded: false,
};

function actionsReducer(state = defaultActionsState, action) {
  switch (action.type) {
    case FILL_USER_INFO:
      return {
        ...state,
        userFilled: true,
      };
    case ADD_LAST_TRAVELERS:
      return {
        ...state,
        lastTravelersAdded: true,
      };
    default: return state;
  }
}

function travelIDReducer(state = null, action) {
  switch (action.type) {
    case SET_SEARCH_PARAMS:
      return action.travelID;
    default:
      return state;
  }
}

const hotelSearchReducer = combineReducers({
  travelID: travelIDReducer,
  place: placeReducer,
  date: dateReducer,
  rooms: roomsReducer,
  passengers: passengersReducer,
  nameContains: nameContainsReducer,
  errors: errorsReducer,
  touched: touchedReducer,
  actions: actionsReducer,
});

export default hotelSearchReducer;
