// import { NotificationManager as Notif } from 'react-notifications';
import { push } from 'connected-react-router';
import {
  SET_OUTWARD_TRIP,
  SET_RETURN_TRIP,
  SET_SEARCHING_TRIP,
  SET_IS_ROUND_TRIP,
  SET_SEARCHED_TRIP,
  SET_PASSENGERS,
  RESET_RESULTS,
  FILL_PASSENGER_INFO,
  SET_SELECTED_OUTWARD_TRANSPORT_MODE,
  SET_SELECTED_RETURN_TRANSPORT_MODE,
  SET_SELECTED_OUTWARD_PROPOSAL,
  SET_SELECTED_RETURN_PROPOSAL,
  SET_SELECTED_OUTWARD_CABIN,
  SET_SELECTED_RETURN_CABIN,
  SET_SELECTED_OUTWARD_CHOICE,
  SET_SELECTED_RETURN_CHOICE,
  VALIDATE_OUTWARD_CHOICE,
  VALIDATE_RETURN_CHOICE,
  SET_TRAVELERS_REQUIRED_INFOS,
  LOADING_TRAVELERS_REQUIRED_INFOS,
  ADD_TO_CART_BEGIN,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_ERROR,
  TOGGLE_FILTER,
  CHANGE_OUTWARD_CHOICE,
  CHANGE_RETURN_CHOICE,
  SET_SORTING,
} from './constants';
import { searchTrip } from '../TripSearch/actions';
import API, { friendlyError } from '../../utils/api';
import { frenchDateToAPI } from '../../utils/converters';
import { formatCartResults, getPaymentInfos, updateCartAmount } from '../Cart/actions';
import { isCNAM } from '../../utils/functions';
// import { isCNAM } from '../../utils/functions';
// import { formatCartResults, getPaymentInfos, updateCartAmount } from '../Cart/actions';

export function setOutwardTrip(result) {
  return (dispatch, getState) => {
    dispatch(setSelectedTransportMode(result.transports[0].type, 'outwardTrip'));
    if (!getState().app.isMobileDevice) {
      const firstProposal = result.transports?.[0] && result.proposals.find((p) => p.type === result.transports[0].type);
      if (firstProposal) {
        dispatch(setSelectedProposal(firstProposal.id));
        if (firstProposal.choices.length) {
          dispatch(setSelectedCabin(firstProposal.choices[0].cabin));
          dispatch(setSelectedChoice(firstProposal.choices[0].id));
        }
      }
    }
    dispatch({
      type: SET_OUTWARD_TRIP,
      trips: result,
    });
  };
}

export function setReturnTrip(result) {
  return (dispatch, getState) => {
    if (result.transports?.[0]) {
      dispatch(setSelectedTransportMode(result.transports[0].type, 'returnTrip'));
    }
    dispatch({
      type: SET_RETURN_TRIP,
      trips: result,
    });
    if (!getState().app.isMobileDevice) {
      const firstProposal = result.transports?.[0] && result.proposals.find((p) => p.type === result.transports[0].type);
      if (firstProposal) {
        dispatch(setSelectedProposal(firstProposal.id));
        if (firstProposal.choices.length) {
          dispatch(setSelectedCabin(firstProposal.choices[0].cabin));
          dispatch(setSelectedChoice(firstProposal.choices[0].id));
        }
      }
    }
  };
}

export function setSearchingTrip() {
  return {
    type: SET_SEARCHING_TRIP,
  };
}

export function setIsRoundTrip() {
  return {
    type: SET_IS_ROUND_TRIP,
  };
}

export function setSearchedTrip(search) {
  return {
    type: SET_SEARCHED_TRIP,
    search,
  };
}

export function setTripPassengers(passengers) {
  return {
    type: SET_PASSENGERS,
    passengers,
  };
}

export function resetResults() {
  return {
    type: RESET_RESULTS,
  };
}

export function fillPassenger(id, passenger) {
  return {
    type: FILL_PASSENGER_INFO,
    id,
    passenger,
  };
}

export function setSelectedTransportMode(selectedTransportMode, trip = null) {
  return (dispatch, getState) => {
    const currentTrip = trip ?? getState().results.currentTrip;
    const action = {
      type: currentTrip === 'outwardTrip' ? SET_SELECTED_OUTWARD_TRANSPORT_MODE : SET_SELECTED_RETURN_TRANSPORT_MODE,
      selectedTransportMode,
    };
    dispatch(action);
  };
}

export function setSelectedProposal(selectedProposal) {
  return (dispatch, getState) => {
    const { currentTrip } = getState().results;
    const { selectedTransportMode } = getState().results[currentTrip];
    const action = {
      type: currentTrip === 'outwardTrip' ? SET_SELECTED_OUTWARD_PROPOSAL : SET_SELECTED_RETURN_PROPOSAL,
      selectedProposal,
      selectedTransportMode,
    };
    dispatch(action);
  };
}

export function setSelectedCabin(selectedCabin) {
  return (dispatch, getState) => {
    const { currentTrip } = getState().results;
    const { selectedTransportMode } = getState().results[currentTrip];
    const action = {
      type: currentTrip === 'outwardTrip' ? SET_SELECTED_OUTWARD_CABIN : SET_SELECTED_RETURN_CABIN,
      selectedCabin,
      selectedTransportMode,
    };
    dispatch(action);
  };
}

export function setSelectedChoice(selectedChoice) {
  return (dispatch, getState) => {
    const { currentTrip } = getState().results;
    const { selectedTransportMode } = getState().results[currentTrip];
    const action = {
      type: currentTrip === 'outwardTrip' ? SET_SELECTED_OUTWARD_CHOICE : SET_SELECTED_RETURN_CHOICE,
      selectedChoice,
      selectedTransportMode,
    };
    dispatch(action);
  };
}

export function validateChoice() {
  return (dispatch, getState) => {
    const { currentTrip, isRoundTrip } = getState().results;
    const {
      selectedTransportMode, selectedChoice,
    } = getState().results[currentTrip];
    const choiceID = selectedChoice[selectedTransportMode];
    const action = {
      type: currentTrip === 'outwardTrip' ? VALIDATE_OUTWARD_CHOICE : VALIDATE_RETURN_CHOICE,
    };
    dispatch(action);

    if (isRoundTrip && currentTrip === 'outwardTrip') {
      dispatch(searchTrip('r', choiceID));
    } else if (!isRoundTrip || currentTrip === 'returnTrip') {
      dispatch(getTravelersRequiredInfos());
    }
  };
}

export function getTravelersRequiredInfos() {
  return (dispatch, getState) => {
    if (!getState().app.user) {
      dispatch({ type: SET_TRAVELERS_REQUIRED_INFOS, requiredInfos: 'NOT_LOGGED_IN' });
      return;
    }
    const {
      outwardTrip, returnTrip, isRoundTrip,
    } = getState().results;
    const outwardChoice = outwardTrip.selectedChoice[outwardTrip.selectedTransportMode];
    const returnChoice = isRoundTrip ? returnTrip.selectedChoice[returnTrip.selectedTransportMode] : null;

    const choiceIDs = [outwardChoice];
    if (returnChoice) {
      choiceIDs.push(returnChoice);
    }

    dispatch({ type: LOADING_TRAVELERS_REQUIRED_INFOS });

    const travelers = outwardTrip.travelers.map((t) => {
      t.travelerID = t.id;
      return t;
    });

    API.post('ticket/travelerForm', {
      choiceIDs,
      travelers,
    }).then((res) => {
      dispatch({
        type: SET_TRAVELERS_REQUIRED_INFOS,
        requiredInfos: res.data.result.travelerForms,
      });
    });
    // Do not need this because we are catching everything localy now. See api.js interceptors
    /*
    .catch((resp) => {
      const message = friendlyError(resp);
      Notif.warning(message, '', 0);
    });
    */
  };
}

export function addSelectedOffersToCart(travelers) {
  return (dispatch, getState) => {
    const { results } = getState();

    const offers = {
      travelers: Object.values(travelers).map((t, i) => {
        t.travelerID = Object.keys(travelers)[i];
        t.birthdate = t.birthdate === 'xx/xx/xxxx' ? t.birthdate : frenchDateToAPI(t.birthdate);
        return t;
      }),
      choiceIDs: [],
    };

    const getSelectedOffersFromTrip = (trip) => {
      const { selectedChoice, selectedProposal, selectedTransportMode } = trip;
      const choice = trip.proposals.find((p) => p.id === selectedProposal[selectedTransportMode])?.choices.find((c) => c.id === selectedChoice[selectedTransportMode]);
      offers.choiceIDs.push(choice.id);
    };

    getSelectedOffersFromTrip(results.outwardTrip);

    if (results.isRoundTrip) {
      getSelectedOffersFromTrip(results.returnTrip);
    }

    dispatch(addToCart(offers));
  };
}

function addToCart(cartBody) {
  return (dispatch, getState) => {
    dispatch({ type: ADD_TO_CART_BEGIN });
    dispatch(getPaymentInfos());
    const { user } = getState().app;
    if (getState().results.search.travelID) {
      cartBody.travelID = getState().results.search.travelID;
    }
    if (user?.perms?.includes('travel_agent')) {
      cartBody.customer_id = user.company_id;
    }
    if (isCNAM()) {
      cartBody.subsidy_number = getState().search.subsidy != null ? getState().search.subsidy.number : '';
    }

    // If an entity is selected, we add it to the params
    if (getState().search.entityID) {
      cartBody.entity_id = getState().search.entityID;
    }
    return API.post('/cart/add', cartBody)
      .then((response) => {
        const cart = formatCartResults(response.data.result.cart);
        dispatch({ type: ADD_TO_CART_SUCCESS, cart });
        dispatch(push('/cart'));
        dispatch(updateCartAmount());
        // We have to use a timeout with a random duration here, which is quite dangerous.
        // This is because of our shitty react-router-transition which doesn't unmount the component until our fancy "transition" is finished
        // Since this is a "springy" fancy transition, we have no way of knowing how long it'll take or when it is done.
        setTimeout(() => dispatch(resetResults()), 2500);
      })
      .catch((resp) => {
        const message = friendlyError(resp);
        // Do not need this because we are catching everything localy now. See api.js interceptors
        /*
        Notif.warning(message, '', 0);
        */
        let errorDetail = message;
        if (resp != null && resp.data != null && resp.data.result != null && resp.data.result.offer_errors != null) {
          errorDetail = resp.data.result.offer_errors;
        }
        dispatch({ type: ADD_TO_CART_ERROR, error: errorDetail });
      });
  };
}

export function toggleFilter(filter) {
  return {
    type: TOGGLE_FILTER,
    filter,
  };
}

export function changeOutwardChoice() {
  return {
    type: CHANGE_OUTWARD_CHOICE,
  };
}

export function changeReturnChoice() {
  return {
    type: CHANGE_RETURN_CHOICE,
  };
}

export function setSorting(sorting) {
  return {
    type: SET_SORTING,
    sorting,
  };
}
