import moment from 'moment';

// Takes a whole cart and returns only the selected subsets
export function getItemSelectedSubsets(item) {
  if (!item.subsets) { return []; }

  return item.subsets.filter((subset) => subset.bookable && subset.selected);
}

// Extract selected ticket_ids to be booked
export function getCartSelectedTickets(items) {
  // we remove disabled items
  items = items.filter((item) => !isCartItemDisabled(item));

  // Each cartItem has n subsets
  // Each subset has n tickets_ids
  // We flatten them all to extract the ids
  const tickets = items.reduce((array, item) => {
    getItemSelectedSubsets(item).forEach((item) => {
      if (item.ticket_ids != null) {
        item.ticket_ids.forEach((ticketId) => array.push(ticketId));
      }
    });

    return array;
  }, []);

  return tickets;
}

// Computes a "treep" (cart item) total price, depending on selected subsets
export function getCartItemPrice(item) {
  // We include the "disabled" treeps in this so their total shows up at the bottom of the card
  return getItemSelectedSubsets(item).reduce((totalPrice, subset) => {
    totalPrice += getSubsetPrice(subset);
    return totalPrice;
  }, 0);
}

// Computes the subset price, multiplies ticket price by the number of tickets needed
export function getSubsetPrice(subset) {
  let total = 0.0;

  if (subset.tickets == null) {
    return total;
  }

  subset.tickets.forEach((ticket) => {
    if (isMultipleTicket(ticket)) { total += ticket.travelers.length * ticket.price; } else { total += ticket.price; }
  });

  return total;
}

// Checks wether the trip is expired
export function isCartItemDisabled(cartItem) {
  return moment(cartItem.ticket_time_limit).isSameOrBefore();
}

// Determines wether a ticket is a multiple ticket (for n persons) or a solo ticket
export function isMultipleTicket(ticket) {
  const maxTravellers = ticket.type === 'driver' ? 4 : 1;
  return ticket.travelers_count > maxTravellers;
}

export function getTravelIDsFromTicketIDs(cart, ticketIDs) {
  const travelIDs = [];
  if (cart) {
    cart.forEach((cartItem) => {
      const mainSubsets = cartItem.subsets.filter((subset) => subset.is_main_section);
      if (mainSubsets.some((subset) => subset.tickets.some((ticket) => ticketIDs.includes(ticket.id)))) {
        if (!travelIDs.includes(cartItem.travelID)) {
          travelIDs.push(cartItem.travelID);
        }
      }
    });
  }
  return travelIDs;
}

export function getTicketUIDsFromTicketIDs(cart, ticketIDs) {
  const ticketUIDs = [];
  if (cart) {
    cart.forEach((cartItem) => {
      const mainSubsets = cartItem.subsets.filter((subset) => subset.is_main_section);
      mainSubsets.forEach((subset) => subset.tickets.forEach((ticket) => {
        if (ticketIDs.includes(ticket.id) && !ticketUIDs.includes(ticket.uid)) {
          ticketUIDs.push(ticket.uid);
        }
      }));
    });
  }
  return ticketUIDs;
}

export function getCartContentIDWithSameGroupID(id, content) {
  const IDs = [id];
  const groupID = content.find((c) => c.uid === id).subsets.find((s) => s.is_main_section).tickets[0].group_id;
  content.filter((c) => c.uid !== id).map((c) => {
    if (c.subsets.find((s) => s.is_main_section).tickets[0].group_id === groupID) {
      IDs.push(c.uid);
    }
    return null;
  });
  return IDs;
}
