import React from 'react';
import PropTypes from 'prop-types';

export default class Loader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      component: null,
    };
  }

  componentDidMount = () => {
    document.body.classList.add('modal-opened');
    document.addEventListener('keyup', this.handleKeyup);
    if (!this.props.modal.options) {
      this.props.modal.options = {};
    }
    // eslint-disable-next-line global-require,import/no-dynamic-require
    Promise.resolve().then(() => require(`./../Modals/${this.props.modal.name}.jsx`)).then((Component) => {
      this.setState({
        component: (
          // eslint-disable-next-line react/jsx-pascal-case
          <Component.default
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
            {...this.props.modal.options}
            shake={this.props.shake}
          />
        ),
      });
    });
  }

  handleKeyup = (evt) => {
    if (this.props.noClose) {
      return;
    }
    if (evt.key === 'Escape' || evt.keyCode === 27) {
      this.props.closeModal();
    }
  }

  componentWillUnmount = () => {
    document.body.classList.remove('modal-opened');
    document.removeEventListener('keyup', this.handleKeyup);
  }

  render() {
    if (!this.state.component) return null;

    return this.state.component;
  }
}

Loader.propTypes = {
  modal: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
