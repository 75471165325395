import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// Components
import Account from './Steps/Account';
import Number from './Steps/Number';
import Passengers from './Steps/Passengers';
import Search from './Steps/Search';
import PageContainer from '../../components/PageContainer';
// Actions
import {
  setPassengers, searchNewTrip, setTripDate, setTripTime, setFromLocation, toggleRT, checkUserInfo, setCorsicanFaresForPassengers,
} from '../TripSearch/actions';
import { setSubsidyNumber } from './actions';
import { openModal, closeModal } from '../ModalWrapper/actions';
import { activateEmailWithToken } from '../App/actions';
import { RESET_PASSWORD_TOKEN_PATH, ACTIVATE_USER_TOKEN_PATH } from '../App/constants';

class StepSearch extends Component {
  constructor(props) {
    super(props);
    if (!this.props.isRT) {
      this.props.toggleRT();
    }

    if (this.props.match.path === RESET_PASSWORD_TOKEN_PATH) {
      this.props.openPasswordModal(this.props.match.params.token);
    }
    if (this.props.match.path === ACTIVATE_USER_TOKEN_PATH) {
      // Try to activate account if error open 'token renewal' popup
      this.props
        .activateEmailWithToken(this.props.match.params.token)
        .catch((err) => {
          if (err.msg != null) {
            this.props.openAskTokenRenewalModal(
              this.props.match.params.token,
              err.msg,
            );
          } else {
            this.props.openAskTokenRenewalModal(
              this.props.match.params.token,
              err,
            );
          }
        });
    }

    if (this.props.user && this.props.subsidy && this.props.subsidy.loading === false && this.props.subsidy.number !== undefined && !this.props.error) {
      this.state = {
        active: 'search',
      };
    } else if (this.props.user) {
      this.state = {
        active: 'number',
      };
    } else {
      this.state = {
        active: 'account',
      };
    }
  }

  componentDidMount() {
    this.props.checkUserInfo(this.props.user);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.props.checkUserInfo(this.props.user);
    }
    if (prevProps.user == null && this.props.user != null) {
      this.props.closeModal();
    }

    if ((!prevProps.user && this.props.user) || (this.props.user && this.props.token !== prevProps.token)) {
      this.setActive('number');
    }
    if (prevProps.user && !this.props.user) {
      this.setActive('account');
    }
    if (prevProps.subsidy !== null && prevProps.subsidy.loading === true && this.props.subsidy !== null && this.props.subsidy.loading === false && this.props.subsidy.number !== undefined) {
      this.setActive('passengers');
    }
  }

  setActive = (active) => {
    this.setState({ active });
  }

  handleSubmit = (values) => {
    const {
      setTripDate, setTripTime, setFromLocation, searchNewTrip, setCorsicanFaresForPassengers, setPassengers, passengers,
    } = this.props;
    setTripDate('a', moment(values.date.a.date));
    setTripDate('r', moment(values.date.r.date));
    setTripTime('a', values.date.a.time);
    setTripTime('r', values.date.r.time);
    setFromLocation(values.from);
    setCorsicanFaresForPassengers(values.corsican_fares);
    const ps = passengers.map((p, i) => {
      p.corsican_fares_number = values.corsican_fares ? values.travelers[i].corsican_fares_number : null;
      return p;
    });
    setPassengers(ps);
    searchNewTrip();
  }

  render() {
    const { active } = this.state;
    const {
      passengers, subsidy, cardtypes, setPassengers, search, setSubsidyNumber, openModal,
    } = this.props;
    return (
      <PageContainer>
        <div className="step-search">
          <div className="row">
            <div className="col col-9">
              <h1 className="title">Le Service Trajet Santé de l'Assurance Maladie</h1>
              <p className="instructions">
                L'Assurance Maladie prend en charge votre transport pour soins, au tarif le moins cher (ou résident corse) et conforme à la notification d'accord que vous avez reçue.
                <br />
                Ce service est réservé aux assurés rattachés aux caisses participant à l’expérimentation dans les départements suivants : Charente Maritime, Corse du Sud et Martinique.
              </p>
            </div>
          </div>
          <Account active={active === 'account'} setActive={this.setActive} setSubsidyNumber={setSubsidyNumber} history={this.props.history} pathname={this.props.history.location.pathname} />
          <Number active={active === 'number'} setActive={this.setActive} subsidy={subsidy} openModal={openModal} />
          <Passengers active={active === 'passengers'} nbAccompanying={subsidy?.searchRequest?.nbAccompanying} passed={active === 'search'} setActive={this.setActive} passengers={passengers} cardtypes={cardtypes} handleSubmit={setPassengers} />
          <Search active={active === 'search'} setActive={() => this.setActive('search')} search={search} handleSubmit={this.handleSubmit} subsidy={subsidy} passengers={passengers} />
        </div>
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user,
  token: state.app.token,
  subsidy: state.search.subsidy,
  passengers: state.tripSearch.passengers,
  cardtypes: state.app.cards,
  search: state.tripSearch,
  isRT: state.tripSearch.isRT,
});

const mapDispatchToProps = (dispatch) => ({
  setPassengers: (passenger) => dispatch(setPassengers(passenger)),
  searchNewTrip: () => dispatch(searchNewTrip()),
  setCorsicanFaresForPassengers: (withCorsicanFares) => dispatch(setCorsicanFaresForPassengers(withCorsicanFares)),
  setTripDate: (trip, date) => dispatch(setTripDate(trip, date)),
  setTripTime: (trip, time) => dispatch(setTripTime(trip, time)),
  setFromLocation: (location) => dispatch(setFromLocation(location)),
  toggleRT: () => dispatch(toggleRT()),
  setSubsidyNumber: (number) => dispatch(setSubsidyNumber(number)),
  openModal: (modal, options) => dispatch(openModal(modal, options)),
  closeModal: () => dispatch(closeModal()),
  openPasswordModal: (token) => dispatch(openModal('NewPasswordWithToken', { token })),
  openAskTokenRenewalModal: (token, error) => dispatch(openModal('AskTokenRenewal', { token, error })),
  activateEmailWithToken: (token) => dispatch(activateEmailWithToken(token)),
  checkUserInfo: (user) => dispatch(checkUserInfo(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepSearch);
