import React from 'react';
import PropTypes from 'prop-types';

import Container from '../../../components/SearchDetails/Container';
import StarsInput from '../../../components/StarsInput';

const Filters = ({
  stars, setStars, breakfast, toggleBreakfast, nameContains, setNameContains, accessible, toggleAccessible, isMobileDevice, field,
}) => (
  <Container title="Selectionnez l'hotel, la chaine, ses critères" isMobileDevice={isMobileDevice} label={field}>
    <div className="hotel-search-details__content">
      <div className="hotel-search-comfort m-top p-top">
        <label htmlFor="name-contains">
          {window.i18('HOTEL_NAME')}
          {' '}
          <span>{window.i18('CONTAINS')}</span>
        </label>
        <span className="hotel-name-contains common-input" id="name-contains">
          <input
            type="text"
            placeholder="Hotel de la gare"
            onChange={(e) => setNameContains(e.target.value)}
            value={nameContains || ''}
          />
        </span>
      </div>
      <div className="hotel-search-comfort m-top p-top">
        <label htmlFor="comfort">
          {window.i18('COMFORT')}
          <span>{window.i18('EQUALS_AT_LEAST')}</span>
        </label>
        <StarsInput stars={stars} onChange={setStars} />
      </div>
      <div className="hotel-search-comfort m-top p-top">
        <label htmlFor="breakfast">
          {window.i18('BREAKFAST')}
          <span>{window.i18('INCLUDED')}</span>
        </label>
        <label className="common-checkbox">
          <input type="checkbox" checked={breakfast} onChange={toggleBreakfast} />
          <span className="checkmark" />
        </label>
      </div>
      <div className="hotel-search-comfort m-top p-top">
        <label htmlFor="breakfast">
          {window.i18('ACCESSIBLE')}
          <span>{window.i18('TO_PRM')}</span>
        </label>
        <label className="common-checkbox">
          <input type="checkbox" checked={accessible} onChange={toggleAccessible} />
          <span className="checkmark" />
        </label>
      </div>
    </div>
  </Container>
);

Filters.propTypes = {
  stars: PropTypes.number.isRequired,
  setStars: PropTypes.func.isRequired,
};

export default Filters;
