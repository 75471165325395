/* eslint-disable global-require */
import React from 'react';

const icons = {
  common: require('../../../images/icons/transport/address.png'),
  address: require('../../../images/icons/transport/address.png'),
  administrative_region: require('../../../images/icons/transport/administrative_region.png'),
  airport: require('../../../images/icons/transport/airport.png'),
  stop_point: require('../../../images/icons/transport/stop_point.png'),
};

const AutoCompleteItems = (props) => {
  if (props.closed) return null;

  let items = [];

  if (!props.loading && props.items) {
    items = props.items.map((item) => (
      <li key={item.ID} className="common-text">
        <a onMouseDown={() => props.onClick(item)}>
          <img src={getIcon(item.type)} className="icon" alt={item.description} />
          <span>{item.description}</span>
        </a>
      </li>
    )) || (
      <li className="common-text no-result">
        <span>{window.i18('NO_RESULT')}</span>
      </li>
    );
  }

  return (
    <ul className="autocompletion-wrapper">
      {props.loading && (
      <li className="common-text loading">
        <span />
      </li>
      )}
      {!props.loading && props.items
          && !(props.items || props.items.length) && (
            <li className="common-text no-result">
              <span>{window.i18('NO_RESULT')}</span>
            </li>
      )}
      {items}
    </ul>
  );
};

function getIcon(iconName) {
  return icons[iconName] ? icons[iconName] : icons.common;
}

export default AutoCompleteItems;
