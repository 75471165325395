import React from 'react';
import classNames from 'classnames';
import vehicle from '../../../../images/vehicle.png';
import TicketTagsTooltip from '../../../components/TicketTagsTooltip';
import { getSvgIcon } from '../../../utils/icons';

class CarRentalsMobile extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.selectedMarker !== this.props.selectedMarker && this.props.selectedMarker) {
      const selected = document.getElementById(this.props.selectedMarker);
      selected.scrollIntoView({
        behavior: 'instant',
        block: 'center',
      });
    }
  }

  render() {
    const {
      results, setSelectedResult, selectedResult, selectedMarker, displayMap,
    } = this.props;

    return (
      <>
        <h3>
          {results.length}
          {' '}
          {window.i18('VEHICLE')}
          {results.length > 1 ? 's' : ''}
          {' '}
          {window.i18('FOUND')}
          {results.length > 1 ? 's' : ''}
        </h3>
        <ul className="car-sm-results-list">
          {results.map((res) => (
            <li className={classNames('car-rental-item', 'car-sm-results-list__item', { selected: displayMap && selectedMarker === res.id })} id={res.id} key={res.id}>
              <div className="car-sm-preview">
                <img src={res.pix_url ? res.pix_url : vehicle} alt={window.i18('VEHICLE')} />
                <div className="car-rental-item__right">
                  <p className="car-rental-item__name">{res.car}</p>
                  {res.tags?.includes('valet') && (
                    <p className="car-rental-item__valet">
                      <span>{window.i18('VALET_SERVICE')}</span>
                      {' '}
                      {getSvgIcon('use-male', 'ui')}
                    </p>
                  )}
                </div>
              </div>
              <div className="car-sm-details">
                <p className="car-rental-item__agency">
                  {window.i18('AGENCY')}
                  {' '}
                  {res.pickup_place.address.city}
                  {' '}
                  {res.pickup_place.name}
                </p>
                <div className="car-sm-details__footer">
                  <p className="car-rental-item__distance">
                    {res.renter?.logo ? (
                      <p className="car-rental-item__renter"><img src={`https://img.thetreep.com/renters/${res.renter.logo}`} alt={res.renter?.name} /></p>
                    ) : (
                      <p className="car-rental-item__renter">{res.renter?.name ?? ''}</p>
                    )}
                    {res.distance > 1000 ? `${(res.distance / 1000).toFixed(1)}km` : `${res.distance}m`}
                  </p>
                  <div className="common-tooltip__parent">
                    <p className="car_tarif">
                      {window.i18('RATE')}
                      {' '}
                      :
                      {' '}
                    </p>
                    <button
                      className={
                        classNames(
                          'hotel-price-btn',
                          { selected: res.id === selectedResult.id && !displayMap },
                          { 'hotel-price-btn--blue': res.tags && res.tags.indexOf('negociated-fare') > -1 },
                          { 'hotel-price-btn--green': res.tags && res.tags.indexOf('compliant') > -1 },
                        )
                      }
                      onClick={() => setSelectedResult(res.id)}
                    >
                      {res.priceByDay.total / 100}
                      €
                    </button>
                    <TicketTagsTooltip tags={res.tags} />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </>
    );
  }
}

export default CarRentalsMobile;
