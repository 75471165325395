import { spring } from 'react-router-transition';
import { isMobileDevice } from './functions';

export function mapStylesTranslateY(styles) {
  if (!isMobileDevice()) {
    return {
      opacity: styles.opacity,
      transform: `translate3d(0,${styles.translateY}px,0)`,
    };
  }
  return {
    opacity: styles.opacity,
    transform: 'none',
  };
}

export const bounceTranlateYTransition = {
  atEnter: {
    opacity: 0,
    translateY: 20,
  },
  atLeave: {
    opacity: bounce(0),
    translateY: bounce(20),
  },
  atActive: {
    opacity: bounce(1),
    translateY: bounce(0),
  },
};

export function mapStylesTranslateX(styles) {
  if (!isMobileDevice()) {
    return {
      opacity: styles.opacity,
      transform: `translate3d(${styles.translateX}px,0,0)`,
    };
  }
  return {
    opacity: styles.opacity,
    transform: 'none',
  };
}

export const bounceTranslateXTransition = {
  atEnter: {
    opacity: 0,
    translateX: 20,
  },
  atLeave: {
    opacity: bounce(0),
    translateX: bounce(20),
  },
  atActive: {
    opacity: bounce(1),
    translateX: bounce(0),
  },
};

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 200,
    damping: 24,
  });
}
