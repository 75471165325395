/* eslint-disable prefer-destructuring */
import { capitalize } from './functions';

// We're matching the transport name to display the correct transport user state
export function transporName(input) {
  switch (input) {
    case 'walking':
      return window.i18('WALKING');
    case 'transfer':
      return window.i18('TRANSFER');
    case 'waiting':
      return window.i18('WAITING');
    case 'bike':
      return window.i18('TO_BIKE');
    case 'bicycling':
      return window.i18('TO_BIKE');
    case 'urban_skating':
      return window.i18('RIDE');
    case 'urban_e_skating':
      return window.i18('RIDE');
    case 'scooter':
      return window.i18('DRIVE');
    case 'e_scooter':
      return window.i18('DRIVE');
    case 'Bus':
      return window.i18('BUS');
    case 'RER':
      return window.i18('RER');
    case 'Tramway':
      return window.i18('TRAMWAY');
    default:
      return window.i18('GO');
  }
}

// We're matching the subset label to a french one
export function subsetTransportName(input) {
  switch (input) {
    case 'driver':
      return window.i18('TAXI');
    case 'cab':
      return window.i18('TAXI');
    case 'walking':
      return window.i18('WALK');
    case 'street_network':
      return window.i18('PUBLIC_TRANSPORT');
    case 'public_transport':
      return window.i18('PUBLIC_TRANSPORT');
    default:
      return window.i18('SECTION');
  }
}

// Matches an offer label (TGD, EJ, AF...) & normalizes it
// Currently only trains with 3 letters starting with TG get a special treatment
export function offerLabel(input) {
  if (!input) { return; }

  if (input.length === 3 && input.substr(0, 2) === 'TG') { return 'TGV'; }

  if (input === 'tgv') {
    return input.toUpperCase();
  }

  if (input === 'thalys' || input === 'eurostar') {
    return input.replace(/\b\w/g, (l) => l.toUpperCase());
  }
  if (input === 'long_train') {
    return window.i18('LONG_TRAIN');
  }
  if (input === 'bus') {
    return window.i18('SHUTTLE');
  }

  return capitalize(window.i18('TRAIN'));
}

// We're matching the commercial name of a section to match a next stop name
export function nextStopName(input) {
  switch (input) {
    case 'RER':
      return window.i18('THE_STATION');
    case 'Bus':
      return window.i18('BUS_STOP');
    case 'Tramway':
      return window.i18('THE_STATION');
    default:
      return '';
  }
}

// We're returning the commercial name of a section + it's line to display 'Bus 91' for example
export function transportWithLine(input, line) {
  if (line === 'ORLYVAL') { return 'ORLYVAL '; } if (line === 'ORLYBUS') { return 'ORLYBUS'; } return `${input} ${line}`;
}

// We display a title depending on the green score
export function displayGreenScoreTitle(input) {
  const greenScoreTitle = {};
  switch (true) {
    case input <= 25:
      greenScoreTitle.upperTitle = window.i18('BAD');
      greenScoreTitle.lowerTitle = window.i18('CARBON_FOOTPRINT');
      return greenScoreTitle;
    case input >= 25 && input <= 50:
      greenScoreTitle.upperTitle = window.i18('POOR_FOOTPRINT')()[0];
      greenScoreTitle.lowerTitle = window.i18('POOR_FOOTPRINT')()[1];
      return greenScoreTitle;
    case input >= 50 && input <= 75:
      greenScoreTitle.upperTitle = window.i18('GOOD');
      greenScoreTitle.lowerTitle = window.i18('CARBON_FOOTPRINT');
      return greenScoreTitle;
    case input >= 75 && input <= 100:
      greenScoreTitle.upperTitle = window.i18('GREAT');
      greenScoreTitle.lowerTitle = window.i18('CARBON_FOOTPRINT');
      return greenScoreTitle;
    default:
      greenScoreTitle.upperTitle = window.i18('FOOTPRINT');
      greenScoreTitle.lowerTitle = window.i18('CARBON');
      return greenScoreTitle;
  }
}

// We're matching the commercial name of a section to match a next stop name
export function ticketStatus(input) {
  switch (input) {
    case 'pending_cancel':
      return window.i18('PENDING_CANCEL');
    case 'cancelled':
      return window.i18('CANCELLED');
    case 'error':
      return window.i18('ERRORED');
    default:
      return null;
  }
}
