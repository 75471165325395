import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { TweenLite } from 'gsap';
// Component
import Desc from './Desc';
import QuantityInput from '../../../../components/QuantityInput';
import TravelersDistribution from './TravelersDistribution';
import TicketTagsTooltip from '../../../../components/TicketTagsTooltip';
import { displayOfferInfos, travelerValidationSchema } from '../../functions';
// Function

const propTypes = {
  selectedResult: PropTypes.shape({}).isRequired,
  selected: PropTypes.shape({}).isRequired,
  search: PropTypes.shape({}).isRequired,
  travelers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fillTraveler: PropTypes.func.isRequired,
  addingToCart: PropTypes.bool.isRequired,
  addToCart: PropTypes.func.isRequired,
  assignTravelerToOffer: PropTypes.func.isRequired,
  filters: PropTypes.shape({}).isRequired,
};

export default class HotelDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nbDisplayedOffers: 5,
    };
    this.block = React.createRef();
    this.distribution = React.createRef();
    this.button = React.createRef();
  }

  componentDidMount() {
    if (this.props.selectedResult.travelersDistribution && this.block.current && this.distribution.current && this.button.current) {
      TweenLite.set(this.block.current, { xPercent: -100, x: -16 });
      TweenLite.set(this.distribution.current, { opacity: 1 });
      TweenLite.set(this.button.current, { opacity: 0 });
    } else if (!this.props.selectedResult.travelersDistribution && this.block.current && this.distribution.current && this.button.current) {
      TweenLite.set(this.block.current, { xPercent: 0, x: 0 });
      TweenLite.set(this.button.current, { opacity: 1 });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(prevProps) {
    if (prevProps.selectedResult.travelersDistribution && !this.props.selectedResult.travelersDistribution && this.block.current && this.distribution.current && this.button.current) {
      TweenLite.to(this.block.current, 0.5, { xPercent: -100, x: -16 });
      TweenLite.to(this.distribution.current, 0.5, { opacity: 1 });
      TweenLite.set(this.button.current, { opacity: 0 });
    } else if (!prevProps.selectedResult.travelersDistribution && this.props.selectedResult.travelersDistribution && this.block.current && this.distribution.current && this.button.current) {
      TweenLite.to(this.block.current, 0.5, { xPercent: 0, x: 0 });
      TweenLite.to(this.distribution.current, 0.5, { opacity: 0 });
      TweenLite.set(this.button.current, { opacity: 1 });
    }
  }

  travelersDistribution = () => {
    if (this.props.selectedResult.selectedOffers.length === 1) {
      const offerId = this.props.selectedResult.selectedOffers[0].id;
      this.props.travelers.map((traveler, i) => this.props.assignTravelerToOffer(offerId, traveler.id.toString(), i));
    }
    this.props.toggleTravelersDistribution();
  }

  render() {
    const {
      selected,
      selectedResult,
      search,
      addSelectedOffer,
      removeSelectedOffer,
      openTravelerModal,
      travelers,
      fillTraveler,
      addToCart,
      addingToCart,
      assignTravelerToOffer,
      filters,
      user,
    } = this.props;
    const displayedOffers = selectedResult.details
      && selectedResult.details.room_offers.filter((offer) => {
        const hasBreakfast = !filters.breakfast || offer.breakfast_included;
        const hasLoyalty = !filters.loyaltyEnabled || offer.tags.includes('loyalty-enabled');
        return hasBreakfast && hasLoyalty;
      }).slice(
        0,
        this.state.nbDisplayedOffers,
      );
    const departure = moment(search.date.departure);
    const arrival = moment(search.date.arrival);
    const nightsCount = departure.diff(arrival, 'days');
    const { selectedOffers } = selectedResult;
    const totalOccupancy = selectedOffers.reduce((total, offer) => offer.maxOccupancy + total, 0);
    const canAddToCart = () => {
      // We check if all the travelers' informations are filled
      const filledTravelers = travelers.filter((traveler) => travelerValidationSchema().isValidSync(traveler));
      const isAllFilled = filledTravelers.length === travelers.length;
      // We check if all the travelers are assigned to an offer
      const assignedTravelers = travelers.filter((traveler) => selectedOffers.find((offer) => offer.travelers.find((t) => t === traveler.id.toString())));
      const isAllAssigned = assignedTravelers.length === travelers.length;
      // And we check if all our selected offers are used by our travelers
      const usedOffers = selectedOffers.filter((offer) => offer.travelers.filter((t) => t !== null).length);
      const isAllUsed = usedOffers.length === selectedOffers.length;
      if (user && isAllFilled && isAllAssigned && isAllUsed) {
        return true;
      }
      return false;
    };

    if (!selected) {
      return null;
    }

    return (
      <>
        <div className="hotel-details" ref={this.block}>
          <div className="bordered-block">
            <Desc selected={selected} selectedResult={selectedResult} />
            {selectedResult.loading ? null : (
              <div className="hotel-offers">
                <div className="rooms-distribution" ref={this.distribution}>
                  <div className="bordered-block">
                    <TravelersDistribution
                      travelers={travelers}
                      openTravelerModal={openTravelerModal}
                      fillTraveler={fillTraveler}
                      selectedResult={selectedResult}
                      nightsCount={nightsCount}
                      assignTravelerToOffer={assignTravelerToOffer}
                    />
                  </div>
                  {!user && <p>{window.i18('LOGIN_IN_TO_ADD_HOTEL_TO_CART')}</p>}
                  <button
                    type="button"
                    className={classNames('button-main', 'm-top', { loading: addingToCart })}
                    onClick={addToCart}
                    disabled={!canAddToCart() || addingToCart}
                  >
                    <span className="loading" />
                    <span className="text">{window.i18('ADD_TO_CART')}</span>
                  </button>
                </div>
                <h2 className="hotel-offers__title">{window.i18('CHOSING_ROOMS')}</h2>
                <p className="hotel-offers__recap">
                  <strong>
                    {`${nightsCount} ${nightsCount > 1 ? window.i18('NIGHTS') : window.i18('NIGHT')} - ${search.passengers.length} ${search.passengers.length > 1 ? window.i18('PERSONS') : window.i18('PERSON')} `}
                  </strong>
                  {`Check-in : ${arrival.format('DD/MM/YYYY')} - Check-out : ${departure.format('DD/MM/YYYY')} `}
                </p>
                {displayedOffers && (
                <ul className="hotel-offers__list">
                  {displayedOffers.map((offer, i) => (
                    <li className="hotel-offers__item p-top" key={offer.id}>
                      <div className="hotel-offers__name common-tooltip__parent">
                        <p>
                          <strong>{offer.name ? offer.name.split('-')[0] : `${window.i18('ROOM')} ${i + 1}`}</strong>
                        </p>
                        <div>
                          <span>
                            {displayOfferInfos(offer)}
                            *
                          </span>
                          {offer.cancel_conditions?.length > 0
                          && (
                          <div className="common-tooltip">
                            <div className="common-tooltip__content">
                              {offer.cancel_conditions}
                            </div>
                          </div>
                          )}
                        </div>
                      </div>
                      <div className="hotel-offers__price">
                        <div className="common-tooltip__parent hotel-room-tooltip">
                          <span
                            className={
                              classNames(
                                'hotel-room-tooltip__dot',
                                { 'hotel-room-tooltip__dot--blue': offer.tags && offer.tags.indexOf('negociated-fare') > -1 },
                                { 'hotel-room-tooltip__dot--green': offer.tags && offer.tags.indexOf('compliant') > -1 },
                                { 'hotel-room-tooltip__dot--red': offer.tags && offer.tags.indexOf('not-compliant') > -1 },
                              )
                            }
                          />
                          <TicketTagsTooltip tags={offer.tags} />
                        </div>
                        <strong>
                          {offer.incl_taxes_amount.toFixed(2)}
                          €
                        </strong>
                        /
                        {window.i18('NIGHT')}
                      </div>
                      <QuantityInput value={selectedOffers ? selectedOffers.filter((selectedOffer) => selectedOffer.offerId === offer.id).length : 0} min={0} max={offer.nb_available || 1} onRemove={() => removeSelectedOffer(offer.id)} onAdd={() => addSelectedOffer(offer)} />
                    </li>
                  ))}
                  {selectedResult.details.room_offers.length
                    > this.state.nbDisplayedOffers && (
                    <li className="hotel-offers__item p-y">
                      <button
                        className="button-secondary m-auto"
                        onClick={() => this.setState({
                          nbDisplayedOffers: this.state.nbDisplayedOffers + 3,
                        })}
                      >
                        {window.i18('MORE_PRICES')}
                      </button>
                    </li>
                  )}
                </ul>
                )}
              </div>
            )}
          </div>
          <button
            className="button-main hotel-add-to-cart"
            disabled={totalOccupancy < search.passengers.length}
            onClick={this.travelersDistribution}
            ref={this.button}
          >
            {window.i18('NEXT')}
          </button>
        </div>
      </>
    );
  }
}

HotelDetails.propTypes = propTypes;
