import React from 'react';

import SubNavLink from './SubNavLink';

class SubNav extends React.Component {
  constructor(props) {
    super(props);
    this.navSection = React.createRef();
  }

  render() {
    const links = this.props.links.map((link) => (
      <SubNavLink
        to={link.route}
        label={link.label}
        svg={link.svg}
        updateLine={this.setLineLeftPos}
        key={`link${link.label}`}
        active={this.props.location.pathname === link.route}
      />
    ));

    return (
      <ul className="user-account-menu" ref={this.navSection}>
        {links}
      </ul>
    );
  }
}

export default SubNav;
