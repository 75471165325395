import React, { useState } from 'react';
import { toast as Notif } from 'react-toastify';
import { useDispatch } from 'react-redux';
import API from '../../../utils/api';
import {
  sendingApprovalBegin, sendingApprovalSuccess,
  getUserApprovals, sendingApprovalError,
} from '../../UserAccount/actions';
import LeavesLoader from '../../../components/Loader/LeavesLoader';

function RejectTicket({
  closeModal, openModal, shake, ...props
}) {
  const dispatch = useDispatch();
  const [denialReason, setReason] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    dispatch(sendingApprovalBegin(props.approval));
    setSubmitting(true);

    API.post('/approval/answer', {
      approvalID: props.approval,
      answer: 'denied',
      message: denialReason,
    })
      .then(() => {
        dispatch(sendingApprovalSuccess(props.approval));
        dispatch(getUserApprovals());
        Notif.success(`${window.i18('REQUEST')} ${window.i18('DENIED').toLowerCase()} ${window.i18('SUCCESSFULLY')}.`);
        setSubmitting(false);
        closeModal();
      }, () => {
        dispatch(sendingApprovalError());
        setSubmitting(false);
      });
  };

  const handleOnChange = (event) => {
    setReason(event.target.value);
  };

  return (
    <div data-testid="login-modal" className="user-modal">
      <div className="modal-inner">
        {submitting ? (
          <>
            <LeavesLoader />
            <p>{window.i18('SENDING')}</p>
          </>
        ) : (
          <>
            <section className="modal-header">
              <h3 className="common-sub-title">{`${window.i18('ARE_YOU_SURE_TO_DENY')} ${window.i18('THIS_FEM')} ${window.i18('REQUEST').toLowerCase()}?`}</h3>
            </section>
            <div className="user-form">
              <div className="row">
                <p className="m-right">{window.i18('DENIAL_REASON')}</p>
                <div className="col col-12">
                  <input className="reason_input" type="text" value={denialReason} onChange={handleOnChange} placeholder={window.i18('REASON')} />
                </div>
              </div>
              <div className="modal-button-group row">
                <div className="col col-md-6 col-sm-12">
                  <a className="button-main" onClick={handleSubmit}>{window.i18('DENY')}</a>
                </div>
                <div className="col col-md-6 col-sm-12">
                  <a className="button-secondary" onClick={closeModal}>{window.i18('CANCEL')}</a>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default RejectTicket;
