// import { NotificationManager as Notif } from 'react-notifications';
import API from '../../utils/api';
import {
  CHECK_SUBSIDY_START, CHECK_SUBSIDY_SUCCESS, CHECK_SUBSIDY_ERROR, SET_SUBSIDY_NUMBER,
} from '../Search/constants';
import { setFromLocation, setToLocation } from '../TripSearch/actions';

export function checkSubsidyStart() {
  return {
    type: CHECK_SUBSIDY_START,
  };
}

export function checkSubsidySuccess(result, number) {
  return {
    type: CHECK_SUBSIDY_SUCCESS,
    result,
    number,
  };
}
export function checkSubsidyError(error) {
  return {
    type: CHECK_SUBSIDY_ERROR,
    error,
  };
}

export function setSubsidyNumber(number) {
  return {
    type: SET_SUBSIDY_NUMBER,
    number,
  };
}

export function checkSubsidyNumber(subsidyNumber) {
  return (dispatch) => {
    dispatch(checkSubsidyStart());
    if (subsidyNumber != null) {
      subsidyNumber = subsidyNumber.replace(' ', '');
    }
    return API.get(
      `/subsidy/request?number=${subsidyNumber}`,
    ).then((resp) => {
      if (resp.data.result == null) {
        dispatch(checkSubsidyError("Le numéro d'accord n'a pas été reconnu"));
      }
      const subsidy = resp.data.result;
      dispatch(checkSubsidySuccess(subsidy, subsidyNumber));
      if (subsidy.request && subsidy.request.origin != null) {
        dispatch(setFromLocation(subsidy.request.origin));
      }
      if (subsidy.request && subsidy.request.destination != null) {
        dispatch(setToLocation(subsidy.request.destination));
      }
    });
    // Do not need this because we are catching everything localy now. See api.js interceptors
    /*
    .catch((error) => {
      if (error && error.data && error.data.api_code && error.data.api_code === 8) { // Invalid number
        Notif.warning(friendlyError(error), '', 0);
      } else {
        dispatch(checkSubsidyError(friendlyError(error)));
        throw error;
      }
    });
    */
  };
}
