import React from 'react';
import { getSvgIcon } from '../../utils/icons';

const InfoTooltip = ({ message }) => (
  <div className="common-tooltip__parent">
    <span className="info-icon">
      {getSvgIcon('information', 'ui')}
    </span>
    <div className="common-tooltip common-tooltip--large">
      <div className="common-tooltip__content">
        {message}
      </div>
    </div>
  </div>
);

export default InfoTooltip;
