import React from 'react';
import PageContainer from '../../components/PageContainer';

class CNAMHelp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <PageContainer>
        <div className="cnam-help">
          <div className="row">
            <div className="col col-12">
              <h1 className="title">Aide</h1>
              <h2 className="subtitle">Règles générales sur la prise en charge des transports</h2>
              <div className="cnam-help__question">
                <div className="cnam-help__question__title">Quels sont les conditions de prise en charge des transports par l’Assurance maladie ?</div>
                <div className="cnam-help__question__content">Pour obtenir des billets de transport (billet de train, avion, bateau pour certaines CPAM/CGSS ), vous devez avoir reçu au préalable un courrier de votre CPAM/CGSS nommé « Le Service Trajet Santé de l’Assurance Maladie » vous donnant toutes les explications.</div>
              </div>
              <div className="cnam-help__question">
                <div className="cnam-help__question__title">Quels sont les transports pris en charge sur le site ?</div>
                <div className="cnam-help__question__content">
                  Lorsque vous pouvez vous déplacer, seul(e) ou accompagné(e) par une personne de votre entourage, le médecin prescrit un moyen de transport en avion, bateau ou train.
                  <br />
                  Pour les transports en avion, bateau ou pour le train lorsque la distance est supérieure à 150 km, vous recevez un accord de prise en charge par l’Assurance maladie.
                  <br />
                  Le numéro d’accord de l’Assurance maladie indiqué sur la notification vous permet de réserver votre billet de transport avec le service trajet santé de l’Assurance maladie.
                </div>
              </div>
              <div className="cnam-help__question">
                <div className="cnam-help__question__title">Quels sont les moyens de transports ouverts à la réservation sur le site ?</div>
                <div className="cnam-help__question__content">
                  Vous pouvez réserver avec mon service trajet santé de l’Assurance maladie :
                  <ul>
                    <li>vos billets de train, avion et bateau.</li>
                  </ul>
                  Pour l’avion et le train, la réservation peut se faire en ligne, par téléphone ou par tchat.
                  <br />
                  Pour le bateau, la réservation se fait via le TCHAT disponible sur la page d’accueil ou par téléphone.
                </div>
              </div>
              <div className="cnam-help__question">
                <div className="cnam-help__question__title">De quoi ai-je besoin pour obtenir des billets de transport pris en charge par ma CPAM/CGSS ?</div>
                <div className="cnam-help__question__content">
                  Pour obtenir des billets de transport (billet de train, avion, bateau pour certaines CPAM/CGSS),
                  vous devez avoir reçu au préalable un courrier de votre CPAM/CGSS nommé « Mon service Trajet santé de l’assurance maladie » vous donnant un accord de prise en charge et un numéro d’accord.
                </div>
              </div>
              <div className="cnam-help__question">
                <div className="cnam-help__question__title">L’Assurance Maladie prend-elle en charge le billet ?</div>
                <div className="cnam-help__question__content">
                  Si vous utilisez mon service trajet santé de l’Assurance Maladie, vous ne payez pas votre billet ou seulement une partie de celui-ci selon le taux de prise en charge.
                </div>
              </div>
              <div className="cnam-help__question">
                <div className="cnam-help__question__title">Quel est mon taux de prise en charge ?</div>
                <div className="cnam-help__question__content">
                  Votre taux de prise en charge figure sur la notification d’accord.
                  <br />
                  Un taux de prise en charge à 100% ouvre droit à une prise en charge par l’Assurance maladie de la totalité de votre billet et de la personne vous accompagnant le cas échéant.
                  <br />
                  Un taux de 65% ouvre droit à une prise en charge par l’Assurance maladie de 65% du montant du billet.
                  Vous devez payer 35% du montant du billet. Ce reste à charge peut être remboursé par votre mutuelle complémentaire.
                  <br />
                  <br />
                  Par exemple :
                  <table>
                    <tr>
                      <th>Montant du billet</th>
                      <th>Taux de prise en charge</th>
                      <th>
                        Pris en charge
                        <br />
                        Assurance maladie
                      </th>
                      <th>Paiement Assuré</th>
                    </tr>
                    <tr>
                      <td>100 €</td>
                      <td>100%</td>
                      <td>100€</td>
                      <td>0€</td>
                    </tr>
                    <tr>
                      <td>100 €</td>
                      <td>65%</td>
                      <td>65€</td>
                      <td>35€</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="cnam-help__question">
                <div className="cnam-help__question__title">Quel billet puis je réserver avec mon accord de prise en charge ?</div>
                <div className="cnam-help__question__content">
                  Votre courrier d’accord vous indique vos droits pris en charge par l’Assurance maladie :
                  <ul>
                    <li>le moyen de transport prescrit</li>
                    <li>le nombre de trajets</li>
                    <li>la destination</li>
                    <li>le taux de prise en charge (65% ou 100%).</li>
                    <li>les conditions de prise en charge spécifiques : personne accompagnante, surclassement 1ère classe, cabine pour le bateau.</li>
                  </ul>
                  <br />
                  <strong>
                    Le billet de transport réservé doit correspondre aux indications de la prise en charge.
                    <br />
                    Ne sont pas comprises dans le billet pris en charge par l’Assurance maladie :
                    <ul>
                      <li>les prestations supplémentaires pour convenance personnelle (repas, cabine de luxe...)</li>
                    </ul>
                  </strong>
                </div>
              </div>

              <div className="cnam-help__question">
                <div className="cnam-help__question__title">Pour mon déplacement, je dois prendre également un taxi ou un moyen de transport en commun, est ce pris en charge par l’Assurance maladie ?</div>
                <div className="cnam-help__question__content">
                  Pour cela, vous pouvez utiliser le service "Mes Remboursements Simplifiés" de l'Assurance Maladie, accessible sur
                  {' '}
                  <a href="https://www.mrs.beta.gouv.fr/" title="MRS">https://www.mrs.beta.gouv.fr/</a>
                </div>
              </div>

              <div className="cnam-help__question">
                <div className="cnam-help__question__title">Est-ce que je peux tout réserver selon ma Prise en charge sur le site servicetrajetsanteassurancemaladie.fr ?</div>
                <div className="cnam-help__question__content">
                  Oui , vous allez être guidé. Le site vous permet de réserver et obtenir les billets de train ou d’avion pour l’assuré(e) seul (e) en Aller et Retour.
                  Parfois, pour des demandes spéciales qui sont prises en charge, le site vous demandera de contacter le tchat ou d’appeler le 01 49 92 63 01, pour qu’un opérateur vous assiste et le réserve pour vous.
                  <br />
                  <br />
                  C’est le cas pour :
                  <ul>
                    <li>les demandes spéciales</li>
                    <li>s’il y a un accompagnant</li>
                    <li>s’il y a un trajet seul (pas d’aller / retour)</li>
                    <li>pour le bateau</li>
                  </ul>
                </div>
              </div>

              <h2 className="subtitle">Utilisation de l’outil</h2>
              <div className="cnam-help__question">
                <div className="cnam-help__question__title">Quelles sont les étapes pour obtenir des billets par le service servicetrajetsanteassurancemaladie.fr ?</div>
                <div className="cnam-help__question__content">
                  C’est très simple, il suffit de vous munir de votre courrier de votre CPAM/CGSS nommé « Le Service Trajet Santé de l’Assurance Maladie » vous donnant toutes les explications et de vous laisser guider.
                  <br />
                  <br />
                  Il y a 4 étapes :
                  <ul>
                    <li>Je m’identifie</li>
                    <li>Je fournis mon n° d’accord</li>
                    <li>Je remplis les informations passager(s)</li>
                    <li>Je recherche mon billet</li>
                  </ul>
                  <br />
                  Enfin, vous recevrez par mail vos billets.
                </div>
              </div>
              <div className="cnam-help__question">
                <div className="cnam-help__question__title">C’est la première fois que j’utilise le service servicetrajetsanteassurancemaladie.fr ?</div>
                <div className="cnam-help__question__content">
                  Vous devez vous inscrire en cliquant sur « inscription ».
                  <br />
                  Vous rentrez toutes les informations demandées et qui doivent être les mêmes que le courrier reçu au préalable de votre CPAM/CGSS nommé « Le Service Trajet Santé de l’Assurance Maladie ».
                  Ces informations sont nécessaires pour vous donner droit à la prise en charge, et aussi pour réserver les billets .
                </div>
              </div>
              <div className="cnam-help__question">
                <div className="cnam-help__question__title">Que se passe t’il lorsque j’ai cliqué sur « créer mon compte » ?</div>
                <div className="cnam-help__question__content">
                  Vous allez recevoir un mail sur l’adresse mail renseignée au préalable.
                  Dans ce mail, vous allez cliquer sur le lien, ainsi cela permettra de vérifier votre adresse mail.
                  Attention, si vous ne recevez pas l'email de confirmation, vérifiez dans vos SPAM qu'il ne s'y trouve pas.
                </div>
              </div>
              <div className="cnam-help__question">
                <div className="cnam-help__question__title">Je suis déjà inscrit au service servicetrajetsanteassurancemaladie.fr, que dois je faire pour l’utiliser ?</div>
                <div className="cnam-help__question__content">Vous devez vous connecter en cliquant sur « connexion », là vous devrez taper vos informations de connexion (adresse mail et mot de passe).</div>
              </div>
              <div className="cnam-help__question">
                <div className="cnam-help__question__title">Que dois je faire si je suis déjà inscrit et j’ai oublié mon mot de passe ?</div>
                <div className="cnam-help__question__content">
                  Vous cliquez sur
                  {' '}
                  <em>mot de passe oublié ?</em>
                  , rentrez votre adresse mail de connexion. Un mail vous sera envoyé dans lequel un lien sera présent. De là, vous allez pouvoir rentrer un nouveau mot de passe.
                </div>
              </div>
              <div className="cnam-help__question">
                <div className="cnam-help__question__title">Un problème , une question, pas de panique, vous êtes assisté</div>
                <div className="cnam-help__question__content">Vous pouvez contacter le tchat ou d’appeler le 01 49 92 63 01, pour qu’un opérateur vous assiste (aux heures ouvrées, du lundi au vendredi de 9h à 18h30 heure métropole).</div>
              </div>
            </div>
          </div>
        </div>

      </PageContainer>
    );
  }
}

export default CNAMHelp;
