import { combineReducers } from 'redux';
import {
  SEARCH_CAR_RENTALS_START,
  SEARCH_CAR_RENTALS_ERROR,
  SEARCH_CAR_RENTALS_SUCCESS,
  SET_SEARCHED_CAR_RENTALS,
  RESET_RESULTS,
  SET_SELECTED_RESULT,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_ERROR,
  ADD_TO_CART_START,
  EDIT_DRIVER,
  SET_PRICES,
  SET_PRICE_RANGE,
  SET_EXCULDED_CAR_TYPES,
  SET_SORTING,
  TOGGLE_MAP,
  SET_SELECTED_MARKER,
  TOGGLE_UNLIMITED_KM,
  SET_EXCLUDED_RENTERS,
  TOGGLE_NEGOCIATED,
  TOGGLE_LOYALTY,
} from './constants';

function resultsReducer(state = null, { type, carRentals }) {
  switch (type) {
    case RESET_RESULTS:
      return null;
    case SEARCH_CAR_RENTALS_SUCCESS:
      return carRentals;
    default:
      return state;
  }
}

function searchReducer(state = null, { type, search }) {
  switch (type) {
    case SET_SEARCHED_CAR_RENTALS:
      return search;
    default:
      return state;
  }
}

function loadingReducer(state = false, { type }) {
  switch (type) {
    case SEARCH_CAR_RENTALS_START:
      return true;
    case SEARCH_CAR_RENTALS_SUCCESS:
    case SEARCH_CAR_RENTALS_ERROR:
    case RESET_RESULTS:
      return false;
    default:
      return state;
  }
}

function selectedResultReducer(state = {}, { type, id }) {
  switch (type) {
    case SET_SELECTED_RESULT:
      return {
        id,
      };
    case RESET_RESULTS:
      return {};
    default:
      return state;
  }
}

function addingToCartReducer(state = false, action) {
  switch (action.type) {
    case ADD_TO_CART_START:
      return true;
    case ADD_TO_CART_ERROR:
    case ADD_TO_CART_SUCCESS:
      return false;
    default:
      return state;
  }
}

function driverReducer(state = {}, action) {
  switch (action.type) {
    case EDIT_DRIVER:
      return action.driver;
    case SEARCH_CAR_RENTALS_SUCCESS:
      return action.driver;
    default:
      return state;
  }
}

function sortingReducer(state = 'BY_PRICE', action) {
  switch (action.type) {
    case SET_SORTING:
      return action.sorting;
    default:
      return state;
  }
}

function priceRangeReducer(state = { min: 0, max: null }, action) {
  switch (action.type) {
    case SET_PRICE_RANGE:
      return {
        min: action.min,
        max: action.max,
      };
    default:
      return state;
  }
}

function pricesReducer(state = null, action) {
  switch (action.type) {
    case SET_PRICES:
      return {
        min: action.min,
        max: action.max,
      };
    default:
      return state;
  }
}

function excludedCarTypesReducer(state = [], action) {
  switch (action.type) {
    case SET_EXCULDED_CAR_TYPES:
      return action.excludedCarTypes;
    case RESET_RESULTS:
      return [];
    default:
      return state;
  }
}

function displayMapReducer(state = true, action) {
  switch (action.type) {
    case TOGGLE_MAP:
      return !state;
    case RESET_RESULTS:
      return true;
    default:
      return state;
  }
}

function selectedMarkerReducer(state = null, action) {
  if (action.type === SET_SELECTED_MARKER) {
    state = action.id;
  }
  return state;
}

function unlimitedKmReducer(state = false, action) {
  switch (action.type) {
    case TOGGLE_UNLIMITED_KM:
      return !state;
    case RESET_RESULTS:
      return false;
    default:
      return state;
  }
}

function negociatedReducer(state = false, action) {
  switch (action.type) {
    case TOGGLE_NEGOCIATED:
      return !state;
    case RESET_RESULTS:
      return false;
    default:
      return state;
  }
}

function loyaltyReducer(state = false, action) {
  switch (action.type) {
    case TOGGLE_LOYALTY:
      return !state;
    case RESET_RESULTS:
      return false;
    default:
      return state;
  }
}

function excludedRentersReducer(state = [], action) {
  switch (action.type) {
    case SET_EXCLUDED_RENTERS:
      return action.excludedRenters;
    case RESET_RESULTS:
      return [];
    default:
      return state;
  }
}

function rentersReducer(state = {}, action) {
  switch (action.type) {
    case SEARCH_CAR_RENTALS_SUCCESS:
      return action.renters;
    case RESET_RESULTS:
      return {};
    default:
      return state;
  }
}

function sortedByPrefReducer(state = null, action) {
  switch (action.type) {
    case SEARCH_CAR_RENTALS_SUCCESS:
      return action.sortedByPref;
    case RESET_RESULTS:
      return {};
    default:
      return state;
  }
}

const filtersReducer = combineReducers({
  priceRange: priceRangeReducer,
  prices: pricesReducer,
  excludedCarTypes: excludedCarTypesReducer,
  unlimitedKm: unlimitedKmReducer,
  excludedRenters: excludedRentersReducer,
  negociated: negociatedReducer,
  loyalty: loyaltyReducer,
});

const carRentalResultsReducer = combineReducers({
  results: resultsReducer,
  search: searchReducer,
  loading: loadingReducer,
  selectedResult: selectedResultReducer,
  addingToCart: addingToCartReducer,
  driver: driverReducer,
  filters: filtersReducer,
  sorting: sortingReducer,
  displayMap: displayMapReducer,
  selectedMarker: selectedMarkerReducer,
  renters: rentersReducer,
  sortedByPref: sortedByPrefReducer,
});

export default carRentalResultsReducer;
