export const TOGGLE_RT = 'tripSearch/TOGGLE_RT';
export const SET_FROM_LOCATION = 'tripSearch/SET_FROM_LOCATION';
export const SET_FROM_TOUCHED = 'tripSearch/SET_FROM_TOUCHED';
export const SET_TO_TOUCHED = 'tripSearch/SET_TO_TOUCHED';
export const SET_TO_LOCATION = 'tripSearch/SET_TO_LOCATION';
export const ADD_PASSENGER = 'tripSearch/ADD_PASSENGER';
export const EDIT_PASSENGER = 'tripSearch/EDIT_PASSENGER';
export const SET_PASSENGERS = 'tripSearch/SET_PASSENGERS';
export const SET_CORSICAN_FARES = 'tripSearch/SET_CORSICAN_FARES';
export const REMOVE_PASSENGER = 'tripSearch/REMOVE_PASSENGER';
export const SET_PASSENGERS_TOUCHED = 'tripSearch/SET_PASSENGERS_TOUCHED';
export const SET_DATE_TOUCHED = 'tripSearch/SET_DATE_TOUCHED';
export const SET_ENTITY_TOUCHED = 'tripSearch/SET_ENTITY_TOUCHED';
export const SET_TRIP_DATE = 'tripSearch/SET_TRIP_DATE';
export const SET_TRIP_TIME = 'tripSearch/SET_TRIP_TIME';
export const SET_TRIP_TIME_MODE = 'tripSearch/SET_TRIP_TIME_MODE';
export const SET_FORM_ERRORS = 'tripSearch/SET_FORM_ERRORS';
export const SEARCHING_TRIP = 'tripSearch/SEARCHING_TRIP';
export const TOGGLE_DOOR_TO_DOOR = 'tripSearch/TOGGLE_DOOR_TO_DOOR';
export const SET_SPECIAL_FARE = 'tripSearch/SET_SPECIAL_FARE';
export const CDS_REDIRECT_BEGIN = 'tripSearch/CDS_REDIRECT_BEGIN';
export const CDS_REDIRECT_SUCCESS = 'tripSearch/CDS_REDIRECT_SUCCESS';
export const CDS_REDIRECT_ERROR = 'tripSearch/CDS_REDIRECT_ERROR';
export const FILL_USER_INFO = 'tripSearch/FILL_USER_INFO';
export const TOGGLE_SELECTED_PASSENGER = 'tripSearch/TOGGLE_SELECTED_PASSENGER';
export const ADD_LAST_TRAVELERS = 'tripSearch/ADD_LAST_TRAVELERS';
export const SET_SEARCH_PARAMS = 'tripSearch/SET_SEARCH_PARAMS';
