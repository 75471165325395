import React from 'react';
import { connect } from 'react-redux';
import {
  CarouselProvider, ButtonBack, ButtonNext, Slide, Slider,
} from 'pure-react-carousel';
// Actions
import { closeImage, setGallery } from './actions';

class ImgViewer extends React.Component {
  componentDidMount() {
    document.addEventListener('click', (event) => {
      if (event.target.classList.contains('img-viewer')) {
        this.props.closeImage();
      }
    });
    document.addEventListener('keydown', (event) => {
      if (this.props.img && event.code === 'Escape') {
        this.props.closeImage();
      }
      if (this.props.imgs && (event.keyCode === 39 || event.keyCode === 37)) {
        const currentId = this.props.imgs.indexOf(this.props.img);
        let nextId;
        if (event.keyCode === 37) {
          nextId = currentId === 0 ? this.props.imgs.length - 1 : currentId - 1;
        } else {
          nextId = currentId === this.props.imgs.length - 1 ? 0 : currentId + 1;
        }
        this.props.setGallery(this.props.imgs[nextId], this.props.imgs);
      }
    });
  }

  render() {
    const { img, imgs } = this.props;
    if (img === null) return null;
    return (
      <div className="img-viewer">
        <button className="img-viewer__close" aria-label="fermer" onClick={this.props.closeImage} />
        {imgs === null
          ? <img id="image" src={img} alt="" />
          : (
            <CarouselProvider
              totalSlides={imgs.length}
              visibleSlides={1}
              step={1}
              currentSlide={imgs.indexOf(img)}
              infinite
              className="img-viewer__carousel"
            >
              <ButtonBack>Back</ButtonBack>
              <div className="carousel__slider-container">
                <Slider>

                  {imgs.map((url, i) => (
                    <Slide index={i} key={img}>
                      <img src={url} alt="" />
                    </Slide>
                  ))}
                </Slider>
              </div>
              <ButtonNext>Next</ButtonNext>
            </CarouselProvider>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  img: state.imgViewer.img,
  imgs: state.imgViewer.imgs,
});

const mapDispatchToProps = (dispatch) => ({
  closeImage: () => dispatch(closeImage()),
  setGallery: (img, imgs) => dispatch(setGallery(img, imgs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImgViewer);
