import { combineReducers } from 'redux';
import moment from 'moment';

import {
  TOGGLE_RT,
  ADD_PASSENGER,
  EDIT_PASSENGER,
  SET_PASSENGERS,
  REMOVE_PASSENGER,
  SET_PASSENGERS_TOUCHED,
  SET_TRIP_DATE,
  SET_TRIP_TIME,
  SET_TRIP_TIME_MODE,
  SET_FORM_ERRORS,
  TOGGLE_DOOR_TO_DOOR,
  SET_SPECIAL_FARE,
  CDS_REDIRECT_BEGIN,
  CDS_REDIRECT_SUCCESS,
  CDS_REDIRECT_ERROR,
  FILL_USER_INFO,
  TOGGLE_SELECTED_PASSENGER,
  SET_FROM_TOUCHED,
  SET_TO_TOUCHED,
  SET_DATE_TOUCHED,
  ADD_LAST_TRAVELERS,
  SET_CORSICAN_FARES,
  SET_ENTITY_TOUCHED,
  SET_SEARCH_PARAMS,
} from './constants';

import { FETCH_USER_CARDS_SUCCESS, SET_USER_CARDS_SUCCESS } from '../UserAccount/constants';

function isRTReducer(state = false, action) {
  switch (action.type) {
    case TOGGLE_RT:
      return !state;
    case SET_SEARCH_PARAMS:
      return action.isRT;
    default:
      return state;
  }
}

function locationReducerFactory(direction) {
  return function reducer(state = {}, action) {
    const DIRECTION = direction.toUpperCase();

    switch (action.type) {
      case `tripSearch/SET_${DIRECTION}_LOCATION`:
        return {
          ...action.location,
        };
      case SET_SEARCH_PARAMS:
        return action[direction];
      default:
        return state;
    }
  };
}

// Each trip gets a key (a & r), with the same reducer
function dateReducer(
  state = {
    a: {
      mode: 'departure',
      date: moment(),
      time: '140000',
    },
    r: {
      mode: 'departure',
      date: null,
      time: '140000',
    },
  },
  action,
) {
  switch (action.type) {
    case SET_TRIP_DATE:
      return {
        ...state,
        [action.trip]: {
          ...state[action.trip],
          date: action.date,
        },
      };
    case SET_TRIP_TIME:
      return {
        ...state,
        [action.trip]: {
          ...state[action.trip],
          time: action.time,
        },
      };
    case SET_TRIP_TIME_MODE:
      return {
        ...state,
        [action.trip]: {
          ...state[action.trip],
          mode: action.mode,
        },
      };
    case SET_SEARCH_PARAMS:
      return {
        a: {
          ...state.a,
          date: action.date.a.date,
        },
        r: {
          ...state.r,
          date: action.date.r.date,
        },
      };
    default:
      return state;
  }
}

function passengersReducer(
  state = [{
    id: 0, cards: [], selected: true, isSimple: true,
  }],
  action,
) {
  switch (action.type) {
    case ADD_PASSENGER: {
      const existingIndex = state.findIndex((p) => p.profile_id && p.profile_id === action.passenger.profile_id);
      if (existingIndex > -1 && action.passenger.profile_id) {
        state.splice(existingIndex, 1);
      }
      return [
        {
          id: nextPassengerUid(),
          selected: true,
          isSimple: true,
          ...action.passenger,
        },
        ...state,
      ];
    }
    case REMOVE_PASSENGER:
      return state.filter((passenger) => action.id !== passenger.id);
    case SET_CORSICAN_FARES:
      state.forEach((passenger) => { passenger.with_corsican_fares = action.withCorsicanFares; });
      return state;
    case EDIT_PASSENGER:
      return state.map((passenger) => (passenger.id === action.passenger.id ? action.passenger : passenger));
    case FILL_USER_INFO:
      return state.map(
        (passenger, index) => {
          if (index === 0) {
            passenger = {
              ...action.user,
              ...passenger,
              isSimple: false,
            };
          }

          return passenger;
        },
      );
    case ADD_LAST_TRAVELERS:
      action.travelers.forEach((traveler) => {
        state.push({
          id: nextPassengerUid(),
          ...traveler,
        });
      });
      return state;
    case FETCH_USER_CARDS_SUCCESS:
      return state.map((passenger, index) => {
        if (index === 0 && !action.isTravelManager) {
          passenger = {
            ...passenger,
            cards: action.cards.map((c) => ({
              cardtype_id: c.cardtype_id,
              cardnumber: c.cardnumber,
            })),
          };
        }
        return passenger;
      });
    case TOGGLE_SELECTED_PASSENGER:
      return state.map((passenger, i) => {
        if (i === action.index) {
          passenger = {
            ...passenger,
            selected: !passenger.selected,
          };
        }
        return passenger;
      });
    case SET_SEARCH_PARAMS:
    case SET_PASSENGERS:
      return action.passengers;
    case SET_USER_CARDS_SUCCESS:
      return state.map((passenger) => {
        if (action.uid === passenger.uid) {
          return {
            ...passenger,
            cards: action.cards,
          };
        }
        return passenger;
      });
    default:
      return state;
  }
}

function errorsReducer(state = null, action) {
  switch (action.type) {
    case SET_FORM_ERRORS:
      return action.errors;
    default:
      return state;
  }
}

function isDoorToDoorReducer(state = false, { type }) {
  switch (type) {
    case TOGGLE_DOOR_TO_DOOR:
      return !state;
    default:
      return state;
  }
}

function specialFareReducer(state = null, { type, specialFare }) {
  switch (type) {
    case SET_SPECIAL_FARE:
      return specialFare;
    default:
      return state;
  }
}

// We store a kind of uid for passenger ids.
// Starts at 0 because the passenger with id 0 is instancied in the reducer above
// And the functions starts with returning an incremented number
let uid = 0;

export function nextPassengerUid() {
  uid += 1;
  return uid;
}

function cdsRedirectReducer(state = false, { type }) {
  switch (type) {
    case CDS_REDIRECT_BEGIN:
      return true;
    case CDS_REDIRECT_SUCCESS:
      return false;
    case CDS_REDIRECT_ERROR:
      return false;
    default:
      return state;
  }
}

function touchedReducer(state = {}, { type }) {
  switch (type) {
    case SET_FROM_TOUCHED:
      return {
        ...state,
        from: true,
      };
    case SET_TO_TOUCHED:
      return {
        ...state,
        to: true,
      };
    case SET_DATE_TOUCHED:
      return {
        ...state,
        date: true,
      };
    case SET_PASSENGERS_TOUCHED:
      return {
        ...state,
        passengers: true,
      };
    case SET_ENTITY_TOUCHED:
      return {
        ...state,
        entityID: true,
      };
    default:
      return state;
  }
}

const defaultActionsState = {
  userFilled: false,
  lastTravelersAdded: false,
};

function actionsReducer(state = defaultActionsState, action) {
  switch (action.type) {
    case FILL_USER_INFO:
      return {
        ...state,
        userFilled: true,
      };
    case ADD_LAST_TRAVELERS:
      return {
        ...state,
        lastTravelersAdded: true,
      };
    default: return state;
  }
}

function travelIDReducer(state = null, action) {
  switch (action.type) {
    case SET_SEARCH_PARAMS:
      return action.travelID;
    default:
      return state;
  }
}

const tripSearchReducer = combineReducers({
  travelID: travelIDReducer,
  isRT: isRTReducer,
  from: locationReducerFactory('from'),
  to: locationReducerFactory('to'),
  date: dateReducer,
  passengers: passengersReducer,
  errors: errorsReducer,
  isDoorToDoor: isDoorToDoorReducer,
  specialFare: specialFareReducer,
  cdsRedirectLoading: cdsRedirectReducer,
  touched: touchedReducer,
  actions: actionsReducer,
});

export default tripSearchReducer;
