import React from 'react';
import {
  Formik, Form, FieldArray,
} from 'formik';

import { connect } from 'react-redux';
import moment from 'moment';
import { fillPassenger } from '../../TripResults_v3/actions';

import { formatDateForAPI } from '../../../utils/converters';
import Checkbox from '../../../components/Checkbox';
import UserForm from '../../../components/UserForm/new';
import CardForm from '../../../components/ModalCardForm';

import { travelerValidationSchema } from '../../TripResults_v3/functions';

class PassengerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addingCard: false,
      edittingCard: null,
      dataAccuracy: false,
    };
  }

  toggleForm = () => {
    this.setState({
      addingCard: !this.state.addingCard,
      edittingCard: null,
    });
  };

  toggleDataAccuracy = () => this.setState({ dataAccuracy: !this.state.dataAccuracy })

  editCard = (index) => {
    this.setState({
      edittingCard: index,
      addingCard: true,
    });
  };

  render() {
    const {
      passenger, closeModal, fillPassenger, handicaps,
    } = this.props;
    const { cardsList } = this.props;
    let birthdate = '';
    if (passenger.birthdate) {
      if (passenger.birthdate === 'xx/xx/xxxx') {
        birthdate = passenger.birthdate;
      } else {
        birthdate = moment(passenger.birthdate).toDate();
      }
    }
    return (
      <div className="passenger-modal">
        <div className="modal-with-tagline">
          <header className="tagline">
            <h1 className="common-title">
              {passenger ? window.i18('UPDATE_PASSENGER') : window.i18('ADD_PASSENGER')}
            </h1>
          </header>
        </div>
        <Formik
          initialValues={{
            ...passenger,
            birthdate,
            cards: passenger.cards ? passenger.cards : null,
            gender: passenger.gender ? passenger.gender : '',
            firstname: passenger.firstname ? passenger.firstname : '',
            lastname: passenger.lastname ? passenger.lastname : '',
            email: passenger.email ? passenger.email : '',
            phone: passenger.phone ? passenger.phone : '',
            address: passenger.address ? passenger.address : '',
          }}
          onSubmit={(values) => {
            if (values.birthdate && values.birthdate !== 'xx/xx/xxxx') {
              values.birthdate = formatDateForAPI(`${moment(values.birthdate).format('YYYY-MM-DD')}T120000`);
            }
            fillPassenger(values.id, values);
            closeModal();
          }}
          validationSchema={travelerValidationSchema}
        >
          {({
            values, touched, errors, setFieldValue,
          }) => {
            if (this.state.addingCard) {
              return (
                <FieldArray
                  name="cards"
                  render={(arrayHelpers) => (
                    <CardForm
                      toggleForm={this.toggleForm}
                      cardsList={cardsList}
                      index={this.state.edittingCard}
                      arrayHelpers={arrayHelpers}
                      cards={values.cards}
                    />
                  )}
                />
              );
            }
            return (
              <Form className="passenger-profile__body">
                <UserForm
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  withPhone
                  withBirthdate
                  withHandicap
                  handicaps={handicaps}
                />
                {values.cards && (
                  <>
                    <div className="row m-bot m-top">
                      <label className="common-title" htmlFor="card">
                        {window.i18('CARDS')}
                      </label>
                    </div>
                    <FieldArray
                      name="cards"
                      render={() => (
                        <ul className="cards-list row m-bot">
                          {values.cards
                          && values.cards.map((card, index) => {
                            if (
                              cardsList
                              && cardsList.find((c) => c.uid === card.cardtype_id)
                            ) {
                              return (
                                <li key={`card-${index}`} className="card">
                                  <button onClick={() => this.editCard(index)}>
                                    {
                                      cardsList.find(
                                        (c) => c.uid === card.cardtype_id,
                                      ).name
                                    }
                                  </button>
                                </li>
                              );
                            }
                            return null;
                          })}
                        </ul>
                      )}
                    />
                  </>
                )}
                <div className="row m-top">
                  <Checkbox
                    small
                    checked={this.state.dataAccuracy}
                    onChange={this.toggleDataAccuracy}
                    value={window.i18('AGGREE_FORM_RESPONSABILITY')}
                  />
                </div>
                <div className="row passenger-profile__footer">
                  <div className="col col-12">
                    <button type="submit" className="button-main m-auto" disabled={!this.state.dataAccuracy}>
                      {window.i18('CONFIRM')}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cardsList: state.app.cards,
  handicaps: state.app.handicaps,
});

const mapDispatchToProps = (dispatch) => ({
  fillPassenger: (id, passenger) => dispatch(fillPassenger(id, passenger)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PassengerModal);
