import * as yup from 'yup';
import { validationSchemaBirthdateOrHidden } from '../../utils/functions';

// eslint-disable-next-line import/prefer-default-export
export function travelerValidationSchema() {
  return yup.object().shape({
    gender: yup.string().required(window.i18('PLEASE_FILL')),
    firstname: yup.string().required(window.i18('PLEASE_FILL')),
    lastname: yup.string().required(window.i18('PLEASE_FILL')),
    email: yup
      .string()
      .email()
      .required(window.i18('PLEASE_FILL')),
    phone: yup
      .string()
      .matches(
        /\b0[0-9]{9}\b|xxxxxxxx/,
        window.i18('PHONE_NUMBER_FORMAT'),
      )
      .required(window.i18('PLEASE_FILL')),
    birthdate: validationSchemaBirthdateOrHidden(),
  });
}
