import axios from 'axios';
import { push } from 'connected-react-router';
import { toast as Notif } from 'react-toastify';
import conf from './env';
import { logout } from '../containers/App/actions';
import { openModal } from '../containers/ModalWrapper/actions';
import {
  sendMalformedRequestErrorToSentry,
  sendApiErrorToSentry,
} from './send-exceptions-to-sentry';

const api = axios.create({
  baseURL: conf.requestURL,
  headers: {
    'Api-Key': conf.apiKey,
    'Content-Type': 'application/json',
    'TheTreep-Client': process.env.REACT_APP_THETREEP_CLIENT_HEADER,
  },
});

// Function used to notify user that something goes wrong
const notifyUser = (error) => {
  const message = error.data.errorMessage || window.i18('ERROR_GENERIC');
  Notif.error(message, { autoClose: 15000 });
};

/* More infos about interceptors here https://github.com/axios/axios#interceptors */
// Intercept all request before being trigger
api.interceptors.request.use((config) => config, (error) => {
  sendMalformedRequestErrorToSentry(error);
  return Promise.reject(error);
});

// Intercept all request response
api.interceptors.response.use((response) => response, (error) => {
  sendApiErrorToSentry(error.response);
  notifyUser(error.response);
  return Promise.reject(error);
});

// This adds the Authorization header for logged in users.
// Also handles auth fail during the user's visit (if the token expires)
export function addAuth(dispatch, getState) {
  const authInterceptor = api.interceptors.request.use((request) => {
    const { token } = getState().app;

    if (token != null) {
      request.headers = {
        ...request.headers,
        Authorization: token,
      };
    }

    return request;
  });

  // Statekeeper to fire only once
  let hasFired = false;

  // If we get a 401, it means the token has expired
  // We go to the home page, we clear the current user in the React app, clear the token from the headers
  // And display a warning message
  api.interceptors.response.use((response) => response, (error) => {
    const { token } = getState().app;
    try {
      const { status } = error.response;
      const { api_code } = error.response.data;

      if (status === 422 && api_code === 60 && !hasFired) {
        dispatch(logout());
        dispatch(push('/'));
        dispatch(openModal('AccountMigrationError', { noClose: true }));
        hasFired = true;
      }

      if (token != null && status === 401 && !hasFired) {
        dispatch(logout());
        dispatch(push('/'));
  
        Notif.warning(window.i18('SESSION_EXPIRED'), { autoClose: 5000 });
        api.interceptors.request.eject(authInterceptor);
        hasFired = true;
      }

    } catch(error){
        Notif.warning(error, { autoClose: 5000 });
    }

    return Promise.reject(error);
  });
}

export function friendlyError(
  error, defaultMsg = window.i18('ERROR_HAPPENED'),
) {
  // eslint-disable-next-line no-console
  console.log('received error', error);
  if (error) {
    // eslint-disable-next-line no-console
    console.log('error data', error.data);
  }
  if (error && error.data && error.data.api_code) {
    switch (error.data.api_code) {
      case 1:
        return 'Impossible de chercher un treep avec plusieurs passagers handicapés';
      case 2:
        return 'Impossible de chercher un treep avec plusieurs passagers accompagnants';
      case 3:
        return 'Pas d\'hôtel à réserver';
      case 4:
        return 'Pas de chambre d\'hôtel à réserver';
      case 5:
        return 'Erreur lors de la communication avec la base de données';
      case 6:
        return 'Une erreur s\'est produite lors de la réservation de l\'hôtel';
      case 7:
        return 'Vous ne pouvez pas accéder à cette ressource';
      case 8:
        return 'Le numéro d\'accord ou le nom et prénom saisis sont invalides';
      case 9:
        return 'Le quota associé à ce numéro d\'accord a déjà été consommé';
      case 10:
        return 'Ce numéro d\'accord ne peut pas être géré dans l\'application';
      case 11:
        return 'Un numéro d\'accord peut être associé uniquement à un passager';
      case 12:
        return 'Utilisateur invalide pour ce numéro d\'accord';
      case 13:
        return 'Le quota associé à ce numéro d\'accord est insuffisant pour cette réservation';
      case 14:
        return 'Informations sur le passager manquantes';
      case 15:
        return 'Mode de transport non supporté sur cette application';
      case 16:
        return 'Les offres à réserver ne sont pas valides';
      case 17:
        return 'Format de requête invalide';
      case 18:
        return 'Fichier de numéros d\'accords manquant';
      case 19:
        return 'Erreur inconnue';
      case 20:
        return 'Fichier de numéros d\'accords invalide';
      case 21:
        return 'Le numéro d\'accord a un statut invalide';
      case 22:
        return 'Ce numéro d\'accord est déjà utilisé pour une autre réservation pour laquelle les dates se chevauchent';
      case 23:
        return 'L\'utilisateur n\'est rattaché à aucune entreprises';
      case 24:
        return 'Pas de résultat';
      case 25:
        return 'Un des tickets n\'est associé à aucun approbateur';
      case 26:
        return 'Les tickets sont manquants';
      case 27:
        return 'L\'un des tickets a déjà un approbateur secondaire';
      case 28:
        return 'Un des tickets n\'est associé à aucun approbateur';
      case 29:
        return 'Mauvais email ou mot de passe';
      case 30:
        return 'Votre email n\'a pas encore été confirmé';
      case 31:
        return 'Il n\'y a pas d\'utilisateur avec cet email';
      case 32:
        return 'Le compte de cet utilisisateur n\'est pas utilisable sur cette adresse';
      case 33:
        return 'Cette entreprise n\'a pas été trouvée';
      case 34:
        return 'Vous n\'avez pas les permissions pour faire cette action';
      case 35:
        return 'L\'entreprise de cet utilisateur n\'a pas été trouvée';
      case 36:
        return 'Cette entreprise n\'a pas été trouvée';
      case 37:
        return 'Aucun résultats trouvés';
      case 38:
        return 'Pas de voyage trouvé';
      case 39:
        return 'L\'origine ou la destination est incorrecte';
      case 40:
        return 'L\'identifiant de l\'entreprise est manquant';
      case 41:
        return 'Aucune prestation trouvée avec cet identifiant';
      case 42:
        return 'Aucune prestation trouvée avec cet identifiant';
      case 43:
        return 'Aucun utilisateur trouvé avec cet identifiant';
      case 44:
        return 'Erreur lors de la mise à jour';
      case 45:
        return 'L\'utilisateur n\'a pas été trouvé';
      case 46:
        return 'L\'email est manquant';
      case 47:
        return 'Le prénom est manquant';
      case 48:
        return 'Le nom de famille est manquant';
      case 49:
        return 'Cet email est déjà utilisé';
      case 65:
        return 'Le statut de cet utilisateur est invalide';
      case 50:
        return 'L\'uilisateur est manquant';
      case 51:
        return 'Le rôle est manquant';
      case 52:
        return 'Un utilisateur existe déjà avec cet identifiant';
      case 53:
        return 'Un identifiant de l\'entreprise ou de l\'utilisateur est requis';
      case 54:
        return 'Aucune entreprise trouvée avec cet identifiant';
      case 55:
        return 'Aucun utilisateur trouvé avec cet identifiant';
      case 56:
        return 'Il faut choisir au moins un ticket';
      case 57:
        return 'Il faut choisir au moins un ticket à réserver';
      case 58:
        return 'Cette requête est invalide';
      case 59:
        return 'Erreur d\'authentification';
      case 60:
        return 'Ce compte utilisateur n\'est plus utilisable sur cette adresse';
      case 61:
        return 'Erreur d\'authentification';
      case 62:
        return 'Il faut choisir au moins un ticket à réserver';
      case 63:
        return 'Aucun résultat pour votre recherche...';
      case 64:
        return 'Ce compte utilisateur a été supprimé';
      case 66:
        return 'Accès non autorisé à ces tickets';
      case 67:
        return 'Ces tickets ne sont plus en attente d\'approbation';
      case 68:
        return 'Cet approbateur n\'est pas autorisé à approuver ces billets';
      case 69:
        return 'Le statut des billets n\'autorise pas cette action';
      case 70:
        return 'Des champs analytiques sont manquants ou invalides';
      default:
        if (error.data.msg) {
          return error.data.msg;
        }
        return defaultMsg;
    }
  }
  if (error && error.data && error.data.msg) {
    switch (error.data.msg) {
      case 'User already exists.':
        return window.i18('USER_ALREADY_EXISTS');
      case 'invalid subsidy number':
        return window.i18('INVALID_SUBSIDY_NUMBER');
      default:
    }
    if (error.data.msg.includes(
      'There is an insufficient number of vacant seats to let all passengers travel',
    )) {
      return window.i18('ERROR_TRAIN_FULL');
    }
    if (error.data.msg.includes('passengerCard[@cardNumber]')) {
      return window.i18('ERROR_INVALID_CARD');
    }
    if (error.data.msg.includes('/passengerName[@lastName]')) {
      return window.i18('ERROR_LASTNAME');
    }
    if (error.data.msg.includes(
      'the user\'s birthdate is mandatory for booking',
    )) {
      return window.i18('ERROR_EMPTY_BIRTHDATE');
    }
    if (error.data.msg.includes('UC SEG STATUS NOT ALLOWED')) {
      return window.i18('ERROR_AIRPLANE_FULL');
    }
    return error.data.msg;
  }
  return defaultMsg;
}

export default api;
