import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Components
import StarsFilter from './StarsFilter';
import ToggleFilter from './ToggleFilter';
import PriceFilter from './PriceFilter';
// Actions
import {
  setStars, toggleBreakfast, setPriceRange, toggleAccessible, toggleLoyaltyEnabled,
} from '../../actions';
import Checkbox from '../../../../components/Checkbox';
import StarsInput from '../../../../components/StarsInput';

function Filters({
  filters: {
    stars, breakfast, priceRange, prices, accessible, loyaltyEnabled,
  }, setStars, toggleBreakfast, setPriceRange, toggleAccessible, toggleLoyaltyEnabled, isMobileDevice,
}) {
  if (isMobileDevice) {
    return (
      <>
        <PriceFilter priceRange={priceRange} prices={prices} setPriceRange={setPriceRange} />
        <p>{window.i18('FILTERS')}</p>
        <div className="common-checkbox">
          <StarsInput stars={stars} onChange={setStars} />
          <div className="p-left">{window.i18('COMFORT')}</div>
        </div>
        <Checkbox value={window.i18('BREAKFAST')} checked={breakfast} onChange={toggleBreakfast} />
        <Checkbox value={window.i18('LOYALTY_CARD_COMPATIBILE')} checked={loyaltyEnabled} onChange={toggleLoyaltyEnabled} />
        <Checkbox value={window.i18('PRM_ACCESSIBLE')} checked={accessible} onChange={toggleAccessible} />
      </>
    );
  }
  return (
    <>
      <div className="hotel-filters">
        <h3>
          {window.i18('FILTERS')}
          :
        </h3>
        <StarsFilter stars={stars} setStars={setStars} />
        <ToggleFilter value={breakfast} toggleValue={toggleBreakfast} name={window.i18('BREAKFAST')} />
        <ToggleFilter value={loyaltyEnabled} toggleValue={toggleLoyaltyEnabled} name={window.i18('LOYALTY_CARD_COMPATIBILE')} />
        <PriceFilter priceRange={priceRange} prices={prices} setPriceRange={setPriceRange} />
        <ToggleFilter value={accessible} toggleValue={toggleAccessible} name={window.i18('PRM_ACCESSIBLE')} />
      </div>
    </>
  );
}

Filters.propTypes = {
  filters: PropTypes.shape({
    stars: PropTypes.number.isRequired,
    breakfast: PropTypes.bool.isRequired,
    priceRange: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }).isRequired,
    prices: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  setStars: PropTypes.func.isRequired,
  toggleBreakfast: PropTypes.func.isRequired,
  toggleLoyaltyEnabled: PropTypes.func.isRequired,
  setPriceRange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.hotelResults.filters,
  isMobileDevice: state.app.isMobileDevice,
});

const mapDispatchToProps = (dispatch) => ({
  setStars: (stars) => dispatch(setStars(stars)),
  toggleBreakfast: () => dispatch(toggleBreakfast()),
  toggleLoyaltyEnabled: () => dispatch(toggleLoyaltyEnabled()),
  toggleAccessible: () => dispatch(toggleAccessible()),
  setPriceRange: (min, max) => dispatch(setPriceRange(min, max)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
