import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
// Components
import Input from '../Inputs/Material';
import Date from '../Inputs/MaterialDate';
// Functions
import { getSvgIcon } from '../../utils/icons';

const icons = {
  airline: () => getSvgIcon('airplane', 'transport/svg/'),
  rail: () => getSvgIcon('tgv', 'transport/svg/'),
  hotel: () => getSvgIcon('hotel', 'transport/svg/'),
  car_rental: () => getSvgIcon('car_rental', 'transport/svg/'),
};

const Option = (props) => {
  const getIcon = icons[props.data.transport];
  return (
    <components.Option {...props}>
      {getIcon()}
      {props.label}
    </components.Option>
  );
};

const MenuList = (props) => (
  <components.MenuList {...props}>
    {props.children.length ? props.children.slice(0, 30) : window.i18('NO_RESULT')}
  </components.MenuList>
);

const CardInput = ({
  card, cardtypes, values, index, setFieldValue, errors, touched, withDate, key, name = 'cards',
}) => {
  const handleCardnameChange = ({ value, transport }) => {
    setFieldValue(name, values.map((card, i) => {
      if (i === index) {
        const cardType = cardtypes.find((c) => parseInt(c.uid, 10) === parseInt(value, 10));
        if (cardType.card_type !== 'loyalty_card' || transport === 'rail') {
          delete card.cardnumber;
        }
        return { ...card, cardtype_id: parseInt(value, 10) };
      }
      return card;
    }));
  };

  const handleCardnumberChange = async (cardnumber) => {
    setFieldValue(name, values.map((card, i) => {
      if (i === index) {
        return { ...card, cardnumber };
      }
      return card;
    }));
  };

  const handleExpirationDateChange = async (expirationDate) => {
    setFieldValue(name, values.map((card, i) => {
      if (i === index) {
        return { ...card, expiration_date: expirationDate };
      }
      return card;
    }));
  };

  const removeCard = async () => {
    const rest = values.filter((card, i) => i !== index);
    setFieldValue(name, rest);
  };

  const [cardType, setCardType] = useState(null);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    setOptions(cardtypes.map((c) => ({
      label: c.name,
      value: c.uid,
      transport: c.type,
    })));
  }, [cardtypes]);

  useEffect(() => {
    setCardType(cardtypes.find((c) => parseInt(c.uid, 10) === parseInt(card.cardtype_id, 10)));
    setValue({
      value: card.cardtype_id,
      label: card.cardtype_id !== ''
        ? cardtypes.find((c) => c.uid === card.cardtype_id).name
        : window.i18('TYPE_IN_CARD_NAME'),
    });
  }, [card.cardtype_id, cardtypes]);

  return (
    <div className="row m-bot">
      <div className="col col-11">
        <p className="common-small-font m-0 m-bot">
          <strong>
            {window.i18('CARD')}
            {' '}
            {index + 1}
          </strong>
        </p>
        <div className="row" key={key}>
          <Select
            className="card-input"
            classNamePrefix="card-input"
            options={options}
            name="cardtype_id"
            value={value}
            error={errors.cards && errors.cards[index] && errors.cards[index].cardtype_id}
            touched={touched.cards && touched.cards[index] && touched.cards[index].cardtype_id}
            onChange={handleCardnameChange}
            components={{ Option, MenuList }}
          />
        </div>
        {cardType && (cardType.card_type === 'loyalty_card' || cardType.type === 'rail') && (
          <div className="row m-top">
            <Input
              name="cardnumber"
              placeholder="123456789"
              label={window.i18('NUMBER')}
              value={card.cardnumber}
              error={errors.cards && errors.cards[index] && errors.cards[index].cardnumber}
              touched={touched.cards && touched.cards[index] && touched.cards[index].cardnumber}
              onChange={handleCardnumberChange}
            />
          </div>
        )}
        {withDate && (
          <div className="row m-top">
            <Date
              name="expiration_date"
              placeholder="01/01/2021"
              label={window.i18('EXPIRATION_DATE')}
              value={card.expiration_date}
              error={errors.cards && errors.cards[index] && errors.cards[index].expiration_date}
              touched={touched.cards && touched.cards[index] && touched.cards[index].expiration_date}
              onChange={handleExpirationDateChange}
              withYearSelect
              future
            />
          </div>
        )}
      </div>
      <div className="col col-1 justify-center">
        <button className="button-icon" onClick={removeCard} type="button">
          {getSvgIcon('dustbin', 'ui/')}
        </button>
      </div>
    </div>
  );
};

export default CardInput;
