import React, { useState } from 'react';
import classNames from 'classnames';
import tree from '../../../images/icons/tree.png';
import { isCNAM } from '../../utils/functions';

const PaymentFees = (props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className={`m-bot cart-fees${props.isB2C ? ' common-frame b2c' : ''}`}>
      {props.isB2C ? (
        <>
          <div className="neutralisation">
            <h2 className="common-sub-title">
              {window.i18('TRIP_CARBON_CANCEL')}
              :
              {props.co2Formatted.toFixed(2)}
              {window.i18('CO2_KG')}
            </h2>
            <p>{window.i18('PASSY_CERTIFICATE')}</p>
            <p>{window.i18('TECHNICAL_FEES')}</p>
          </div>
          <div className="price">
            <div className="trees">
              <img
                src={tree}
                alt=""
              />
              <img
                src={tree}
                alt=""
              />
              <img
                src={tree}
                alt=""
              />
            </div>
            <h3 className="price-value">
              {props.amount && props.amount && props.amount.incl_taxes_fee
                ? (props.amount.incl_taxes_fee / 100).toFixed(2)
                : 0.0}
              €
            </h3>
          </div>
        </>
      )
        : (
          <div className="price">
            {props.showFees && (
              <div className="price-details">
                <button
                  className={`price-details-button ${expanded
                    ? 'expanded'
                    : ''}`}
                  onClick={() => setExpanded(!expanded)}
                >
                  {window.i18('VIEW_DETAILS')}
                  {' '}
                  <span className="chevron" />
                </button>
                {expanded && (
                  <>
                    <h3 className="price-value">
                      <span className="price-label">
                        {window.i18('BEFORE_TAX_FEES')}
                        {' '}
                        :
                      </span>
                      {' '}
                      {props.amount && props.amount.excl_taxes_agency_fee
                        ? (props.amount.excl_taxes_agency_fee / 100).toFixed(2)
                        : 0.0.toFixed(2)}
                      €
                    </h3>
                    <h4 className="price-value price-value--small">
                      <span className="price-label">
                        {window.i18('INCLUDING_TAXABLE')}
                        {' '}
                        :
                      </span>
                      {' '}
                      {props.amount && props.amount.taxable_agency_fee
                        ? (props.amount.taxable_agency_fee / 100).toFixed(2)
                        : 0.0.toFixed(2)}
                      €
                    </h4>
                    <h3 className="price-value">
                      <span className="price-label">
                        {window.i18(
                          'TOTAL_BEFORE_TAXES',
                        )}
                      </span>
                      {' '}
                      {props.amount && props.amount.excl_taxes_amount
                      && props.amount.excl_taxes_agency_fee
                        ? ((props.amount.excl_taxes_amount
                          + props.amount.excl_taxes_agency_fee) / 100).toFixed(2)
                        : 0.0.toFixed(2)}
                      €
                    </h3>
                    <h3 className="price-value">
                      <span className="price-label">
                        {window.i18(
                          'TAXES_ON_FEES',
                        )}
                      </span>
                      {' '}
                      {props.amount && props.amount.vat_on_taxable_agency_fee
                        ? (props.amount.vat_on_taxable_agency_fee
                          / 100).toFixed(2)
                        : 0.0.toFixed(2)}
                      €
                    </h3>
                  </>
                )}
              </div>
            )}
            <h3
              className={classNames('price-value', {
                'price-value--big': !props.amount
                  || props.amount.total_subsizided === null,
              })}
            >
              <span className="price-label">
                {window.i18('TOTAL_WITH_TAXES')}
                {' '}
              </span>
              {' '}
              {props.amount && props.amount.incl_taxes_amount
                ? (props.amount.incl_taxes_amount / 100).toFixed(2)
                : 0.0.toFixed(2)}
              €
            </h3>
            {props.amount && props.amount.total_subsizided != null
            && props.amount.total_subsizided > 0.0
            && (
              <>
                <h3 className="price-value price-value--big">
                  <span className="price-label">
                    {isCNAM() ? window.i18(
                      'PART_PAYED_BY_CNAM',
                    ) : `${window.i18(
                      'SUBVENTION',
                    )} :`}
                  </span>
                  {' -'}
                  {(props.amount.total_subsizided / 100).toFixed(2)}
                  €
                </h3>
                <h3 className="price-value price-value--big">
                  <span className="price-label">
                    {window.i18(
                      'TOTAL_INCLUDING_TAXES',
                    )}
                  </span>
                  {' '}
                  {(props.amount.to_pay_by_card / 100).toFixed(2)}
                  €
                </h3>
              </>
            )}
          </div>
        )}
    </div>
  );
};

export default PaymentFees;
