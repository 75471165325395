import React from 'react';

import Filters from './Filters';
import DateTime from '../../../components/SearchDetails/DateTime';
import Location from '../../../components/SearchDetails/Location';
import Passengers from '../../../components/SearchDetails/Passengers';
import Default from '../../../components/SearchDetails/Default';

const hotelSearchDetails = (props) => {
  switch (props.selected) {
    case 'place':
      return (
        <Location
          searchLocation={props.place}
          field="place"
          selectedLocation={props.search.place.location}
          setLocation={props.setPlaceLocation}
          setPlaceRadius={props.setPlaceRadius}
          radius={props.search.place.radius}
          isHotel
          isMobileDevice={props.isMobileDevice}
          suggestedAddresses={props.suggestedAddresses}
        />
      );
    case 'out':
      return (
        <DateTime
          {...props}
          field="arrival"
          isMobileDevice={props.isMobileDevice}
        />
      );
    case 'return':
      return (
        <DateTime
          {...props}
          field="departure"
          isMobileDevice={props.isMobileDevice}
        />
      );
    case 'passengers':
      return (
        <Passengers
          passengers={props.search.passengers}
          toggleSelected={props.toggleSelectedPassenger}
          ageCategories={props.ageCategories}
          openModal={props.openModal}
          cardsList={props.cardsList}
          userPermissions={props.userPermissions}
          travelers={props.travelers}
          searchTravelers={props.searchTravelers}
          hasTravelersSearch={props.hasTravelersSearch}
          addPassenger={props.addPassenger}
          editPassenger={props.editPassenger}
          checkForm={props.checkForm}
          rooms={props.search.rooms}
          addRoom={props.addRoom}
          removeRoom={props.removeRoom}
          isHotel
          isMobileDevice={props.isMobileDevice}
          field="passengers"
        />
      );
    case 'filters':
      return (
        <Filters
          field="filters"
          stars={props.filters.stars}
          setStars={props.setStars}
          breakfast={props.filters.breakfast}
          toggleBreakfast={props.toggleBreakfast}
          setNameContains={props.setNameContains}
          nameContains={props.search.nameContains}
          accessible={props.filters.accessible}
          toggleAccessible={props.toggleAccessible}
          isMobileDevice={props.isMobileDevice}
        />
      );
    default:
      return <Default co2={props.co2} isMobileDevice={props.isMobileDevice} />;
  }
};

export default hotelSearchDetails;
