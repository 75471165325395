import React from 'react';
import ReactDOM from 'react-dom';

const Content = ({
  isDefault, isMobileDevice, title, children,
}) => (
  <div className="search-form__details" style={{ display: isDefault && isMobileDevice ? 'none' : 'flex' }}>
    {title && <h2 className="search-form__details__title">{title}</h2>}
    {children}
  </div>
);

const Container = ({
  children, title, isMobileDevice, isDefault = false, label,
}) => {
  const [domNode, setDomNode] = React.useState(null);
  React.useEffect(() => {
    setDomNode(document.querySelector(`#${label}-portal`));
    if (domNode) {
      const top = ((top) => {
        let element = domNode;
        do {
          top += element.offsetTop || 0;
          element = element.offsetParent;
        } while (element);
        return top;
      })(0);
      if (isMobileDevice) {
        window.scrollTo(0, top - 180);
      }
    }
  }, [label, domNode, isMobileDevice]);
  if (isMobileDevice && !isDefault && domNode) {
    return ReactDOM.createPortal(
      <Content isMobileDevice={isMobileDevice} isDefault={isDefault}>
        {children}
      </Content>,
      domNode,
    );
  }
  return (
    <Content title={title} isMobileDevice={isMobileDevice} isDefault={isDefault}>
      {children}
    </Content>
  );
};

export default Container;
