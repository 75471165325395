import React from 'react';

import { connect } from 'react-redux';
import { renewActivationToken } from '../../App/actions';

class ActivateToken extends React.Component {
  handleSubmit = () => this.props.renewActivationToken(this.props.token).finally(() => {
    this.props.closeModal();
  })

  render() {
    return (
      <div className="user-modal">
        <div className="modal-with-tagline">
          <header className="tagline">
            <h1 className="common-title">{window.i18('EMAIL_ACTIVATION')}</h1>
          </header>
        </div>
        <div className="modal-inner">
          <p className="ask-token-error">
            {window.i18('ERROR_DURING_ACCOUNT_ACTIVATION')}
          </p>
          <div className="user-form">
            <div className="form-submit">
              <button type="submit" className="button-main" onClick={this.handleSubmit}>
                {window.i18('GET_NEW_ACTIVATION_LINK')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  renewActivationToken: (token) => dispatch(renewActivationToken(token)),
});

export default connect(
  null,
  mapDispatchToProps,
)(ActivateToken);
