import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
// Components
import LoginForm from '../../../components/LoginForm';
import SignupForm from '../../../components/CNAMSignup';
// Actions
import { sendLogin, signup } from '../../App/actions';
import { openModal } from '../../ModalWrapper/actions';

const propTypes = {
  active: PropTypes.bool.isRequired,
};

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignup: true,
      successfulSignup: false,
      email: '',
    };
  }

  setSignup = (isSignup) => this.setState({ isSignup })

  signupSuccess = (email) => {
    this.setState({
      successfulSignup: true,
      email,
    });
  }

  login = (user) => this.props.sendLogin(user)

  render() {
    const {
      active, signup, user, setActive, history, pathname,
    } = this.props;
    const {
      isSignup, successfulSignup, email,
    } = this.state;
    if (pathname === '/form/login') {
      this.setState({ isSignup: false });
      history.replace('/form');
    }
    if (pathname === '/form/signup') {
      this.setState({ isSignup: true });
      history.replace('/form');
    }
    return (
      <div className={classNames('step-block', { active })}>
        <h2 data-step="1" className="step-block__title">{isSignup && active ? 'Je crée mon compte' : 'Je m\'identifie'}</h2>
        {(active)
          ? (
            <>
              {successfulSignup
                ? (
                  <div className="step-block__form">
                    <p>Votre compte est créé.</p>
                    <p>
                      Pour valider votre
                      inscription, cliquez sur le lien dans l'email
                      que nous venons de vous envoyer sur
                      {' '}
                      <strong>{email}</strong>
                      . À tout de suite !
                    </p>
                    <button className="button-main" onClick={() => this.setState({ successfulSignup: false, isSignup: false })}>Me connecter</button>
                  </div>
                )
                : (
                  <>
                    <p className="switch-form">
                      {isSignup ? 'Déjà réservé sur ce site ?' : 'Première visite ? '}
                      <button onClick={() => this.setSignup(!isSignup)} className="button-link">{isSignup ? 'Connectez-vous' : 'Créer mon compte'}</button>
                    </p>
                    <p className="step-block__info">Les noms et prénoms sont ceux du bénéficiaire des soins et doivent être identiques à la prescription Améli.</p>
                    {isSignup ? <SignupForm setSignup={this.setSignup} handleSubmit={signup} signupSuccess={this.signupSuccess} className="step-block__form" />
                      : <LoginForm setSignup={this.setSignup} handleSubmit={this.login} btnText="Me connecter" className="step-block__form" />}
                    <p>
                      <a className="button-link" onClick={() => this.props.openModal('ResetPassword', this.props.options)}>Mot de passe oublié ?</a>
                    </p>
                  </>
                )}
            </>
          )
          : user && (
            <div className="step-block__completed">
              <p>
                Connecté en tant que
                {' '}
                <strong>
                  {user.firstname}
                  {' '}
                  {user.lastname}
                </strong>
              </p>
              <button className="button-link" onClick={() => setActive('account')}>Modifier</button>
            </div>
          )}
      </div>
    );
  }
}

Account.propTypes = propTypes;

const mapStateToProps = (state) => ({
  user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
  sendLogin: (user) => dispatch(sendLogin(user)),
  signup: (user) => dispatch(signup(user)),
  openModal: (modal, options) => dispatch(openModal(modal, options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
