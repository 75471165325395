import React from 'react';
import GreenScore from '../GreenScore';

const leavesLoader = (props) => (
  <div className="loader-wrapper common-wrapper">
    <div className="loader-wrapper-inner">
      <div className="loader-copy-wrapper">
        <h3 className="loader-copy common-grey-font">{props.sentence}</h3>
      </div>
      <div className="loader-illustration-wrapper">
        <span className="loader-illustration">
          <GreenScore
            completion={0}
            loader
          />
        </span>
      </div>
    </div>
  </div>
);

export default leavesLoader;
