import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
// Components
import Input from '../../../components/Inputs/Material';
// Functions
import { checkSubsidyNumber } from '../actions';
import { openModal } from '../../ModalWrapper/actions';
import { formatTransportModeName } from '../../../utils/converters';
import { getSvgIcon } from '../../../utils/icons';

function Number({
  active, checkSubsidyNumber, subsidy, setActive, openModal, openNotif,
}) {
  return (
    <>
      <div className={classNames('step-block', { active })}>
        <h2 data-step="2" className="step-block__title">Je fournis mon nº d'accord</h2>
        {active && (
          <>
            <Formik
              initialValues={{
                subsidy_number: subsidy ? subsidy.number : '',
              }}
              validationSchema={yup.object().shape({
                subsidy_number: yup.string().required('Ce champ est nécessaire'),
              })}
              onSubmit={(values, actions) => {
                const subsidyNumber = values.subsidy_number.replace(/\s/g, '');
                checkSubsidyNumber(subsidyNumber)
                  .then(() => actions.setSubmitting(false), (error) => {
                    openModal('CNAMSubsidyError', { error });
                    actions.setSubmitting(false);
                  });
              }}
            >
              {({
                setFieldValue, values, errors, touched, isSubmitting, status,
              }) => (
                <Form className="step-block__form">
                  <span className="submit-error">
                    {status}
                  </span>
                  <div className="row">
                    <div className="col col-12">
                      <Input
                        onChange={(value) => setFieldValue('subsidy_number', value)}
                        name="subsidy_number"
                        value={values.subsidy_number}
                        placeholder="972201965434321001"
                        type="text"
                        label="Numéro d'accord"
                        error={errors.subsidy_number}
                        touched={touched.subsidy_number}
                      />
                    </div>
                    <div>
                      <a onClick={openNotif} className="relative-tooltip">?</a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-12">
                      <button className="button-main" type="submit" disabled={isSubmitting}>Valider</button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
        {!active && subsidy && subsidy.number && subsidy.searchRequest && (
          <div className="step-block__completed">
            <p>
              Demande n°
              <strong>{subsidy.number}</strong>
            </p>
            <button className="button-link" onClick={() => setActive('number')}>Modifier</button>
          </div>
        )}
      </div>
      {!active && subsidy && subsidy.searchRequest && (
        <div className="step-block step-info">
          <div className="step-info__icon">
            {getSvgIcon('information', 'ui/')}
          </div>
          <div className="step-info__content">
            <p>
              Vous disposez de
              {' '}
              {subsidy.searchRequest.max_count}
              {' '}
              trajet
              {subsidy.searchRequest.max_count > 1 ? 's' : ''}
              {' '}
              aller-retour pris en charge à
              {' '}
              {subsidy.searchRequest.rate}
              %
              {' '}
              {subsidy.searchRequest.transport_modes ? 'en ' : ''}
              {subsidy.searchRequest.transport_modes ? subsidy.searchRequest.transport_modes.map(formatTransportModeName).join(' ou ') : ''}
            </p>
            <span>
              {subsidy.searchRequest.remaining}
              {' '}
              restant
              {subsidy.searchRequest.remaining > 1 ? 's' : ''}
            </span>
            { subsidy.searchRequest.travel_policy_html
            && (
              <div className="pve-content">
                <h3>Rappel des conditions de prise en charge par l'assurance maladie :</h3>
                <span dangerouslySetInnerHTML={{ __html: subsidy.searchRequest.travel_policy_html }} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
const mapDispatchToProps = (dispatch) => ({
  checkSubsidyNumber: (number) => dispatch(checkSubsidyNumber(number)),
  openNotif: () => dispatch(openModal('CNAMNotif')),
});

export default connect(null, mapDispatchToProps)(Number);
