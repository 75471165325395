import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
// Components
import MaterialDate from '../../components/Inputs/MaterialDate';
import Input from '../../components/Inputs/Material';
import Select from '../../components/Inputs/MaterialSelect';
import CardInput from '../../components/CardInput';
import DocumentsForm from '../../components/DocumentsForm';
// import Radio from '../../components/Radio';
import Checkbox from '../../components/Checkbox';
import Switcher from '../../components/Switcher';
import { formatDateForAPI } from '../../utils/converters';

const SecondPage = ({
  back, handicaps, cardTypes, secondValues, onSubmit, firstValues,
  //  openModal,
}) => (
  <>
    <h1>{window.i18('FINALIZE_YOUR_SIGNUP')}</h1>
    <button onClick={back} className="button-link">{window.i18('GO_BACK')}</button>
    <p>{window.i18('CHOSE_TO_STORE_DATA')}</p>
    <Formik
      initialValues={{
        cards: [],
        hasHandicap: false,
        documents: [],
        gdpr_consent: false,
        data_accuracy: false,
        gender: '',
        birthdate: '',
        phone: '',
        ...secondValues,
      }}
      validationSchema={yup.object().shape({
        gender: yup.string().required(window.i18('PLEASE_FILL')),
        birthdate: yup.date().required(window.i18('PLEASE_FILL')).min('1900-01-01', window.i18('VALID_DATE')).max(new Date().toISOString().slice(0, 10), window.i18('VALID_DATE')),
        phone: yup.string().required(window.i18('PLEASE_FILL')),
        gdpr_consent: yup.bool().oneOf([true], window.i18('PLEASE_CHECK')),
        data_accuracy: yup.bool().oneOf([true], window.i18('PLEASE_CHECK')),
      })}
      onSubmit={(values) => {
        if (values.cards) {
          values.cards.forEach((card) => {
            if (card.expiration_date != null && card.expiration_date !== '') {
              card.expiration_date = formatDateForAPI(card.expiration_date);
            }
          });
        }
        values.travel_documents = values.documents;
        delete values.documents;
        onSubmit(values);
      }}
    >
      {({
        values, touched, errors, setFieldValue,
      }) => (
        <Form>
          <div className="row">
            <div className="col col-12">
              <Switcher
                first={window.i18('MADAM')}
                second={window.i18('SIR')}
                value={values.gender === 'MS'}
                disabled={!values.gender}
                handleClick={(value) => (value === 1
                  ? setFieldValue('gender', 'MS')
                  : setFieldValue('gender', 'MR'))}
                small
                touched={touched.gender}
              />
              {errors.gender && (
                <p className="material-input__error">{errors.gender}</p>
              )}
            </div>
          </div>
          <div className="row m-top">
            <div className="col col-6">
              <MaterialDate
                onChange={(value) => setFieldValue('birthdate', value)}
                name="birthdate"
                value={values.birthdate}
                placeholder="01/01/1970"
                label={window.i18('BIRTHDATE')}
                error={errors.birthdate}
                touched={touched.birthdate}
                withYearSelect
              />
            </div>
            <div className="col col-6">
              <Input
                onChange={(value) => setFieldValue('phone', value)}
                name="phone"
                value={values.phone}
                placeholder="0612345678"
                type="text"
                label={window.i18('PHONE_NUMBER')}
                error={errors.phone}
                touched={touched.phone}
              />
            </div>
          </div>
          <div className="row m-top">
            <Checkbox
              name="hasHandicap"
              checked={values.hasHandicap}
              value={window.i18('HAS_HANDICAP')}
              onChange={() => setFieldValue('hasHandicap', !values.hasHandicap)}
            />
          </div>
          {values.hasHandicap && (
            <>
              <div className="row m-top">
                <div className="col col-6">
                  <Select
                    onChange={(value) => setFieldValue('handicap', value)}
                    name="handicap"
                    value={values.handicap}
                    placeholder={window.i18('FILL_YOUR_HANDICAP')}
                    type="text"
                    label={window.i18('HANDICAP')}
                    error={errors.handicap}
                    touched={touched.handicap}
                    options={(handicaps && handicaps.types && handicaps.types.map((item) => ({ value: item.name, name: item.label }))) || []}
                    nullable
                  />
                </div>
                <div className="col col-6">
                  <Select
                    onChange={(value) => setFieldValue('wheelchair', value)}
                    name="wheelchair"
                    value={values.wheelchair}
                    type="text"
                    label={window.i18('WHEELCHAIR')}
                    error={errors.wheelchair}
                    touched={touched.wheelchairs}
                    options={(handicaps && handicaps.wheelchairs && handicaps.wheelchairs.map((item) => ({ value: item.name, name: item.label }))) || []}
                    nullable
                  />
                </div>
              </div>
              {values.wheelchair && (
                <>
                  <div className="row m-top">
                    <div className="col col-6">
                      <Input
                        onChange={(value) => setFieldValue('wheelchair_dimensions', value)}
                        name="wheelchair_dimensions"
                        value={values.wheelchair_dimensions}
                        placeholder="20 x 10 x 15"
                        type="text"
                        label={window.i18('WHEELCHAIR_DIMENSIONS')}
                        error={errors.wheelchair_dimensions}
                        touched={touched.wheelchair_dimensions}
                      />
                    </div>
                    <div className="col col-6">
                      <Input
                        onChange={(value) => setFieldValue('wheelchair_weight', value)}
                        name="wheelchair_weight"
                        value={values.wheelchair_weight}
                        placeholder="20"
                        type="text"
                        label={window.i18('WHEELCHAIR_WEIGHT')}
                        error={errors.wheelchair_weight}
                        touched={touched.wheelchair_weight}
                      />
                    </div>
                  </div>
                  <div className="row m-top">
                    <div className="col col-6">
                      <Input
                        onChange={(value) => setFieldValue('wheelchair_wheel_thickness', value)}
                        name="wheelchair_wheel_thickness"
                        value={values.wheelchair_wheel_thickness}
                        placeholder="20"
                        type="text"
                        label={window.i18('WHEELS_WIDTH')}
                        error={errors.wheelchair_wheel_thickness}
                        touched={touched.wheelchair_wheel_thickness}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="row m-top">
                <Checkbox
                  name="need_accompanying"
                  checked={values.need_accompanying}
                  value={window.i18('INVALIDITY_CARD')}
                  onChange={() => setFieldValue('need_accompanying', !values.need_accompanying)}
                />
              </div>
            </>
          )}
          <h2 className="m-y">{window.i18('LOYALTY_AND_REDUCTION_CARDS')}</h2>
          {values.cards.map((card, i) => (
            <CardInput cardtypes={cardTypes || []} card={card} values={values.cards} errors={errors} touched={touched} setFieldValue={setFieldValue} index={i} key={card.uid} withDate />
          ))}
          <button className="button-secondary" type="button" disabled={values.cards.find((card) => card.cardtype_id === '') !== undefined} onClick={() => setFieldValue('cards', [...values.cards, { cardtype_id: '', cardnumber: '' }])}>{window.i18('ADD_CARD')}</button>
          <h2 className="m-y">{window.i18('DOCUMENTS')}</h2>
          <DocumentsForm documents={values.documents} setFieldValue={setFieldValue} values={{ ...firstValues, civilty: values.civilty }} />
          <div className="row m-top">
            <Checkbox
              name="gdpr_consent"
              checked={values.gdpr_consent}
              value={window.i18('CONSENT_STORAGE')}
              onChange={() => setFieldValue('gdpr_consent', !values.gdpr_consent)}
            />
          </div>
          {errors.gdpr_consent && (
            <div className="row">
              <p className="material-input__error">{errors.gdpr_consent}</p>
            </div>
          )}
          <div className="row m-top">
            <a className="button-tertiary" href="https://www.thetreep.com/politiqueconfidentialite.html" target="_blank" rel="noopener noreferrer">{window.i18('CONSULT_PRIVACY_POLICY')}</a>
          </div>
          <div className="row m-top">
            <Checkbox
              name="data_accuracy"
              checked={values.data_accuracy}
              value={window.i18('AGGREE_FORM_RESPONSABILITY')}
              onChange={() => setFieldValue('data_accuracy', !values.data_accuracy)}
            />
          </div>
          {errors.data_accuracy && (
            <div className="row">
              <p className="material-input__error">{errors.data_accuracy}</p>
            </div>
          )}
          <div className="row justify-center p-top m-top">
            <button type="submit" className="button-main" disabled={!values.data_accuracy || !values.gdpr_consent}>{window.i18('CONFIRM')}</button>
          </div>
        </Form>
      )}
    </Formik>
  </>
);

export default SecondPage;
