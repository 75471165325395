import React from 'react';
import infoAccord from '../../../../images/CNAM/notif-type.jpg';

function CNAMNotif() {
  return (
    <>
      <div className="modal-notif">
        <div className="modal-with-tagline">
          <header className="tagline">
            <h1 className="common-title">Numéro d’accord</h1>
          </header>
        </div>
        <p className="text-notif">Vous pourrez trouver votre numéro d’accord dans le courrier envoyé par l’Assurance Maladie :</p>
        <img className="notif-img" src={infoAccord} alt="" />
      </div>
    </>
  );
}

export default CNAMNotif;
