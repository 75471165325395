import moment from 'moment';
import React from 'react';
import PVE from '../../../components/PVE';

const BusinessFooter = (props) => (
  <section className="trip-search-footer">
    {(props.user.company.travel_policy || (props.user.company.agency && props.user.company.agency.toLowerCase()))
      && <PVE user={props.user} pve={props.user.company.travel_policy} />}
    {props.user?.cards?.filter((card) => card.expiration_date)?.length > 0 && (
      <div className="user-pve">
        <h2>{window.i18('CARDS_EXPIRATION_DATES')}</h2>
        {props.user.cards.filter((card) => card.expiration_date).map((card) => (
          <p key={card.cardtype.name}>
            {card.cardtype.name}
            :
            {' '}
            {moment(card.expiration_date).format('LL')}
          </p>
        ))}
      </div>
    )}
  </section>
);

export default BusinessFooter;
