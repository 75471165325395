import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  disabled: false,
};

const Checkbox = ({
  disabled, checked, onChange, value, name, small,
}) => (
  <label className={classNames('common-checkbox', { disabled }, { 'small-checkbox': small })}>
    <input type="checkbox" checked={checked} onChange={onChange} name={name} />
    <span className="checkmark" />
    {value && <span className="common-checkbox__value">{value}</span>}
  </label>
);

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
