import React, { Component } from 'react';
import classNames from 'classnames';

// Components
import { isEqual } from 'lodash-es';
import Container from './Container';
import CardsList from '../CardsList';
import AutocompleteTravelers from '../AutoComplete/travelers';

// Functions
import { getSvgIcon } from '../../utils/icons';
import { shortUserName } from '../../utils/converters';

export default class PassengersDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      travelersFocused: false,
      isTyping: false,
    };
    this.typingTimeout = null;
  }

  addPassenger = (traveler) => {
    const {
      passengers, editPassenger, addPassenger, checkForm,
    } = this.props;
    const passenger = {
      birthdate: traveler.birthdate,
      cards: traveler.cards,
      gender: traveler.gender,
      firstname: traveler.firstname,
      lastname: traveler.lastname,
      email: traveler.email,
      handicap: traveler.handicap,
      wheelchair: traveler.wheelchair,
      disability_degree: traveler.disability_degree,
      wheelchair_dimensions: traveler.wheelchair_dimensions,
      wheelchair_weight: traveler.wheelchair_weight,
      wheelchair_wheel_thickness: traveler.wheelchair_wheel_thickness,
      accompanying_dog: traveler.accompanying_dog,
      accompanying_needed: traveler.accompanying_needed,
      profile_id: traveler.uid,

      phone: traveler.phone,
      selected: true,
      isSimple: false,
    };
    if (
      passengers.length === 1
          && (
            isEqual(passengers[0], {
              id: 0, cards: null, selected: true, isSimple: true,
            })
            || isEqual(passengers[0], {
              id: 0, cards: null, selected: false, isSimple: true,
            })
            || isEqual(passengers[0], {
              id: 0, cards: [], selected: true, isSimple: true,
            })
          )
    ) {
      editPassenger({ id: 0, ...passenger });
    } else {
      addPassenger(passenger);
    }
    checkForm();
    this.setState({ query: '' });
  }

  onChange = (e) => {
    // We wait for the user to stop typing before sending the GET /places call
    const val = e.target.value;

    this.setState({
      query: val,
    });

    if (this.state.isTyping) {
      clearTimeout(this.typingTimeout);
    } else {
      this.setState({ isTyping: true });
    }

    if (val.length) {
      this.typingTimeout = setTimeout(() => {
        this.props.searchTravelers(val);
        this.setState({ isTyping: false });
      }, 700);
    } else {
      this.setState({ isTyping: false });
    }
  }

  render() {
    const {
      passengers,
      toggleSelected,
      openModal,
      cardsList,
      userPermissions,
      travelers,
      addPassenger,
      addRoom,
      removeRoom,
      rooms,
      isHotel,
      hasTravelersSearch,
      isMobileDevice,
      field,
    } = this.props;
    const { travelersFocused, query } = this.state;
    const tvlLabelDet = isHotel ? window.i18('A_FEM') : window.i18('A_MASC');
    const tvlLabel = isHotel ? window.i18('PERSON') : window.i18('PASSENGER');
    return (
      <Container title={`${window.i18('SELECT_THE')} ${tvlLabel}(s) :`} isMobileDevice={isMobileDevice} label={field}>
        <div className="passengers bordered-block p-all">
          {hasTravelersSearch && (
          <div className="passengers__travelers">
            <input
              type="text"
              className="passengers__input"
              onFocus={() => this.setState({ travelersFocused: true })}
              onBlur={() => this.setState({ travelersFocused: false })}
              // disabled={!travelers || travelers.fetching === true}
              value={query}
              onChange={this.onChange}
              placeholder={window.i18('SEARCH_EMPLOYEE')}
            />
            {travelers
              && travelersFocused
              && query && (
              <AutocompleteTravelers
                travelers={travelers}
                handleClick={this.addPassenger}
                value={query}
              />
            )}
          </div>
          )}
          <div className="passengers__list">
            <ul>
              {passengers.map((passenger, i) => {
                const {
                  selected,
                  cards,
                  handicap,
                  accompanying,
                } = passenger;

                const name = shortUserName(passenger);

                let displayedName = '';

                // If the passenger has a name, we display it
                if (name !== '') {
                  displayedName += name;
                } else {
                // If we don't know the passengers name nor age, the API will consider him as an adult, so we display that
                  displayedName = window.i18('ADULT');
                }

                return (
                  <li className="passengers__item" key={`passenger-${i}`}>
                    <label className="common-checkbox">
                      <input
                        type="checkbox"
                        checked={selected}
                        onChange={() => toggleSelected(i)}
                      />
                      <span className="checkmark" />
                    </label>

                    <button
                      className={classNames('name', { 'name--internal': passenger.profile_id })}
                      onClick={() => openModal({ passenger, isHotel })}
                    >
                      {passenger.profile_id && (
                      <div className="common-tooltip__parent">
                        {getSvgIcon('use-user', 'ui/')}
                        <div className="common-tooltip">
                          <div className="common-tooltip__content">
                            {window.i18('EMPLOYEE')}
                          </div>
                        </div>
                      </div>
                      )}
                      {displayedName}
                      {' '}
                      {handicap && handicap !== ''
                      && (
                        <span className="handicap">
                          {getSvgIcon('handicap', 'ui/')}
                        </span>
                      )}
                      {accompanying
                      && (
                        <span className="handicap" title="Accompagnateur">
                          {getSvgIcon('handicap', 'ui/')}
                          {' '}
                          <span>{window.i18('ACC')}</span>
                        </span>
                      )}
                      {' '}
                      {getSvgIcon('edit', 'ui/')}
                    </button>
                    {cards && cards.length > 0 && (
                    <CardsList
                      cards={cards}
                      cardsList={cardsList}
                      isMobileDevice={isMobileDevice}
                    />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <button className="passengers__add" onClick={() => addPassenger()}>
          {getSvgIcon('add', 'ui/')}
          {userPermissions.isTravelManager
            ? `${window.i18('CREATE')} ${tvlLabelDet} ${tvlLabel}`
            : `${window.i18('ADD')} ${tvlLabelDet} ${tvlLabel}`}
        </button>
        {isHotel && (
          <div className="hotel-search-rooms">
            <p>
              {window.i18('IN')}
              <button aria-label="Enlever une chambre" onClick={removeRoom} disabled={rooms === 1}>-</button>
              <span>
                {rooms}
                {' '}
                {rooms > 1 ? window.i18('ROOMS') : window.i18('ROOM')}
              </span>
              <button aria-label={window.i18('ADD_ROOM')} onClick={addRoom} disabled={rooms === 9}>+</button>
            </p>
          </div>
        )}
      </Container>
    );
  }
}
