import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
// Misc
import { displayPrice, formatCabinClass } from '../../../utils/converters';
import {
  setSelectedProposal, setSelectedCabin, setSelectedChoice, toggleFilter,
} from '../actions';
import TripPriceButton from '../../../components/TripPriceButton';
import { getProposalsFromResults } from '../selectors';
import Carrier from '../../../components/Carrier';
import Leaf from '../../../components/GreenScore/Leaf';
import { FILTER_TRANSIT_FLIGHT } from '../constants';

function Trips() {
  const dispatch = useDispatch();
  const { isMobileDevice } = useSelector((state) => state.app);
  const { results } = useSelector((state) => state);
  const proposals = getProposalsFromResults(results);
  React.useEffect(() => {
    if (results[results.currentTrip].selectedTransportMode === 'flight' && proposals.length < 5) {
      dispatch(toggleFilter(FILTER_TRANSIT_FLIGHT));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cabinClasses = [];
  // We extract cabin classes from every choices
  for (let i = 0; i < proposals.length; i += 1) {
    const proposal = proposals[i];
    for (let j = 0; j < proposal.choices.length; j += 1) {
      if (!cabinClasses.includes(proposal.choices[j].cabin)) {
        cabinClasses.push(proposal.choices[j].cabin);
      }
    }
  }

  return (
    <div className={classNames('result-list', 'col', isMobileDevice ? 'col-12' : 'col-7')}>
      {!proposals
        ? (
          <p>
            {window.i18('LOADING')}
            ...
          </p>
        )
        : (
          <>
            {proposals.length ? (
              <>
                <div className="row result-list__header">
                  <p>{moment(proposals[0].departure).format('dddd Do MMMM YYYY')}</p>
                  {!isMobileDevice && (
                  <ul>
                    {cabinClasses.map((cabinClass, i) => (
                      <li key={`cabin-${i}`}>{formatCabinClass(cabinClass)}</li>
                    ))}
                  </ul>
                  )}
                </div>
                {proposals.map((proposal, i) => (
                  <TripResult trip={proposal} cabinClasses={cabinClasses} key={i} />
                ))}
              </>
            ) : (
              <p>{window.i18('NO_MATCHING_RESULTS')}</p>
            )}
          </>
        )}
    </div>
  );
}

function toHHMM(duration) {
  const d = parseInt(duration, 10);
  let hours = Math.floor(d / 3600);
  let minutes = Math.floor((d - (hours * 3600)) / 60);

  if (hours < 10) { hours = `0${hours}`; }
  if (minutes < 10) { minutes = `0${minutes}`; }
  return `${hours}h${minutes}`;
}

function TripResult({ trip, cabinClasses }) {
  const dispatch = useDispatch();
  const { isMobileDevice } = useSelector((state) => state.app);
  const {
    selectedProposal, selectedTransportMode, selectedCabin, places, carriers,
  } = useSelector((state) => state.results[state.results.currentTrip]);
  const { choices } = trip;
  // For each cabin class of a trip, we associate the choice with the minimal price
  const choicesByCabin = cabinClasses.map((cabinClass) => {
    const cabinChoices = choices.filter((choice) => choice.cabin === cabinClass);
    // If there are available choices for this cabin class, we return the one with the lowest price, else we return null
    return cabinChoices.length > 0
      ? cabinChoices.reduce((previousChoice, choice) => (previousChoice.price < choice.price ? previousChoice : choice), {})
      : null;
  });

  const setSelected = (tripId, cabinId = 0) => {
    dispatch(setSelectedProposal(tripId));
    dispatch(setSelectedCabin(cabinClasses[cabinId]));
    dispatch(setSelectedChoice(choices.filter((choice) => choice.cabin === cabinClasses[cabinId])?.[0]?.id));
  };

  return (
    <>
      <div className="result-item" id={selectedProposal[selectedTransportMode] === trip.id ? 'selected-proposal' : null}>
        <div className="trip-details">
          <div className="flex justify-between">
            <div className="trip-details__upper">
              <div>
                <p className="trip-details__time">{moment(trip.departure).format('HH:mm')}</p>
                <p className="trip-details__location">{places[trip.from].name}</p>
              </div>
              <div>
                <p className="trip-details__time">{moment(trip.arrival).format('HH:mm')}</p>
                <p className="trip-details__location">{places[trip.to].name}</p>
              </div>
            </div>
            <p className="trip-details__co2">
              <Leaf completion={1} />
              {(Math.round(trip.co2Emission * 10) / 10000).toFixed(trip.co2Emission > 10000 ? 0 : 1)}
              kg CO2e
            </p>
          </div>
          <div className="trip-details__lower">
            <div>
              <p className="trip-details__duration">
                {toHHMM(trip.duration)}
                {' '}
                -
                {' '}
                {trip.transitCount >= 1 ? `${trip.transitCount} ${window.i18('CONNECTIONS')}` : window.i18('DIRECT')}
              </p>
            </div>
            <Carrier carrier={trip.marketingCarrier} carriers={carriers} transport={selectedTransportMode} />
          </div>
        </div>
        <div className="result-item-prices">
          {isMobileDevice
            ? (
              <button className={classNames('result-item-prices__btn', { 'result-item-prices__btn--selected': trip.id === selectedProposal })} onClick={() => setSelected(trip.id)}>
                <p>{window.i18('STARTING_FROM')}</p>
                {displayPrice(Math.min(...choices.map((c) => c.price)) / 100)}
              </button>
            )
            : (
              choicesByCabin.map((choice, i) => (
                !choice
                  ? <p key={`choice-${i}`} className="result-item-prices__empty">{window.i18('UNAVAILABLE')}</p>
                  : (
                    <TripPriceButton selected={trip.id === selectedProposal[selectedTransportMode] && cabinClasses[i] === selectedCabin[selectedTransportMode]} key={`choice-${i}`} onClick={() => setSelected(trip.id, i)} choice={choice} />
                  )
              ))
            )}
        </div>
      </div>
    </>
  );
}

export default Trips;
