import React from 'react';
import ReactDOM from 'react-dom';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import menusvg from '../../../images/icons/ui/menu_icon.svg';
import { getSvgIcon } from '../../utils/icons';

// eslint-disable-next-line func-names
const SubNavSideBar = (props) => {
  const links = props.links.map((link) => (
    <Link
      className="menu-item"
      to={link.route}
      exact={link.exact}
      onClick={() => {
        closeMenu();
      }}
    >
      <li>
        {getSvgIcon(link.svg, 'ui/')}
        <span>{link.label}</span>
      </li>
    </Link>
  ));

  const [menuOpen, setMenuOpen] = React.useState(false);
  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return ReactDOM.createPortal(
    <Menu
      {...props}
      customBurgerIcon={(
        <img
          src={menusvg}
          alt=""
        />
)}
      noTransition
      noOverlay
      isOpen={menuOpen}
      onStateChange={(state) => handleStateChange(state)}
      right
    >
      {links}
    </Menu>,
    document.querySelector('#root'),
  );
};

export default SubNavSideBar;
