import { OPEN_MODAL, CLOSE_MODAL } from './constants';

export function openModal(name, options) {
  return {
    type: OPEN_MODAL,
    name,
    options,
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL,
  };
}
