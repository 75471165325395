import React from 'react';
import * as yup from 'yup';

import { withFormik } from 'formik';
import moment from 'moment';
import Checkbox from '../Checkbox';
import Input from '../Inputs/Material';
import Address from '../Inputs/MaterialAddress';
import Select from '../Inputs/MaterialSelect';
import Switcher from '../Switcher';
import countries from '../SelectCountry/ISO-3166';

import { formatDateFromAPI, formatDateForAPI } from '../../utils/converters';

const options = countries.map((c) => ({
  value: c.value,
  name: c.label,
}));

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.autocomplete = this.autocomplete.bind(this);
    this.state = {
      data_accuracy: false,
    };
  }

  autocomplete(uid) {
    const user = this.props.travelers.filter((traveler) => traveler.uid === uid)[0];
    if (user.birthdate) {
      const birthdate = formatDateForAPI(user.birthdate);
      user.birthdate = formatDateFromAPI(birthdate).slice(0, 10);
    }
    if (user.cards) {
      user.cards = user.cards.filter(
        (card) => card.cardtype.card_type === 'loyalty_card',
      );
    }
    if (user.cards && user.cards.length === 1) {
      user.loyalty_card_type = user.cards[0].cardtype_id;
      user.loyalty_card = user.cards[0].cardnumber;
    }
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const input in initialUser) {
      this.props.setFieldValue(input, user[input] ? user[input] : '');
    }
  }

  cardChange(e) {
    if (e.target.value.includes('card-')) {
      this.props.setFieldValue('loyalty_card', e.target.value.slice(5));
    }
    this.props.handleChange(e);
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="user-form">
          <span className="submit-error">{this.props.status}</span>
          <div className="row m-top">
            <div className="col col-12">
              <Switcher
                first="Madame"
                second="Monsieur"
                value={this.props.values.gender === 'MS'}
                disabled={!this.props.values.gender}
                handleClick={(value) => (value === 1
                  ? this.props.setFieldValue('gender', 'MS')
                  : this.props.setFieldValue('gender', 'MR'))}
                small
                touched={this.props.touched.gender}
              />
              {this.props.errors.gender && (
              <p className="material-input__error">{this.props.errors.gender}</p>
              )}
            </div>
          </div>
          <div className="row m-top">
            <div className="col col-12">
              <Input
                type="text"
                label={window.i18('FIRSTNAME')}
                value={this.props.values.firstname}
                placeholder="John"
                name="firstname"
                onChange={(value) => this.props.setFieldValue('firstname', value)}
                onBlur={this.props.handleBlur}
                error={
              this.props.touched.firstname
                            && this.props.errors.firstname
            }
                travelers={this.props.travelers}
                handleClick={(traveler) => this.autocomplete(traveler.uid)}
              />
            </div>
          </div>
          <div className="row m-top">
            <div className="col col-12">
              <Input
                type="text"
                label={window.i18('LASTNAME')}
                value={this.props.values.lastname}
                placeholder="Snow"
                name="lastname"
                onChange={(value) => this.props.setFieldValue('lastname', value)}
                onBlur={this.props.handleBlur}
                error={
              this.props.touched.lastname
                            && this.props.errors.lastname
            }
                travelers={this.props.travelers}
                handleClick={(traveler) => this.autocomplete(traveler.uid)}
              />
            </div>
          </div>
          <div className="row m-top">
            <div className="col col-12">
              <Input
                type="username"
                label={window.i18('USERNAME')}
                value={this.props.values.username}
                placeholder="john.doe"
                name="username"
                onChange={(value) => this.props.setFieldValue('username', value)}
                onBlur={this.props.handleBlur}
                error={
              this.props.touched.username && this.props.errors.username
            }
                travelers={this.props.travelers}
                handleClick={(traveler) => this.autocomplete(traveler.uid)}
              />
            </div>
          </div>
          <div className="row m-top">
            <div className="col col-12">
              <Input
                type="email"
                label={window.i18('EMAIL')}
                value={this.props.values.email}
                placeholder={window.i18('EMAIL')}
                name="email"
                onChange={(value) => this.props.setFieldValue('email', value)}
                onBlur={this.props.handleBlur}
                error={
              this.props.touched.email && this.props.errors.email
            }
                travelers={this.props.travelers}
                handleClick={(traveler) => this.autocomplete(traveler.uid)}
              />
            </div>
          </div>
          <div className="row m-top">
            <div className="col col-12">
              <Input
                type="text"
                label={window.i18('PHONE')}
                value={this.props.values.phone}
                placeholder="0987654321"
                name="phone"
                onChange={(value) => this.props.setFieldValue('phone', value)}
                onBlur={this.props.handleBlur}
                error={
              this.props.touched.phone && this.props.errors.phone
            }
              />
            </div>
          </div>
          <div className="row m-top">
            <div className="col col-12">
              <Input
                type="date"
                label={window.i18('BIRTHDATE')}
                value={this.props.values.birthdate}
                name="birthdate"
                onChange={(value) => this.props.setFieldValue('birthdate', value)}
                onBlur={this.props.handleBlur}
                error={
                  this.props.touched.birthdate
                    && this.props.errors.birthdate
                }
              />
            </div>
          </div>
          <div className="row m-top">
            <div className="col col-12">
              <Address
                name="address"
                value={this.props.values.address && this.props.values.address.description}
                placeholder="1 rue de Rivoli, Paris"
                label={window.i18('ADDRESS')}
                onClick={(value) => this.props.setFieldValue('address', value)}
                error={this.props.errors.address}
                touched={this.props.touched.address}
              />
            </div>
          </div>
          <div className="row m-top">
            <div className="col col-6">
              <Input
                type="text"
                label={window.i18('BIRTH_CITY')}
                value={this.props.values.city_of_birth}
                placeholder="Paris"
                name="city_of_birth"
                onChange={(value) => this.props.setFieldValue('city_of_birth', value)}
                onBlur={this.props.handleBlur}
                touched={this.props.touched.city_of_birth}
                error={this.props.touched.city_of_birth && this.props.errors.city_of_birth}
              />
            </div>
            <div className="col col-6">
              <Select
                name="birth_country"
                value={this.props.values.birth_country}
                placeholder="France"
                label={window.i18('BIRTH_COUNTRY')}
                onChange={(value) => this.props.setFieldValue('birth_country', value)}
                error={this.props.errors.birth_country}
                touched={this.props.touched.birth_country}
                options={options}
              />
            </div>
          </div>
          <div className="row m-top">
            <Checkbox
              name="hasHandicap"
              checked={this.props.values.hasHandicap}
              value={window.i18('HAS_HANDICAP')}
              onChange={() => {
                this.props.setFieldValue('hasHandicap', !this.props.values.hasHandicap);
                if (!this.props.values.hasHandicap) {
                  this.props.setFieldValue('handicap', null);
                  this.props.setFieldValue('wheelchair', null);
                  this.props.setFieldValue('wheelchair_dimensions', null);
                  this.props.setFieldValue('wheelchair_weight', null);
                  this.props.setFieldValue('wheelchair_wheel_thickness', null);
                  this.props.setFieldValue('need_accompanying', false);
                }
              }}
            />
          </div>
          {(this.props.values.hasHandicap) && (
            <>
              <div className="row m-top">
                <div className="col col-12">
                  <Select
                    onChange={(value) => this.props.setFieldValue('handicap', value)}
                    name="handicap"
                    value={this.props.values.handicap}
                    placeholder={window.i18('FILL_YOUR_HANDICAP')}
                    type="text"
                    label={window.i18('HANDICAP')}
                    error={this.props.errors.handicap}
                    touched={this.props.touched.handicap}
                    options={(this.props.handicaps && this.props.handicaps.types && this.props.handicaps.types.map((item) => ({ value: item.name, name: item.label }))) || []}
                    nullable
                  />
                </div>
              </div>
              <div className="row m-top">
                <div className="col col-12">
                  <Select
                    onChange={(value) => this.props.setFieldValue('wheelchair', value)}
                    name="wheelchair"
                    value={this.props.values.wheelchair}
                    type="text"
                    label={window.i18('WHEELCHAIR')}
                    error={this.props.errors.wheelchair}
                    touched={this.props.touched.wheelchairs}
                    options={(this.props.handicaps && this.props.handicaps.wheelchairs && this.props.handicaps.wheelchairs.map((item) => ({ value: item.name, name: item.label }))) || []}
                    nullable
                  />
                </div>
              </div>
              {this.props.values.wheelchair && (
                <>
                  <div className="row m-top">
                    <div className="col col-12">
                      <Input
                        onChange={(value) => this.props.setFieldValue('wheelchair_dimensions', value)}
                        name="wheelchair_dimensions"
                        value={this.props.values.wheelchair_dimensions}
                        placeholder="20 x 10 x 15"
                        type="text"
                        label={window.i18('WHEELCHAIR_DIMENSIONS')}
                        error={this.props.errors.wheelchair_dimensions}
                        touched={this.props.touched.wheelchair_dimensions}
                      />
                    </div>
                  </div>
                  <div className="row m-top">
                    <div className="col col-12">
                      <Input
                        onChange={(value) => this.props.setFieldValue('wheelchair_weight', value)}
                        name="wheelchair_weight"
                        value={this.props.values.wheelchair_weight}
                        placeholder="20"
                        type="text"
                        label={window.i18('WHEELCHAIR_WEIGHT')}
                        error={this.props.errors.wheelchair_weight}
                        touched={this.props.touched.wheelchair_weight}
                      />
                    </div>
                  </div>
                  <div className="row m-top">
                    <div className="col col-12">
                      <Input
                        onChange={(value) => this.props.setFieldValue('wheelchair_wheel_thickness', value)}
                        name="wheelchair_wheel_thickness"
                        value={this.props.values.wheelchair_wheel_thickness}
                        placeholder="20"
                        type="text"
                        label={window.i18('WHEELS_WIDTH')}
                        error={this.props.errors.wheelchair_wheel_thickness}
                        touched={this.props.touched.wheelchair_wheel_thickness}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="row m-top">
                <Checkbox
                  name="need_accompanying"
                  checked={this.props.values.need_accompanying}
                  value={window.i18('INVALIDITY_CARD')}
                  onChange={() => this.props.setFieldValue('need_accompanying', !this.props.values.need_accompanying)}
                />
              </div>
            </>
          )}
          {!this.props.hidePassword && (
            <div className="row m-top">
              <div className="col col-12">
                <Input
                  type="password"
                  label={window.i18('PASSWORD')}
                  placeholder="••••••••"
                  name="password"
                  value={this.props.values.password}
                  onChange={(value) => this.props.setFieldValue('password', value)}
                  onBlur={this.props.handleBlur}
                  error={this.props.errors.password}
                  touched={this.props.touched.password}
                />
                <div className="row">
                  <p className="form-error m-0">
                    {this.props.errors.password}
                  </p>
                </div>
              </div>
            </div>
          )}
          {(this.props.buttonLabel === window.i18('SIGN_UP') || this.props.buttonLabel === window.i18('UPDATE')) && (
            <div className="form-group rgpd p-top">
              <Checkbox
                name="rgpd"
                checked={this.props.values.rgpd}
                value={window.i18('I_ACCEPT_MY_DATA_WILL_BE_USED')}
                onChange={() => this.props.setFieldValue(
                  'rgpd',
                  !this.props.values.rgpd,
                )}
              />
              <div className="row">
                <p className="form-error m-0">
                  {this.props.errors.rgpd}
                </p>
              </div>
              <a
                className="m-top"
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.thetreep.com/politiqueconfidentialite.html"
              >
                {window.i18('CONSULT_PRIVACY_POLICY')}
              </a>
            </div>
          )}
          {this.props.buttonLabel === window.i18('UPDATE') && (
            <div className="row m-top">
              <div className="col col-12">
                <Checkbox
                  name="data_accuracy"
                  checked={this.state.data_accuracy}
                  value={window.i18('AGGREE_FORM_RESPONSABILITY')}
                  onChange={() => this.setState({
                    data_accuracy: !this.state.data_accuracy,
                  })}
                />
              </div>
            </div>
          )}
          <div className="form-submit">
            <button
              data-testid="update-user-info-button"
              type="submit"
              disabled={!this.props.values.rgpd || !this.state.data_accuracy}
              className="button-main shadowed"
            >
              {this.props.buttonLabel}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

// Default user for signup form / passenger form
const initialUser = {
  gender: '',
  firstname: '',
  lastname: '',
  email: '',
  username: '',
  phone: '',
  birthdate: '',
  address: '',
  rgpd: false,
};

const schema = yup.object().shape({
  gender: yup.string().required('Veuillez remplir ce champ.'),
  firstname: yup.string().required('Veuillez remplir ce champ.'),
  lastname: yup.string().required('Veuillez remplir ce champ.'),
  email: yup
    .string()
    .email()
    .required('Veuillez remplir ce champ.'),
  username: yup
    .string()
    .required('Veuillez remplir ce champ.'),
  phone: yup
    .string()
    .matches(/\b0[0-9]{9}\b/, 'Votre numéro doit être au format 0XXXXXXXXX')
    .notRequired(),
  birthdate: yup
    .date()
    .notRequired()
    .min('1900-01-01')
    .max(new Date().toISOString().slice(0, 10)),
  address: yup.object().shape({
    description: yup.string().notRequired(),
  }),
  password: yup
    .string()
    .min(8, 'Au moins 8 caractères requis')
    .matches(/^(?=.*[A-Z]).*$/, 'Au moins une majuscule requise')
    .matches(/^(?=.*[0-9]).*$/, 'Au moins un chiffre requis')
    .matches(/^(?=.*[a-z]).*$/, 'Au moins une minuscule requise')
    .notRequired(),
  rgpd: yup.bool().oneOf([true], 'Cette case doit être cochée'),
});

const handleSubmit = (values, actions) => {
  let birthdate = null;
  if (values.birthdate != null && values.birthdate !== '') {
    birthdate = formatDateForAPI(new Date(values.birthdate).toISOString());
  }
  // We copy the values to prevent mutating them
  const formatted = {
    ...values,
    birthdate,
    phone: values.phone.trim().replace(/ /g, ''),
    username: values.username.trim(),
    email: values.email.trim(),
    firstname: values.firstname.trim(),
    lastname: values.lastname.trim(),
  };

  const promise = actions.props.handleSubmit(formatted);

  // If it's indeed a promise, there's probably some async action going on
  // And if not, we still do it thanks to Promise's resolve
  // Check https://stackoverflow.com/questions/27746304/how-do-i-tell-if-an-object-is-a-promise for more info
  Promise.resolve(promise).then(
    () => {
      actions.setSubmitting(false);
    },
    (error) => {
      actions.setStatus(error.msg);
    },
  );
};

export default withFormik({
  mapPropsToValues: (props) => {
    if (!props.user) return initialUser;
    let hasHandicap = false;
    if (props.user.handicap && props.user.handicap !== '') {
      hasHandicap = true;
    }

    // If we get a user passed in props, it means we have an already registered user
    const birthdate = props.user.birthdate
      ? moment(props.user.birthdate).format('YYYY-MM-DD')
      : '';
    return {
      ...initialUser,
      ...props.user,
      password: '',
      birthdate,
      hasHandicap,
    };
  },
  validationSchema: schema,
  handleSubmit,
})(UserForm);
