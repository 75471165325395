import * as yup from 'yup';
import moment from 'moment';
import { toast as Notif } from 'react-toastify';
import { push } from 'connected-react-router';
// Actions
import {
  setSearchedCarRentals,
  resetResults,
  searchCarRentalStart,
  // searchCarRentalError,
  searchCarRentalSuccess,
} from '../CarRentalResults/actions';
// Constants
import {
  SET_PICK_UP_LOCATION,
  SET_PICK_UP_LOCATION_TOUCHED,
  SET_RETURN_LOCATION,
  SET_RETURN_LOCATION_TOUCHED,
  SET_PICK_UP_DATE,
  SET_PICK_UP_DATE_TOUCHED,
  SET_RETURN_DATE,
  SET_RETURN_DATE_TOUCHED,
  SET_CAR_RENTAL_TIME,
  EDIT_DRIVER,
  SET_FORM_ERRORS,
  FILL_USER_INFO,
  SET_CAR_TYPES,
  ADD_DRIVER,
  SELECT_DRIVER,
  ADD_LAST_TRAVELERS,
  SET_ENTITY_TOUCHED,
  SET_SEARCH_PARAMS,
} from './constants';
// Functions
import API from '../../utils/api';
// import { formatTimeToObject } from '../../utils/converters'
// import { NotificationManager as Notif } from 'react-notifications'

export function setPickUpLocation(location) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PICK_UP_LOCATION_TOUCHED });
    dispatch({
      type: SET_PICK_UP_LOCATION,
      location,
    });
    if (getState().carRentalSearch.returnLocation.location === null) {
      dispatch(setReturnLocation(location));
    }
    dispatch(checkForm());
  };
}

export function setReturnLocation(location) {
  return (dispatch) => {
    dispatch({ type: SET_RETURN_LOCATION_TOUCHED });
    dispatch({
      type: SET_RETURN_LOCATION,
      location,
    });
    dispatch(checkForm());
  };
}

export function invertLocations() {
  return (dispatch, getState) => {
    const { pickUpLocation } = getState().carRentalSearch;
    const { returnLocation } = getState().carRentalSearch;

    dispatch(setReturnLocation(pickUpLocation.location));
    dispatch(setPickUpLocation(returnLocation.location));
  };
}

export function editDriver(driver) {
  return {
    type: EDIT_DRIVER,
    driver,
  };
}

export function addDriver(driver) {
  return (dispatch) => {
    dispatch({
      type: ADD_DRIVER,
      driver,
    });
    dispatch(checkForm());
  };
}

export function selectDriver(id) {
  return (dispatch) => {
    dispatch({
      type: SELECT_DRIVER,
      id,
    });
    dispatch(checkForm());
  };
}

export function setPickUpDate(date) {
  return (dispatch) => {
    dispatch({
      type: SET_PICK_UP_DATE,
      date,
    });
    dispatch({ type: SET_PICK_UP_DATE_TOUCHED });
    dispatch(checkForm());
  };
}

export function setReturnDate(date) {
  return (dispatch) => {
    dispatch({
      type: SET_RETURN_DATE,
      date,
    });
    dispatch({ type: SET_RETURN_DATE_TOUCHED });
    dispatch(checkForm());
  };
}

export function setCarRentalTime(field, time) {
  return (dispatch) => {
    dispatch({
      type: SET_CAR_RENTAL_TIME,
      field,
      time,
    });
    dispatch(checkForm());
  };
}

export function setFormErrors(errors) {
  return {
    type: SET_FORM_ERRORS,
    errors,
  };
}

export function checkForm() {
  return (dispatch, getState) => {
    try {
      const search = getState().carRentalSearch;
      searchSchema.validateSync(search, { abortEarly: false });
      const { entities } = getState().app;
      if (entities && entities.length > 0) { // an entity can be selected => it is required.
        yup.object().shape({
          entityID: yup.mixed().required(window.i18('CHOSE_ENTITY')),
        }).validateSync(getState().search, { abortEarly: false });
      }
      dispatch(setFormErrors(null));
    } catch (err) {
      let errors = null;
      if (err.inner) {
        errors = err.inner.reduce((map, error) => {
          const path = error.path || error.type;
          map[path] = error.message;
          return map;
        }, {});
      }

      dispatch(setFormErrors(errors));
    }
  };
}

// This is the function that is called when we click on the "GO!" button.
// It just (re)sets the results object, and calls the setSearchedCarRentals fn
export function searchCarRentals() {
  return (dispatch, getState) => {
    const search = getState().carRentalSearch;

    dispatch({ type: SET_PICK_UP_LOCATION_TOUCHED });
    dispatch({ type: SET_RETURN_LOCATION_TOUCHED });
    dispatch({ type: SET_PICK_UP_DATE_TOUCHED });
    dispatch({ type: SET_RETURN_DATE_TOUCHED });
    dispatch({ type: SET_ENTITY_TOUCHED });
    // We check the form
    dispatch(checkForm(search));

    // Check if any error surfaced
    if (getState().carRentalSearch.errors) return;

    dispatch(resetResults());
    // We store the search made by the user (allows us to display his search even if he changes it, and to search for the return trip)
    dispatch(setSearchedCarRentals(search));
    // dispatch(searchTrip("a"))
    dispatch(getCarRentals(search));
  };
}

function getCarRentals({
  pickUpLocation,
  returnLocation,
  date,
  carTypes,
  travelID,
}) {
  return (dispatch, getState) => {
    const { drivers } = getState().carRentalSearch;
    const driver = drivers.find((d) => d.selected);
    const { user } = getState().app;
    const params = {
      latitude: pickUpLocation.location.lat,
      longitude: pickUpLocation.location.lon,
      pickup_time: `${moment(date.a.date).format('YYYY-MM-DD')}T${date.a.time.charAt(0)}${date.a.time.charAt(1)}:${date.a.time.charAt(2)}${date.a.time.charAt(3)}:${date.a.time.charAt(4)}${date.a.time.charAt(5)}Z`,
      return_time: `${moment(date.r.date).format('YYYY-MM-DD')}T${date.r.time.charAt(0)}${date.r.time.charAt(1)}:${date.r.time.charAt(2)}${date.r.time.charAt(3)}:${date.r.time.charAt(4)}${date.r.time.charAt(5)}Z`,
      car_types: carTypes,
    };
    if (travelID) {
      params.travelID = travelID;
    }
    if (returnLocation.location.ID !== pickUpLocation.location.ID) {
      params.return_latitude = returnLocation.location.lat;
      params.return_longitude = returnLocation.location.lon;
    }
    // If an entity is selected, we add it to the params
    if (getState().search.entityID) {
      params.entity_id = getState().search.entityID;
    }
    if (user && user.perms && user.perms.includes('travel_agent')) {
      params.customer_id = user.company_id;
    }
    dispatch(searchCarRentalStart());
    dispatch(push('/search-car-rentals'));
    API.post('carrental/search', params).then((res) => {
      if (res.data?.result?.carRentals?.length) {
        dispatch(searchCarRentalSuccess(res.data.result, driver));
      } else {
        Notif.warning(window.i18('NO_RESULTS_SEARCH'), { autoClose: 5000 });
        dispatch(resetResults());
      }
    }).catch(() => {
      // Do not need this because we are catching everything localy now. See api.js interceptors
      /*
      let msg = window.i18('CAR_SEARCH_ERROR');
      if (error && error.data && error.data.msg) { msg = `${msg} : ${error.data.msg}`; }
      (msg, '', 8000);
      dispatch(searchCarRentalError(msg)); Not sure about this one.
      */
      dispatch(resetResults());
    });
  };
}

// Validation schema for the search
const searchSchema = yup
  .object()
  .shape({
    date: yup.object().shape({
      a: yup.object().shape({
        date: yup
          .date()
          .typeError("Le format n'est pas reconnu")
          .required()
          .min(
            moment().startOf('day'),
            "Veuillez rentrer une date qui n'est pas passée",
          ),
        time: yup.string().required(),
      }),
      r: yup.object().shape({
        date: yup.date("Le format n'est pas reconnu").typeError('Veuillez rentrer une date de retour'),
      }),
    }),
    pickUpLocation: yup.object().shape({
      location: yup
        .object()
        .nullable()
        .required("Veuillez remplir l'adresse de départ")
        .shape({
          ID: yup.string().required("Veuillez remplir l'adresse de départ"),
        }),
    }),
    returnLocation: yup.object().shape({
      location: yup
        .object()
        .nullable()
        .required("Veuillez remplir l'adresse de retour")
        .shape({
          ID: yup.string().required("Veuillez remplir l'adresse de retour"),
        }),
    }),
  }).test(
    'drivers',
    'Veuillez entrer un conducteur',
    (schema) => schema.drivers.filter((d) => d.selected).length !== 0,
  );

// Checks wether the first passenger in our result list is the currently logged in user
// This is used to auto fill the first passenger as the logged in user
export function checkUserInfo(user) {
  return (dispatch, getState) => {
    // If the user isn't logged in, nothing to do here
    if (!user) return;

    const { userFilled, lastTravelersAdded } = getState().carRentalSearch.actions;

    if (!lastTravelersAdded && user.last_travelers) {
      const drivers = user.last_travelers.map((traveler) => {
        traveler.uid = traveler.profile_id;
        return traveler;
      });
      dispatch(addLastTravelers(drivers.slice(0, 10)));
    }

    const { userPermissions } = getState().app;
    // If the user is a travel manager, we don't use his profile as default passenger
    if (userPermissions != null && userPermissions.isTravelManager) {
      dispatch(selectDriver(0));
      return;
    }

    if (!userFilled) {
      dispatch(fillUser(user));
    }
  };
}

// Fills the first passenger in the list as the current logged in user
export function fillUser(user) {
  return {
    type: FILL_USER_INFO,
    driver: user,
  };
}

export function addLastTravelers(travelers) {
  return {
    type: ADD_LAST_TRAVELERS,
    travelers,
  };
}

export function setCarTypes(carTypes) {
  return {
    type: SET_CAR_TYPES,
    carTypes,
  };
}

export function setCarRentalSearchParams(params) {
  return {
    type: SET_SEARCH_PARAMS,
    ...params,
  };
}
