import * as yup from 'yup';
import moment from 'moment';
import { push } from 'connected-react-router';
import { toast as Notif } from 'react-toastify';
import { isCNAM } from '../../utils/functions';
import API from '../../utils/api';
import {
  TOGGLE_RT,
  SET_FROM_LOCATION,
  SET_TO_LOCATION,
  ADD_PASSENGER,
  EDIT_PASSENGER,
  SET_PASSENGERS,
  REMOVE_PASSENGER,
  SET_PASSENGERS_TOUCHED,
  SET_ENTITY_TOUCHED,
  SET_TRIP_DATE,
  SET_TRIP_TIME,
  SET_TRIP_TIME_MODE,
  SET_FORM_ERRORS,
  SET_FROM_TOUCHED,
  SET_TO_TOUCHED,
  SET_DATE_TOUCHED,
  TOGGLE_DOOR_TO_DOOR,
  SET_SPECIAL_FARE,
  CDS_REDIRECT_BEGIN,
  CDS_REDIRECT_SUCCESS,
  CDS_REDIRECT_ERROR,
  FILL_USER_INFO,
  TOGGLE_SELECTED_PASSENGER,
  ADD_LAST_TRAVELERS,
  SET_CORSICAN_FARES,
  SET_SEARCH_PARAMS,
} from './constants';

import {
  setSearchingTrip, setOutwardTrip, setReturnTrip, setTripPassengers, setSearchedTrip, resetResults, setIsRoundTrip,
} from '../TripResults_v3/actions';

import { formatTimeToObject, formatDateForAPI } from '../../utils/converters';

export function toggleRT() {
  return {
    type: TOGGLE_RT,
  };
}

export function setFromLocation(location) {
  return (dispatch) => {
    dispatch({ type: SET_FROM_TOUCHED });
    dispatch({
      type: SET_FROM_LOCATION,
      location,
    });
    dispatch(checkForm());
  };
}

export function setToLocation(location) {
  return (dispatch) => {
    dispatch({ type: SET_TO_TOUCHED });
    dispatch({
      type: SET_TO_LOCATION,
      location,
    });
    dispatch(checkForm());
  };
}

export function invertFromToLocation() {
  return (dispatch, getState) => {
    const { from } = getState().tripSearch;
    const { to } = getState().tripSearch;

    dispatch(setFromLocation(to));
    dispatch(setToLocation(from));
  };
}

export function addPassenger(passenger = {}) {
  return (dispatch, getState) => {
    const travelers = getState().tripSearch.passengers.filter((p) => p.selected);
    const disabledTraveler = travelers.filter((t) => t.handicap && t.handicap.length > 0).length > 0;
    const accompanyingTraveler = travelers.filter((t) => t.accompanying).length > 0;
    const defaultTraveler = travelers.length === 1 && travelers[0] === {
      id: 0, cards: [], selected: true, isSimple: true,
    };
    const isDisabled = passenger.handicap && passenger.handicap.length > 0;
    // If there's a disabled passenger and no accompanying person,
    // we make this new passenger the accompanying person (only if he's not disabled himself)
    if (!isDisabled && disabledTraveler && !accompanyingTraveler) {
      passenger.accompanying = true;
    }
    // If the passenger we're adding is disabled and there are some
    // passengers already added, we make the first one his accompanying person
    dispatch({ type: SET_PASSENGERS_TOUCHED });
    if (travelers.length > 0 && isDisabled && !accompanyingTraveler && !defaultTraveler) {
      editPassenger({
        ...travelers[0],
        accompanying: true,
      });
    }
    dispatch({
      type: ADD_PASSENGER,
      passenger,
    });
    dispatch(checkForm());
  };
}

export function setPassengers(passengers) {
  return {
    type: SET_PASSENGERS,
    passengers,
  };
}

export function removePassenger(id) {
  return {
    type: REMOVE_PASSENGER,
    id,
  };
}

export function editPassenger(passenger) {
  return {
    type: EDIT_PASSENGER,
    passenger,
  };
}

export function setCorsicanFaresForPassengers(withCorsicanFares) {
  return {
    type: SET_CORSICAN_FARES,
    withCorsicanFares,
  };
}

export function setTripDate(trip, date) {
  return (dispatch) => {
    dispatch({
      type: SET_TRIP_DATE,
      trip,
      date,
    });
    dispatch({ type: SET_DATE_TOUCHED });
    dispatch(checkForm());
  };
}

export function setTripTime(trip, time) {
  return (dispatch) => {
    dispatch({
      type: SET_TRIP_TIME,
      trip,
      time,
    });
    dispatch({ type: SET_DATE_TOUCHED });
    dispatch(checkForm());
  };
}

export function setTripTimeMode(trip, mode) {
  return {
    type: SET_TRIP_TIME_MODE,
    trip,
    mode,
  };
}

export function setFormErrors(errors) {
  return {
    type: SET_FORM_ERRORS,
    errors,
  };
}

export function checkForm() {
  return (dispatch, getState) => {
    try {
      const search = getState().tripSearch;
      if (isCNAM()) {
        cnamSearchSchema.validateSync(search, { abortEarly: false });
      } else {
        searchSchema.validateSync(search, { abortEarly: false });
        const { entities } = getState().app;
        if (entities && entities.length > 0) { // an entity can be selected => it is required.
          yup.object().shape({
            entityID: yup.mixed().required(window.i18('CHOSE_ENTITY')),
          }).validateSync(getState().search, { abortEarly: false });
        }
      }
      dispatch(setFormErrors(null));
    } catch (err) {
      let errors = null;
      if (err.inner) {
        errors = err.inner.reduce((map, error) => {
          const path = error.path || error.type;
          map[path] = error.message;
          return map;
        }, {});
      }

      dispatch(setFormErrors(errors));
    }
  };
}

// This is the function that is called when we click on the "GO!" button.
// It just (re)sets the results object, and calls the searchTrip fn
export function searchNewTrip() {
  return (dispatch, getState) => {
    const search = { ...getState().tripSearch };
    search.passengers = search.passengers.filter((p) => p.selected);

    dispatch({ type: SET_FROM_TOUCHED });
    dispatch({ type: SET_TO_TOUCHED });
    dispatch({ type: SET_DATE_TOUCHED });
    dispatch({ type: SET_PASSENGERS_TOUCHED });
    dispatch({ type: SET_ENTITY_TOUCHED });
    // We check the form
    dispatch(checkForm(search));

    // Check if any error surfaced
    if (getState().tripSearch.errors) { return; }

    dispatch(resetResults());
    // We store the search made by the user (allows us to display his search even if he changes it, and to search for the return trip)
    dispatch(setSearchedTrip(search));
    dispatch(searchTrip('a'));
  };
}

export function searchTrip(trip = 'a', choiceID = null) {
  return (dispatch, getState) => {
    const { results } = getState();
    const { search } = results;
    const { user } = getState().app;
    const { travelID } = search;

    // Sets the loader in motion :)
    dispatch(setSearchingTrip());

    if (search.isRT) {
      dispatch(setIsRoundTrip());
    }

    dispatch(push('/search'));

    // We copy the datetime object to mutate it a lil bit without fucking the redux store
    let datetime = search.date[trip].date.clone();
    // Formats the date & sets the time
    datetime = datetime.set(formatTimeToObject(search.date[trip].time)).format('YMMDD[T]HHmmss');

    // Origins & destinations get swapped for the return trip
    const origin = trip === 'a' ? search.from : search.to;
    const destination = trip === 'a' ? search.to : search.from;

    // Passengers are either provided from the search (if we're on the A trip)
    // Or we copy them from the A trip in case we're in the return trip
    const { passengers } = search;

    // We adapt out passenger object to fit the API's travelers object
    const travelers = passengers.map((passenger, i) => ({
      uid: passenger.id,
      birthdate: formatDateForAPI(passenger.birthdate),
      cards: passenger.cards,
      firstname: passenger.firstname,
      lastname: passenger.lastname,
      email: passenger.email,
      accompanying: passenger.accompanying,
      gender: passenger.gender,
      address: passenger.address,
      phone: passenger.phone,
      age_cat: passenger.age_cat,
      handicap: passenger.handicap,
      wheelchair: passenger.wheelchair,
      wheelchair_dimensions: passenger.wheelchair_dimensions,
      wheelchair_weight: passenger.wheelchair_weight,
      wheelchair_wheel_thickness: passenger.wheelchair_wheel_thickness,
      need_accompanying: passenger.need_accompanying,
      profile_id: passenger.profile_id,
      with_corsican_fares: passenger.with_corsican_fares,
      corsican_fares_number: passenger.corsican_fares_number,
      travelerID: trip === 'r' ? results.outwardTrip.travelers[i].id : null,
    }));
    const searchParams = {
      datetime,
      origin,
      destination,
      represents: search.date[trip].mode,
      nb_travelers: search.passengers.length,
      travelers,
    };
    if (travelID) {
      searchParams.travelID = travelID;
    }
    if (user && user.perms && user.perms.includes('travel_agent')) {
      searchParams.customer_id = user.company_id;
    }
    if (!search.isDoorToDoor) {
      searchParams.direct = true;
      searchParams.filters = ['rail', 'flight'];
    }
    // If a special fare is selected, we add it to the params
    if (search.specialFare) {
      searchParams.special_fare = search.specialFare;
    }
    // If an entity is selected, we add it to the params
    if (getState().search.entityID) {
      searchParams.entity_id = getState().search.entityID;
    }
    if (isCNAM()) {
      searchParams.subsidy_number = getState().search.subsidy != null ? getState().search.subsidy.number : '';
    }

    // If it is a search with return, for the outward ('a') add the return_datetime and return_represents params
    if (search.isRT && trip === 'a' && search.date.r !== null && search.date.r.date !== null) {
      let returnDatetime = search.date.r.date.clone();
      returnDatetime = returnDatetime.set(formatTimeToObject(search.date.r.time)).format('YMMDD[T]HHmmss');
      searchParams.return_datetime = returnDatetime;
      searchParams.return_represents = search.date.r.mode;
    }

    // If it is the search of the return add the selected offer for outward
    if (trip === 'r' && choiceID !== null) {
      searchParams.outward = {
        choiceID,
      };
    }

    API.post('/treep/search?version=v2', searchParams)
      .then((response) => {
        if (response.data.result.length === 0) {
          dispatch(resetResults());
          Notif.warning(window.i18('NO_RESULTS_SEARCH'));
        }
        if (trip === 'a') {
          dispatch(setOutwardTrip(response.data.result));
          dispatch(setTripPassengers(passengers));
        } else {
          dispatch(setReturnTrip(response.data.result));
        }
      })
      .catch(() => {
        // Do not need this because we are catching everything localy now. See api.js interceptors
        /*
        Notif.warning(friendlyError(error), '', 0);
        */
        dispatch(resetResults());
      });
  };
}

// Validation schema for the search
const searchSchema = yup.object().shape({
  isRT: yup.boolean().required(),
  date: yup.object().shape({
    a: yup.object().shape({
      mode: yup.string().required(),
      time: yup.string().required().min(6).max(6)
        .when('date', {
        // If we're traveling today, we check that the time set is in the future
          is: (date) => moment(date).isSame(moment(), 'day'),
          then: yup.string().test('is-after-now', "Veuillez entrer une heure qui n'est pas passée", (time) => {
            const datetime = moment().set(formatTimeToObject(time));
            return datetime.isAfter();
          }),
        }),
      date: yup.date().typeError("Le format n'est pas reconnu").required().min(moment().startOf('day'), "Veuillez entrer une date qui n'est pas passée"),
    }),
    r: yup.object().shape({
      mode: yup.string().required(),
      time: yup.string().required().min(6).max(6),
      date: yup.date("Le format n'est pas reconnu").nullable(),
    }),
  }),
  from: yup.object().required().shape({
    ID: yup.string().required("Veuillez remplir l'adresse de départ"),
  }),
  to: yup.object().required().shape({
    ID: yup.string().required("Veuillez remplir l'adresse d'arrivée"),
  }),
}).test('date.r.time', 'Veuillez entrer une date/heure de retour après celle de départ', (schema) => {
  // For a RT trip, we check that the return datetime is after the departure datetime
  if (!schema.isRT) { return true; }

  const returnDate = moment(schema.date.r.date).set(formatTimeToObject(schema.date.r.time));
  const allerDate = moment(schema.date.a.date).set(formatTimeToObject(schema.date.a.time));
  return returnDate.isAfter(allerDate);
}).test('passengers', 'Veuillez entrer au moins un passager', (schema) => (schema.passengers.filter((p) => p.selected).length !== 0))
  .test('passengers', 'Vous ne pouvez pas entrer plus de 4 passagers', (schema) => (schema.passengers.filter((p) => p.selected).length <= 4));

// Validation schema for the search
const cnamSearchSchema = yup.object().shape({
  isRT: yup.boolean().required(),
  date: yup.object().shape({
    a: yup.object().shape({
      mode: yup.string().required(),
      time: yup.string().required().min(6).max(6)
        .when('date', {
        // If we're traveling today, we check that the time set is in the future
          is: (date) => moment(date).isSame(moment(), 'day'),
          then: yup.string().test('is-after-now', "Veuillez entrer une heure qui n'est pas passée", (time) => {
            const datetime = moment().set(formatTimeToObject(time));
            return datetime.isAfter();
          }),
        }),
      date: yup.date().typeError("Le format n'est pas reconnu").required().min(moment().startOf('day'), "Veuillez entrer une date qui n'est pas passée"),
    }),
    r: yup.object().shape({
      mode: yup.string().required(),
      time: yup.string().required().min(6).max(6)
        .when('date', {
        // If we're traveling today, we check that the time set is in the future
          is: (date) => moment(date).isSame(moment(), 'day'),
          then: yup.string().test('is-after-now', "Veuillez entrer une heure qui n'est pas passée", (time) => {
            const datetime = moment().set(formatTimeToObject(time));
            return datetime.isAfter();
          }),
        }),
      date: yup.date().typeError('Veuillez saisir une date de retour').required().min(moment().startOf('day'), "Veuillez entrer une date qui n'est pas passée"),
    }),
  }),
  from: yup.object().required().shape({
    ID: yup.string().required("Veuillez remplir l'adresse de départ"),
  }),
  to: yup.object().required().shape({
    ID: yup.string().required("Veuillez remplir l'adresse d'arrivée"),
  }),
}).test('date.r.time', 'Veuillez entrer une date/heure de retour après celle de départ', (schema) => {
  // For a RT trip, we check that the return datetime is after the departure datetime
  if (!schema.isRT) { return true; }

  const returnDate = moment(schema.date.r.date).set(formatTimeToObject(schema.date.r.time));
  const allerDate = moment(schema.date.a.date).set(formatTimeToObject(schema.date.a.time));
  return returnDate.isAfter(allerDate);
}).test('passengers', 'Veuillez entrer au moins un passager', (schema) => (schema.passengers.filter((p) => p.selected).length !== 0))
  .test('passengers', 'Vous ne pouvez pas entrer plus de 4 passagers', (schema) => (schema.passengers.filter((p) => p.selected).length <= 4));

export function reverseGeolocation(coords) {
  return (dispatch, getState) => {
    if (!coords) { return; }

    return API.get('/places/reversegeo', {
      params: {
        lat: coords.latitude,
        lon: coords.longitude,
      },
    })
      .then((response) => {
        const { result } = response.data;
        const search = getState().tripSearch;

        // If we get a place back and the user didn't already type something in, we update the "from" field
        if (result && result.ID) {
          if (!search.from.ID) { dispatch(setFromLocation(result)); }
        }
      }).catch(() => {
        // no location found, nothing to do here
      });
  };
}

export function toggleDoorToDoor() {
  return {
    type: TOGGLE_DOOR_TO_DOOR,
  };
}

export function setSpecialFare(specialFare) {
  return {
    type: SET_SPECIAL_FARE,
    specialFare,
  };
}

export function redirectToCds() {
  return (dispatch) => {
    dispatch({ type: CDS_REDIRECT_BEGIN });
    API.post('/hotel/cds', {})
      .then((resp) => {
        dispatch({ type: CDS_REDIRECT_SUCCESS });
        if (resp.data.result != null && resp.data.result != null) {
          window.open(resp.data.result.url, '_blank');
        }
      }, () => {
        dispatch({ type: CDS_REDIRECT_ERROR });
        // Do not need this because we are catching everything localy now. See api.js interceptors
        /*
        Notif.warning(window.i18('REDIRECTION_ERROR'));
        */
      });
  };
}

// Checks wether the first passenger in our result list is the currently logged in user
// This is used to auto fill the first passenger as the logged in user
export function checkUserInfo(user) {
  return (dispatch, getState) => {
    // If the user isn't logged in, nothing to do here
    if (!user) return;

    const { userPermissions } = getState().app;
    const { passengers, actions, travelID } = getState().tripSearch;
    const { userFilled, lastTravelersAdded } = actions;

    // If the user is a travel manager, we don't use his profile as default passenger
    if (userPermissions != null && userPermissions.isTravelManager && passengers.length === 1 && !isCNAM() && !travelID) {
      dispatch(removePassenger(0));
      return;
    }
    if (!lastTravelersAdded && user.last_travelers) {
      dispatch(addLastTravelers(user.last_travelers.slice(0, 10)));
    }

    user = { profile_id: user.uid, ...user };
    // If we did not already do that, we fill the user infos
    if (!userFilled) dispatch(fillUser(user));
  };
}

// Fills the first passenger in the list as the current logged in user
export function fillUser(user) {
  return {
    type: FILL_USER_INFO,
    user,
  };
}

export function addLastTravelers(travelers) {
  return {
    type: ADD_LAST_TRAVELERS,
    travelers,
  };
}

export function toggleSelectedPassenger(index) {
  return (dispatch) => {
    dispatch({ type: SET_PASSENGERS_TOUCHED });
    dispatch({
      type: TOGGLE_SELECTED_PASSENGER,
      index,
    });
    dispatch(checkForm());
  };
}

export function setTreepSearchParams(params) {
  return {
    type: SET_SEARCH_PARAMS,
    ...params,
  };
}
