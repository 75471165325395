import React from 'react';
import { connect } from 'react-redux';
import { openModal } from '../../containers/ModalWrapper/actions';
import logo from '../../../images/the_treep_logo.png';

import amelisvg from '../../../images/CNAM/ameli.svg';

const CNAMContact = (props) => (
  <footer className="footer-cnam">
    <a href="https://www.ameli.fr/">
      <img className="svg-footer" src={amelisvg} alt="ameli" />
    </a>
    <h5 className="title-footer">
      © 2020 - by
      <a href="https://www.thetreep.com/">
        <img className="logo-footer" src={logo} alt="The Treep - logo" />
      </a>
    </h5>
    <button onClick={props.openContact} className="button-main footer-button">Contactez nous</button>
  </footer>
);

const mapDispatchToProps = (dispatch) => ({

  openContact: () => dispatch(openModal('Footer')),

});
export default connect(null, mapDispatchToProps)(CNAMContact);
