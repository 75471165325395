import * as yup from 'yup';

export function filterResults(filters, results) {
  return results.filter((res) => {
    const hasConfort = res.stars >= filters.stars || filters.stars === 0;
    const hasBreakfastIncluded = !filters.breakfast || res.room_offers.find((offer) => offer.breakfast_included) !== undefined;
    const hasLoyaltyEnabled = !filters.loyaltyEnabled || res.room_offers.find((offer) => offer?.tags?.includes('loyalty-enabled')) !== undefined;
    const isAccessible = !filters.accessible || res.hotel_tags.has_prm;
    const minNotSet = filters.prices.min === filters.priceRange.min;
    const maxNotSet = filters.prices.max === filters.priceRange.max;

    let isInPriceRange;
    if (minNotSet && maxNotSet) {
      isInPriceRange = true;
    } else {
      isInPriceRange = res.room_offers.find((offer) => {
        const price = offer.incl_taxes_amount;
        const { min, max } = filters.priceRange;
        const moreThanMin = minNotSet || price >= min;
        const lessThanMax = maxNotSet || price <= max;
        return moreThanMin && lessThanMax;
      }) !== undefined;
    }
    return (hasConfort && hasBreakfastIncluded && hasLoyaltyEnabled && isInPriceRange && isAccessible);
  });
}

export function sortResults(results, sorting) {
  if (sorting === 'BY_PRICE') {
    return results.sort((a, b) => (a.room_offers?.[0]?.incl_taxes_amount || 99999) - (b.room_offers?.[0]?.incl_taxes_amount || 99999));
  }
  if (sorting === 'BY_DISTANCE') {
    return results.sort((a, b) => a.distance - b.distance);
  }
  return results;
}

export function travelerValidationSchema() {
  return yup.object().shape({
    gender: yup.string().required(window.i18('PLEASE_FILL')),
    firstname: yup.string().required(window.i18('PLEASE_FILL')),
    lastname: yup.string().required(window.i18('PLEASE_FILL')),
    email: yup
      .string()
      .email()
      .required(window.i18('PLEASE_FILL')),
    phone: yup
      .string()
      .matches(
        /\b0[0-9]{9}\b|xxxxxxxx/,
        window.i18('PHONE_NUMBER_FORMAT'),
      )
      .required(window.i18('PLEASE_FILL')),
  });
}

export function displayOfferInfos(offer) {
  const text = [];
  text.push(offer.is_cancellable ? window.i18('CANCELLABLE') : window.i18('NOT_CANCELLABLE'));
  if (offer.breakfast_included) {
    text.push(window.i18('BREAKFAST_INCLUDED'));
  } else {
    text.push(window.i18('BREAKFAST_NOT_INCLUDED'));
  }
  if (offer.lunch_included) {
    text.push(window.i18('LUNCH_INCLUDED'));
  }
  if (offer.dinner_included) {
    text.push(window.i18('DINNER_INCLUDED'));
  }
  if (offer.tags && offer.tags.includes('loyalty-enabled')) {
    text.push(window.i18('COMPATIBLE_WITH_CARDS'));
  }
  text.push('');
  return text.join('. ');
}
