import {
  push,
} from 'connected-react-router';
// import { NotificationManager as Notif } from 'react-notifications';
import {
  SEARCH_CAR_RENTALS_START,
  SEARCH_CAR_RENTALS_ERROR,
  SEARCH_CAR_RENTALS_SUCCESS,
  SET_SEARCHED_CAR_RENTALS,
  RESET_RESULTS,
  SET_SELECTED_RESULT,
  ADD_TO_CART_START,
  EDIT_DRIVER,
  ADD_TO_CART_ERROR,
  ADD_TO_CART_SUCCESS,
  SET_PRICE_RANGE,
  SET_PRICES,
  SET_EXCULDED_CAR_TYPES,
  SET_SORTING,
  TOGGLE_MAP,
  SET_SELECTED_MARKER,
  TOGGLE_UNLIMITED_KM,
  SET_EXCLUDED_RENTERS,
  TOGGLE_NEGOCIATED,
  TOGGLE_LOYALTY,
} from './constants';
import API from '../../utils/api';

export function resetResults() {
  return {
    type: RESET_RESULTS,
  };
}

export function setSearchedCarRentals(search) {
  return {
    type: SET_SEARCHED_CAR_RENTALS,
    search,
  };
}

export function searchCarRentalStart() {
  return {
    type: SEARCH_CAR_RENTALS_START,
  };
}

export function searchCarRentalError(error) {
  return {
    type: SEARCH_CAR_RENTALS_ERROR,
    error,
  };
}

export function searchCarRentalSuccess({ carRentals, renters, sortedByPref }, driver) {
  return (dispatch, getState) => {
    // We calculate the max and min price for our price range filer
    const maxPrice = carRentals.reduce((max, res) => {
      const price = res.best_price.total / 100;
      return price > max ? price : max;
    }, 0);

    const minPrice = carRentals.reduce((min, res) => {
      const price = res.best_price.total / 100;
      return price < min ? price : min;
    }, 99999999);

    const roundedMaxPrice = Math.round(maxPrice * 10) / 10;
    const roundedMinPrice = Math.floor(minPrice);

    dispatch(setPrices(roundedMinPrice, roundedMaxPrice));
    dispatch(setPriceRange(roundedMinPrice, roundedMaxPrice));
    if (sortedByPref?.length) {
      dispatch(setSorting('BY_PREF'));
    }

    if (!getState().app.isMobileDevice) {
      dispatch({
        type: SET_SELECTED_RESULT,
        id: carRentals[0].id,
      });
    }
    dispatch({
      type: SEARCH_CAR_RENTALS_SUCCESS,
      carRentals,
      driver,
      renters,
      sortedByPref,
    });
  };
}

export function setSelectedResult(id) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SELECTED_RESULT,
      id,
    });
    if (getState().carRentalResults.displayMap) {
      dispatch(toggleMap());
    }
  };
}

export function addToCart() {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_TO_CART_START,
    });

    const {
      driver,
    } = getState().carRentalResults;
    const formattedDriver = {
      ...driver,
    };
    const {
      id,
    } = getState().carRentalResults.selectedResult;
    formattedDriver.license.firstname = driver.firstname;
    formattedDriver.license.lastname = driver.lastname;
    formattedDriver.profile_id = driver.uid ? driver.uid : null;
    const travelers = [formattedDriver];

    const params = {
      car_rental: {
        id,
        driver: formattedDriver,
        travelers,
      },
    };

    if (getState().carRentalResults.search.travelID) {
      params.travelID = getState().carRentalResults.search.travelID;
    }
    const { user } = getState().app;
    if (user.perms.includes('travel_agent')) {
      params.customer_id = user.company_id;
    }

    // If an entity is selected, we add it to the params
    if (getState().search.entityID) {
      params.entity_id = getState().search.entityID;
    }

    API.post('/cart/add', params)
      .then(() => {
        dispatch({
          type: ADD_TO_CART_SUCCESS,
        });
        dispatch(push('/cart'));
      })
      .catch(() => {
        // Do not need this because we are catching everything localy now. See api.js interceptors
        /*
        const message = friendlyError(resp);
        Notif.warning(message, '', 0);
        */
        dispatch({
          type: ADD_TO_CART_ERROR,
        });
      });
  };
}

export function editDriver(driver) {
  return (dispatch) => {
    dispatch({
      type: EDIT_DRIVER,
      driver,
    });
  };
}

export function setSorting(sorting) {
  return {
    type: SET_SORTING,
    sorting,
  };
}

export function setPriceRange(min, max) {
  return {
    type: SET_PRICE_RANGE,
    min,
    max,
  };
}

export function setPrices(min, max) {
  return {
    type: SET_PRICES,
    min,
    max,
  };
}

export function setExcludedCarTypes(excludedCarTypes) {
  return {
    type: SET_EXCULDED_CAR_TYPES,
    excludedCarTypes,
  };
}

export function toggleMap() {
  return {
    type: TOGGLE_MAP,
  };
}

export function setSelectedMarker(id) {
  return {
    type: SET_SELECTED_MARKER,
    id,
  };
}

export function toggleUnlimitedKm() {
  return {
    type: TOGGLE_UNLIMITED_KM,
  };
}

export function setExcludedRenters(excludedRenters) {
  return {
    type: SET_EXCLUDED_RENTERS,
    excludedRenters,
  };
}

export function toggleNegociated() {
  return {
    type: TOGGLE_NEGOCIATED,
  };
}

export function toggleLoyalty() {
  return {
    type: TOGGLE_LOYALTY,
  };
}
