import React from 'react';
import moment from 'moment';
import SubsetTicket from './SubsetTicket';
import { matchTransportIcons } from '../../utils/icons';

import { isMultipleTicket } from '../../containers/Cart/selectors';

export default function CartSubset(props) {
  let icon = null;
  if (props.type === 'street_network') { icon = matchTransportIcons(props.type); } else { icon = matchTransportIcons(props.transportMode.name); }

  const priceDetails = props.tickets && props.tickets.map((ticket) => {
    if (isMultipleTicket(ticket)) { return `${ticket.travelers_count}x ${ticket.price}€`; }
    return null;
  });

  return (
    <div className="cart-row">
      <div className="cart-block informations">
        <div className="from-to-wrapper">
          <div className="timing-details">
            <span className="time departure">
              {moment(props.departure).format('LT')}
              <strong>{props.origin}</strong>
            </span>
            <span className="time arrival">
              {moment(props.arrival).format('LT')}
              <strong>{props.destination}</strong>
            </span>
          </div>
        </div>
      </div>
      <SubsetTicket {...props} priceDetails={priceDetails} icon={icon} />
    </div>
  );
}
