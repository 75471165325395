import React from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import * as yup from 'yup';
// Components
import moment from 'moment';
import Input from '../Inputs/Material';
import MaterialDate from '../Inputs/MaterialDate';
import Switcher from '../Switcher';
import { openModal } from '../../containers/ModalWrapper/actions';
import { frenchDateToAPI } from '../../utils/converters';
import Checkbox from '../Checkbox';

const Signup = ({
  handleSubmit, signupSuccess, className,
}) => (
  <Formik
    validationSchema={yup.object().shape({
      gender: yup.string().required('Veuillez remplir ce champ'),
      firstname: yup.string().required('Ce champ est nécessaire.'),
      lastname: yup.string().required('Ce champ est nécessaire.'),
      email: yup.string().email('Veuillez entrer un e-mail valide.').required('Ce champ est nécessaire.'),
      phone: yup.string().matches(/\b0[0-9]{9}\b/, 'Votre numéro doit être au format 0XXXXXXXXX').required('Veuillez remplir ce champ'),
      birthdate: yup.date().typeError('Entrez une date au format JJ/MM/AAAA').required('Ce champ est requis.').min('1900', 'Veuillez entrer une date plus récente.')
        .max(new Date().toISOString().slice(0, 10), 'Veuillez entrer une date passée.'),
      password: yup
        .string()
        .min(8, 'Au moins 8 caractères requis')
        .matches(/^(?=.*[A-Z]).*$/, 'Au moins une majuscule requise')
        .matches(/^(?=.*[0-9]).*$/, 'Au moins un chiffre requis')
        .matches(/^(?=.*[a-z]).*$/, 'Au moins une minuscule requise')
        .required('Ce champ est nécessaire.'),
      rgpd: yup.bool().oneOf([true], 'Cette case doit être cochée'),
    })}
    initialValues={{
      gender: 'MS',
      firstname: '',
      lastname: '',
      email: '',
      birthdate: '',
      phone: '',
      password: '',
      rgpd: false,
    }}
    onSubmit={(user, actions) => handleSubmit({ ...user, username: user.email, birthdate: frenchDateToAPI(user.birthdate) }).then(() => {
      actions.setSubmitting(false);
      signupSuccess(user.email);
    }, (error) => {
      actions.setSubmitting(false);
      actions.setStatus(error.response?.data?.errorMessage || window.i18('ERROR_GENERIC'));
    })}
  >
    {({
      values, setFieldValue, errors, touched, status, isSubmitting, setFieldTouched,
    }) => (
      <Form className={className}>
        <span className="submit-error">
          {status}
        </span>
        <div className="row">
          <div className="col col-12">
            <Switcher
              first="Madame"
              second="Monsieur"
              value={values.gender === 'MS'}
              disabled={!values.gender}
              handleClick={(value) => (value === 1
                ? setFieldValue('gender', 'MS')
                : setFieldValue('gender', 'MR'))}
              small
              touched={touched.gender}
            />
            {errors.gender && (
              <p className="material-input__error">{errors.gender}</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col col-6">
            <Input
              onChange={(value) => setFieldValue('firstname', value)}
              name="firstname"
              value={values.firstname}
              placeholder="Zinédine"
              type="text"
              label="Prénom"
              error={errors.firstname}
              touched={touched.firstname}
            />
          </div>
          <div className="col col-6">
            <Input
              onChange={(value) => setFieldValue('lastname', value)}
              name="lastname"
              value={values.lastname}
              placeholder="Zidane"
              type="text"
              label="Nom"
              error={errors.lastname}
              touched={touched.lastname}
            />
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <Input
              onChange={(value) => setFieldValue('email', value)}
              name="email"
              value={values.email}
              placeholder="zizou13@gmail.com"
              type="email"
              label="E-mail"
              error={errors.email}
              touched={touched.email}
            />
          </div>
        </div>
        <div className="row">
          <div className="col col-6">
            <MaterialDate
              onChange={(value) => {
                setFieldTouched('birthdate');
                setFieldValue('birthdate', moment.parseZone(value).format());
              }}
              name="birthdate"
              value={values.birthdate}
              type="date"
              label="Date de naissance"
              error={errors.birthdate}
              touched={touched.birthdate}
              withYearSelect
            />
          </div>
          <div className="col col-6">
            <Input
              onChange={(value) => setFieldValue('phone', value)}
              name="phone"
              value={values.phone}
              placeholder="0623456789"
              type="text"
              label="Téléphone"
              error={errors.phone}
              touched={touched.phone}
            />
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <Input
              onChange={(value) => setFieldValue('password', value)}
              name="password"
              value={values.password}
              placeholder="•••••••••"
              type="password"
              label="Mot de passe"
              error={errors.password}
              touched={touched.password}
            />
          </div>
        </div>
        <div className="row">
          <p className="material-input__error">
            {touched.rgpd && errors.rgpd}
          </p>
          <Checkbox
            name="rgpd"
            checked={values.rgpd}
            value="En soumettant ce formulaire, j’accepte que
            les informations saisies soient exploitées
            dans le cadre de mon déplacement"
            onChange={() => setFieldValue('rgpd', !values.rgpd)}
          />
        </div>
        <div className="row">
          <div className="col col-12">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.thetreep.com/politiqueconfidentialite.html"
            >
              Consultez notre politique de confidentialité
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <button className="button-main" type="submit" disabled={isSubmitting}>Créer mon compte</button>
          </div>
        </div>
      </Form>
    )}
  </Formik>
);
const mapDispatchToProps = (dispatch) => ({
  openModal: () => dispatch(openModal('CNAMNotif')),
});

export default connect(null, mapDispatchToProps)(Signup);
