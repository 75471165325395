import React from 'react';
import { TweenLite, Back } from 'gsap';

class Number extends React.Component {
  constructor(props) {
    super(props);
    this.digits = React.createRef();
  }

  componentDidMount() {
    const {
      value,
      reverse,
      initialValue,
      delay,
    } = this.props;
    const y = value * 10;

    if (value !== 0) {
      if (reverse) {
        TweenLite.from(this.digits.current, 1.5 + Math.random() * 0.5, {
          yPercent: -y,
          delay,
          ease: Back.easeInOut.config(0.4),
        });
      } else if (initialValue) {
        const z = initialValue * 10;
        TweenLite.fromTo(this.digits.current, 1.5 + Math.random() * 0.5, {
          yPercent: -z,
          ease: Back.easeInOut.config(0.4),
        }, {
          yPercent: -y,
          delay,
        });
      } else {
        TweenLite.to(this.digits.current, 1.5 + Math.random() * 0.5, {
          yPercent: -y,
          delay,
          ease: Back.easeInOut.config(0.4),
        });
      }
    }
  }

  render() {
    const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    return (
      <article className="digit">
        <div ref={this.digits}>
          {digits.map((digit, i) => (
            <span
              key={`digit-${i}`}
              className="digit__item"
            >
              {digit}
            </span>
          ))}
        </div>
      </article>
    );
  }
}

export default Number;
