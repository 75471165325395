import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import GreenScore from '../GreenScore';
import Sentence from './Sentence';

export default class LoaderContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(this.nextStep, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

    nextStep = () => {
      let x = this.state.step;
      x += 1;

      const { length } = this.props.sentences;

      // We had a +1 to the sentences length because of the first state of the loader that shows no sentences
      if (x >= length + 1) {
        return clearInterval(this.intervalId);
      }

      this.setState({
        step: x,
      });
    }

    getSentence = (id) => {
      const { length } = this.props.sentences;
      if (id > length) { return this.props.sentences[length - 1]; }

      return this.props.sentences[id];
    }

    render() {
      return (
        <div className="leaves-wrapper">
          <div className="loader-step loader-step-1-animation-appear">
            <div className="loader-copy-wrapper">
              <h3 className="loader-copy common-grey-font">{this.props.isCheckout ? window.i18('PAYING') : window.i18('SEARCHING')}</h3>
            </div>
            <div className="loader-illustration-wrapper">
              <span className="loader-illustration">
                <GreenScore
                  completion={80}
                  loader
                />
              </span>
            </div>
            <div className="loader-copy-wrapper loader-animation-appear">
              {this.state.step > 0 && (
              <TransitionGroup>
                <CSSTransition classNames="sentence" timeout={{ enter: 1000, exit: 500, appear: 500 }}>
                  <Sentence
                    key={this.state.step - 1}
                    step={this.state.step - 1}
                    label={this.state.step - 1}
                    sentence={this.getSentence(this.state.step - 1)}
                  />
                </CSSTransition>
              </TransitionGroup>
              )}
            </div>
          </div>
        </div>
      );
    }
}
