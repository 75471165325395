import React from 'react';
import * as yup from 'yup';

import { withFormik } from 'formik';
import UserInput from '../Inputs/UserInput';

const ResetPasswordForm = (props) => (
  <form onSubmit={props.handleSubmit}>
    <div className="user-form">
      <span className="submit-error">{props.status}</span>
      <UserInput
        type="username"
        label={window.i18('EMAIL')}
        value={props.values.username}
        placeholder="john.doe"
        name="username"
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        error={props.touched.username && props.errors.username}
      />
      <div className="form-submit">
        <button type="submit" className="button-main">
          {window.i18('RESET')}
        </button>
      </div>
    </div>
  </form>
);

const schema = yup.object().shape({
  username: yup
    .string()
    .required(),
});

const handleSubmit = (values, actions) => {
  const formatted = {
    ...values,
    username: values.username.trim(),
  };
  actions.props.handleSubmit(formatted).then(
    () => {
      actions.setSubmitting(false);
    },
    (error) => {
      actions.setStatus(error.msg);
    },
  );
};

export default withFormik({
  validationSchema: schema,
  mapPropsToValues: () => ({
    username: '',
  }),
  handleSubmit,
})(ResetPasswordForm);
