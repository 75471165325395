import React from 'react';

function Sorting({
  sorting, setSorting, sortedByPref, isMobileDevice,
}) {
  return (
    <>
      {isMobileDevice ? <hr /> : null}
      {isMobileDevice ? <p>{window.i18('SORT')}</p> : (
        <h3>
          {window.i18('SORTING')}
          :
        </h3>
      )}
      <select className="hotel-results-filter__btn active" defaultValue={sorting} onChange={(e) => setSorting(e.target.value)}>
        <option value="BY_PRICE">{window.i18('PRICE')}</option>
        <option value="BY_DISTANCE">{window.i18('DISTANCE')}</option>
        {sortedByPref?.length && <option value="BY_PREF">{window.i18('FAVORITE_RENTERS')}</option>}
      </select>
    </>
  );
}

export default Sorting;
