import React from 'react';
import { connect } from 'react-redux';

// Components
import Counter from '../../../components/Counter';
import { fetchCO2Stats, fetchTreepsStats } from '../../App/actions';
import TrainImage from '../../../../images/icons/transport/white_train.png';
import PlaneImage from '../../../../images/icons/transport/white_plane.png';

class UserDashboard extends React.Component {
  componentDidMount() {
    this.props.fetchCO2Stats();
    this.props.fetchTreepsStats();
  }

  render() {
    const { co2, treeps } = this.props;
    return (
      <div className="user-stats">
        <div className="row">
          <div className="col col-md-6 col-sm-12">
            <h3>
              {window.i18('CO2_SUMMARY_OF_YOUR_TRIPS')}
              {' '}
              (1
              <sup>{window.i18('ST')}</sup>
              {' '}
              {window.i18('JANUARY_TO_TODAY')}
              )
            </h3>
            <div className="user-stats__block">
              {co2 && co2.user_savings != null
                && (
                <div className="row">
                  <div className="col col-md-12 col-sm-12">
                    <h4>{window.i18('CO2_ECO')}</h4>
                    <Counter value={co2.user_savings / 1000} light />
                  </div>
                </div>
                )}
              <div className="row">
                {co2 && co2.user_emissions != null
                  && (
                  <div className="col col-6">
                    <h4 className="user-stats__colored-title red">{window.i18('CO2_EMISSIONS')}</h4>
                    <p className="user-stats__colored-number red">
                      {Math.round(co2.user_emissions / 1000).toLocaleString()}
                      <span> kg CO2</span>
                    </p>
                  </div>
                  )}
                {co2 && co2.user_compensation != null
                  && (
                  <div className="col col-6">
                    <h4 className="user-stats__colored-title green">{window.i18('COMPENSATION')}</h4>
                    <p className="user-stats__colored-number green">
                      {Math.round(co2.user_compensation / 1000).toLocaleString()}
                      <span> kg CO2</span>
                    </p>
                  </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-md-6 col-sm-12">
            <h3>
              {window.i18('KM_SUMMARY_OF_YOUR_TRIPS')}
              {' '}
              (1
              <sup>{window.i18('ST')}</sup>
              {' '}
              {window.i18('JANUARY_TO_TODAY')}
              )
            </h3>
            <div className="user-stats__block">
              <div className="row">
                {treeps && treeps.number != null
                  && (
                  <div className="col col-md-4 col-sm-6">
                    <h4>{window.i18('TRIPS_COUNT')}</h4>
                    <p className="user-stats__big-number">{treeps.number.toLocaleString()}</p>
                  </div>
                  )}
                {treeps && treeps.total_distance_km != null
                  && (
                  <div className="col col-md-8 col-sm-6">
                    <h4>{window.i18('DISTANCE_COVERED')}</h4>
                    <p className="user-stats__big-number">
                      {treeps.total_distance_km.toLocaleString()}
                      <span> km</span>
                    </p>
                  </div>
                  )}
              </div>
              <div className="row">
                <div className="col col-12">
                  {treeps && treeps.transport_modes != null && treeps.transport_modes.length > 0
                    && (
                    <div className="user-stats__transport-percent">
                      {treeps.transport_modes.sort((a, b) => (a.mode > b.mode ? 1 : -1)).map((mode) => {
                        const percent = Math.round(mode.share * 100);
                        const icon = transportModeToIcon(mode.mode);
                        return (
                          <div key={mode.mode} style={{ width: percent > 10 ? `${percent}%` : null }}>
                            {icon && <img className="icon" src={icon} alt={mode.mode} />}
                            <p>
                              {percent}
                              %
                            </p>
                          </div>
                        );
                      })}
                    </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  co2: state.app.stats && state.app.stats.co2,
  treeps: state.app.stats && state.app.stats.treeps,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCO2Stats: () => dispatch(fetchCO2Stats()),
  fetchTreepsStats: () => dispatch(fetchTreepsStats()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserDashboard);

const transportModeToIcon = (tm) => {
  switch (tm) {
    case 'rail':
      // return "train_white"
      return TrainImage;
    case 'flight':
      // return "airplane_white"
      return PlaneImage;
    default:
      return null;
  }
};
