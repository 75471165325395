/* eslint-disable camelcase */
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

// Components
import Schedule from '../../../components/CarRentalSchedule';

// Functions
import { getSvgIcon } from '../../../utils/icons';
import { formatCarType } from '../../../utils/converters';

const SelectedResult = ({
  selected, openModal, editDriver, driver, driverIsFilled, addingToCart, addToCart,
}) => {
  if (!selected) {
    return null;
  }
  return (
    <>
      <div className="car-rental-details">
        <div className="bordered-block">
          <div className="car-rental-details__top row">
            <div className="col md-col-9 sm-col-8">
              <h1 className="car-rental-details__title m-bot">{selected.car}</h1>
              <p className="m-bot">
                {window.i18('DEPARTURE_ON')}
                {' '}
                {moment.utc(selected.pickup_time).format('DD/MM/YYYY à HH:mm')}
                {' - '}
                {window.i18('RETURN_ON')}
                {' '}
                {moment.utc(selected.return_time).format('DD/MM/YYYY à HH:mm')}
              </p>
              <div className="car-rental-details__infos m-bot">
                <p>
                  {window.i18('VEHICLE_CATEGORY')}
                  :
                  {' '}
                  {formatCarType(selected.category)}
                </p>
                <p>
                  {window.i18('VEHICLE_TYPE')}
                  :
                  {' '}
                  {selected.car}
                </p>
                <p>
                  {window.i18('PRICE_DETAIL')}
                  :
                  {' '}
                  {selected.unlimited_distance ? window.i18('UNLIMITED_DISTANCE') : `${selected.included_distance}km/${window.i18('DAY')} + ${selected.additional_distance_price.total / 100}${selected.additional_distance_price.currency}/${selected.additional_distance_price.unit}`}
                </p>
              </div>
              <h3 className="car-rental-details__subtitle">{window.i18('INCLUDED_SERVICES')}</h3>
              <ul className="car-rental-details__services">
                {selected.included_services && selected.included_services.map((service) => (
                  <li key={service}><p>{service}</p></li>
                ))}
              </ul>
              <h3 className="car-rental-details__subtitle">{window.i18('DRIVER')}</h3>
              <button className={`car-rental-details__driver ${driverIsFilled ? '' : 'car-rental-details__driver--not-filled'}`} onClick={() => openModal({ driver, editDriver })}>
                {driverIsFilled ? driver.firstname : window.i18('TO_COMPLETE')}
                {getSvgIcon('edit', 'ui/')}
              </button>
            </div>
            <div className="col md-col-3 sm-col-4">
              {(selected.tags && selected.tags.indexOf('compliant') > -1) && <p className="offer-badge offer-badge--green">{window.i18('COMPLIANT_FARE')}</p>}
              {(selected.tags && selected.tags.indexOf('negociated-fare') > -1) && <p className="offer-badge offer-badge--blue">{window.i18('NEGOCIATED_FARE')}</p>}
              {(selected.tags && selected.tags.indexOf('not-compliant') > -1) && <p className="offer-badge offer-badge--red">{window.i18('NOT_COMPLIANT_FARE')}</p>}
            </div>
          </div>
          <div className="car-rental-details__bot">

            <h3 className="car-rental-details__subtitle">{selected.tags?.includes('valet') ? window.i18('VALET_WITHDRAWAL') : window.i18('AGENCY_WITHDRAWAL')}</h3>
            <div className="row m-bot">
              <div className="col md-col-6 sm-col-12">
                <p>
                  {selected.pickup_place.renter ? `${selected.pickup_place.renter.name} ` : ''}
                  {selected.pickup_place.name}
                  {', '}
                  {selected.pickup_place.address.formatted_address}
                  {' '}
                  {selected.pickup_place.address.zip_code}
                  {' '}
                  {selected.pickup_place.address.city}
                </p>
                {selected.pickup_place.email && (
                <p>
                  {window.i18('EMAIL')}
                  :
                  {' '}
                  {selected.pickup_place.email}
                </p>
                )}
                {selected.pickup_place.phone && (
                <p>
                  {window.i18('PHONE')}
                  :
                  {' '}
                  {selected.pickup_place.phone}
                </p>
                )}
                {selected.pickup_place?.collect_remarks && <p>{selected.pickup_place?.collect_remarks}</p>}

              </div>
              <div className="col md-col-6 sm-col-12 m-top">
                {selected.tags?.includes('valet') ? (
                  <p>
                    Le
                    {' '}
                    {moment.utc(selected.pickup_time).format(`DD/MM/YYYY ${window.i18('AT')} HH:mm`)}
                  </p>
                )
                  : <Schedule place={selected.pickup_place} />}
              </div>
            </div>
            <h3 className="car-rental-details__subtitle">{selected.tags?.includes('valet') ? window.i18('VALET_DEPOSIT') : window.i18('AGENCY_DEPOSIT')}</h3>
            {selected.pickup_place.id === selected.return_place.id
              ? (
                <p>{selected.tags?.includes('valet') ? `${moment.utc(selected.return_time).format(`DD/MM/YYYY ${window.i18('AT')} HH:mm`)}` : window.i18('IDENTICAL')}</p>
              )
              : (
                <div className="row">
                  <div className="col md-col-6 sm-col-12">
                    <p>
                      {selected.return_place.renter ? `${selected.return_place.renter.name} ` : ''}
                      {selected.return_place.name}
                      {', '}
                      {selected.return_place.address.formatted_address}
                      {' '}
                      {selected.return_place.address.zip_code}
                      {' '}
                      {selected.return_place.address.city}
                    </p>
                    {selected.return_place?.delivery_remarks && <p>{selected.return_place?.delivery_remarks}</p>}
                  </div>
                  <div className="col md-col-6 sm-col-12">
                    {selected.tags?.includes('valet') ? (
                      <p>
                        Le
                        {' '}
                        {moment.utc(selected.return_time).format(`DD/MM/YYYY ${window.i18('AT')} HH:mm`)}
                      </p>
                    ) : <Schedule place={selected.return_place} />}
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="car-rental-details__cart">
          <p className="total">
            {window.i18('TOTAL')}
            {' '}
            :
            {' '}
            <strong>
              {selected.best_price.total / 100}
              €
            </strong>
          </p>
          <button
            type="button"
            disabled={!driverIsFilled || addingToCart}
            onClick={addToCart}
            className={classNames('button-main', { loading: addingToCart })}
          >
            <span className="loading" />
            <span className="text">{window.i18('ADD_TO_CART')}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectedResult;
