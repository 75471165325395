import { capitalize } from './functions';

// sortApproversList sorts alphabetically first then on approval level
const sortApproversList = (approversList) => approversList.sort(
  (a, b) => a.lastname - b.lastname,
)
  .sort((a, b) => a.approval_level - b.approval_level)
  .map((approver) => ({
    name: formatName(approver),
    value: approver.username,
  }));

const formatName = (approver) => {
  const firstName = !approver.firstname
    ? window.i18('APPROVER')
    : approver.firstname
      .split('-')
      .map(
        (name) => name.charAt(0)
          .toUpperCase() + name.slice(1).toLowerCase(),
      )
      .join('-')
      .split(' ')
      .map(
        (name) => name.charAt(0)
          .toUpperCase() + name.slice(1).toLowerCase(),
      )
      .join(' ');
  const lastName = !approver.lastname
    ? capitalize(window.i18('UNKNOWN'))
    : approver.lastname.toUpperCase();

  return `${firstName} ${lastName}`;
};

export { formatName, sortApproversList };
