import React from 'react';

const AccountMigrationError = () => (
  <div className="user-modal">
    <div className="modal-with-tagline">
      <header className="tagline">
        <h1 className="common-title">{window.i18('ACCESS_TO_BOOKING_PORTAL_CHANGED')}</h1>
      </header>
    </div>
    <section className="modal-inner">
      <p>
        {window.i18('WARNING_ACCOUNT_MOVED')}
        {' '}
        <a href="https://vairon.thetreep.com" style={{ textDecoration: 'underline' }}>https://vairon.thetreep.com</a>
        {window.i18('PLEASE_USE_THIS_URL')}
      </p>
      <a className="button-main" href="https://vairon.thetreep.com">{window.i18('GET_THERE')}</a>
    </section>
  </div>
);

export default AccountMigrationError;
