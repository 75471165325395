import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Range } from 'react-range';

const propTypes = {
  priceRange: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  prices: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
};

export default class PriceRange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: [props.priceRange.min, props.priceRange.max],
    };
  }

  componentDidUpdate(prevProps) {
    const { priceRange } = this.props;
    if (prevProps.priceRange !== priceRange) {
      this.setState({
        values: [priceRange.min, priceRange.max],
      });
    }
  }

  handleFinalChange = (values) => {
    values = values.map((v) => parseInt(v, 10) + parseInt(this.props.prices.min, 10));
    this.props.onFinalChange(values[0], values[1]);
  }

  handleChange = (values) => {
    values = values.map((v) => parseInt(v, 10) + parseInt(this.props.prices.min, 10));
    this.setState({ values });
    this.props.onChange(values[0], values[1]);
  }

  render() {
    const { prices } = this.props;
    const { values } = this.state;
    return (
      <div className="slider-input">
        <Range
          values={values.map((v) => parseInt(v, 10) - parseInt(prices.min, 10))}
          min={0}
          max={prices.max - prices.min}
          onChange={this.handleChange}
          onFinalChange={this.handleFinalChange}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
              }}
              className="slider-input__bar-container"
            >
              <div
                ref={props.ref}
                className="slider-input__bar"
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              className="slider-input__thumb"
            >
              <div />
              <div />
              <div />
            </div>
          )}
        />
        <ul className="slider-input__values">
          <li>{prices.min}</li>
          <li>{prices.max}</li>
        </ul>
      </div>
    );
  }
}

PriceRange.prototypes = propTypes;
