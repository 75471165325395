import { combineReducers } from 'redux';

import {
  FETCHING_CART, RECEIVE_CART, FETCHING_PAYMENT_INFO, RECEIVE_PAYMENT_INFO, TOGGLE_CART_SUBSET, FETCHING_CART_FAILED, FETCHING_CART_AMOUNT, RECEIVE_CART_AMOUNT, UPDATE_CART_AMOUNT_FETCHING_TICKETS, RESET_CART, DELETING_FROM_CART, DELETING_FROM_CART_DONE,
} from './constants';
import { ADD_TO_CART_SUCCESS } from '../TripResults_v3/constants';

function isFetchingReducer(state = false, action) {
  switch (action.type) {
    case FETCHING_CART:
      return true;
    case RECEIVE_CART:
    case FETCHING_CART_FAILED:
    case RESET_CART:
      return false;
    default:
      return state;
  }
}

function isDeletingReducer(state = false, action) {
  switch (action.type) {
    case DELETING_FROM_CART:
      return true;
    case RECEIVE_CART:
    case DELETING_FROM_CART_DONE:
    case FETCHING_CART_FAILED:
    case RESET_CART:
      return false;
    default:
      return state;
  }
}

function contentReducer(state = [], action) {
  switch (action.type) {
    case RECEIVE_CART:
      return action.cart;
    case ADD_TO_CART_SUCCESS:
      return action.cart;
    case TOGGLE_CART_SUBSET:
      return state.map((content) => {
        // We find the corresponding subset & toggle its selected key (if it exists, else it gets created along the way)
        content.subsets.map((subset) => {
          if (subset.set_ref === action.subsetId) { subset.selected = !subset.selected; }

          return subset;
        });

        return content;
      });
    case RESET_CART:
      return [];
    default:
      return state;
  }
}

const paymentInfoReducer = combineReducers({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case FETCHING_PAYMENT_INFO:
        return true;
      case RECEIVE_PAYMENT_INFO:
      case RESET_CART:
        return false;
      default:
        return state;
    }
  },
  content: (state = [], action) => {
    switch (action.type) {
      case RECEIVE_PAYMENT_INFO:
        return action.payload;
      case RESET_CART:
        return [];
      default:
        return state;
    }
  },
});

const cartAmountReducer = combineReducers({
  isFetching: (state = false, action) => {
    switch (action.type) {
      case FETCHING_CART_AMOUNT:
        return true;
      case RECEIVE_CART_AMOUNT:
      case RESET_CART:
        return false;
      default:
        return state;
    }
  },
  amount: (state = null, action) => {
    switch (action.type) {
      case RECEIVE_CART_AMOUNT:
        return action.amount;
      case RESET_CART:
        return null;
      default:
        return state;
    }
  },
  fetchingTickets: (state = null, action) => {
    switch (action.type) {
      case UPDATE_CART_AMOUNT_FETCHING_TICKETS:
        return action.tickets;
      case RESET_CART:
        return null;
      default:
        return state;
    }
  },
});

const cartReducer = combineReducers({
  isFetching: isFetchingReducer,
  isDeleting: isDeletingReducer,
  content: contentReducer,
  paymentInfos: paymentInfoReducer,
  cartAmount: cartAmountReducer,
});

export default cartReducer;
