import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import HeaderLink from './HeaderLink';
import Profile from './Profile';
import { searchSvg, cartSvg, accountSvg } from './constants';

import { logout } from '../App/actions';
import { openModal } from '../ModalWrapper/actions';
import logo from '../../../images/the_treep_logo.png';

const Header = (props) => {
  const demoMode = props.user && props.user.company && props.user.company.config && props.user.company.config.test;
  return (
    <header className={classNames('main-header', { 'main-header--demo': demoMode })}>
      <div className="wrapper">
        <Link to="/" className="logo">
          <img src={logo} alt="The Treep - logo" />
          {demoMode && (
          <p className="demo-mode"><span className="small-badge">{window.i18('DEMO_MODE')}</span></p>
          )}
        </Link>
        <ul className="menu">
          <HeaderLink
            exact
            to="/"
            label={window.i18('SEARCH')}
            svg={searchSvg}
          />
          <HeaderLink
            to="/cart"
            disabled={!props.user}
            label={window.i18('CART')}
            svg={cartSvg}
          />
          {!props.sandboxMode && (
          <HeaderLink
            to="/account"
            label={window.i18('MY_SPACE')}
            disabled={!props.user}
            svg={accountSvg}
          />
          )}
        </ul>
        <Profile
          user={props.user}
          openLoginModal={props.openLoginModal}
          openSignupModal={props.openSignupModal}
          logout={props.logout}
        />
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  user: state.app.user,
  sandboxMode: state.app.sandboxMode,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  openLoginModal: () => dispatch(openModal('Login')),
  openSignupModal: () => dispatch(openModal('Signup')),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Header),
);
