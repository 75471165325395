import React from 'react';
import { connect } from 'react-redux';
import { geolocated } from 'react-geolocated';

import { setSearchingTrip, setSearchingHotel, setSearchingCar } from './actions';
import { reverseGeolocation, checkUserInfo } from '../TripSearch/actions';
import { checkUserInfo as checkHotelUserInfo } from '../HotelSearch/actions';
import { checkUserInfo as checkCarRentalUserInfo } from '../CarRentalSearch/actions';
import TripHeader from './TripHeader';
import SearchForm from '../TripSearch';
import HotelForm from '../HotelSearch';
import CarRentalForm from '../CarRentalSearch';
import BusinessFooter from './TripFooter';
import { openModal, closeModal } from '../ModalWrapper/actions';
import {
  RESET_PASSWORD_TOKEN_PATH,
  ACTIVATE_USER_TOKEN_PATH,
} from '../App/constants';
import { activateEmailWithToken, changeCompany } from '../App/actions';
import { getSvgIcon } from '../../utils/icons';
import CovidCheckerHeader from '../../components/CovidCheckerHeader';
import { capitalize } from '../../utils/functions';

class TripSearch extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.match.path === RESET_PASSWORD_TOKEN_PATH) {
      this.props.openPasswordModal(this.props.match.params.token);
    }
    if (this.props.match.path === ACTIVATE_USER_TOKEN_PATH) {
      // Try to activate account if error open 'token renewal' popup
      this.props
        .activateEmailWithToken(this.props.match.params.token)
        .catch((err) => {
          if (err.msg != null) {
            this.props.openAskTokenRenewalModal(
              this.props.match.params.token,
              err.msg,
            );
          } else {
            this.props.openAskTokenRenewalModal(
              this.props.match.params.token,
              err,
            );
          }
        });
    }
  }

  componentDidMount() {
    this.props.checkUserInfo(this.props.user);
    this.props.checkHotelUserInfo(this.props.user);
    this.props.checkCarRentalUserInfo(this.props.user);
  }

  componentDidUpdate(prevProps) {
    // If we get coords from geolocation, we fire the corresponding action that will fill the fields
    if (this.props.coords) this.props.reverseGeolocation(this.props.coords);
    if (prevProps.user !== this.props.user) {
      this.props.checkUserInfo(this.props.user);
      this.props.checkHotelUserInfo(this.props.user);
      this.props.checkCarRentalUserInfo(this.props.user);
    }
    if (prevProps.user == null && this.props.user != null) {
      this.props.closeModal();
    }
  }

  render() {
    const { user, companies, changeCompany } = this.props;
    return (
      <div className="trip-search-background">
        <CovidCheckerHeader />
        <div className="common-centred-wrapper common-wrapper">
          {user?.perms?.includes('travel_agent') && (
            <form className="travel-agent">
              <h1 className="travel-agent__title">{window.i18('TRAVEL_AGENT_MODE')}</h1>
              {companies && companies.fetching && (
                <p className="travel-agent__loading">
                  {window.i18('LOADING')}
                </p>
              )}
              {companies && companies.list && (
                <select
                  className="travel-agent__select"
                  value={user.company_id}
                  name="company"
                  id="company"
                  onChange={(e) => changeCompany(e.target.value)}
                >
                  {companies.list.map((company, i) => (
                    <option key={`option-${i}`} value={company.uid}>
                      {company.name}
                    </option>
                  ))}
                </select>
              )}
              {companies && companies.error && <p>{companies.error}</p>}
            </form>
          )}
          <TripHeader />
          {((user && user.company && user.company.hotel_enabled) || (user && user.company && user.company.carrental_enabled))
            && (
              <form className="trip-search__switch">
                <label
                  htmlFor="trip"
                  className={this.props.search.type === 'trip' ? 'selected' : ''}
                >
                  {getSvgIcon('zeplin-icon', 'ui/')}
                  <span>
                    {' '}
                    {window.i18('TRIP')}
                  </span>
                  <input
                    type="radio"
                    checked={(this.props.search.type === 'trip')}
                    name="type"
                    id="trip"
                    onChange={() => {
                      this.props.setSearchingTrip();
                    }}
                  />
                </label>
                {((user && user.company && user.company.hotel_enabled)) && (
                  <label
                    htmlFor="hotel"
                    className={this.props.search.type === 'hotel' ? 'selected' : ''}
                  >
                    {getSvgIcon('bed', 'transport/svg')}
                    <span>
                      {' '}
                      {capitalize(window.i18('HOTEL'))}
                    </span>
                    <input
                      type="radio"
                      name="type"
                      id="hotel"
                      onChange={() => {
                        this.props.setSearchingHotel();
                      }}
                    />
                  </label>
                )}
                {((user && user.company && user.company.carrental_enabled)) && (
                  <label
                    htmlFor="car"
                    className={this.props.search.type === 'car' ? 'selected' : ''}
                  >
                    {getSvgIcon('car_rental', 'transport/svg')}
                    <span>
                      {this.props.isMobileDevice ? window.i18('RENTAL') : capitalize(window.i18('CAR_RENTAL')) }
                    </span>
                    <input
                      type="radio"
                      checked={(this.props.search.type === 'car')}
                      name="type"
                      id="car"
                      onChange={() => {
                        this.props.setSearchingCar();
                      }}
                    />
                  </label>
                )}
              </form>
            )}
          <div className="trip-search">
            {this.props.search.type === 'trip' && <SearchForm />}
            {this.props.search.type === 'hotel' && <HotelForm />}
            {this.props.search.type === 'car' && <CarRentalForm />}
          </div>
          {user && user.company_id !== 0 ? (
            <BusinessFooter {...this.props} />
          ) : (
            <IndividualFooter />
          )}
        </div>
      </div>
    );
  }
}

const IndividualFooter = () => (
  <p className="individual-footer">
    {window.i18('PRESERVE_PLANET_BY')}
    {' '}
    <b>{window.i18('NEUTRALIZING_AUTOMATICALLY')}</b>
    {' '}
    {window.i18('YOUR_EMISSIONS_BY_PLANTING_TREES')}
  </p>
);

const mapStateToProps = (state) => ({
  user: state.app.user,
  companies: state.app.companies,
  search: state.search,
  isMobileDevice: state.app.isMobileDevice,
});

const mapDispatchToProps = (dispatch) => ({
  reverseGeolocation: (location) => dispatch(reverseGeolocation(location)),
  openPasswordModal: (token) => dispatch(openModal('NewPasswordWithToken', { token })),
  openAskTokenRenewalModal: (token, error) => dispatch(openModal('AskTokenRenewal', { token, error })),
  activateEmailWithToken: (token) => dispatch(activateEmailWithToken(token)),
  checkUserInfo: (user) => dispatch(checkUserInfo(user)),
  checkHotelUserInfo: (user) => dispatch(checkHotelUserInfo(user)),
  checkCarRentalUserInfo: (user) => dispatch(checkCarRentalUserInfo(user)),
  changeCompany: (companyId) => dispatch(changeCompany(companyId)),
  setSearchingHotel: () => dispatch(setSearchingHotel()),
  setSearchingTrip: () => dispatch(setSearchingTrip()),
  setSearchingCar: () => dispatch(setSearchingCar()),
  openLoginModal: () => dispatch(openModal('Signup', { noClose: true })),
  closeModal: () => dispatch(closeModal()),
});

export default navigator.geolocation ? geolocated()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TripSearch),
) : connect(
  mapStateToProps,
  mapDispatchToProps,
)(TripSearch);
