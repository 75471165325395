import React from 'react';
import ReactDOM from 'react-dom';

const MobileActions = ({
  pendingSelected, selected, isCNAM, promptAskAgainApprover, promptAnotherApprover, promptCancelTickets, promptCancelPending, pendingSelectedTravels,
}) => ReactDOM.createPortal(
  <div className="user-tickets-mb-actions">
    { pendingSelected && (
      <>
        {pendingSelectedTravels.length === 1 && (
          <>
            <a className="btn-main" onClick={promptAskAgainApprover}>
              {window.i18('REMIND_APPROVER')}
            </a>
            <a className="btn-secondary" onClick={promptAnotherApprover}>
              {window.i18('NEW_APPROVER')}
            </a>
          </>
        )}
        <a className="btn-secondary" onClick={promptCancelPending}>
          {window.i18('DELETE')}
        </a>
      </>
    )}
    {selected && selected.length > 0 && !isCNAM() && (
      <a className="btn-main" onClick={promptCancelTickets}>
        {window.i18('CANCEL')}
        {' '}
        {selected && selected.length > 1 ? `${window.i18('THE_PLURAL')} ${window.i18('TICKETS').toLowerCase()}` : `${window.i18('THE')} ${window.i18('TICKET').toLowerCase()}`}
      </a>
    )}
  </div>,
  document.querySelector('#root'),
);

export default MobileActions;
