import { CLOSE_IMAGE, SET_IMAGE, SET_GALLERY } from './constants';

export function closeImage() {
  return { type: CLOSE_IMAGE };
}

export function setImage(img) {
  return { type: SET_IMAGE, img };
}

export function setGallery(img, imgs) {
  return { type: SET_GALLERY, img, imgs };
}
