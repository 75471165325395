import React from 'react';
import Select from 'react-select';
import AutoCompleteTravelers from '../AutoComplete/travelers';

export default class UserInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      autocomplete: !!props.travelers,
    };
  }

  handleFocus = () => {
    this.setState({
      isFocused: true,
    });
  };

  handleBlur = (e) => {
    this.setState({
      isFocused: false,
    });

    this.props.onBlur(e);
  };

  handleChange = ({ value }) => {
    const e = {
      target: {
        value,
      },
    };
    this.props.onChange(e);
  }

  render() {
    let classes = 'form-group';
    if (this.state.isFocused) classes += ' on-focus';
    if (this.props.error) classes += ' on-error';
    if (this.props.className) classes += ` ${this.props.className}`;
    let optionLabel = this.props.value;
    if (this.props.values) {
      const found = this.props.values.find((el) => el.value === this.props.value);
      if (found !== undefined) {
        optionLabel = found.label;
      }
    }

    return (
      <div className={classes}>
        <label className="user-label common-sub-title">
          {this.props.label}
        </label>
        <div className="input-wrapper">
          {this.props.error && (<span className="form-error">{this.props.error}</span>)}
          {this.props.type !== 'select'
            ? (
              <input
                className="user-input"
                type={this.props.type}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={this.props.value}
                name={this.props.name}
                disabled={this.props.disabled || false}
                autoComplete={this.state.autocomplete ? 'off' : 'on'}
              />
            )
            : (
              <Select
                placeholder={this.props.placeholder}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={{
                  value: this.props.value,
                  label: optionLabel,
                }}
                name={this.props.name}
                disabled={this.props.disabled || false}
                options={this.props.values}
                getOptionLabel={(option) => (option.label != null ? option.label : option.value)}
              />
            )}
          {
            this.props.travelers && this.props.value && this.state.isFocused
              && <AutoCompleteTravelers travelers={this.props.travelers} handleClick={this.props.handleClick} value={this.props.value} />
          }

        </div>
      </div>
    );
  }
}
