import React from 'react';
import { Field, Form } from 'formik';
// Components
import Radio from '../Radio';
import MaterialDate from '../Inputs/MaterialDate';
import Input from '../Inputs/Material';
import Select from '../Inputs/MaterialSelect';
import countries from '../SelectCountry/ISO-3166';

const options = countries.map((c) => ({
  value: c.value,
  name: c.label,
}));

function DocumentForm({
  setFieldValue, values, errors, touched,
}) {
  return (
    <Form>
      <div className="row">
        <div className="col col-12">
          <Input
            onChange={(value) => setFieldValue('document_name', value)}
            name="document_name"
            value={values.document_name}
            placeholder={`${window.i18('DOCUMENT')}...`}
            type="text"
            label={window.i18('DOCUMENT_NAME')}
            error={errors.document_name}
            touched={touched.document_name}
            optional
          />
        </div>
      </div>
      <div className="row m-top">
        <div className="col col-12">
          <Select
            onChange={(value) => setFieldValue('type', value)}
            name="type"
            value={values.type}
            placeholder={window.i18('DOCUMENT_TYPE')}
            type="text"
            label={window.i18('TYPE')}
            error={errors.type}
            touched={touched.type}
            options={[
              { value: '', name: '' },
              { value: 'passport', name: window.i18('PASSPORT') },
              { value: 'id_card', name: window.i18('ID_CARD') },
              { value: 'driver_license', name: window.i18('DRIVER_LICENSE') },
            ]}
          />
        </div>
      </div>
      <div className="row m-top">
        <div className="m-right">
          <Field
            component={Radio}
            name="civility"
            id="MR"
            label={window.i18('MAN')}
          />
        </div>
        <div>
          <Field
            component={Radio}
            name="civility"
            id="MS"
            label={window.i18('WOMAN')}
          />
        </div>
      </div>
      <div className="row m-top">
        <div className="col col-6 col-sm-12">
          <Input
            onChange={(value) => setFieldValue('firstname', value)}
            name="firstname"
            value={values.firstname}
            placeholder="Jean"
            type="text"
            label={window.i18('FIRSTNAME')}
            error={errors.firstname}
            touched={touched.firstname}
          />
        </div>
        <div className="col col-6 col-sm-12">
          <Input
            onChange={(value) => setFieldValue('lastname', value)}
            name="lastname"
            value={values.lastname}
            placeholder="Doe"
            type="text"
            label={window.i18('LASTNAME')}
            error={errors.lastname}
            touched={touched.lastname}
          />
        </div>
      </div>
      <div className="row m-top">
        <div className="col col-12">
          <Select
            name="nationality"
            value={values.nationality}
            placeholder="France"
            label={window.i18('NATIONALITY')}
            onChange={(value) => setFieldValue('nationality', value)}
            error={errors.nationality}
            touched={touched.nationality}
            options={options}
          />
        </div>
      </div>
      <div className="row m-top">
        <div className="col col-sm-12 col-6">
          <MaterialDate
            onChange={(value) => setFieldValue('effective_date', value)}
            name="effective_date"
            value={values.effective_date}
            placeholder="01/01/1970"
            label={window.i18('EFFECTIVE_DATE')}
            error={errors.effective_date}
            touched={touched.effective_date}
            withYearSelect
          />
        </div>
        <div className="col col-sm-12 col-6">
          <MaterialDate
            onChange={(value) => setFieldValue('expiration_date', value)}
            name="effective_date"
            value={values.expiration_date}
            placeholder="01/01/1970"
            label={window.i18('EXPIRATION_DATE')}
            error={errors.expiration_date}
            touched={touched.expiration_date}
            optional={values.type === 'driver_license'}
            withYearSelect
            future
          />
        </div>
      </div>
      <div className="row m-top">
        <div className="col col-12">
          <Input
            onChange={(value) => setFieldValue('number', value)}
            name="number"
            value={values.number}
            placeholder="123456789"
            type="text"
            label={window.i18('NUMBER')}
            error={errors.number}
            touched={touched.number}
          />
        </div>
      </div>
      <div className="row m-top">
        <div className="col col-12">
          <Select
            name="country_of_issue"
            value={values.country_of_issue}
            placeholder="France"
            label={window.i18('COUNTRY_OF_ISSUE')}
            onChange={(value) => setFieldValue('country_of_issue', value)}
            error={errors.country_of_issue}
            touched={touched.country_of_issue}
            options={options}
          />
        </div>
      </div>
    </Form>
  );
}

export default DocumentForm;
