import React from 'react';

const tripHeader = () => (
  <header className="trip-search-header">
    <h1 className="common-big-title">
      {window.i18('COMPOSE_YOUR_TRIP')}
      <strong className="title-strong">
        {window.i18(
          'PRACTICAL_AND_RESPONSIBLE',
        )}
      </strong>
    </h1>
  </header>
);

export default tripHeader;
