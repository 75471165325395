import React from 'react';
import { analyticPageRequired, creditCardPageRequired } from './actions';

const checkoutButtons = ({
  amount,
  checkout,
  approval,
  confirm,
  cartItem,
}) => {
  if (amount?.missionOrders?.length) {
    return null;
  }
  if (analyticPageRequired(amount)) {
    return (
      <div className="form-submit">
        <a
          className="button-main"
          onClick={checkout}
        >
          {cartItem ? window.i18('CONTINUE_TRAVEL') : window.i18('CONTINUE_TRAVELS')}
        </a>
      </div>
    );
  }
  if (amount.need_approval) {
    return (
      <div className="form-submit">
        <a
          className="button-main"
          onClick={approval}
        >
          {window.i18('ASK_APPROVAL')}
        </a>
      </div>
    );
  }
  if (creditCardPageRequired(amount)) {
    return (
      <div className="form-submit">
        <a
          className="button-main"
          onClick={checkout}
        >
          {window.i18('PAY_BY_CARD')}
        </a>
      </div>
    );
  }
  return (
    <div className="form-submit">
      <a
        className="button-main"
        onClick={confirm}
      >
        {window.i18('CONFIRM_BOOKING')}
      </a>
    </div>
  );
};

export default checkoutButtons;
