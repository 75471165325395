import React from 'react';
import PropTypes from 'prop-types';

import { getSvgIcon } from '../../utils/icons';

const StarsInput = ({ stars, onChange }) => {
  const filledStars = [];
  const emptyStars = [];

  function handleChange(i) {
    if (i === stars) {
      onChange(0);
    } else {
      onChange(i);
    }
  }

  for (let i = 1; i <= 5; i += 1) {
    if (i <= stars) {
      filledStars.push(
        <button onClick={() => handleChange(i)}>
          {getSvgIcon('star', 'ui/')}
        </button>,
      );
    } else {
      emptyStars.push(
        <button onClick={() => handleChange(i)}>
          {getSvgIcon('star', 'ui/')}
        </button>,
      );
    }
  }
  return (
    <div className="stars-input">
      {stars > 0
        && (
        <div className="stars-input__filled">
          {filledStars}
        </div>
        )}
      {stars < 5
        && (
          <div className="stars-input__empty">
            {emptyStars}
          </div>
        )}
    </div>
  );
};

StarsInput.propTypes = {
  stars: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StarsInput;
