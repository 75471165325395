import React from 'react';

function Radio({
  field: {
    name, value, onChange, onBlur = () => null,
  },
  id,
  label,
  className,
  ...props
}) {
  return (
    <div className="common-radio">
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className="m-right"
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

export default Radio;
