import React from 'react';
import {
  Formik, Form,
} from 'formik';
// Components
import moment from 'moment';
import UserForm from '../../../components/UserForm/new';
import LicenseForm from '../../../components/LicenseForm';
import Checkbox from '../../../components/Checkbox';
// Functions
import { formatDateForAPI } from '../../../utils/converters';
import { driverValidationSchema } from '../../CarRentalResults/functions';

class DriverModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataAccuracy: false,
    };
  }

  toggleDataAccuracy = () => this.setState({ dataAccuracy: !this.state.dataAccuracy })

  render() {
    const { driver, closeModal, editDriver } = this.props;
    const { dataAccuracy } = this.state;
    let birthdate = '';
    if (driver.birthdate) {
      if (driver.birthdate === 'xx/xx/xxxx') {
        birthdate = driver.birthdate;
      } else {
        birthdate = moment(driver.birthdate).toDate();
      }
    }
    return (
      <div className="passenger-modal">
        <div className="modal-with-tagline">
          <header className="tagline">
            <h1 className="common-title">
              {window.i18('UPDATE_DRIVER')}
            </h1>
          </header>
        </div>
        <Formik
          initialValues={{
            gender: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            address: '',
            city_of_birth: '',
            birth_country: '',
            license: {
              country_of_issue: '',
              effective_date: '',
              number: '',
            },
            ...driver,
            birthdate,
          }}
          onSubmit={(values) => {
            if (values.birthdate && values.birthdate !== 'xx/xx/xxxx') {
              values.birthdate = formatDateForAPI(`${moment(values.birthdate).format('YYYY-MM-DD')}T120000`);
            }
            const formattedValues = {
              id: values.id,
              uid: values.uid,
              gender: values.gender,
              firstname: values.firstname,
              lastname: values.lastname,
              email: values.email,
              phone: values.phone,
              address: values.address,
              birthdate: values.birthdate,
              city_of_birth: values.city_of_birth,
              birth_country: values.birth_country,
              license: {
                country_of_issue: values.license.country_of_issue,
                effective_date: values.license.effective_date,
                number: values.license.number,
              },
              cards: values.cards,
              handicap: values.handicap,
              wheelchair: values.wheelchair,
              disability_degree: values.disability_degree,
              wheelchair_dimensions: values.wheelchair_dimensions,
              wheelchair_weight: values.wheelchair_weight,
              wheelchair_wheel_thickness: values.wheelchair_wheel_thickness,
              accompanying_dog: values.accompanying_dog,
              accompanying_needed: values.accompanying_needed,
            };
            editDriver(formattedValues);
            closeModal();
          }}
          validationSchema={driverValidationSchema}
        >
          {({
            values, touched, errors, setFieldValue,
          }) => (
            <Form className="passenger-profile__body">
              <UserForm
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                touched={touched}
                withPhone
                withAddress
                withCountry
                withCity
                withBirthdate
              />
              <p>Permis de conduire:</p>
              <LicenseForm
                values={values.license || {}}
                errors={errors.license || {}}
                setFieldValue={(name, value) => setFieldValue(`license.${name}`, value)}
                touched={touched.license || {}}
              />
              <div className="row m-top">
                <Checkbox
                  small
                  checked={dataAccuracy}
                  onChange={this.toggleDataAccuracy}
                  value={window.i18('AGGREE_FORM_RESPONSABILITY')}
                />
              </div>
              <div className="row passenger-profile__footer">
                <div className="col col-12">
                  <button type="submit" className="button-main m-auto" disabled={!dataAccuracy}>
                    {window.i18('CONFIRM')}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default DriverModal;
