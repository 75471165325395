import React from 'react';
import { getSvgIcon } from '../../utils/icons';

export default function HotelTags({ tags }) {
  return (
    <div className="hotel-infos-icons">
      {tags.has_restaurant
                && getSvgIcon('resto-outline', 'ui/')}
      {tags.has_parking
                && getSvgIcon('parking-outline', 'ui/')}
      {tags.has_pool
                && getSvgIcon('piscine-outline', 'ui/')}
      {tags.has_prm
                && getSvgIcon('pmr-a-outline', 'ui/')}
      {tags.has_wifi
                && getSvgIcon('wifi-outline', 'ui/')}
    </div>
  );
}
