import React from 'react';
import classNames from 'classnames';
import { Field } from 'formik';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';
import 'moment/locale/fr';

const fromMonth = new Date('1900-01-01');
const toMonth = new Date();
const futureMonth = new Date('2025-12-31');

function YearMonthForm({ date, onChange, future }) {
  const months = MomentLocaleUtils.getMonths('fr').map((month) => (month != null && month.length > 1 ? month.charAt(0).toUpperCase() + month.slice(1) : month));

  const years = [];
  if (future) {
    for (let i = toMonth.getFullYear(); i <= futureMonth.getFullYear(); i += 1) {
      years.push(i);
    }
  } else {
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i);
    }
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <div className="DayPicker-Caption common-input.select">
      <select name="month" onChange={handleChange} value={date.getMonth()} className="common-input.select">
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()} className="common-input.select">
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}

class MaterialDate extends React.Component {
  constructor(props) {
    super(props);
    let month = new Date();
    if (props.value && props.value !== 'xx/xx/xxxx') {
      month = new Date(props.value);
    }
    this.state = {
      isFocused: props && props.value,
      month,
      showOverlay: false,
    };
  }

  componentDidUpdate() {
    if (!this.props.value && this.props.month && this.state.month.toString() !== new Date(this.props.month).toString()) {
      this.setState({ month: new Date(this.props.month) });
    }
  }

  handleChange = (date) => {
    if (!date) {
      this.props.onChange(undefined);
    } else if (date === 'xx/xx/xxxx') {
      this.props.onChange(date);
    } else {
      this.props.onChange(moment(date, 'DD/MM/YYYY').toDate());
    }
  }

  onFocus = () => {
    if (this.props.value === 'xx/xx/xxxx') {
      this.props.onChange('');
      this.setState({ showOverlay: true });
    }
    this.setState({ isFocused: true });
  }

  handleYearMonthChange = (month) => {
    this.setState({ month: new Date(month) });
  }

  render() {
    const {
      name,
      label,
      placeholder,
      error,
      touched,
      locked,
      disabled,
      value,
      optional,
      withYearSelect,
      future,
      disabledDays,
      required = false,
    } = this.props;
    const { isFocused, month, showOverlay } = this.state;
    const classes = classNames({
      'material-input': true,
      'material-input--focused': (isFocused || locked),
      'material-input--error': (touched && error),
      'material-input--disabled': disabled,
      'material-input--optional': optional,
      'DayPicker__with-year-select': withYearSelect,
    });

    let formattedValue = value;
    if (typeof value === 'string' && value !== '') {
      formattedValue = moment(value).toDate();
    }

    return (
      <div className={classes}>
        <label className="material-input__label" htmlFor={name}>
          {label}
        </label>
        {
        value === 'xx/xx/xxxx' // display simple input
          ? (
            <Field
              className="material-input__input"
              type="text"
              id={name}
              placeholder={placeholder}
              name={name}
              value={value}
              disabled={disabled}
              onChange={this.handleChange}
              onFocus={this.onFocus}
              onBlur={() => {
                if (!value) {
                  this.setState({ isFocused: false });
                }
              }}
            />
          )
          : (
            <Field
              component={DayPickerInput}
              id={name}
              placeholder={placeholder || 'jj/mm/aaaa'}
              name={name}
              // To prevent react-day-picker crash, we must set the format this way https://github.com/gpbl/react-day-picker/issues/910
              format="D/M/YYYY"
              formatDate={formatDate}
              parseDate={parseDate}
              keepFocus={false}
              dayPickerProps={{
                month,
                locale: 'fr',
                localeUtils: MomentLocaleUtils,
                onDayClick: this.handleChange,
                disabledDays,
                captionElement: withYearSelect ? ({ date, localeUtils }) => (
                  <YearMonthForm
                    date={date}
                    localeUtils={localeUtils}
                    locale="fr"
                    onChange={this.handleYearMonthChange}
                    future={future}
                  />
                ) : undefined,
              }}
              onChange={this.handleChange}
              value={formattedValue}
              showOverlay={showOverlay}
              inputProps={{
                className: 'material-input__input',
                onFocus: this.onFocus,
                onChange: (e) => this.handleChange(e.target.value),
                value,
                disabled,
                required,
              }}
            />
          )
        }
        {touched && error && <p className="material-input__error">{error}</p>}
      </div>
    );
  }
}

export default MaterialDate;
