import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { getUserApprovals, promptApproveTicket, promptRejectTicket } from '../actions';
import { getSvgIcon } from '../../../utils/icons';
import { displayPrice, formatTicketTags } from '../../../utils/converters';
import { capitalize } from '../../../utils/functions';

export default function UserApproval() {
  const dispatch = useDispatch();
  const { list, sendingApproval } = useSelector((state) => state.account.userApprovals);
  const userID = useSelector((state) => state.app.user.uid);
  const { perms } = useSelector((state) => state.app.user);
  const [displayed, toDisplayed] = React.useState('pending');
  React.useEffect(() => {
    dispatch(getUserApprovals());
  }, [dispatch]);

  return (
    <div className="user-approval">
      <div className="user-approval-header">
        <h1>{window.i18('APPROVAL_REQUESTS')}</h1>
        <select className="user-approval-select" name="displayed" id="displayed" value={displayed} onChange={(e) => toDisplayed(e.target.value)}>
          <option value="pending">{window.i18('PENDING_APPROVAL')}</option>
          <option value="answered">{window.i18('ANSWERED')}</option>
          <option value="expired">{capitalize(window.i18('EXPIRED'))}</option>
        </select>
      </div>
      {(!list || !list[displayed] || list[displayed].length === 0) && (
        <p>Aucune demande.</p>
      )}
      {list[displayed]?.map((request) => {
        const requester = request.currentRequest?.requester || [];
        const message = request.currentRequest?.message || null;
        const { journey, exemption, missionOrders } = request;
        return (
          <div key={request.approvalID} className="user-approval-travel">
            <h2>
              {capitalize(window.i18('REQUEST'))}
              {' '}
              #
              {request.approvalID}
            </h2>
            {displayed === 'pending' && (
              <p className="user-approval-ttl">
                {window.i18('YOU_HAVE_UP_TO')}
                {' '}
                {journey.reduce((date, t) => (
                  // We get the closest ttl in order to display it
                  moment(t.ticketTimeLimit).isBefore(date)
                    ? moment(t.ticketTimeLimit)
                    : date
                ), moment().add(100, 'years')).format('L')}
                {' '}
                {window.i18('TO_HANDLE_THIS_REQUEST')}
              </p>
            )}
            <div>
              <p className="small-text">
                {window.i18('REQUESTED_BY')}
                {' '}
                {(requester.firstname && requester.lastname) ? (
                  <b>
                    {requester.firstname}
                    {' '}
                    {requester.lastname}
                  </b>
                ) : <b>{requester.email}</b>}
                {' '}
                {window.i18('ON')}
                {' '}
                <b>
                  {moment(request?.currentRequest.requestedAt).format('LLLL')}
                </b>
              </p>
              {message && (
              <p className="request-message">{message}</p>
              )}
            </div>
            {exemption && (
            <div>
              <p className="small-text exemption-reason">{window.i18('NOT_CONFORM_REASON')}</p>
              <p className="request-message">{exemption}</p>
            </div>
            )}
            {missionOrders && missionOrders.map((missionOrder) => <MissionOrder key={missionOrder.id} missionOrder={missionOrder} />)}
            {!missionOrders && request.paxs && (
              <p className="user-approval-pax">
                {getSvgIcon('use-user', 'ui')}
                {' '}
                {request.paxs.map((pax) => `${pax.firstname} ${pax.lastname}`).join(', ')}
              </p>
            )}
            {journey && journey.map((ticket) => (
              <TravelItem key={ticket.start} ticket={ticket} />
            ))}
            {displayed === 'pending' && (
              <div className="user-approval-actions">
                <button className="button-main" disabled={!perms.includes('approval') || sendingApproval.includes(request.approvalID)} onClick={() => dispatch(promptApproveTicket(request.approvalID, request))}>{window.i18('ACCEPT')}</button>
                {(perms.includes('approval') || perms.includes('check_approval')) && request.escalationEnabled && <button className="button-main" disabled={sendingApproval.includes(request.approvalID)} onClick={() => dispatch(promptApproveTicket(request.approvalID, request, true, request.approvers))}>{window.i18('ESCALATE')}</button>}
                <button className="button-secondary" disabled={sendingApproval.includes(request.approvalID)} onClick={() => dispatch(promptRejectTicket(request.approvalID))}>{window.i18('REFUSE')}</button>
              </div>
            )}
            {displayed !== 'pending' && request.chain.map((step) => (
              <p key={step.answeredAt} className={classNames({ denied: request.status === 'denied', approved: request.status === 'approved', escalated: request.status === 'escalated' })}>
                {step.response && (
                  <>
                    {step.response.status === 'denied' ? window.i18('DENIED') : ''}
                    {step.response.status === 'approved' ? capitalize(window.i18('APPROVED_FEM')) : ''}
                    {step.response.status === 'escalated' ? window.i18('ESCALATED') : ''}
                    {step.response.status === 'expired' ? window.i18('EXPIRED_FEM') : ''}
                    {' '}
                    {window.i18('BY')}
                    {' '}
                    {step.approver.userID === userID && <b>{window.i18('YOU')}</b>}
                    {step.approver.userID !== userID && (
                      <>
                        {(step.approver.firstname && step.approver.lastname) && (
                          <b>
                            {step.approver.firstname}
                            {' '}
                            {step.approver.lastname}
                          </b>
                        )}
                        {(!step.approver.firstname || !step.approver.lastname) && <b>{step.approver.email}</b>}
                      </>
                    )}
                  </>
                )}
                {!step.response && (
                  <>
                    {window.i18('PENDING_APPROVAL')}
                    {' '}
                    {window.i18('BY')}
                    {' '}
                    {(step.approver.firstname && step.approver.lastname) && (
                      <b>
                        {step.approver.firstname}
                        {' '}
                        {step.approver.lastname}
                      </b>
                    )}
                    {(!step.approver.firstname || !step.approver.lastname) && <b>{step.approver.email}</b>}
                  </>
                )}
              </p>
            ))}
          </div>
        );
      })}
    </div>
  );
}

function TravelItem({ ticket }) {
  const [collapsed, setCollapsed] = React.useState(true);
  return (
    <div key={ticket.start} className={classNames('user-approval-ticket', { expanded: !collapsed })}>
      <div className="user-approval-ticket__header">
        <div className="user-approval-ticket__title">
          {ticket.type === 'rail' && getSvgIcon('tgv', 'transport/svg/')}
          {ticket.type === 'flight' && getSvgIcon('airplane', 'transport/svg/')}
          {ticket.type === 'hotel' && getSvgIcon('hotel', 'transport/svg/')}
          {ticket.type === 'car_rental' && getSvgIcon('car_rental2', 'transport/svg/')}
          <h2>{ticket.routes.join(' - ')}</h2>
        </div>
        <div className="user-approval-ticket__price">
          <h2>{displayPrice(ticket.totalAmount / 100)}</h2>
          {ticket.compliant !== null && <span className="badge m-left" style={{ background: ticket.compliant ? '#01dcbe' : '#EB5D5D', fontSize: 16 }}>{formatTicketTags(ticket.compliant ? 'compliant' : 'not-compliant')}</span>}
          <button className="icon-button" onClick={() => setCollapsed(!collapsed)}>
            <span className="chevron" />
          </button>
        </div>
      </div>
      {!collapsed && (
        <div className="user-approval-ticket__body">
          <div className="user-approval-ticket__info">
            {(ticket.type === 'rail' || ticket.type === 'flight') && (
              <>
                <p>
                  {moment(ticket.start).format('L')}
                </p>
                <p>
                  {window.i18('DEPARTURE')}
                  :
                  {' '}
                  <b>{moment(ticket.start).format('LT')}</b>
                  {' '}
                  -
                  {' '}
                  {window.i18('ARRIVAL')}
                  :
                  {' '}
                  <b>{moment(ticket.end).format('LT')}</b>
                  {' '}
                  {moment(ticket.end).diff(ticket.start, 'days') > 0 && `(+${moment(ticket.end).diff(ticket.start, 'days')} ${moment(ticket.end).diff(ticket.start, 'days') > 1 ? 'jours' : 'jour'})`}
                </p>
              </>
            )}
            {ticket.type === 'hotel' && (
              <>
                <p className="small">{ticket.roomName}</p>
                <p>
                  {window.i18('CHECKIN')}
                  :
                  {' '}
                  <b>{moment(ticket.start).format('L')}</b>
                </p>
                <p>
                  {window.i18('CHECKOUT')}
                  :
                  {' '}
                  <b>{moment(ticket.end).format('L')}</b>
                </p>
              </>
            )}
            {ticket.type === 'car_rental' && (
              <>
                <p className="small">{ticket.agencyName}</p>
                <p>
                  {window.i18('VEHICLE_TAKEN')}
                  :
                  {' '}
                  <b>{moment(ticket.start).format('L')}</b>
                </p>
                <p>
                  {window.i18('VEHICLE_GIVEN')}
                  :
                  {' '}
                  <b>{moment(ticket.end).format('L')}</b>
                </p>
              </>
            )}
          </div>
          <div className="user-approval-ticket__pax">
            {(ticket.type === 'rail' || ticket.type === 'flight') && <p>{ticket.fareLabel}</p>}
            {ticket?.paxs?.map((pax) => (
              <p key={pax.firstname} className="pax">
                {getSvgIcon('use-user', 'ui')}
                {' '}
                {pax.firstname && pax.lastname ? `${pax.firstname} ${pax.lastname}` : pax.username}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function MissionOrder({ missionOrder }) {
  const [collapsed, setCollapsed] = React.useState(true);
  const {
    pax, purpose, location, startLocation, endLocation, extraRemark, transports,
  } = missionOrder;
  return (
    <div className={classNames('user-approval-ticket', { expanded: !collapsed })}>
      <div className="user-approval-ticket__header mission-order-header">
        <div className="user-approval-ticket__title">
          <h2>
            {window.i18('MISSION_ORDER_OF')(pax.firstname && pax.lastname ? `${pax.firstname} ${pax.lastname}` : pax.username)}
          </h2>

        </div>
        <button className="icon-button" onClick={() => setCollapsed(!collapsed)}>
          <span className="chevron" />
        </button>
      </div>
      {!collapsed && (
        <div className="mission-order-data">
          {purpose && (
          <p>
            <b>
              {window.i18('MISSION_OBJECT')}
              :
            </b>
            {' '}
            {purpose}
          </p>
          )}
          {location && (
          <p>
            <b>
              {window.i18('MISSION_PLACE')}
              :
            </b>
            {' '}
            {location}
          </p>
          )}
          {startLocation && (
          <p>
            <b>
              {window.i18('START_LOCATION')}
              :
            </b>
            {' '}
            {startLocation}
          </p>
          )}
          {endLocation && (
          <p>
            <b>
              {window.i18('END_LOCATION')}
              :
            </b>
            {' '}
            {endLocation}
          </p>
          )}
          {transports && (
          <p>
            <b>
              {window.i18('LINK_TRANSPORTS')}
              :
            </b>
            {' '}
            {transports.join(', ')}
          </p>
          )}
          {extraRemark && (
          <p>
            <b>
              {window.i18('OTHER_REMARKS')}
              :
            </b>
            {' '}
            {extraRemark}
          </p>
          )}
        </div>
      )}
    </div>
  );
}
