import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Skeleton from 'react-skeleton-loader';
import DocumentsForm from '../../../components/DocumentsForm';
import { setDocuments } from '../actions';

function UserDocuments({
  documents, user, setDocuments, isMobileDevice,
}) {
  const initialDocument = {
    firstname: user.firstname,
    lastname: user.lastname,
    civility: user.gender,
  };

  return (
    <div className="user-documents">
      {isMobileDevice && (<h2>Mes documents</h2>)}
      {documents && (documents.fetching || documents.setting)
        ? (
          <>
            <Skeleton width="100%" height="4em" heightRandomness={false} widthRandomness={false} />
            <Skeleton width="100%" height="4em" heightRandomness={false} widthRandomness={false} />
            <Skeleton width="100px" height="2em" heightRandomness={false} widthRandomness={false} />
          </>
        )
        : (
          <>
            <Formik initialValues={{ documents: documents.list }}>
              {({ values, setFieldValue }) => {
                if (JSON.stringify(documents.list) !== JSON.stringify(values.documents)) {
                  setDocuments(values.documents);
                }
                return (
                  <DocumentsForm documents={values.documents} setFieldValue={setFieldValue} values={{ ...initialDocument }} userDocuments isMobileDevice={isMobileDevice} />
                );
              }}
            </Formik>
          </>
        )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.app.user,
  documents: state.account.documents,
  isMobileDevice: state.app.isMobileDevice,
});

const mapDispatchToProps = (dispatch) => ({
  setDocuments: (documents) => dispatch(setDocuments(documents)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDocuments);
