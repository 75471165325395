import React from 'react';
import { NbNight, TooltipSubset } from './SubsetTicket';

const subsetHotelTicket = (props) => (
  <>
    {props.tickets.map((ticket) => (
      <div
        key={ticket.offer.id}
        className="room-details"
      >
        <div className="hotel-info">
          <p className="room-info">
            {`${NbNight(props.departure,
              props.arrival)} ${window.i18('NIGHTS')} ${window.i18(
              'INTO',
            )} ${ticket.offer.fare_name}`}
          </p>
          <p className="price">{`${ticket.offer.effective_price}€`}</p>
        </div>
        <div className="traveler-info">
          {
            ticket.ticket_travelers.map((traveler, i) => (
              <div
                key={traveler.uid}
                className="common-tooltip__parent"
              >
                {traveler.firstname}
                {' '}
                {traveler.lastname}
                {ticket.ticket_travelers.length - i > 1 ? ',' : ''}
                &nbsp;
                <TooltipSubset traveler={traveler} />
              </div>
            ))
          }
        </div>
      </div>
    ))}
  </>
);

export default subsetHotelTicket;
