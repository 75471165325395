import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

// Components
import Container from './Container';
import AutocompleteTravelers from '../AutoComplete/travelers';

// Functions
import { getSvgIcon } from '../../utils/icons';

function Driver({
  travelers,
  drivers,
  openModal,
  hasTravelersSearch,
  editDriver,
  addDriver,
  searchTravelers,
  selectDriver,
  field,
  isMobileDevice,
}) {
  const [query, setQuery] = useState('');
  const [travelersFocused, setTravelersFocused] = useState(false);

  useEffect(() => {
    const typingTimeout = query.length && setTimeout(() => {
      searchTravelers(query);
    }, 700);
    return () => clearTimeout(typingTimeout);
  }, [query, searchTravelers]);

  const addAndSelectDriver = (driver) => {
    addDriver(driver);
    selectDriver(drivers.length);
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleTravelerClick = (driver) => {
    addAndSelectDriver(driver);
    setQuery('');
  };

  const formatDriverName = (driver) => (driver.firstname && driver.lastname ? `${driver.firstname.charAt(0)}. ${driver.lastname}` : 'Conducteur');

  return (
    <Container title={window.i18('SELECT_DRIVER')} isMobileDevice={isMobileDevice} label={field}>
      <div className="passengers bordered-block p-all">
        {hasTravelersSearch && (
        <div className="passengers__travelers">
          <input
            type="text"
            className="passengers__input"
            onFocus={() => setTravelersFocused(true)}
            onBlur={() => setTravelersFocused(false)}
            value={query}
            onChange={handleQueryChange}
            placeholder={window.i18('SEARCH_EMPLOYEE')}
          />
          {travelers
                && travelersFocused
                && query && (
                  <AutocompleteTravelers
                    travelers={travelers}
                    handleClick={handleTravelerClick}
                    value={query}
                  />
          )}
        </div>
        )}
        <div className="passengers__list">
          <ul>
            {drivers.map((driver) => (
              <li key={driver.id} className="passengers__item">
                <input type="radio" name="driver" className="common-radio-input m-right" id={driver.id} checked={driver.selected} onChange={() => selectDriver(driver.id)} />
                <button
                  className={classNames('name', { 'name--internal': driver.uid })}
                  onClick={() => openModal({ driver, editDriver })}
                >
                  {driver.uid !== undefined && (
                  <div className="common-tooltip__parent">
                    {getSvgIcon('use-user', 'ui/')}
                    <div className="common-tooltip">
                      <div className="common-tooltip__content">
                        {window.i18('EMPLOYEE')}
                      </div>
                    </div>
                  </div>
                  )}
                  {formatDriverName(driver)}
                  {getSvgIcon('edit', 'ui/')}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <button className="passengers__add" onClick={() => addAndSelectDriver({})}>
        {getSvgIcon('add', 'ui/')}
        {window.i18('CREATE_DRIVER')}
      </button>
    </Container>
  );
}

export default Driver;
