import React from 'react';

import DateTime from '../../components/SearchDetails/DateTime';
import Location from '../../components/SearchDetails/Location';
import Driver from '../../components/SearchDetails/Driver';
import Default from '../../components/SearchDetails/Default';
import CarTypes from '../../components/SearchDetails/CarTypes';

const carRentalSearchDetails = (props) => {
  switch (props.selected) {
    case 'pickUpLocation':
      return (
        <Location
          searchLocation={props.pickUpLocation}
          field="pickUpLocation"
          selectedLocation={props.search.pickUpLocation.location}
          setLocation={props.setPickUpLocation}
          isMobileDevice={props.isMobileDevice}
          suggestedAddresses={props.suggestedAddresses}
        />
      );
    case 'returnLocation':
      return (
        <Location
          searchLocation={props.returnLocation}
          field="returnLocation"
          selectedLocation={props.search.returnLocation.location}
          setLocation={props.setReturnLocation}
          isMobileDevice={props.isMobileDevice}
          suggestedAddresses={props.suggestedAddresses}
        />
      );
    case 'pickUpDate':
      return (
        <DateTime
          {...props}
          field="a"
          isCarRental
          withTime
          isMobileDevice={props.isMobileDevice}
        />
      );
    case 'returnDate':
      return (
        <DateTime
          {...props}
          field="r"
          isCarRental
          withTime
          isMobileDevice={props.isMobileDevice}
        />
      );
    case 'driver':
      return (
        <Driver
          drivers={props.search.drivers}
          openModal={props.openModal}
          userPermissions={props.userPermissions}
          travelers={props.travelers}
          editDriver={props.editDriver}
          addDriver={props.addDriver}
          hasTravelersSearch={props.hasTravelersSearch}
          searchTravelers={props.searchTravelers}
          selectDriver={props.selectDriver}
          isMobileDevice={props.isMobileDevice}
          field="driver"
        />
      );
    case 'carTypes':
      return (
        <CarTypes
          carTypes={props.carTypes}
          setCarTypes={props.setCarTypes}
          isMobileDevice={props.isMobileDevice}
          field="carTypes"
        />
      );
    default:
      return (
        <Default
          co2={props.co2}
          isMobileDevice={props.isMobileDevice}
        />
      );
  }
};

export default carRentalSearchDetails;
