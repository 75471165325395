import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { TweenLite } from 'gsap';
import Switch from 'react-switch';
// // Actions
import {
  toggleTravelersDistribution, toggleMap, setSorting, setSelectedResult,
} from '../actions';
// Components
import HotelSearch from '../../HotelSearch';
import Filters from './Filters';
import Sorting from './Sorting';

const propTypes = {
  city: PropTypes.string.isRequired,
  travelersCount: PropTypes.number.isRequired,
  date: PropTypes.shape({
    departure: PropTypes.string.isRequired,
    arrival: PropTypes.string.isRequired,
  }).isRequired,
  travelersDistribution: PropTypes.bool.isRequired,
  toggleTravelersDistribution: PropTypes.func.isRequired,
  toggleMap: PropTypes.func.isRequired,
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySearch: false,
      showFilters: false,
    };
    this.block = React.createRef();
    this.back = React.createRef();
    this.modify = React.createRef();
  }

  componentDidMount() {
    if (this.props.travelersDistribution) {
      TweenLite.set(this.block.current, { xPercent: -50 });
      TweenLite.set(this.back.current, { xPercent: 0, opacity: 1, pointerEvents: 'all' });
      TweenLite.set(this.modify.current, { opacity: 0, pointerEvents: 'none' });
    } else if (!this.props.travelersDistribution) {
      TweenLite.set(this.block.current, { xPercent: 0, x: 0 });
      TweenLite.set(this.back.current, { xPercent: 50, opacity: 0, pointerEvents: 'none' });
      TweenLite.set(this.modify.current, { opacity: 1, pointerEvents: 'all' });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(prevProps) {
    if (prevProps.travelersDistribution && !this.props.travelersDistribution) {
      TweenLite.to(this.block.current, 0.5, { xPercent: -100, x: -16, opacity: 0 });
      TweenLite.to(this.back.current, 0.5, { xPercent: 0, opacity: 1, pointerEvents: 'all' });
      TweenLite.to(this.modify.current, 0.5, { opacity: 0, pointerEvents: 'none' });
    } else if (!prevProps.travelersDistribution && this.props.travelersDistribution) {
      TweenLite.to(this.block.current, 0.5, { xPercent: 0, x: 0, opacity: 1 });
      TweenLite.to(this.back.current, 0.5, { xPercent: 50, opacity: 0, pointerEvents: 'none' });
      TweenLite.to(this.modify.current, 0.5, { opacity: 1, pointerEvents: 'all' });
    }
  }

  toggleSearch= () => {
    this.setState({ displaySearch: !this.state.displaySearch });
  }

  render() {
    const {
      city, travelersCount, date, toggleTravelersDistribution, toggleMap, displayMap, setSorting, sorting, isMobileDevice, setSelected, selected,
    } = this.props;
    const { displaySearch, showFilters } = this.state;

    const setShowFilters = (value) => this.setState({ showFilters: value });

    // We use moment to get the number of days between the arrival and the departure
    const departure = moment(date.departure);
    const arrival = moment(date.arrival);
    const nightsCount = moment(departure).diff(moment(arrival), 'days');

    const searchClasses = classNames('search-block offers__search', { show: displaySearch });

    return (
      <>
        <div
          className={searchClasses}
        >
          <HotelSearch resuls />
        </div>
        <div className="hotel-results-header m-auto m-top p-left">
          <div className="title">
            <h1>{`${window.i18('HOTELS_IN')} ${city}`}</h1>
            <h2 className="p-left">
              {`${travelersCount} ${travelersCount > 1 ? window.i18('PERSONS') : window.i18('PERSON')} ${nightsCount} ${nightsCount > 1 ? window.i18('NIGHTS') : window.i18('NIGHT')} ${window.i18('FROM_THE')} ${arrival.format('DD/MM')} ${window.i18('TO_THE')} ${departure.format('DD/MM')}`}
            </h2>
            <button className={`button-secondary m-left ${isMobileDevice ? 'hidden' : ''}`} onClick={this.toggleSearch} ref={this.modify}>
              {window.i18('EDIT_SEARCH')}
            </button>
            {isMobileDevice && selected && (
              <button className="button-secondary" onClick={() => setSelected(null)}>{window.i18('RETURN_TO_LIST')}</button>
            )}
          </div>
          <div ref={this.block} className="hotel-results-header__filters m-right">
            {isMobileDevice ? (
              <div className="user-tickets-filter" style={{ marginBottom: '5px' }}>
                <button className={`user-tickets-filter__toggle common-sub-title ${showFilters ? 'on' : 'off'}`} onClick={() => setShowFilters(!showFilters)}>{window.i18('FILTER_AND_SORT')}</button>
                <div className={`user-tickets-filter__container ${showFilters ? 'show' : 'hide'}`} id="results-filters">
                  <Filters />
                  <Sorting sorting={sorting} setSorting={setSorting} isMobileDevice={isMobileDevice} />
                  <button className="button-main" onClick={() => setShowFilters(!showFilters)}>{window.i18('CONFIRM')}</button>
                </div>
              </div>
            ) : (
              <>
                <Filters />
                <Sorting sorting={sorting} setSorting={setSorting} />
              </>
            )}
            {!this.props.isMobileDevice
            && (
            <div className="display-map">
              <p className="m-right">{window.i18('DISPLAY_ON_MAP')}</p>
              <Switch
                onChange={toggleMap}
                checked={displayMap}
                onColor="#f18888"
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
            )}
          </div>
          <div className="hotel-results-back" ref={this.back}>
            <button className="button-secondary" onClick={toggleTravelersDistribution}>
              {window.i18('RETURN_TO_RESULTS_LIST')}
            </button>
          </div>
        </div>
      </>
    );
  }
}

Header.propTypes = propTypes;

const mapStateToProps = (state) => ({
  isMobileDevice: state.app.isMobileDevice,
  city: state.hotelResults.search.place.location.city,
  travelersCount: state.hotelResults.travelers.length,
  date: state.hotelResults.search.date,
  travelersDistribution: state.hotelResults.selectedResult.travelersDistribution,
  displayMap: state.hotelResults.displayMap,
  sorting: state.hotelResults.sorting,
  selected: state.hotelResults.selectedResult.id,
});

const mapDispatchToProps = (dispatch) => ({
  toggleTravelersDistribution: () => dispatch(toggleTravelersDistribution()),
  toggleMap: () => dispatch(toggleMap()),
  setSorting: (sorting) => dispatch(setSorting(sorting)),
  setSelected: (id) => dispatch(setSelectedResult(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
