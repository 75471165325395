import { toast as Notif } from 'react-toastify';

import { push } from 'connected-react-router';
import API from '../../utils/api';
import { frenchDateToAPI } from '../../utils/converters';
import { persistLogin, checkLogin } from '../App/actions';

export function deleteInfos(token, username) {
  return (dispatch) => {
    API.delete(`/user/onboarding/data?token=${token}&username=${username}`).then(() => {
      Notif.success(window.i18('INFOS_DELETED'), { autoClose: 8000 });
      dispatch(push('/'));
    });
    // Do not need this because we are catching everything localy now. See api.js interceptors
    /*
    .catch((error) => {
      Notif.warning(friendlyError(error), '', 8000);
    });
    */
  };
}

export function saveInfos(token, values, secondValues) {
  return (dispatch) => {
    if (values.stock !== 'accept') {
      values.gdpr_consent = false;
    }
    delete values.stock;
    if (secondValues) {
      delete secondValues.hasHandicap;
      if (secondValues.birthdate != null) {
        secondValues.birthdate = frenchDateToAPI(secondValues.birthdate);
      }
    }
    API.post('/user/onboarding/confirm', { ...values, ...secondValues, onboarding_token: token })
      .then((response) => {
        Notif.success(window.i18('INFOS_SAVED'), { autoClose: 8000 });
        if (response.data.result) {
          persistLogin(response.data.result.token);
          dispatch(checkLogin());
          dispatch(push('/'));
        }
      });
    // Do not need this because we are catching everything localy now. See api.js interceptors
    /*
    .catch((error) => {
      Notif.warning(friendlyError(error), '', 8000);
    });
    */
  };
}

export function getOnboarding(token, username) {
  return () => API.get(`user/onboarding/data?token=${token}&username=${username}`).then((res) => res);
  /*
  catch(() => {
    Notif.warning(window.i18('ACTIVATION_LINK_EXPIRED'), '', 8000);
  });
  */
}
