import React, { useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import { TimelineLite, CSSPlugin } from 'gsap';
import { getSvgIcon } from '../../utils/icons';

export default function BarrierGestures({ destination, returnTrip }) {
  useEffect(() => {
    const tl = new TimelineLite();
    tl.from('#destination-city', 0.2, { opacity: 0, y: 20 });
    tl.from('#reflexes-text', 0.2, { opacity: 0, y: 20 }, '-=0.2');
    tl.staggerFrom('.barrier-gestures__reflexe', 0.5, { opacity: 0, y: 12, rotationY: 90 }, 0.4, '+=0.5');
  }, []);
  return (
    <div className="barrier-gestures">
      <div className="barrier-gestures__city" id="destination-city">
        <p>
          <big>
            {returnTrip ? window.i18('YOU_ARE_GOING_BACK_TO') : window.i18('YOU_ARE_GOING_TO')}
            {' '}
          </big>
        </p>
        <h1>
          {destination}
          {' '}
          ?
        </h1>
      </div>
      <p className="barrier-gestures__text p-all" id="reflexes-text"><big>{window.i18('BARRIER_GESTURES')}</big></p>
      <div className="row m-top barrier-gestures__reflexes">
        <div className="col col-4 barrier-gestures__reflexe">
          {getSvgIcon('handwash', 'ui/')}
        </div>
        <div className="col col-4 barrier-gestures__reflexe">
          {getSvgIcon('social-distancing', 'ui/')}
        </div>
        <div className="col col-4 barrier-gestures__reflexe">
          {getSvgIcon('mask', 'ui/')}
        </div>
      </div>
    </div>
  );
}
