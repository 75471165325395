import React from 'react';
import { connect } from 'react-redux';

import { withRouter, Link } from 'react-router-dom';
import HeaderLink from './HeaderLink';
import Profile from './Profile';
import {
  searchSvg, cartSvg, accountSvg, helpSvg,
} from './constants';

import { logout } from '../App/actions';
import logo from '../../../images/CNAM/AM_logo.svg';

const Header = (props) => (
  <header className="main-header">
    <div className="wrapper">
      <Link to="/" className="logo">
        <img src={logo} alt="Assurance Maladie" className="cnam" />
      </Link>
      <ul className="menu cnam">
        <HeaderLink
          exact
          to="/form"
          label="Nouvelle réservation"
          svg={searchSvg}
        />
        <HeaderLink
          to="/cart"
          disabled={!props.user}
          label="Panier"
          svg={cartSvg}
        />
        <HeaderLink
          to="/account"
          label="Mon espace"
          disabled={!props.user}
          svg={accountSvg}
        />
        <HeaderLink
          to="/help"
          label="Aide"
          svg={helpSvg}
        />
      </ul>
      <Profile
        user={props.user}
        logout={props.logout}
        loginLink="/form/login"
        signupLink="/form/signup"
      />
    </div>
  </header>
);

const mapStateToProps = (state) => ({
  user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Header),
);
