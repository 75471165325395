export const SET_PICK_UP_LOCATION = 'carRentalSearch/SET_PICK_UP_LOCATION';
export const SET_PICK_UP_LOCATION_TOUCHED = 'carRentalSearch/SET_PICK_UP_LOCATION_TOUCHED';
export const SET_RETURN_LOCATION = 'carRentalSearch/SET_RETURN_LOCATION';
export const SET_RETURN_LOCATION_TOUCHED = 'carRentalSearch/SET_RETURN_LOCATION_TOUCHED';
export const EDIT_DRIVER = 'carRentalSearch/EDIT_DRIVER';
export const SET_PICK_UP_DATE_TOUCHED = 'carRentalSearch/SET_PICK_UP_DATE_TOUCHED';
export const SET_RETURN_DATE_TOUCHED = 'carRentalSearch/SET_RETURN_DATE_TOUCHED';
export const SET_PICK_UP_DATE = 'carRentalSearch/SET_PICK_UP_DATE';
export const SET_RETURN_DATE = 'carRentalSearch/SET_RETURN_DATE';
export const SET_CAR_RENTAL_TIME = 'carRentalSearch/SET_RETURN_TIME';
export const SET_FORM_ERRORS = 'carRentalSearch/SET_FORM_ERRORS';
export const SEARCHING_HOTELS = 'carRentalSearch/SEARCHING_CARS';
export const FILL_USER_INFO = 'carRentalSearch/FILL_USER_INFO';
export const SET_CAR_TYPES = 'carRentalSearch/SET_CAR_TYPES';
export const ADD_DRIVER = 'carRentalSearch/ADD_DRIVER';
export const SELECT_DRIVER = 'carRentalSearch/SELECT_DRIVER';
export const ADD_LAST_TRAVELERS = 'carRentalSearh/ADD_LAST_TRAVELERS';
export const SET_ENTITY_TOUCHED = 'carRentalSearh/SET_ENTITY_TOUCHED';
export const SET_SEARCH_PARAMS = 'carRentalSearh/SET_SEARCH_PARAMS';
