import React from 'react';
import classNames from 'classnames';
// Components
import Input from '../Inputs/Material';
import Address from '../Inputs/MaterialAddress';
import Select from '../Inputs/MaterialSelect';
import Switcher from '../Switcher';
import countries from '../SelectCountry/ISO-3166';
import MaterialDate from '../Inputs/MaterialDate';
import Checkbox from '../Checkbox';

const options = countries.map((c) => ({
  value: c.value,
  name: c.label,
}));

const UserForm = (props) => {
  const {
    values, setFieldValue, errors, touched,
    withAddress, withPhone, withCountry, withBirthdate, withHandicap, withCity, withUsername, handicaps, prefix = '',
  } = props;
  let hasHandicap = false;
  if (values && (
    (values.handicap && values.handicap.length > 0)
      || values.hasHandicap)) {
    hasHandicap = true;
  }
  return (
    <>
      <div className="row m-bot">
        <Switcher
          first={window.i18('WOMAN')}
          second={window.i18('MAN')}
          value={values.gender === 'MS'}
          disabled={!values.gender}
          handleClick={(value) => (value === 1
            ? setFieldValue(`${prefix}gender`, 'MS')
            : setFieldValue(`${prefix}gender`, 'MR'))}
          small
          touched={touched.firstname}
        />
        {errors.gender && (
        <p className="material-input__error">{errors.gender}</p>
        )}
      </div>
      <div className="row m-bot">
        <div className="col col-6">
          <Input
            onChange={(value) => setFieldValue(`${prefix}firstname`, value)}
            name="firstname"
            value={values.firstname}
            placeholder="John"
            type="text"
            label={window.i18('FIRSTNAME')}
            error={errors.firstname}
            touched={touched.firstname}
            required
          />
        </div>
        <div className="col col-6">
          <Input
            onChange={(value) => setFieldValue(`${prefix}lastname`, value)}
            name="lastname"
            value={values.lastname}
            placeholder="Snow"
            type="text"
            label={window.i18('LASTNAME')}
            error={errors.lastname}
            touched={touched.lastname}
            required
          />
        </div>
      </div>
      {(withBirthdate || withPhone) && (
        <div className="row m-bot">
          {withBirthdate && (
          <div className={classNames('col', { 'col-6': withPhone, 'col-12': !withPhone })}>
            <MaterialDate
              name="birthdate"
              value={values.birthdate}
              placeholder="01/01/2021"
              label={window.i18('BIRTHDATE')}
              error={errors.birthdate}
              touched={touched.birthdate}
              onChange={(value) => setFieldValue(`${prefix}birthdate`, value)}
              withYearSelect
              required
            />
          </div>
          )}
          {withPhone && (
          <div className="col col-6">
            <Input
              onChange={(value) => setFieldValue(`${prefix}phone`, value)}
              name="phone"
              value={values.phone}
              placeholder="0123456789"
              type="text"
              label={window.i18('PHONE')}
              error={errors.phone}
              touched={touched.phone}
              required
            />
          </div>
          )}
        </div>
      )}
      <div className="row m-bot">
        <div className={classNames('col', { 'col-6': withUsername, 'col-12': !withUsername })}>
          <Input
            onChange={(value) => setFieldValue(`${prefix}email`, value)}
            name="email"
            value={values.email}
            placeholder="john.doe@thetreep.com"
            type="text"
            label={window.i18('EMAIL')}
            error={errors.email}
            touched={touched.email}
            required
          />
        </div>
        {withUsername && (
          <div className="col col-6">
            <Input
              onChange={(value) => setFieldValue(`${prefix}username`, value)}
              name="username"
              value={values.username}
              placeholder="john.doe"
              type="text"
              label={window.i18('USERNAME')}
              error={errors.username}
              touched={touched.username}
              required
            />
          </div>
        )}
      </div>
      {withAddress && (
        <div className="row m-bot relative">
          <Address
            name="address"
            value={values.address && values.address.description}
            placeholder="1 rue de Rivoli, Paris"
            label={window.i18('ADDRESS')}
            onClick={(value) => setFieldValue(`${prefix}address`, value)}
            error={errors.address}
            touched={touched.address}
            required
          />
        </div>
      )}
      {withCity && (
        <div className="row m-bot">
          <Input
            onChange={(value) => setFieldValue(`${prefix}city_of_birth`, value)}
            name="city_of_birth"
            value={values.city_of_birth}
            placeholder="Nice"
            type="text"
            label={window.i18('BIRTH_CITY')}
            error={errors.city_of_birth}
            touched={touched.city_of_birth}
            required
          />
        </div>
      )}
      {withCountry && (
        <div className="row m-bot">
          <Select
            name="birth_country"
            value={values.birth_country}
            placeholder="France"
            label={window.i18('BIRTH_COUNTRY')}
            onChange={(value) => setFieldValue(`${prefix}birth_country`, value)}
            error={errors.birth_country}
            touched={touched.birth_country}
            options={options}
            required
          />
        </div>
      )}
      {withHandicap && (
        <>
          <div className="row m-top">
            <Checkbox
              small
              name="hasHandicap"
              checked={hasHandicap}
              value={window.i18('HAS_HANDICAP')}
              onChange={() => {
                setFieldValue(`${prefix}hasHandicap`, !hasHandicap);
                if (!values.hasHandicap) {
                  setFieldValue(`${prefix}handicap`, null);
                  setFieldValue(`${prefix}wheelchair`, null);
                  setFieldValue(`${prefix}wheelchair_dimensions`, null);
                  setFieldValue(`${prefix}wheelchair_weight`, null);
                  setFieldValue(`${prefix}wheelchair_wheel_thickness`, null);
                  setFieldValue(`${prefix}need_accompanying`, false);
                }
              }}
            />
          </div>
          {(hasHandicap) && (
            <>
              <div className="row">
                <div className="col col-4">
                  <Select
                    onChange={(value) => setFieldValue(`${prefix}handicap`, value)}
                    name="handicap"
                    value={values.handicap}
                    placeholder={window.i18('FILL_YOUR_HANDICAP')}
                    type="text"
                    label={window.i18('HANDICAP')}
                    error={errors.handicap}
                    touched={touched.handicap}
                    options={(handicaps && handicaps.types && handicaps.types.map((item) => ({ value: item.name, name: item.label }))) || []}
                    nullable
                  />
                </div>
                <div className="col col-8">
                  <Select
                    onChange={(value) => setFieldValue(`${prefix}wheelchair`, value)}
                    name="wheelchair"
                    value={values.wheelchair}
                    type="text"
                    label={window.i18('WHEELCHAIR')}
                    error={errors.wheelchair}
                    touched={touched.wheelchairs}
                    options={(handicaps && handicaps.wheelchairs && handicaps.wheelchairs.map((item) => ({ value: item.name, name: item.label }))) || []}
                    nullable
                  />
                </div>
              </div>
              {values.wheelchair && (
                <>
                  <div className="row">
                    <div className="col col-12">
                      <Input
                        onChange={(value) => setFieldValue(`${prefix}wheelchair_dimensions`, value)}
                        name="wheelchair_dimensions"
                        value={values.wheelchair_dimensions}
                        placeholder="20 x 10 x 15"
                        type="text"
                        label={window.i18('WHEELCHAIR_DIMENSIONS')}
                        error={errors.wheelchair_dimensions}
                        touched={touched.wheelchair_dimensions}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-6">
                      <Input
                        onChange={(value) => setFieldValue(`${prefix}wheelchair_weight`, value)}
                        name="wheelchair_weight"
                        value={values.wheelchair_weight}
                        placeholder="20"
                        type="text"
                        label={window.i18('WHEELCHAIR_WEIGHT')}
                        error={errors.wheelchair_weight}
                        touched={touched.wheelchair_weight}
                      />
                    </div>
                    <div className="col col-6">
                      <Input
                        onChange={(value) => setFieldValue(`${prefix}wheelchair_wheel_thickness`, value)}
                        name="wheelchair_wheel_thickness"
                        value={values.wheelchair_wheel_thickness}
                        placeholder="20"
                        type="text"
                        label={window.i18('WHEELS_WIDTH')}
                        error={errors.wheelchair_wheel_thickness}
                        touched={touched.wheelchair_wheel_thickness}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="row m-top">
                <Checkbox
                  small
                  name="need_accompanying"
                  checked={values.need_accompanying}
                  value={window.i18('INVALIDITY_CARD')}
                  onChange={() => setFieldValue(`${prefix}need_accompanying`, !values.need_accompanying)}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default UserForm;
