import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import InvertFromTo from '../../components/InvertFromTo';
import { formatTime, getCarRentalDaysDiff } from '../../utils/functions';

const displayCarTypes = (carTypes) => {
  const types = carTypes.map(
    (carType) => window.i18(carType) || window.i18('UNKNOWN'),
  );
  let text = '';
  if (carTypes.includes('TP') || carTypes.includes('TM')
    || carTypes.includes('TG')) {
    text = `${window.i18('CAR')} (`;
  } else if (carTypes.includes('UP') || carTypes.includes('UM')
    || carTypes.includes('UG')) {
    text = `${window.i18('COMMERCIAL_VEHICLE')} (`;
  }
  text += types.join(', ');
  text += ')';
  if (carTypes.length) {
    return text;
  }
  return window.i18('VEHICLE_TYPE');
};

const carRentalSearchForm = ({
  search: {
    date, drivers, errors, touched, carTypes,
  },
  setSelected,
  selected,
  pickUpLocation,
  returnLocation,
  changeSearchLocation,
  handleSearchBlur,
  searchCarRentals,
  invertLocations,
  setEntity,
  entities,
  entityID,
}) => {
  const selectedDriver = drivers && drivers.find((d) => d.selected);
  const driverName = selectedDriver ? selectedDriver.firstname
    || window.i18('DRIVER') : `0 ${window.i18('DRIVER')}`;
  return (
    <div className="search-form__inputs hotel-search-form">
      <div
        className={classNames(
          'search-form__inputs__group',
          {
            selected: selected === 'pickUpLocation' || selected
              === 'returnLocation',
          },
          {
            errors: errors
              && ((errors['pickUpLocation.ID'] && touched.pickUpLocation)
                || (errors['returnLocation.ID'] && touched.returnLocation)),
          },
        )}
      >
        {errors && errors['pickUpLocation.ID'] && touched.pickUpLocation && (
          <span
            className="search-form__error top"
          >
            {errors['pickUpLocation.ID']}
          </span>
        )}
        <input
          className={`search-form__inputs__group__input ${
            selected === 'pickUpLocation' ? 'selected' : ''
          } ${
            errors && errors['pickUpLocation.ID'] && touched.pickUpLocation
              ? 'input-errors'
              : ''
          }`}
          type="text"
          placeholder={window.i18('I_PICK_THE_VEHICLE_AT')}
          onFocus={() => setSelected('pickUpLocation')}
          onChange={(e) => changeSearchLocation(e.target.value,
            'pickUpLocation')}
          value={pickUpLocation.value}
          onBlur={() => handleSearchBlur('pickUpLocation')}
        />
        {pickUpLocation.searching && <span className="loading from" />}
        <div id="pickUpLocation-portal" />
        <input
          className={`search-form__inputs__group__input ${
            selected === 'returnLocation' ? 'selected' : ''
          } ${errors && errors['returnLocation.ID'] && touched.returnLocation
            ? 'input-errors'
            : ''}`}
          id="returnLocation"
          type="text"
          placeholder={window.i18('I_RETURN_VEHICLE_AT')}
          onFocus={() => setSelected('returnLocation')}
          onChange={(e) => changeSearchLocation(e.target.value,
            'returnLocation')}
          value={returnLocation.value}
          onBlur={() => handleSearchBlur('returnLocation')}
        />
        {returnLocation.searching && <span className="loading to" />}
        <div id="returnLocation-portal" />
        <InvertFromTo onClick={invertLocations} />
        {errors && errors['returnLocation.ID'] && touched.returnLocation && (
          <span
            className="search-form__error bot"
          >
            {errors['returnLocation.ID']}
          </span>
        )}
      </div>
      <div className="search-form__nights-container">
        <div
          className={`search-form__inputs__group ${
            selected === 'pickUpDate' || selected === 'returnDate'
              ? 'selected'
              : ''
          } ${errors && errors['date.a.date'] && touched.date ? 'errors' : ''}`}
        >
          {errors && errors['date.a.date'] && touched.date && (
            <span className="search-form__error top left">
              {errors['date.a.date']}
            </span>
          )}
          <button
            className={`search-form__inputs__group__input ${
              selected === 'pickUpDate' ? 'selected' : ''
            } ${
              errors && errors['date.a.date'] && touched.date
                ? 'input-errors'
                : ''
            }`}
            onFocus={() => setSelected('pickUpDate')}
            onClick={() => setSelected('pickUpDate')}
          >
            <p>
              {window.i18('START')}
              {' '}
              {date.a.date
                ? `${window.i18('ON')} ${moment(date.a.date)
                  .format('DD/MM/YYYY')}`
                : window.i18('OF_THE_LOCATION_ON')}
            </p>
            <p style={{ maxWidth: '70px' }}>
              {window.i18('AT')}
              {' '}
              {formatTime(date.a.time)}
            </p>
          </button>
          <div id="a-portal" />
          <button
            className={`search-form__inputs__group__input ${
              selected === 'returnDate' ? 'selected' : ''
            } ${
              errors && errors['date.r.date.time'] && touched.date
                ? 'input-errors'
                : ''
            }`}
            onFocus={() => setSelected('returnDate')}
            onClick={() => setSelected('returnDate')}
          >
            <p>
              {window.i18('END')}
              {' '}
              {date.r.date
                ? `${window.i18('THE')} ${moment(date.r.date)
                  .format('DD/MM/YYYY')}`
                : window.i18('OF_THE_LOCATION_ON')}
            </p>
            <p style={{ maxWidth: '70px' }}>
              {window.i18('AT')}
              {' '}
              {formatTime(date.r.time)}
            </p>
          </button>
          <div id="r-portal" />
          {errors && errors['date.r.date'] && touched.returnDate && (
            <span className="search-form__error bot">
              {errors['date.r.date']}
            </span>
          )}
        </div>
        <div className="search-form__nights">
          <p>
            {(date.r.date
              && date.a.date
              && getCarRentalDaysDiff(date))}
          </p>
          <p>
            {(date.r.date
              && date.a.date
              && getCarRentalDaysDiff(date) > 1)
              ? window.i18('DAYS')
              : window.i18('DAY')}
          </p>
        </div>
      </div>
      <button
        className={`search-form__inputs__single ${
          selected === 'carTypes' ? 'selected' : ''
        } ${errors && errors.carTypes ? 'errors' : ''}`}
        onFocus={() => setSelected('carTypes')}
        onClick={() => setSelected('carTypes')}
      >
        <p>
          {displayCarTypes(carTypes)}
        </p>
        {errors && errors.carTypes && (
          <span className="search-form__error bot">{errors.carTypes}</span>
        )}
      </button>
      <div id="carTypes-portal" />
      <button
        className={`search-form__inputs__single ${
          selected === 'driver' ? 'selected' : ''
        } ${errors && errors.drivers ? 'errors' : ''}`}
        onFocus={() => setSelected('driver')}
        onClick={() => setSelected('driver')}
      >
        <p>
          {driverName}
        </p>
        {errors && errors.driver && (
          <span className="search-form__error bot">{errors.driver}</span>
        )}
      </button>
      <div id="driver-portal" />
      <div className="search-form__last-line">
        {entities && entities.length > 0 && (
          <>
            <select
              name="entityID"
              placeholder={window.i18('ON_THE_ACCOUNT_OF')}
              className={`search-form__inputs__single search-form__entity-select ${
                selected === 'entityID' ? 'selected' : ''
              } ${errors && errors.entityID && touched.entityID
                ? 'errors'
                : ''}`}
              onChange={(e) => setEntity(e.target.value)}
              required
              value={entityID || ''}
            >
              <option value="" disabled hidden>
                {window.i18(
                  'ON_THE_ACCOUNT_OF',
                )}
              </option>
              {entities
              && entities.map((entity) => (
                <option key={entity.uid} value={entity.uid}>
                  {entity.name}
                </option>
              ))}
            </select>
            {errors && touched.entityID && errors.entityID && (
              <span className="search-form__error bot">{errors.entityID}</span>
            )}
          </>
        )}
        <button
          className="search-form__inputs__submit button-main"
          onClick={searchCarRentals}
        >
          {window.i18('SEARCH_BTN')}
        </button>
      </div>
    </div>
  );
};

export default carRentalSearchForm;
