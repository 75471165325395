export const SET_PLACE_LOCATION = 'hotelSearch/SET_PLACE_LOCATION';
export const SET_PLACE_RADIUS = 'hotelSearch/SET_PLACE_RADIUS';
export const SET_PLACE_TOUCHED = 'hotelSearch/SET_PLACE_TOUCHED';
export const ADD_PASSENGER = 'hotelSearch/ADD_PASSENGER';
export const EDIT_PASSENGER = 'hotelSearch/EDIT_PASSENGER';
export const REMOVE_PASSENGER = 'hotelSearch/REMOVE_PASSENGER';
export const SET_ARRIVAL_TOUCHED = 'hotelSearch/SET_ARRIVAL_TOUCHED';
export const SET_DEPARTURE_TOUCHED = 'hotelSearch/SET_DEPARTURE_TOUCHED';
export const SET_ARRIVAL_DATE = 'hotelSearch/SET_ARRIVAL_DATE';
export const SET_DEPARTURE_DATE = 'hotelSearch/SET_DEPARTURE_DATE';
export const SET_FORM_ERRORS = 'hotelSearch/SET_FORM_ERRORS';
export const SEARCHING_HOTELS = 'hotelSearch/SEARCHING_HOTELS';
export const FILL_USER_INFO = 'hotelSearch/FILL_USER_INFO';
export const TOGGLE_SELECTED_PASSENGER = 'hotelSearch/TOGGLE_SELECTED_PASSENGER';
export const ADD_ROOM = 'hotelSearch/ADD_ROOM';
export const REMOVE_ROOM = 'hotelSearch/REMOVE_ROOM';
export const SET_NAME_CONTAINS = 'hotelSearch/SET_NAME_CONTAINS';
export const ADD_LAST_TRAVELERS = 'hotelSearch/ADD_LAST_TRAVELERS';
export const SET_ENTITY_TOUCHED = 'hotelSearch/SET_ENTITY_TOUCHED';
export const SET_SEARCH_PARAMS = 'hotelSearch/SET_SEARCH_PARAMS';
