/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// Components
import Hotels from './Hotels';
import HotelsMobile from './HotelsMobile';
import HotelDetails from './HotelDetails';
import PricesMap from '../../../components/PricesMap';
// Functions
import { filterResults, sortResults } from '../functions';

// Actions
import {
  setSelectedResult,
  addSelectedOffer,
  removeSelectedOffer,
  toggleTravelersDistribution,
  fillTraveler,
  addToCart,
  assignTravelerToOffer,
  setSelectedMarker,
} from '../actions';
import { openModal } from '../../ModalWrapper/actions';

const propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedResult: PropTypes.shape({}).isRequired,
  search: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({}).isRequired,
  setSelectedResult: PropTypes.func.isRequired,
  addSelectedOffer: PropTypes.func.isRequired,
  removeSelectedOffer: PropTypes.func.isRequired,
  toggleTravelersDistribution: PropTypes.func.isRequired,
  travelers: PropTypes.arrayOf((PropTypes.shape({}))).isRequired,
  addingToCart: PropTypes.bool.isRequired,
  assignTravelerToOffer: PropTypes.func.isRequired,
};

function Results(props) {
  const {
    results,
    selectedResult,
    search,
    setSelectedResult,
    filters,
    addSelectedOffer,
    removeSelectedOffer,
    toggleTravelersDistribution,
    openTravelerModal,
    travelers,
    fillTraveler,
    addToCart,
    addingToCart,
    assignTravelerToOffer,
    displayMap,
    selectedMarker,
    setSelectedMarker,
    sorting,
    user,
  } = props;
  const isMobileDevice = useSelector((state) => state.app.isMobileDevice);
  const selected = results.find((res) => res.id === selectedResult.id);
  const filteredResults = filterResults(filters, results);
  const sortedResults = sortResults(filteredResults, sorting);
  const markers = filteredResults.map((res) => ({
    id: res.id,
    lat: res.latitude,
    lon: res.longitude,
    price: res.room_offers?.[0]?.incl_taxes_amount,
  }));

  // If there are results but none corresponding to current filters,
  // we don't want to show the default selected result, and we explain
  // to the user that he can see some results by modifying filters
  if (results.length && !filteredResults.length) {
    return (
      <div className="hotel-results m-auto p-all">
        <p>
          {window.i18('NO_RESULTS')}
          {' '}
          (
          {results.length}
          {' '}
          {window.i18('IN_TOTAL')}
          ).
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="hotel-results m-auto m-top p-all">
        { !isMobileDevice ? (
          <Hotels
            results={sortedResults}
            setSelectedResult={setSelectedResult}
            selectedResult={selectedResult}
            selectedMarker={selectedMarker}
            displayMap={displayMap}
            filters={filters}
            isMobile={isMobileDevice}
          />
        ) : (selected ? (
          <HotelDetails
            selected={selected}
            selectedResult={selectedResult}
            search={search}
            addSelectedOffer={addSelectedOffer}
            removeSelectedOffer={removeSelectedOffer}
            toggleTravelersDistribution={toggleTravelersDistribution}
            openTravelerModal={openTravelerModal}
            travelers={travelers}
            fillTraveler={fillTraveler}
            addToCart={addToCart}
            addingToCart={addingToCart}
            assignTravelerToOffer={assignTravelerToOffer}
            filters={filters}
            setSelectedResult={setSelectedResult}
          />
        ) : (
          <HotelsMobile
            results={sortedResults}
            setSelectedResult={setSelectedResult}
            selectedResult={selectedResult}
            selectedMarker={selectedMarker}
            displayMap={displayMap}
            filters={filters}
            isMobile={isMobileDevice}
          />
        ))}

        {!displayMap && !isMobileDevice ? (
          <HotelDetails
            selected={selected}
            selectedResult={selectedResult}
            search={search}
            addSelectedOffer={addSelectedOffer}
            removeSelectedOffer={removeSelectedOffer}
            toggleTravelersDistribution={toggleTravelersDistribution}
            openTravelerModal={openTravelerModal}
            travelers={travelers}
            fillTraveler={fillTraveler}
            addToCart={addToCart}
            addingToCart={addingToCart}
            assignTravelerToOffer={assignTravelerToOffer}
            filters={filters}
            user={user}
          />
        ) : (!isMobileDevice
          && (
          <div className="hotel-details">
            <div className="bordered-block p-all">
              <PricesMap
                lat={search.place.location.lat}
                lon={search.place.location.lon}
                markers={markers}
                handleClick={setSelectedMarker}
                selectedMarker={selectedMarker}
                radius={search.place.radius}
              />
            </div>
          </div>
          )
        )}
      </div>
    </>
  );
}

Results.propTypes = propTypes;

const mapStateToProps = (state) => ({
  results: state.hotelResults.results,
  selectedResult: state.hotelResults.selectedResult,
  search: state.hotelResults.search,
  filters: state.hotelResults.filters,
  travelers: state.hotelResults.travelers,
  addingToCart: state.hotelResults.addingToCart,
  displayMap: state.hotelResults.displayMap,
  selectedMarker: state.hotelResults.selectedMarker,
  sorting: state.hotelResults.sorting,
  user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedResult: (id) => dispatch(setSelectedResult(id)),
  addSelectedOffer: (id) => dispatch(addSelectedOffer(id)),
  removeSelectedOffer: (id) => dispatch(removeSelectedOffer(id)),
  toggleTravelersDistribution: () => dispatch(toggleTravelersDistribution()),
  openTravelerModal: (options) => dispatch(openModal('HotelTraveler', options)),
  fillTraveler: (traveler) => dispatch(fillTraveler(traveler)),
  addToCart: (hotelId, offerId, travelers) => dispatch(addToCart(hotelId, offerId, travelers)),
  assignTravelerToOffer: (offerId, traveler, index) => dispatch(assignTravelerToOffer(offerId, traveler, index)),
  setSelectedMarker: (id) => dispatch(setSelectedMarker(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);
