import React from 'react';
import moment from 'moment';

import { formatTime } from '../../utils/functions';
import InvertFromTo from '../../components/InvertFromTo';

const tripSearchForm = ({
  search: {
    date, isRT, passengers, errors, touched,
  },
  setSelected,
  selected,
  invertFromToLocation,
  from,
  to,
  changeSearchLocation,
  handleSearchBlur,
  searchNewTrip,
  totalCards,
  specialFares,
  setSpecialFare,
  setEntity,
  entities,
  entityID,
}) => (
  <div className="search-form__inputs">
    <div
      className={`search-form__inputs__group ${
        selected === 'from' || selected === 'to' ? 'selected' : ''
      } ${
        errors
        && ((errors['from.ID'] && touched.from)
          || (errors['to.ID'] && touched.to))
          ? 'errors'
          : ''
      }`}
    >
      {errors && errors['from.ID'] && touched.from && (
        <span className="search-form__error top">{errors['from.ID']}</span>
      )}
      <input
        data-testid="search-from"
        className={`search-form__inputs__group__input ${
          selected === 'from' ? 'selected' : ''
        } ${
          errors && errors['from.ID'] && touched.from ? 'input-errors' : ''
        }`}
        type="text"
        placeholder={window.i18('I_LEAVE_FROM')}
        onFocus={() => setSelected('from')}
        onChange={(e) => changeSearchLocation(e.target.value, 'from')}
        value={from.value}
        onBlur={() => handleSearchBlur('from')}
      />
      <div id="from-portal" />
      {from.searching && <span className="loading from" />}
      <input
        className={`search-form__inputs__group__input ${
          selected === 'to' ? 'selected' : ''
        } ${errors && errors['to.ID'] && touched.to ? 'input-errors' : ''}`}
        id="to"
        type="text"
        placeholder={window.i18('I_GO_TO')}
        onFocus={() => setSelected('to')}
        onChange={(e) => changeSearchLocation(e.target.value, 'to')}
        value={to.value}
        onBlur={() => handleSearchBlur('to')}
      />
      <div id="to-portal" />
      {to.searching && <span className="loading to" />}
      <InvertFromTo onClick={invertFromToLocation} />
      {errors && errors['to.ID'] && touched.to && (
        <span className="search-form__error bot">{errors['to.ID']}</span>
      )}
    </div>
    <div
      className={`search-form__inputs__group ${
        selected === 'out' || selected === 'return' ? 'selected' : ''
      } ${
        errors
        && (errors['date.a.date']
          || errors['date.a.time']
          || errors['date.r.time'])
        && touched.date
          ? 'errors'
          : ''
      }`}
    >
      {errors && errors['date.a.date'] && touched.date && (
        <span className="search-form__error top left">
          {errors['date.a.date']}
        </span>
      )}
      {errors && errors['date.a.time'] && touched.date && (
        <span className="search-form__error top right">
          {errors['date.a.time']}
        </span>
      )}
      <button
        className={`search-form__inputs__group__input ${
          selected === 'out' ? 'selected' : ''
        } ${
          errors
          && (errors['date.a.date'] || errors['date.a.time'])
          && touched.date
            ? 'input-errors'
            : ''
        }`}
        onFocus={() => setSelected('out')}
        onClick={() => setSelected('out')}
      >
        <p>
          {window.i18('DEPARTURE_ON')}
          {' '}
          {date.a.date ? moment(date.a.date).format('DD/MM/YYYY') : '...'}
        </p>
        <p>
          {date.a.mode === 'departure' ? window.i18('LEAVE_AFTER') : window.i18(
            'ARRIVE_BEFORE',
          )}
          {' '}
          {formatTime(date.a.time)}
        </p>
      </button>
      <div id="a-portal" />
      <button
        className={`search-form__inputs__group__input ${
          selected === 'return' ? 'selected' : ''
        } ${
          errors && errors['date.r.time'] && touched.date
            ? 'input-errors'
            : ''
        }`}
        onFocus={() => setSelected('return')}
        onClick={() => setSelected('return')}
      >
        <p>
          {window.i18('RETURN_ON')}
          {' '}
          {date.r.date ? moment(date.r.date).format('DD/MM/YYYY') : '...'}
        </p>
        {isRT && (
          <p>
            {date.r.mode === 'departure'
              ? window.i18('LEAVE_AFTER')
              : window.i18('ARRIVE_BEFORE')}
            {' '}
            {formatTime(date.r.time)}
          </p>
        )}
      </button>
      <div id="r-portal" />
      {errors && errors['date.r.time'] && touched.date && (
        <span className="search-form__error bot">
          {errors['date.r.time']}
        </span>
      )}
    </div>
    <button
      className={`search-form__inputs__single ${
        selected === 'passengers' ? 'selected' : ''
      } ${errors && errors.passengers && touched.passengers ? 'errors' : ''}`}
      onFocus={() => setSelected('passengers')}
      onClick={() => setSelected('passengers')}
    >
      <p>
        {(passengers.filter((p) => p.selected).length === 1
          && passengers.filter((p) => p.selected)[0].firstname
          ? passengers.filter((p) => p.selected)[0].firstname
          : `${passengers.filter(
            (p) => p.selected,
          ).length} ${passengers.filter(
            (p) => p.selected,
          ).length > 1
            ? window.i18('PASSENGERS')
            : window.i18('PASSENGER')}`
        )}
      </p>
      <p>
        {totalCards
          ? `${totalCards} ${totalCards > 1 ? window.i18('CARDS') : window.i18(
            'CARD',
          )}`
          : window.i18('NO_CARDS')}
      </p>
      {errors && errors.passengers && touched.passengers && (
        <span className="search-form__error bot">{errors.passengers}</span>
      )}
    </button>
    <div id="passengers-portal" />
    {specialFares && specialFares.length > 0 && (
      <select
        className="search-form__inputs__single"
        onChange={(e) => setSpecialFare(e.target.value)}
      >
        <option selected="selected">{window.i18('NO_SPECIAL_FARES')}</option>
        {specialFares && specialFares.length > 0
        && specialFares.map((fare) => (
          <option key={fare.code} value={fare.code}>
            {fare.label}
          </option>
        ))}
      </select>
    )}
    <div className="search-form__last-line">
      {entities && entities.length > 0 && (
        <>
          <select
            name="entityID"
            placeholder="Pour le compte de ..."
            className={`search-form__inputs__single search-form__entity-select ${
              selected === 'entityID' ? 'selected' : ''
            } ${errors && errors.entityID && touched.entityID ? 'errors' : ''}`}
            onChange={(e) => setEntity(e.target.value)}
            required
            value={entityID || ''}
          >
            <option value="" disabled hidden>
              {window.i18(
                'ON_THE_ACCOUNT_OF',
              )}
            </option>
            {entities
            && entities.map((entity) => (
              <option key={entity.uid} value={entity.uid}>
                {entity.name}
              </option>
            ))}
          </select>
          {errors && touched.entityID && errors.entityID && (
            <span className="search-form__error bot">{errors.entityID}</span>
          )}
        </>
      )}
      <button
        className="search-form__inputs__submit button-main"
        onClick={searchNewTrip}
      >
        {window.i18('SEARCH_BTN')}
      </button>
    </div>
  </div>
);

export default tripSearchForm;
