import React, { Component } from 'react';
import { connect } from 'react-redux';
// Components
import Filter from './Filter';
import TicketItem from '../../../components/TicketItem';
import Loader from '../../../components/SkeletonLoader/UserTicketsLoader';
// functions
import { formatDateFromAPI } from '../../../utils/converters';
import { isMobileDevice, isCNAM } from '../../../utils/functions';

import {
  promptCancelTickets,
  getCdsUrl,
  getUserTickets,
  getUserArchives,
  toggleCheckAllPending,
  toggleCheckAllTickets,
  toggleCheckPending,
  toggleCheckTicket,
  resetCheckedTickets,
  resetCheckedPending,
  isChecked,
  promptAnotherApprover,
  promptAskAgainApprover,
  promptCancelPending,
} from '../actions';
import { openModal } from '../../ModalWrapper/actions';
import MobileActions from './MobileActions';

const findCommonElements = (arr1, arr2) => arr1.some((item) => arr2.includes(item));

class UserTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        name: '',
        place: '',
        date: '',
        status: '',
      },
      filter: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (this.props.archived) {
      this.props.getUserArchives();
    } else {
      this.props.getUserTickets();
    }
  }

  setFilterKeyword = (keyword, type) => {
    this.setState({
      search: {
        ...this.state.search,
        [type]: keyword,
      },
    });
    this.props.resetCheckedPending();
    this.props.resetCheckedTickets();
  };

  toggleFilter = () => {
    this.setState({
      filter: !this.state.filter,
    });
  };

  allChecked = (displayed, checked) => {
    const disabledStatuses = ['cancelled', 'error', 'denied'];
    const filteredTickets = displayed.filter((tk) => !disabledStatuses.includes(tk.status));
    if (!checked || checked.length === 0) {
      return false;
    }
    for (let i = 0; i < filteredTickets.length; i += 1) {
      if (!checked.includes(filteredTickets[i].uid)) {
        return false;
      }
    }
    return true;
  };

  renderTableHeader = (withCheckbox, isPending, allChecked, toggleCheckAll) => (
    <thead>
      <tr className="ref-info">
        <th>{window.i18('STATUS')}</th>
        <th>{window.i18('TYPE')}</th>
        <th>{window.i18('DATES')}</th>
        <th>{window.i18('PLACES')}</th>
        <th>{window.i18('SCHEDULES')}</th>
        <th>{window.i18('PASSENGER_S')}</th>
        <th>{window.i18('PRICE')}</th>
        <th>{window.i18('CONDITIONS')}</th>
        <th>{window.i18('PNR_FILE')}</th>
        <th>
          {window.i18('APPROVER')}
          {' '}
          <br />
          (
          {window.i18('FAILOVER')}
          )
        </th>
        {isPending ? (
          <th>{window.i18('DELAY')}</th>
        )
          : (
            <th>{window.i18('TICKETS')}</th>
          )}
      </tr>
    </thead>
  )

  render() {
    const {
      userTickets, userArchives, archived, user,
    } = this.props;
    const {
      pendingSelected, selected,
    } = userTickets;
    let {
      list, pendingList,
    } = userTickets;
    let archivedList = userArchives.list;
    const loading = archived ? userArchives.loading : userTickets.loading;
    const { search } = this.state;
    let tickets = [];
    if (archived) {
      tickets = userArchives.list;
    } else if (list) {
      if (pendingList) {
        tickets = list.concat(pendingList);
      } else {
        tickets = list;
      }
    } else if (pendingList) {
      tickets = pendingList;
    }
    // remove 'cart' tickets
    const filter = (item) => item.status !== 'cart';
    list = list && list.filter(filter);
    pendingList = pendingList && pendingList.filter(filter);
    archivedList = archivedList && archivedList.filter(filter);

    const ticketsByTravelID = {};
    list.forEach((ticket) => {
      if (ticketsByTravelID[ticket.travelID]) {
        ticketsByTravelID[ticket.travelID].push(ticket.uid);
      } else {
        ticketsByTravelID[ticket.travelID] = [ticket.uid];
      }
    });

    const ticketsByApprovalID = {};
    pendingList.forEach((ticket) => {
      if (ticketsByApprovalID[ticket.approvalID]) {
        ticketsByApprovalID[ticket.approvalID].push(ticket.uid);
      } else {
        ticketsByApprovalID[ticket.approvalID] = [ticket.uid];
      }
    });

    const pendingSelectedTravels = [...new Set(pendingSelected.map((id) => pendingList.find((ticket) => ticket.uid === id)?.approvalID))];

    const archivedTicketsByTravelID = {};
    archivedList.forEach((ticket) => {
      if (archivedTicketsByTravelID[ticket.travelID]) {
        archivedTicketsByTravelID[ticket.travelID].push(ticket.uid);
      } else {
        archivedTicketsByTravelID[ticket.travelID] = [ticket.uid];
      }
    });

    const status = tickets && tickets.map((ticket) => ticket.status);
    const uniqueStatus = status && status.filter((status, i, s) => s.indexOf(status) === i);
    if (search.name) {
      const filter = (item) => {
        if (!item.travelers) {
          return false;
        }
        for (let i = 0; i < item.travelers.length; i += 1) {
          const traveler = item.travelers[i];
          const name = `${traveler.firstname.toLowerCase()} ${traveler.lastname.toLowerCase()}`;
          if (name.includes(search.name.toLowerCase())) {
            return true;
          }
        }
        return false;
      };
      list = list && list.filter(filter);
      pendingList = pendingList && pendingList.filter(filter);
      archivedList = archivedList && archivedList.filter(filter);
    }
    if (search.place) {
      const filter = (item) => {
        const places = `${item.from && item.from.toLowerCase()} ${item.to && item.to.toLowerCase()}`;
        return places.includes(search.place.toLowerCase());
      };
      list = list && list.filter(filter);
      pendingList = pendingList && pendingList.filter(filter);
      archivedList = archivedList && archivedList.filter(filter);
    }
    if (search.status) {
      const filter = (item) => item.status === search.status;
      list = list && list.filter(filter);
      pendingList = pendingList && pendingList.filter(filter);
      archivedList = archivedList && archivedList.filter(filter);
    }
    if (search.date) {
      const filter = (item) => {
        const departure = formatDateFromAPI(item.departure).slice(0, 10);
        const arrival = formatDateFromAPI(item.arrival).slice(0, 10);
        return departure === search.date || arrival === search.date;
      };
      list = list && list.filter(filter);
      pendingList = pendingList && pendingList.filter(filter);
      archivedList = archivedList && archivedList.filter(filter);
    }

    const hasFailover = user.company.config?.approvalType !== 'cascade' && user.company.config?.approvalType !== 'escalation';

    return loading || this.props.userTickets.cancelling
      ? <Loader archived={archived} />
      : (
        <div className="user-tickets">
          { (isMobileDevice() && ((pendingSelected && pendingSelected.length > 0) || (selected && selected.length > 0))) && (
            <MobileActions pendingSelected={pendingSelected} selected={selected} isCNAM={isCNAM} promptAskAgainApprover={this.props.promptAskAgainApprover} promptAnotherApprover={this.props.promptAnotherApprover} promptCancelTickets={this.props.promptCancelTickets} promptCancelPending={this.props.promptCancelPending} pendingSelectedTravels={pendingSelectedTravels} />
          )}
          <Filter
            toggleFilter={this.toggleFilter}
            filter={this.state.filter || false}
            setFilterKeyword={this.setFilterKeyword}
            status={uniqueStatus}
          />
          {!archived
            ? (
              <>
                {pendingList && pendingList.length > 0 && (
                  <>
                    <div className="user-tickets__toolbar">
                      <h2>{window.i18('BOOKINGS_AWAITING_APPROVAL')}</h2>
                      {!isMobileDevice() && pendingSelected && pendingSelected.length > 0 && (
                      <div className="user-tickets-actions">
                        {pendingSelectedTravels.length === 1 && <a className="user-tickets-actions__cancel button-main" onClick={this.props.promptAskAgainApprover}>{window.i18('REMIND_APPROVER')}</a>}
                        {pendingSelectedTravels.length === 1 && hasFailover && <a className="button-secondary" onClick={this.props.promptAnotherApprover}>{window.i18('ASK_ANOTHER_APPROVER')}</a>}
                        <a className="button-secondary" onClick={this.props.promptCancelPending}>{window.i18('DELETE')}</a>
                      </div>
                      )}
                    </div>
                    <table className="tickets-table pending-approval-table" cellSpacing="0" cellPadding="0">
                      {this.renderTableHeader(true, true, this.allChecked(pendingList, pendingSelected), () => this.props.toggleCheckAllPending(pendingList))}
                      <tbody>
                        {Object.keys(ticketsByApprovalID).map((id, i) => {
                          if (findCommonElements(ticketsByApprovalID[id], pendingList.map((ticket) => ticket.uid))) {
                            return (
                              <tr>
                                <td colSpan="11">
                                  <tr>
                                    <h3 className="user-tickets__travel">
                                      <label className="common-checkbox medium-checkbox col-sm-2">
                                        <input
                                          type="checkbox"
                                          checked={isChecked(ticketsByApprovalID[id][0], pendingSelected)}
                                          onChange={() => ticketsByApprovalID[id].forEach((uid) => this.props.toggleCheckPending(uid))}
                                        />
                                        <span className="checkmark" />
                                      </label>
                                      Voyage
                                      {' '}
                                      {i + 1}
                                    </h3>
                                  </tr>
                                  {ticketsByApprovalID[id].map((uid) => {
                                    const ticket = pendingList.find((item) => item.uid === uid);
                                    if (ticket) {
                                      return <TicketItem key={ticket.uid} ticket={ticket} isPending />;
                                    }
                                    return null;
                                  })}
                                </td>
                              </tr>
                            );
                          }
                          return null;
                        })}
                      </tbody>
                    </table>
                  </>
                )}
                {list && list.length > 0 && (
                  <>
                    <div className="user-tickets__toolbar">
                      <h2>Réservations</h2>
                      {!isMobileDevice() && selected && selected.length > 0 && !isCNAM() && (
                      <div className="user-tickets-actions">
                        <a className="user-tickets-actions__cancel button-main" onClick={this.props.promptCancelTickets}>
                          {window.i18('CANCEL')}
                          {' '}
                          {selected && selected.length > 1 ? `${window.i18('THE_PLURAL')} ${window.i18('TICKETS').toLowerCase()}` : `${window.i18('THE')} ${window.i18('TICKET').toLowerCase()}`}
                        </a>
                      </div>
                      )}
                    </div>
                    <table className="tickets-table" cellSpacing="0" cellPadding="0">
                      {this.renderTableHeader(true, false, this.allChecked(list, selected), () => this.props.toggleCheckAllTickets(list))}
                      <tbody>
                        {Object.keys(ticketsByTravelID).map((id, i) => {
                          if (findCommonElements(ticketsByTravelID[id], list.map((ticket) => ticket.uid))) {
                            return (
                              <tr>
                                <td colSpan="11">
                                  <tr style={{ display: 'table' }}>
                                    <h3 className="user-tickets__travel">
                                      <label className="common-checkbox medium-checkbox col-sm-2">
                                        <input
                                          type="checkbox"
                                          checked={isChecked(ticketsByTravelID[id][0], selected)}
                                          onChange={() => ticketsByTravelID[id].forEach((uid) => this.props.toggleCheckTicket(uid))}
                                        />
                                        <span className="checkmark" />
                                      </label>
                                      Voyage
                                      {' '}
                                      {i + 1 + Object.keys(ticketsByApprovalID).length}
                                    </h3>
                                  </tr>
                                  {ticketsByTravelID[id].map((uid) => {
                                    const ticket = list.find((item) => item.uid === uid);
                                    if (ticket) {
                                      return <TicketItem key={ticket.uid} ticket={ticket} />;
                                    }
                                    return null;
                                  })}
                                </td>
                              </tr>
                            );
                          }
                          return null;
                        })}
                      </tbody>
                    </table>
                  </>
                )}
                {list && list.length === 0 && pendingList && pendingList.length === 0 && (
                  <p>{window.i18('NO_RECENT_TICKETS')}</p>
                )}
              </>
            )
            : (
              <>
                {archivedList && archivedList.length > 0 ? (
                  <>
                    <h2>{window.i18('ARCHIVED_BOOKINGS')}</h2>
                    <table className="tickets-table pending-approval-table" cellSpacing="0" cellPadding="0">
                      {this.renderTableHeader(true, true, this.allChecked(pendingList, pendingSelected), () => this.props.toggleCheckAllPending(pendingList))}
                      <tbody>
                        {Object.keys(archivedTicketsByTravelID).map((id, i) => {
                          if (findCommonElements(archivedTicketsByTravelID[id], archivedList.map((ticket) => ticket.uid))) {
                            return (
                              <tr>
                                <td colSpan="11">
                                  <tr style={{ display: 'table' }}>
                                    <h3 className="user-tickets__travel">
                                      Voyage
                                      {' '}
                                      {i + 1}
                                    </h3>
                                  </tr>
                                  {archivedTicketsByTravelID[id].map((uid) => {
                                    const ticket = archivedList.find((item) => item.uid === uid);
                                    if (ticket) {
                                      return <TicketItem key={ticket.uid} ticket={ticket} />;
                                    }
                                    return null;
                                  })}
                                </td>
                              </tr>
                            );
                          }
                          return null;
                        })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <p>{window.i18('NO_ARCHIVE')}</p>
                )}
              </>
            )}
        </div>
      );
  }
}

const mapStateToProps = (state) => ({
  userTickets: state.account.userTickets,
  userArchives: state.account.userArchives,
  user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
  getUserTickets: () => dispatch(getUserTickets()),
  getUserArchives: () => dispatch(getUserArchives()),
  openModal: (name, options) => dispatch(openModal(name, options)),
  promptCancelTickets: () => dispatch(promptCancelTickets()),
  promptAnotherApprover: () => dispatch(promptAnotherApprover()),
  promptAskAgainApprover: () => dispatch(promptAskAgainApprover()),
  promptCancelPending: () => dispatch(promptCancelPending()),
  getCdsUrl: (treepId) => dispatch(getCdsUrl(treepId)),
  toggleCheckAllPending: (tickets) => dispatch(toggleCheckAllPending(tickets)),
  toggleCheckAllTickets: (tickets) => dispatch(toggleCheckAllTickets(tickets)),
  toggleCheckPending: (id) => dispatch(toggleCheckPending(id)),
  toggleCheckTicket: (id) => dispatch(toggleCheckTicket(id)),
  resetCheckedTickets: () => dispatch(resetCheckedTickets()),
  resetCheckedPending: () => dispatch(resetCheckedPending()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserTickets);
