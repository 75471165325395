import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
// Components
import Input from '../../components/Inputs/Material';
import Radio from '../../components/Radio';
import Checkbox from '../../components/Checkbox';

const FirstPage = ({ initialValues, setValues }) => (
  <>
    <h1>
      {window.i18('WELCOME_ABOARD')}
      {' '}
      !
    </h1>
    <p>{window.i18('CONGRATULATIONS_FIRST_LOGIN')}</p>
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        firstname: yup.string().required(window.i18('PLEASE_FILL')),
        lastname: yup.string().required(window.i18('PLEASE_FILL')),
        email: yup.string().email().required(window.i18('PLEASE_FILL')),
        username: yup.string().required(window.i18('PLEASE_FILL')),
        password: yup
          .string()
          .min(8, 'Au moins 8 caractères requis')
          .matches(/^(?=.*[A-Z]).*$/, 'Au moins une majuscule requise')
          .matches(/^(?=.*[0-9]).*$/, 'Au moins un chiffre requis')
          .matches(/^(?=.*[a-z]).*$/, 'Au moins une minuscule requise')
          .required('Ce champ est requis.'),
        stock: yup.string().required(window.i18('PLEASE_FILL')),
        data_accuracy: yup.bool().oneOf([true], window.i18('PLEASE_CHECK')),
      })}
      onSubmit={(values) => {
        setValues(values);
      }}
    >
      {({
        values, touched, errors, setFieldValue,
      }) => (
        <Form>
          <div className="row">
            <div className="col col-6">
              <Input
                onChange={(value) => setFieldValue('firstname', value)}
                name="firstname"
                value={values.firstname}
                placeholder="Jon"
                type="text"
                label={`${window.i18('FIRSTNAME')} *`}
                error={errors.firstname}
                touched={touched.firstname}
              />
            </div>
            <div className="col col-6">
              <Input
                onChange={(value) => setFieldValue('lastname', value)}
                name="lastname"
                value={values.lastname}
                placeholder="Snow"
                type="text"
                label={`${window.i18('LASTNAME')} *`}
                error={errors.lastname}
                touched={touched.lastname}
              />
            </div>
          </div>
          <div className="row m-top">
            <div className="col col-6">
              <Input
                onChange={(value) => setFieldValue('username', value)}
                name="username"
                value={values.username}
                placeholder="dimi.tree"
                type="text"
                label={`${window.i18('USERNAME')} *`}
                error={errors.username}
                touched={touched.username}
              />
            </div>
            <div className="col col-6">
              <Input
                onChange={(value) => setFieldValue('password', value)}
                name="password"
                value={values.password}
                placeholder="••••••••••"
                type="password"
                label={`${window.i18('PASSWORD')} *`}
                error={errors.password}
                touched={touched.password}
              />
            </div>
          </div>

          <div className="row m-top">
            <div className="col col-12">
              <Input
                onChange={(value) => setFieldValue('email', value)}
                name="email"
                value={values.email}
                placeholder="dimitree@thetreep.com"
                type="text"
                label={`${window.i18('EMAIL')} *`}
                error={errors.email}
                touched={touched.email}
              />
            </div>
          </div>
          <p>J’accepte que the Treep stocke plus d’informations personnelles comme ma date de naissance, mon téléphone, mes cartes voyageur… et m’évite de les ressaisir à chaque voyage :</p>
          <div className="row m-top">
            <div className="m-right">
              <Field
                component={Radio}
                name="stock"
                id="accept"
                label={window.i18('YES')}
              />
            </div>
            <div>
              <Field
                component={Radio}
                name="stock"
                id="refuse"
                label={window.i18('NO_PREFER_TYPING_INFO')}
              />
            </div>
          </div>
          {errors.stock && (
            <div className="row">
              <p className="material-input__error">{errors.stock}</p>
            </div>
          )}
          <div className="row p-top m-top">
            <Checkbox
              name="data_accuracy"
              checked={values.data_accuracy}
              onChange={() => setFieldValue('data_accuracy', !values.data_accuracy)}
              value={window.i18('CHECK_CONFIRM_MY_DATA')}
            />
          </div>
          {errors.data_accuracy && (
            <div className="row">
              <p className="material-input__error">{errors.data_accuracy}</p>
            </div>
          )}
          <div className="row justify-center p-top m-top">
            <button type="submit" className="button-main" disabled={!values.data_accuracy}>
              {window.i18('CONFIRM')}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </>
);

export default FirstPage;
