import React from 'react';
import { connect } from 'react-redux';
import UserForm from '../../../components/UserForm';

import { submitUser } from '../../App/actions';
import { promptDeleteAccount, promptDeleteInfos } from '../actions';

const UserInformations = (props) => {
  if (!props.user) { return <span>Loading</span>; }

  return (
    <div className="common-wrapper">
      {props.isMobileDevice && (<h2>{window.i18('MY_DATA')}</h2>)}
      <UserForm user={props.user} handicaps={props.handicaps} handleSubmit={props.handleSubmit} buttonLabel={window.i18('UPDATE')} />
      <div className="user-form user-form__delete">
        <div className="row">
          <button className="button-secondary" onClick={props.promptDeleteInfos}>{window.i18('DELELTE_OPTIONAL_DATA')}</button>
        </div>
        <div className="row m-top">
          <button className="button-secondary" onClick={props.promptDeleteAccount}>{window.i18('DELETE_ACCOUNT')}</button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.app.user,
  handicaps: state.app.handicaps,
});

const mapDispatchToProps = (dispatch) => ({
  handleSubmit: (user) => dispatch(submitUser(user)),
  promptDeleteAccount: () => dispatch(promptDeleteAccount()),
  promptDeleteInfos: () => dispatch(promptDeleteInfos()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInformations);
