import { combineReducers } from 'redux';
import { SET_APPROVAL_ERROR, SET_APPROVAL_LOADING, SET_APPROVAL_RESPONSE } from './constants';

function errorReducer(state = null, action) {
  switch (action.type) {
    case SET_APPROVAL_ERROR:
      return action.error;
    default:
      return state;
  }
}

function responseReducer(state = null, action) {
  switch (action.type) {
    case SET_APPROVAL_RESPONSE:
      return action.response;
    default:
      return state;
  }
}

function isLoadingReducer(state = true, action) {
  switch (action.type) {
    case SET_APPROVAL_ERROR:
    case SET_APPROVAL_RESPONSE:
      return false;
    case SET_APPROVAL_LOADING:
      return true;
    default:
      return state;
  }
}

const approvalReducer = combineReducers({
  error: errorReducer,
  isLoading: isLoadingReducer,
  response: responseReducer,
});

export default approvalReducer;
