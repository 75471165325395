/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import CarRentals from './CarRentals';
import CarRentalsMobile from './CarRentalsMobile';
import SelectedResult from './SelectedResult';
import PricesMap from '../../../components/PricesMap';
// Actions
import {
  setSelectedResult, editDriver, addToCart, setSelectedMarker,
} from '../actions';
import { openModal } from '../../ModalWrapper/actions';
// Functions
import { driverValidationSchema, filterResults, sortResults } from '../functions';
import { isMobileDevice } from '../../../utils/functions';

const propTypes = {
  results: PropTypes.shape({}).isRequired,
  selectedResult: PropTypes.shape({}).isRequired,
};

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      results, setSelectedResult, selectedResult, driver, openModal, editDriver, addToCart, addingToCart, filters, sorting, displayMap, search, selectedMarker, setSelectedMarker, sortedByPref,
    } = this.props;
    const selected = results.find((res) => res.id === selectedResult.id);
    const filteredResults = filterResults(filters, results);
    const sortedResults = sortResults(filteredResults, sorting, sortedByPref);

    const driverIsFilled = driverValidationSchema().isValidSync(driver);
    const isMobile = isMobileDevice();
    const markers = sortedResults.map((res) => ({
      id: res.id,
      lat: res.pickup_place.address.lat,
      lon: res.pickup_place.address.lon,
      price: res.best_price.total / 100,
    }));

    // If there are results but none corresponding to current filters,
    // we don't want to show the default selected result, and we explain
    // to the user that he can see some results by modifying filters
    if (results.length && !filteredResults.length) {
      return (
        <div className="hotel-results m-auto p-all">
          <p>
            {window.i18('NO_RESULTS')}
            {' '}
            (
            {results.length}
            {' '}
            {window.i18('IN_TOTAL')}
            ).
          </p>
        </div>
      );
    }

    return (
      <div className="hotel-results m-auto m-top p-all">
        { !isMobile ? (
          <CarRentals results={sortedResults} setSelectedResult={setSelectedResult} selectedResult={selectedResult} selectedMarker={selectedMarker} displayMap={displayMap} />
        ) : (selected ? (
          <SelectedResult setSelectedResult={setSelectedResult} selected={selected} openModal={openModal} editDriver={editDriver} driver={driver} driverIsFilled={driverIsFilled} addingToCart={addingToCart} addToCart={addToCart} />
        ) : (<CarRentalsMobile results={sortedResults} setSelectedResult={setSelectedResult} selectedResult={selectedResult} selectedMarker={selectedMarker} displayMap={displayMap} />
        ))}
        {displayMap && !isMobile
          ? (
            <div className="hotel-details">
              <div className="bordered-block p-all">
                <PricesMap
                  lat={search.pickUpLocation.location.lat}
                  lon={search.pickUpLocation.location.lon}
                  markers={markers}
                  handleClick={setSelectedMarker}
                  selectedMarker={selectedMarker}
                />
              </div>
            </div>
          )
          : (!isMobile
            && <SelectedResult selected={selected} openModal={openModal} editDriver={editDriver} driver={driver} driverIsFilled={driverIsFilled} addingToCart={addingToCart} addToCart={addToCart} />
          )}
      </div>

    );
  }
}

Results.propTypes = propTypes;

const mapStateToProps = (state) => ({
  results: state.carRentalResults.results,
  search: state.carRentalResults.search,
  selectedResult: state.carRentalResults.selectedResult,
  driver: state.carRentalResults.driver,
  addingToCart: state.carRentalResults.addingToCart,
  filters: state.carRentalResults.filters,
  sorting: state.carRentalResults.sorting,
  displayMap: state.carRentalResults.displayMap,
  selectedMarker: state.carRentalResults.selectedMarker,
  sortedByPref: state.carRentalResults.sortedByPref,
  isMobile: state.isMobileDevice,
});

const mapActionsToProps = (dispatch) => ({
  setSelectedResult: (id) => dispatch(setSelectedResult(id)),
  editDriver: (driver) => dispatch(editDriver(driver)),
  openModal: (options = null) => dispatch(openModal('Driver', options)),
  addToCart: () => dispatch(addToCart()),
  setSelectedMarker: (id) => dispatch(setSelectedMarker(id)),
});

export default connect(mapStateToProps, mapActionsToProps)(Results);
