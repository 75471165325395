import React from 'react';
import PropTypes from 'prop-types';
// Components
import FilterContainer from './FilterContainer';
import StarsInput from '../../../../components/StarsInput';

const propTypes = {
  stars: PropTypes.number.isRequired,
  setStars: PropTypes.func.isRequired,
};

export default function StarsFilter({ stars, setStars }) {
  return (
    <FilterContainer btnText={`${stars} ★ ${window.i18('OR')} +`} active={stars > 0}>
      <>
        <p>{window.i18('COMFORT_AT_LEAST')}</p>
        <StarsInput stars={stars} onChange={setStars} />
      </>
    </FilterContainer>
  );
}

StarsFilter.propTypes = propTypes;
