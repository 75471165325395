import React from 'react';
import GreenScore from '../../../components/GreenScore';
import { displayGreenScoreTitle } from '../../../utils/matchers';

const GreenScoreModal = (props) => {
  const greenScoreTitle = displayGreenScoreTitle(props.score);
  return (
    <div className="modal-inner">
      <section className="modal-header">
        <h3 className="common-sub-title">
          {greenScoreTitle.upperTitle}
          <strong>{greenScoreTitle.lowerTitle}</strong>
        </h3>
      </section>
      <section className="modal-section bordered">
        <GreenScore completion={props.score} />
      </section>
      <section className="modal-section bordered co2-emission">
        <div>
          <h3 className="common-sub-title">
            <strong>Co2</strong>
            {' '}
            {window.i18('EMITTED')}
          </h3>
          <span className="common-light-font red">{props.co2Emitted}</span>
        </div>
        <div>
          <div>
            <h3 className="common-sub-title">
              <strong>Co2</strong>
              {' '}
              {window.i18('TO_NEUTRALIZE')}
            </h3>
            <p className="common-small-bold-font">
              {window.i18('BY_A_REFORESTATION_PROGRAM')}
            </p>
          </div>
          <span className="common-light-font green">
            -
            {props.co2Emitted}
          </span>
        </div>
      </section>
      <section className="modal-section">
        <h3 className="common-sub-title">
          <strong>Co2</strong>
          {' '}
          {window.i18('SAVED')}
        </h3>
        <p className="common-small-bold-font">
          {window.i18('COMPARED_TO_CAR')}
        </p>
        <span className="common-light-font blue">{props.co2Saved}</span>
      </section>
    </div>
  );
};

export default GreenScoreModal;
