import { sortBy } from 'lodash-es';
import { push } from 'connected-react-router';
// import { NotificationManager as Notif } from 'react-notifications';
import moment from 'moment';
import {
  FETCHING_CART, FETCHING_CART_FAILED, RECEIVE_CART, FETCHING_PAYMENT_INFO, RECEIVE_PAYMENT_INFO, TOGGLE_CART_SUBSET, FETCHING_CART_AMOUNT, RECEIVE_CART_AMOUNT, UPDATE_CART_AMOUNT_FETCHING_TICKETS, RESET_CART, DELETING_FROM_CART, DELETING_FROM_CART_DONE,
} from './constants';

import API from '../../utils/api';
import { openModal } from '../ModalWrapper/actions';
import {
  setSearchingTrip as searchingTrip, setSearchedTrip, setOutwardTrip, setTripPassengers,
} from '../TripResults_v3/actions';
import { isCartItemDisabled, getCartSelectedTickets, getCartContentIDWithSameGroupID } from './selectors';
import { setCarRentalSearchParams } from '../CarRentalSearch/actions';
import { setHotelSearchParams } from '../HotelSearch/actions';
import { setTreepSearchParams } from '../TripSearch/actions';
import { setSearchingTrip, setSearchingHotel, setSearchingCar } from '../Search/actions';

export function fetchingCart() {
  return {
    type: FETCHING_CART,
  };
}

export function fetchingCartFailed() {
  return {
    type: FETCHING_CART_FAILED,
  };
}

export function deleteFromCart() {
  return {
    type: DELETING_FROM_CART,
  };
}

export function deleteFromCartDone() {
  return {
    type: DELETING_FROM_CART_DONE,
  };
}
export function receiveCart(cart) {
  return {
    type: RECEIVE_CART,
    cart,
  };
}

export function fetchingPaymentInfos() {
  return {
    type: FETCHING_PAYMENT_INFO,
  };
}

export function receivePaymentInfos(payload) {
  return {
    type: RECEIVE_PAYMENT_INFO,
    payload,
  };
}
export function fetchingCartAmount() {
  return {
    type: FETCHING_CART_AMOUNT,
  };
}

export function receiveCartAmount(amount) {
  return {
    type: RECEIVE_CART_AMOUNT,
    UPDATE_CART_AMOUNT_FETCHING_TICKETS,
    amount,
  };
}

export function updateCartAmountFetchingTickets(tickets) {
  return {
    type: UPDATE_CART_AMOUNT_FETCHING_TICKETS,
    tickets,
  };
}

export function resetCart() {
  return {
    type: RESET_CART,
  };
}

export function getPaymentInfos() {
  return (dispatch, getState) => {
    const cart = !getState().cart;
    const { user } = getState().app;
    const shouldGetPaymentInfos = !cart.paymentInfos || !cart.paymentInfos.isFetching;

    if (!shouldGetPaymentInfos || !user) { return; }

    dispatch(fetchingPaymentInfos());
    let url = '/paymentinfo/';
    if (user?.perms?.includes('travel_agent')) {
      url += `?customer_id=${user.company_id}`;
    }
    return API.get(url).then(
      (paymentInfos) => {
        dispatch(receivePaymentInfos(paymentInfos.data.result));
      },
    );
  };
}

export function getCart() {
  return (dispatch, getState) => {
    const shouldGetCart = !getState().cart.isFetching && !getState().cart.isAdding;

    if (!shouldGetCart) { return; }

    // Payment infos are needed to calculate the price of the cart
    dispatch(getPaymentInfos());
    dispatch(fetchingCart());

    return API.get('/cart/').then(
      (cart) => {
        cart = formatCartResults(cart.data.result || []);
        dispatch(receiveCart(cart));
        dispatch(updateCartAmount());
      },
    ).catch(() => {
      dispatch(fetchingCartFailed());
    });
  };
}

export function updateCartAmount() {
  return (dispatch, getState) => {
    const { cart } = getState();
    if (!cart.content || cart.content?.length === 0) {
      return;
    }

    const newTickets = getCartSelectedTickets(cart.content);

    if (!newTickets) {
      return;
    }

    if (cart.cartAmount.isFetching && sameTickets(newTickets, cart.cartAmount.fetchingTickets)) {
      return; // already fetching same tickets
    }

    // Check if tickets have changed
    if (cart.cartAmount.amount != null && sameTickets(newTickets, cart.cartAmount.amount.ticket_ids)) {
      return; // tickets are already those of the amount displayed
    }

    // Fetching new tickets amount

    dispatch(fetchingCartAmount());
    dispatch(updateCartAmountFetchingTickets(newTickets));

    return API.post(
      '/cart/detailed',
      { ticket_ids: newTickets },
    ).then((resp) => {
      // Check that the result correspond to the current fetchingTickets (two requests may have been sent nearly at the same time)
      const { cart } = getState();
      if (cart.content == null || resp.data.result == null) {
        return;
      }

      if (!sameTickets(newTickets, cart.cartAmount.fetchingTickets)) {
        return; // not the response for the last fetching tickets request
      }
      dispatch(receiveCartAmount(resp.data.result));
    });
  };
}

export function toggleCartSubset(subsetId) {
  return {
    type: TOGGLE_CART_SUBSET,
    subsetId,
  };
}

export function promptDeleteTreepFromCart(id) {
  return (dispatch, getState) => {
    const contentIDs = getCartContentIDWithSameGroupID(id, getState().cart.content);
    dispatch(openModal('Confirm', { message: window.i18('CONFIRM_DELETE'), confirm: () => dispatch(deleteTreepFromCart(id)), secondaryMessage: contentIDs.length > 1 ? window.i18('DELETING_ROUND_TRIP') : null }));
  };
}

function deleteTreepFromCart(id) {
  return (dispatch, getState) => {
    const contentIDs = getCartContentIDWithSameGroupID(id, getState().cart.content);
    dispatch(deleteFromCart());

    const APICalls = contentIDs.map((id) => API.delete(`/cart/${id}`));

    Promise.all(APICalls).then(() => {
      dispatch(deleteFromCartDone());
      dispatch(getCart());
    }).catch(() => {
      // Do not need this because we are catching everything localy now. See api.js interceptors
      /*
      Notif.warning(window.i18('ERROR_OCCURED_TRY_AGAIN'));
      */
      dispatch(deleteFromCartDone());
      dispatch(getCart());
    });
  };
}

export function refreshTreep(treep) {
  return (dispatch, getState) => {
    const mainSubset = treep.subsets.find((subset) => subset.is_main_section);
    const sections = treep.sections.filter((section) => section.set_ref === mainSubset.set_ref);
    const firstSection = sections[0];
    const lastSection = sections[sections.length - 1];

    let passengers = [];

    // This is a bit dirty.
    // We have to simulate a treep search...
    let search = {
      isRT: false,
      from: {
        search: '',
        location: treep.origin,
      },
      to: {
        search: '',
        location: treep.destination,
      },
      date: {
        r: null,
        a: {
          mode: 'departure',
          date: treep.departure,
          time: 140000,
        },
      },
    };

    // We take the passengers from the trip and reset them so we can match them with the offers
    // We do a lil flippidy floop with the passengers to get the same structure front <React.Fragment> back
    let uid = 0;
    const getNextUid = () => {
      uid += 1;
      return uid;
    };

    passengers = treep.travelers.map((traveler) => ({ id: getNextUid(), age: 1, card: traveler.card }));

    search = { ...search, passengers };

    // We change the URL
    dispatch(push('/search'));
    // We set the loader and everything up
    dispatch(searchingTrip('a'));
    // We store the search that was made at the time, to display it in the sidebar
    dispatch(setSearchedTrip(search));

    API.post('/treep/refresh', {
      origin: treep.origin,
      destination: treep.destination,
      main_sections_origin: firstSection.origin,
      main_sections_destination: lastSection.destination,
      departure: treep.departure,
      arrival: treep.arrival,
      user: getState().app.user,
      treep,
      filters: [],
    }).then((response) => {
      dispatch(setOutwardTrip(response.data.result));
      dispatch(setTripPassengers(passengers));
    });
  };
}

// Formats the API result to fit our needs
export function formatCartResults(cartResults) {
  // Build index of tickets set_ref to compute linked elements in cart
  const ticketsSetRef = new Map(); // ticket.uid -> ticket.set_ref
  cartResults.forEach((treep) => {
    if (treep.subsets == null || treep.subsets.length === 0) {
      return;
    }
    treep.subsets.forEach((subset) => {
      if (subset.tickets == null || subset.tickets.length === 0) {
        return;
      }
      subset.tickets.forEach((ticket) => {
        ticketsSetRef.set(ticket.uid, ticket.set_ref);
      });
    });
  });

  // Build index of linked set_refs
  const linkedSetRefs = new Map(); // ticket.set_ref -> relatedTicket.set_ref
  cartResults.forEach((treep) => {
    if (treep.subsets == null || treep.subsets.length === 0) {
      return;
    }
    treep.subsets.forEach((subset) => {
      if (subset.tickets == null || subset.tickets.length === 0) {
        return;
      }
      subset.tickets.forEach((ticket) => {
        if (ticket.related_ticket_id == null) {
          return;
        }
        const ticketSetRef = ticketsSetRef.get(ticket.uid);
        const relatedTicketSetRef = ticketsSetRef.get(ticket.related_ticket_id);
        if (ticketSetRef == null) {
          // console.error('cart: could not find set_ref of ticket', ticket.uid, 'ticketsSetRef:', ticketsSetRef)
          return;
        }
        if (relatedTicketSetRef == null) {
          // console.error('cart: could not find set_ref of related ticket', ticket.related_ticket_id, 'ticketsSetRef:', ticketsSetRef)
          return;
        }
        linkedSetRefs.set(ticketSetRef, relatedTicketSetRef);
        linkedSetRefs.set(relatedTicketSetRef, ticketSetRef);
      });
    });
  });

  cartResults = cartResults.map((cartResult) => {
    // We add a "selected" key, defaults to true
    cartResult.subsets = cartResult.subsets.map((subset) => {
      if (subset.bookable) { subset.selected = true; }

      // We add related set_ref in ticket, if available
      if (subset.tickets == null || subset.tickets.length === 0) {
        return subset;
      }
      const linkedSetRef = linkedSetRefs.get(subset.set_ref);
      if (linkedSetRef != null) {
        subset.linked_set_ref = linkedSetRef;
      }
      return subset;
    });

    return cartResult;
  });

  const sortedCartResults = {
    bookable: [],
    expired: [],
  };

  cartResults = sortBy(cartResults, 'departure').forEach((item) => {
    if (isCartItemDisabled(item)) { sortedCartResults.expired.push(item); } else { sortedCartResults.bookable.push(item); }
  });

  cartResults = sortedCartResults.bookable.concat(sortedCartResults.expired);

  return cartResults;
}

function sameTickets(ticketsA, ticketsB) {
  if (!ticketsA || ticketsA.length === 0) {
    return !ticketsB || ticketsB.length === 0;
  }
  if (!ticketsB || ticketsA.length !== ticketsB.length) {
    return false;
  }
  let containedInFirst = false;
  for (let i = 0; i < ticketsA.length; i += 1) {
    containedInFirst = false;
    for (let j = 0; j < ticketsB.length; j += 1) {
      if (ticketsA[i] === ticketsB[j]) {
        containedInFirst = true;
        break;
      }
    }
    if (!containedInFirst) {
      return false;
    }
  }
  return containedInFirst;
}

function emptyCart() {
  return (dispatch, getState) => {
    const treeps = getState().cart.content;
    dispatch(deleteFromCart());

    const APICalls = treeps.map((treep) => API.delete(`/cart/${treep.uid}`));

    Promise.all(APICalls).then(() => {
      dispatch(deleteFromCartDone());
      dispatch(getCart());
    }).catch(() => {
      // Do not need this because we are catching everything localy now. See api.js interceptors
      /*
      Notif.warning(window.i18('ERROR_OCCURED_TRY_AGAIN'));
      */
      dispatch(deleteFromCartDone());
      dispatch(getCart());
    });
  };
}

export function promptEmptyCart() {
  return (dispatch) => {
    dispatch(openModal('Confirm', { message: window.i18('CONFIRM_DELETE'), confirm: () => dispatch(emptyCart()) }));
  };
}

function getReturnTreep(booking, cartContent) {
  let outwardTreep;
  let returnTreep = null;
  if (booking.outward_treep_id) {
    // If our current booking has the outward_treep_id key, we look for the outward treep booking
    if (cartContent.find((item) => item.uid === booking.outward_treep_id) !== undefined) {
      returnTreep = booking;
      outwardTreep = cartContent.find((item) => item.uid === booking.outward_treep_id);
    } else outwardTreep = booking;
  } else {
    // If this booking ID appears as the outward_treep_id for another booking, we get that booking
    outwardTreep = booking;
    if (cartContent.find((item) => item.outward_treep_id === booking.uid)) {
      returnTreep = cartContent.find((item) => item.outward_treep_id === booking.uid);
    }
  }
  return {
    outwardTreep,
    returnTreep,
  };
}

export function searchCarRental(booking) {
  return (dispatch, getState) => {
    let carRentalParams;
    // eslint-disable-next-line camelcase
    const driver = booking.treep_travelers?.[0]?.user_profile ? {
      ...booking.treep_travelers[0].user_profile,
      selected: true,
      id: 0,
    }
      : {
        ...booking.travelers?.[0],
        selected: true,
        id: 0,
      };
    const license = driver.travel_documents?.find((doc) => doc.type === 'driver_license');
    if (license) {
      driver.license = {
        country_of_issue: license.country_of_issue,
        effective_date: license.effective_date,
        number: license.number,
      };
    }
    const drivers = [driver];
    if (booking.type === 'long_train' || booking.type === 'flying' || booking.type === 'rail') {
      const { content } = getState().cart;
      const { outwardTreep, returnTreep } = getReturnTreep(booking, content);
      const subsets = booking.subsets.filter((subset) => subset.is_main_section);
      const departure = subsets[subsets.length - 1].arrival ? subsets[subsets.length - 1].arrival : outwardTreep.departure;
      carRentalParams = {
        travelID: booking.travelID ?? null,
        location: outwardTreep.destination,
        date: {
          a: {
            date: moment(departure).format(),
            // We add a 30 minute delay after the flight or train arrival so that the user has enough time to reach the agency
            time: moment(departure).add(30, 'minutes').format('HHmmss'),
          },
          r: {
            date: returnTreep ? moment(returnTreep.departure).format() : moment(departure).add(1, 'days').format(),
            time: returnTreep ? moment(returnTreep.departure).add(-1, 'hours').format('HHmmss') : '090000',
          },
        },
        drivers,
      };
    } else if (booking.type === 'hotel') {
      carRentalParams = {
        travelID: booking.travelID ?? null,
        location: booking.destination,
        date: {
          a: {
            date: moment(booking.departure).format(),
          },
          r: {
            date: moment(booking.arrival).format(),
          },
        },
        drivers,
      };
    } else if (booking.type === 'car_rental') {
      carRentalParams = {
        travelID: booking.travelID ?? null,
        location: booking.destination,
        date: {
          a: {
            date: moment(booking.departure).format(),
          },
          r: {
            date: moment(booking.arrival).format(),
          },
        },
        drivers,
      };
    }
    dispatch(setCarRentalSearchParams(carRentalParams));
    dispatch(setSearchingCar());
    dispatch(push('/'));
    window.scrollTo(0, 0);
  };
}

export function searchHotel(booking) {
  return (dispatch, getState) => {
    let hotelParams;
    if (booking.type === 'long_train' || booking.type === 'flying' || booking.type === 'rail') {
      const { content } = getState().cart;
      const { outwardTreep, returnTreep } = getReturnTreep(booking, content);
      const departure = outwardTreep.arrival ? outwardTreep.arrival : outwardTreep.departure;
      hotelParams = {
        travelID: booking.travelID ?? null,
        location: outwardTreep.destination,
        date: {
          arrival: moment(departure).set({ hour: 0, minute: 0, second: 0 }).format(),
          departure: returnTreep ? moment(returnTreep.departure).set({ hour: 0, minute: 0, second: 0 }).format() : moment(departure).add(1, 'days').format(),
        },
        passengers: outwardTreep.travelers.map((t, i) => {
          t.selected = true;
          t.id = i;
          return t;
        }),
      };
    } else if (booking.type === 'car_rental') {
      hotelParams = {
        travelID: booking.travelID ?? null,
        location: booking.origin,
        date: {
          arrival: moment(booking.departure).set({ hour: 0, minute: 0, second: 0 }).format(),
          departure: moment(booking.arrival).set({ hour: 0, minute: 0, second: 0 }).format(),
        },
        passengers: booking.travelers.map((t, i) => {
          t.selected = true;
          t.id = i;
          return t;
        }),
      };
    } else if (booking.type === 'hotel') {
      hotelParams = {
        travelID: booking.travelID ?? null,
        location: booking.origin,
        date: {
          arrival: moment(booking.departure).set({ hour: 0, minute: 0, second: 0 }).format(),
          departure: moment(booking.arrival).set({ hour: 0, minute: 0, second: 0 }).format(),
        },
        passengers: booking.travelers.map((t, i) => {
          t.selected = true;
          t.id = i;
          return t;
        }),
      };
    }
    dispatch(setHotelSearchParams(hotelParams));
    dispatch(setSearchingHotel());
    dispatch(push('/'));
    window.scrollTo(0, 0);
  };
}

export function searchTreep(booking) {
  return (dispatch) => {
    let treepSearchParams;
    if (booking.type === 'hotel') {
      treepSearchParams = {
        travelID: booking.travelID ?? null,
        isRT: true,
        from: booking.travelers[0].address.ID !== '' ? booking.travelers[0].address.ID : {},
        to: booking.destination,
        date: {
          a: {
            date: moment(booking.departure),
          },
          r: {
            date: moment(booking.arrival),
          },
        },
        passengers: booking.travelers.map((t, i) => {
          t.selected = true;
          t.id = i;
          return t;
        }),
      };
    } else if (booking.type === 'car_rental') {
      treepSearchParams = {
        travelID: booking.travelID ?? null,
        isRT: true,
        from: booking.travelers[0].address.ID !== '' ? booking.travelers[0].address.ID : {},
        to: booking.origin,
        date: {
          a: {
            date: moment(booking.departure),
          },
          r: {
            date: moment(booking.arrival),
          },
        },
        passengers: booking.travelers.map((t, i) => {
          t.selected = true;
          t.id = i;
          return t;
        }),
      };
    } else if (booking.type === 'long_train' || booking.type === 'flying' || booking.type === 'rail') {
      treepSearchParams = {
        travelID: booking.travelID ?? null,
        isRT: false,
        from: booking.destination,
        to: booking.origin,
        date: {
          a: {
            date: moment(booking.departure),
          },
          r: {
            date: null,
          },
        },
        passengers: booking.travelers.map((t, i) => {
          t.selected = true;
          t.id = i;
          return t;
        }),
      };
    }
    dispatch(setTreepSearchParams(treepSearchParams));
    dispatch(setSearchingTrip());
    dispatch(push('/'));
    window.scrollTo(0, 0);
  };
}
