import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { displayPrice } from '../../../utils/converters';
import API from '../../../utils/api';
import PoweredByStripe from '../../../../images/powered-by-stripe.png';
import Leaf from '../../../components/GreenScore/Leaf';
import Error from '../Error';

export default function CheckoutForm({ ticketIDs }) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [amount, setAmount] = useState(null);
  const [name, setName] = useState('');
  const [tos, setTos] = useState(false);
  const [intentError, setIntentError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useSelector((state) => state.app);
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const data = {
      ticket_ids: ticketIDs,
    };
    if (user.perms.includes('travel_agent')) {
      data.customer_id = user.company_id;
    }
    API.post('/payment/credit_card_intent', data).then((res) => {
      setClientSecret(res.data.result.clientSecret);
      setAmount(res.data.result.amount);
    }).catch((error) => {
      setIntentError(error.response?.data?.errorMessage);
    });
  }, [ticketIDs, user.company_id, user.perms]);
  const inputStyle = {
    border: 'none',
    borderBottom: '1px solid lightgrey',
    padding: '8px 4px',
    marginTop: '0.4em',
    marginBottom: '1em',
    width: '100%',
    backgroundColor: 'transparent',
    outline: 'none',
    color: '#4A5875',
    fontFamily: 'Arial, sans-serif',
  };
  const labelStyle = {
    color: '#4A5875',
    fontWeight: 500,
    fontSize: '0.9em',
  };
  const cardStyle = {
    style: {
      base: {
        color: '#4A5875',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: 'grey',
          fontWeight: 500,
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name,
        },
      },
    });
    if (payload.error) {
      setError(`Erreur de paiement: ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };
  if (succeeded) {
    return (
      <div className="common-wrapper checkout-success">
        <section className="big-leaf">
          <Leaf completion={1} />
        </section>
        <p className="common-small-font">{window.i18('PAYMENT_SUCCESS')}</p>
        <Link className="button-main" to="/account/tickets/">
          {window.i18('SEE_MY_TICKETS')}
        </Link>
      </div>
    );
  }
  if (intentError) {
    return (
      <Error errors={[intentError]} />
    );
  }
  return (
    <div className="common-wrapper checkout-wrapper">
      <h2 className="common-sub-title">{window.i18('SECURED_PAYMENT')}</h2>
      <form
        id="payment-form"
        onSubmit={handleSubmit}
        style={{
          width: '100%', maxWidth: 400, marginTop: '2em',
        }}
      >
        <div className="bordered-block p-all">
          <label style={labelStyle}>{window.i18('CARD_NUMBER')}</label>
          <div style={inputStyle}>
            <CardNumberElement id="card-number-element" options={cardStyle} onChange={handleChange} />
          </div>
          <label style={labelStyle}>{window.i18('EXPIRATION_DATE')}</label>
          <div style={inputStyle}>
            <CardExpiryElement id="card-expiry-element" options={cardStyle} onChange={handleChange} />
          </div>
          <label style={labelStyle}>{window.i18('CVC_CODE')}</label>
          <div style={inputStyle}>
            <CardCvcElement id="card-cvc-element" options={cardStyle} onChange={handleChange} />
          </div>
          <div>
            <label style={labelStyle}>{window.i18('CARD_HOLDER')}</label>
            <input type="text" style={inputStyle} onChange={(e) => setName(e.target.value)} value={name} placeholder="Prénom Nom" />
          </div>
          <label htmlFor="tos" className="flex">
            <div className="common-checkbox" style={{ width: '40px' }}>
              <input type="checkbox" name="tos" id="tos" checked={tos} onChange={() => setTos(!tos)} />
              <span className="checkmark" />
            </div>
            <span className="checkout-sentence common-small-font">
              {window.i18('I_ACCEPT_THE')}
              {' '}
              <a href="https://www.thetreep.com/nos-passionnantes-cgv.html" target="_blank " rel="noopener">{window.i18('CGV')}</a>
              {' '}
              {window.i18('OF_THETREEP')}
            </span>
          </label>
          <button
            disabled={processing || disabled || succeeded || name === '' || !tos}
            id="submit"
            className={classNames('button-main', 'm-top', { loading: processing })}
          >
            <span className="loading" />
            <span className="text">
              {processing ? (
                window.i18('PAYING')
              ) : (
                `${window.i18('PAY')} ${displayPrice(amount / 100)}`
              )}
            </span>
          </button>
        </div>
      </form>
      {error && (
        <p className="m-y" style={{ color: '#F18888' }}>{error}</p>
      )}
      <div className="powered-by m-top">
        <img src={PoweredByStripe} alt="Powered by stripe" />
        <div className="stripe-informations">
          <span className="common-small-font">{window.i18('SECURED_BY_STRIPE')}</span>
          <span className="common-small-font">{window.i18('SSL_PAYMENT')}</span>
        </div>
      </div>
    </div>
  );
}
