import React from 'react';
import PropTypes from 'prop-types';
import { getTrainLogo, getAirlineSquareLogo, getAirlineLogo } from '../../utils/icons';

function Carrier({
  carrier, carriers, transport, size = 'default',
}) {
  const logoFn = {
    square: {
      long_train: {
        getLogo: getTrainLogo,
        logo: 'logo',
      },
      rail: {
        getLogo: getTrainLogo,
        logo: 'logo',
      },
      flight: {
        getLogo: getAirlineSquareLogo,
        logo: 'logo',
      },
    },
    default: {
      long_train: {
        getLogo: getTrainLogo,
        logo: 'logo',
      },
      rail: {
        getLogo: getTrainLogo,
        logo: 'logo',
      },
      flight: {
        getLogo: getAirlineLogo,
        logo: 'square_logo',
      },
    },
  };

  const carrierDetails = carriers[transport][carrier];

  if (!carrierDetails) {
    return (
      <span className="m-x">{carrier}</span>
    );
  }

  const { getLogo, logo } = logoFn[size][transport];
  if (carrierDetails[logo]) {
    return getLogo(carrierDetails[logo]);
  }
  return (
    <span className="m-x">{carrierDetails.name}</span>
  );
}

Carrier.propTypes = {
  carrier: PropTypes.string.isRequired,
  carriers: PropTypes.shape({}).isRequired,
  transport: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['default', 'square']),
};

Carrier.defaultProps = {
  size: 'default',
};

export default Carrier;
