import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr';

// Transitions
import { AnimatedSwitch } from 'react-router-transition';
import {
  bounceTranlateYTransition,
  mapStylesTranslateY,
} from '../../utils/transitions';

import StepSearch from '../StepSearch';
import DefaultSearch from '../Search';
import TripResultsV3 from '../TripResults_v3';
import HotelResults from '../HotelResults';
import CarRentalResults from '../CarRentalResults';
import UserAccount from '../UserAccount';
import Cart from '../Cart';
import Checkout from '../Checkout';
import DefaultHeader from '../Header';
import CNAMHeader from '../Header/CNAM';
import ModalWrapper from '../ModalWrapper';
import Onboarding from '../Onboarding';
import OnboardingDelete from '../Onboarding/Delete';
import ImgViewer from '../ImgViewer';
import vaironLogo from '../../../images/logo-vairon.png';
import { checkAgency, isCNAM } from '../../utils/functions';
import SkeletonLoader from '../../components/SkeletonLoader';

import {
  checkLogin,
  fetchCards,
  fetchAgeCategories,
  fetchCO2Stats,
  checkMobileDevice,
  fetchHandicaps,
  getSandboxSession,
  logWithToken,
  setLang,
} from './actions';
import FreshChat from '../../utils/freshchat';

import {
  RESET_PASSWORD_TOKEN_PATH,
  ACTIVATE_USER_TOKEN_PATH,
  TICKET_APPROVAL_PATH,
  SEARCH_FORM_PATH,
  LOCAL_STORAGE_USER_KEY,
} from './constants';
import Approval from '../Approval';
// explorer ie 9/11
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import CNAMHome from '../CNAMHome';
import CNAMHelp from '../CNAMHelp';
import CNAMContact from '../CNAMContact';
import text from './text';

const Search = isCNAM() ? StepSearch : DefaultSearch;
const Header = isCNAM() ? CNAMHeader : DefaultHeader;

const freshChatID = process.env.REACT_APP_FRESHCHAT_TOKEN;

class App extends Component {
  // This used to (and should) be in the componentWillMount lifecycle call.
  // However, in React 16 (current version at the time of writing), componentWillMount are called from children to parent,
  // Making this one (the App one) the last to fire
  // Since we need to instanciate stuff app-wide beforehand (like login infos & loyalty cards), we need to fire it here.
  // See the official React doc for more infos & future upgrades : https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html#fetching-external-data
  // https://gist.github.com/bvaughn/89700e525ff423a75ffb63b1b1e30a8f

  constructor(props) {
    super(props);
    props.fetchCO2Stats();
    if (props.location.pathname.match(/\/user\/onboarding/i)) {
      window.localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    }
    if (props.location?.pathname.match(/^\/sandbox\/[a-zA-Z0-9-_+.]+/)) {
      const path = props.location.pathname.split('/');
      const token = path[2];
      props.getSandboxSession(token);
    } else if (props.location?.pathname.match(/^\/sso\/[a-zA-Z0-9-_+.]+/)) {
      const path = props.location.pathname.split('/');
      const token = path[2];
      props.logWithToken(token);
    } else {
      props.checkLogin();
    }
    props.fetchCards();
    props.fetchAgeCategories();
    props.fetchHandicaps();
    props.checkMobileDevice();
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.checkMobileDevice();
    });

    let lang = window.navigator.languages
      ? window.navigator.languages[0]
      : null;
    lang = lang || window.navigator.language
      || window.navigator.browserLanguage || window.navigator.userLanguage;

    let shortLang = lang;
    if (shortLang.indexOf('-') !== -1) { [shortLang] = shortLang.split('-'); }

    if (shortLang.indexOf('_') !== -1) { [shortLang] = shortLang.split('_'); }

    const savedLang = localStorage.getItem('theTreepLang');

    if (savedLang === 'fr' || savedLang === 'en') {
      this.props.setLang(savedLang);
    } else if (shortLang === 'fr') {
      this.props.setLang('fr');
      localStorage.setItem('theTreepLang', 'fr');
    } else {
      this.props.setLang('en');
      localStorage.setItem('theTreepLang', 'en');
    }

    if (isCNAM()) {
      this.props.setLang('fr');
    }

    moment.locale(this.props.lang);

    window.i18 = (key) => text[this.props.lang]?.[key];

    document.title = isCNAM()
      ? 'Le Service Trajet santé de l\'Assurance Maladie'
      : 'The Treep';
    const crispID = process.env.REACT_APP_CRISP_WEBSITE_ID;
    if (crispID != null) { // Crisp is enabled for this env
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = crispID;
      try {
        startCrisp();

        // If user's agency is vairon, we move the crisp button
        const interval = setInterval(() => {
          const crisp = document.querySelector('.crisp-kquevr');
          const logo = getLogo(process.env.REACT_APP_CRISP_AGENCY);
          if (!logo) { // no agency logo => standard placement
            clearInterval(interval);
          } else if (crisp) {
            clearInterval(interval);
            crisp.style.transform = 'translate(-20px, -50px)';
            const img = document.createElement('img');
            img.src = logo;
            img.style.cssText = `
              position: absolute !important;
              width: 100px !important;
              left: -20px !important;
              top: 70px !important;
              border-radius: 3px !important;
              transition: opacity 0.5s ease-in !important;
            `;
            crisp.insertAdjacentElement('afterbegin', img);
            window.$crisp.push(['config', 'color:theme', ['green']]);
          }
        }, 100);
      } catch (e) {
        // Crisp error
      }
    }
  }

  componentDidUpdate = () => {
    const { user } = this.props;

    moment.locale(this.props.lang);

    const crispID = process.env.REACT_APP_CRISP_WEBSITE_ID;
    if (crispID != null) { // Crisp is enabled for this env
      // Set appropriate account for Vairon users
      if (window.CRISP_WEBSITE_ID != null && user != null && user.company
        != null) {
        // @retrocompat we overide crisp ID for Vairon users here as Prod SBT is currently used by both TheTreep and Vairon users
        const vaironCrispID = 'c534f3d8-59b5-4f5f-85c1-3cd41c307352';
        if (checkAgency(user, 'vairon') && window.CRISP_WEBSITE_ID
          !== vaironCrispID) {
          window.$crisp.push(['do', 'chat:hide']); // Hide the current (thetreep) CRISP
          window.$crisp = [];
          window.CRISP_WEBSITE_ID = vaironCrispID;
          window.$crisp.push(['config', 'color:theme', ['green']]);
          window.$crisp.push(['do', 'chat:show']); // Show Vairon CRISP

          try {
            startCrisp();
          } catch (e) {
            // Crisp error
          }
        }
      }
      if (window.$crisp != null) {
        window.$crisp.push(['safe', true]); // enable crisp safe mode (disable errors and warnings)
      }
      // Set appropriate account info in crisp
      setCrispCredentials(user);
    } else if (freshChatID != null) {
      setFreshChatCredentials(user);
    }
  };

  render() {
    if (this.props.isCheckingUser) {
      return (
        <SkeletonLoader />
      );
    }
    return (
      <div className="App">
        <ImgViewer />
        <ModalWrapper />
        <Header lang={this.props.lang} />
        <div><ToastContainer closeButton={false} /></div>
        <AnimatedSwitch
          atEnter={bounceTranlateYTransition.atEnter}
          atLeave={bounceTranlateYTransition.atLeave}
          atActive={bounceTranlateYTransition.atActive}
          mapStyles={mapStylesTranslateY}
          className="route-wrapper"
        >
          <Route
            path={TICKET_APPROVAL_PATH}
            component={Approval}
          />
          <Route
            path="/cart/checkout"
            component={Checkout}
          />
          <Route
            path="/cart"
            component={Cart}
          />
          <Route
            path="/account"
            component={UserAccount}
          />
          {/* <Route path="/search" component={TripResults} />
          <Route path="/search_v2" component={TripResultsV2} /> */}
          <Route
            path="/search"
            component={TripResultsV3}
          />
          <Route
            path="/search-hotels"
            component={HotelResults}
          />
          <Route
            path="/search-car-rentals"
            component={CarRentalResults}
          />
          <Route
            path="/user/onboarding/delete"
            component={OnboardingDelete}
          />
          <Route
            path="/user/onboarding"
            component={Onboarding}
          />
          <Route
            path={RESET_PASSWORD_TOKEN_PATH}
            component={Search}
          />
          <Route
            path={ACTIVATE_USER_TOKEN_PATH}
            component={Search}
          />
          <Route
            path="/help"
            component={isCNAM() ? CNAMHelp : () => (<div />)}
          />
          <Route
            path="/contact"
            component={isCNAM() ? CNAMContact : () => (<div />)}
          />
          <Route
            path={SEARCH_FORM_PATH}
            component={Search}
          />
          <Route
            path="*"
            component={isCNAM() ? CNAMHome : Search}
          />
        </AnimatedSwitch>
        {
          freshChatID != null
          && (
            <FreshChat />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user,
  isCheckingUser: state.app.isCheckingUser,
  userPermissions: state.app.userPermissions,
  lang: state.app.lang,
});

const mapDispatchToProps = (dispatch) => ({
  checkLogin: () => dispatch(checkLogin()),
  fetchCO2Stats: () => dispatch(fetchCO2Stats()),
  fetchCards: () => dispatch(fetchCards()),
  fetchAgeCategories: () => dispatch(fetchAgeCategories()),
  checkMobileDevice: () => dispatch(checkMobileDevice()),
  fetchHandicaps: () => dispatch(fetchHandicaps()),
  getSandboxSession: (token) => dispatch(getSandboxSession(token)),
  logWithToken: (token) => dispatch(logWithToken(token)),
  setLang: (lang) => dispatch(setLang(lang)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);

function startCrisp() {
  const d = document;
  const s = d.createElement('script');

  s.src = 'https://client.crisp.chat/l.js';
  s.async = 1;
  d.getElementsByTagName('head')[0].appendChild(s);
}

function setCrispCredentials(user) {
  if (window.$crisp != null && user != null) {
    window.$crisp.push(['set', 'user:email', [user.email]]);
    window.$crisp.push(
      ['set', 'user:nickname', [`${user.firstname} ${user.lastname}`]],
    );
    if (user.company != null) {
      window.$crisp.push(['set', 'user:company', [user.company.name]]);
    }
  }
}

const setFreshChatCredentials = (user) => {
  if (window.fcWidget != null && user != null) {
    window.fcWidget.user.setProperties({
      firstName: user.firstname,
      lastName: user.lastname,
      email: user.email,
      phone: user.phone,
      company: user.company.name,
    });
  }
};

function getLogo(agency) {
  switch (agency) {
    case 'vairon':
      return vaironLogo;
    default:
      return null;
  }
}
