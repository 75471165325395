import React from 'react';

function parseType(type, length) {
  switch (type) {
    case 'rail':
      return (length > 1 ? `${window.i18('THE_PLURAL')} ${window.i18('TRAINS')}` : `${window.i18('THE')} ${window.i18('TRAIN')}`);
    case 'hotel':
      return (length > 1 ? `${window.i18('THE_PLURAL')} ${window.i18('HOTELS')}` : window.i18('THE_CUT') + window.i18('HOTEL'));
    case 'car_rental':
      return (length > 1 ? `${window.i18('THE_PLURAL')} ${window.i18('CAR_RENTALS')}` : window.i18('CAR_RENTAL'));
    case 'flight':
      return (length > 1 ? `${window.i18('THE_PLURAL')} ${window.i18('FLIGHTS')}` : `${window.i18('THE')} ${window.i18('FLIGHT')}`);
    default:
      return type;
  }
}
export default class Confirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
    };
  }

    confirm = () => {
      this.props.confirm();
      this.setState({
        success: true,
      });
    }

    render() {
      const { types, airOfflineCancellation, railOfflineCancellation } = this.props;

      if ((types.includes('flight') && airOfflineCancellation) || (types.includes('rail') && railOfflineCancellation)) {
        return (
          <div className="modal-inner cancel-modal">
            <p>{window.i18('OFFLINE_CANCEL')}</p>
          </div>
        );
      }
      if (this.state.success) {
        return (
          <div className="modal-inner cancel-modal">
            <section className="modal-body">
              <p>
                {' '}
                {window.i18('TREATING_YOUR_REQUEST')}
                <br />
                <br />
                {window.i18('VERIFY_CANCEL_CONDITIONS')}
              </p>
              <span><strong>{this.props.email}</strong></span>
            </section>
          </div>
        );
      }
      let conditions = `${window.i18('HERE_ARE_CONDITIONS')} :<br/><br/>`;
      if (this.props.conditions !== undefined && this.props.conditions.length === 1) {
        conditions += `<blockquote>${this.props.conditions[0]}</blockquote>`;
      } else if (this.props.conditions !== undefined && this.props.conditions.length > 1) {
        this.props.conditions.forEach((condition, i) => {
          conditions += `<strong>Ticket ${(i + 1).toString()}</strong> :<br/>`;
          conditions += `<blockquote>${condition}</blockquote>`;
        });
      } else {
        conditions = `${window.i18('WE_CAN_CHECK_CONDITIONS')}<br/>`;
      }
      conditions += '<br/>';
      const description = (this.props.types?.every((t) => t === this.props.types[0]) ? parseType(this.props.types[0], this.props.types.length) : window.i18('THE_RESERVATIONS'));
      return (
        <div className="modal-inner cancel-modal">
          <section className="modal-body">
            <h4>{window.i18('WARNING')}</h4>
            <p>
              {window.i18('IF_YOU_CANCEL')}
              {' '}
              {description}
              ,
              {' '}
              {window.i18('ALL_TRAVELERS_WILL_BE_CONCERNED')}
            </p>
            <p dangerouslySetInnerHTML={{ __html: conditions }} />
            {this.props.types?.includes('flight') && (
              <p>{window.i18('CANCEL_REQUIRES_AGENT')}</p>
            )}
          </section>
          <div className="modal-button-group">
            <a className="button-main" onClick={this.confirm}>{window.i18('CONFIRM_CANCEL_REQUEST')}</a>
            <a className="button-secondary" onClick={this.props.closeModal}>{window.i18('ABANDON_CANCEL_REQUEST')}</a>
          </div>
        </div>

      );
    }
}
