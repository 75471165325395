import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
// Functions
import { travelerValidationSchema } from '../../functions';
import { getSvgIcon } from '../../../../utils/icons';
// Actions
import { setGallery } from '../../../ImgViewer/actions';
import { capitalize } from '../../../../utils/functions';

const propTypes = {
  travelers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openTravelerModal: PropTypes.func.isRequired,
  fillTraveler: PropTypes.func.isRequired,
  selectedOffers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  nightsCount: PropTypes.number.isRequired,
  setGallery: PropTypes.func.isRequired,
};

function TravelersDistribution({
  travelers, openTravelerModal, fillTraveler, selectedResult, nightsCount, assignTravelerToOffer, setGallery,
}) {
  const handleSubmit = (values) => {
    fillTraveler(values);
  };

  const { selectedOffers } = selectedResult;

  return (
    <>
      <h2 className="hotel-offers__title">{window.i18('DISTRIBUTING_PEOPLE_IN_ROOMS')}</h2>
      <ul className="hotel-travelers__list">
        <li className="hotel-travelers__item">
          {window.i18('PEOPLE_TO_PLACE')}
          {' '}
          :
          {' '}
        </li>
        {travelers.map((traveler) => {
          const filled = travelerValidationSchema().isValidSync(traveler);
          const assigned = selectedOffers.find((offer) => offer.travelers.find((t) => t === traveler.id.toString()));
          const travelersClass = classNames('hotel-travelers__btn', { filled, assigned });
          const handicap = traveler.handicap && traveler.handicap.length > 0;
          return (
            <li className="hotel-travelers__item" key={traveler.id}>
              <button
                onClick={() => openTravelerModal({
                  traveler, travelerValidationSchema, handleSubmit,
                })}
                className={travelersClass}
              >
                {filled ? traveler.firstname : window.i18('TO_COMPLETE')}
                {handicap && handicap !== ''
                  && (
                  <span className="handicap">
                    {getSvgIcon('handicap', 'ui/')}
                  </span>
                  )}
              </button>
            </li>
          );
        })}
      </ul>
      <ul className="hotel-rooms__list">
        {selectedOffers.map((offer, i) => (
          <li key={offer.id} className="hotel-rooms__item">
            {offer.img && (
              <a className="hotel-rooms__image" data-count={offer.img && offer.img.length} onClick={() => setGallery(offer.img[0], offer.img)}>
                <img src={offer.img.length >= 1 ? offer.img[0] : null} alt="" />
              </a>
            )}
            <p className="hotel-rooms__name">
              <strong>
                {window.i18('ROOM')}
                {' '}
                { i + 1 }
              </strong>
              {' '}
              (
              {offer.pricePerNight.toFixed(2)}
              €)
            </p>
            {[...Array(offer.maxOccupancy)].map((x, i) => (
              <div key={offer.id} className={classNames('hotel-rooms__traveler__container', { filled: offer.travelers[i] })}>
                <select name="traveler" id="traveler" className="hotel-rooms__traveler" value={offer.travelers[i] || ''} onChange={(e) => assignTravelerToOffer(offer.id, e.target.value, i)}>
                  <option key="none" value="none">{window.i18('NONE')}</option>
                  {travelers.map((traveler, j) => (
                    <option key={traveler.id} value={traveler.id}>{traveler.firstname || `${capitalize(window.i18('TRAVELER'))} ${j + 1}`}</option>
                  ))}
                </select>
              </div>
            ))}
          </li>
        ))}
      </ul>
      {/* TODO: Assign each passenger to a room */}
      <p className="hotel-total-price">
        {window.i18('TOTAL_FOR')}
        {' '}
        <strong>
          {nightsCount}
          {' '}
          {nightsCount > 1 ? window.i18('NIGHTS') : window.i18('NIGHT')}
          {' '}
          -
          {' '}
          {selectedOffers.length}
          {' '}
          {selectedOffers.length > 1 ? window.i18('ROOMS').toLowerCase() : window.i18('ROOM').toLowerCase()}
          {' '}
          :
          {' '}
          {selectedOffers.reduce((totalPrice, offer) => offer.pricePerNight * nightsCount + totalPrice, 0).toFixed(2)}
          €
        </strong>
        {' '}
        {window.i18('WITHOUT_TAXES')}
      </p>
    </>
  );
}

TravelersDistribution.propTypes = propTypes;

const mapDispatchToProps = (dispatch) => ({
  setGallery: (img, imgs) => dispatch(setGallery(img, imgs)),
});

export default connect(null, mapDispatchToProps)(TravelersDistribution);
