import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { AnimatedSwitch } from 'react-router-transition';
import UserTickets from './UserTickets';
import UserApproval from './UserApproval';
import UserInformations from './UserInformations';
import UserDocuments from './UserDocuments';
import UserCards from './UserCards';
import UserDashboard from './UserDashboard';

import SideBar from '../../components/SubNav/SideBar';
import SubNav from '../../components/SubNav';
// Transitions
import {
  bounceTranslateXTransition,
  mapStylesTranslateX,
} from '../../utils/transitions';
import UserLanguage from './UserLanguage';
import { isCNAM } from '../../utils/functions';

const UserAccount = (props) => {
  if ((!props.user && !props.isCheckingUser) || props.sandboxMode) {
    return <Redirect to="/" />;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isMobileDevice } = useSelector((state) => state.app);

  const { match, location } = props;
  const DashboardLink = process.env.REACT_APP_WITH_USER_DASHBOARD && process.env.REACT_APP_WITH_USER_DASHBOARD === 'true' ? [{
    label: window.i18('DASHBOARD'),
    route: `${match.path}/dashboard/`,
    svg: 'dashboard',
  }] : [];
  const links = DashboardLink.concat([
    {
      label: window.i18('MY_BOOKINGS'),
      route: `${match.path}/tickets/`,
      svg: 'reservations',
    },
    {
      label: window.i18('MY_ARCHIVES'),
      route: `${match.path}/archives/`,
      svg: 'archives',
    },
    {
      label: window.i18('PERSONAL_DATA'),
      route: `${match.path}/informations/`,
      svg: 'info',
    },
    {
      label: window.i18('DOCUMENTS'),
      route: `${match.path}/documents`,
      svg: 'documents',
    },
    {
      label: window.i18('LOYALTY_AND_SUBSCRIBTION_CARDS'),
      route: `${match.path}/cards`,
      svg: 'cards',
    },
  ]);

  const isApprover = props.user && props.user.company !== null && (props.user.perms.includes('approval') || props.user.perms.includes('checkApproval'));

  if (isApprover) {
    links.splice(3, 0,
      {
        label: window.i18('APPROVAL_REQUESTS'),
        route: `${match.path}/approvals/`,
        svg: 'reservations',
      });
  }

  if (!isCNAM()) {
    links.push(
      {
        label: window.i18('LANGUAGE'),
        route: `${match.path}/language`,
        svg: 'language',
      },
    );
  }

  const shouldRedirect = match.url === location.pathname; // Prevent "You tried to redirect to the same route you're currently on" because AnimatedSwitch is buggy
  return (
    <div className="user-account" id="user-account">
      {isMobileDevice ? (
        <SideBar pageWrapId="user-account-page" outerContainerId="user-account" links={links} location={props.location} />
      ) : (
        <div className="user-account__menu">
          <SubNav links={links} location={props.location} />
        </div>
      )}
      {shouldRedirect && <Redirect to={process.env.REACT_APP_WITH_USER_DASHBOARD && process.env.REACT_APP_WITH_USER_DASHBOARD === 'true' ? `${match.path}/dashboard/` : `${match.path}/tickets/`} />}
      <div className="user-account__page" id="user-account-page">
        <AnimatedSwitch
          atEnter={bounceTranslateXTransition.atEnter}
          atLeave={bounceTranslateXTransition.atLeave}
          atActive={bounceTranslateXTransition.atActive}
          mapStyles={mapStylesTranslateX}
          className="route-wrapper"
        >
          { process.env.REACT_APP_WITH_USER_DASHBOARD && process.env.REACT_APP_WITH_USER_DASHBOARD === 'true' && <Route path={`${match.path}/dashboard/`} component={UserDashboard} />}
          <Route path={`${match.path}/tickets/`} component={UserTickets} />
          <Route path={`${match.path}/archives/`} render={(props) => <UserTickets {...props} archived />} />
          {isApprover && (
            <Route
              path={`${match.path}/approvals/`}
              render={(props) => <UserApproval {...props} isMobileDevice={isMobileDevice} />}
            />
          )}
          <Route
            path={`${match.path}/informations/`}
            render={(props) => <UserInformations {...props} isMobileDevice={isMobileDevice} />}
          />
          <Route
            path={`${match.path}/documents/`}
            render={(props) => <UserDocuments {...props} isMobileDevice={isMobileDevice} />}
          />
          <Route
            path={`${match.path}/cards/`}
            render={(props) => <UserCards {...props} isMobileDevice={isMobileDevice} />}
          />
          {!isCNAM() && (
          <Route
            path={`${match.path}/language/`}
            render={(props) => <UserLanguage {...props} isMobileDevice={isMobileDevice} />}
          />
          )}
        </AnimatedSwitch>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.app.user,
  isCheckingUser: state.app.isCheckingUser,
  account: state.account,
  sandboxMode: state.app.sandboxMode,
});

export default connect(mapStateToProps)(UserAccount);
