import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Leaf from '../../components/GreenScore/Leaf';

const Message = ({
  message, date, sandboxMode, extraInfo, leaf,
}) => (
  <div className={`checkout-success ${leaf ?? 'common-wrapper'}`}>
    {leaf && (
      <section className="big-leaf">
        <h2>{window.i18('ALL_WENT_WELL')}</h2>
        <Leaf completion={1} />
      </section>
    )}
    <p className="common-small-font">{message}</p>
    {date && (
      <>
        <p className="common-small-font">
          {window.i18('YOU_HAVE_UP_TO')}
          <strong>{moment(date).format(`DD/MM/YY ${window.i18('AT')} HH:mm:ss`)}</strong>
          {' '}
          {window.i18('TO_VALIDATE_THIS_OPTION')}
        </p>
        <p className="common-small-font">{window.i18('FARE_CONDITIONS_CAN_CHANGE')}</p>
      </>
    )}
    {!sandboxMode && (
      <Link className="button-main" to="/account/tickets/">
        {window.i18('SEE_TICKETS')}
      </Link>
    )}
    {extraInfo && <div className="checkout-success__extra-info info" dangerouslySetInnerHTML={{ __html: extraInfo }} />}
    {process.env.REACT_APP_SURVEY_URL && (
      <>
        <iframe
          title="survey-cnam"
          className="clickup-embed clickup-dynamic-height"
          src={process.env.REACT_APP_SURVEY_URL}
          frameBorder="0"
          width="100%"
          height="100%"
          style={{ background: 'transparent', height: 1468 }}
        />
        <script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js" />
      </>
    )}
  </div>
);

const Success = ({
  message, status, date, extraInfo, sandboxMode,
}) => (
  <Message message={message} status={status} date={date} extraInfo={extraInfo} sandboxMode={sandboxMode} leaf />
);

export default Success;
