import React from 'react';
import { getSvgIcon } from '../../utils/icons';

const stars = ({ count }) => {
  const stars = [];
  for (let i = 0; i < count; i += 1) {
    stars.push(
      <React.Fragment key={`star-${i}`}>
        {getSvgIcon('star', 'ui/')}
      </React.Fragment>,
    );
  }
  return (
    <div className="hotel-stars">
      {stars}
    </div>
  );
};

export default stars;
