// import { NotificationManager as Notif } from 'react-notifications';
import {
  SET_SEARCHING_TRIP, SET_SEARCHING_HOTEL, SET_SEARCHING_CAR, SET_ENTITY,
} from './constants';
import {
  CDS_REDIRECT_BEGIN, CDS_REDIRECT_SUCCESS, CDS_REDIRECT_ERROR, SET_ENTITY_TOUCHED,
} from '../TripSearch/constants';
import API from '../../utils/api';
import { checkForm } from '../TripSearch/actions';

export function setSearchingTrip() {
  return {
    type: SET_SEARCHING_TRIP,
  };
}

export function setSearchingHotel() {
  return {
    type: SET_SEARCHING_HOTEL,
  };
}

export function setSearchingCar() {
  return {
    type: SET_SEARCHING_CAR,
  };
}

export function redirectToCds() {
  return (dispatch) => {
    dispatch({ type: CDS_REDIRECT_BEGIN });
    API.post('/hotel/cds', {})
      .then((resp) => {
        dispatch({ type: CDS_REDIRECT_SUCCESS });
        if (resp.data.result != null && resp.data.result != null) {
          window.open(resp.data.result.url, '_blank');
        }
      }, () => {
        dispatch({ type: CDS_REDIRECT_ERROR });
        // Do not need this because we are catching everything localy now. See api.js interceptors
        /*
        Notif.warning(window.i18('REDIRECTION_ERROR'));
        */
      });
  };
}

export function setEntity(entity) {
  return (dispatch) => {
    dispatch({ type: SET_ENTITY_TOUCHED });
    dispatch({ type: SET_ENTITY, entity: entity != null ? parseInt(entity, 10) : null });
    dispatch(checkForm());
  };
}
