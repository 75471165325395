import React from 'react';
import Skeleton from 'react-skeleton-loader';

const UserTicketsLoader = ({ archived }) => (
  <div className="user-tickets-loader">
    <Skeleton width="100px" height="1em" />
    <span />
    <Skeleton width="150px" height="1em" />
    <div>
      <Skeleton width="100%" widthRandomness={0} height="5em" count={archived ? 8 : 4} />
    </div>
    {!archived && (
      <>
        <Skeleton width="150px" height="1em" />
        <div>
          <Skeleton width="100%" widthRandomness={0} height="5em" count={4} />
        </div>
      </>
    )}
  </div>
);

export default UserTicketsLoader;
