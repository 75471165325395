import React from 'react';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { useSelector } from 'react-redux';
// Components
import Input from '../../../components/Inputs/Material';
import Address from '../../../components/Inputs/MaterialAddress';
import DateInput from '../../../components/Inputs/MaterialDate';
import Select from '../../../components/Inputs/MaterialSelect';
import Checkbox from '../../../components/Checkbox';
// Functions
// import { formatTimeToObject } from '../../../utils/converters';

const hours = [
  {
    value: '',
    name: '',
  },
  {
    value: '060000',
    name: '06:00',
  },
  {
    value: '080000',
    name: '08:00',
  },
  {
    value: '100000',
    name: '10:00',
  },
  {
    value: '120000',
    name: '12:00',
  },
  {
    value: '140000',
    name: '14:00',
  },
  {
    value: '160000',
    name: '16:00',
  },
  {
    value: '180000',
    name: '18:00',
  },
  {
    value: '200000',
    name: '20:00',
  },
  {
    value: '220000',
    name: '22:00',
  },
];

function Search({
  active, search, handleSubmit, subsidy, passengers,
}) {
  const formErrors = useSelector((state) => state.tripSearch.errors);
  const formTouched = useSelector((state) => state.tripSearch.touched);

  return (
    <div className={classNames('step-block', { active })}>
      <h2 data-step="4" className="step-block__title">Je recherche mon billet</h2>
      {active && (
        <Formik
          initialValues={{
            isRT: true,
            from: search.from,
            to: search.to,
            date: {
              a: {
                date: '',
                time: '',
              },
              r: {
                date: '',
                time: '',
              },
            },
            travelers: [],
          }}
          onSubmit={handleSubmit}
          validationSchema={yup.object().shape({
            from: yup.object().shape({
              description: yup.string().required('Ce champ est requis'),
            }),
            date: yup.object().shape({
              a: yup.object().shape({
                date: yup.date()
                  .min(moment().subtract(1, 'days').toDate(), 'Date passée')
                  .max(moment().add(2, 'years').toDate(), 'Date trop éloignée')
                  .required('Ce champ est requis')
                  .typeError('Veuillez entrer une date'),
                time: yup.string().required('Ce champ est requis'),
              }),
              r: yup.object().shape({
                date: yup.date()
                  .max(moment().add(2, 'years').toDate(), 'Date trop éloignée')
                  .typeError('Veuillez entrer une date')
                  .required('Ce champ est requis'),
                time: yup.string().required('Ce champ est requis'),
              }),
            }),
            travelers: yup.array().of(yup.object().shape({
              corsican_fares_number: yup.string().required(),
            })),
          })}
        >
          {({
            errors, touched, values, setFieldValue, setFieldTouched,
          }) => (
            <Form className="step-block__form">
              {formTouched?.date && formErrors?.['date.a.time'] && (
                <p className="status-error">
                  Aller:
                  {' '}
                  {formTouched?.date && formErrors?.['date.a.time']}
                </p>
              )}
              {formTouched?.date && formErrors?.['date.r.time'] && (
                <p className="status-error">
                  Retour:
                  {' '}
                  {formTouched?.date && formErrors?.['date.r.time']}
                </p>
              )}
              <div className="row">
                <div className="col col-12">
                  <Address
                    label="Départ"
                    name="from"
                    value={search.from && search.from.description}
                    onClick={(value) => setFieldValue('from', value)}
                    disabled={search.from && typeof search.from.description === 'string' && search.from.description.length > 0}
                    subsidy={subsidy != null ? subsidy.number : null}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-12">
                  <Input
                    label="Arrivée"
                    name="to"
                    value={search.to.description}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-6">
                  <DateInput
                    label="Aller le..."
                    name="date.a.date"
                    error={touched?.date?.a?.date && errors?.date?.a?.date}
                    touched={touched?.date?.a?.date}
                    value={values.date.a.date}
                    disabledDays={{ before: new Date() }}
                    onChange={(date) => {
                      setFieldValue('date.a.date', date);
                      setImmediate(() => setFieldTouched('date.a.date'));
                    }}
                  />
                </div>
                <div className="col col-6">
                  <Select
                    label="Heure"
                    name="date.a.time"
                    error={errors?.date?.a?.time}
                    touched={touched?.date?.a?.time}
                    onChange={(time) => {
                      setFieldValue('date.a.time', time);
                      setImmediate(() => setFieldTouched('date.a.time'));
                    }}
                    value={values.date.a.time}
                    options={hours}
                    placeholder="hh:mm"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-6">
                  <DateInput
                    label="Retour le..."
                    name="date.r.date"
                    error={touched?.date?.r?.date && errors?.date?.r?.date}
                    touched={touched?.date?.r?.date}
                    value={values.date.r.date}
                    disabledDays={{ before: values.date.a.date || new Date() }}
                    month={values.date.a.date}
                    onChange={(date) => {
                      setFieldValue('date.r.date', date);
                      setImmediate(() => setFieldTouched('date.r.date'));
                    }}
                  />
                </div>
                <div className="col col-6">
                  <Select
                    label="Heure"
                    name="date.r.time"
                    error={touched?.date?.r?.time && errors?.date?.r?.time}
                    touched={touched?.date?.r?.time}
                    onChange={(time) => {
                      setFieldValue('date.r.time', time);
                      setImmediate(() => setFieldTouched('date.r.time'));
                    }}
                    value={values.date.r.time}
                    options={hours}
                    placeholder="hh:mm"
                  />
                </div>
              </div>
              { subsidy && subsidy.searchRequest && subsidy.searchRequest.corsican_fares_enabled
              && (
                <>
                  <div className="row m-top corsican-fares">
                    <Checkbox
                      name="corsican_fares"
                      checked={values.corsican_fares}
                      value={(
                        <>
                          Tarif résident Corse Pour
                          {' '}
                          <b>bénéficier</b>
                          {' '}
                          de ces
                          {' '}
                          <b>tarifs</b>
                          {' '}
                          à compter du 1er  mars 2021, vous devez avoir un numéro d'accréditation valide, attribué en  ligne en justifiant d'une résidence fiscale principale et effective en
                          {' '}
                          <b>
                            Corse.
                            <br />
                            {' '}
                            Plus d'information :
                            {' '}
                          </b>
                          {' '}
                          <a href="https://accreditation-resident.com/newspring/public/portal/XK/welcome" rel="noopener noreferrer" target="_blank"> https://accreditation-resident.com/newspring/public/portal/XK/welcome </a>
                          {' '}

                        </>
                      )}
                      onChange={() => setFieldValue('corsican_fares', !values.corsican_fares)}
                    />
                  </div>
                  {values.corsican_fares && (
                  <div className="row">
                    {passengers.map((passenger, index) => (
                      <div className="col col-12">
                        <Input
                          label={`n° Accréditation de ${passenger.firstname} ${passenger.lastname}`}
                          name="corsican_fares_number"
                          touched
                          error={values.travelers[index] && values.travelers[index].corsican_fares_number ? null : 'Ce champ est requis'}
                          value={values.travelers && values.travelers[index] ? values.travelers[index].corsican_fares_number : ''}
                          onChange={(number) => setFieldValue(`travelers[${index}].corsican_fares_number`, number)}
                        />
                      </div>
                    ))}
                    <div className="row corsican-fares-details">
                      Le tarif résident en Corse est accessible aux personnes justifiant d'un lieu de résidence principale en Corse, et effectuant un vol aller retour au départ de la Corse et à destination de Paris-Orly, Marseille, ou Nice.
                      La durée de séjour maximale est de 120 jours (ou 6 mois pour les étudiants résidents).
                    </div>
                  </div>
                  )}
                </>
              )}
              <div className="row">
                <div className="col col-12">
                  <button className="button-main" type="submit">Rechercher</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default Search;
