import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import Switch from 'react-switch';
// Components
import CarRentalSearch from '../../CarRentalSearch';
import Filters from './Filters';
import Sorting from './Sorting';
// Actions
import { setSelectedResult, setSorting, toggleMap } from '../actions';

const propTypes = {
  city: PropTypes.string.isRequired,
  date: PropTypes.shape({
    departure: PropTypes.string.isRequired,
    arrival: PropTypes.string.isRequired,
  }).isRequired,
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySearch: false,
      showFilters: false,
    };
  }

  toggleSearch= () => {
    this.setState({ displaySearch: !this.state.displaySearch });
  }

  render() {
    const {
      city, date, sorting, setSorting, toggleMap, displayMap, sortedByPref, isMobileDevice, selected, setSelected,
    } = this.props;
    const { displaySearch, showFilters } = this.state;

    const setShowFilters = (value) => this.setState({ showFilters: value });
    // We use moment to get the number of days between the arrival and the departure
    const arrival = moment(date.a.date);
    const departure = moment(date.r.date);

    const searchClasses = classNames('search-block offers__search', { show: displaySearch });
    return (
      <>
        <div
          className={searchClasses}
        >
          <CarRentalSearch results />
        </div>
        <div className="hotel-results-header m-auto m-top p-left">
          <div className="title">
            <h1>{`${window.i18('CAR_RENTALS_IN')} ${city}`}</h1>
            <h2 className="p-left">
              {`${window.i18('FROM_THE')} ${arrival.format('DD/MM')} ${window.i18('TO_THE')} ${departure.format('DD/MM')}`}
            </h2>
            {!isMobileDevice && (
            <button className="button-secondary m-left" onClick={this.toggleSearch} ref={this.modify}>
              {window.i18('EDIT_SEARCH')}
            </button>
            )}
            {isMobileDevice && selected && (
              <button className="button-secondary" onClick={setSelected}>{window.i18('RETURN_TO_LIST')}</button>
            )}
          </div>
          <div className="hotel-results-header__filters m-right">
            <div className="hotel-filters">
              {isMobileDevice ? (
                <div className="user-tickets-filter" style={{ marginBottom: '5px' }}>
                  <button className={`user-tickets-filter__toggle common-sub-title ${showFilters ? 'on' : 'off'}`} onClick={() => setShowFilters(!showFilters)}>{window.i18('FILTER_AND_SORT')}</button>
                  <div className={`user-tickets-filter__container ${showFilters ? 'show' : 'hide'}`} id="results-filters">
                    <Filters />
                    <Sorting sorting={sorting} setSorting={setSorting} isMobileDevice={isMobileDevice} sortedByPref={sortedByPref} />
                    <button className="button-main" onClick={() => setShowFilters(!showFilters)}>{window.i18('CONFIRM')}</button>
                  </div>
                </div>
              ) : (
                <>
                  <Filters />
                  <Sorting sorting={sorting} setSorting={setSorting} sortedByPref={sortedByPref} />
                </>
              )}
            </div>
            {!isMobileDevice
            && (
            <div className="display-map">
              <p className="m-right">{window.i18('DISPLAY_ON_MAP')}</p>
              <Switch
                onChange={toggleMap}
                checked={displayMap}
                onColor="#f18888"
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

Header.propTypes = propTypes;

const mapStateToProps = (state) => ({
  city: state.carRentalResults.search.pickUpLocation.location.city,
  date: state.carRentalResults.search.date,
  sorting: state.carRentalResults.sorting,
  displayMap: state.carRentalResults.displayMap,
  sortedByPref: state.carRentalResults.sortedByPref,
  isMobileDevice: state.app.isMobileDevice,
  selected: state.carRentalResults.selectedResult.id,
});

const mapDispatchToProps = (dispatch) => ({
  setSorting: (sorting) => dispatch(setSorting(sorting)),
  toggleMap: () => dispatch(toggleMap()),
  setSelected: () => dispatch(setSelectedResult(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
