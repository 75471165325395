import React from 'react';
import moment from 'moment';

const hotelSearchForm = ({
  search: {
    date, passengers, errors, touched, rooms, nameContains,
  },
  setSelected,
  selected,
  place,
  changeSearchLocation,
  handleSearchBlur,
  searchHotels,
  radius,
  filters,
  setEntity,
  entities,
  entityID,
}) => (
  <div className="search-form__inputs hotel-search-form">
    <span
      className="hotel-place-input"
      data-radius={`${window.i18('RADIUS')} < ${radius}km`}
    >
      <input
        className={`search-form__inputs__single  ${
          selected === 'place' ? 'selected' : ''
        } ${
          errors && errors['from.ID'] && touched.place ? 'input-errors' : ''
        }`}
        type="text"
        placeholder={window.i18('I_SLEEP_IN')}
        onFocus={() => setSelected('place')}
        onChange={(e) => changeSearchLocation(e.target.value, 'place')}
        value={place.value}
        onBlur={() => handleSearchBlur('place')}
      />
    </span>
    <div id="place-portal" />
    {place.searching && <span className="loading place" />}
    <div className="search-form__nights-container">
      <div
        className={`search-form__inputs__group ${
          selected === 'out' || selected === 'return' ? 'selected' : ''
        } ${errors && errors['date.arrival'] && touched.date ? 'errors' : ''}`}
      >
        {errors && errors['date.arrival'] && touched.date && (
          <span className="search-form__error top left">
            {errors['date.arrival']}
          </span>
        )}
        <button
          className={`search-form__inputs__group__input ${
            selected === 'out' ? 'selected' : ''
          } ${
            errors && errors['date.arrival'] && touched.date
              ? 'input-errors'
              : ''
          }`}
          onFocus={() => setSelected('out')}
          onClick={() => setSelected('out')}
        >
          <p>
            {window.i18('START')}
            {' '}
            {date.arrival
              ? `${window.i18('ON')} ${moment(date.arrival)
                .format('DD/MM/YYYY')}`
              : window.i18('THE_STAY_ON')}
          </p>
        </button>
        <div id="arrival-portal" />
        <button
          className={`search-form__inputs__group__input ${
            selected === 'return' ? 'selected' : ''
          } ${
            errors && errors['date.r.time'] && touched.date
              ? 'input-errors'
              : ''
          }`}
          onFocus={() => setSelected('return')}
          onClick={() => setSelected('return')}
        >
          <p>
            {window.i18('END')}
            {' '}
            {date.departure
              ? `${window.i18('ON')} ${moment(date.departure)
                .format('DD/MM/YYYY')}`
              : window.i18('THE_STAY_ON')}
          </p>
        </button>
        <div id="departure-portal" />

        {errors && errors['date.departure'] && touched.departure && (
          <span className="search-form__error bot">
            {errors['date.departure']}
          </span>
        )}
      </div>
      <div className="search-form__nights">
        <p>
          {(date.departure
            && date.arrival
            && moment(date.departure).diff(moment(date.arrival), 'days'))
          || 0}
        </p>
        <p>
          {(date.departure
            && date.arrival
            && moment(date.departure).diff(moment(date.arrival), 'days')) > 1
            ? window.i18('NIGHTS')
            : window.i18('NIGHT')}
        </p>
      </div>
    </div>
    <button
      className={`search-form__inputs__single ${
        selected === 'filters' ? 'selected' : ''
      } ${errors && errors.filters ? 'errors' : ''}`}
      onFocus={() => setSelected('filters')}
      onClick={() => setSelected('filters')}
    >
      {filters.stars === 0 && filters.breakfast === false && !nameContains
        ? (
          <p>{window.i18('ANY_HOTEL')}</p>
        )
        : (
          <p>
            {nameContains && `${window.i18('NAME_CONTAINS')} "${nameContains}"`}
            {filters.stars > 0
            && `${nameContains ? ', ' : ''}${filters.stars} ${filters.stars > 1
              ? window.i18('STARS')
              : window.i18('STAR')} min`}
            {filters.breakfast
            && `${nameContains || filters.stars > 0 ? ', ' : ''}${window.i18(
              'BREAKFAST_INCLUDED',
            ).toLowerCase()}`}
          </p>
        )}
    </button>
    <div id="filters-portal" />
    <button
      className={`search-form__inputs__single ${
        selected === 'passengers' ? 'selected' : ''
      } ${errors && errors.passengers ? 'errors' : ''}`}
      onFocus={() => setSelected('passengers')}
      onClick={() => setSelected('passengers')}
    >
      <p>
        {(passengers.filter((p) => p.selected).length === 1
          && passengers.filter((p) => p.selected)[0].firstname)
          ? passengers.filter((p) => p.selected)[0].firstname
          : `${passengers.filter((p) => p.selected).length} ${passengers.filter(
            (p) => p.selected,
          ).length > 1 ? window.i18('PERSONS') : window.i18(
              'PERSON',
            )}`}
      </p>
      <p>
        {window.i18('IN')}
        {' '}
        {rooms}
        {' '}
        {rooms > 1 ? window.i18('ROOMS') : window.i18('ROOM')}
      </p>
      {errors && errors.passengers && (
        <span className="search-form__error bot">{errors.passengers}</span>
      )}
    </button>
    <div id="passengers-portal" />
    <div className="search-form__last-line">
      {entities && entities.length > 0 && (
        <>
          <select
            name="entityID"
            placeholder={window.i18('ON_THE_ACCOUNT_OF')}
            className={`search-form__inputs__single search-form__entity-select ${
              selected === 'entityID' ? 'selected' : ''
            } ${errors && errors.entityID && touched.entityID ? 'errors' : ''}`}
            onChange={(e) => setEntity(e.target.value)}
            required
            value={entityID || ''}
          >
            <option value="" disabled hidden>
              {window.i18(
                'ON_THE_ACCOUNT_OF',
              )}
            </option>
            {entities
            && entities.map((entity) => (
              <option key={entity.uid} value={entity.uid}>
                {entity.name}
              </option>
            ))}
          </select>
          {errors && touched.entityID && errors.entityID && (
            <span className="search-form__error bot">{errors.entityID}</span>
          )}
        </>
      )}
      <button
        className="search-form__inputs__submit button-main"
        onClick={searchHotels}
      >
        {window.i18('SEARCH_BTN')}
      </button>
    </div>
  </div>
);

export default hotelSearchForm;
