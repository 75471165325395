// eslint-disable-next-line max-classes-per-file
import _ from 'lodash/fp';
import React from 'react';

class Queue {
  constructor() {
    this.data = [];
    this.index = 0;
  }

  queue(value) {
    this.data.push(value);
  }

  dequeue() {
    if (this.index > -1 && this.index < this.data.length) {
      // eslint-disable-next-line no-plusplus
      const result = this.data[this.index++];

      if (this.isEmpty) {
        this.reset();
      }

      return result;
    }
  }

  get isEmpty() {
    return this.index >= this.data.length;
  }

  dequeueAll(cb) {
    if (!_.isFunction(cb)) {
      throw new Error('Please provide a callback');
    }

    while (!this.isEmpty) {
      const { method, args } = this.dequeue();
      cb(method, args);
    }
  }

  reset() {
    this.data.length = 0;
    this.index = 0;
  }
}

let fakeWidget;
const earlyCalls = new Queue();

export const widget = (fake = fakeWidget) => {
  if (window.fcWidget) return window.fcWidget;
  if (!fake) fake = mockMethods(availableMethods);
  return fake;
};

let mockMethods = (methods) => {
  let obj = {};
  methods.forEach((method) => {
    obj = _.set(method, queueMethod(method), obj);
  });
  return obj;
};

let queueMethod = (method) => (...args) => {
  earlyCalls.queue({ method, args });
};

const loadScript = () => {
  const id = 'freshchat-lib';
  if (document.getElementById(id) || window.fcWidget) return;
  const script = document.createElement('script');
  script.async = 'true';
  script.type = 'text/javascript';
  script.src = 'https://wchat.eu.freshchat.com/js/widget.js';
  script.id = id;
  document.head.appendChild(script);
};

class FreshChat extends React.Component {
  constructor(props) {
    super(props);

    const { token, ...moreProps } = props;

    this.init({
      host: process.env.REACT_APP_FRESHCHAT_HOST,
      token: process.env.REACT_APP_FRESHCHAT_TOKEN,
      ...moreProps,
    });
  }

  componentWillUnmount() {
    widget().close();
  }

  init(settings) {
    if (settings.onInit) {
      const tmp = settings.onInit;
      settings.onInit = () => tmp(widget());
    }

    if (window.fcWidget) {
      window.fcWidget.init(settings);
      if (settings.onInit) {
        settings.onInit();
      }
    } else {
      this.lazyInit(settings);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  lazyInit(settings) {
    widget().init(settings);

    loadScript();

    const interval = setInterval(() => {
      if (window.fcWidget) {
        clearInterval(interval);
        try {
          earlyCalls.dequeueAll((method, value) => {
            window.fcWidget[method](...value);
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
        if (settings.onInit) {
          settings.onInit();
        }
      }
    }, 1000);
  }

  render() {
    return false;
  }
}

let availableMethods = [
  'close',
  'destroy',
  'hide',
  'init',
  'isInitialized',
  'isLoaded',
  'isOpen',
  'off',
  'on',
  'open',
  'setConfig',
  'setExternalId',
  'setFaqTags',
  'setTags',
  'track',
  'user.show',
  'user.track',
  'user.user',
  'user.clear',
  'user.create',
  'user.get',
  'user.isExists',
  'user.setEmail',
  'user.setFirstName',
  'user.setLastName',
  'user.setMeta',
  'user.setPhone',
  'user.setPhoneCountryCode',
  'user.setProperties',
  'user.update',
];

export default FreshChat;
