import moment from 'moment';
import { capitalize } from './functions';

export function formatSeconds(input) {
  if (!input) { return; }

  const hours = String(Math.floor(input / 3600));
  input %= 3600;

  let minutes = String(Math.floor(input / 60));

  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return hours !== '0' ? `${hours}h${minutes}` : `${minutes} min`;
}

export function formatSecondsToMinutes(input) {
  const minutes = String(Math.floor(input / 60));
  return `${minutes} min`;
}

// Takes a badly formatted & so called "RFC3339" date from the API
// And converts it to a real one (adds dashes)
// Truncate = true => returns only the date part
export function formatDateFromAPI(date, truncate = false) {
  let construct = '';

  // Constructs the "date"
  construct = `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;

  if (truncate) { return construct; }

  // Adds the T
  construct += 'T';
  // Constructs the time
  construct += `${date.substring(9, 11)}-${date.substring(11, 13)}-${date.substring(13, 15)}`;

  return construct;
}

export function formatJSDateFromAPI(date) {
  return moment(date).toDate();
}

// Takes a good ole RFC3339 date, and returns it invalid for the API.
// (we just remove the dashes !)
// We now also remove the : and the .000Z at the end
export function formatDateForAPI(date) {
  if (!date) {
    return '';
  }
  if (date instanceof Date) {
    date = date.toJSON();
  }
  return date.replace('-', '').replace('-', '').replace(':', '').replace(':', '')
    .replace('.000Z', ''); // Two differents dashes ;)
}

export function frenchDateToAPI(date) {
  if (!date) {
    return '';
  }
  return moment(date).format('YYYYMMDDT120000');
}

export function formatApiDateToDisplay(date) {
  return moment(date).format('DD/MM/YYYY');
}

// Takes a time string with a format of "HHmmSS" (like our TimePicker)
// And returns a formatted object with H, M, S
// Can be used to pass to moment.
export function formatTimeToObject(time) {
  return {
    hours: time.substring(0, 2),
    minutes: time.substring(2, 4),
    seconds: time.substring(4, 6),
  };
}

// We're converting the CO2 in kg
export function convertCO2(input) {
  if (Math.floor(input / 1000) < 1) { return `${Math.abs((input / 1000).toFixed(1)) || 0} kg`; }
  return `${Math.abs(Math.floor(input / 1000)) || 0} kg`;
}

export function displayPrice(price) {
  if (price === null) { return; }

  return `${price.toFixed(2).replace(/[.,]00$/, '').replace('.', ',')}€`;
}

export function formatTransportModeName(name) {
  switch (name) {
    case 'rail':
      return window.i18('TRAIN');
    case 'flight':
      return window.i18('FLIGHT');
    case 'boat':
      return window.i18('BOAT');
    case 'car_rental':
      return window.i18('CAR_RENTAL');
    case 'hotel':
      return window.i18('HOTEL');
    default:
      return name;
  }
}

export function formatCarType(type) {
  switch (type) {
    case 'TP':
      return window.i18('TP');
    case 'TM':
      return window.i18('TM');
    case 'TG':
      return window.i18('TG');
    case 'UP':
      return window.i18('UP');
    case 'UM':
      return window.i18('UM');
    case 'UG':
      return window.i18('UG');
    default:
      return window.i18('UNKNOWN');
  }
}

export function formatStatus(status) {
  switch (status) {
    case 'error':
      return {
        name: window.i18('ERRORED'),
        icon: 'cross',
        color: 'dark',
      };
    case 'cancelled':
    case 'not_found':
      return {
        name: window.i18('CANCELLED'),
        icon: 'cross',
        color: 'red',
      };
    case 'pending_cancel':
      return {
        name: window.i18('PENDING_CANCEL'),
        sign: '!',
        color: 'blue',
      };
    case 'partially_cancelled':
      return {
        name: window.i18('PARTIALLY_CANCELLED'),
        sign: '!',
        color: 'blue',
      };
    case 'pending_confirmation':
      return {
        name: window.i18('PENDING_CONFIRMATION'),
        sign: '?',
        color: 'blue',
      };
    case 'pending_approval':
      return {
        name: window.i18('PENDING_APPROVAL'),
        sign: '?',
        color: 'blue',
      };
    case 'denied':
      return {
        name: capitalize(window.i18('DENIED')),
        icon: 'cross',
        color: 'red',
      };
    case 'cart':
      return {
        name: window.i18('IN_CART'),
        sign: '?',
        color: 'blue',
      };
    case 'partially_booked':
      return {
        name: window.i18('PARTIALLY_BOOKED'),
        sign: '!',
        color: 'blue',
      };
    case 'booked':
    case 'confirmed':
    case 'ticketed':
    case 'done':
    case 'issued':
      return {
        name: window.i18('ISSUED'),
        icon: 'check',
        color: 'green',
      };
    case 'partially_confirmed':
      return {
        name: window.i18('PARTIALLY_CONFIRMED'),
        sign: '!',
        color: 'blue',
      };
    case 'pending_issuing':
    case 'pending_ticketing':
      return {
        name: window.i18('PENDING_TICKETING'),
        sign: '!',
        color: 'blue',
      };
    case 'partially_issued':
    case 'partially_ticketed':
      return {
        name: window.i18('PARTIALLY_TICKETED'),
        sign: '!',
        color: 'blue',
      };
    case 'pending_payment':
      return {
        name: window.i18('PENDING_PAYMENT'),
        sign: '!',
        color: 'blue',
      };
    case 'paid':
      return {
        name: window.i18('PAID'),
        icon: 'check',
        color: 'green',
      };
    case 'pending_refund':
      return {
        name: window.i18('PENDING_REFUND'),
        sign: '!',
        color: 'blue',
      };
    case 'partially_refunded':
      return {
        name: window.i18('PARTIALLY_REFUNDED'),
        sign: '!',
        color: 'blue',
      };
    case 'refunded':
      return {
        name: window.i18('REFUNDED'),
        icon: 'check',
        color: 'blue',
      };
    case 'removed':
      return {
        name: window.i18('REMOVED'),
        icon: 'cross',
        color: 'dark',
      };
    case 'modified':
      return {
        name: window.i18('MODIFIED'),
        sign: '!',
        color: 'blue',
      };
    case 'archived':
      return {
        name: window.i18('ARCHIVED'),
        sign: '!',
        color: 'blue',
      };
    case 'unknown':
    default:
      return {
        name: window.i18('UNKNOWN_STATUS'),
        sign: '?',
        color: 'blue',
      };
  }
}

export const getTimeLeft = (dateTime) => {
  const days = moment(dateTime).diff(moment(), 'days');
  const hours = moment(dateTime).diff(moment(), 'hours');
  const minutes = moment(dateTime).diff(moment(), 'minutes');
  if (days > 0) {
    return `${days}j`;
  }
  if (days === 0 && hours > 0) {
    return `${hours}h`;
  }
  if (hours === 0 && minutes > 0) {
    return `${minutes}mn`;
  }
  return null;
};

export function shortUserName(user) {
  let name = '';

  if (user.firstname && !user.lastname) {
    name += user.firstname;
  } else if (user.firstname && user.lastname) {
    name += `${user.firstname.charAt(0)}. ${user.lastname}`;
  } else if (user.lastname) {
    name += user.lastname;
  }
  return name;
}

export function formatDocumentType(type) {
  switch (type) {
    case 'passport':
      return window.i18('PASSPORT');
    case 'id_card':
      return window.i18('ID_CARD');
    case 'driver_license':
      return window.i18('DRIVER_LICENSE');
    default:
      return capitalize(window.i18('UNKNOWN'));
  }
}

export function formatTicketTags(tag) {
  switch (tag) {
    case 'compliant':
      return window.i18('CONFORM');
    case 'not-compliant':
      return window.i18('NOT_CONFORM');
    case 'negociated-fare':
      return window.i18('NEGOCIATED_FARE');
    case 'ngo-fare':
      return window.i18('NGO_FARE');
    default:
      return null;
  }
}

export function formatCabinClass(cabinClass) {
  switch (cabinClass) {
    case 'Première':
      return window.i18('FIRST_CLASS');
    case 'Seconde':
      return window.i18('SECOND_CLASS');
    case 'Économique':
      return window.i18('ECONOMY_CLASS');
    case 'Économique Premium':
      return window.i18('PREMIUM_ECONOMY_CLASS');
    default:
      return cabinClass;
  }
}

export function levelToColor(level) {
  const colors = ['#00B59C', '#DAD44F', '#F1BA88', '#F1A188', '#EB5D5D'];
  return colors[level - 1];
}

export function formatFlexibility(f) {
  const flex = [window.i18('UNKNOWN'), 'No-Flex', 'Semi-Flex', 'Full-Flex'];
  return flex[f];
}
