import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Radio from '../../../components/Radio';
import { setLang } from '../../App/actions';

const UserLanguage = () => {
  const lang = useSelector((state) => state.app.lang);
  const dispatch = useDispatch();
  const setLanguage = (lang) => {
    dispatch(setLang(lang));
    localStorage.setItem('theTreepLang', lang);
  };
  return (
    <div className="common-wrapper">
      <h3>{window.i18('APP_LANG')}</h3>
      <div className="m-top p-top">
        <Radio label={window.i18('ENGLISH')} id="en" field={{ value: lang, name: 'language', onChange: () => { setLanguage('en'); } }} />
      </div>
      <div className="m-top">
        <Radio label={window.i18('FRENCH')} id="fr" field={{ value: lang, name: 'language', onChange: () => { setLanguage('fr'); } }} />
      </div>
    </div>
  );
};

export default UserLanguage;
