export const SEARCH_CAR_RENTALS_START = 'carRentalResults/SEARCH_CAR_RENTALS_START';
export const SEARCH_CAR_RENTALS_ERROR = 'carRentalResults/SEARCH_CAR_RENTALS_ERROR';
export const SEARCH_CAR_RENTALS_SUCCESS = 'carRentalResults/SEARCH_CAR_RENTALS_SUCCESS';
export const SET_SEARCHED_CAR_RENTALS = 'carRentalResults/SET_SEARCHED_CAR_RENTALS';
export const RESET_RESULTS = 'carRentalResults/RESET_RESULTS';
export const SET_SELECTED_RESULT = 'carRentalResults/SET_SELECTED_RESULT';
export const ADD_TO_CART_START = 'carRentalResults/ADD_TO_CAR_START';
export const ADD_TO_CART_ERROR = 'carRentalResults/ADD_TO_CAR_ERROR';
export const ADD_TO_CART_SUCCESS = 'carRentalResults/ADD_TO_CAR_SUCCESS';
export const EDIT_DRIVER = 'carRentalResults/EDIT_DRIVER';
export const SET_PRICES = 'carRentalResults/SET_PRICES';
export const SET_PRICE_RANGE = 'carRentalResults/SET_PRICE_RANGE';
export const SET_EXCULDED_CAR_TYPES = 'carRentalResults/SET_EXCLUDED_CAR_TYPES';
export const SET_SORTING = 'carRentalResults/SET_SORTING';
export const TOGGLE_MAP = 'carRentalResults/TOGGLE_MAP';
export const SET_SELECTED_MARKER = 'carRentalResults/SET_SELECTED_MARKER';
export const TOGGLE_UNLIMITED_KM = 'carRentalResults/TOGGLE_UNLIMITED_KM';
export const SET_EXCLUDED_RENTERS = 'carRentalResults/SET_EXCLUDED_RENTERS';
export const TOGGLE_NEGOCIATED = 'carRentalResults/TOGGLE_NEGOCIATED';
export const TOGGLE_LOYALTY = 'carRentalResults/TOGGLE_LOYALTY';
