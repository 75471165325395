import { sortBy } from 'lodash-es';
import { toast as Notif } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {
  FETCH_USER_TICKETS_BEGIN,
  FETCH_USER_TICKETS_ERROR,
  FETCH_USER_TICKETS_SUCCESS,
  FETCH_USER_ARCHIVES_BEGIN,
  FETCH_USER_ARCHIVES_ERROR,
  FETCH_USER_ARCHIVES_SUCCESS,
  FETCH_USER_DOCUMENTS_BEGIN,
  FETCH_USER_DOCUMENTS_ERROR,
  FETCH_USER_DOCUMENTS_SUCCESS,
  FETCH_USER_APPROVALS_BEGIN,
  FETCH_USER_APPROVALS_ERROR,
  FETCH_USER_APPROVALS_SUCCESS,
  SET_USER_DOCUMENTS_BEGIN,
  SET_USER_DOCUMENTS_SUCCESS,
  SET_USER_DOCUMENTS_ERROR,
  FETCH_USER_CARDS_BEGIN,
  FETCH_USER_CARDS_ERROR,
  FETCH_USER_CARDS_SUCCESS,
  SET_USER_CARDS_BEGIN,
  SET_USER_CARDS_ERROR,
  SET_USER_CARDS_SUCCESS,
  GET_CDS_URL_BEGIN,
  GET_CDS_URL_SUCCESS,
  GET_CDS_URL_ERROR,
  SET_SELECTED_PENDING,
  SET_SELECTED_TICKETS,
  SET_SELECTED_APPROVALS,
  SENDING_APPROVAL_BEGIN,
  SENDING_APPROVAL_ERROR,
  SENDING_APPROVAL_SUCCESS,
  SENDING_CANCEL,
  SENT_CANCEL,
} from './constants';

import { openModal, closeModal } from '../ModalWrapper/actions';

import API from '../../utils/api';
import { getPaymentInfos } from '../Cart/actions';
import { logout } from '../App/actions';

export function getUserTickets() {
  return (dispatch, getState) => {
    if (getState().account.userTickets.loading) { return; }
    dispatch(getPaymentInfos()); // Payment infos are needed to calculate the price of the tickets when confirming an option
    dispatch({ type: FETCH_USER_TICKETS_BEGIN });
    const userID = getState().app.user ? getState().app.user.uid : 0;
    return API.get(`tickets/?active=true&creator_id=${userID}`).then((result) => {
      let tickets = result.data.result;
      // We sort the treeps
      tickets = sortBy(tickets, 'departure').reverse();
      // We add a truely unique guuid to differentiate treeps
      tickets = tickets.map((ticket) => {
        const uuid = uuidv4();
        return ({ ...ticket, guuid: uuid });
      });
      // We separate pending tickets from the rest
      const pendingTickets = [];
      const restOfTickets = [];
      tickets.map((ticket) => {
        if (['pending_approval', 'pending_confirmation'].includes(ticket.status)) {
          pendingTickets.push(ticket);
        } else {
          restOfTickets.push(ticket);
        }
        return null;
      });
      dispatch({
        type: FETCH_USER_TICKETS_SUCCESS,
        pendingTickets,
        restOfTickets,
      });
    }, (error) => {
      // Do not need this because we are catching everything localy now. See api.js interceptors
      /*
      Notif.warning(window.i18('ERROR_LOADING_BOOKINGS'));
      */
      dispatch({
        type: FETCH_USER_TICKETS_ERROR,
        error,
      });
    });
  };
}

export function getUserApprovals() {
  return (dispatch, getState) => {
    if (getState().account.userApprovals.loading) { return; }
    dispatch({ type: FETCH_USER_APPROVALS_BEGIN });
    return API.get('/approval/').then((result) => {
      const tickets = result.data.result;
      dispatch({
        type: FETCH_USER_APPROVALS_SUCCESS,
        tickets,
      });
    }, (error) => {
      // Do not need this because we are catching everything localy now. See api.js interceptors
      /*
      Notif.warning(window.i18('ERROR_LOADING_APPROVALS'));
      */
      dispatch({
        type: FETCH_USER_APPROVALS_ERROR,
        error,
      });
    });
  };
}

export function resetCheckedPending() {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_PENDING,
      selected: [],
    });
  };
}

export function toggleCheckAllPending(pending) {
  return (dispatch, getState) => {
    const previous = getState().account.userTickets.pendingSelected;
    if (!previous || previous.length < pending.length) { // select all
      dispatch({
        type: SET_SELECTED_PENDING,
        selected: pending.map((tk) => tk.uid),
      });
    } else { // unselect all
      dispatch(resetCheckedPending());
    }
  };
}

export function resetCheckedTickets() {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_TICKETS,
      selected: [],
    });
  };
}

export function resetCheckedApprovals() {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_APPROVALS,
      selected: [],
    });
  };
}

export function toggleCheckAllTickets(tickets) {
  return (dispatch, getState) => {
    const disabledStatuses = ['cancelled', 'error', 'denied'];
    const filteredTickets = tickets.filter((tk) => !disabledStatuses.includes(tk.status));
    const previous = getState().account.userTickets.selected;
    if (!previous || previous.length < filteredTickets.length) { // select all
      dispatch({
        type: SET_SELECTED_TICKETS,
        selected: filteredTickets.map((tk) => tk.uid),
      });
    } else { // unselect all
      dispatch(resetCheckedTickets());
    }
  };
}

export function toggleCheckAllApprovals(tickets) {
  return (dispatch, getState) => {
    const previous = getState().account.userApprovals.selected;
    if (!previous || previous.length < tickets.length) { // select all
      dispatch({
        type: SET_SELECTED_APPROVALS,
        selected: tickets.map((tk) => tk.uid),
      });
    } else { // unselect all
      dispatch(resetCheckedApprovals());
    }
  };
}

export function toggleCheckPending(id) {
  return (dispatch, getState) => {
    let selected = getState().account.userTickets.pendingSelected;
    if (isChecked(id, selected)) {
      selected = selected.filter((tkID) => tkID !== id);
    } else {
      selected.push(id);
    }
    dispatch({
      type: SET_SELECTED_PENDING,
      selected,
    });
  };
}

export function toggleCheckApproval(id) {
  return (dispatch, getState) => {
    let { selected } = getState().account.userApprovals;
    if (isChecked(id, selected)) {
      selected = selected.filter((tkID) => tkID !== id);
    } else {
      selected.push(id);
    }
    dispatch({
      type: SET_SELECTED_APPROVALS,
      selected,
    });
  };
}

export function toggleCheckTicket(id) {
  return (dispatch, getState) => {
    let { selected } = getState().account.userTickets;
    if (isChecked(id, selected)) {
      selected = selected.filter((tkID) => tkID !== id);
    } else {
      selected.push(id);
    }
    dispatch({
      type: SET_SELECTED_TICKETS,
      selected,
    });
  };
}

export function isChecked(id, checked) {
  if (!checked || checked.length === 0) {
    return false;
  }
  return checked.includes(id);
}

export function getUserArchives() {
  return (dispatch, getState) => {
    if (getState().account.userTickets.loading) { return; }
    dispatch(getPaymentInfos()); // Payment infos are needed to calculate the price of the tickets when confirming an option
    dispatch({ type: FETCH_USER_ARCHIVES_BEGIN });
    const userID = getState().app.user ? getState().app.user.uid : 0;
    return API.get(`tickets/?passed=true&creator_id=${userID}`).then((result) => {
      let tickets = result.data.result;
      // We sort the treeps
      tickets = sortBy(tickets, 'departure').reverse();
      // We add a truely unique guuid to differentiate treeps
      tickets = tickets.map((ticket) => {
        const uuid = uuidv4();
        return ({ ...ticket, guuid: uuid });
      });
      dispatch({
        type: FETCH_USER_ARCHIVES_SUCCESS,
        tickets,
      });
    }, (error) => {
      // Do not need this because we are catching everything localy now. See api.js interceptors
      /*
      Notif.warning(window.i18('ERROR_LOADING_BOOKINGS'));
      */
      dispatch({
        type: FETCH_USER_ARCHIVES_ERROR,
        error,
      });
    });
  };
}

export function promptCancelTickets() {
  return (dispatch, getState) => {
    const { selected, list } = getState().account.userTickets;
    const { airOfflineCancellation, railOfflineCancellation } = getState().app.user?.company?.config?.travelAgency;
    const toCancel = [];
    const types = [];
    const conditions = [];
    selected.forEach((id) => {
      list.forEach((tk) => {
        if (tk.uid === id) {
          conditions.push(tk.fare_condition);
          toCancel.push(id);
          types.push(tk.type);
        }
      });
    });
    dispatch(openModal('CancelConfirm', {
      airOfflineCancellation,
      railOfflineCancellation,
      conditions,
      types,
      email: getState().app.user.email,
      confirm: () => dispatch(deleteTicketsFromTreep(toCancel)),
    }));
  };
}

export function promptAnotherApprover() {
  return (dispatch, getState) => {
    const { pendingSelected } = getState().account.userTickets;
    const { pendingList } = getState().account.userTickets;
    const { approvalID } = pendingList.filter((ticket) => ticket.uid === pendingSelected[0])[0];
    const { approvers } = getState().app.user;
    dispatch(openModal('ApproversList', {
      approvers,
      addingNew: true,
      handleSubmit: (username) => {
        API.put('/approvals/secondaryApprover', {
          approvalID,
          approverUsername: username,
        })
          .then(() => {
            dispatch(closeModal());
            dispatch(getUserTickets());
            Notif.success(`${window.i18('APPROVAL_REQUEST_SENT_TO')} ${username}`);
          }, () => {
            dispatch(closeModal());
            // Do not need this because we are catching everything localy now. See api.js interceptors
            /*
            Notif.warning(`${window.i18('PROBLEM_DURING_APPROVAL')}: ${friendlyError(error)}`, '', 0);
            */
          });
      },
    }));
  };
}

export function promptAskAgainApprover() {
  return (dispatch, getState) => {
    const { pendingSelected, pendingList } = getState().account.userTickets;
    // We check approvers for each selected tickets, and class them by approver (to prepare for the API call)
    const approvers = [];
    pendingSelected.forEach((pendingSelected) => {
      const ticket = pendingList.find((ticket) => ticket.uid === pendingSelected);
      if (ticket.secondary_approver) {
        const existing = approvers.find((a) => a.approver === ticket.secondary_approver.username);
        if (existing) {
          existing.ticket_ids.push(ticket.uid);
        } else {
          approvers.push({
            approver: ticket.secondary_approver.username,
            ticket_ids: [ticket.uid],
          });
        }
      }
      const existing = approvers.find((a) => a.approver === ticket.main_approver?.username);
      if (existing) {
        existing.ticket_ids.push(ticket.uid);
      } else {
        approvers.push({
          approver: ticket.main_approver?.username,
          ticket_ids: [ticket.uid],
        });
      }
    });
    dispatch(openModal('Confirm', {
      message: window.i18('SURE_REMIND_APPROVER'),
      confirm: () => {
        const calls = approvers.map((params) => API.post('/approval/add', params));
        Promise.all(calls)
          .then(() => {
            Notif.success(window.i18('APPROVER_REMINDED'));
          });
        // Do not need this because we are catching everything localy now. See api.js interceptors
        /*
          .catch((err) => {
            Notif.warning(friendlyError(err));
          });
          */
      },
    }));
  };
}

export function promptCancelPending() {
  return (dispatch, getState) => {
    const { pendingSelected, pendingList } = getState().account.userTickets;
    const { airOfflineCancellation, railOfflineCancellation } = getState().app.user?.company?.config?.travelAgency;
    const types = [];
    const conditions = [];
    pendingSelected.forEach((id) => {
      pendingList.forEach((tk) => {
        if (tk.uid === id) {
          conditions.push(tk.fare_condition);
          types.push(tk.type);
        }
      });
    });
    dispatch(openModal('CancelConfirm', {
      airOfflineCancellation,
      railOfflineCancellation,
      conditions,
      types,
      message: window.i18('CONFIRM_DELETE'),
      confirm: () => {
        dispatch({ type: SENDING_CANCEL });
        API.post('/approval/cancel', {
          ticket_ids: pendingSelected,
        })
          .then(() => {
            dispatch({ type: SENT_CANCEL });
            dispatch(getUserTickets());
            Notif.success(window.i18('TICKETS_DELETED'));
          }, () => {
            dispatch({ type: SENT_CANCEL });
            // Do not need this because we are catching everything localy now. See api.js interceptors
            /*
            Notif.warning(window.i18('PROBLEM_DELETING'));
            */
          });
      },
    }));
  };
}

export function promptApproveTicket(approvalID, request, escalate = false, approvers) {
  return (dispatch) => {
    dispatch(openModal('Approbation', {
      approvalID, request, escalate, approvers,
    }));
  };
}

export function sendingApprovalBegin(approvalID) {
  return ({ type: SENDING_APPROVAL_BEGIN, approvalID });
}

export function sendingApprovalSuccess(approvalID) {
  return ({ type: SENDING_APPROVAL_SUCCESS, approvalID });
}

export function sendingApprovalError(approvalID) {
  return ({ type: SENDING_APPROVAL_ERROR, approvalID });
}

export function promptRejectTicket(approvalID) {
  return (dispatch) => {
    dispatch(openModal('DenyApprovalRequest', {
      approval: approvalID,
    }));
  };
}

export function deleteTicketsFromTreep(tickets) {
  return (dispatch) => {
    dispatch({ type: SENDING_CANCEL });
    return API.post(
      '/treep/cancel',
      { ticket_ids: tickets },
    ).then(() => {
      dispatch({ type: SENT_CANCEL });
      dispatch(getUserTickets());
      Notif.success(window.i18('DELETE_REQUEST_SENT'));
    }, () => {
      dispatch({ type: SENT_CANCEL });
      dispatch(closeModal());
      // Do not need this because we are catching everything localy now. See api.js interceptors
      /*
      Notif.warning(`${window.i18('PROBLEM_DURING_DELETING')}: ${friendlyError(error)}`, '', 0);
      */
    });
  };
}

export function fetchUserDocuments() {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_DOCUMENTS_BEGIN });

    API.get('user/documents').then((response) => {
      const documents = response.data && response.data.result;
      dispatch({
        type: FETCH_USER_DOCUMENTS_SUCCESS,
        documents,
      });
    }, (error) => {
      dispatch({
        type: FETCH_USER_DOCUMENTS_ERROR,
        error: error.data,
      });
    });
  };
}

export function setDocuments(documents) {
  return (dispatch, getState) => {
    dispatch({ type: SET_USER_DOCUMENTS_BEGIN });
    API.put('user/documents', { travel_documents: documents }).then(() => {
      dispatch({
        type: SET_USER_DOCUMENTS_SUCCESS,
        documents,
        uid: getState().app.user.uid,
      });
      Notif.success(window.i18('DOCUMENTS_UPDATED'));
    }, (error) => {
      dispatch({
        type: SET_USER_DOCUMENTS_ERROR,
        settingError: error.data,
      });
      Notif.error(window.i18('ERROR_DURING_SAVE'));
    });
  };
}

export function fetchUserCards() {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_USER_CARDS_BEGIN });
    const { isTravelManager } = getState().app.userPermissions;
    API.get('user/cards').then((response) => {
      const cards = response.data && response.data.result;
      cards.some((card) => {
        if (card.cardtype.type === 'rail' && !card.cardnumber) {
          dispatch(openModal('Information', { title: window.i18('SNCF_CARDS'), text: [window.i18('SNCF_CARDS_STARTING_FROM'), window.i18('SNCF_CARDS_VISIT')] }));
          return true;
        }
        return false;
      });
      dispatch({
        type: FETCH_USER_CARDS_SUCCESS,
        cards,
        isTravelManager,
      });
    }, (error) => {
      dispatch({
        type: FETCH_USER_CARDS_ERROR,
        error: error.data,
      });
    });
  };
}

export function setUserCards(card, action) {
  return (dispatch, getState) => {
    dispatch({ type: SET_USER_CARDS_BEGIN });
    let cards = getState().account.cards.list;
    const { uid } = getState().app.user;
    if (action === 'remove') {
      cards = cards.filter((c) => c.uid !== card.uid);
    } else if (action === 'add') {
      cards.push(card);
    } else if (action === 'update') {
      cards = cards.map((c) => (c.uid === card.uid ? card : c));
    }
    API.put('user/cards', { user_cards: cards }).then((response) => {
      dispatch({
        type: SET_USER_CARDS_SUCCESS,
        cards: response.data.result,
        uid,
      });
      Notif.success(window.i18('CARD_UPDATED'));
    }, (error) => {
      dispatch({
        type: SET_USER_CARDS_ERROR,
        settingError: error.data,
      });
      Notif.error(window.i18('ERROR_DURING_SAVE'));
    });
  };
}

export function getCdsUrl(id) {
  return (dispatch) => {
    dispatch({ type: GET_CDS_URL_BEGIN, id });
    return API.post(
      '/hotel/cds',
      { treep_id: id },
    ).then((resp) => {
      dispatch({ type: GET_CDS_URL_SUCCESS });
      if (resp.data.result != null && resp.data.result != null) {
        window.open(resp.data.result.url, '_blank');
      }
    }, () => {
      Notif.error(window.i18('REDIRECTION_ERROR'));
      dispatch({
        type: GET_CDS_URL_ERROR,
      });
    });
  };
}

export function promptDeleteInfos() {
  return (dispatch) => {
    dispatch(openModal('Confirm', {
      message: window.i18('CONFIRM_DATA_DELETE_MESSAGE'),
      confirm: () => {
        API.delete('user/optional_data')
          .then(() => {
            Notif.success(window.i18('DATA_DELETED'));
          });
        // Do not need this because we are catching everything localy now. See api.js interceptors
        /*
          .catch((err) => {
            Notif.warning(friendlyError(err));
          });
          */
      },
    }));
  };
}

export function promptDeleteAccount() {
  return (dispatch) => {
    dispatch(openModal('Confirm', {
      message: window.i18('CONFIRM_ACCOUNT_DELETE_MESSAGE'),
      confirm: () => {
        API.delete('user/data')
          .then(() => {
            Notif.success(window.i18('ACCOUNT_DELETED'));
            dispatch(logout());
          });
        // Do not need this because we are catching everything localy now. See api.js interceptors
        /*
          .catch((err) => {
            Notif.warning(friendlyError(err));
          });
          */
      },
    }));
  };
}
