import React from 'react';

export default class Confirm extends React.Component {
    confirm = () => {
      this.props.confirm();
      this.props.closeModal();
    }

    render() {
      return (
        <div className="modal-inner">
          <section className="modal-header">
            <h3 className="common-sub-title">{this.props.message}</h3>
          </section>
          {this.props.secondaryMessage && <p className="p-bot">{this.props.secondaryMessage}</p>}
          <div className="modal-button-group">
            <a className="button-main" onClick={this.confirm}>{window.i18('CONFIRM')}</a>
            <a className="button-secondary" onClick={this.props.closeModal}>{window.i18('CANCEL')}</a>
          </div>
        </div>

      );
    }
}
