import React from 'react';
import classNames from 'classnames';
import vehicle from '../../../../images/vehicle.png';
import TicketTagsTooltip from '../../../components/TicketTagsTooltip';
import { getSvgIcon } from '../../../utils/icons';

class CarRentals extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.selectedMarker !== this.props.selectedMarker && this.props.selectedMarker) {
      const selected = document.getElementById(this.props.selectedMarker);
      selected.scrollIntoView({
        behavior: 'instant',
        block: 'center',
      });
    }
  }

  render() {
    const {
      results,
      setSelectedResult,
      selectedResult,
      selectedMarker,
      displayMap,
    } = this.props;

    return (
      <ul className="hotel-results-list">
        <div className="bordered-block">
          <li className="hotel-results-list__item">
            <div>
              <h3>
                {results.length}
                {' '}
                {window.i18('VEHICLE')}
                {results.length > 1 ? 's' : ''}
                {' '}
                {window.i18('FOUND')}
                {results.length > 1 ? 's' : ''}
              </h3>
            </div>
            <div>
              <h4 className="text-center">{window.i18('RATE')}</h4>
            </div>
          </li>
          <div className="hotel-results-list__scroll">
            {results.map((res) => (
              <li
                className={classNames('car-rental-item', 'hotel-results-list__item',
                  { selected: displayMap && selectedMarker === res.id })}
                id={res.id}
                key={res.id}
              >
                <div>
                  <div className="car-rental-item__left">
                    <p className="car-rental-item__agency">
                      {window.i18('AGENCY')}
                      {' '}
                      {res.pickup_place.address.city}
                      {' '}
                      {res.pickup_place.name}
                    </p>
                    <img
                      src={res.pix_url ? res.pix_url : vehicle}
                      alt={window.i18('VEHICLE')}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = 'https://img.thetreep.com/misc/CGMR_SN.jpg';
                      }}
                    />
                  </div>
                  <div className="car-rental-item__right">
                    <p className="car-rental-item__name">{res.car}</p>
                    {res.tags?.includes('valet') && (
                      <p className="car-rental-item__valet">
                        {window.i18('VALET_SERVICE')}
                        {' '}
                        {getSvgIcon('use-male', 'ui')}
                      </p>
                    )}
                    {res.renter?.logo ? (
                      <p className="car-rental-item__renter">
                        <img
                          src={`https://img.thetreep.com/renters/${res.renter.logo}`}
                          alt={res.renter?.name}
                        />
                      </p>
                    ) : (
                      <p className="car-rental-item__renter">{res.renter?.name ?? ''}</p>
                    )}
                    <p className="car-rental-item__distance">
                      {res.distance > 1000 ? `${(res.distance / 1000).toFixed(
                        1,
                      )}km` : `${res.distance}m`}
                    </p>
                  </div>
                </div>
                <div className="p-x">
                  <div className="common-tooltip__parent">
                    <button
                      className={
                        classNames(
                          'hotel-price-btn',
                          { selected: res.id === selectedResult.id && !displayMap },
                          { 'hotel-price-btn--blue': res.tags && res.tags.indexOf('negociated-fare') > -1 },
                          { 'hotel-price-btn--green': res.tags && res.tags.indexOf('compliant') > -1 },
                        )
                      }
                      onClick={() => setSelectedResult(res.id)}
                    >
                      {res.best_price.total / 100}
                      €
                      <span className="small-text">
                        {res.priceByDay.total / 100}
                        € /
                        {' '}
                        {window.i18('DAY')}
                      </span>
                    </button>
                    <TicketTagsTooltip tags={res.tags} />
                  </div>
                </div>
              </li>
            ))}
          </div>
        </div>
      </ul>
    );
  }
}

export default CarRentals;
