import { combineReducers } from 'redux';
import {
  SET_OUTWARD_TRIP,
  SET_RETURN_TRIP,
  SET_SEARCHING_TRIP,
  SET_IS_ROUND_TRIP,
  SET_SEARCHED_TRIP,
  SET_PASSENGERS,
  RESET_RESULTS,
  SET_SELECTED_OUTWARD_TRANSPORT_MODE,
  SET_SELECTED_RETURN_TRANSPORT_MODE,
  SET_SELECTED_OUTWARD_PROPOSAL,
  SET_SELECTED_RETURN_PROPOSAL,
  SET_SELECTED_OUTWARD_CABIN,
  SET_SELECTED_RETURN_CABIN,
  SET_SELECTED_OUTWARD_CHOICE,
  SET_SELECTED_RETURN_CHOICE,
  VALIDATE_OUTWARD_CHOICE,
  VALIDATE_RETURN_CHOICE,
  SET_TRAVELERS_REQUIRED_INFOS,
  LOADING_TRAVELERS_REQUIRED_INFOS,
  ADD_TO_CART_BEGIN,
  ADD_TO_CART_ERROR,
  ADD_TO_CART_SUCCESS,
  TOGGLE_FILTER,
  FILTER_FULL_FLEX,
  FILTER_SEMI_FLEX,
  FILTER_NO_FLEX,
  FILTER_LUGGAGE,
  CHANGE_OUTWARD_CHOICE,
  CHANGE_RETURN_CHOICE,
  SET_SORTING,
  FILTER_TRANSIT_FLIGHT,
} from './constants';

const initialTripState = {
  podium: [],
  travelers: [],
  proposals: [],
  selectedTransportMode: null,
  selectedProposal: {},
  selectedCabin: {},
  selectedChoice: {},
  validated: false,
};

function outwardTripReducer(state = initialTripState, {
  type, trips, selectedTransportMode, selectedProposal, selectedCabin, selectedChoice,
}) {
  switch (type) {
    case SET_OUTWARD_TRIP:
      return {
        ...state,
        ...trips,
      };
    case SET_SELECTED_OUTWARD_TRANSPORT_MODE:
      return {
        ...state,
        selectedTransportMode,
      };
    case SET_SELECTED_OUTWARD_PROPOSAL:
      return {
        ...state,
        selectedProposal: {
          ...state.selectedProposal,
          [selectedTransportMode]: selectedProposal,
        },
      };
    case SET_SELECTED_OUTWARD_CABIN:
      return {
        ...state,
        selectedCabin: {
          ...state.selectedCabin,
          [selectedTransportMode]: selectedCabin,
        },
      };
    case SET_SELECTED_OUTWARD_CHOICE:
      return {
        ...state,
        selectedChoice: {
          ...state.selectedChoice,
          [selectedTransportMode]: selectedChoice,
        },
      };
    case VALIDATE_OUTWARD_CHOICE:
      return {
        ...state,
        validated: true,
      };
    case CHANGE_OUTWARD_CHOICE:
      return {
        ...state,
        validated: false,
      };
    case RESET_RESULTS:
      return initialTripState;
    default:
      return state;
  }
}

function returnTripReducer(state = initialTripState, {
  type, trips, selectedTransportMode, selectedProposal, selectedCabin, selectedChoice,
}) {
  switch (type) {
    case SET_RETURN_TRIP:
      return {
        ...state,
        ...trips,
      };
    case SET_SELECTED_RETURN_TRANSPORT_MODE:
      return {
        ...state,
        selectedTransportMode,
      };
    case SET_SELECTED_RETURN_PROPOSAL:
      return {
        ...state,
        selectedProposal: {
          ...state.selectedProposal,
          [selectedTransportMode]: selectedProposal,
        },
      };
    case SET_SELECTED_RETURN_CABIN:
      return {
        ...state,
        selectedCabin: {
          ...state.selectedCabin,
          [selectedTransportMode]: selectedCabin,
        },
      };
    case SET_SELECTED_RETURN_CHOICE:
      return {
        ...state,
        selectedChoice: {
          ...state.selectedChoice,
          [selectedTransportMode]: selectedChoice,
        },
      };
    case VALIDATE_RETURN_CHOICE:
      return {
        ...state,
        validated: true,
      };
    case CHANGE_RETURN_CHOICE:
    case CHANGE_OUTWARD_CHOICE:
      return {
        ...state,
        validated: false,
      };
    case RESET_RESULTS:
      return initialTripState;
    default:
      return state;
  }
}

function loadingReducer(state = false, { type }) {
  switch (type) {
    case SET_SEARCHING_TRIP:
      return true;
    case SET_OUTWARD_TRIP:
    case SET_RETURN_TRIP:
    case RESET_RESULTS:
      return false;
    default:
      return state;
  }
}

function isRoundTripReducer(state = false, { type }) {
  switch (type) {
    case SET_IS_ROUND_TRIP:
      return true;
    case RESET_RESULTS:
      return false;
    default:
      return state;
  }
}

function searchReducer(state = {}, { type, search }) {
  switch (type) {
    case SET_SEARCHED_TRIP:
      return search;
    case RESET_RESULTS:
      return {};
    default:
      return state;
  }
}

function passengersReducer(state = [], { type, passengers }) {
  switch (type) {
    case SET_PASSENGERS:
      return passengers;
    case RESET_RESULTS:
      return [];
    default:
      return state;
  }
}

function currentTripReducer(state = 'outwardTrip', { type }) {
  switch (type) {
    case SET_OUTWARD_TRIP:
    case CHANGE_OUTWARD_CHOICE:
      return 'outwardTrip';
    case SET_RETURN_TRIP:
      return 'returnTrip';
    default:
      return state;
  }
}

function requiredInfosReducer(state = { loading: false, data: [] }, { type, requiredInfos }) {
  switch (type) {
    case LOADING_TRAVELERS_REQUIRED_INFOS:
      return {
        ...state,
        loading: true,
      };
    case SET_TRAVELERS_REQUIRED_INFOS:
      return {
        loading: false,
        data: requiredInfos,
      };
    default:
      return state;
  }
}

function addingToCartReducer(state = false, { type }) {
  switch (type) {
    case ADD_TO_CART_BEGIN:
      return true;
    case ADD_TO_CART_ERROR:
    case ADD_TO_CART_SUCCESS:
      return false;
    default:
      return state;
  }
}

const initialFilters = [FILTER_LUGGAGE, FILTER_TRANSIT_FLIGHT];

function filtersReducer(state = initialFilters, { type, filter }) {
  const flexFilters = [FILTER_FULL_FLEX, FILTER_SEMI_FLEX, FILTER_NO_FLEX];
  switch (type) {
    case TOGGLE_FILTER: {
      const newState = [...state];
      if (state.indexOf(filter) === -1) {
        // If this new filter is a flexibility filter...
        if (flexFilters.includes(filter)) {
          // ... and that by adding it, every flexibility would be filtered out...
          if (flexFilters.every((f) => [...state, filter].includes(f))) {
            // ... we do not add it
            return state;
          }
        }
        newState.push(filter);
      } else {
        newState.splice(state.indexOf(filter), 1);
      }
      return newState;
    }
    case RESET_RESULTS:
      return initialFilters;
    default:
      return state;
  }
}

function sortingReducer(state = 'BY_TIME', { type, sorting }) {
  switch (type) {
    case SET_SORTING:
      return sorting;
    case RESET_RESULTS:
      return 'BY_TIME';
    default:
      return state;
  }
}

const tripResultsReducer = combineReducers({
  outwardTrip: outwardTripReducer,
  returnTrip: returnTripReducer,
  currentTrip: currentTripReducer,
  loading: loadingReducer,
  isRoundTrip: isRoundTripReducer,
  search: searchReducer,
  passengers: passengersReducer,
  requiredInfos: requiredInfosReducer,
  addingToCart: addingToCartReducer,
  filters: filtersReducer,
  sorting: sortingReducer,
});

export default tripResultsReducer;
