import React from 'react';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils, { formatDate } from 'react-day-picker/moment';
import moment from 'moment';
import 'moment/locale/fr';

// import {timeToRange} from '../../../../utils/functions'
import Switcher from '../Switcher';
import Container from './Container';
import { formatTime } from '../../utils/functions';

export default class DateTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayTime: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.field !== this.props.field) {
      this.setState({
        displayTime: false,
      });
    }
  }

  toggleTimeDisplay = () => {
    this.setState({
      displayTime: !this.state.displayTime,
    });
  };

  render() {
    const { displayTime } = this.state;
    const title = displayTime
      ? window.i18('SELECT_TIME')
      : window.i18('SELECT_DATE');
    return (
      <Container title={title} isMobileDevice={this.props.isMobileDevice} label={this.props.field}>
        {displayTime ? (
          <Time {...this.props} toggleTimeDisplay={this.toggleTimeDisplay} />
        ) : (
          <PreciseDate
            {...this.props}
            toggleTimeDisplay={this.toggleTimeDisplay}
          />
        )}
      </Container>
    );
  }
}

const PreciseDate = ({
  search: { date, isRT },
  field,
  changeDate,
  toggleTimeDisplay,
  setTripTimeMode,
  setTripTime,
  toggleRT,
  setTripDate,
  withTime,
  isCarRental,
}) => {
  let selectedDate;
  let disabledDays;
  if (field === 'a' || field === 'r') {
    selectedDate = date[field].date;
    disabledDays = {
      before: field === 'r' ? new Date(date.a.date) : new Date(),
    };
  } else {
    selectedDate = date[field];
    disabledDays = {
      before: field === 'departure' ? moment(new Date(date.arrival)).add(1, 'days').toDate() : new Date(),
    };
  }
  const formattedDate = new Date(selectedDate);
  let monthDate = formattedDate;
  if (!(date[field] && date[field].date)) {
    if (field === 'r') {
      if (date.a.date) {
        monthDate = new Date(date.a.date);
      } else {
        monthDate = new Date();
      }
    } else if (field === 'departure') {
      if (date.arrival) {
        monthDate = new Date(date.arrival);
      } else {
        monthDate = new Date();
      }
    } else {
      monthDate = new Date();
    }
  }
  const morning = isCarRental ? '090000' : '050000';
  const afternoon = isCarRental ? '180000' : '140000';

  const setTime = (clicked) => {
    if (!isCarRental) {
      setTripTimeMode('departure');
    }
    setTripTime(field, clicked === 1 ? morning : afternoon);
  };

  const setDate = (d, m) => {
    if (m.disabled) return;
    if (field === 'r' && !isRT && !isCarRental) {
      toggleRT();
    }
    changeDate(d, field);
  };

  const resetReturn = () => {
    toggleRT();
    setTripDate('r', null);
  };

  const savedLang = localStorage.getItem('theTreepLang');

  return (
    <>
      {field === 'r' && isRT && !isCarRental && (
        <button aria-label={window.i18('DELETE_RETURN')} className="supprimer-retour" onClick={resetReturn} />
      )}
      <DayPicker
        minDate={moment()}
        formatDate={formatDate}
        onDayClick={setDate}
        disabledDays={disabledDays}
        selectedDays={formattedDate}
        month={monthDate}
        localeUtils={MomentLocaleUtils}
        locale={savedLang || 'fr'}
        showOutsideDays
        fixedWeeks
        className="date-picker"
      />
      {withTime && (
        <>
          <Switcher
            first={window.i18('MORNING')}
            second={window.i18('AFTERNOON')}
            value={
              date[field].time === morning && (date[field].mode === 'departure' || isCarRental)
            }
            disabled={
              (date[field].mode === 'arrival' && !isCarRental)
              || (date[field].time !== morning && date[field].time !== afternoon)
            }
            handleClick={setTime}
            small
          />
          <button className="show-time-details" onClick={toggleTimeDisplay}>
            {window.i18('PRECISE_TIME')}
          </button>
        </>
      )}
    </>
  );
};

const Time = ({
  setTripTimeMode,
  setTripTime,
  search: { date },
  toggleTimeDisplay,
  field,
  isCarRental,
}) => {
  const createTimeTable = () => {
    const items = [];
    for (let i = 6; i < 24; i += 1) {
      const time = (i < 10 ? '0' : '') + (i * 10000).toString();
      items.push(
        <button
          className={`time-details__time-table__item ${
            parseInt(date[field].time, 10) === parseInt(i * 10000, 10)
              ? 'selected'
              : ''
          }`}
          onClick={() => setTripTime(field, time)}
        >
          {i < 10 ? '0' : ''}
          {i}
          :00
        </button>,
      );
    }
    return items;
  };

  const formattedTime = formatTime(date[field].time);

  return (
    <section className="time-details">
      {
        !isCarRental && (
          <Switcher
            first={window.i18('LEAVE_AFTER')}
            second={window.i18('ARRIVE_BEFORE')}
            value={date[field].mode === 'departure'}
            small
            handleClick={() => setTripTimeMode(
              field,
              date[field].mode === 'departure' ? 'arrival' : 'departure',
            )}
          />
        )
      }
      <input
        className="time"
        type="time"
        value={formattedTime}
        onChange={(e) => setTripTime(field, `${e.target.value.replace(':', '')}00`)}
      />
      <section className="time-details__time-table">
        {createTimeTable()}
      </section>
      <button className="button-secondary" onClick={toggleTimeDisplay}>
        {window.i18('CONFIRM')}
      </button>
    </section>
  );
};
