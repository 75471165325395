import React from 'react';
import { Range } from 'react-range';

const STEP = 1;
const MIN = 0;
const MAX = 6;
const values = [1, 2, 5, 10, 15, 25, 50];

export default class RadiusRange extends React.Component {
  render() {
    const { handleChange } = this.props;
    const value = values.indexOf(this.props.value);
    return (
      <div className="slider-input">
        <Range
          values={[value]}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={([value]) => handleChange(values[value])}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
              }}
              className="slider-input__bar-container"
            >
              <div
                ref={props.ref}
                className="slider-input__bar"
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              className="slider-input__thumb"
            >
              <div />
              <div />
              <div />
            </div>
          )}
        />
        <ul className="slider-input__values">
          {values.map((v) => (
            <li key={v}>{v}</li>
          ))}
        </ul>
      </div>
    );
  }
}
