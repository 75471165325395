import React from 'react';
import { Link } from 'react-router-dom';
import { getSvgIcon } from '../../../utils/icons';

const InformationModal = ({ title, text, closeModal }) => (
  <div className="modal-inner">
    <section className="modal-section information-icon">
      {getSvgIcon('information', 'ui/')}
    </section>
    <section className="modal-header">
      <h3 className="common-sub-title">
        {window.i18('INFORMATION')}
        <strong>{title}</strong>
      </h3>
    </section>
    <section className="modal-section text">
      {text?.map((t) => (
        <p>{t}</p>
      ))}
    </section>
    <section className="modal-section">
      <Link to="/account/cards" onClick={closeModal} className="button-main">{window.i18('UPDATE_MY_CARDS')}</Link>
    </section>
  </div>
);

export default InformationModal;
