import React from 'react';

import { useDispatch } from 'react-redux';
import { newPassword } from '../../App/actions';
import NewPasswordForm from '../../../components/NewPasswordForm';

function NewPasswordModal({ token, closeModal }) {
  const dispatch = useDispatch();
  const [error, setError] = React.useState(null);

  const handleSubmit = ({ password }) => dispatch(newPassword(token, password)).then(() => {
    closeModal();
  }).catch((err) => setError(err.response?.data?.errorMessage || window.i18('ERROR_GENERIC')));

  return (
    <div className="user-modal">
      <div className="modal-with-tagline">
        <header className="tagline">
          <h1 className="common-title">{window.i18('UPDATE_PASSWORD')}</h1>
        </header>
      </div>
      <div className="modal-inner">
        <div className="user-form">
          {error && <p className="error-message">{error}</p>}
          <NewPasswordForm handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
}

export default NewPasswordModal;
