import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Components
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
// Actions
import { openModal } from '../ModalWrapper/actions';
// Functions
import { formatJSDateFromAPI } from '../../utils/converters';
import { getOnboarding, saveInfos } from './actions';

const Onboarding = ({ location }) => {
  const handicaps = useSelector((state) => state.app.handicaps);
  const cardTypes = useSelector((state) => state.app.cards);

  const [filled, setFilled] = useState(false);
  const [secondValues, setSecondValues] = useState({});
  const [fetchedData, setFetchedData] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const url = new URLSearchParams(location.search);
  const [values, setValues] = useState({
    firstname: url.get('firstname'),
    lastname: url.get('lastname'),
    email: url.get('email'),
    username: url.get('username'),
    password: '',
    stock: '',
    gdpr_consent: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await dispatch(getOnboarding(url.get('token'), url.get('username')));
        const {
          birthdate, phone, gender,
        } = res.data.result;

        setSecondValues({
          phone,
          gender,
          birthdate: formatJSDateFromAPI(birthdate),
        });
      } catch (e) {
        // No handling
      }
    };
    if (!fetchedData) {
      setFetchedData(true);
      fetchData();
    }
  }, [dispatch, secondValues, url, fetchedData]);

  const setFirstPageValues = (values) => {
    if (values.stock === 'refuse') {
      dispatch(saveInfos(url.get('token'), values, null));
      return;
    }

    toggleFilled();
    setValues(values);
  };

  const handleSubmit = (secondValues) => {
    dispatch(saveInfos(url.get('token'), values, secondValues));
  };

  const toggleFilled = () => setFilled(!filled);

  return (
    <div className="first-sign-in">
      {filled
        ? <SecondPage secondValues={secondValues} firstValues={values} back={toggleFilled} handicaps={handicaps} cardTypes={cardTypes} openModal={() => dispatch(openModal())} onSubmit={handleSubmit} />
        : <FirstPage initialValues={values} setValues={setFirstPageValues} />}
    </div>
  );
};

export default Onboarding;
