import React from 'react';
import { Link } from 'react-router-dom';

import CartImage from '../../../images/cart.png';

const emptyCart = () => (
  <div className="common-illustration-wrapper empty-cart">
    <img
      className="illustration-big"
      src={CartImage}
      alt="empty cart | The treep"
    />
    <p className="common-sub-title">{window.i18('CART_IS_EMPTY')}</p>
    <Link
      className="button-main"
      to="/"
    >
      {window.i18('BOOK_ECO_TRIP')}
    </Link>
  </div>
);

export default emptyCart;
