import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import appReducer from './containers/App/reducer';
import searchReducer from './containers/Search/reducer';
import tripSearchReducer from './containers/TripSearch/reducer';
import hotelSearchReducer from './containers/HotelSearch/reducer';
import carRentalSearchReducer from './containers/CarRentalSearch/reducer';
import resultsReducer from './containers/TripResults_v3/reducer';
import hotelResultsReducer from './containers/HotelResults/reducer';
import carRentalResultsReducer from './containers/CarRentalResults/reducer';
import modalReducer from './containers/ModalWrapper/reducer';
import cartReducer from './containers/Cart/reducer';
import checkoutReducer from './containers/Checkout/reducer';
import accountReducer from './containers/UserAccount/reducer';
import approvalReducer from './containers/Approval/reducer';
import imgViewerReducer from './containers/ImgViewer/reducer';

const createRootReducer = (history) => combineReducers({
  app: appReducer,
  search: searchReducer,
  tripSearch: tripSearchReducer,
  hotelSearch: hotelSearchReducer,
  carRentalSearch: carRentalSearchReducer,
  results: resultsReducer,
  hotelResults: hotelResultsReducer,
  carRentalResults: carRentalResultsReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  modal: modalReducer,
  account: accountReducer,
  approval: approvalReducer,
  imgViewer: imgViewerReducer,
  router: connectRouter(history),
});

export default createRootReducer;
