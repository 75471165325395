import React from 'react';
import {
  CarouselProvider, Slide, Slider, ButtonBack, ButtonNext,
} from 'pure-react-carousel';
import Skeleton from 'react-skeleton-loader';
import { connect } from 'react-redux';
// Components
import Stars from '../../../../components/Stars';
import HotelTags from '../../../../components/HotelTags';
// Functions
import { setGallery } from '../../../ImgViewer/actions';
import Rating from '../../../../components/Rating';
import { getSvgIcon } from '../../../../utils/icons';
import { getSelectedDetails } from '../../actions';

class Desc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullDesc: false,
    };
  }

  render() {
    const {
      selected,
      selectedResult,
      setGallery,
      getSelectedDetails,
    } = this.props;
    const { fullDesc } = this.state;

    return (
      <div className="hotel-desc">
        <div className="hotel-desc__more">
          <Stars count={selected.stars} />
          <HotelTags tags={selected.hotel_tags} />
          {selected.sanitary_note && (
            <div className="hotel-desc__covid common-tooltip__parent">
              <Rating score={selected.sanitary_note} />
              <p>{window.i18('SANITARY_NOTE')}</p>
              <div className="info-icon">
                {getSvgIcon('information', 'ui')}
              </div>
              <div className="common-tooltip common-tooltip--large">
                {(selected.sanitary_infos || selected.sanitary_norm) && (
                  <div className="common-tooltip__content">
                    {selected.sanitary_infos ? (
                      <>
                        <p>
                          {selected.name}
                          {' '}
                          {window.i18('HAS_IMPLEMENTED_SANITARY_MEASURES')}
                          {' '}
                          {selected.sanitary_norm && `(${selected.sanitary_norm})`}
                          {' '}
                          :
                        </p>
                        <ul>
                          {selected.sanitary_infos?.map((info) => <li key={info}><p>{info}</p></li>)}
                        </ul>
                      </>
                    ) : (
                      <p>
                        {window.i18('NORM')}
                        {' '}
                        {selected.sanitary_norm}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <h2>{selected.name}</h2>
        <p>
          {selected.address}
          ,
          {selected.city}
          {' '}
          (
          {selected.country_code}
          )
        </p>
        <p>
          {selected.phone}
          {' - '}
          {selected.email}
        </p>
        <div>
          {selectedResult.error ? (
            <>
              <p>Une erreur a eu lieu lors de la recherche des informations supplémentaires.</p>
              <button className="button-main" onClick={() => getSelectedDetails(selectedResult.id)}>Réessayer</button>
            </>
          ) : (
            <>
              {selectedResult.loading
                ? (
                  <div className="hotel-carousel__loader">
                    <Skeleton width="28%" height="100px" heightRandomness={0} widthRandomness={0} />
                    <Skeleton width="28%" height="100px" heightRandomness={0} widthRandomness={0} />
                    <Skeleton width="28%" height="100px" heightRandomness={0} widthRandomness={0} />
                  </div>
                )
                : selectedResult.details.img_urls && (
                <CarouselProvider
                  naturalSlideWidth={100}
                  naturalSlideHeight={60}
                  totalSlides={selectedResult.details.img_urls.length}
                  visibleSlides={3}
                  step={3}
                  className="hotel-carousel m-top m-bot p-top p-bot"
                >
                  <ButtonBack>Back</ButtonBack>
                  <div className="carousel__slider-container">
                    <Slider>
                      {selectedResult.details.img_urls.map((img, i) => (
                        <Slide index={i} key={img}>
                          <a onClick={() => setGallery(img, selectedResult.details.img_urls)}>
                            <img
                              src={img}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = 'https://img.thetreep.com/misc/y9DpT.jpg';
                              }}
                            />
                          </a>
                        </Slide>
                      ))}
                    </Slider>
                  </div>
                  <ButtonNext>Next</ButtonNext>
                </CarouselProvider>
                )}
              {selectedResult.loading ? (
                <p className="hotel-details__loading">
                  {window.i18('LOADING_ADDITIONAL_DATA')}
                </p>
              ) : (
                selectedResult.details && (
                  <div className="hotel-desc__text">
                    <p className="hotel-details__desc">
                      {selectedResult.details.description?.length > 500
                        ? (
                          <>
                            {fullDesc
                              ? selectedResult.details.description
                              : `${selectedResult.details.description.substring(0, 300)}...`}
                          </>
                        )
                        : selectedResult.details.description}
                    </p>
                    {selectedResult.details.description?.length > 500 && (
                      <button className="button-tertiary" onClick={() => this.setState({ fullDesc: !fullDesc })}>
                        {window.i18('SEE')}
                        {' '}
                        {fullDesc ? window.i18('LESS') : window.i18('MORE')}
                      </button>
                    )}
                  </div>
                )
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setGallery: (img, imgs) => dispatch(setGallery(img, imgs)),
  getSelectedDetails: (id) => dispatch(getSelectedDetails(id)),
});

export default connect(null, mapDispatchToProps)(Desc);
