import { combineReducers } from 'redux';

import {
  FETCH_USER_TICKETS_BEGIN,
  FETCH_USER_TICKETS_ERROR,
  FETCH_USER_TICKETS_SUCCESS,
  FETCH_USER_ARCHIVES_BEGIN,
  FETCH_USER_ARCHIVES_ERROR,
  FETCH_USER_ARCHIVES_SUCCESS,
  FETCH_USER_DOCUMENTS_BEGIN,
  FETCH_USER_DOCUMENTS_ERROR,
  FETCH_USER_DOCUMENTS_SUCCESS,
  FETCH_USER_APPROVALS_BEGIN,
  FETCH_USER_APPROVALS_ERROR,
  FETCH_USER_APPROVALS_SUCCESS,
  SET_USER_DOCUMENTS_BEGIN,
  SET_USER_DOCUMENTS_SUCCESS,
  SET_USER_DOCUMENTS_ERROR,
  FETCH_USER_CARDS_BEGIN,
  FETCH_USER_CARDS_ERROR,
  FETCH_USER_CARDS_SUCCESS,
  SET_USER_CARDS_BEGIN,
  SET_USER_CARDS_ERROR,
  SET_USER_CARDS_SUCCESS,
  GET_CDS_URL_BEGIN,
  GET_CDS_URL_SUCCESS,
  GET_CDS_URL_ERROR,
  SET_SELECTED_PENDING,
  SET_SELECTED_TICKETS,
  SENDING_APPROVAL_BEGIN,
  SENDING_APPROVAL_ERROR,
  SENDING_APPROVAL_SUCCESS,
  SENDING_CANCEL,
  SENT_CANCEL,
} from './constants';

function userTicketsReducer(state = {
  loading: false,
  selected: [],
  pendingSelected: [],
  cancelling: false,
  list: [],
  pendingList: [],
}, action) {
  switch (action.type) {
    case FETCH_USER_TICKETS_BEGIN:
      return {
        ...state,
        loading: true,
        selected: [],
        pendingSelected: [],
      };
    case FETCH_USER_TICKETS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        selected: [],
        pendingSelected: [],
      };
    case FETCH_USER_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.restOfTickets,
        pendingList: action.pendingTickets,
        selected: [],
        pendingSelected: [],
      };
    case SET_SELECTED_TICKETS:
      return {
        ...state,
        selected: action.selected,
      };
    case SET_SELECTED_PENDING:
      return {
        ...state,
        pendingSelected: action.selected,
      };
    case SENDING_CANCEL:
      return {
        ...state,
        cancelling: true,
      };
    case SENT_CANCEL:
      return {
        ...state,
        cancelling: false,
      };
    default:
      return state;
  }
}

function userApprovalsReducer(state = {
  loading: false,
  sendingApproval: [],
  error: null,
  list: {},
}, action) {
  switch (action.type) {
    case FETCH_USER_APPROVALS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_APPROVALS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_USER_APPROVALS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.tickets,
      };
    case SENDING_APPROVAL_BEGIN:
      return {
        ...state,
        sendingApproval: [...state.sendingApproval, action.approvalID],
      };
    case SENDING_APPROVAL_SUCCESS:
    case SENDING_APPROVAL_ERROR: {
      const index = state.sendingApproval.indexOf(action.approvalID);
      const newSendingApproval = [...state.sendingApproval];
      if (index > -1) {
        newSendingApproval.splice(index, 1);
      }
      return {
        ...state,
        sendingApproval: newSendingApproval,
      };
    }
    default:
      return state;
  }
}

function userArchivesReducer(state = {
  loading: false,
  list: [],
}, action) {
  switch (action.type) {
    case FETCH_USER_ARCHIVES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_ARCHIVES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_USER_ARCHIVES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.tickets,
      };
    default:
      return state;
  }
}

function documentsReducer(state = {
  list: [],
  fetching: false,
  fetchingError: null,
  setting: false,
  settingError: null,
}, action) {
  switch (action.type) {
    case FETCH_USER_DOCUMENTS_BEGIN:
      return {
        ...state,
        fetching: true,
        fetchingError: null,
      };
    case FETCH_USER_DOCUMENTS_ERROR:
      return {
        ...state,
        fetching: false,
        fetchingError: action.error,
      };
    case FETCH_USER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        list: action.documents,
      };
    case SET_USER_DOCUMENTS_BEGIN:
      return {
        ...state,
        setting: true,
        settingError: null,
      };
    case SET_USER_DOCUMENTS_ERROR:
      return {
        ...state,
        setting: false,
        settingError: action.error,
      };
    case SET_USER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        setting: false,
        list: action.documents,
      };
    default:
      return state;
  }
}

function cardsReducer(state = null, action) {
  switch (action.type) {
    case FETCH_USER_CARDS_BEGIN:
      return {
        ...state,
        fetching: true,
        fetchingError: null,
      };
    case FETCH_USER_CARDS_ERROR:
      return {
        ...state,
        fetching: false,
        fetchingError: action.error,
      };
    case FETCH_USER_CARDS_SUCCESS:
      return {
        ...state,
        fetching: false,
        list: action.cards,
      };
    case SET_USER_CARDS_BEGIN:
      return {
        ...state,
        setting: true,
        settingError: null,
      };
    case SET_USER_CARDS_ERROR:
      return {
        ...state,
        setting: false,
        settingError: action.error,
      };
    case SET_USER_CARDS_SUCCESS:
      return {
        ...state,
        setting: false,
        list: action.cards,
      };
    default:
      return state;
  }
}

function loadingCdsUrlReducer(state, { type, id }) {
  switch (type) {
    case GET_CDS_URL_BEGIN:
      return id;
    case GET_CDS_URL_SUCCESS:
      return false;
    case GET_CDS_URL_ERROR:
      return 'error';
    default:
      return false;
  }
}

const accountReducer = combineReducers({
  userTickets: userTicketsReducer,
  userApprovals: userApprovalsReducer,
  userArchives: userArchivesReducer,
  documents: documentsReducer,
  cards: cardsReducer,
  loadingCdsUrl: loadingCdsUrlReducer,
});

export default accountReducer;
