import React from 'react';
import { Form, Formik, yupToFormErrors } from 'formik';
import * as yup from 'yup';
import Select, { components } from 'react-select';

import UserInput from '../Inputs/UserInput';

import { formatDateFromAPI, formatDateForAPI } from '../../utils/converters';
import { getSvgIcon } from '../../utils/icons';

const icons = {
  airline: () => getSvgIcon('airplane', 'transport/svg/'),
  rail: () => getSvgIcon('tgv', 'transport/svg/'),
  hotel: () => getSvgIcon('hotel', 'transport/svg/'),
  car_rental: () => getSvgIcon('car_rental', 'transport/svg/'),
};

const Option = (props) => {
  const getIcon = icons[props.data.transport];
  return (
    <components.Option {...props}>
      {getIcon()}
      {props.label}
    </components.Option>
  );
};

const MenuList = (props) => (
  <components.MenuList {...props}>
    {props.children.length ? props.children.slice(0, 30) : window.i18('NO_RESULT')}
  </components.MenuList>
);

const initialCard = {
  card_name: '',
  cardnumber: '',
  cardtype_id: '',
  expiration_date: '',
};

function CardForm({
  cardtypes, card, handleCancel, user, setCards,
}) {
  const cards = cardtypes.map((c) => ({
    value: c.uid,
    label: c.name,
    transport: c.type,
  }));

  return (
    <Formik
      validate={(values) => {
        const schema = yup.object().shape({
          card_name: yup.string(),
          cardnumber: yup.string().notRequired()
            .when('cardtype_id', {
              is: (val) => {
                const currentCard = val && cardtypes.find((c) => c.uid === parseInt(values.cardtype_id, 10));
                return currentCard && (currentCard.card_type === 'loyalty_card' || currentCard.type === 'rail');
              },
              then: yup.string().required(window.i18('CARD_NUMBER_REQUIRED')),
              otherwise: yup.string().notRequired(),
            }),
          cardtype_id: yup.string().required(window.i18('PLEASE_FILL')),
          expiration_date: yup
            .date()
            .nullable().transform((curr, orig) => (orig === '' ? null : curr))
            .typeError(window.i18('VALID_DATE'))
            .min(new Date().toISOString().slice(0, 10), window.i18('VALID_DATE')),
        });
        return schema
          .validate(values, { abortEarly: false })
          .then(() => ({}))
          .catch((err) => yupToFormErrors(err));
      }}
      onSubmit={(values, actions) => {
        let action = 'add';

        const formatted = {
          ...values,
          user_id: user.uid,
          cardnumber: values.cardnumber.trim(),
          expiration_date: values.expiration_date && formatDateForAPI(new Date(values.expiration_date).toISOString()),
        };

        if (card !== 'new') {
          action = 'update';
          formatted.uid = card.uid;
        }

        const promise = setCards(formatted, action);

        Promise.resolve(promise).then(
          () => {
            actions.setSubmitting(false);
            handleCancel();
          },
          (error) => {
            actions.setStatus(error.msg);
          },
        );
      }}
      initialValues={(() => {
        if (card === 'new') return initialCard;
        return {
          ...card,
          expiration_date: card.expiration_date && formatDateFromAPI(card.expiration_date).slice(0, 10),
        };
      })()}
    >
      {({
        values, handleBlur, handleChange, errors, isSubmitting, setFieldValue,
      }) => {
        const currentCard = values.cardtype_id && cardtypes.find((c) => c.uid === parseInt(values.cardtype_id, 10));
        const withNumber = currentCard && (currentCard.card_type === 'loyalty_card' || currentCard.type === 'rail');
        return (

          <Form className="document-form">
            <UserInput
              type="text"
              label={window.i18('CARD_NAME')}
              name="card_name"
              placeholder={window.i18('CARD')}
              value={values.card_name}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.card_name}
            />
            <div className="form-group">
              <label className="user-label common-sub-title">
                {window.i18('CARD_TYPE')}
                :
              </label>
              <div className="input-wrapper">
                {errors.cardtype_id && (<span className="form-error">{errors.cardtype_id}</span>)}
                <Select
                  name="cardtype_id"
                  options={cards}
                  value={{
                    value: values.cardtype_id,
                    label: values.cardtype_id !== ''
                      ? cards.find((c) => c.value === values.cardtype_id)?.label
                      : window.i18('TYPE_IN_CARD_NAME'),
                  }}
                  onChange={({ value }) => setFieldValue('cardtype_id', value)}
                  components={{ Option, MenuList }}
                  className="card-input"
                  classNamePrefix="card-input"
                />
              </div>
            </div>
            {withNumber && (
              <UserInput
                type="text"
                label={`${window.i18('CARD_NUMBER')}:`}
                name="cardnumber"
                placeholder="0123456789"
                value={values.cardnumber}
                onBlur={handleBlur}
                onChange={handleChange}
                error={errors.cardnumber}
              />
            )}
            <UserInput
              type="date"
              label="Date d'expiration (optionnel):"
              name="expiration_date"
              placeholder="01/01/2020"
              value={values.expiration_date}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.expiration_date}
            />
            <div className="document-buttons">
              <button type="submit" disabled={isSubmitting} className="button-main">{window.i18('SAVE')}</button>
              <button className="button-secondary" onClick={handleCancel}>{window.i18('CANCEL')}</button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default CardForm;
