export const FETCH_USER_TICKETS_BEGIN = 'account/FETCH_USER_TICKETS_BEGIN';
export const FETCH_USER_TICKETS_ERROR = 'account/FETCH_USER_TICKETS_ERROR';
export const FETCH_USER_TICKETS_SUCCESS = 'account/FETCH_USER_TICKETS_SUCCESS';
export const FETCH_USER_ARCHIVES_BEGIN = 'account/FETCH_USER_ARCHIVES_BEGIN';
export const FETCH_USER_ARCHIVES_ERROR = 'account/FETCH_USER_ARCHIVES_ERROR';
export const FETCH_USER_ARCHIVES_SUCCESS = 'account/FETCH_USER_ARCHIVES_SUCCESS';
export const FETCH_USER_DOCUMENTS_BEGIN = 'account/FETCH_USER_DOCUMENTS_BEGIN';
export const FETCH_USER_DOCUMENTS_ERROR = 'account/FETCH_USER_DOCUMENTS_ERROR';
export const FETCH_USER_DOCUMENTS_SUCCESS = 'account/FETCH_USER_DOCUMENTS_SUCCESS';
export const FETCH_USER_APPROVALS_BEGIN = 'account/FETCH_USER_APPROVALS_BEGIN';
export const FETCH_USER_APPROVALS_ERROR = 'account/FETCH_USER_APPROVALS_ERROR';
export const FETCH_USER_APPROVALS_SUCCESS = 'account/FETCH_USER_APPROVALS_SUCCESS';
export const SET_USER_DOCUMENTS_BEGIN = 'account/SET_USER_DOCUMENTS_BEGIN';
export const SET_USER_DOCUMENTS_ERROR = 'account/SET_USER_DOCUMENTS_ERROR';
export const SET_USER_DOCUMENTS_SUCCESS = 'account/SET_USER_DOCUMENTS_SUCCESS';
export const FETCH_USER_CARDS_BEGIN = 'account/FETCH_USER_CARDS_BEGIN';
export const FETCH_USER_CARDS_ERROR = 'account/FETCH_USER_CARDS_ERROR';
export const FETCH_USER_CARDS_SUCCESS = 'account/FETCH_USER_CARDS_SUCCESS';
export const SET_USER_CARDS_BEGIN = 'account/SET_USER_CARDS_BEGIN';
export const SET_USER_CARDS_ERROR = 'account/SET_USER_CARDS_ERROR';
export const SET_USER_CARDS_SUCCESS = 'account/SET_USER_CARDS_SUCCESS';
export const GET_CDS_URL_BEGIN = 'account/GET_CDS_URL_BEGIN';
export const GET_CDS_URL_ERROR = 'account/GET_CDS_URL_ERROR';
export const GET_CDS_URL_SUCCESS = 'account/GET_CDS_URL_SUCCESS';
export const SET_SELECTED_TICKETS = 'account/SET_SELECTED_TICKETS';
export const SET_SELECTED_PENDING = 'account/SET_SELECTED_PENDING';
export const SET_SELECTED_APPROVALS = 'account/SET_SELECTED_APPROVALS';
export const SENDING_APPROVAL_BEGIN = 'account/SENDING_APPROVAL_BEGIN';
export const SENDING_APPROVAL_ERROR = 'account/SENDING_APPROVAL_ERROR';
export const SENDING_APPROVAL_SUCCESS = 'account/SENDING_APPROVAL_SUCCESS';
export const SENDING_CANCEL = 'account/SENDING_CANCEL';
export const SENT_CANCEL = 'account/SENT_CANCEL';
