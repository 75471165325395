import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import Select, { components } from 'react-select';
import Input from '../Inputs/Material';
import { getSvgIcon } from '../../utils/icons';

const icons = {
  airline: () => getSvgIcon('airplane', 'transport/svg/'),
  rail: () => getSvgIcon('tgv', 'transport/svg/'),
  hotel: () => getSvgIcon('hotel', 'transport/svg/'),
  car_rental: () => getSvgIcon('car_rental', 'transport/svg/'),
};

const Option = (props) => {
  const getIcon = icons[props.data.transport];
  return (
    <components.Option {...props}>
      {getIcon()}
      {props.label}
    </components.Option>
  );
};

const MenuList = (props) => (
  <components.MenuList {...props}>
    {props.children.length ? props.children.slice(0, 30) : 'Aucun résultat'}
  </components.MenuList>
);

const CardForm = ({
  toggleForm, cardsList, index, arrayHelpers, cards,
}) => {
  useEffect(() => {
    const modalWrapper = document.querySelector('.modal-wrapper');
    modalWrapper.style.overflowY = 'visible';
    return () => {
      modalWrapper.style.overflowY = 'auto';
    };
  }, []);
  return (
    <Formik
      initialValues={
        index !== null
          ? cards[index]
          : {
            cardtype_id: '',
            cardnumber: null,
          }
      }
      validate={(values) => {
        const errors = {};
        if (values.cardtype_id === '') {
          errors.cardtype_id = window.i18('ENTER_CARD_TYPE');
        }
        const currentCard = values.cardtype_id && cardsList.find((c) => c.uid === parseInt(values.cardtype_id, 10));
        // We ask for the number of loyalty and rail cards
        const withNumber = currentCard.card_type === 'loyalty_card' || currentCard.type === 'rail';
        if (withNumber) {
          if (values.cardnumber === null || values.cardnumber === '') {
            errors.cardnumber = window.i18('CARD_NUMBER_REQUIRED');
          } else if (!new RegExp('^[0-9]*$').test(values.cardnumber)) {
            errors.cardnumber = window.i18('ENTER_NUMBERS_ONLY');
          }
        }
        return errors;
      }}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (values) => {
        values.cardtype_id = parseInt(values.cardtype_id, 10);
        if (index !== null) {
          await arrayHelpers.remove(index);
          await arrayHelpers.insert(index, values);
        } else {
          await arrayHelpers.push(values);
        }
        toggleForm();
      }}
    >
      {({
        errors, values, setFieldValue, touched,
      }) => {
        const currentCard = values.cardtype_id && cardsList.find((c) => c.uid === parseInt(values.cardtype_id, 10));
        // We ask for the number of loyalty and rail cards
        const withNumber = currentCard.card_type === 'loyalty_card' || currentCard.type === 'rail';
        return (
          <Form className="modal-inner">
            <div className="passenger-profile__body">
              <div className="row m-bot">
                <Select
                  name="cardtype_id"
                  options={cardsList.map((c) => ({
                    label: c.name,
                    value: c.uid,
                    transport: c.type,
                  }))}
                  value={{
                    value: values.cardtype_id,
                    label: values.cardtype_id !== ''
                      ? cardsList.find((c) => c.uid === values.cardtype_id)?.name
                      : window.i18('TYPE_IN_CARD_NAME'),
                  }}
                  onChange={({ value }) => setFieldValue('cardtype_id', value)}
                  components={{ Option, MenuList }}
                  className="card-input"
                  classNamePrefix="card-input"
                />
              </div>
              {withNumber && (
                <div className="row">
                  <Input
                    onChange={(value) => setFieldValue('cardnumber', value)}
                    name="cardnumber"
                    value={values.cardnumber}
                    placeholder="0123456789"
                    type="text"
                    label={window.i18('NUMBER')}
                    error={errors.cardnumber}
                    touched={touched.cardnumber}
                  />
                </div>
              )}
              <div className="row justify-center m-top p-top">
                <button type="submit" className="button-main m-right">
                  {window.i18('ADD')}
                </button>
                <button
                  type="button"
                  className="button-secondary m-left"
                  onClick={toggleForm}
                >
                  {window.i18('CANCEL')}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CardForm;
