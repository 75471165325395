import { combineReducers } from 'redux';
import {
  RESET_RESULTS,
  SET_SEARCHED_HOTELS,
  SEARCH_HOTEL_START,
  SEARCH_HOTEL_ERROR,
  SEARCH_HOTEL_SUCCESS,
  SET_SELECTED_RESULT,
  SET_SELECTED_DETAILS_BEGIN,
  SET_SELECTED_DETAILS_SUCCESS,
  SET_SELECTED_DETAILS_ERROR,
  SET_STARS,
  TOGGLE_BREAKFAST,
  TOGGLE_LOYALTY_ENABLED,
  SET_PRICE_RANGE,
  SET_PRICES,
  ADD_SELECTED_OFFER,
  REMOVE_SELECTED_OFFER,
  TOGGLE_TRAVELERS_DISTRIBUTION,
  FILL_TRAVELER,
  ADD_TO_CART_BEGIN,
  ADD_TO_CART_ERROR,
  ADD_TO_CART_SUCCESS,
  ASSIGN_TRAVELER_TO_OFFER,
  TOGGLE_MAP,
  SET_SELECTED_MARKER,
  TOGGLE_ACCESSIBLE,
  SET_SORTING,
} from './constants';

function resultsReducer(state = null, { type, results }) {
  switch (type) {
    case RESET_RESULTS:
      return null;
    case SEARCH_HOTEL_SUCCESS:
      return results;
    default:
      return state;
  }
}

function searchReducer(state = null, { type, search }) {
  switch (type) {
    case SET_SEARCHED_HOTELS:
      return search;
    default:
      return state;
  }
}

function loadingReducer(state = false, { type }) {
  switch (type) {
    case SEARCH_HOTEL_START:
      return true;
    case SEARCH_HOTEL_SUCCESS:
    case SEARCH_HOTEL_ERROR:
    case RESET_RESULTS:
      return false;
    default:
      return state;
  }
}

const selectedResultProps = {
  id: null, selectedOffers: [], travelersDistribution: false,
};
function selectedResultReducer(
  state = selectedResultProps,
  {
    type, id, details, maxOccupancy, pricePerNight, traveler, travelerIndex, offerId, img,
  },
) {
  switch (type) {
    case SET_SELECTED_RESULT:
      return {
        ...selectedResultProps,
        id,
      };
    case SET_SELECTED_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case SET_SELECTED_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details,
      };
    case SET_SELECTED_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ADD_SELECTED_OFFER:
      return {
        ...state,
        selectedOffers: [
          {
            id,
            offerId,
            maxOccupancy,
            pricePerNight,
            travelers: new Array(maxOccupancy),
            img,
          },
          ...state.selectedOffers,
        ],
      };
    case REMOVE_SELECTED_OFFER: {
      const { selectedOffers } = state;
      const index = selectedOffers.findIndex((offer) => offer.offerId === id);
      selectedOffers.splice(index, 1);
      return {
        ...state,
        selectedOffers,
      };
    }
    case TOGGLE_TRAVELERS_DISTRIBUTION:
      return {
        ...state,
        travelersDistribution: !state.travelersDistribution,
      };
    case ASSIGN_TRAVELER_TO_OFFER:
      return {
        ...state,
        selectedOffers: state.selectedOffers.map((offer) => {
          if (offer.id === id) {
            offer.travelers[travelerIndex] = traveler === '' ? null : traveler;
            if (offer.travelers.find((t, i) => t === traveler && i !== travelerIndex)) {
              const indexToRemove = offer.travelers.findIndex((t, i) => t === traveler && i !== travelerIndex);
              offer.travelers.splice(indexToRemove, 1, null);
            }
          } else if (offer.travelers.find((t) => t === traveler)) {
            const indexToRemove = offer.travelers.findIndex((t) => t === traveler);
            offer.travelers.splice(indexToRemove, 1, null);
          }
          return offer;
        }),
      };
    case RESET_RESULTS:
      return selectedResultProps;
    default:
      return state;
  }
}

function starsReducer(state = 0, action) {
  switch (action.type) {
    case SET_STARS:
      return action.count;
    default:
      return state;
  }
}

function loyaltyEnabledReducer(state = false, action) {
  switch (action.type) {
    case TOGGLE_LOYALTY_ENABLED:
      return !state;
    default:
      return state;
  }
}

function breakfastReducer(state = false, action) {
  switch (action.type) {
    case TOGGLE_BREAKFAST:
      return !state;
    default:
      return state;
  }
}

function accessibleReducer(state = false, action) {
  switch (action.type) {
    case TOGGLE_ACCESSIBLE:
      return !state;
    default:
      return state;
  }
}

function priceRangeReducer(state = { min: 0, max: null }, action) {
  switch (action.type) {
    case SET_PRICE_RANGE:
      return {
        min: action.min,
        max: action.max,
      };
    default:
      return state;
  }
}

function pricesReducer(state = null, action) {
  switch (action.type) {
    case SET_PRICES:
      return {
        min: action.min,
        max: action.max,
      };
    default:
      return state;
  }
}

function sortingReducer(state = 'BY_PRICE', action) {
  switch (action.type) {
    case SET_SORTING:
      return action.sorting;
    default:
      return state;
  }
}

function travelersReducer(state = [], action) {
  switch (action.type) {
    case SEARCH_HOTEL_SUCCESS:
      return action.travelers;
    case FILL_TRAVELER:
      return state.map((traveler) => {
        if (traveler.id === action.traveler.id) {
          return action.traveler;
        }
        return traveler;
      });
    default:
      return state;
  }
}

function addingToCartReducer(state = false, action) {
  switch (action.type) {
    case ADD_TO_CART_BEGIN:
      return true;
    case ADD_TO_CART_ERROR:
    case ADD_TO_CART_SUCCESS:
      return false;
    default:
      return state;
  }
}

function displayMapReducer(state = true, action) {
  if (action.type === TOGGLE_MAP) {
    state = !state;
  }
  return state;
}

function selectedMarkerReducer(state = null, action) {
  if (action.type === SET_SELECTED_MARKER) {
    state = action.id;
  }
  return state;
}

const filtersReducer = combineReducers({
  stars: starsReducer,
  breakfast: breakfastReducer,
  loyaltyEnabled: loyaltyEnabledReducer,
  priceRange: priceRangeReducer,
  prices: pricesReducer,
  accessible: accessibleReducer,
});

const hotelResultsReducer = combineReducers({
  results: resultsReducer,
  search: searchReducer,
  loading: loadingReducer,
  selectedResult: selectedResultReducer,
  filters: filtersReducer,
  travelers: travelersReducer,
  addingToCart: addingToCartReducer,
  displayMap: displayMapReducer,
  selectedMarker: selectedMarkerReducer,
  sorting: sortingReducer,
});

export default hotelResultsReducer;
