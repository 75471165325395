import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Components
import Trips from './Trips';
import TransportComparisons from '../../../components/TransportComparisons';
import OfferSelector from './OfferSelector';
import TransportSwitcher from '../../../components/TransportSwitcher';
// Misc
import { setSelectedProposal, setSelectedTransportMode } from '../actions';
import Filters from '../Filters';
import Timeline from '../Timeline';

function MainTransport() {
  const { isMobileDevice } = useSelector((state) => state.app);
  const { currentTrip } = useSelector((state) => state.results);
  const {
    selectedTransportMode, transports, selectedProposal, podium, warns,
  } = useSelector((state) => state.results[currentTrip]);
  const dispatch = useDispatch();
  const [showOutward, setShowOutward] = useState(false);

  return (
    <>
      {!isMobileDevice && (
        <TransportComparisons stats={podium} />
      )}
      {showOutward && currentTrip === 'returnTrip' && (
        <Timeline />
      )}
      {warns && (
      <div className="warns m-top" dangerouslySetInnerHTML={{ __html: warns.join('. ') }} />
      )}
      <div className="flex align-center">
        <h2>{window.i18('MAIN_TRIP')}</h2>
        {currentTrip === 'returnTrip' && (
          <button onClick={() => setShowOutward(!showOutward)} className="m-left button-secondary">{window.i18('SEE_OUTWARD_TICKET')}</button>
        )}
      </div>
      {(!isMobileDevice || !selectedProposal[selectedTransportMode]) && (
        <TransportSwitcher items={transports} selected={selectedTransportMode} setSelected={(transportMode) => dispatch(setSelectedTransportMode(transportMode))} />
      )}
      {isMobileDevice && selectedProposal[selectedTransportMode] && (
        <button className="button-secondary m-bot" onClick={() => dispatch(setSelectedProposal(null))}>{window.i18('RETURN_TO_LIST')}</button>
      )}
      <div className="row">
        <div className="col col-12">
          <Filters />
        </div>
      </div>
      <div className="trip-results row">
        {(!isMobileDevice || !selectedProposal[selectedTransportMode]) && (
          <Trips />
        )}
        {(!isMobileDevice || selectedProposal) && (
          <OfferSelector />
        )}
      </div>
    </>
  );
}

export default MainTransport;
