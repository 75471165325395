import React from 'react';

const Rocket = (props) => (
  <div className="rocket-svg-wrapper">
    <div className="rocket-illustration-wrapper loader-animation-appear">
      <span className="loader-illustration rocket-animation" dangerouslySetInnerHTML={createSvg(rocketSvg)} />
    </div>
    <div className="loader-copy-wrapper loader-animation-appear">
      {!props.isCheckout && <h3 className="loader-copy common-dark-grey-font rocket-copy">{!props.returnTrip ? window.i18('LETS_GO') : window.i18('SEARCHING_RETURN')}</h3>}
      {props.isCheckout && <h3 className="loader-copy common-dark-grey-font rocket-copy">{window.i18('PAYING')}</h3>}
    </div>
  </div>
);
export default Rocket;

function createSvg(svg) {
  return { __html: svg };
}

const rocketSvg = `<svg class="rocket-svg" width='88' height='168' viewBox='0 0 88 168' xmlns='http://www.w3.org/2000/svg'>
<g id='Page-1' fill='none' fillRule='evenodd'>
    <g id='Artboard-2'>
        <g id='Group' transform='translate(-14 5)'>
            <g id='treep' transform='translate(47 36)' fill='#01DCBE'>
                <path d='M14.8549544,11.9267809 L16.4487077,13.5771607 C16.7137549,13.8516252 17.1511155,13.8592593 17.4255799,13.5942121 C17.7000444,13.3291649 17.7076785,12.8918043 17.4426313,12.6173398 L15.41086,10.5133791 C15.215695,10.3112798 14.9271021,10.2538552 14.6789111,10.3434412 C14.5567599,10.3674894 14.4396081,10.4248386 14.3418581,10.515992 L12.2027585,12.5107347 C11.9237102,12.7709514 11.9084442,13.2081122 12.168661,13.4871606 C12.4288777,13.7662089 12.8660385,13.7814749 13.1450869,13.5212581 L14.8549544,11.9267809 Z M21.9859698,18.0780209 C21.8055209,22.416746 18.5574408,24.9476689 15.6702586,24.9476689 C12.7830763,24.9476689 10.4372407,22.2359657 11.1590363,21.6936251 C15.5172481,20.4710853 15.6702586,16.450999 15.6702586,15.3663178 C15.6702586,14.2816365 14.2266674,14.2816365 14.2266674,15.3663178 C14.2266674,16.450999 12.9635252,20.0666032 10.4372407,19.885823 C8.45230289,19.1627022 8.81320067,12.4738344 9.89589402,8.49666984 C5.38467172,15.0047574 7.91095621,20.4281637 9.89589402,24.7668887 C5.08392357,25.6105297 2.01629241,24.164288 0.693000532,20.4281637 C-1.59268543,14.0405963 1.77569388,7.23120837 10.7981385,-2.84217094e-14 C20.7228275,6.68886775 22.1664187,13.7392959 21.9859698,18.0780209 Z'
                id='Combined-Shape' />
            </g>
            <g id='rocket' stroke='#414E6A' strokeWidth='4'>
                <path class="rocket-flamme" d='M25.5,102 L25.5,145.848276' id='Path-3' strokeLinecap='round'
                />
                <path class="rocket-flamme" d='M57.5,131 L57.5,154.846154' id='Path-3' strokeLinecap='round'
                />
                <path class="rocket-flamme" d='M89.5,96 L89.5,137.846715' id='Path-3' strokeLinecap='round'
                />
                <g id='spaceship' transform='rotate(-45 70.02 28.98)'>
                    <path d='M52.1818182,7.14393939 L74.5454545,29.5075758' id='Shape' />
                    <path d='M43.7954545,12.0727463 C32.7855379,7.35419666 19.5253918,9.46779184 10.5338971,18.4172827 C8.68885968,20.2533162 7.16797595,22.2806422 5.90151515,24.4167424 L18.8205461,37.2727273'
                    id='Shape' />
                    <path d='M69.8467137,38.2045455 C74.6719065,49.2072223 72.576437,62.4764805 63.5921114,71.486517 C61.7604465,73.3234236 59.7379442,74.8376051 57.6069265,76.0984848 L44.7272727,63.1819727'
                    id='Shape' />
                    <path d='M34.2625263,68.9545455 L13.0454545,47.7374737 C13.0454545,47.7374737 31.1682918,2.65166514 82,0 C79.1270502,50.6104235 34.2625263,68.9545455 34.2625263,68.9545455 Z'
                    id='Shape' strokeLinecap='square' />
                    <path d='M0,72.6818182 C0,67.5363182 4.17268182,63.3636364 9.31818182,63.3636364 C14.4636818,63.3636364 18.6363636,67.5363182 18.6363636,72.6818182 C18.6363636,77.8273182 14.4636818,82 9.31818182,82 C7.22159091,82 0,82 0,82 C0,82 0,75.7325909 0,72.6818182 Z'
                    id='Shape' strokeLinecap='square' />
                </g>
            </g>
        </g>
    </g>
</g>
</svg>`;
