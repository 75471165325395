import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import API from '../../utils/api';

import { isValidDate } from '../../utils/functions';
import Form from './Form';
import Details from './Details';

import {
  setPickUpLocation,
  setReturnLocation,
  invertLocations,
  editDriver,
  addDriver,
  selectDriver,
  setPickUpDate,
  setReturnDate,
  searchCarRentals,
  checkForm,
  setCarRentalTime,
  setCarTypes,
} from './actions';

import { openModal } from '../ModalWrapper/actions';
import { setEntity } from '../Search/actions';
import { searchTravelers } from '../App/actions';

const source = axios.CancelToken.source();

class SearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pickUpLocation: { value: '', places: null, searching: false },
      returnLocation: { value: '', places: null, searching: false },
      dates: {
        a: this.props.search.date.a.date
          ? moment(this.props.search.date.a.date).format('DD/MM/YYYY')
          : '',
        r: this.props.search.date.r.date
          ? moment(this.props.search.date.r.date).format('DD/MM/YYYY')
          : '',
      },
    };

    this.typingTimeout = null;
  }

  componentDidMount() {
    if (this.props.search.pickUpLocation.location) {
      this.setState({
        pickUpLocation: {
          value: this.props.search.pickUpLocation.location.description ? this.props.search.pickUpLocation.location.description : this.props.search.pickUpLocation.location.name,
          places: null,
          searching: null,
        },
      });
    }
    if (this.props.search.returnLocation.location) {
      this.setState({
        returnLocation: {
          value: this.props.search.returnLocation.location.description ? this.props.search.returnLocation.location.description : this.props.search.returnLocation.location.name,
          places: null,
          searching: null,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search.pickUpLocation !== this.props.search.pickUpLocation && this.props.search.pickUpLocation.location) {
      this.changeSearchLocation(
        this.props.search.pickUpLocation.location.description ? this.props.search.pickUpLocation.location.description : this.props.search.pickUpLocation.location.name,
        'pickUpLocation',
        false,
      );
    }
    if (prevProps.search.returnLocation !== this.props.search.returnLocation && this.props.search.returnLocation.location) {
      this.changeSearchLocation(
        this.props.search.returnLocation.location.description ? this.props.search.returnLocation.location.description : this.props.search.returnLocation.location.name,
        'returnLocation',
        false,
      );
    }
  }

  setSelected = (field) => {
    this.setState({
      selected: field,
    });
  };

  changeSearchLocation = (value, field, search = true) => {
    if (!value) {
      this.setState({
        [field]: { value: '' },
      });
      return;
    }
    this.setState({
      [field]: { ...this.state[field], value },
    });
    clearTimeout(this.typingTimeout);
    if (value.length && search) {
      this.typingTimeout = setTimeout(async () => {
        this.setState({ [field]: { ...this.state[field], searching: true } });
        const places = await this.getPlaces(value);
        this.setState({
          [field]: { ...this.state[field], places, searching: false },
        });
      }, 300);
    } else {
      if (field === 'pickUpLocation' && this.state.selected === 'pickUpLocation') {
        this.setSelected('returnLocation');
        document.querySelector('#returnLocation').focus();
      } else if (field === 'returnLocation' && this.state.selected === 'returnLocation') {
        this.setSelected('pickUpDate');
      }
      if (!search) {
        this.setState({
          [field]: { ...this.state[field], value, searching: false },
        });
      } else {
        this.setState({ [field]: { value, places: null } });
      }
      if (document.querySelector('#returnLocation') === document.activeElement) {
        document.querySelector('#returnLocation').addEventListener('keydown', (e) => {
          if (e.which === 13) document.querySelector('#returnLocation').blur();
        });
      }
    }
  }

  getPlaces = (q) => new Promise((resolve) => {
    const params = { q };
    API.get('/places', {
      cancelToken: source.token,
      params,
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;

        resolve([]);
      });
  });

  onBlur = (field) => {
    if (this.props.search[field] !== this.state[field].value) {
      this.setState({
        [field]: {
          ...this.state[field],
          value: this.props.search[field].description ? this.props.search[field].description : this.props.search[field].name,
        },
      });
    }
  };

  changeDate = (date, trip) => {
    date = moment(date, ['DD-MM-YYYY', 'D/M/YYYY']); // We have to parse it first because if we get the raw input, it's gonna look like 20/08/2018 while the whole thing expects a moment object
    if (!isValidDate(date)) return;
    if (trip === 'a') {
      this.props.setPickUpDate(date);
      // If the return date is set before the outward date, we need to change it
      if (moment(this.props.search.date.r.date).isBefore(date)) {
        this.props.setReturnDate(moment(date).add(1, 'days'));
      }
      this.setSelected('returnDate');
    } else {
      this.props.setReturnDate(date);
    }
  };

  changeDateInput = (field, date) => {
    if (date) {
      this.setState({
        dates: {
          ...this.state.dates,
          [field]: date,
        },
      });
      this.changeDate(date, field);
    } else {
      this.setState({
        dates: {
          ...this.state.dates,
          [field]: moment(this.props.search.date[field].date).format(
            'DD/MM/YYYY',
          ),
        },
      });
    }
  };

  render() {
    return (
      <div className="search-form">
        <Form
          {...this.props}
          setSelected={this.setSelected}
          selected={this.state.selected}
          changeSearchLocation={this.changeSearchLocation}
          pickUpLocation={this.state.pickUpLocation}
          returnLocation={this.state.returnLocation}
          dates={this.state.dates}
          handleSearchBlur={this.onBlur}
          changeDate={this.changeDate}
          changeDateInput={this.changeDateInput}
        />
        <Details
          {...this.props}
          selected={this.state.selected}
          changeSearchLocation={this.changeSearchLocation}
          pickUpLocation={this.state.pickUpLocation}
          returnLocation={this.state.returnLocation}
          changeDate={this.changeDate}
          setTripTime={this.props.setCarRentalTime}
          changeDateInput={this.changeDateInput}
          co2={this.props.co2}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  search: state.carRentalSearch,
  travelers: state.app.travelers,
  co2: state.app.stats && state.app.stats.co2,
  userPermissions: state.app.userPermissions,
  carTypes: state.carRentalSearch.carTypes,
  entities: state.app.entities,
  entityID: state.search.entityID,
  hasTravelersSearch: state.app.hasTravelersSearch,
  isMobileDevice: state.app.isMobileDevice,
  suggestedAddresses: state.app.user?.company?.config?.suggestedAddresses,
});

const mapDispatchToProps = (dispatch) => ({
  setPickUpLocation: (location) => dispatch(setPickUpLocation(location)),
  setReturnLocation: (location) => dispatch(setReturnLocation(location)),
  invertLocations: () => dispatch(invertLocations()),
  editDriver: (passenger) => dispatch(editDriver(passenger)),
  addDriver: (passenger) => dispatch(addDriver(passenger)),
  setPickUpDate: (date) => dispatch(setPickUpDate(date)),
  setReturnDate: (date) => dispatch(setReturnDate(date)),
  setCarRentalTime: (field, time) => dispatch(setCarRentalTime(field, time)),
  searchCarRentals: () => dispatch(searchCarRentals()),
  checkForm: () => dispatch(checkForm()),
  openModal: (options = null) => dispatch(openModal('Driver', options)),
  setCarTypes: (carTypes) => dispatch(setCarTypes(carTypes)),
  setEntity: (entity) => dispatch(setEntity(entity)),
  searchTravelers: (query) => dispatch(searchTravelers(query)),
  selectDriver: (id) => dispatch(selectDriver(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchForm);
