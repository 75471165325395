import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isCNAM } from '../../utils/functions';

function UserMenu({ onLogout }) {
  const sandboxMode = useSelector((state) => state.app.sandboxMode);

  return (
    <div className="user-menu">
      <ul className="user-menu-list">
        {!sandboxMode && (
        <Link to="/account/">
          <li data-testid="my-space-link" className="user-menu-item">
            <span className="link-label">{isCNAM() ? 'Mes transports réalisés' : window.i18('MY_SPACE')}</span>
          </li>
        </Link>
        )}
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <li data-testid="logout-link" className="user-menu-item" onClick={onLogout}>
          {window.i18('LOG_OUT')}
        </li>
      </ul>
    </div>
  );
}

export default UserMenu;
