import React from 'react';
// Components
import FilterContainer from '../../../../components/FilterContainer';
import Checkbox from '../../../../components/Checkbox';
// Functions

const propTypes = {
};

function RentersFilter({ excludedRenters, setExcludedRenters, renters }) {
  const handleChange = (renter) => {
    const values = [...excludedRenters];
    if (excludedRenters.includes(renter)) {
      values.splice(values.indexOf(renter), 1);
    } else {
      values.push(renter);
    }
    setExcludedRenters(values);
  };

  const toggleAllRenters = () => {
    if (renters.every((r) => !excludedRenters.includes(r))) {
      setExcludedRenters(renters);
    } else {
      setExcludedRenters([]);
    }
  };

  return (
    <FilterContainer btnText={window.i18('RENTER')} active={excludedRenters.length > 0}>
      <>
        <ul>
          <li>
            <Checkbox checked={renters.every((r) => !excludedRenters.includes(r))} onChange={toggleAllRenters} value={window.i18('SELECT_ALL')} />
          </li>
          {renters.map((renter) => (
            <li key={renter}>
              <Checkbox checked={!excludedRenters.includes(renter)} name="car-type" onChange={() => handleChange(renter)} value={renter} />
            </li>
          ))}
        </ul>
      </>
    </FilterContainer>
  );
}

RentersFilter.propTypes = propTypes;

export default RentersFilter;
