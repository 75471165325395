export const FETCHING_CART = 'cart/FETCHING_CART';
export const FETCHING_CART_FAILED = 'cart/FETCHING_CART_FAILED';
export const RECEIVE_CART = 'cart/RECEIVE_CART';
export const DELETING_FROM_CART = 'cart/DELETING_FROM_CART';
export const DELETING_FROM_CART_DONE = 'cart/DELETING_FROM_CART_DONE';
export const FETCHING_PAYMENT_INFO = 'cart/FETCHING_PAYMENT_INFO';
export const RECEIVE_PAYMENT_INFO = 'cart/RECEIVE_PAYMENT_INFO';
export const TOGGLE_CART_SUBSET = 'cart/TOGGLE_CART_SUBSET';
export const FETCHING_CART_AMOUNT = 'cart/FETCHING_CART_AMOUNT';
export const RECEIVE_CART_AMOUNT = 'cart/RECEIVE_CART_AMOUNT';
export const UPDATE_CART_AMOUNT_FETCHING_TICKETS = 'cart/UPDATE_CART_AMOUNT_FETCHING_TICKETS';
export const RESET_CART = 'cart/RESET_CART';
