import React from 'react';
import Container from './Container';
import Counter from '../Counter';

const Default = ({ co2, isMobileDevice }) => (
  <Container isMobileDevice={isMobileDevice} isDefault>
    <ul className="search-form__details__infos">
      <li className="search-form__details__infos__item">
        <div className="number">
          <span>1</span>
        </div>
        <div className="text">
          <h2>{window.i18('COMPARE_AND_RESERVE')}</h2>
          <p>{window.i18('COMPARE_AND_RESERVE_TEXT')}</p>
        </div>
      </li>
      <li className="search-form__details__infos__item">
        <div className="number">
          <span>2</span>
        </div>
        <div className="text">
          <h2>{window.i18('PAYED_AND_APPROVED')}</h2>
          <p>{window.i18('PAYED_AND_APPROVED_TEXT')}</p>
        </div>
      </li>
      <li className="search-form__details__infos__item">
        <div className="number">
          <span>3</span>
        </div>
        <div className="text">
          <h2>{window.i18('PRESERVE_ENV')}</h2>
          <p>{window.i18('PRESERVE_ENV_TEXT')}</p>
        </div>
      </li>
      {co2 && co2.global_savings
        && (
        <li className="counter-container">
          <h2 className="counter-container__title">
            {window.i18('TOTAL_CO2_ECO')}
          </h2>
          <Counter value={co2.global_savings / 1000} delay={1} />
        </li>
        )}
    </ul>
  </Container>
);

export default Default;
