import React from 'react';
import classNames from 'classnames';
import TicketTagsTooltip from '../TicketTagsTooltip';
import { displayPrice } from '../../utils/converters';

export default function TripPriceButton({
  choice: { price, tags, minimumReturnPrice }, selected, onClick,
}) {
  const isNegociatedFare = tags?.indexOf('negociated-fare') > -1;
  const isMissionaryFare = tags?.indexOf('ngo-fare') > -1;
  const isCompliant = tags?.indexOf('compliant') > -1;

  return (
    <button className={classNames('result-item-prices__btn', 'common-tooltip__parent', { 'result-item-prices__btn--selected': selected })} onClick={onClick}>
      <div style={{
        top: 10, right: 10, position: 'absolute', display: 'flex',
      }}
      >
        {(isNegociatedFare || isMissionaryFare) && (
          <span style={{
            backgroundColor: '#7BBEDF',
            width: 10,
            height: 10,
            borderRadius: '50%',
            marginLeft: 5,
            display: 'block',
          }}
          />
        )}
        {isCompliant && (
          <span style={{
            backgroundColor: '#01dcbe',
            width: 10,
            height: 10,
            borderRadius: '50%',
            marginLeft: 5,
            display: 'block',
          }}
          />
        )}
      </div>
      {displayPrice(price / 100)}
      <TicketTagsTooltip tags={tags} />
      {minimumReturnPrice && (
        <p className="roundTrip">
          {window.i18('ROUND_TRIP')}
          {' '}
          {window.i18('STARTING_FROM')}
          {' '}
          <strong>{displayPrice(minimumReturnPrice / 100)}</strong>
        </p>
      )}
    </button>
  );
}
