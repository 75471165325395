import * as yup from 'yup';
import moment from 'moment';
import { toast as Notif } from 'react-toastify';

import { push } from 'connected-react-router';
import {
  setSearchedHotels,
  resetResults,
  searchHotelStart,
  searchHotelError,
  searchHotelSuccess,
} from '../HotelResults/actions';
import {
  SET_PLACE_LOCATION,
  SET_PLACE_RADIUS,
  SET_PLACE_TOUCHED,
  SET_ARRIVAL_DATE,
  SET_DEPARTURE_DATE,
  SET_ARRIVAL_TOUCHED,
  SET_DEPARTURE_TOUCHED,
  ADD_PASSENGER,
  EDIT_PASSENGER,
  REMOVE_PASSENGER,
  SET_FORM_ERRORS,
  FILL_USER_INFO,
  TOGGLE_SELECTED_PASSENGER,
  ADD_ROOM,
  REMOVE_ROOM,
  SET_NAME_CONTAINS,
  ADD_LAST_TRAVELERS,
  SET_ENTITY_TOUCHED,
  SET_SEARCH_PARAMS,
} from './constants';
import API from '../../utils/api';
// import { formatTimeToObject } from '../../utils/converters'
// import { NotificationManager as Notif } from 'react-notifications'

export function setPlaceLocation(location) {
  return (dispatch) => {
    dispatch({ type: SET_PLACE_TOUCHED });
    dispatch({
      type: SET_PLACE_LOCATION,
      location,
    });
    dispatch(checkForm());
  };
}

export function setPlaceRadius(radius) {
  return {
    type: SET_PLACE_RADIUS,
    radius,
  };
}

export function addPassenger(passenger = {}) {
  return (dispatch) => {
    const isDisabled = passenger.handicap && passenger.handicap.length > 0;
    if (isDisabled) {
      passenger.isDisabled = isDisabled;
    }
    dispatch({
      type: ADD_PASSENGER,
      passenger,
    });
    dispatch(checkForm());
  };
}

export function removePassenger(id) {
  return {
    type: REMOVE_PASSENGER,
    id,
  };
}

export function editPassenger(passenger) {
  return {
    type: EDIT_PASSENGER,
    passenger,
  };
}

export function setArrivalDate(date) {
  return (dispatch) => {
    dispatch({
      type: SET_ARRIVAL_DATE,
      date,
    });
    dispatch({ type: SET_ARRIVAL_TOUCHED });
    dispatch(checkForm());
  };
}

export function setDepartureDate(date) {
  return (dispatch) => {
    dispatch({
      type: SET_DEPARTURE_DATE,
      date,
    });
    dispatch({ type: SET_DEPARTURE_TOUCHED });
    dispatch(checkForm());
  };
}

export function setFormErrors(errors) {
  return {
    type: SET_FORM_ERRORS,
    errors,
  };
}

export function checkForm() {
  return (dispatch, getState) => {
    try {
      const search = getState().hotelSearch;
      searchSchema.validateSync(search, { abortEarly: false });
      const { entities } = getState().app;
      if (entities && entities.length > 0) { // an entity can be selected => it is required.
        yup.object().shape({
          entityID: yup.mixed().required(window.i18('CHOSE_ENTITY')),
        }).validateSync(getState().search, { abortEarly: false });
      }
      dispatch(setFormErrors(null));
    } catch (err) {
      let errors = null;
      if (err.inner) {
        errors = err.inner.reduce((map, error) => {
          const path = error.path || error.type;
          map[path] = error.message;
          return map;
        }, {});
      }

      dispatch(setFormErrors(errors));
    }
  };
}

// This is the function that is called when we click on the "GO!" button.
// It just (re)sets the results object, and calls the setSearchedHotels fn
export function searchHotels() {
  return (dispatch, getState) => {
    const search = { ...getState().hotelSearch };
    search.passengers = search.passengers.filter((p) => p.selected).map((p) => {
      // To prevent an API error, we remove the uid if it is empty
      if (!p.uid) {
        delete p.uid;
      }
      return p;
    });

    dispatch({ type: SET_PLACE_TOUCHED });
    dispatch({ type: SET_ARRIVAL_TOUCHED });
    dispatch({ type: SET_DEPARTURE_TOUCHED });
    dispatch({ type: SET_ENTITY_TOUCHED });
    // We check the form
    dispatch(checkForm(search));

    // Check if any error surfaced
    if (getState().hotelSearch.errors) return;

    dispatch(resetResults());
    // We store the search made by the user (allows us to display his search even if he changes it, and to search for the return trip)
    dispatch(setSearchedHotels(search));
    // dispatch(searchTrip("a"))
    dispatch(getHotels(search));
  };
}

function getHotels({
  date,
  place,
  passengers,
  rooms,
  nameContains,
  travelID,
}) {
  return (dispatch, getState) => {
    const { user } = getState().app;
    const params = {
      checkin_date: `${moment(date.arrival).format('YYYYMMDD')}T120000`,
      checkout_date: `${moment(date.departure).format('YYYYMMDD')}T120000`,
      latitude: place.location.lat,
      longitude: place.location.lon,
      nb_rooms: rooms,
      radius_km: place.radius,
      travelers: passengers,
      name_contains: nameContains,
    };

    if (travelID) {
      params.travelID = travelID;
    }

    // If an entity is selected, we add it to the params
    if (getState().search.entityID) {
      params.entity_id = getState().search.entityID;
    }
    if (user && user.perms && user.perms.includes('travel_agent')) {
      params.customer_id = user.company_id;
    }

    dispatch(searchHotelStart());
    dispatch(push('/search-hotels'));
    API.post('hotel/search', params).then((res) => {
      if (res?.data?.result?.hotels?.length) {
        dispatch(searchHotelSuccess(res.data.result.hotels, passengers));
      } else {
        Notif.warning(window.i18('NO_RESULTS_SEARCH'), { autoClose: 8000 });
        dispatch(resetResults());
      }
    }).catch(() => {
      const msg = window.i18('HOTEL_SEARCH_ERROR');
      // Do not need this because we are catching everything localy now. See api.js interceptors
      /*
      if (error && error.data && error.data.msg) { msg = `${msg} : ${error.data.msg}`; }
      Notif.warning(msg, '', 8000);
      */
      dispatch(searchHotelError(msg));
      dispatch(resetResults());
    });
  };
}

// Validation schema for the search
const searchSchema = yup
  .object()
  .shape({
    date: yup.object().shape({
      arrival: yup
        .date('Veuillez entrer une date de début')
        .typeError("Le format n'est pas reconnu")
        .required()
        .min(
          moment().startOf('day'),
          "Veuillez entrer une date qui n'est pas passée",
        ),
      departure: yup
        .date('Veuillez entrer une date de fin')
        .typeError('Veuillez entrer une date de fin')
        .required()
        .min(
          moment().startOf('day'),
          "Veuillez entrer une date qui n'est pas passée",
        ),
    }),
    place: yup.object().shape({
      location: yup
        .object()
        .required()
        .shape({
          ID: yup.string().required("Veuillez remplir l'adresse de départ"),
        }),
      radius: yup.number().required(),
    }),
  })
  .test(
    'passengers',
    'Veuillez entrer au moins un passager',
    (schema) => schema.passengers.filter((p) => p.selected).length !== 0,
  );

// Checks wether the first passenger in our result list is the currently logged in user
// This is used to auto fill the first passenger as the logged in user
export function checkUserInfo(user) {
  return (dispatch, getState) => {
    // If the user isn't logged in, nothing to do here
    if (!user) return;

    const { userPermissions } = getState().app;
    const { passengers, actions, travelID } = getState().hotelSearch;
    const { userFilled, lastTravelersAdded } = actions;

    // If the user is a travel manager, we don't use his profile as default passenger
    if (userPermissions != null && userPermissions.isTravelManager && passengers.length === 1 && !travelID) {
      dispatch(removePassenger(0));
      return;
    }
    if (!lastTravelersAdded && user.last_travelers) {
      dispatch(addLastTravelers(user.last_travelers.slice(0, 10)));
    }

    user = { profile_id: user.uid, ...user };
    // If we did not already do that, we fill the user infos
    if (!userFilled) {
      dispatch(fillUser(user));
    }
  };
}

// Fills the first passenger in the list as the current logged in user
export function fillUser(user) {
  return {
    type: FILL_USER_INFO,
    user,
  };
}

export function addLastTravelers(travelers) {
  return {
    type: ADD_LAST_TRAVELERS,
    travelers,
  };
}

export function toggleSelectedPassenger(index) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_SELECTED_PASSENGER,
      index,
    });
    dispatch(checkForm());
  };
}

export function setNameContains(name) {
  return {
    type: SET_NAME_CONTAINS,
    name_contains: name,
  };
}

export function addRoom() {
  return {
    type: ADD_ROOM,
  };
}

export function removeRoom() {
  return {
    type: REMOVE_ROOM,
  };
}

export function setHotelSearchParams(params) {
  return {
    type: SET_SEARCH_PARAMS,
    ...params,
  };
}
