import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  FILTER_FULL_FLEX, FILTER_NO_FLEX, FILTER_SEMI_FLEX, FILTER_TRANSIT_FLIGHT, FILTER_TRANSIT_RAIL, FILTER_LUGGAGE,
} from './constants';
import { toggleFilter, setSorting } from './actions';
import FilterContainer from '../../components/FilterContainer';
import Checkbox from '../../components/Checkbox';

const Filters = () => {
  const dispatch = useDispatch();
  const isMobileDevice = useSelector((state) => state.app.isMobileDevice);
  const filters = new Set(useSelector((state) => state.results.filters));
  const { currentTrip, sorting } = useSelector((state) => state.results);
  const { selectedTransportMode } = useSelector((state) => state.results[currentTrip]);
  const carriers = useSelector((state) => state.results?.[currentTrip]?.carriers?.[selectedTransportMode]);
  const [showFilters, setShowFilters] = React.useState(false);

  const toggleAllCarriers = () => {
    const allSelected = carriers && Object.keys(carriers)?.every((c) => !filters.has(c));
    if (carriers) {
      Object.keys(carriers).forEach((carrier) => {
        if (filters.has(carrier) !== allSelected) {
          dispatch(toggleFilter(carrier));
        }
      });
    }
  };
  if (isMobileDevice) {
    return (
      <div className="user-tickets-filter" style={{ marginBottom: '5px' }}>
        <button className={`user-tickets-filter__toggle common-sub-title ${showFilters ? 'on' : 'off'}`} onClick={() => setShowFilters(!showFilters)}>{window.i18('FILTER_AND_SORT')}</button>
        <div className={`user-tickets-filter__container ${showFilters ? 'show' : 'hide'}`} id="results-filters">
          <p>{window.i18('FILTERS')}</p>
          <FilterContainer btnText={window.i18('COMPANIES')} active={carriers && !Object.keys(carriers)?.every((c) => !filters.has(c))}>
            <>
              <ul>
                <li>
                  {carriers && <Checkbox checked={Object.keys(carriers)?.every((c) => !filters.has(c))} onChange={toggleAllCarriers} value={window.i18('SELECT_ALL')} />}
                </li>
                {carriers && Object.keys(carriers)?.map((carrier) => (
                  <li key={carrier}>
                    <Checkbox checked={!filters.has(carrier)} name="car-type" onChange={() => dispatch(toggleFilter(carrier))} value={carriers?.[carrier]?.name || carrier} />
                  </li>
                ))}
              </ul>
            </>
          </FilterContainer>
          {selectedTransportMode === 'flight' && <Checkbox checked={!filters.has(FILTER_TRANSIT_FLIGHT)} onChange={() => dispatch(toggleFilter(FILTER_TRANSIT_FLIGHT))} value={window.i18('CONNECTIONS_FULL')} />}
          {selectedTransportMode === 'rail' && <Checkbox checked={!filters.has(FILTER_TRANSIT_RAIL)} onChange={() => dispatch(toggleFilter(FILTER_TRANSIT_RAIL))} value={window.i18('CONNECTIONS_FULL')} />}
          <Checkbox checked={!filters.has(FILTER_NO_FLEX)} onChange={() => dispatch(toggleFilter(FILTER_NO_FLEX))} value={window.i18('NO_FLEX')} />
          <Checkbox checked={!filters.has(FILTER_SEMI_FLEX)} onChange={() => dispatch(toggleFilter(FILTER_SEMI_FLEX))} value={window.i18('SEMI_FLEX')} />
          <Checkbox checked={!filters.has(FILTER_FULL_FLEX)} onChange={() => dispatch(toggleFilter(FILTER_FULL_FLEX))} value={window.i18('FULL_FLEX')} />
          <Checkbox checked={!filters.has(FILTER_LUGGAGE)} onChange={() => dispatch(toggleFilter(FILTER_LUGGAGE))} value={window.i18('WITH_LUGGAGE')} />
          <hr />
          <p>{window.i18('SORT')}</p>
          <select defaultValue={sorting} onChange={(e) => dispatch(setSorting(e.target.value))}>
            <option value="BY_TIME">{window.i18('DEPARTURE_TIME')}</option>
            <option value="BY_PRICE">{window.i18('PRICE')}</option>
          </select>
          <button className="button-main" onClick={() => setShowFilters(!showFilters)}>{window.i18('CONFIRM')}</button>
        </div>
      </div>
    );
  }
  return (
    <div className="results-filters">
      <span>
        {window.i18('FILTERS')}
        :
        {' '}
      </span>
      {selectedTransportMode === 'flight' && (
      <label htmlFor="transit" className={classNames('badge', { deselect: filters.has(FILTER_TRANSIT_FLIGHT) })}>
        <input type="checkbox" id="transit" checked={filters.has(FILTER_TRANSIT_FLIGHT)} onChange={() => dispatch(toggleFilter(FILTER_TRANSIT_FLIGHT))} />
        <span className="badge__check" />
        {window.i18('CONNECTIONS_FULL')}
      </label>
      )}
      {selectedTransportMode === 'rail' && (
      <label htmlFor="transit" className={classNames('badge', { deselect: filters.has(FILTER_TRANSIT_RAIL) })}>
        <input type="checkbox" id="transit" checked={filters.has(FILTER_TRANSIT_RAIL)} onChange={() => dispatch(toggleFilter(FILTER_TRANSIT_RAIL))} />
        <span className="badge__check" />
        {window.i18('CONNECTIONS_FULL')}
      </label>
      )}
      {selectedTransportMode === 'flight' && (
        <label htmlFor="luggage" className={classNames('badge', { deselect: filters.has(FILTER_LUGGAGE) })}>
          <input type="checkbox" id="luggage" checked={!filters.has(FILTER_LUGGAGE)} onChange={() => dispatch(toggleFilter(FILTER_LUGGAGE))} />
          <span className="badge__check" />
          {window.i18('WITH_LUGGAGE')}
        </label>
      )}
      <FilterContainer btnText={window.i18('COMPANIES')} active={carriers && !Object.keys(carriers)?.every((c) => !filters.has(c))}>
        <>
          <ul>
            <li>
              <Checkbox checked={carriers && Object.keys(carriers)?.every((c) => !filters.has(c))} onChange={toggleAllCarriers} value={window.i18('SELECT_ALL')} />
            </li>
            {carriers && Object.keys(carriers)?.map((carrier) => (
              <li key={carrier}>
                <Checkbox checked={!filters.has(carrier)} name="car-type" onChange={() => dispatch(toggleFilter(carrier))} value={carriers?.[carrier]?.name || carrier} />
              </li>
            ))}
          </ul>
        </>
      </FilterContainer>
      <div className="m-right">
        <label htmlFor="no-flex" className={classNames('badge', { deselect: filters.has(FILTER_NO_FLEX) })}>
          <input type="checkbox" id="no-flex" checked={filters.has(FILTER_NO_FLEX)} onChange={() => dispatch(toggleFilter(FILTER_NO_FLEX))} />
          <span className="badge__check" />
          {window.i18('NO_FLEX')}
        </label>
        <label htmlFor="semi-flex" className={classNames('badge', { deselect: filters.has(FILTER_SEMI_FLEX) })}>
          <input type="checkbox" id="semi-flex" checked={filters.has(FILTER_SEMI_FLEX)} onChange={() => dispatch(toggleFilter(FILTER_SEMI_FLEX))} />
          <span className="badge__check" />
          {window.i18('SEMI_FLEX')}
        </label>
        <label htmlFor="full-flex" className={classNames('badge', { deselect: filters.has(FILTER_FULL_FLEX) })}>
          <input type="checkbox" id="full-flex" checked={filters.has(FILTER_FULL_FLEX)} onChange={() => dispatch(toggleFilter(FILTER_FULL_FLEX))} />
          <span className="badge__check" />
          {window.i18('FULL_FLEX')}
        </label>
      </div>
      <span className="m-left">
        {window.i18('SORTING')}
        :
      </span>
      <select className="hotel-results-filter__btn active" defaultValue={sorting} onChange={(e) => dispatch(setSorting(e.target.value))}>
        <option value="BY_TIME">{window.i18('DEPARTURE_TIME')}</option>
        <option value="BY_PRICE">{window.i18('PRICE')}</option>
      </select>
    </div>
  );
};

export default Filters;
