import { toast as Notif } from 'react-toastify';
import { push } from 'connected-react-router';
import moment from 'moment';
import {
  LOCAL_STORAGE_USER_KEY,
  SET_USER,
  SET_CARDS,
  FETCHING_CARDS,
  SET_IS_TRAVEL_MANAGER,
  SET_TOKEN,
  FETCHING_AGE_CATEGORIES,
  SET_AGE_CATEGORIES,
  FETCH_TRAVELERS_BEGIN,
  FETCH_TRAVELERS_ERROR,
  FETCH_TRAVELERS_SUCCESS,
  FETCH_COMPANIES_BEGIN,
  FETCH_COMPANIES_ERROR,
  FETCH_COMPANIES_SUCCESS,
  CHANGE_COMPANY,
  FETCH_CO2_STATS_SUCCESS,
  FETCH_TREEPS_STATS_SUCCESS,
  SET_MOBILE_DEVICE,
  SET_HANDICAP_TYPES,
  SET_WHEELCHAIR_TYPES,
  SET_APPROVERS,
  USER_NOT_LOGGED,
  SET_HAS_TRAVELERS_SEARCH,
  ACTIVATE_SANDBOX_MODE,
  SET_SANDBOX_PARAMS,
  SET_LANG,
} from './constants';
import { fetchUserDocuments, fetchUserCards } from '../UserAccount/actions';
import { setTreepSearchParams } from '../TripSearch/actions';
import { setCarRentalSearchParams } from '../CarRentalSearch/actions';
import { setHotelSearchParams } from '../HotelSearch/actions';
import { isMobileDevice } from '../../utils/functions';

import API, { addAuth } from '../../utils/api';
import { resetCart } from '../Cart/actions';
import { formatDateForAPI } from '../../utils/converters';
import { setEntity } from '../Search/actions';
import { SET_ENTITY } from '../Search/constants';

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

export function setIsTravelManager(isTravelManager) {
  return {
    type: SET_IS_TRAVEL_MANAGER,
    isTravelManager,
  };
}
export function setHasTravelersSearch(hasTravelersSearch) {
  return {
    type: SET_HAS_TRAVELERS_SEARCH,
    hasTravelersSearch,
  };
}

export function fetchingCards() {
  return {
    type: FETCHING_CARDS,
  };
}

export function setCards(cards) {
  return {
    type: SET_CARDS,
    cards,
  };
}

export function setHandicapTypes(handicaps) {
  return {
    type: SET_HANDICAP_TYPES,
    handicaps,
  };
}

export function setWheelchairTypes(wheelchairs) {
  return {
    type: SET_WHEELCHAIR_TYPES,
    wheelchairs,
  };
}

export function fetchingAgeCategories() {
  return {
    type: FETCHING_AGE_CATEGORIES,
  };
}

export function setAgeCategories(ageCategories) {
  return {
    type: SET_AGE_CATEGORIES,
    ageCategories,
  };
}

export function setToken(token) {
  return {
    type: SET_TOKEN,
    token,
  };
}
// This checks the localstorage to get any token if existing
// Then, tests it against the API and stores the user if it's still valid.
export function checkLogin(sandboxToken) {
  return (dispatch, getState) => {
    const token = window.localStorage.getItem(LOCAL_STORAGE_USER_KEY);

    if (token) {
      dispatch(setToken(token));

      API.get('/user/', { headers: { Authorization: token } }).then(
        (response) => {
          if (response.data.result) {
            const user = response.data.result;
            // After getting the user's data, we do another call to get the user's approvers
            // Then we add these to the user data object and send it to logUser
            dispatch(logUser(user));
            if (sandboxToken) {
              dispatch(getSandboxSession(sandboxToken));
            }
            dispatch(getApprovers());
          } else {
            dispatch({ type: USER_NOT_LOGGED });
          }
        },
        () => {
          dispatch({ type: USER_NOT_LOGGED });
        },
      );
    } else {
      dispatch({ type: USER_NOT_LOGGED });
    }
    addAuth(dispatch, getState);
  };
}

// Loads the reductions cards list from the API
export function fetchCards() {
  return (dispatch) => {
    dispatch(fetchingCards());

    return API.get('/user/cards/types').then((response) => {
      const cards = response.data.result || null;
      dispatch(setCards(cards));
    });
  };
}

// Loads the handicaps and wheelchair list from the API
export function fetchHandicaps() {
  return (dispatch) => {
    API.get('/handicap/types').then((response) => {
      const handicaps = response.data.result || null;
      dispatch(setHandicapTypes(handicaps));
    });
    API.get('/handicap/wheelchairs').then((response) => {
      const wheelchairs = response.data.result || null;
      dispatch(setWheelchairTypes(wheelchairs));
    });
  };
}

// Loads the age category list from the API
export function fetchAgeCategories() {
  return (dispatch) => {
    dispatch(fetchingAgeCategories());

    return API.get('/treep/traveler/types').then((response) => {
      const ageCats = response.data.result || null;
      dispatch(setAgeCategories(ageCats));
    });
  };
}

export function logout() {
  return (dispatch, getState) => {
    window.localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    dispatch(push('/'));
    dispatch(setUser(null));
    dispatch(setToken(null));
    dispatch(resetCart());
    addAuth(dispatch, getState);
  };
}

// Tries to login against the API
export function sendLogin(user) {
  return (dispatch, getState) => API.post('/user/login', user).then(
    (response) => {
      if (response.data.result) {
        persistLogin(response.data.result.token);
        dispatch(setToken(response.data.result.token));
        addAuth(dispatch, getState);
        dispatch(logUser(response.data.result));
      }
    },
  );
}

export function logWithToken(token) {
  return (dispatch, getState) => API.post('/user/login', { token }).then(
    (response) => {
      if (response.data.result) {
        persistLogin(response.data.result.token);
        dispatch(setToken(response.data.result.token));
        addAuth(dispatch, getState);
        dispatch(logUser(response.data.result));
      }
    },
    () => {
      dispatch({ type: USER_NOT_LOGGED });
      // Do not need this because we are catching everything localy now. See api.js interceptors
      // Notif.warning(window.i18('AUTH_PROBLEM'));
    },
  );
}

// Tries to activate email with token and log user
export function activateEmailWithToken(token) {
  return (dispatch, getState) => API.post('/user/confirm', { token }).then(
    (response) => {
      if (response.data.result) {
        Notif.success(window.i18('ACCOUNT_ACTIVATED'));
        persistLogin(response.data.result.token);
        dispatch(setToken(response.data.result.token));
        addAuth(dispatch, getState);
        dispatch(logUser(response.data.result));
        dispatch(push('/form'));
      }
    },
  );
}

// Ask the server to send a new token by email
export function renewActivationToken(token) {
  return () => API.post('/user/regenerate', { token }).then(
    () => {
      Notif.success(
        window.i18('NEW_LINK_SENT_TO_ACTIVATE_ACCOUNT'),
      );
    },
  );
  // Do not need this because we are catching everything localy now. See api.js interceptors
  /*
  .catch((error) => {
    let message = '';
    if (error.data != null && error.data.msg != null) {
      message = error.data.msg;
    }
    Notif.warning(
      `${window.i18('ERROR_ACTIVATION_LINK')} : ${
        message}`,
      '',
      5000,
    );
    throw message;
  });
  */
}

// Store current user token in localStorage
export function persistLogin(token) {
  window.localStorage.setItem(LOCAL_STORAGE_USER_KEY, token);
}

function fetchCompanies() {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_COMPANIES_BEGIN });
    API.get('/companies/').then((response) => {
      dispatch({ type: FETCH_COMPANIES_SUCCESS, companies: response.data.result });
      const { user } = getState().app;
      if (user && user.company_id != null && user.company_id > 0) {
        dispatch(changeCompany(user.company_id));
      }
    }).catch(() => {
      dispatch({ type: FETCH_COMPANIES_ERROR });
    });
  };
}

// Log the user and update its profile type
export function logUser(user) {
  return (dispatch) => {
    dispatch(setIsTravelManager(user != null && user.perms != null && (user.perms.includes('account_creation'))));
    dispatch(setHasTravelersSearch(user != null));
    if (user != null && user.perms != null && user.perms.includes('travel_agent')) {
      dispatch(fetchCompanies());
    }
    if (user.special_fares != null) { // Do not display "auto" special fares that will automatically be added to the results
      user.special_fares = user.special_fares.filter((fare) => !fare.auto);
    }
    dispatch(setUser(user));
    if (user.entities != null && user.entities.length === 1) {
      dispatch(setEntity(user.entities[0].uid));
    }
    dispatch(fetchUserCards());
    dispatch(fetchUserDocuments());
    dispatch(fetchCO2Stats());
  };
}

// change travel_agent user's company
export function changeCompany(companyId) {
  return (dispatch, getState) => {
    const company = getState().app.companies.list.find((company) => company.uid === parseInt(companyId, 10));
    dispatch({
      type: CHANGE_COMPANY,
      company,
    });
    dispatch({ type: SET_ENTITY, entity: null });
  };
}

// Edit user information
export function submitUser(user) {
  return (dispatch) => {
    if (user.birthdate && user.birthdate.length > 8) {
      user.birthdate = user.birthdate.substring(0, 8);
    } else {
      user.birthdate = '';
    }

    return API.put('/user/', user).then(
      (response) => {
        if (response.data.result) {
          persistLogin(response.data.result.token);
          dispatch(setToken(response.data.result.token));
          dispatch(logUser(response.data.result));
          Notif.success(window.i18('INFORMATIONS_SAVED'));
        }
      },
    );
  };
}

// Signup user
export function signup(user) {
  return () => {
    if (user.birthdate && (user.birthdate.length > 8 || user.birthdate instanceof Date)) { user.birthdate = formatDateForAPI(user.birthdate); }
    if (user.firstname) { user.firstname = user.firstname.trim(); }
    if (user.lastname) { user.lastname = user.lastname.trim(); }
    if (user.phone) { user.phone = user.phone.trim(); }
    if (user.subsidy_number) { user.subsidy_number = user.subsidy_number.trim(); }
    user.gdpr_consent = true;

    return API.post('/user/signin', user);
  };
}

export function resetPassword(email) {
  return () => API.post('/user/forgot', email);
}

export function newPassword(token, password) {
  return () => API.put('/user/password', {
    token_pwd: token,
    password,
  }).then(
    () => {
      Notif.success(window.i18('PASSWORD_UPDATED'));
    },
  );
}

function fetchTravelersBegin() {
  return {
    type: FETCH_TRAVELERS_BEGIN,
  };
}

function fetchTravelersError(error) {
  return {
    type: FETCH_TRAVELERS_ERROR,
    error,
  };
}

function fetchTravelersSuccess(travelers) {
  return {
    type: FETCH_TRAVELERS_SUCCESS,
    travelers,
  };
}

export function searchTravelers(query) {
  return async (dispatch, getState) => {
    let params = `?query=${query}&limit=15`;
    const { user } = getState().app;
    if (user && user.company_id != null && user.company_id > 0) {
      params = `${params}&company_id=${user.company_id}`;
    }
    dispatch(fetchTravelersBegin());
    let lastError = null;
    const users = await Promise.allSettled([
      API.get(`/users/${params}`), API.get(`/user/guests${params}`),
    ]).then((results) => results.map((response) => {
      if (response && response.value && response.value.data && response.value.data.result) {
        return response.value.data.result;
      }
      return null;
    }).flat().filter((el) => el != null), (error) => {
      lastError = error;
    });
    if (lastError != null && (!users || users.length === 0)) {
      dispatch(fetchTravelersError(lastError));
    } else {
      dispatch(fetchTravelersSuccess(users));
    }
  };
}

export function fetchCO2Stats() {
  return (dispatch) => {
    API.get('/stats/co2').then((response) => {
      const stats = response.data.result || null;
      dispatch(fetchCO2StatsSuccess(stats));
    });
  };
}

function fetchCO2StatsSuccess(stats) {
  return {
    type: FETCH_CO2_STATS_SUCCESS,
    stats,
  };
}

export function fetchTreepsStats() {
  return (dispatch) => {
    API.get('/stats/treeps').then((response) => {
      const stats = response.data.result || null;
      dispatch(fetchTreepsStatsSuccess(stats));
    });
  };
}

function fetchTreepsStatsSuccess(stats) {
  return {
    type: FETCH_TREEPS_STATS_SUCCESS,
    stats,
  };
}

export function checkMobileDevice() {
  return {
    type: SET_MOBILE_DEVICE,
    mobileDevice: isMobileDevice(),
  };
}

export function getApprovers() {
  return (dispatch, getState) => {
    const { user } = getState().app;
    if (user == null) {
      return;
    }
    if (user.perms != null && user.perms.includes('pro')) {
      API.get('/user/approvers')
        .then((response) => {
          if (
            response.data
              && response.data.result
          ) {
            dispatch({
              type: SET_APPROVERS,
              approvers: response.data.result,
            });
          }
        });
    }
  };
}

function activateSandboxMode() {
  return {
    type: ACTIVATE_SANDBOX_MODE,
  };
}

function setSandboxParams(params) {
  return {
    type: SET_SANDBOX_PARAMS,
    params,
  };
}

export function getSandboxSession(token) {
  return (dispatch, getState) => {
    dispatch(activateSandboxMode());
    API.get(`/sso/?type=thetreep&token=${token}`).then((res) => {
      const { user, request, analytics } = res.data.result;

      const travelers = request.travelers ? request.travelers.map((p, i) => {
        p.selected = true;
        p.id = i + 1;
        return p;
      }) : [];

      const treepSearchParams = {
        isRT: true,
        from: request.origin ?? {},
        to: request.destination ?? {},
        date: {
          a: {
            date: moment(request.datetime) ?? null,
          },
          r: {
            date: moment(request.return_datetime) ?? null,
          },
        },
        passengers: [{ ...user, selected: true, id: 0 }, ...travelers],
      };

      const hotelSearchParams = {
        location: request.destination,
        date: {
          arrival: moment(request.datetime),
          departure: moment(request.return_datetime),
        },
        passengers: [{ ...user, selected: true, id: 0 }, ...travelers],
      };

      const carRentalSearchParams = {
        location: request.destination,
        date: {
          a: {
            date: moment(request.datetime),
          },
          r: {
            date: moment(request.return_datetime),
          },
        },
        drivers: [{ ...user, selected: true, id: 0 }],
      };

      dispatch(setUser(user));
      persistLogin(user.token);
      dispatch(setToken(user.token));
      addAuth(dispatch, getState);
      dispatch(setSandboxParams({ request, analytics }));
      dispatch(setTreepSearchParams(treepSearchParams));
      dispatch(setHotelSearchParams(hotelSearchParams));
      dispatch(setCarRentalSearchParams(carRentalSearchParams));
    });
  };
}

export function setLang(lang) {
  return {
    type: SET_LANG,
    lang,
  };
}
