import { OPEN_MODAL, CLOSE_MODAL } from './constants';

const initialState = {
  isOpen: false,
  name: null,
  options: null,
};

function modalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        isOpen: true,
        name: action.name,
        options: action.options,
      };
    case CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
}

export default modalReducer;
