import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { toast as Notif } from 'react-toastify';
import { sortApproversList } from '../../../utils/approvers-utils';
// Components
import Input from '../../../components/Inputs/Material';
import Select from '../../../components/Inputs/MaterialSelect';
import LeavesLoader from '../../../components/Loader/LeavesLoader';
// Actions
import {
  sendingApprovalBegin,
  sendingApprovalSuccess,
  sendingApprovalError,
  getUserApprovals,
} from '../../UserAccount/actions';
// Functions
import API from '../../../utils/api';

export default function Approbation({
  closeModal,
  approvalID,
  request,
  escalate,
  approvers,
}) {
  const { analytics } = useSelector(
    (state) => state.app.user && state.app.user.company,
  );
  const [options, setOptions] = useState([]);
  const [approversList] = useState([...approvers || []]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    // We sort the approvers by alphabetical order first, then by their approval level
    setOptions(sortApproversList(approversList));
  }, [approversList, setOptions]);

  const fields = analytics
    ? analytics.filter((field) => field.for_approval)
    : [];
  const requiredFields = fields.filter((field) => field.required === true)
    || [];
  // We have to add appro_analytics value to Formik initialValues in order to display it within the field
  // In some case Approbator should pass analytic value when he escalate request
  const initialValues = Object.fromEntries(
    fields.map((field) => {
      const value = request.approvalAnalytics.length > 0 ? request.approvalAnalytics.find((aa) => aa.name === field.name)?.value : '';
      return [field.name, value];
    }),
  );

  if (escalate) {
    initialValues.approver = '';
  }

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(sendingApprovalBegin(approvalID));
    let fields = [];
    if (values) {
      fields = Object.entries(values)
        .map(([key, value]) => ({
          name: key,
          value,
        }));
    }

    const params = {
      approvalID,
      answer: escalate ? 'escalated' : 'approved',
    };

    if (escalate) {
      params.escalationApprover = values.approver;
      params.analytics = fields;
    } else {
      params.analytics = fields;
    }

    setSubmitting(true);

    API.post('/approval/answer', params)
      .then(() => {
        dispatch(sendingApprovalSuccess(approvalID));
        dispatch(getUserApprovals());
        Notif.success(`${window.i18('REQUEST')} ${escalate
          ? window.i18('TRANSFERED')
          : window.i18('APPROVED_FEM')} ${window.i18('SUCCESSFULLY')}`);
        setSubmitting(false);
        closeModal();
      }, () => {
        dispatch(sendingApprovalError());
        setSubmitting(false);
      });
  };

  const validation = (values) => {
    const errors = {};

    if (escalate && !values.approver) {
      errors.approver = window.i18('PLEASE_FILL');
    }

    requiredFields.filter((field) => !values[field.name])
      .forEach((field) => {
        errors[field.name] = window.i18('PLEASE_FILL');
      });

    return errors;
  };

  return (
    <div className="modal-inner">
      {submitting ? (
        <>
          <LeavesLoader />
          <p>{window.i18('SENDING')}</p>
        </>
      ) : (
        <>
          <section className="modal-header">
            <h3 className="common-sub-title">
              {`${escalate ? window.i18(
                'ARE_YOU_SURE_YOU_TRANSFER',
              ) : window.i18(
                'ARE_YOU_SURE_YOU_APPROVE',
              )} ${window.i18('THIS_FEM')} ${window.i18(
                'REQUEST',
              )
                .toLowerCase()}?`}
            </h3>
          </section>
          <section className="modal-body align-left">
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validate={validation}
            >
              {({
                setFieldValue,
                values,
                errors,
                touched,
                isSubmitting,
                handleSubmit,
              }) => (
                <Form>
                  {fields && fields.length > 0 && fields.map((field, index) => (
                    <Field
                      field={field}
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      index={index}
                      key={index}
                    />
                  ))}
                  {escalate && (
                  <Select
                    error={errors?.approver}
                    touched={touched?.approver}
                    name="approver"
                    value={values.approver}
                    label={window.i18('APPROVER')}
                    options={options}
                    onChange={(value) => setFieldValue('approver', value)}
                    nullable
                  />
                  )}
                  <div className="modal-button-group m-top p-top">
                    <a
                      className="button-main"
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                    >
                      {escalate ? window.i18('ESCALATE') : window.i18('CONFIRM')}
                    </a>
                    <a
                      className="button-secondary"
                      disabled={isSubmitting}
                      onClick={closeModal}
                    >
                      {window.i18('CANCEL')}
                    </a>
                  </div>
                </Form>
              )}
            </Formik>
          </section>
        </>
      )}
    </div>
  );
}

function Field({
  field,
  values,
  errors,
  touched,
  setFieldValue,
  index,
}) {
  const options = field.values
    ? field.values.map((value) => ({
      value,
      name: value,
    }))
    : null;
  return (
    <div
      className="row m-top"
      key={`field-${index}`}
    >
      <div className="col col-12">
        {options
          ? (
            <Select
              onChange={(value) => setFieldValue(field.name, value)}
              name={field.name}
              value={values[field.name]}
              placeholder={field.label}
              type="text"
              label={field.required ? `${field.label}*` : field.label}
              error={errors[field.name]}
              touched={touched[field.name]}
              options={[
                {
                  value: '',
                  name: '',
                }].concat(options)}
            />
          )
          : (
            <Input
              onChange={(value) => setFieldValue(field.name, value)}
              name={field.name}
              value={values[field.name]}
              placeholder={field.label}
              type="text"
              label={field.required ? `${field.label}*` : field.label}
              error={errors[field.name]}
              touched={touched[field.name]}
            />
          )}
      </div>
    </div>
  );
}
