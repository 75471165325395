import moment from 'moment';
import {
  FILTER_TRANSIT_FLIGHT, FILTER_TRANSIT_RAIL, FILTER_LUGGAGE, FILTER_FULL_FLEX, FILTER_NO_FLEX, FILTER_SEMI_FLEX,
} from './constants';

export const getProposalsFromResults = (results, filtered = true) => {
  const { filters, sorting } = results;
  const currentTrip = results[results.currentTrip];
  const { proposals, selectedTransportMode } = currentTrip;
  const sortedProposals = sortProposals(proposals, sorting);
  if (filtered) {
    let filteredProposals = JSON.parse(JSON.stringify(sortedProposals));
    filteredProposals = filteredProposals.filter((proposal) => (
      proposal.type === selectedTransportMode
        && ((!filters.includes(FILTER_TRANSIT_FLIGHT) && selectedTransportMode === 'flight') || (!filters.includes(FILTER_TRANSIT_RAIL) && selectedTransportMode === 'rail') || proposal.transitCount === 0)
    ));
    filteredProposals = filteredProposals.filter((proposal) => (
      !filters.includes(proposal.marketingCarrier)
    ));
    filteredProposals = filteredProposals.map((proposal) => {
      if (proposal.choices == null) {
        proposal.choices = [];
      }
      proposal.choices = proposal.choices.filter((choice) => choice.choice?.some((c) => {
        const offer = getProposalOfferFromID(proposal, c.offerID);
        return (
          (!filters.includes(FILTER_FULL_FLEX) || offer.flexibility !== 3)
            && (!filters.includes(FILTER_SEMI_FLEX) || offer.flexibility !== 2)
            && (!filters.includes(FILTER_NO_FLEX) || offer.flexibility !== 1)
        );
      }));
      return proposal;
    });
    filteredProposals = selectedTransportMode === 'rail' ? filteredProposals : filteredProposals.filter((proposal) => {
      const withLuggage = proposal.choices && proposal.choices.filter((choice) => choice.tags && choice.tags.includes('withLuggage')).length > 0;
      return (
        proposal.type === selectedTransportMode
            && (filters.includes(FILTER_LUGGAGE) || withLuggage)
      );
    });
    filteredProposals = filteredProposals.filter((proposal) => proposal.choices.length);
    return filteredProposals;
  }
  return sortedProposals;
};

function sortProposals(proposals, sorting) {
  if (sorting === 'BY_PRICE') {
    return [...proposals].sort((a, b) => {
      const aPrices = a.choices.map((choice) => choice.price);
      const bPrices = b.choices.map((choice) => choice.price);
      return Math.min(...aPrices) - Math.min(...bPrices);
    });
  }
  if (sorting === 'BY_TIME') {
    return [...proposals].sort((a, b) => moment(a.departure).diff(moment(b.departure)));
  }
  return proposals;
}

export const getSelectedProposal = (results, filtered = true) => {
  const proposals = getProposalsFromResults(results, filtered);
  const { selectedProposal, selectedTransportMode } = results[results.currentTrip];
  return proposals.find((p) => p.id === selectedProposal[selectedTransportMode]) || { choices: [] };
};

export const getProposalOfferFromID = (proposal, offerID) => proposal.offers.find((offer) => offer.id === offerID);
