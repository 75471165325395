import * as Sentry from '@sentry/react';
// import ApiError from './api-error'
import FatalError from './fatal-error';
import MalformedRequestError from './malformed-request-error';

// Function used to send all API responses error to Sentry
export const sendApiErrorToSentry = (error) => {
  // Only send error if there is no message or if status >= 500
  if (!error.data || !error.data.errorMessage || error.status >= 500) {
    Sentry.captureException(new FatalError(`${error.status || 'No status code'}
      app => ${process.env.REACT_APP_URL}
      xRequestId => ${error.headers ? (error.headers['x-request-id'] || 'pas de x-request-id') : 'headers object is not provided'}
      apiCode => ${error.data ? (error.data.api_code || 'api code not provided') : 'data object is not provided'}
      endpoint => ${error.config ? (error.config.url || 'no service url provided') : 'config object is not provided'}`),
    { level: 'fatal' });
  }
};

// Function used to send all request error to Sentry (CORS par exemple)
export const sendMalformedRequestErrorToSentry = (error) => {
  Sentry.captureException(new MalformedRequestError(error), {
    level: 'error',
  });
};

/*
Sentry.captureException(new ApiError(`An API error occur:
    app => ${process.env.REACT_APP_URL}
    xRequestId => ${error.headers ? (error.headers['x-request-id'] || 'pas de x-request-id') : 'headers object is not present'}
    status => ${error.status || 'pas de code de status'}
    statusText => ${error.statusText || 'pas de texte de status'}
    apiCode => ${error.data ? (error.data.api_code || 'pas de code api') : 'data object is not present'}
    erroMessage => ${error.data ? (error.data.errorMessage || 'pas de message utilisateur') : 'data object is not present'}
    endpoint => ${error.config ? (error.config.url || 'aucune url de service renseignée') : 'config object is not present'}
  `), {
    level: 'error',
  });
  */
