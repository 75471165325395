import React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import LoaderContainer from './LoaderContainer';
import Rocket from './Rocket';
import BarrierGestures from './BarrierGestures';

export default function Loader({
  sentences, isCheckout, destination, returnTrip,
}) {
  const [step, setStep] = React.useState(0);
  const isMobileDevice = useSelector((state) => state.app.isMobileDevice);

  React.useEffect(() => {
    const timeoutId = setTimeout(nextStep, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [nextStep]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const nextStep = () => {
    setStep(step + 1);
  };

  let loaderContainer = (
    <>
      <LoaderContainer
        key={step}
        sentences={sentences}
        isCheckout={isCheckout}
      />
      {!isMobileDevice && (
        <BarrierGestures returnTrip={returnTrip} destination={destination} />
      )}
    </>
  );

  if (step === 0) {
    loaderContainer = <Rocket key={step} returnTrip={returnTrip} isCheckout={isCheckout} />;
  }

  return (

    <div className="loader-wrapper common-wrapper">
      <div className="loader-wrapper-inner">
        <TransitionGroup>
          <CSSTransition classNames="rocket" timeout={{ enter: 1000, exit: 500, appear: 500 }}>
            {loaderContainer}
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}
