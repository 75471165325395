import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import EmptyCart from './EmptyCart';
import CartItems from './CartItems';

import {
  getCart, toggleCartSubset, promptDeleteTreepFromCart, refreshTreep, updateCartAmount, promptEmptyCart,
} from './actions';
import { triggerCheckout, triggerConfirm } from '../Checkout/actions';
import { getCartSelectedTickets } from './selectors';
import { getSvgIcon } from '../../utils/icons';
import LeavesLoader from '../../components/Loader/LeavesLoader';
import PaymentFees from './PaymentFees';
import CheckoutButtons from '../Checkout/CheckoutButtons';
import { getApprovers } from '../App/actions';
import { openModal } from '../ModalWrapper/actions';
import { uniqueArray } from '../../utils/functions';

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      co2_emission: null,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.getApprovers();
    this.props.getCart();
    this.props.updateCartAmount();
  }

  componentDidUpdate() {
    let co2 = 0;
    this.props.cart.content.forEach((content) => {
      let total = 0;
      let selected = false;
      content.subsets.forEach((subset) => {
        total = subset.co2_emission ? total + parseFloat(subset.co2_emission) : total;
        if (subset.selected) {
          selected = true;
        }
      });
      if (selected) co2 = co2 === null ? total : co2 + total;
    });
    if (co2 !== this.state.co2_emission) {
      this.setState({
        co2_emission: co2,
      });
    }
  }

  checkout = (tickets) => {
    if (!this.props.amount || !tickets || tickets.length === 0) {
      return;
    }
    this.props.triggerCheckout(tickets, this.props.amount);
  }

  checkoutMissionOrder = (missionOrders) => {
    if (!this.props.amount || !missionOrders) {
      return;
    }
    this.props.triggerCheckout(null, this.props.amount, missionOrders);
  }

  confirm = (tickets) => {
    if (!this.props.amount || !tickets || tickets.length === 0) {
      return;
    }
    this.props.triggerConfirm(tickets, this.props.amount);
  }

  approval = (tickets) => {
    if (!this.props.amount || !tickets || tickets.length === 0 || !this.props.user) {
      return;
    }
    const travelIDs = uniqueArray(this.props.cart.content.filter((c) => c.subsets.find((subset) => subset.bookable && subset.ticket_ids.filter((id) => tickets.includes(id)).length > 0)).map((c) => c.travelID));
    this.props.openModal('ApproversList', {
      tickets,
      approvers: this.props.user.approvers,
      travelIDs,
      checkoutAmount: this.props.amount,
      withSecondary: true,
    });
  }

  render() {
    if (!this.props.user) { return <Redirect to="/" />; }

    if (this.props.cart.isFetching) {
      return (
        <LeavesLoader
          sentence={window.i18('CART_LOADING')}
        />
      );
    }
    if (this.props.cart.isDeleting) {
      return (
        <LeavesLoader
          sentence={window.i18('FREEING_OPTIONS')}
        />
      );
    }
    if (!this.props.cart.content || !this.props.cart.content.length > 0) {
      return (
        <EmptyCart />
      );
    }
    const tickets = getCartSelectedTickets(this.props.cart.content);

    const amountLoading = this.props.cart.cartAmount == null || this.props.cart.cartAmount.isFetching;
    return (

      <div className="cart-wrapper">
        <div className="common-wrapper">
          <h1 className="common-sub-title">{window.i18('MY_CART')}</h1>
          <div className="common-add-button">
            {getSvgIcon('add', 'ui/')}
            <Link className="common-add-hyperlink" to="/">
              {window.i18('ADD_SERVICE')}
            </Link>
          </div>
          <button className="button-main m-bot" onClick={this.props.promptEmptyCart}>
            {getSvgIcon('dustbin', 'ui/')}
            {window.i18('EMPTY_CART')}
          </button>
          <div className="cart-item-wrapper">
            <CartItems user={this.props.user} cart={this.props.cart} toggleCartSubset={this.props.toggleCartSubset} refreshTreep={this.props.refreshTreep} promptDeleteTreepFromCart={this.props.promptDeleteTreepFromCart} isMobileDevice={this.props.isMobileDevice} amount={this.props.amount} checkoutMissionOrder={this.checkoutMissionOrder} checkout={this.checkout} approval={this.approval} confirm={this.confirm} />
          </div>
          <div className="cart-footer">
            {this.props.amount && this.props.amount.travel_policy
              && (
              <div className="pve">
                <span className="icon">{getSvgIcon('warning', 'ui/')}</span>
                <div className="pve-content">
                  <h2>{window.i18('PVE_REMINDER')}</h2>
                  <p dangerouslySetInnerHTML={{ __html: this.props.amount.travel_policy }} />
                </div>
              </div>
              )}

            {!amountLoading && tickets.length > 0 && this.props.amount != null
              && <PaymentFees isB2C={this.props.user && !this.props.user.company_id} co2Formatted={this.state.co2_emission / 1000} amount={this.props.amount.amount} showFees={this.props.amount.show_fees} />}
            <div className="common-add-button add-service-btn--second">
              {getSvgIcon('add', 'ui/')}
              <Link className="common-add-hyperlink" to="/">
                {window.i18('ADD_SERVICE')}
              </Link>
            </div>
            {amountLoading || tickets.length === 0
              ? <a className="button-main" disabled>...</a>
              : <CheckoutButtons amount={this.props.amount} checkout={() => this.checkout(tickets)} approval={() => this.approval(tickets)} confirm={() => this.confirm(tickets)} />}
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  amount: state.cart.cartAmount.amount,
  paymentInfos: state.cart.paymentInfos,
  user: state.app.user,
  isMobileDevice: state.app.isMobileDevice,
});

const mapDispatchToProps = (dispatch) => ({
  getCart: () => dispatch(getCart()),
  updateCartAmount: () => dispatch(updateCartAmount()),
  toggleCartSubset: (subset) => {
    dispatch(toggleCartSubset(subset));
    dispatch(updateCartAmount());
  },
  promptDeleteTreepFromCart: (id) => dispatch(promptDeleteTreepFromCart(id)),
  refreshTreep: (treep) => dispatch(refreshTreep(treep)),
  triggerCheckout: (tickets, amount, missionOrders) => dispatch(triggerCheckout(tickets, amount, missionOrders)),
  triggerConfirm: (tickets, amount) => dispatch(triggerConfirm(tickets, amount)),
  getApprovers: () => dispatch(getApprovers()),
  openModal: (name, options) => dispatch(openModal(name, options)),
  promptEmptyCart: () => dispatch(promptEmptyCart()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cart);
