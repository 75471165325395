import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
};

const defaultProps = {
  min: 0,
  max: 0,
};

export default class index extends React.Component {
  handleMinusClick = () => {
    if (this.props.value > this.props.min) {
      this.props.onRemove();
    }
  }

  handlePlusClick = () => {
    if (this.props.value < this.props.max) {
      this.props.onAdd();
    }
  }

  render() {
    const { value, min, max } = this.props;
    const quantityClass = classNames('quantity', { empty: value === min });
    return (
      <div className="quantity-input">
        <button
          aria-label={window.i18('REMOVE')}
          className="minus"
          onClick={this.handleMinusClick}
          disabled={value === min}
        />
        <p className={quantityClass}>{value}</p>
        <button
          aria-label={window.i18('ADD')}
          className="plus"
          onClick={this.handlePlusClick}
          disabled={value === max}
        />
      </div>
    );
  }
}

index.propTypes = propTypes;
index.defaultProps = defaultProps;
