import moment from 'moment';
import {
  push,
} from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';
// import { NotificationManager as Notif } from 'react-notifications';

import {
  RESET_RESULTS,
  SET_SEARCHED_HOTELS,
  SEARCH_HOTEL_START,
  SEARCH_HOTEL_ERROR,
  SEARCH_HOTEL_SUCCESS,
  SET_SELECTED_RESULT,
  SET_SELECTED_DETAILS_BEGIN,
  SET_SELECTED_DETAILS_SUCCESS,
  SET_STARS,
  TOGGLE_BREAKFAST,
  TOGGLE_LOYALTY_ENABLED,
  SET_PRICE_RANGE,
  SET_PRICES,
  ADD_SELECTED_OFFER,
  REMOVE_SELECTED_OFFER,
  TOGGLE_TRAVELERS_DISTRIBUTION,
  FILL_TRAVELER,
  ADD_TO_CART_BEGIN,
  ADD_TO_CART_ERROR,
  ADD_TO_CART_SUCCESS,
  ASSIGN_TRAVELER_TO_OFFER,
  TOGGLE_MAP,
  SET_SELECTED_MARKER,
  TOGGLE_ACCESSIBLE,
  SET_SORTING,
  SET_SELECTED_DETAILS_ERROR,
} from './constants';
import API from '../../utils/api';

export function resetResults() {
  return {
    type: RESET_RESULTS,
  };
}

export function setSearchedHotels(search) {
  return {
    type: SET_SEARCHED_HOTELS,
    search,
  };
}

export function searchHotelStart() {
  return {
    type: SEARCH_HOTEL_START,
  };
}

export function searchHotelError(error) {
  return {
    type: SEARCH_HOTEL_ERROR,
    error,
  };
}

export function setSorting(sorting) {
  return {
    type: SET_SORTING,
    sorting,
  };
}

export function searchHotelSuccess(results, travelers) {
  return (dispatch, getState) => {
    // Keep only results with offers and with prices
    results = results.filter(
      (res) => res.room_offers && res.room_offers.length > 0,
    );
    results.forEach((res) => {
      res.room_offers = res.room_offers.filter(
        (of) => of.incl_taxes_amount != null,
      );
    });
    // We calculate the max and min price for our price range filer
    const maxPrice = results.reduce((max, res) => {
      const price = res.room_offers[res.room_offers.length - 1].incl_taxes_amount;
      return price > max ? price : max;
    }, 0);

    const minPrice = results.reduce((min, res) => {
      const price = res.room_offers.length ? res.room_offers?.[0]?.incl_taxes_amount : 99999999;
      return price < min ? price : min;
    }, 99999999);

    const roundedMaxPrice = Math.round(maxPrice / 100) * 100;
    const roundedMinPrice = Math.floor(minPrice);

    dispatch(setPrices(roundedMinPrice, roundedMaxPrice));
    dispatch(setPriceRange(roundedMinPrice, roundedMaxPrice));

    // On desktop, we set the first hotel as selected by default
    let firstHotelId = null;
    if (results && results.length > 0) {
      firstHotelId = results[0].id;
    }
    if (!getState().app.isMobileDevice) {
      dispatch(setSelectedResult(firstHotelId, true));
    }

    // TODO Add optional chaining: check if possible at https://github.com/babel/babel/issues/11711
    results = results.sort((a, b) => {
      const firstPrice = a.room_offers?.[0]?.incl_taxes_amount || 10000000;
      const secondPrice = b.room_offers?.[0]?.incl_taxes_amount || 10000000;

      return firstPrice - secondPrice;
    });

    dispatch({
      type: SEARCH_HOTEL_SUCCESS,
      results,
      travelers,
    });
  };
}

export function setSelectedResult(id, keepMap = false) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SELECTED_RESULT,
      id,
    });
    if (!keepMap && getState().hotelResults.displayMap) {
      dispatch(toggleMap());
    }
    dispatch(getSelectedDetails(id));
  };
}

export function getSelectedDetails(id) {
  return (dispatch, getState) => {
    const {
      date,
      place,
      passengers,
    } = getState().hotelResults.search;
    const params = {
      checkin_date: `${moment(date.arrival).format('YYYYMMDD')}T120000`,
      checkout_date: `${moment(date.departure).format('YYYYMMDD')}T120000`,
      latitude: place.location.lat,
      longitude: place.location.lon,
      nb_rooms: 1,
      radius_km: place.radius,
      travelers: passengers,
    };
    // If an entity is selected, we add it to the params
    if (getState().search.entityID) {
      params.entity_id = getState().search.entityID;
    }
    dispatch({
      type: SET_SELECTED_DETAILS_BEGIN,
    });
    API.post(`/hotel/search/${id}`, params).then((res) => {
      dispatch({
        type: SET_SELECTED_DETAILS_SUCCESS,
        details: res.data.result,
      });
    }).catch(() => {
      dispatch({
        type: SET_SELECTED_DETAILS_ERROR,
      });
    });
  };
}

export function setStars(stars) {
  return {
    type: SET_STARS,
    count: stars,
  };
}

export function toggleLoyaltyEnabled() {
  return {
    type: TOGGLE_LOYALTY_ENABLED,
  };
}

export function toggleBreakfast() {
  return {
    type: TOGGLE_BREAKFAST,
  };
}

export function toggleAccessible() {
  return {
    type: TOGGLE_ACCESSIBLE,
  };
}

export function setPriceRange(min, max) {
  return {
    type: SET_PRICE_RANGE,
    min,
    max,
  };
}

export function setPrices(min, max) {
  return {
    type: SET_PRICES,
    min,
    max,
  };
}

export function addSelectedOffer(offer) {
  return {
    type: ADD_SELECTED_OFFER,
    offerId: offer.id,
    id: uuidv4(),
    maxOccupancy: offer.max_occupancy,
    pricePerNight: offer.incl_taxes_amount,
    img: offer.img_urls,
  };
}

export function removeSelectedOffer(id) {
  return {
    type: REMOVE_SELECTED_OFFER,
    id,
  };
}

export function toggleTravelersDistribution() {
  return {
    type: TOGGLE_TRAVELERS_DISTRIBUTION,
  };
}

export function fillTraveler(traveler) {
  return {
    type: FILL_TRAVELER,
    traveler,
  };
}

export function addToCart() {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_TO_CART_BEGIN,
    });

    const state = getState().hotelResults;
    const hotelId = state.selectedResult.id;
    const {
      travelers,
    } = state;
    const roomOfferTravelers = state.selectedResult.selectedOffers.map(
      (offer) => {
        const offerTravelers = offer.travelers.map((id) => travelers.find((t) => t.id.toString() === id));
        return {
          room_offer_id: offer.offerId,
          travelers: offerTravelers.filter((t) => t !== undefined),
        };
      },
    );

    const payload = {
      hotel_id: hotelId,
      room_offer_travelers: roomOfferTravelers,
    };

    if (state.search.travelID) {
      payload.travelID = state.search.travelID;
    }

    const { user } = getState().app;
    if (user.perms.includes('travel_agent')) {
      payload.customer_id = user.company_id;
    }

    // If an entity is selected, we add it to the params
    if (getState().search.entityID) {
      payload.entity_id = getState().search.entityID;
    }

    API.post('/cart/add', payload)
      .then(() => {
        dispatch({
          type: ADD_TO_CART_SUCCESS,
        });
        dispatch(push('/cart'));
      })
      .catch(() => {
        // Do not need this because we are catching everything localy now. See api.js interceptors
        /*
        const message = friendlyError(resp);
        Notif.warning(message, '', 0);
        */
        dispatch({
          type: ADD_TO_CART_ERROR,
        });
      });
  };
}

export function assignTravelerToOffer(id, traveler, travelerIndex) {
  return {
    type: ASSIGN_TRAVELER_TO_OFFER,
    id,
    traveler,
    travelerIndex,
  };
}

export function toggleMap() {
  return {
    type: TOGGLE_MAP,
  };
}

export function setSelectedMarker(id) {
  return {
    type: SET_SELECTED_MARKER,
    id,
  };
}
