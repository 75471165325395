import React from 'react';
import { useSelector } from 'react-redux';

const CovidCheckerHeader = () => {
  const [showIFrame, setShowIFrame] = React.useState(false);
  const { isMobileDevice } = useSelector((state) => state.app);
  const height = window.innerHeight;
  const headerHeight = document.querySelector('.main-header')?.offsetHeight;
  return !showIFrame ? (
    <div className="covid-checker-header">
      {isMobileDevice ? (
        <a onClick={() => setShowIFrame(true)}>{window.i18('COVID_INFO')}</a>
      ) : (
        <>
          {window.i18('COVID_INFO')}
          :
          {' '}
          <a onClick={() => setShowIFrame(true)}>{window.i18('COVID_INFO_TEXT')}</a>
        </>
      )}
    </div>
  ) : (
    <div className="covid-checker-iframe">
      <iframe src="https://www.covidchecker.com/embed" title="covidchecker" width="100%" height={height - headerHeight - 40} />
      <button className="button-main" onClick={() => setShowIFrame(false)}>{window.i18('CLOSE')}</button>
    </div>
  );
};

export default CovidCheckerHeader;
