import React from 'react';

import Location from '../../../components/SearchDetails/Location';
import DateTime from '../../../components/SearchDetails/DateTime';
import Passengers from '../../../components/SearchDetails/Passengers';
import Default from '../../../components/SearchDetails/Default';

const tripSearchDetails = (props) => {
  switch (props.selected) {
    case 'from':
      return (
        <Location
          searchLocation={props.from}
          field="from"
          selectedLocation={props.search.from}
          setLocation={props.setFromLocation}
          isMobileDevice={props.isMobileDevice}
          suggestedAddresses={props.suggestedAddresses}
        />
      );
    case 'to':
      return (
        <Location
          searchLocation={props.to}
          field="to"
          selectedLocation={props.search.to}
          setLocation={props.setToLocation}
          isMobileDevice={props.isMobileDevice}
          suggestedAddresses={props.suggestedAddresses}
        />
      );
    case 'out':
      return (
        <DateTime
          {...props}
          field="a"
          withTime
          isMobileDevice={props.isMobileDevice}
        />
      );
    case 'return':
      return (
        <DateTime
          {...props}
          field="r"
          withTime
          isMobileDevice={props.isMobileDevice}
        />
      );
    case 'passengers':
      return (
        <Passengers
          passengers={props.search.passengers}
          toggleSelected={props.toggleSelectedPassenger}
          ageCategories={props.ageCategories}
          openModal={props.openModal}
          cardsList={props.cardsList}
          userPermissions={props.userPermissions}
          travelers={props.travelers}
          searchTravelers={props.searchTravelers}
          hasTravelersSearch={props.hasTravelersSearch}
          addPassenger={props.addPassenger}
          editPassenger={props.editPassenger}
          checkForm={props.checkForm}
          isMobileDevice={props.isMobileDevice}
          field="passengers"
        />
      );
    default:
      return (
        <Default
          co2={props.co2}
          isMobileDevice={props.isMobileDevice}
        />
      );
  }
};

export default tripSearchDetails;
