import { combineReducers } from 'redux';

import {
  SET_SEARCHING_TRIP, SET_SEARCHING_HOTEL, CHECK_SUBSIDY_START, CHECK_SUBSIDY_ERROR, CHECK_SUBSIDY_SUCCESS, SET_SUBSIDY_NUMBER, SET_SEARCHING_CAR, SET_ENTITY,
} from './constants';

function typeReducer(state = 'trip', action) {
  switch (action.type) {
    case SET_SEARCHING_TRIP:
      return 'trip';
    case SET_SEARCHING_HOTEL:
      return 'hotel';
    case SET_SEARCHING_CAR:
      return 'car';
    default:
      return state;
  }
}

function subsidyReducer(state = null, action) {
  switch (action.type) {
    case CHECK_SUBSIDY_START:
      return { ...state, loading: true };
    case CHECK_SUBSIDY_SUCCESS:
      return { searchRequest: { ...action.result, nbAccompanying: action.result.nb_accompanying }, loading: false, number: action.number };
    case CHECK_SUBSIDY_ERROR:
      return { error: action.error, loading: false };
    case SET_SUBSIDY_NUMBER:
      return { ...state, number: action.number };
    default:
      return state;
  }
}

function entityIDReducer(state = null, { type, entity }) {
  switch (type) {
    case SET_ENTITY:
      return entity;
    default:
      return state;
  }
}

const searchReducer = combineReducers({
  type: typeReducer,
  subsidy: subsidyReducer,
  entityID: entityIDReducer,
});

export default searchReducer;
