import React from 'react';
import { getSvgIcon } from '../../utils/icons';

const Rating = ({ score }) => (
  <div className="rating">
    {getSvgIcon('badge', 'ui')}
    <p className="rating__score">
      {score}
      /10
    </p>
  </div>
);

export default Rating;
