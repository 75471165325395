import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
// Functions
import { deleteInfos } from '../actions';

class OnboardingDelete extends React.Component {
  constructor(props) {
    super(props);
    this.url = new URLSearchParams(this.props.location.search);
  }

  componentDidMount() {
    if (this.url.get('token') != null && this.url.get('email') != null) {
      this.props.deleteInfos(this.url.get('token'), this.url.get('email'));
    }
    this.props.goHome();
  }

  render() {
    return '';
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteInfos: (token, email) => dispatch(deleteInfos(token, email)),
  goHome: () => dispatch(push('/')),
});

export default connect(null, mapDispatchToProps)(OnboardingDelete);
