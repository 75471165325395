import React from 'react';

const Leaf = ({ completion }) => (
  <span className="leaf-wrapper">{constructLeaf(completion)}</span>
);

export default Leaf;
// We create this function to add the attribute completion to the mask which will set the leaf score
function constructLeaf(completion) {
  const svg = (
    <svg
      className="leaf-svg"
      width="35"
      height="39"
      viewBox="0 0 35 39"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <clipPath id="leaf-mask">
          <path d="M23.9014737,14.5003276 C23.5548841,14.4871932 23.2040116,14.6129227 22.939418,14.8775162 L20.1689505,17.6479838 L20.1689505,17.6479838 C19.66479,18.1521442 19.66479,18.9695499 20.1689505,19.4737103 C20.6731109,19.9778708 21.4905165,19.9778708 21.994677,19.4737103 L23.9014737,17.5669136 L25.8082705,19.4737103 C26.312431,19.9778708 27.1298366,19.9778708 27.633997,19.4737103 C28.1381575,18.9695499 28.1381575,18.1521442 27.633997,17.6479838 L24.8635295,14.8775162 C24.5989359,14.6129227 24.2480634,14.4871932 23.9014737,14.5003276 Z M34.9776792,28.2017127 C34.6906014,34.9701237 29.5232013,38.9183635 24.9299568,38.9183635 C20.3367123,38.9183635 16.6047011,34.6881066 17.7530122,33.8420552 C24.686531,31.934893 24.9299568,25.6635585 24.9299568,23.9714558 C24.9299568,22.279353 22.6333346,22.279353 22.6333346,23.9714558 C22.6333346,25.6635585 20.6237901,31.303901 16.6047011,31.0218839 C13.4468455,29.8938154 14.0210011,19.4591817 15.7434678,13.2548049 C8.5665232,23.4074215 12.5856122,31.8679353 15.7434678,38.6363463 C8.08806023,39.9524263 3.20773792,37.6962892 1.10250085,31.8679353 C-2.53381773,21.9033302 2.82496754,11.2806851 17.1788567,-2.84217094e-14 C32.9681347,10.4346337 35.264757,21.4333016 34.9776792,28.2017127 Z" fill="#01DCBE" />
        </clipPath>
      </defs>
      <g id="leaf">
        <path d="M23.9014737,14.5003276 C23.5548841,14.4871932 23.2040116,14.6129227 22.939418,14.8775162 L20.1689505,17.6479838 L20.1689505,17.6479838 C19.66479,18.1521442 19.66479,18.9695499 20.1689505,19.4737103 C20.6731109,19.9778708 21.4905165,19.9778708 21.994677,19.4737103 L23.9014737,17.5669136 L25.8082705,19.4737103 C26.312431,19.9778708 27.1298366,19.9778708 27.633997,19.4737103 C28.1381575,18.9695499 28.1381575,18.1521442 27.633997,17.6479838 L24.8635295,14.8775162 C24.5989359,14.6129227 24.2480634,14.4871932 23.9014737,14.5003276 Z M34.9776792,28.2017127 C34.6906014,34.9701237 29.5232013,38.9183635 24.9299568,38.9183635 C20.3367123,38.9183635 16.6047011,34.6881066 17.7530122,33.8420552 C24.686531,31.934893 24.9299568,25.6635585 24.9299568,23.9714558 C24.9299568,22.279353 22.6333346,22.279353 22.6333346,23.9714558 C22.6333346,25.6635585 20.6237901,31.303901 16.6047011,31.0218839 C13.4468455,29.8938154 14.0210011,19.4591817 15.7434678,13.2548049 C8.5665232,23.4074215 12.5856122,31.8679353 15.7434678,38.6363463 C8.08806023,39.9524263 3.20773792,37.6962892 1.10250085,31.8679353 C-2.53381773,21.9033302 2.82496754,11.2806851 17.1788567,-2.84217094e-14 C32.9681347,10.4346337 35.264757,21.4333016 34.9776792,28.2017127 Z" fill="#8BA3BF" />
        <rect style={{ width: `${(completion * 100) || 0}%` }} className="leaf-mask" fill="#01DCBE" mask="url(#mask-2)" y="-3.674" width={`${(completion * 100) || 0}%`} height="46.065" clipPath="url(#leaf-mask)" />
      </g>
    </svg>
  );
  return svg;
}
