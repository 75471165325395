import React from 'react';
import { getSvgIcon } from '../../utils/icons';

const AutoCompleteTravelers = (props) => {
  const formattedTravelers = props.travelers.list && props.travelers.list.map((traveler) => (
    <li className="common-text" key={traveler.uid}>
      <a onMouseDown={() => props.handleClick(traveler)}>
        <p className="autocompletion-item">
          {traveler.uid && getSvgIcon('use-user', 'ui/')}
          {`${traveler.firstname ? traveler.firstname : ''} ${traveler.lastname ? traveler.lastname : ''} (${traveler.email})`}
        </p>
      </a>
    </li>
  ));
  return props.travelers.fetching
    ? (
      <ul className="autocompletion-wrapper">
        <li>
          <p className="autocompletion-item">
            {window.i18('LOADING')}
            ...
          </p>
        </li>
      </ul>
    )
    : (
      <ul className="autocompletion-wrapper">
        {props.travelers.list?.length ? formattedTravelers : <li><p className="autocompletion-item">{window.i18('NO_RESULT')}</p></li>}
      </ul>
    );
};

export default AutoCompleteTravelers;
