import React from 'react';
import Footer from '../CNAMFooter/CnamFooter';
import { isCNAM } from '../../utils/functions';

function PageContainer({ children }) {
  return (
    <>
      <div>{children}</div>
      {isCNAM() && <Footer />}
    </>
  );
}

export default PageContainer;
