import { push } from 'connected-react-router';
import { toast as Notif } from 'react-toastify';
import {
  DOING_CHECKOUT,
  PROCESSING_START,
  PROCESSING_SUCCESS,
  PROCESSING_ERROR,
  RESET_CHECKOUT,
} from './constants';
import API from '../../utils/api';
import { checkAgency } from '../../utils/functions';
import { getTicketUIDsFromTicketIDs } from '../Cart/selectors';

function formatFields(fields) {
  if (fields) {
    fields = Object.keys(fields).map((name) => ({ name, value: fields[name] }));
  }
  return fields;
}

function formatBookingData(paymentToken, getState, fields) {
  const { checkout } = getState();
  const formattedFields = formatFields(fields);

  return {
    fields: formattedFields,
    ticket_ids: checkout.tickets,
    payment_token: paymentToken,
  };
}

export function doCheckout(tickets, amount, isConfirmingOption, missionOrders) {
  if (amount == null) {
    return;
  }
  return {
    type: DOING_CHECKOUT,
    tickets,
    isConfirmingOption,
    amount: amount.amount,
    payment_methods: amount.payment_methods,
    required_fields: amount.required_fields,
    optional_fields: amount.optional_fields,
    need_approval: amount.need_approval,
    is_compliant: amount.is_compliant,
    travel_policy: amount.travel_policy,
    missionOrders,
  };
}

export function resetCheckout() {
  return {
    type: RESET_CHECKOUT,
  };
}

export function triggerCheckout(tickets, amount, missionOrders) {
  return (dispatch, getState) => {
    if (tickets && tickets.length) { dispatch(doCheckout(tickets, amount, null)); }
    if (missionOrders) {
      // We flatten the multiple missionOrders ticketIDs
      // see https://codeburst.io/how-to-flatten-a-nested-javascript-array-628e01b85512
      const ticketIDs = [].concat(...missionOrders.map((mo) => mo.ticketIDs));
      const ticketUIDs = getTicketUIDsFromTicketIDs(getState().cart.content, ticketIDs);
      dispatch(doCheckout(ticketUIDs, amount, null, missionOrders));
    }
    dispatch(push('/cart/checkout'));
  };
}

export function triggerConfirm(tickets, amount) {
  return (dispatch) => {
    dispatch(triggerCheckout(tickets, amount));
    dispatch(payTickets());
  };
}

export function processingStart(action) {
  return {
    type: PROCESSING_START,
    action,
  };
}

export function processingSuccess(success) {
  return {
    type: PROCESSING_SUCCESS,
    success,
  };
}

export function processingError(error) {
  return (dispatch, getState) => {
    const mail = checkAgency(getState().app.user, 'vairon') ? 'affaires2@vaironvoyages.com' : 'support@thetreep.com';
    const defaultError = [
      window.i18('BOOKING_ERROR'),
      `${window.i18('PLEASE_CONTACT_US_ON')} ${mail} ${window.i18('TO_KNOW_MORE_ABOUT_STATUS')}`,
    ];
    dispatch({
      type: PROCESSING_ERROR,
      error: error || defaultError,
    });
  };
}

function handleErrors() {
  return (dispatch) => {
    // Do not need this because we are catching everything localy now. See api.js interceptors
    /*
    Notif.warning(friendlyError(error), '', 0);
    */
    dispatch(processingError(null));
  };
}

export function changePaymentMethod() {
  // @todo
}

export function analyticPageRequired(amount) {
  if (!amount) {
    return true;
  }
  const hasRequired = amount.required_fields != null && amount.required_fields.length > 0;
  const hasOptional = amount.optional_fields != null && amount.optional_fields.length > 0;
  const nbMethods = amount.payment_methods != null ? amount.payment_methods.length : 0;
  return hasRequired || hasOptional || nbMethods > 1;
}

export function creditCardPageRequired(amount) {
  if (!amount) {
    return true;
  }
  return amount.payment_methods && amount.payment_methods.length === 1 && (amount.payment_methods[0] === 'PARTIAL_SUBSIDY' || amount.payment_methods[0] === 'CREDIT_CARD');
}

export function payTickets(paymentToken = null, fields = null) {
  return (dispatch, getState) => {
    dispatch(processingStart({
      loadingMessage: window.i18('WE_VERIFY_YOUR_INFOS'),
      status: window.i18('RESERVED'),
    }));
    const { user } = getState().app;

    const data = formatBookingData(paymentToken, getState, fields);
    if (user.perms.includes('travel_agent')) {
      data.customer_id = user.company_id;
    }

    API.post('/treep/book', data)
      .then((response) => {
        if (response && response.data && response.data.result && response.data.result.errors) {
          dispatch(handleErrors(response));
        } else {
          Notif.success(window.i18('BOOKING_CONFIRMED'));
          dispatch(processingSuccess({
            message: window.i18('YOUR_BOOKING_IS_CONFIRMED'),
            co2: response.data.result.co2,
            extraInfo: response.data.result.extra_info,
          }));
        }
      })
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };
}

export function requestOption(paymentToken = null, fields = null) {
  return (dispatch, getState) => {
    dispatch(processingStart({
      loadingMessage: window.i18('WE_VERIFY_YOUR_INFOS'),
      status: window.i18('RESERVED'),
    }));
    const optionData = formatBookingData(paymentToken, getState, fields);

    API.post('/ticket/option', optionData)
      .then((response) => {
        if (response && response.data && response.data.result && response.data.result.errors) {
          dispatch(handleErrors(response));
        } else {
          Notif.success('Option posée !');
          const ttl = response.data.result.tickets.reduce((oldTicket, ticket) => {
            if (ticket.ticket_time_limit > oldTicket.ticket_time_limit && oldTicket) {
              return {
                ticket_time_limit: oldTicket.ticket_time_limit,
              };
            }
            return {
              ticket_time_limit: ticket.ticket_time_limit,
            };
          });
          const ticketTimeLimit = ttl.ticket_time_limit;
          dispatch(processingSuccess({
            message: window.i18('YOUR_OPTION_IS_SET'),
            date: ticketTimeLimit,
            co2: response.data.result.co2,
            extraInfo: response.data.result.extra_info,
            sandboxMode: getState().app.sandboxMode,
          }));
        }
      })
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };
}

export function requestApproval(travelIDs, approverUsername, emergencyApprover, fields, message, exemption, missionOrderData) {
  return (dispatch, getState) => {
    if (!getState().checkout.isProcessing) {
      dispatch(processingStart({
        loadingMessage: window.i18('SENDING_APPROBATION_REQUEST'),
        status: window.i18('AWAITING_APPROVAL'),
      }));
    }
    const params = {
      approverUsername,
      travelIDs,
      analytics: formatFields(fields) || null,
      message: message || null,
      exemption: exemption || null,
      secondaryApproverUsername: emergencyApprover || null,
      missionOrderData: missionOrderData || null,
    };

    API.post('approval/request?version=2', params)
      .then((response) => {
        if (response?.data?.result?.errors) {
          dispatch(handleErrors(response));
        } else if (response) {
          let message = `${window.i18('YOUR_REQUEST_WAS_SENT_TO')} ${approverUsername}`;
          let newStatus = false;
          if (response.data?.result?.status === 'allowed_automatically') {
            message = window.i18('YOUR_REQUEST_WAS_ACCEPTED_AUTOMATICALLY');
            Notif.success(window.i18('REQUEST_ACCEPTED'));
            newStatus = window.i18('RESERVED');
          } else {
            Notif.success(window.i18('REQUEST_SENT'));
          }
          dispatch(processingSuccess({
            message,
            co2: response.data.result.co2,
            status: newStatus,
          }));
        } else throw new Error();
      }, (error) => {
        dispatch(handleErrors(error));
      });
  };
}

export function requestOptionAndApproval(paymentToken = null, fields = null, travelIDs, approverUsername, emergencyApprover, message, exemption, missionOrderData) {
  return (dispatch, getState) => {
    dispatch(processingStart({
      loadingMessage: window.i18('SENDING_APPROBATION_REQUEST'),
      status: window.i18('AWAITING_APPROVAL'),
    }));

    const optionData = formatBookingData(paymentToken, getState, fields);

    API.post('/ticket/option', optionData)
      .then((response) => new Promise((resolve) => {
        if (response?.data?.result?.errors) {
          dispatch(handleErrors(response));
        } else if (response) {
          resolve();
        } else throw new Error();
      }))
      .then(() => {
        dispatch(requestApproval(travelIDs, approverUsername, emergencyApprover, fields, message, exemption, missionOrderData));
      })
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };
}

export function confirmTickets(paymentToken, fields) {
  return (dispatch, getState) => {
    dispatch(processingStart({
      loadingMessage: window.i18('WE_VERIFY_YOUR_INFOS'),
      status: window.i18('RESERVED'),
    }));

    const data = formatBookingData(paymentToken, getState, fields);

    API.post('/ticket/confirm', data)
      .then((response) => {
        if (response && response.data && response.data.result && response.data.result.errors) {
          dispatch(handleErrors(response));
        } else {
          Notif.success(window.i18('BOOKING_CONFIRMED'));
          dispatch(processingSuccess({
            message: window.i18('YOUR_BOOKING_IS_CONFIRMED'),
            co2: response.data.result.co2,
            extraInfo: response.data.result.extra_info,
          }));
        }
      })
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };
}
