import React from 'react';
import * as yup from 'yup';

import { withFormik } from 'formik';
import UserInput from '../Inputs/UserInput';

const ResetPasswordForm = (props) => (
  <form onSubmit={props.handleSubmit}>
    <div className="user-form">
      <span className="submit-error">{props.status}</span>
      <UserInput
        type="password"
        label={window.i18('PASSWORD')}
        placeholder="••••••••"
        name="password"
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        error={props.touched.password && props.errors.password}
      />
      <div className="form-submit">
        <button type="submit" className="button-main">
          {window.i18('UPDATE')}
        </button>
      </div>
    </div>
  </form>
);

const schema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Au moins 8 caractères requis')
    .matches(/^(?=.*[A-Z]).*$/, 'Au moins une majuscule requise')
    .matches(/^(?=.*[0-9]).*$/, 'Au moins un chiffre requis')
    .matches(/^(?=.*[a-z]).*$/, 'Au moins une minuscule requise')
    .required('Ce champ est nécessaire.'),
});

const handleSubmit = (values, actions) => {
  actions.props.handleSubmit(values).then(
    () => {
      actions.setSubmitting(false);
    },
    (error) => {
      actions.setStatus(error.msg);
    },
  );
};

export default withFormik({
  validationSchema: schema,
  mapPropsToValues: () => ({
    password: '',
  }),
  handleSubmit,
})(ResetPasswordForm);
