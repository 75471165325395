import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { validateChoice, setSelectedChoice, setSelectedCabin } from '../actions';
import FromToDetails from '../../../components/FromToDetails';
import { displayPrice, formatTicketTags, formatFlexibility } from '../../../utils/converters';
import { getSelectedProposal } from '../selectors';
import Carrier from '../../../components/Carrier';
import { uniqueArray } from '../../../utils/functions';
import InfoTooltip from '../../../components/Tooltips/Info';
import LuggagesTooltip from '../../../components/Tooltips/Luggages';
import { getSvgIcon } from '../../../utils/icons';

function countOccurrence(arr) {
  const a = [];
  const b = [];
  let prev;

  arr.sort();
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i] !== prev) {
      a.push(arr[i]);
      b.push(1);
    } else {
      b[b.length - 1] += 1;
    }
    prev = arr[i];
  }

  return [a, b];
}

export default function OfferSelector() {
  const dispatch = useDispatch();
  const { isMobileDevice } = useSelector((state) => state.app);
  const results = useSelector((state) => state.results);
  const {
    carriers, selectedTransportMode, places, selectedCabin, selectedChoice,
  } = results[results.currentTrip];
  const handleClick = () => dispatch(validateChoice());
  const [top, setTop] = useState(0);
  const [unfolded, setUnfolded] = useState([]);

  const toggleFold = (choiceID) => {
    const i = unfolded.indexOf(choiceID);
    if (i === -1) {
      setUnfolded([...unfolded, choiceID]);
    } else {
      setUnfolded([...unfolded].filter((item) => item !== choiceID));
    }
  };
  const div = useRef();

  useEffect(() => {
    // See https://stackoverflow.com/a/35211286/11702623 :
    // MutationObserver looks for changes in the DOM, we use it to know
    // if the element with the 'selected-proposal' id has changed
    const parent = document.querySelector('.result-list');
    const firstResult = document.querySelector('.result-item');
    const observer = new MutationObserver((() => {
      const element = document.getElementById('selected-proposal');
      if (element && top !== element.offsetTop && div.current) {
        let newTop = element?.offsetTop + element?.offsetHeight / 2 - div.current.offsetHeight / 2;
        const last = element.parentNode.lastChild;
        const bottom = last.offsetTop + last.offsetHeight;
        const divBottom = newTop + div.current.offsetHeight;
        const maxTop = firstResult?.offsetTop || 0;
        if (divBottom > bottom) {
          newTop = bottom - (div.current.offsetHeight + 22);
        }
        if (newTop < maxTop) {
          newTop = maxTop;
        }
        setTop(newTop);
      }
    }));

    // start observing
    if (!isMobileDevice) {
      observer.observe(parent, {
        childList: true,
        subtree: true,
        attributes: true,
      });
    }

    return () => {
      if (!isMobileDevice) {
        observer.disconnect();
      }
    };
  }, [isMobileDevice, top]);

  const setCabin = (cabin) => {
    dispatch(setSelectedCabin(cabin));
    const newProposal = getSelectedProposal(results);
    dispatch(setSelectedChoice(newProposal.choices.filter((choice) => choice.cabin === cabin)[0].id));
  };

  const proposal = getSelectedProposal(results);

  const cabinClasses = uniqueArray(proposal.choices.map((choice) => choice.cabin) || []);

  proposal.choices = proposal.choices.filter((c) => c.cabin === selectedCabin[selectedTransportMode]);
  const { sections } = proposal;
  return (
    <div className={classNames('col', isMobileDevice ? 'col-12' : 'col-5')} style={{ position: 'absolute', top, right: 0 }} ref={div}>
      {proposal.choices.length > 0 && (
      <div className="trip-offers">
        <div className="trip-offer-selector">
          <div className="trip-offer-selector__logo m-bot">
            <Carrier carrier={proposal.marketingCarrier} carriers={carriers} transport={selectedTransportMode} />
          </div>
          <FromToDetails sections={sections} places={places} selectedTransportMode={selectedTransportMode} carriers={carriers} />
          <div className="trip-offer-selector__title">
            <p>
              {window.i18('AVAILABLE_OFFERS')}
              {' '}
              {!isMobileDevice && <span className="badge" style={{ backgroundColor: '#4A5875', borderColor: '#4A5875' }}>{selectedCabin[selectedTransportMode]}</span>}
            </p>
            {isMobileDevice && (
            <ul className="trip-offer-selector__classes">
              {cabinClasses.map((cabin, i) => {
                const selected = selectedCabin[selectedTransportMode] === cabin;
                return (
                  <li key={i}>
                    <button className={selected ? 'selected' : ''} onClick={() => setCabin(cabin)}>{cabin}</button>
                  </li>
                );
              })}
            </ul>
            )}
          </div>
          <div className="trip-offer-selector__offers">
            {proposal.choices.map((choice, i) => {
              const isNegociatedFare = choice.tags?.indexOf('negociated-fare') > -1;
              const isMissionaryFare = choice.tags?.indexOf('ngo-fare') > -1;
              const isCompliant = choice.tags?.indexOf('compliant') > -1;
              const offers = choice.choice.map((c) => proposal.offers.find((offer) => offer.id === c.offerID));
              const flexibilities = offers.map((offer) => formatFlexibility(offer.flexibility));
              const [flex, count] = countOccurrence(flexibilities);
              return (
                <div className="trip-offer" key={i}>
                  <label className="trip-offer__radio common-radio" htmlFor={`choice-${i}`}>
                    <input type="radio" name="offer" id={`choice-${i}`} checked={choice.id === selectedChoice[selectedTransportMode]} onChange={() => dispatch(setSelectedChoice(choice.id))} />
                  </label>
                  <div className="trip-offer__infos">
                    <p className="flex align-center">
                      {flex.map((f, i) => `${count[i]} ${window.i18('TICKET')}${count[i] > 1 ? 's' : ''} ${f}`).join(' + ')}
                      { !isMissionaryFare && (isNegociatedFare || isCompliant) && <span className="small-badge m-left" style={{ background: isNegociatedFare ? '#7BBEDF' : '#01dcbe', fontSize: 12 }}>{formatTicketTags(isNegociatedFare ? 'negociated-fare' : 'compliant')}</span>}
                      { (isMissionaryFare) && <span className="small-badge m-left" style={{ background: '#7BBEDF', fontSize: 12 }}>{formatTicketTags('ngo-fare')}</span>}
                    </p>
                    {choice.choice.map((c, i) => {
                      const offer = proposal.offers.find((offer) => offer.id === c.offerID);
                      const quantity = c.travelerIDs.length;
                      return (
                        <React.Fragment key={i}>
                          <div className="trip-offer__fare">
                            {displayPrice(offer.price / 100)}
                            {' - '}
                            {quantity}
                            {' '}
                            {quantity > 1 ? window.i18('TICKETS') : window.i18('TICKET')}
                            {' '}
                            {offer.name}
                            {offer.tags?.includes('upgraded') && (
                              <span className="small-badge">
                                {window.i18('UPGRADED')}
                              </span>
                            )}
                            {offer.tags?.includes('disabled-pax') && (
                              <span className="small-badge">
                                {getSvgIcon('handicap', 'ui')}
                              </span>
                            )}
                            {offer.info && (
                              <InfoTooltip message={offer.info} />
                            )}
                            {offer.luggages?.length && (
                              <LuggagesTooltip luggages={offer.luggages} />
                            )}
                          </div>
                          {unfolded.includes(choice.id) && (
                            offer.fares.map((fare, i) => (
                              <React.Fragment key={i}>
                                <p className="trip-offer__fare">
                                  <strong>
                                    {window.i18('TICKET')}
                                    {' '}
                                    #
                                    {i + 1}
                                  </strong>
                                </p>
                                <p className="trip-offer__fare" dangerouslySetInnerHTML={{ __html: fare.condition }} />
                              </React.Fragment>
                            ))
                          )}

                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className="trip-offer__price">
                    <p>
                      {displayPrice(choice.price / 100)}
                    </p>
                    <button className={classNames('trip-offer__toggle', { 'trip-offer__toggle--active': unfolded.includes(choice.id) })} onClick={() => toggleFold(choice.id)}>
                      {window.i18('SEE_DETAILS')}
                      <i className="Chevron" />
                    </button>
                  </div>
                </div>
              );
            })}
            {proposal.choices.length === 0 && (
            <p>Veuillez selectionner une offre.</p>
            )}
          </div>

          <div className="trip-offer-selector__btn">
            <button disabled={!proposal.choices.length > 0} className="button-main" onClick={handleClick}>{window.i18('CHOSE_OFFER')}</button>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}
