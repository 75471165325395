/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';
import { closestTo } from '../../utils/functions';
import RadiusRange from '../RadiusRange';
import { getSvgIcon } from '../../utils/icons';

const icons = {
  common: require('../../../images/icons/transport/address.png'),
  address: require('../../../images/icons/transport/address.png'),
  administrative_region: require('../../../images/icons/transport/administrative_region.png'),
  airport: require('../../../images/icons/transport/airport.png'),
  stop_point: require('../../../images/icons/transport/stop_point.png'),
};

const defaultLocations = [
  {
    ID: 'Paris, France',
    lat: '48.8534',
    lon: '2.3488',
    name: 'Paris, France',
    src: 'stations_database',
    city: 'Paris',
    country: 'France',
    description: 'Paris, France',
    type: 'city',
    formatted_address: 'Paris, France',
  },
  {
    ID: 'Lyon, France',
    lat: '45.7677',
    lon: '4.8349',
    name: 'Lyon, France',
    src: 'stations_database',
    city: 'Lyon',
    country: 'France',
    description: 'Lyon, France',
    type: 'city',
    formatted_address: 'Lyon, France',
  },
  {
    ID: 'Marseille, France',
    lat: '43.3027',
    lon: '5.3804',
    name: 'Marseille, France',
    src: 'stations_database',
    city: 'Marseille',
    zip_code: '13232',
    country: 'France',
    description: 'Marseille, France',
    type: 'city',
    formatted_address: 'Marseille, France',
  },
  {
    ID: 'Toulouse, France',
    lat: '43.6045',
    lon: '1.4440',
    name: 'Toulouse, France',
    src: 'stations_database',
    city: 'Toulouse',
    zip_code: '31000',
    country: 'France',
    description: 'Toulouse, France',
    type: 'city',
    formatted_address: 'Toulouse, France',
  },
  {
    ID: 'Bordeaux, France',
    lat: '44.8259',
    lon: '-0.5567',
    name: 'Bordeaux, France',
    src: 'stations_database',
    city: 'Bordeaux',
    zip_code: '33700',
    country: 'France',
    description: 'Bordeaux, France',
    type: 'city',
    formatted_address: 'Bordeaux, France',
  },
  {
    ID: 'Lille, France',
    lat: '50.6304',
    lon: '3.0709',
    name: 'Lille, France',
    src: 'stations_database',
    city: 'Lille',
    zip_code: '59777',
    country: 'France',
    description: 'Lille, France',
    type: 'city',
    formatted_address: 'Lille, France',
  },
  {
    ID: 'Nice, France',
    lat: '43.6966',
    lon: '7.2719',
    name: 'Nice, France',
    src: 'stations_database',
    city: 'Nice',
    zip_code: '06088',
    country: 'France',
    description: 'Nice, France',
    type: 'city',
    formatted_address: 'Nice, France',
  },
  {
    ID: 'Nantes, France',
    lat: '47.2161',
    lon: '-1.5424',
    name: 'Nantes, France',
    src: 'stations_database',
    city: 'Nantes',
    zip_code: '44000',
    country: 'France',
    description: 'Nantes, France',
    type: 'city',
    formatted_address: 'Nantes, France',
  },
  {
    ID: 'Strasbourg, France',
    lat: '48.5850',
    lon: '7.7346',
    name: 'Strasbourg, France',
    src: 'stations_database',
    city: 'Strasbourg',
    zip_code: '67100',
    country: 'France',
    description: 'Strasbourg, France',
    type: 'city',
    formatted_address: 'Strasbourg, France',
  },
  {
    ID: 'Montpellier, France',
    lat: '43.6047',
    lon: '3.8807',
    name: 'Montpellier, France',
    src: 'stations_database',
    city: 'Montpellier',
    country: 'France',
    description: 'Montpellier, France',
    type: 'city',
    formatted_address: 'Montpellier, France',
  },
  {
    ID: 'Rennes, France',
    lat: '48.1035',
    lon: '-1.6727',
    name: 'Rennes, France',
    src: 'stations_database',
    city: 'Rennes',
    zip_code: '35005',
    country: 'France',
    description: 'Rennes, France',
    type: 'city',
    formatted_address: 'Rennes, France',
  },
];

export default class Location extends React.Component {
  placesRefs = {}

  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyboardEvents);
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.field !== this.props.field && this.state.selected > 0)
      || prevProps.searchLocation.places !== this.props.searchLocation.places
    ) {
      this.setState({
        selected: 0,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyboardEvents);
  }

  keyboardEvents = ({ keyCode }) => {
    const locations = this.props.searchLocation.places || defaultLocations;
    const { selected } = this.state;
    if (keyCode === 38 || keyCode === 40) {
      const index = closestTo(
        locations,
        selected,
        keyCode === 38 ? 'prev' : 'next',
      );
      this.setState({
        selected: index,
      });
      this.placesRefs[index].scrollIntoView(false);
    }
    if (
      keyCode === 13
      || (keyCode === 9 && this.props.selectedLocation === {})
    ) {
      this.pickLocation(locations[selected], selected);
    }
  }

  pickLocation = (location, key) => {
    this.props.setLocation(location);
    this.setState({
      selected: key,
    });
  }

  render() {
    const {
      searchLocation, radius, setPlaceRadius, isHotel, isMobileDevice, field, suggestedAddresses,
    } = this.props;
    let displayedLocations = defaultLocations;
    if (suggestedAddresses) {
      displayedLocations = [...suggestedAddresses.map((a) => ({ ...a, custom: true })), ...defaultLocations];
    }
    const locations = searchLocation.places || displayedLocations;
    const { selected } = this.state;

    const getIcon = (iconName) => (icons[iconName] ? icons[iconName] : icons.common);

    return (
      <Container title={window.i18('SELECT_PLACE')} isMobileDevice={isMobileDevice} label={field}>
        <ul className="autocompletion">
          {locations.length === 0 && <p>{window.i18('NO_RESULT')}</p>}
          {locations.map((loc, key) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
              ref={(el) => { this.placesRefs[key] = el; }}
              className={`autocompletion__item ${
                selected === key ? 'selected' : ''
              }`}
              onClick={() => this.pickLocation(loc, key)}
              key={key}
            >
              <span className="autocompletion__item__icon">
                {loc.custom ? getSvgIcon('loc-pin-angle', 'ui') : <img src={getIcon(loc.type)} alt="" />}
              </span>
              <span className="autocompletion__item__description">
                {loc.description}
              </span>
            </li>
          ))}
        </ul>
        {isHotel && (
          <div className="hotel-search-radius">
            <p>
              {window.i18('IN_A_RADIUS')}
              {' '}
              <strong>
                {' < '}
                {radius}
                km
              </strong>
            </p>
            <RadiusRange value={radius} handleChange={setPlaceRadius} />
          </div>
        )}
      </Container>
    );
  }
}

Location.propTypes = {
  setLocation: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  searchLocation: PropTypes.shape({
    places: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  selectedLocation: PropTypes.shape({}).isRequired,
};
