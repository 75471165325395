import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
// Components
import UserForm from '../../../components/UserForm/new';
import Checkbox from '../../../components/Checkbox';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

class HotelTraveler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataAccuracy: false,
    };
  }

  toggleDataAccuracy = () => this.setState({ dataAccuracy: !this.state.dataAccuracy })

  render() {
    const {
      handleSubmit, travelerValidationSchema, traveler, closeModal,
    } = this.props;
    return (
      <div className="passenger-modal">
        <div className="modal-with-tagline">
          <header className="tagline">
            <h1 className="common-title">{window.i18('UPDATE_TRAVELER')}</h1>
          </header>
        </div>
        <Formik
          onSubmit={(values) => {
            handleSubmit(values);
            closeModal();
          }}
          initialValues={{
            ...traveler,
            gender: traveler.gender ? traveler.gender : '',
            firstname: traveler.firstname ? traveler.firstname : '',
            lastname: traveler.lastname ? traveler.lastname : '',
            email: traveler.email ? traveler.email : '',
            phone: traveler.phone ? traveler.phone : '',
          }}
          validationSchema={travelerValidationSchema}
        >
          {({
            values, touched, errors, setFieldValue,
          }) => (
            <Form className="passenger-profile__body">
              <UserForm
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                touched={touched}
                withPhone
                withHandicap
              />
              <div className="row m-top">
                <Checkbox
                  small
                  checked={this.state.dataAccuracy}
                  onChange={this.toggleDataAccuracy}
                  value={window.i18('AGGREE_FORM_RESPONSABILITY')}
                />
              </div>
              <div className="row passenger-profile__footer">
                <div className="col col-12">
                  <button type="submit" className="button-main m-auto" disabled={!this.state.dataAccuracy}>
                    {window.i18('CONFIRM')}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

HotelTraveler.propTypes = propTypes;

export default HotelTraveler;
