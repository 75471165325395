import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './Container';
import Checkbox from '../Checkbox';

// Functions
import { getSvgIcon } from '../../utils/icons';
import { capitalize } from '../../utils/functions';

const propTypes = {
  carTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCarTypes: PropTypes.func.isRequired,
};

class CarTypes extends React.Component {
  constructor(props) {
    super(props);
    const { carTypes } = props;
    if (carTypes.includes('UP') || carTypes.includes('UM') || carTypes.includes('UG')) {
      this.state = {
        type: 'trucks',
      };
    } else {
      this.state = {
        type: 'cars',
      };
    }
  }

  render() {
    const { carTypes, setCarTypes } = this.props;
    const { type } = this.state;
    const list = {
      cars: {
        TP: capitalize(window.i18('TP')),
        TM: capitalize(window.i18('TM')),
        TG: capitalize(window.i18('TG')),
      },
      trucks: {
        UP: capitalize(window.i18('UP')),
        UM: capitalize(window.i18('UM')),
        UG: capitalize(window.i18('UG')),
      },
    };

    const onChange = (key) => {
      const newCarTypes = carTypes.map((t) => t);
      if (carTypes.includes(key)) {
        newCarTypes.splice(newCarTypes.indexOf(key), 1);
      } else {
        newCarTypes.push(key);
      }
      setCarTypes(newCarTypes);
    };

    const onTypeChange = (e) => {
      if (e.target.value === 'cars' && this.state.type !== 'cars') {
        setCarTypes(['TP']);
      } else if (e.target.value === 'trucks' && this.state.type !== 'trucks') {
        setCarTypes(['UP']);
      }
      this.setState({ type: e.target.value });
    };

    return (
      <Container title={window.i18('SELECT_CATEGORY')} isMobileDevice={this.props.isMobileDevice} label={this.props.field}>
        <div className="car-type-switch">
          <div className="car-type-switch__icons">
            <div className="car-type-switch__car">
              {getSvgIcon('veh-car', 'transport/svg')}
            </div>
            <div className="car-type-switch__truck">
              {getSvgIcon('veh-truck', 'transport/svg')}
            </div>
          </div>
          <div className="car-type-switch__input">
            <label htmlFor="cars" className={type === 'cars' ? 'active' : ''}>
              <span>{window.i18('CAR')}</span>
              <input
                type="radio"
                name="type"
                id="cars"
                value="cars"
                checked={type === 'cars'}
                onChange={onTypeChange}
              />
            </label>
            <label htmlFor="trucks" className={type === 'trucks' ? 'active' : ''}>
              {window.i18('COMMERCIAL_VEHICLE')}
              <input
                type="radio"
                name="type"
                id="trucks"
                value="trucks"
                checked={type === 'trucks'}
                onChange={onTypeChange}
              />
            </label>
          </div>
        </div>
        <ul className="car-type-switch__checkboxes">
          {Object.keys(list[type]).map((key) => <li key={key}><Checkbox value={list[type][key]} checked={carTypes.includes(key)} onChange={() => onChange(key)} /></li>)}
        </ul>
      </Container>
    );
  }
}

CarTypes.propTypes = propTypes;

export default CarTypes;
