import React from 'react';
import {
  Formik, Form,
} from 'formik';
// Components
import DocumentForm from '../../../components/DocumentForm';

function Document({ closeModal, initialValues, onSubmit }) {
  return (
    <div className="passenger-modal">
      <div className="modal-with-tagline">
        <header className="tagline">
          <h1 className="common-title">
            {window.i18('UPDATE_DOCUMENT')}
          </h1>
        </header>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onSubmit(values);
          closeModal();
        }}
      >
        {({
          values, touched, errors, setFieldValue,
        }) => (
          <Form className="passenger-profile__body">
            <DocumentForm values={values} touched={touched} errors={errors} setFieldValue={setFieldValue} />
            <div className="row m-top">
              <div className="col col-6 justify-center">
                <button type="submit" className="button-main">{window.i18('CONFIRM')}</button>
              </div>
              <div className="col col-6 justify-center">
                <button onClick={closeModal} className="button-secondary">{window.i18('CANCEL')}</button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Document;
