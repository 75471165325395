import React from 'react';
import * as yup from 'yup';

import { withFormik } from 'formik';
import UserInput from '../Inputs/Material';

const LoginForm = (props) => (
  <form onSubmit={props.handleSubmit} className={props.className}>
    <span className="submit-error">
      {props.status}
    </span>
    <div className="row">
      <div className="col col-12">
        <UserInput
          type="input"
          label={window.i18('EMAIL')}
          value={props.values.username}
          placeholder="john.doe"
          name="username"
          onChange={(value) => props.setFieldValue('username', value)}
          touched={props.touched.username}
          error={props.errors.username}
        />
      </div>
    </div>
    <div className="row m-top">
      <div className="col col-12">
        <UserInput
          type="password"
          label={window.i18('PASSWORD')}
          value={props.values.password}
          placeholder={window.i18('YOUR_PASSWORD')}
          name="password"
          onChange={(value) => props.setFieldValue('password', value)}
          error={props.errors.password}
          touched={props.touched.password}
        />
      </div>
    </div>
    <div className="row m-top">
      <div className="col col-12">
        <button type="submit" className="button-main">
          {props.isSubmitting ? '...' : props.btnText}
        </button>
      </div>
    </div>
  </form>
);

LoginForm.defaultProps = {
  btnText: 'Connexion',
};

const schema = yup.object().shape({
  username: yup.string().required('Ce champ est nécessaire.'),
  password: yup.string().required('Ce champ est nécessaire.'),
});

const handleSubmit = (values, actions) => {
  actions.setStatus(null);
  const formatted = {
    ...values,
    username: values.username.trim(),
  };
  actions.props.handleSubmit(formatted).then(
    () => {
      actions.setSubmitting(false);
    },
    (error) => {
      actions.setSubmitting(false);
      actions.setStatus(error && error.msg);
      if (actions.props.handleFail) actions.props.handleFail();
    },
  );
};

export default withFormik({
  validationSchema: schema,
  mapPropsToValues: () => ({
    username: '',
    password: '',
  }),
  handleSubmit,
})(LoginForm);
