import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import createRootReducer from './reducers';

export const history = createBrowserHistory();

let reducer = createRootReducer(history);

if (process.env.NODE_ENV === 'development') {
  const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['tripSearch', 'hotelSearch', 'carRentalSearch', 'app', 'search'],
  };
  reducer = persistReducer(persistConfig, reducer);
}

export default function configureStore(preloadedState) {
  if (process.env.NODE_ENV === 'development') {
    const store = createStore(
      reducer,
      preloadedState,
      composeWithDevTools(
        applyMiddleware(
          thunkMiddleware,
          // loggerMiddleware,
          routerMiddleware(history),
        ),
      ),
    );
    const persistor = persistStore(store);
    return { store, persistor };
  }

  return createStore(
    reducer,
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware,
        routerMiddleware(history),
      ),
    ),
  );
}
