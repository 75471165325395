import React from 'react';
import { Link } from 'react-router-dom';
import { getSvgIcon } from '../../utils/icons';

const subNavLink = (props) => (
  <Link
    to={props.to}
    exact={props.exact}
  >
    <li
      data-testid={`link-to-${props.to}`}
      className={`${props.active
        ? 'active '
        : ''}nav-link link-label common-sub-title`}
    >
      {getSvgIcon(props.svg, 'ui/')}
      <span>{props.label}</span>
    </li>
  </Link>
);

export default subNavLink;
