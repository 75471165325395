import React from 'react';
import { getSvgIcon } from '../../../utils/icons';
import { friendlyError } from '../../../utils/api';

const CNAMSubsidyError = ({ error, closeModal }) => {
  const errorMessage = ((err) => {
    switch (err && err.data && err.data.api_code) {
      case 15:
        return 'Pour réserver un trajet en bateau';
      case 21:
        return 'Nous avons un problème avec cette demande';
      default:
        return friendlyError(err);
    }
  })(error);
  return (
    <div className="modal-inner cnam-subsidy-error">
      <section className="modal-section">
        {getSvgIcon('support', 'ui/')}
      </section>
      <section className="modal-section">
        <p>
          {errorMessage}
          , veuillez contacter l'agence de voyage
          {' '}
          <strong>par le Tchat</strong>
          {' '}
          ou par téléphone au
          {' '}
          <strong>01 49 92 63 01</strong>
        </p>
      </section>
      <section className="modal-section">
        <div className="row">
          <div className="col col-12">
            <button className="button-main" onClick={() => window.$crisp && window.$crisp.push(['do', 'chat:open'])}>Ouvrir le Tchat</button>
          </div>
        </div>
        <div className="row m-top">
          <div className="col col-12">
            <button className="button-secondary" onClick={closeModal}>Entrer un autre numéro d'accord</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CNAMSubsidyError;
