import {
  Field, Form, Formik,
} from 'formik';
import moment from 'moment';
import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import Input from '../../../components/Inputs/Material';
import { getSvgIcon, matchTransportIcons } from '../../../utils/icons';
import { displayPrice } from '../../../utils/converters';
import { openModal } from '../../ModalWrapper/actions';

const MissionOrderForm = ({ missionOrders }) => {
  const dispatch = useDispatch();
  const { approvers } = useSelector((state) => state.app.user);
  const { isConfirmingOption } = useSelector((state) => state.checkout);
  const [index, setIndex] = React.useState(0);
  const onSubmit = (values) => {
    dispatch(openModal('ApproversList', {
      approvers,
      existingOption: isConfirmingOption,
      missionOrderData: values.missionOrders,
      // Mission orders are always generated for the same travel, this must be changed if it is not the case anymore
      travelIDs: [missionOrders[0].travelID],
      withSecondary: true,
    }));
  };

  const goToNextMissionOrder = (errors, setFieldTouched, touched, setFieldValue, values) => {
    setFieldTouched(`missionOrders[${index}].purpose`);
    setFieldTouched(`missionOrders[${index}].location`);
    setFieldTouched(`missionOrders[${index}].startLocation`);
    setFieldTouched(`missionOrders[${index}].endLocation`);
    if (!errors.missionOrders?.[index]) {
      if (!touched.missionOrders?.[index + 1]) {
        setFieldValue(`missionOrders[${index + 1}].purpose`, values.missionOrders[index].purpose);
        setFieldValue(`missionOrders[${index + 1}].location`, values.missionOrders[index].location);
        setFieldValue(`missionOrders[${index + 1}].extraRemark`, values.missionOrders[index].extraRemark);
      }
      setIndex(index + 1);
    }
  };

  const initialValues = missionOrders.map(({ travelData, id }) => ({
    id,
    purpose: '',
    location: '',
    travelData,
    extraRemark: '',
    startLocation: '',
    endLocation: '',
  }));

  return (
    <div className="common-wrapper">
      <Formik
        onSubmit={onSubmit}
        initialValues={{ missionOrders: initialValues }}
        validationSchema={yup.object().shape({
          missionOrders: yup.array().of(yup.object().shape({
            purpose: yup.string().required(window.i18('PLEASE_FILL')),
            location: yup.string().required(window.i18('PLEASE_FILL')),
            startLocation: yup.string().required(window.i18('PLEASE_FILL')),
            endLocation: yup.string().required(window.i18('PLEASE_FILL')),
          })),
        })}
        validateOnMount
      >
        {({
          values, setFieldValue, errors, touched, setFieldTouched,
        }) => {
          const { travelData } = values.missionOrders[index];
          return (
            <Form>
              <div className="flex justify-between">
                <h2>{window.i18('MISSION_ORDER_FINALIZATION')}</h2>
                {missionOrders.length > 1 && (
                <div className="mission-order-prev-next">
                  <button type="button" className="mission-order-prev" aria-label={window.i18('PREVIOUS')} onClick={() => setIndex(index - 1)} disabled={index === 0} />
                  <button type="button" className="mission-order-next" aria-label={window.i18('NEXT')} onClick={() => goToNextMissionOrder(errors, setFieldTouched, touched, setFieldValue, values)} disabled={index === missionOrders.length - 1} />
                </div>
                )}
              </div>
              <div className="common-frame p-all">
                <h3 className="m-0">
                  {window.i18('MISSION_ORDER_OF')(`${missionOrders[index]?.pax.firstname} ${missionOrders[index]?.pax.lastname}`)}
                </h3>
                <div className="mission-order-wrapper">
                  <div className="m-top">
                    <Input name={`missionOrders[${index}].purpose`} id="purpose" error={errors.missionOrders?.[index]?.purpose} touched={touched.missionOrders?.[index]?.purpose} value={values.missionOrders?.[index]?.purpose} onChange={(value) => setFieldValue(`missionOrders[${index}]purpose`, value)} label={window.i18('MISSION_OBJECT')} />
                  </div>
                  <div className="m-top">
                    <Input name={`missionOrders[${index}].location`} id="location" error={errors.missionOrders?.[index]?.location} touched={touched.missionOrders?.[index]?.location} value={values.missionOrders?.[index]?.location} onChange={(value) => setFieldValue(`missionOrders[${index}]location`, value)} label={window.i18('MISSION_PLACE')} />
                  </div>
                  <div className="m-top">
                    <Input name={`missionOrders[${index}].extraRemark`} id="extraRemark" error={errors.missionOrders?.[index]?.extraRemark} touched={touched.missionOrders?.[index]?.extraRemark} value={values.missionOrders?.[index]?.extraRemark} onChange={(value) => setFieldValue(`missionOrders[${index}]extraRemark`, value)} label={window.i18('OTHER_REMARKS')} optional />
                  </div>
                  <div className="mission-order-sections">
                    <div className="flex">
                      <p className="mission-order-badge blue m-right">{window.i18('DEPARTURE')}</p>
                      <Field type="text" placeholder={window.i18('START_LOCATION')} name={`missionOrders[${index}].startLocation`} className={classNames('mission-order-field', { 'mission-order-field--error': touched.missionOrders?.[index]?.startLocation && errors.missionOrders?.[index]?.startLocation })} />
                      {touched.missionOrders?.[index]?.startLocation && errors.missionOrders?.[index]?.startLocation && (
                      <p className="error-message m-0 flex align-center m-left">{errors.missionOrders?.[index]?.startLocation}</p>
                      )}
                    </div>
                    {/* eslint-disable-next-line array-callback-return */}
                    {travelData?.map(({ transport, travel }, i) => {
                      if (transport) {
                        const travelAfterTransport = i === travelData.length - 1 ? null : travelData[i + 1].travel;
                        const travelBeforeTransport = i === 0 ? null : travelData[i - 1]?.travel;
                        // We display the date if there are no travels before this transport, or if the next travel's date is different from the previous travel's date
                        const displayDate = !travelBeforeTransport || (travelAfterTransport && moment(travelBeforeTransport.start).diff(moment(travelAfterTransport.start), 'days') !== 0);
                        return (
                          <React.Fragment key={i}>
                            {displayDate && (
                            <div className="flex">
                              <p className="mission-order-badge mission-order-badge--negative">
                                {window.i18('DATE')}
                                :
                                {' '}
                                {moment(travelAfterTransport.start).format('L')}
                              </p>

                            </div>
                            )}
                            <div className="flex m-left p-left align-center">
                              <p className="m-0 p-right">
                                {window.i18('LINK_TRANSPORT')}
                                :
                              </p>
                              <Field type="text" placeholder={window.i18('TRANSPORT_FIELD')} name={`missionOrders[${index}].travelData[${i}].transport.value`} className="mission-order-field" />
                            </div>
                          </React.Fragment>
                        );
                      }
                      if (travel) {
                        const {
                          start, end, type, routes, amount, compliant,
                        } = travel;
                        return (
                          <React.Fragment key={i}>
                            <div className="mission-order-ticket">
                              <div className="mission-order-ticket__travel">
                                {getSvgIcon(matchTransportIcons(type), 'transport/svg')}
                                {(type === 'rail' || type === 'flight') && (
                                <div>
                                  <div className="mission-order-ticket__step">
                                    <p className="mission-order-ticket__time">{moment(start).format('HH:mm')}</p>
                                    <p className="mission-order-ticket__route">{routes[0]}</p>
                                  </div>
                                  <div className="mission-order-ticket__step">
                                    <p className="mission-order-ticket__time">{moment(end).format('HH:mm')}</p>
                                    <p className="mission-order-ticket__route">{routes[routes.length - 1]}</p>
                                  </div>
                                </div>
                                )}
                                {type === 'hotel' && (
                                <div>
                                  <p className="mission-order-ticket__route">{routes[0]}</p>
                                  <p className="mission-order-ticket__duration">
                                    {moment(end).diff(start, 'days')}
                                    {' '}
                                    {moment(end).diff(start, 'days') > 1 ? window.i18('NIGHTS') : window.i18('NIGHT')}
                                    {' '}
                                    {window.i18('FROM_THE')}
                                    {' '}
                                    {moment(start).format('L')}
                                    {' '}
                                    {window.i18('TO_THE')}
                                    {' '}
                                    {moment(end).format('L')}
                                  </p>
                                </div>
                                )}
                                {type === 'car_rental' && (
                                <div>
                                  <p className="mission-order-ticket__route">{routes[0]}</p>
                                  <p className="mission-order-ticket__duration">
                                    {moment(end).diff(start, 'days')}
                                    {' '}
                                    {moment(end).diff(start, 'days') > 1 ? window.i18('DAYS') : window.i18('DAY')}
                                    {' '}
                                    {window.i18('FROM_THE')}
                                    {' '}
                                    {moment(start).format('L')}
                                    {' '}
                                    {window.i18('TO_THE')}
                                    {' '}
                                    {moment(end).format('L')}
                                  </p>
                                </div>
                                )}
                              </div>
                              <div className="mission-order-ticket__price">
                                <p>{displayPrice(amount / 100)}</p>
                                {compliant !== null && <p className={classNames(-'m-top', 'offer-badge', { 'offer-badge--green': compliant }, { 'offer-badge--red': !compliant })}>{compliant ? window.i18('CONFORM') : window.i18('NOT_CONFORM')}</p>}
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      }
                    })}
                    <div className="flex">
                      <p className="mission-order-badge blue m-right">{window.i18('ARRIVAL')}</p>
                      <Field type="text" placeholder={window.i18('END_LOCATION')} name={`missionOrders[${index}].endLocation`} className={classNames('mission-order-field', { 'mission-order-field--error': touched.missionOrders?.[index]?.endLocation && errors.missionOrders?.[index]?.endLocation })} />
                      {touched.missionOrders?.[index]?.endLocation && errors.missionOrders?.[index]?.endLocation && (
                      <p className="error-message m-0 flex align-center m-left">{errors.missionOrders?.[index]?.endLocation}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <div className="flex">
                  {index > 0 && (
                  <button type="button" className="m-top button-main" onClick={() => setIndex(index - 1)}>{window.i18('PREVIOUS')}</button>
                  )}
                  {index < missionOrders.length - 1 && (
                  <button type="button" className="m-top button-main m-left" onClick={() => goToNextMissionOrder(errors, setFieldTouched, touched, setFieldValue, values)}>{window.i18('NEXT')}</button>
                  )}
                  {index === missionOrders.length - 1 && (
                  <button type="submit" className="m-top button-main m-left">{window.i18('SEND_APPROVAL_REQUEST')}</button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default MissionOrderForm;
