import React from 'react';

export default class InvertFromTo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      animating: false,
    };
  }

  handleClick = () => {
    this.setState({
      animating: true,
    });

    this.props.onClick();

    setTimeout(() => {
      this.setState({
        animating: false,
      });
    }, 400);
  };

  render() {
    let classes = 'common-circle-button rt-reverse';
    if (this.state.animating) classes += ' clicked';

    return (
      <div className="form-group rt-reverse-wrapper">
        <button type="button" aria-label="Inverser locations" className={classes} onClick={this.handleClick} />
      </div>
    );
  }
}
