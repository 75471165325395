import React from 'react';
import axios from 'axios';
import { Field } from 'formik';
import classNames from 'classnames';
import API from '../../utils/api';

import AutocompleteItems from '../LocationInput/AutoCompleteItems';
import { isCNAM } from '../../utils/functions';

let source = axios.CancelToken.source();

class MaterialAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: props.value || '',
      places: [],
      searching: false,
      isFocused: false,
      isTyping: false,
      isExpanded: this.props.value,
    };
    this.typingTimeout = null;
    this.request = null;
  }

  componentDidUpdate(prevProps) {
    // If a location is passed down by props from the parent, we update the search field
    if (prevProps.location !== this.props.location) {
      this.setState({
        search: this.props.value || '',
      });
    }
  }

    handleChange = (e) => {
      // We wait for the user to stop typing before sending the GET /places call
      const val = e.target.value;

      this.setState({
        search: val,
      });

      if (this.state.isTyping) {
        clearTimeout(this.typingTimeout);
      } else {
        this.setState({ isTyping: true });
      }

      if (val.length) {
        this.typingTimeout = setTimeout(() => {
          this.getPlaces(val);
          this.setState({ isTyping: false });
        }, 700);
      } else {
        // Clears the location if the user clears the input
        // Calls the redux method with an empty object to reset the departing place
        this.props.onClick({});

        if (this.props.checkForm) this.props.checkForm();

        this.setState({ isTyping: false });
      }

      if (this.props.setTouched) this.props.setTouched();
    }

    getPlaces = (q = this.state.search) => {
      this.setState({
        searching: true,
      });
      const params = { q };
      if (this.props.isDirect) {
        params.types = 'city,airport,station'; // search only stations and airports
      }
      if (isCNAM() && this.props.subsidy) {
        params.subsidy = this.props.subsidy;
      }
      const promise = API.get('/places', {
        cancelToken: source.token,
        params,
      })
        .then((response) => {
          this.setState({
            places: response.data.result,
            searching: false,
          });
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;

          this.setState({
            places: [],
            searching: false,
          });
        });

      this.request = promise;
    }

    cancelRequest = () => {
      source.cancel(source.token);
      source = axios.CancelToken.source();

      this.request = null;
    }

    handleClick = (place) => {
      this.props.onClick(place);

      if (this.props.checkForm) this.props.checkForm();

      this.setState({
        search: place.description,
      });
    }

    handleBlur = () => {
      if (!this.state.search.length) {
        this.setState({
          isExpanded: false,
        });
      }
      this.setState({
        isFocused: false,
      });
    }

    hasErrored = () => this.props.touched && !this.props.isFocused && this.props.error

    render() {
      const {
        name, label, placeholder, error, touched, disabled, optional,
      } = this.props;
      const { isExpanded } = this.state;
      const classes = classNames({
        'material-input': true,
        'material-input--focused': isExpanded,
        'material-input--error': (touched && error),
        'material-input--disabled': disabled,
        'material-input--optional': optional,
      });
      return (
        <div
          className={classes}
        >
          <label className="material-input__label" htmlFor={name}>
            {label}
          </label>
          <Field
            className="material-input__input"
            type="text"
            id={name}
            placeholder={placeholder}
            name={name}
            onChange={this.handleChange}
            value={this.state.search}
            onFocus={() => this.setState({ isFocused: true, isExpanded: true })}
            onBlur={this.handleBlur}
            disabled={disabled}
            autoComplete="nope"
          />
          {touched && error && <p className="material-input__error">{error.description}</p>}
          <AutocompleteItems
            closed={
              !this.state.isFocused
                        || !this.state.search.length
                        || this.state.search === this.props.location
            }
            onClick={this.handleClick}
            loading={this.state.searching || this.state.isTyping}
            items={this.state.places}
          />
        </div>
      );
    }
}

export default MaterialAddress;
