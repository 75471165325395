import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
// Components
import { Redirect } from 'react-router-dom';
import MainTransport from './MainTransport';
import Timeline from './Timeline';
import Loader from '../../components/Loader';
import Passengers from './Passengers';
import { SEARCH_FORM_PATH } from '../App/constants';
import { isCNAM } from '../../utils/functions';
import TripSearch from '../TripSearch';

const Title = ({
  currentTrip, returnValidated, from, to,
}) => (
  <h1>
    {currentTrip === 'returnTrip' && returnValidated && `${window.i18('OUTWARD')} `}
    {currentTrip === 'returnTrip' ? window.i18('RETURN') : window.i18('OUTWARD')}
    {' '}
    :
    {' '}
    {currentTrip === 'returnTrip' && !returnValidated ? to : from}
    {' '}
    -
    {' '}
    {currentTrip === 'returnTrip' && !returnValidated ? from : to}
  </h1>
);

export default function Results() {
  const isMobileDevice = useSelector((state) => state.app.isMobileDevice);
  const { results } = useSelector((state) => state);
  const {
    currentTrip, search, loading, isRoundTrip,
  } = results;
  const outwardValidated = useSelector((state) => state.results.outwardTrip.validated);
  const returnValidated = useSelector((state) => state.results.returnTrip.validated);
  const [displaySearch, setDisplaySearch] = useState(false);

  if ((!loading && currentTrip === 'outwardTrip' && results[currentTrip].proposals.length === 0)) {
    return <Redirect to={SEARCH_FORM_PATH} />;
  }

  if (loading) {
    const outwardDestination = search?.to?.city || search?.to?.name;
    const returnDestination = search?.from?.city || search?.from?.name;
    return (
      <Loader
        sentences={[]}
        returnTrip={outwardValidated}
        destination={outwardValidated ? returnDestination : outwardDestination}
      />
    );
  }
  return (
    <div className={classNames('results-container', { desktop: !isMobileDevice })}>
      {!isCNAM() && (
        <div
          className={`search-block offers__search ${
            displaySearch ? 'show' : ''
          }`}
        >
          <TripSearch result />
        </div>
      )}
      <div className="row">
        <Title currentTrip={currentTrip} returnValidated={returnValidated} from={search?.from?.name} to={search?.to?.name} />
        {!isCNAM() && !isMobileDevice && (
        <div className="results-search-btn">
          <button
            className="button-secondary"
            onClick={() => setDisplaySearch(!displaySearch)}
          >
            {window.i18('EDIT_SEARCH')}
          </button>
        </div>
        )}
      </div>
      {(!outwardValidated || (isRoundTrip && !returnValidated)) ? <MainTransport /> : (
        <>
          <Timeline />
          <Passengers />
        </>
      )}
    </div>
  );
}
