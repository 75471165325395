import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
// Components
import SubsetHotel from '../../components/CartSubset/SubsetHotel';
import CartSubset from '../../components/CartSubset';
// Misc
import {
  getCartItemPrice, isCartItemDisabled, getSubsetPrice, getCartSelectedTickets,
} from './selectors';
import { getSvgIcon } from '../../utils/icons';
import { searchCarRental, searchHotel, searchTreep } from './actions';
import { isCNAM } from '../../utils/functions';
import CheckoutButtons from '../Checkout/CheckoutButtons';

export default function CartItems({
  cart, toggleCartSubset, refreshTreep, promptDeleteTreepFromCart, isMobileDevice, user, amount, checkoutMissionOrder, checkout, approval, confirm,
}) {
  const amountLoading = cart.cartAmount == null || cart.cartAmount.isFetching;
  const { content } = cart;
  let hasLinkedSetRef = false;
  const dispatch = useDispatch();
  const travels = {};

  content.forEach((item) => {
    if (!travels[item.travelID]) {
      travels[item.travelID] = [];
    }
    travels[item.travelID].push(item);
  });

  return Object.keys(travels).map((travel, i) => {
    const missionOrders = amount?.missionOrders?.filter((missionOrder) => missionOrder.travelID === travel);
    const approvers = user.approvers ? [...user.approvers] : [];
    const tickets = getCartSelectedTickets(travels?.[travel]);

    const totalPrice = travels[travel].map((travelContent) => travelContent.subsets.filter((subset) => subset.bookable)?.map((subset) => getSubsetPrice(subset)).reduce((prev, curr) => prev + curr)).reduce((prev, curr) => prev + curr);
    return (
      <React.Fragment key={`cart-item-${i}`}>
        <h2>
          {window.i18('TRIP')}
          {' '}
          {i + 1}
          :
          {' '}
          {travels?.[travel]?.[0]?.travelName}
        </h2>
        <div className="cart-travel">
          {travels[travel].map((travelContent) => {
          // If the treep cannot be booked because it's too late
            const isDisabled = isCartItemDisabled(travelContent);

            const subsets = travelContent.subsets.filter((subset) => subset.bookable).map((subset) => {
              let linkedSubset = null;
              if (subset.linked_set_ref != null) {
                hasLinkedSetRef = true;
                content.forEach((treep) => {
                  treep.subsets.forEach((s) => {
                    if (s.set_ref === subset.linked_set_ref) {
                      linkedSubset = s;
                    }
                  });
                });
              }
              if (subset.type === 'hotel') {
                return (
                  <SubsetHotel
                    hotelName={travelContent.destination.name}
                    departure={travelContent.departure}
                    arrival={travelContent.arrival}
                    nbNight={travelContent.duration}
                    adress={travelContent.destination.formatted_address}
                    fare_name={subset.offers}
                    price={getSubsetPrice(subset)}
                    linked_subsets_price={(linkedSubset != null) ? (getSubsetPrice(subset) + getSubsetPrice(linkedSubset)).toFixed(2) : null}
                    tickets={subset.tickets}
                    toggleCartSubset={() => {
                      toggleCartSubset(subset.set_ref);
                      if (subset.linked_set_ref != null) {
                        toggleCartSubset(subset.linked_set_ref);
                      }
                    }}
                    active={subset.selected}
                    type={subset.type}
                    disabled={isDisabled}
                    transportMode={subset.transport_mode}
                    key={subset.set_ref}
                    carrier={subset.carrier}
                    isMobileDevice={isMobileDevice}
                  />
                );
              }
              return (
                <CartSubset
                  origin={subset.origin.name}
                  destination={subset.destination.name}
                  departure={subset.departure}
                  arrival={subset.arrival}
                  price={getSubsetPrice(subset)}
                  linked_subsets_price={(linkedSubset != null) ? (getSubsetPrice(subset) + getSubsetPrice(linkedSubset)).toFixed(2) : null}
                  tickets={subset.tickets}
                  toggleCartSubset={() => {
                    toggleCartSubset(subset.set_ref);
                    if (subset.linked_set_ref != null) {
                      toggleCartSubset(subset.linked_set_ref);
                    }
                  }}
                  active={subset.selected}
                  type={subset.type}
                  disabled={isDisabled}
                  transportMode={subset.transport_mode}
                  key={subset.set_ref}
                  carrier={subset.carrier}
                  isMobileDevice={isMobileDevice}
                />
              );
            });

            const expirationCopy = isDisabled ? window.i18('EXPIRED') : `${window.i18('GARANTEED_UP_TO')} ${moment(travelContent.ticket_time_limit).format(`dddd Do MMMM [${window.i18('AT')}] HH[h]mm`)}`;
            const travelerName = travelContent.type === 'hotel' ? window.i18('TRAVELER') : window.i18('PASSENGER');

            const travelTitle = (({ type, arrival, departure }) => {
              switch (type) {
                case 'hotel':
                  return `${window.i18('STAY_FROM')} ${moment(departure).format('dddd Do MMMM YYYY')} ${window.i18('TO_THE')} ${moment(arrival).format('dddd Do MMMM YYYY')}`;
                case 'car_rental':
                  return `${window.i18('LOCATION_FROM')} ${moment(departure).format('dddd Do MMMM YYYY')} ${window.i18('TO_THE')} ${moment(arrival).format('dddd Do MMMM YYYY')}`;
                default:
                  return `${window.i18('TRAVEL_FROM')} ${moment(departure).format('dddd Do MMMM YYYY')}`;
              }
            })(travelContent);

            const isInternational = travelContent.subsets.some((subset) => subset.tickets?.some((ticket) => ticket.tag?.includes('international')));

            return (

              <div className="common-frame cart-item" key={travelContent.uid}>
                <div className="cart-item-header">
                  <div className="item-header-row">
                    <div className="header-block header-left-block">
                      <strong className="common-sub-title">
                        {travelTitle}
                      </strong>
                    </div>
                    <div className="header-block header-right-block">
                      {isDisabled && (<a className="refresh-cta" onClick={() => refreshTreep(travelContent)}>{getSvgIcon('refresh', 'ui/')}</a>)}
                      <a className="delete-cta" onClick={() => promptDeleteTreepFromCart(travelContent.uid)}>{getSvgIcon('dustbin', 'ui/')}</a>
                    </div>
                  </div>
                  <div className="item-header-row">
                    <div className="header-block header-left-block">
                      <span className="disponibilities">
                        {window.i18('PRICE_AND_AVAILABILITY')}
                        {' '}
&nbsp;
                        <strong>{expirationCopy}</strong>
                      </span>
                    </div>
                    <div className="header-block header-right-block">
                      <span className="nb-travelers">{travelContent.travelers && travelContent.travelers.length > 1 ? `${travelContent.travelers && travelContent.travelers.length} ${travelerName}s` : `1 ${travelerName}`}</span>
                    </div>
                  </div>
                </div>
                <div>{subsets}</div>
                {isInternational && (
                <div className="cart-row">
                  <p className="cart-information">
                    <span>
                      {getSvgIcon('information', 'ui/')}
                    </span>
                    {window.i18('VISIT')}
                    {' '}
                    <a className="button-link" rel="noopener" target="blank" href="http://diplomatie.gouv.fr">diplomatie.gouv.fr</a>
                    {' '}
                    {window.i18('FOR_INFO_ON_TRIP')}
                  </p>
                </div>
                )}
                <strong className="cart-item-price">
                  {(hasLinkedSetRef) // Display tooltip only if this subset is linked to another one (i.e. it can only be booked with this other subset)
                    ? (
                      <span
                        className="infobulle"
                        aria-label={window.i18('PRICE_CAN_CHANGE')}
                      >
                        {getCartItemPrice(travelContent).toFixed(2)}
                        €
                      </span>
                    ) : (
                      <span>
                        {getCartItemPrice(travelContent).toFixed(2)}
                        €
                        {' '}
                      </span>
                    )}
                </strong>
              </div>
            );
          })}
          {
          isCNAM()
            ? null
            : (
              <div>
                <div className="cart-add-service">
                  <button onClick={() => dispatch(searchTreep(travels[travel][0]))}>
                    {getSvgIcon('add', 'ui/')}
                    {window.i18('BOOK_A_TRIP')}
                  </button>
                  {(user && user.company && user.company.hotel_enabled)
                  && (
                  <button onClick={() => dispatch(searchHotel(travels[travel][0]))}>
                    {getSvgIcon('add', 'ui/')}
                    {window.i18('BOOK_A_ROOM')}
                  </button>
                  )}
                  {(user && user.company && user.company.carrental_enabled)
                  && (
                  <button onClick={() => dispatch(searchCarRental(travels[travel][0]))}>
                    {getSvgIcon('add', 'ui/')}
                    {window.i18('RENT_A_CAR')}
                  </button>
                  )}
                </div>
                <div className="m-top flex justify-between">
                  <p className="cart-travel-price">
                    Total du voyage:
                    {' '}
                    {totalPrice.toFixed(2)}
                    €
                  </p>
                  {amountLoading || tickets.length === 0
                    ? <a className="button-main" disabled>...</a>
                    : <CheckoutButtons amount={amount} checkout={() => checkout(tickets)} approval={() => approval(tickets)} confirm={() => confirm(tickets)} cartItem />}
                </div>
              </div>
            )
        }
        </div>
        {missionOrders && (
        <div className="flex justify-end m-top align-center">
          { (
            approvers.length > 0
          )
            ? (
              <div>
                <span>{window.i18('MISSION_ORDER_WILL_BE_GENERATED')}</span>
                <button button className="button-main m-left" onClick={() => checkoutMissionOrder(missionOrders)}>{window.i18('CREATE_MISSION_ORDER')}</button>
              </div>
            )
            : <span>{window.i18('CONFIG_ERROR_NO_APPROVERS')}</span>}
        </div>
        )}
      </React.Fragment>
    );
  });
}
