import React from 'react';

import { connect } from 'react-redux';
import { resetPassword } from '../../App/actions';
import ResetPasswordForm from '../../../components/ResetPasswordForm';
import Leaf from '../../../components/GreenScore/Leaf';
import { isCNAM } from '../../../utils/functions';

class ResetPasswordModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      submitting: false,
      error: null,
    };
  }

  componentDidMount() {
    if (this.props.username) {
      this.handleSubmit({ username: this.props.username });
    }
  }

  handleSubmit = (values) => {
    this.setState({
      submitting: true,
    });
    this.props.resetPassword(values).then(() => {
      this.setState({
        success: true,
        submitting: false,
      });
    }).catch((error) => {
      this.setState({
        success: false,
        submitting: false,
        error: error.response?.data?.errorMessage || window.i18('ERROR_GENERIC'),
      });
    });
  }

  render() {
    if (this.state.submitting) {
      return (
        <div className="modal-inner">
          <p>{window.i18('SENDING')}</p>
        </div>
      );
    }
    if (this.state.success) {
      return (
        <div className="modal-inner">
          <section className="modal-header">
            <h3 className="common-sub-title">
              {window.i18('AN_EMAIL_HAS_BEEN_SENT')}
              ,
              <strong>{window.i18('SEE_YOU')}</strong>
            </h3>
          </section>
          <section className="modal-section big-leaf">
            <Leaf completion={1} />
          </section>
          <a className="button-main" onClick={() => this.props.openModal('Login')}>{window.i18('LOG_IN')}</a>
        </div>
      );
    }

    return (
      <div className="user-modal">
        <div className="modal-with-tagline">
          <header className="tagline">
            <h1 className="common-title">{window.i18('FORGOTTEN_PASSWORD')}</h1>
          </header>
        </div>
        <div className="modal-inner">
          <div className="user-form">
            {this.state.error && (
            <p className="error-message">{this.state.error}</p>
            )}
            <ResetPasswordForm handleSubmit={this.handleSubmit} />
            {!isCNAM() && (
            <div className="button-group">
              <a className="connexion" onClick={() => this.props.openModal('Login', this.props.options)}>{window.i18('LOG_IN')}</a>
              <a className="create-account" onClick={() => this.props.openModal('Signup', this.props.options)}>{window.i18('CREATE_ACCOUNT')}</a>
            </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.modal.options,
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (username) => dispatch(resetPassword(username)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordModal);
