import React from 'react';
import PropTypes from 'prop-types';
import { Map, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

function PricesMap({
  lat, lon, markers, handleClick, selectedMarker, radius,
}) {
  document.addEventListener('click', (event) => {
    if (event.target.classList.contains('marker-button')) {
      handleClick(event.target.id);
    }
  });

  const center = [lat, lon];
  let zoom = 9;
  if (radius < 2) {
    zoom = 15;
  } else if (radius < 5) {
    zoom = 14;
  } else if (radius < 15) {
    zoom = 12;
  } else if (radius < 30) {
    zoom = 11;
  } else if (!radius) {
    zoom = 11;
  }

  return (
    <div className="prices-map">
      <Map center={center} zoom={zoom}>
        <TileLayer
          attribution='&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup iconCreateFunction={(cluster) => {
          const prices = cluster.getAllChildMarkers().map((marker) => marker.options.attribution);
          const minPrice = Math.min(...prices);
          const childCount = cluster.getChildCount();

          let c = ' marker-cluster-';
          if (childCount < 10) {
            c += 'small';
          } else if (childCount < 100) {
            c += 'medium';
          } else {
            c += 'large';
          }
          return new L.DivIcon({ html: `<div><span><b>${minPrice}€+<b></span></div>`, className: `marker-cluster ${c}`, iconSize: new L.Point(40, 40) });
        }}
        >
          {markers.map((marker) => {
            const icon = L.divIcon({
              className: 'prices-map__marker',
              html: `<button id="${marker.id}" class="marker-button ${marker.id === selectedMarker ? 'marker-button--selected' : ''}">
                ${marker.price}€
              </button>`,
            });
            return <Marker position={[marker.lat, marker.lon]} icon={icon} attribution={marker.price} />;
          })}
        </MarkerClusterGroup>
        <Marker
          position={center}
          icon={L.divIcon({
            className: 'prices-map__center',
            html: '<span class="prices-map__center-inner"></span>',
          })}
        />
      </Map>
    </div>
  );
}

PricesMap.propTypes = {
  lat: PropTypes.number,
  lon: PropTypes.number,
  markers: PropTypes.arrayOf({
    price: PropTypes.number.isRequired,
    lat: PropTypes.number.isRequired,
    lon: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
  }),
};

PricesMap.defaultProps = {
  lat: 1,
  lon: 2,
  markers: [],
};

export default PricesMap;
