import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import className from 'classnames';
// Functions
import {
  formatStatus, getTimeLeft, shortUserName, formatTransportModeName,
} from '../../utils/converters';
import { getSvgIcon, matchTransportIcons } from '../../utils/icons';
import { isMobileDevice } from '../../utils/functions';

const propTypes = {
  ticket: PropTypes.shape({
    status: PropTypes.string.isRequired,

  }).isRequired,
};

function TicketItem({
  ticket,
  isPending,
  withCheckbox,
  isChecked,
  toggleCheck,
  withCompliance,
  paddingLeft,
}) {
  const {
    type,
    departure,
    arrival,
    from,
    to,
    travelers,
    price,
    cabin,
    summaries,
    carrier,
    compliant,
  } = ticket;
  const status = formatStatus(ticket.status);
  const transportIcon = matchTransportIcons(type);
  const ticketTimeLimit = ticket.ticket_time_limit;
  const fare = ticket.fare_category;
  const mainApprover = ticket.main_approver;
  const secondaryApprover = ticket.secondary_approver;

  const timeLeft = getTimeLeft(ticketTimeLimit);

  if (isMobileDevice()) {
    return (
      <div key={ticket.uid} className="row ticket-card">
        <div className="row bg-ticket-item user-tickets-mb-header">
          <div className="col col-sm-6 user-tickets-mb-checkbox">
            <div className="user-tickets-mb-header-item__checkbox row">
              <p className="col-sm-10">
                {formatTransportModeName(
                  ticket.type,
                )}
              </p>
            </div>
          </div>
          <div className="col col-sm-6 ticket-info">
            <p className="sub-title">
              <span>{from}</span>
              {from !== to && (
                <>
                  <br />
                  <span>{to}</span>
                </>
              )}
            </p>
          </div>
        </div>
        <div className="row user-tickets-mb">
          <div className="col col-sm-6 pl">
            <p>{window.i18('DATES').toUpperCase()}</p>
          </div>
          <div className="col col-sm-6 ticket-info">
            <p>
              <span>{moment(departure).format('DD/MM/YYYY')}</span>
              {moment(arrival).isAfter(moment(departure), 'day') && (
                <>
                  <span>-</span>
                  <span>{moment(arrival).format('DD/MM/YYYY')}</span>
                </>
              )}
            </p>
          </div>
        </div>
        <div className="row user-tickets-mb">
          <div className="col col-sm-6 pl">
            <p>{window.i18('SCHEDULES').toUpperCase()}</p>
          </div>
          <div className="col col-sm-6 ticket-info">
            <p>
              <span>{moment(departure).format('LT')}</span>
              <span> - </span>
              <span>{moment(arrival).format('LT')}</span>
            </p>
          </div>
        </div>
        <div className="row user-tickets-mb">
          <div className="col col-sm-6 pl">
            <p>{window.i18('PASSENGER_S').toUpperCase()}</p>
          </div>
          <div className="col col-sm-6 ticket-info">
            <p>
              {travelers && travelers.map((traveler) => (
                <ul key={traveler.uid}>
                  <li>
                    {' '}
                    {traveler.firstname}
                    {' '}
                    {traveler.lastname}
                  </li>
                </ul>
              ))}
            </p>
          </div>
        </div>
        <div className="row user-tickets-mb">
          <div className="col col-sm-6 ticket-title">
            <p>{window.i18('CONDITIONS').toUpperCase()}</p>
          </div>
          <div className="col col-sm-6 ticket-info">
            <p>
              <span>{fare}</span>
              <br />
              <span>{cabin}</span>
            </p>
          </div>
        </div>
        <div className="row user-tickets-mb">
          <div className="col col-sm-6 ticket-title">
            <p>{window.i18('PNR_FILE').toUpperCase()}</p>
          </div>
          <div className="col col-sm-6 ticket-info">
            <p>
              {ticket.pnr}
              {ticket.pnr && ticket.reference && <br />}
              {ticket.reference}
            </p>
          </div>
        </div>
        <div className="row user-tickets-mb">
          <div className="col col-sm-6 ticket-title">
            <p>{window.i18('STATUS').toUpperCase()}</p>
          </div>
          <div className="col col-sm-6 ticket-info">
            <div className={className('ticket-info__status')}>
              <div className={className('ticket-status', status.color)}>
                {status.icon
                  ? getSvgIcon(status.icon, 'ui/')
                  : <span className="sign">{status.sign}</span>}
                {status.name}
              </div>
            </div>
          </div>
        </div>
        <div className="row user-tickets-mb">
          <div className="col col-sm-6 ticket-title">
            <p>{window.i18('APPROVER_S').toUpperCase()}</p>
          </div>
          <div className="col col-sm-6 ticket-info">
            <div className={className('ticket-info__status')}>
              <p>
                {mainApprover && (
                  <>
                    {shortUserName(mainApprover)}
                  </>
                )}
                {secondaryApprover && (
                  <>
                    <br />
                    (
                    {shortUserName(secondaryApprover)}
                    )
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="row user-tickets-mb">
          <div className="col col-sm-12 ticket-info">
            {summaries && summaries.length > 0 && (
              <div className="recap-image">
                <a
                  href={summaries[0]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {window.i18('SEE_TICKET_RECAP')}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <tr
      key={ticket.uid}
      className={className('user-tickets-item',
        { 'ticket-error': ticket.status === 'error' })}
      style={{ display: 'table' }}
    >
      {paddingLeft && (
        <td className="user-tickets-item__checkbox" />
      )}
      <td
        data-label="Statut"
        className={className('user-tickets-item__status')}
      >
        <div className={className('ticket-status', status.color)}>
          {status.icon
            ? getSvgIcon(status.icon, 'ui/')
            : <span className="sign">{status.sign}</span>}
          {status.name}
        </div>
      </td>
      <td data-label="Type" className="user-tickets-item__carrier">
        {getSvgIcon(transportIcon, 'transport/svg')}
        <br />
        <span>{carrier}</span>
      </td>
      <td data-label="Dates" className="user-tickets-item__date">
        <span>{moment(departure).format('DD/MM/YYYY')}</span>
        {moment(arrival).isAfter(moment(departure), 'day') && (
          <>
            <br />
            <span>-</span>
            <br />
            <span>{moment(arrival).format('DD/MM/YYYY')}</span>
          </>
        )}
      </td>
      <td data-label="Lieux" className="user-tickets-item__place">
        <span>{from}</span>
        {from !== to && (
          <>
            <br />
            <span>-</span>
            <br />
            <span>{to}</span>
          </>
        )}
      </td>
      <td data-label="Horaires" className="user-tickets-item__time">
        <span>{moment(departure).format('LT')}</span>
        <br />
        <span>-</span>
        <br />
        <span>{moment(arrival).format('LT')}</span>
      </td>
      <td data-label="Passagers(s)" className="user-tickets-item__travelers">
        {travelers && travelers.map((traveler) => (
          <ul key={traveler.uid}>
            <li>
              {' '}
              {traveler.firstname}
              {' '}
              {traveler.lastname}
            </li>
          </ul>
        ))}
      </td>
      <td data-label="Prix" className="user-tickets-item__price">
        {price.toFixed(2)}
        €
      </td>
      <td data-label="Condition" className="user-tickets-item__offer">
        <span>{fare}</span>
        <br />
        <span>{cabin}</span>
      </td>
      <td data-label="PNR/ Dossier" className="user-tickets-item__references">
        {ticket.pnr}
        {ticket.pnr && ticket.reference && <br />}
        {ticket.reference}
      </td>
      <td data-label="Approbateur" className="user-tickets-item__approver">
        {mainApprover && (
          <>
            {shortUserName(mainApprover)}
          </>
        )}
        {secondaryApprover && (
          <>
            <br />
            (
            {shortUserName(secondaryApprover)}
            )
          </>
        )}
      </td>
      {isPending ? (
        <td data-label="Délais" className="user-tickets-item__approve-delay">
          {timeLeft && (
          <span>
            {window.i18('ONLY')}
            {' '}
            <strong>{timeLeft}</strong>
            {' '}
            {window.i18('TO_APPROVE')}
          </span>
          )}
        </td>
      )
        : (
          <td data-label="Billets" className="user-tickets-item__recap">
            {summaries && summaries.length > 0 && (
              <div className="recap-image">
                <a
                  href={summaries[0]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getSvgIcon('recap', 'ui/')}
                </a>
              </div>
            )}
          </td>
        )}
      {withCompliance && compliant !== null && (
        <td className="user-tickets-item__compliance">
          <div className={className('compliance-circle', {
            'compliance-circle--red': !compliant,
            'compliance-circle--green': compliant,
          })}
          />
        </td>
      )}
    </tr>

  );
}

TicketItem.protoTypes = propTypes;

export default TicketItem;
