import React from 'react';

function Sorting({ sorting, setSorting, isMobileDevice }) {
  if (isMobileDevice) {
    return (
      <>
        <hr />
        <p>{window.i18('SORT')}</p>
        <select className="hotel-results-filter__btn active" defaultValue={sorting} onChange={(e) => setSorting(e.target.value)}>
          <option value="BY_PRICE">{window.i18('PRICE')}</option>
          <option value="BY_DISTANCE">{window.i18('DISTANCE')}</option>
        </select>
      </>
    );
  }
  return (
    <div className="flex align-center">
      <h3 className="m-right">
        {window.i18('SORTING')}
        :
      </h3>
      <select className="hotel-results-filter__btn active" defaultValue={sorting} onChange={(e) => setSorting(e.target.value)}>
        <option value="BY_PRICE">{window.i18('PRICE')}</option>
        <option value="BY_DISTANCE">{window.i18('DISTANCE')}</option>
      </select>
    </div>
  );
}

export default Sorting;
