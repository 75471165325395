import React from 'react';
import { levelToColor } from '../../utils/converters';
import { getSvgIcon } from '../../utils/icons';

export default function TransportComparisons({ stats }) {
  let emptyCount = 0;
  if (!stats?.[0]?.rail?.value) emptyCount += 1;
  if (!stats?.[0]?.car?.value) emptyCount += 1;
  if (!stats?.[0]?.flight?.value) emptyCount += 1;

  if (emptyCount === 2) {
    return null;
  }

  return (
    <div className="transport-comparisons__container">
      {stats?.map(({
        title, rail, car, flight, name,
      }) => {
        let transports = [];
        if (rail) transports.push({ ...rail, icon: 'tgv' });
        if (car) transports.push({ ...car, icon: 'car' });
        if (flight) transports.push({ ...flight, icon: 'airplane' });

        transports = transports.sort((a, b) => {
          if (!a.value || !b.value) {
            return 1;
          }
          return a.value - b.value;
        });

        return (
          <div className="transport-comparisons" key={title}>
            <h3 className="transport-comparisons__title">
              {title}
            </h3>
            {tooltip[name] && (
            <i className="transport-comparisons__icon common-tooltip__parent">
              {getSvgIcon('information', 'ui')}
              <div className="common-tooltip common-tooltip--large">
                <div className="common-tooltip__content">
                  {tooltip[name]()}
                </div>
              </div>

            </i>
            )}
            <div className="row">
              {transports.map((transport) => (transport.value ? (
                <div key={transport.value} className={`col col-${12 / (3 - emptyCount)}`}>
                  {getSvgIcon(transport.icon, 'transport/svg/')}
                  <p className="transport-comparisons__value" style={{ color: levelToColor(transport?.level) }}>{transport?.text}</p>
                </div>
              ) : null))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

const tooltip = {
  duration: () => (
    <div>
      {window.i18('TOTAL_DURATION_CONTAINS')}
      <ul>
        <li>{window.i18('TOTAL_DURATION_1')}</li>
        <li>{window.i18('TOTAL_DURATION_2')}</li>
        <li>{window.i18('TOTAL_DURATION_3')}</li>
        <li>{window.i18('TOTAL_DURATION_4')}</li>
      </ul>
    </div>
  ),
  price: () => (
    <p>{window.i18('PRICE_CALCULATION')}</p>
  ),
  co2: () => (
    <div>
      <p>{window.i18('CO2_PODIUM_TOOLTIP_1')}</p>
      <ul>
        <li><p>{window.i18('CO2_PODIUM_TOOLTIP_2')}</p></li>
        <li><p>{window.i18('CO2_PODIUM_TOOLTIP_3')}</p></li>
        <li><p>{window.i18('CO2_PODIUM_TOOLTIP_4')}</p></li>
      </ul>
      <p className="m-top">{window.i18('CO2_PODIUM_TOOLTIP_5')}</p>
    </div>
  ),
};
