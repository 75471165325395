import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { Dedupe as DedupeIntegration } from '@sentry/integrations';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import '../less/index.less';
import gsap from 'gsap';
import { toast as Notif } from 'react-toastify';
import * as serviceWorker from '../serviceWorker';

import App from './containers/App';
import createStore, { history } from './configureStore';
import ServiceWorkerError from './utils/service-worker-error';
import StormTrooperError from './utils/storm-trooper-error';

const storm = require('../images/storm-trooper.svg');

window.SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE;

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  dsn: 'https://689039a8d79b4e7d817d3015682baea0@o526021.ingest.sentry.io/6068323',
  integrations: [
    ...Sentry.defaultIntegrations,
    new TracingIntegrations.BrowserTracing(),
    new DedupeIntegration(),
  ],
  release: process.env.REACT_APP_SENTRY_RELEASE,
  tracesSampleRate: 0.2,
});

gsap.defaults({ overwrite: 'auto' });

const MOUNT_NODE = document.getElementById('root');

const StormTrooper = () => {
  const refresh = (() => {
    document.location.reload();
  });
  return (
    <div className="error">
      <storm.ReactComponent className="error__icon" />
      {/* eslint-disable-next-line no-useless-concat */}
      <h2 className="error__title">{window.i18('SORRY_ERROR')}</h2>
      <button
        className="error__repair"
        onClick={refresh}
      >
        {window.i18(
          'BACK_TO_LIGHT',
        )}
      </button>
    </div>
  );
};

// Google translate addon fix according to https://github.com/facebook/react/issues/11538#issuecomment-417504600
// Awaiting a proper fix : https://bugs.chromium.org/p/chromium/issues/detail?id=872770
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  // eslint-disable-next-line func-names
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        // eslint-disable-next-line no-console
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  // eslint-disable-next-line func-names
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        // eslint-disable-next-line no-console
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
      }
      return newNode;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalInsertBefore.apply(this, arguments);
  };
}

const sendStormTrooperToSentry = (error) => {
  Sentry.captureException(
    new StormTrooperError(error), {
      level: 'fatal',
    },
  );
};

if (process.env.NODE_ENV === 'development') {
  const {
    persistor,
    store,
  } = createStore();
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate
        loading={<p>test</p>}
        persistor={persistor}
      >
        <ConnectedRouter history={history}>
          <Sentry.ErrorBoundary fallback={StormTrooper} onError={sendStormTrooperToSentry}>
            <App />
          </Sentry.ErrorBoundary>
        </ConnectedRouter>
      </PersistGate>
    </Provider>,
    MOUNT_NODE,
  );
} else {
  const store = createStore();
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Sentry.ErrorBoundary fallback={StormTrooper} onError={sendStormTrooperToSentry}>
          <App />
        </Sentry.ErrorBoundary>
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE,
  );
}

const Update = ({
  closeToast,
}) => {
  const handleClick = () => {
    window.location.reload(true);
    closeToast();
  };

  return (
    <div>
      <p>{window.i18('UPDATE_AVAILABLE')}</p>
      <button className="update-button" onClick={handleClick}>
        {window.i18(
          'TRIGGER_UPDATE',
        )}
      </button>
    </div>
  );
};

try {
  serviceWorker.register({
    onUpdate: async (registration) => {
      // We want to run this code only if we detect a new service worker is
      // waiting to be activated.
      // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
      if (registration && registration.waiting) {
        Notif.info(<Update />, { autoClose: false });
      }
    },
  });
} catch (error) {
  Sentry.captureException(
    new ServiceWorkerError(error), {
      level: 'fatal',
    },
  );
}
