import React from 'react';
import { connect, useDispatch } from 'react-redux';
import classNames from 'classnames';
// import PropTypes from 'prop-types';

// CO
import PriceFilter from './PriceFilter';
import CarTypeFilter from './CarTypeFilter';
import RentersFilter from './RentersFilter';
// Actions
import {
  setPriceRange, setExcludedCarTypes, toggleUnlimitedKm, setExcludedRenters, toggleNegociated, toggleLoyalty,
} from '../../actions';
import Checkbox from '../../../../components/Checkbox';

function Filters({
  filters: {
    priceRange, prices, excludedCarTypes, unlimitedKm, excludedRenters, negociated, loyalty,
  },
  setPriceRange,
  setExcludedCarTypes,
  setExcludedRenters,
  carTypes,
  renters,
  isMobileDevice,
}) {
  const dispatch = useDispatch();

  if (isMobileDevice) {
    return (
      <>
        <p>{window.i18('FILTERS')}</p>
        <PriceFilter priceRange={priceRange} prices={prices} setPriceRange={setPriceRange} />
        <CarTypeFilter excludedCarTypes={excludedCarTypes} setExcludedCarTypes={setExcludedCarTypes} carTypes={carTypes} />
        <RentersFilter excludedRenters={excludedRenters} setExcludedRenters={setExcludedRenters} renters={[window.i18('OTHERS'), ...renters]} />
        <Checkbox checked={unlimitedKm} value={window.i18('UNLIMITED_DISTANCE')} onChange={() => dispatch(toggleUnlimitedKm())} />
        <Checkbox checked={negociated} value={window.i18('NEGOCIATED_FARE')} onChange={() => dispatch(toggleNegociated())} />
        <Checkbox checked={loyalty} value={window.i18('COMPATIBLE_WITH_CARDS')} onChange={() => dispatch(toggleLoyalty())} />
      </>
    );
  }

  return (
    <>
      <h3>
        {window.i18('FILTERS')}
        :
      </h3>
      <PriceFilter priceRange={priceRange} prices={prices} setPriceRange={setPriceRange} />
      <CarTypeFilter excludedCarTypes={excludedCarTypes} setExcludedCarTypes={setExcludedCarTypes} carTypes={carTypes} />
      <RentersFilter excludedRenters={excludedRenters} setExcludedRenters={setExcludedRenters} renters={['Autres', ...renters]} />
      <label htmlFor="unlimitedKm" className={classNames('badge', { deselect: !unlimitedKm })}>
        <input type="checkbox" id="unlimitedKm" checked={unlimitedKm} onChange={() => dispatch(toggleUnlimitedKm())} />
        <span className="badge__check" />
        {window.i18('UNLIMITED_DISTANCE')}
      </label>
      <label htmlFor="negociated" className={classNames('badge', { deselect: !negociated })}>
        <input type="checkbox" id="negociated" checked={negociated} onChange={() => dispatch(toggleNegociated())} />
        <span className="badge__check" />
        {window.i18('NEGOCIATED_FARE')}
      </label>
      <label htmlFor="loyalty" className={classNames('badge', { deselect: !loyalty })}>
        <input type="checkbox" id="loyalty" checked={loyalty} onChange={() => dispatch(toggleLoyalty())} />
        <span className="badge__check" />
        {window.i18('COMPATIBLE_WITH_CARDS')}
      </label>
    </>
  );
}

Filters.propTypes = {
};

const mapStateToProps = (state) => ({
  filters: state.carRentalResults.filters,
  carTypes: state.carRentalResults.search.carTypes,
  renters: state.carRentalResults.renters,
  isMobileDevice: state.app.isMobileDevice,
});

const mapDispatchToProps = (dispatch) => ({
  setPriceRange: (min, max) => dispatch(setPriceRange(min, max)),
  setExcludedCarTypes: (excludedCarTypes) => dispatch(setExcludedCarTypes(excludedCarTypes)),
  setExcludedRenters: (excludedRenters) => dispatch(setExcludedRenters(excludedRenters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
