import React from 'react';
// Components
import FilterContainer from '../../../../components/FilterContainer';
import Checkbox from '../../../../components/Checkbox';
// Functions
import { formatCarType } from '../../../../utils/converters';

const propTypes = {
};

function CarTypeFilter({ carTypes, excludedCarTypes, setExcludedCarTypes }) {
  const handleChange = (type) => {
    const values = [...excludedCarTypes];
    if (excludedCarTypes.includes(type)) {
      values.splice(values.indexOf(type), 1);
    } else {
      values.push(type);
    }
    setExcludedCarTypes(values);
  };

  return (
    <FilterContainer btnText={window.i18('VEHICLE_TYPE')} active={excludedCarTypes.length > 0}>
      <>
        <ul>
          {carTypes.map((c) => (
            <li key={c}>
              <Checkbox checked={!excludedCarTypes.includes(c)} name="car-type" onChange={() => handleChange(c)} value={formatCarType(c)} />
            </li>
          ))}
        </ul>
      </>
    </FilterContainer>
  );
}

CarTypeFilter.propTypes = propTypes;

export default CarTypeFilter;
