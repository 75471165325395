/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { is } from 'immutable';
import { openModal } from '../../ModalWrapper/actions';
import { getApprovers } from '../../App/actions';

import { changePaymentMethod } from '../actions';
import { displayPrice } from '../../../utils/converters';
import Input from '../../../components/Inputs/Material';
import Select from '../../../components/Inputs/MaterialSelect';
import { uniqueArray } from '../../../utils/functions';

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        travel_policy_exemption: null,
      },
      PVEConfirmed: null,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.getApprovers();
  }

    openApproversListModal = () => {
      const travelIDs = uniqueArray(this.props.content.filter((c) => c.subsets.find((subset) => subset.bookable && subset.ticket_ids.filter((id) => this.props.checkout.tickets.includes(id)).length > 0)).map((c) => c.travelID));
      this.props.openModal('ApproversList', {
        approvers: this.props.user.approvers,
        fields: this.state.fields,
        existingOption: this.props.checkout.isConfirmingOption,
        travelIDs,
        exemption: this.state.fields && this.state.fields.travel_policy_exemption ? this.state.fields.travel_policy_exemption : null,
        withSecondary: true,
      });
    }

    onChange = (ev, field) => {
      const { value } = ev.target;

      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          [field]: value,
        },
      }));
    }

    // Here in case we want to implement it
    // It prevents apps from crashing due to the UserInput structure
    onBlur = () => true

    changePaymentMethod = () => {
      this.props.changePaymentMethod('CREDIT_CARD');
    }

    setPVE = (val) => {
      this.setState((prevState) => {
        const PVEConfirmed = val;
        let { travelPolicyExemption } = prevState.fields;

        // Clearing the comment when the PVE is respected
        if (PVEConfirmed) { travelPolicyExemption = null; }

        return {
          PVEConfirmed,
          fields: {
            ...prevState.fields,
            travel_policy_exemption: travelPolicyExemption,
          },
        };
      });
    }

    canProceed = () => {
      // Can pay only if the user has confirmed PVE or filled an exemption reason, and if every required field is filled
      // We remove the travel_policy_exemption which is not required (only if the PVE is not confirmed)
      const fields = this.props.checkout.requiredFields == null ? [] : this.props.checkout.requiredFields.map((field) => field.name).filter((name) => name !== 'travel_policy_exemption');
      return (this.state.PVEConfirmed // PVE confirmed or not confirmed with travel_policy_exemption filled
                || (this.state.PVEConfirmed !== null && this.state.fields.travel_policy_exemption != null && this.state.fields.travel_policy_exemption.length > 0))
            && fields.every((field) => this.state.fields[field] != null && (this.state.fields[field].trim().length > 0)); // All required fields have been filled
    }

    payTickets = () => {
      if (this.props.checkout.isConfirmingOption) {
        this.props.confirmTickets(this.state.fields);
      } else {
        this.props.payTickets(this.state.fields);
      }
    }

    requestOption = () => {
      this.props.requestOption(this.state.fields);
    }

    render() {
      // @todo allow to pay by credit card for after filling analytics
      // We're hiding the credit card option by default for an enterprise, and show it only if available to this company
      // let hidingCreditCardOption = true;
      // // eslint-disable-next-line no-unused-expressions
      // this.props.user && this.props.user.company && this.props.user.company.payment_methods && this.props.user.company.payment_methods.forEach((method) => {
      //   if (method != null && method.toLowerCase() === 'credit_card') {
      //     hidingCreditCardOption = false;
      //   }
      // });
      if (!this.props.user) {
        return null;
      }
      let isInternational = false;
      for (let i = 0; i < this.props.content.length; i += 1) {
        const content = this.props.content[i];
        const bookable = content.subsets.filter((subset) => subset.bookable);
        const internationalTicket = bookable.find((subset) => subset.tickets.some((ticket) => this.props.checkout.tickets.includes(ticket.uid) && ticket.tag?.includes('international')));
        if (internationalTicket) {
          isInternational = true;
        }
      }

      return (
        <div className="common-wrapper enterprise-checkout-wrapper">
          <h2 className="common-sub-title">{window.i18('BOOKING_AND_PAYMENT')}</h2>
          <div className="checkout-details">
            <p className="common-small-font">{window.i18('ABOUT_TO_BOOK_COMPANY')}</p>
          </div>
          { this.props.checkout.travelPolicy
            && (
              <>
                { this.props.checkout.isCompliant ? (
                  <p className="common-small-font">
                    {window.i18('TRIP_IS_CONFORM_TO')}
                    {' '}
                    <strong>{window.i18('THE_PVE')}</strong>
                  </p>
                )
                  : (
                    <p className="common-small-font">
                      {window.i18('TRIP_IS_NOT_CONFORM_TO')}
                      {' '}
                      <strong>{window.i18('THE_PVE')}</strong>
                    </p>
                  )}
                <div className="pve">
                  { this.props.checkout.isCompliant ? (
                    <span className="icon compliant">{window.i18('CONFORM')}</span>
                  )
                    : (
                      <span className="icon not-compliant">{window.i18('NOT_CONFORM')}</span>
                    )}
                  <div className="pve-content">
                    <p dangerouslySetInnerHTML={{ __html: this.props.checkout.travelPolicy }} />
                  </div>
                </div>
              </>
            )}

          <Formik
            initialValues={buildInitialValues(this.props.checkout.requiredFields, this.props.checkout.optionalFields, !this.props.checkout.isCompliant)}
            validationSchema={buildValidationShema(this.props.checkout.requiredFields, this.props.checkout.optionalFields, !this.props.checkout.isCompliant)}
            onSubmit={(fields, actions) => {
              this.setState({ fields }, () => {
                if (this.props.sandoxMode) {
                  this.requestOption();
                } else if (this.props.checkout.needApproval) {
                  this.openApproversListModal();
                } else {
                  this.payTickets();
                }
                actions.setSubmitting(false);
              });
            }}
          >
            {({
              setFieldValue, values, errors, touched, isSubmitting, status,
            }) => (
              <Form className="step-block__form analytics-form">
                { !this.props.checkout.isCompliant && (
                  <div className="row travel_policy_exemption">
                    <div className="col travel_policy_exemption__header">
                      {window.i18('PLEASE_FILL_NON_CONFORM_REASON')}
                      {' '}
                      :
                    </div>
                    <div className="col col-12">
                      <Input
                        onChange={(value) => setFieldValue('travel_policy_exemption', value)}
                        name="travel_policy_exemption"
                        value={values.travel_policy_exemption}
                        placeholder={window.i18('SECOND_UNAVAILABLE')}
                        type="text"
                        label={`${window.i18('REASON')}*`}
                        error={errors.travel_policy_exemption}
                        touched={touched.travel_policy_exemption}
                      />
                    </div>
                  </div>
                )}

                <p className="m-0 m-top">
                  <strong>
                    {window.i18('ANALYTICAL_FIELDS')}
                    {' '}
                    :
                  </strong>
                  {' '}
                  {window.i18('TO_FILL_BELOW_ASTERISK')}
                </p>
                <span className="submit-error">
                  {status}
                </span>
                {this.props.checkout.requiredFields && this.props.checkout.requiredFields.map((field, index) => renderField(true, field, index, setFieldValue, values, errors, touched))}
                {this.props.checkout.optionalFields && this.props.checkout.optionalFields.map((field, index) => renderField(false, field, index, setFieldValue, values, errors, touched))}
                {isInternational && (
                  <>
                    <h2>{window.i18('VERIFY_ACCESS_CONDITIONS')}</h2>
                    <iframe src="https://www.covidchecker.com/embed_plain" title="covidchecker" width="100%" height="400px" />
                  </>
                )}
                <div className="row">
                  <div className="col col-12">
                    {(this.props.checkout.needApproval && !this.props.sandboxMode)
                      ? renderApproButton(this.props.user.approvers.length, isSubmitting)
                      : (
                        <button type="submit" className="button-main" disabled={isSubmitting}>
                          {this.props.sandboxMode ? window.i18('PUT_OPTION') : window.i18('COMPANY_BOOKING')}
                          (
                          {this.props.price ? displayPrice(this.props.price / 100) : '...'}
                          )
                        </button>
                      )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      );
    }
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
  user: state.app.user,
  sandboxMode: state.app.sandboxMode,
  sandboxAnalytics: state.app.sandboxParams.analytics,
  content: state.cart.content,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (name, options) => dispatch(openModal(name, options)),
  changePaymentMethod: (method) => dispatch(changePaymentMethod(method)),
  getApprovers: () => dispatch(getApprovers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckoutForm);

function renderApproButton(n, isSubmitting) {
  return (n > 0 ? <button type="submit" className="button-main" disabled={isSubmitting}>{window.i18('ASK_APPROVAL')}</button> : <span>{window.i18('CONFIG_ERROR_NO_APPROVERS')}</span>);
}

function buildValidationShema(requiredFields, optionalFields, withTPExemption) {
  const shape = {};
  if (requiredFields != null) {
    requiredFields.forEach((field) => {
      const validation = yup.string().required('Ce champ est requis');
      if (field.values && field.values.length > 0) {
        validation.oneOf(field.values, 'Une valeur valide doit être sélectionnée');
      }
      shape[field.name] = validation;
    });
  }
  if (optionalFields != null) {
    optionalFields.forEach((field) => {
      const validation = yup.string();
      if (field.values && field.values.length > 0) {
        validation.oneOf(field.values, 'Une valeur valide doit être sélectionnée');
      }
      shape[field.name] = validation;
    });
  }
  if (withTPExemption) {
    shape.travel_policy_exemption = yup.string().required('Ce champ est requis');
  }
  return yup.object().shape(shape);
}

function buildInitialValues(requiredFields, optionalFields, withTPExemption, sandboxAnalytics) {
  const init = {};
  if (requiredFields != null) {
    requiredFields.forEach((field) => {
      const existingAnalytic = sandboxAnalytics && sandboxAnalytics.find((a) => a.analytic.name === field.name);
      init[field.name] = existingAnalytic ?? '';
    });
  }
  if (optionalFields != null) {
    optionalFields.forEach((field) => {
      const existingAnalytic = sandboxAnalytics && sandboxAnalytics.find((a) => a.analytic.name === field.name);
      init[field.name] = existingAnalytic ?? '';
    });
  }
  if (withTPExemption) {
    init.travel_policy_exemption = '';
  }
  return init;
}

function renderField(isRequired, field, index, setFieldValue, values, errors, touched) {
  const options = field.values
    ? field.values.map((value) => ({
      value,
      name: value,
    }))
    : null;
  return (
    <div className="row" key={`field-${index}`}>
      <div className="col col-12">
        {options
          ? (
            <Select
              onChange={(value) => setFieldValue(field.name, value)}
              name={field.name}
              value={values[field.name]}
              placeholder={field.label}
              type="text"
              label={isRequired ? `${field.label}*` : field.label}
              error={errors[field.name]}
              touched={touched[field.name]}
              options={[{ value: '', name: '' }].concat(options)}
            />
          )
          : (
            <Input
              onChange={(value) => setFieldValue(field.name, value)}
              name={field.name}
              value={values[field.name]}
              placeholder={field.label}
              type="text"
              label={isRequired ? `${field.label}*` : field.label}
              error={errors[field.name]}
              touched={touched[field.name]}
            />
          )}
      </div>
    </div>
  );
}
