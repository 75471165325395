import React from 'react';

import Number from './number';

const counter = ({
  value,
  initialValue,
  reverse,
  delay,
  light,
}) => {
  const splitValue = (value) => {
    value = value.toString();
    // First, we remove decimals
    if (value.includes('.')) {
      [value] = value.split('.');
    }
    // Then we return the digits split in an array
    return value.split('');
  };

  const formatDigits = (value, initialValue) => {
    const valueDigits = splitValue(value);
    if (!initialValue) {
      while (valueDigits.length < 3) {
        valueDigits.unshift(0);
      }
      return valueDigits;
    }
    const initialValueDigits = splitValue(initialValue);
    // If an initial value is given, we need to format
    // the two number in an array so that we can animate
    // each digit from the initial value to the final one
    return valueDigits.reverse()
      .map((digit, i) => ({
        final: digit,
        initial: initialValueDigits.reverse()[i] || '0',
      }))
      .reverse();
  };

  const digits = formatDigits(value, initialValue);

  return (
    <section className={`counter ${light ? 'counter--light' : ''}`}>
      <div className="counter__numbers">

        {digits.map((v, i) => (
          // TODO : Wrap thousands in an element to add padding to
          initialValue
            ? (
              <Number
                key={`counter-${i}`}
                value={v.final}
                initialValue={v.initial}
                delay={delay}
              />
            )
            : (
              <Number
                key={`counter-${i}`}
                reverse={reverse}
                value={v}
                delay={delay}
              />
            )))}
        <span className="counter__unit">kg CO₂</span>
      </div>
    </section>
  );
};

export default counter;
