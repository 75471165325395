const text = {
  fr: {
    // ERRORS
    ERROR_GENERIC: 'Le service est momentanément indisponible, veuillez contacter le support client de votre agence',
    CONFIG_ERROR_NO_APPROVERS: 'Afin de créer un ordre de mission, vous devez avoir au moins 1 approbateur de définis !',
    // GENERIC WORDS
    LANGUAGE: 'Langue',
    FROM: 'de',
    FROM_THE: 'du',
    TO_THE: 'au',
    AT: 'à',
    YOU: 'vous',
    OTHERS: 'Autres',
    CONFIRM: 'Valider',
    SORTING: 'Trier',
    SORT: 'Tri',
    PRICE: 'Prix',
    DISTANCE: 'Distance',
    FOUND: 'trouvé',
    FOUND_PLURAL: 'trouvés',
    DAY: 'jour',
    DAYS: 'jours',
    NIGHT: 'nuit',
    NIGHTS: 'nuits',
    EMAIL: 'Email',
    PHONE: 'Téléphone',
    IDENTICAL: 'identique',
    TOTAL: 'Total',
    START: 'Début',
    THE: 'le',
    THE_PLURAL: 'les',
    THE_CUT: 'l\'',
    ON: 'le',
    END: 'Fin',
    UNKNOWN: 'inconnue',
    EXPIRED: 'expirés',
    NEXT: 'Suivant',
    PREVIOUS: 'Précédent',
    BETWEEN: 'Entre',
    AND: 'et',
    OR: 'ou',
    PERSON: 'personne',
    PERSONS: 'personnes',
    SEE: 'Voir',
    LESS: 'moins',
    MORE: 'plus',
    NONE: 'Aucun',
    TRAVELER: 'voyageur',
    PASSENGER: 'passager',
    PASSENGERS: 'passagers',
    CONTAINS: 'contient',
    INCLUDED: 'inclus',
    ACCESSIBLE: 'Accessible',
    IN: 'dans',
    TICKET: 'Billet',
    TICKETS: 'Billets',
    APPROVED: 'approuvé',
    APPROVED_FEM: 'approuvée',
    APPROVED_PLURAL: 'approuvés',
    CANCEL: 'Annuler',
    THIS: 'ce',
    THIS_FEM: 'cette',
    THESE: 'ces',
    SEND: 'Envoyer',
    WARNING: 'ATTENTION',
    INFORMATION: 'Information',
    WELCOME: 'Bienvenue',
    CARD: 'Carte',
    CARDS: 'Cartes',
    UNKNOWN_CARD: 'Carte iconnue',
    YES: 'oui',
    DELETE: 'Supprimer',
    DENIED: 'refusé',
    DENIED_PLURAL: 'refusés',
    WALKING: 'Marcher',
    TRANSFER: 'Changer de ligne',
    WAITING: 'Patienter',
    TO_BIKE: 'Pédaler',
    RIDE: 'Glisser',
    DRIVE: 'Rouler',
    BUS: 'Bus',
    RER: 'RER',
    TRAMWAY: 'Tramway',
    GO: 'Aller',
    TAXI: 'Taxi',
    WALK: 'Marche',
    SECTION: 'Tronçon',
    BAD: 'Mauvaise',
    GOOD: 'Bonne',
    GREAT: 'Excellente',
    FOOTPRINT: 'Empreinte',
    CARBON: 'carbone',
    SAVE: 'Enregistrer',
    NUMBER: 'Numéro',
    MON: 'Lun',
    TUE: 'Mar',
    WED: 'Mer',
    THU: 'Jeu',
    FRI: 'Ven',
    SAT: 'Sam',
    SUN: 'Dim',
    INTO: 'en',
    CLOSE: 'Fermer',
    THANKS: 'Merci',
    ADD: 'Ajouter',
    REMOVE: 'Retirer',
    RESET: 'Réinitialiser',
    MORNING: 'Matin',
    AFTERNOON: 'Après-midi',
    EMPLOYEE: 'Employé',
    A_MASC: 'un',
    A_FEM: 'une',
    ADULT: 'Adulte',
    ACC: 'Acc.',
    CREATE: 'Créer',
    EDIT: 'Modifier',
    RADIUS: 'rayon',
    REQUEST: 'demande',
    TRANSFERED: 'transférée',
    ACCEPT: 'Accepter',
    ESCALATE: 'Transférer',
    DENIED: 'Refusée',
    EXPIRED_FEM: 'Expirée',
    ESCALATED: 'Transférée',
    DEPARTURE: 'Départ',
    ARRIVAL: 'Arrivée',
    CHECKIN: 'Check-in',
    CHECKOUT: 'Check-out',
    DATE: 'Date',
    BY: 'par',
    // GENERIC PHRASES
    RATE: 'Tarif',
    IN_TOTAL: 'au total',
    TOTAL_FOR: 'Total pour',
    DEPARTURE_ON: 'Départ le',
    RETURN_ON: 'Retour le',
    PRICE_DETAIL: 'Détails du prix',
    INCLUDED_SERVICES: 'Services compris',
    ON_THE_ACCOUNT_OF: 'Pour le compte de ...',
    NO_RESULTS_SEARCH: 'Aucun résultat pour votre recherche...',
    ERROR_OCCURED_TRY_AGAIN: 'Une erreur a eu lieu, veuillez rééssayer.',
    GARANTEED_UP_TO: 'jusqu\'au',
    WITHOUT_TAXES: 'HT',
    TOTAL_WITH_TAXES: 'Total TTC',
    TO_PRM: 'aux personnes à mobilité réduite',
    SUCCESSFULLY: 'avec succès',
    AT_THE_TREEP: 'chez the Treep',
    STARTING_FROM: 'à partir de',
    PUBLIC_TRANSPORT: 'Transport en commun',
    CARBON_FOOTPRINT: 'empreinte carbone',
    POOR: 'mediocre',
    POOR_FOOTPRINT() {
      return [this.FOOTPRINT, `${this.CARBON} ${this.MEDIOCRE}`];
    },
    YOUR_PASSWORD: 'Votre mot de passe',
    TRAVEL_POLICY: 'Politique de Voyage',
    TO_APPROVE: 'pour approuver',
    ONLY: 'Plus que',
    ROUND_TRIP: 'A/R',
    CARDS_EXPIRATION_DATES: 'Dates d\'expiration de vos cartes',
    // ACTIONS
    EDIT_SEARCH: 'Modifier la recherche',
    RETURN_TO_LIST: 'Retour à la liste',
    DISPLAY_ON_MAP: 'Afficher sur la carte',
    TO_COMPLETE: 'A compléter',
    ADD_TO_CART: 'Mettre au panier',
    SEARCH_BTN: 'Go !',
    RENT_A_CAR: 'Louer une voiture',
    BOOK_A_ROOM: 'Réserver une chambre',
    BOOK_A_TRIP: 'Réserver un voyage',
    ADD_SERVICE: 'Ajouter une prestation',
    EMPTY_CART: 'Vider le panier',
    SEE_MY_TICKETS: 'Voir la liste de mes billets',
    RETURN_TO_RESULTS_LIST: 'Retour à la liste des résultats',
    GO_BACK: 'Revenir en arrière',
    ADD_CARD: 'Ajouter une carte',
    ADD_ROOM: 'Ajouter une chambre',
    SEE_DETAILS: 'Voir détails',
    ADD_DOCUMENT: 'Ajouter un document',
    PRECISE_TIME: 'Préciser l\'heure',
    SEE_TICKET_RECAP: 'VOIR RECAP BILLET',
    VIEW_DETAILS: 'Voir détails',
    // LOADING
    CART_LOADING: 'Nous chargeons votre panier',
    FREEING_OPTIONS: 'Nous libérons les options',
    LOADING: 'Chargement',
    PUTTING_OPTION: 'Pose d\'une option...',
    // LOGIN SIGNUP
    AUTH_PROBLEM: 'Un problème a eu lieu lors de votre authentification. Veuillez rééssayer.',
    ACCOUNT_ACTIVATED: 'Compte activé avec succès !',
    NEW_LINK_SENT_TO_ACTIVATE_ACCOUNT: 'Un nouveau lien pour activer de votre compte vous a été envoyé par email',
    ERROR_ACTIVATION_LINK: 'Erreur lors de la demande du lien d\'activation',
    INFORMATIONS_SAVED: 'Vos informations ont bien été enregistrées',
    PASSWORD_UPDATED: 'Votre mot de passe a bien été mis à jour',
    // FORM
    PLEASE_FILL: 'Veuillez remplir ce champ',
    PHONE_NUMBER_FORMAT: 'Votre numéro doit être au format 0XXXXXXXXX',
    NOT_THAT_OLD: 'Vous n\'êtes pas si vieux !',
    TOO_YOUNG: 'Vous êtes un peu trop jeune !',
    ENTER_A_DATE: 'Veuillez entrer une date',
    CHOSE_ENTITY: 'Veuillez choisir une entité',
    FORMAT_NOT_RECOGNIZED: 'Le format n\'est pas reconnu',
    ENTER_DATE_NOT_PASSED: 'Veuillez rentrer une date qui n\'est pas passée',
    ENTER_RETURN_DATE: 'Veuillez rentrer une date de retour',
    ENTER_OUTWARD_PLACE: 'Veuillez remplir l\'adresse de départ',
    ENTER_DESTINATION_PLACE: 'Veuillez remplir l\'adresse de départ',
    ENTER_RETURN_PLACE: 'Veuillez remplir l\'adresse de retour',
    ENTER_DRIVER: 'Veuillez entrer un conducteur',
    AGGREE_FORM_RESPONSABILITY: 'En cochant cette case, je certifie l’exactitude des données enregistrées et je suis responsable des conséquences d’une mauvaise saisie de ces dernières.',
    I_ACCEPT_MY_DATA_WILL_BE_USED: 'En soumettant ce formulaire, j’accepte que les informations saisies soient exploitées dans le cadre de mon déplacement',
    PLEASE_CHECK: 'Cette case doit être cochée',
    VALID_DATE: 'Veuillez entrer une date valide.',
    ENTER_TIME_NOT_PASSED: 'Veuillez entrer une heure qui n\'est pas passée',
    ENTER_DATE_TIME_OUTWARD: 'Veuillez entrer une date/heure de retour après celle de départ',
    ENTER_ONE_PASSENGER_AT_LEAST: 'Veuillez entrer au moins un passager',
    CANT_ENTER_MORE_THAN_FOUR_PASSENGERS: 'Vous ne pouvez pas entrer plus de 4 passagers',
    CARD_NUMBER_REQUIRED: 'Un numéro est nécessaire pour ajouter une carte de ce type',
    TYPE_IN_CARD_NAME: 'Saisissez le nom de votre carte',
    ENTER_PASSED_DATE: 'Veuillez entrer une date antérieure',
    ENTER_FUTURE_DATE: 'Veuillez entrer une date future',
    ENTER_CARD_TYPE: 'Veuillez indiquer le type de carte',
    ENTER_NUMBERS_ONLY: 'Ce champ n\'accepte que des chiffres',
    // LABELS
    PASSWORD: 'Mot de passe',
    FIRSTNAME: 'Prénom',
    LASTNAME: 'Nom',
    USERNAME: 'Nom d\'utilisateur',
    NO_PREFER_TYPING_INFO: 'non, je préfère saisir mes informations personnelles à chaque voyage',
    CHECK_CONFIRM_MY_DATA: 'en cochant cette case, je certifie l’exactitude des données enregistrées et je suis responsable des conséquences d’une mauvaise saisie de ces dernières.',
    MADAM: 'Madame',
    SIR: 'Monsieur',
    BIRTHDATE: 'Date de naissance',
    PHONE_NUMBER: 'Numéro de téléphone mobile',
    HANDICAP: 'Handicap',
    HAS_HANDICAP: 'En situation d\'handicap',
    FILL_YOUR_HANDICAP: 'Entrez votre handicap',
    WHEELCHAIR: 'Fauteuil roulant',
    WHEELCHAIR_DIMENSIONS: 'Dimensions fauteuil (plié) larg x long x haut',
    WHEELCHAIR_WEIGHT: 'Poids du fauteuil roulant (kg)',
    WHEELS_WIDTH: 'Epaisseur des roues (cm)',
    INVALIDITY_CARD: 'Carte d\'invalidité avec mention tierce personne',
    CONSENT_STORAGE: 'the Treep, en sa qualité de responsable de traitement, collecte et stocke vos données. Si vous y consentez, vos informations seront utilisées pour vous éviter de les ressaisir à chaque demande de voyage. Vous pourrez retirer votre consentement à tout moment depuis votre espace personnel.',
    DOCUMENTS: 'Documents',
    LOYALTY_AND_REDUCTION_CARDS: 'Cartes de fidélité et de réduction',
    CARD_NAME: 'Nom de la carte:',
    CARD_TYPE: 'Type de carte',
    EXPIRATION_DATE: 'Date d\'expiration',
    DOCUMENT_NAME: 'Nom du document',
    DOCUMENT_TYPE: 'Type de document',
    MAN: 'Homme',
    WOMAN: 'Femme',
    NATIONALITY: 'Nationalité',
    EFFECTIVE_DATE: 'Date de délivrance',
    COUNTRY_OF_ISSUE: 'Pays de délivrance',
    ADDRESS: 'Adresse',
    BIRTH_CITY: 'Ville de naissance',
    BIRTH_COUNTRY: 'Pays de naissance',
    REASON: 'Motif',
    // FARE CONDITIONS
    CANCELLABLE: 'Annulable',
    NOT_CANCELLABLE: 'Non annulable',
    BREAKFAST_INCLUDED: 'Petit déjeuner compris',
    BREAKFAST_NOT_INCLUDED: 'Petit déjeuner non compris',
    LUNCH_INCLUDED: 'Déjeuner compris',
    DINNER_INCLUDED: 'Dîner compris',
    // APPROVAL
    DID_APPROVE: 'avez approuvé',
    DID_NOT_APPROVE: 'n\'avez pas approuvé',
    THE_TRAVEL_REQUEST: 'la demande de voyage',
    APPROVAL_VALIDATION_ERROR: 'Désolé, une erreur s\'est produite et la demande de voyage n\'a pas pu être approuvée :',
    APPROVAL_VALIDATION_EXPIRED_ERROR: 'Votre validation n’a pas pu être effectuée car sa date de validité est expirée',
    DISCOVER_THE_TREEP: 'DECOUVRIR THE TREEP',
    APPROVER: 'Approbateur',
    // FILTERS AND SORTING
    UNLIMITED_DISTANCE: 'Distance illimitée',
    COMPATIBLE_WITH_CARDS: 'Compatible avec les cartes de fidélité',
    PRICE_RANGE: 'Fourchette de prix',
    SELECT_ALL: 'Tout sélectionner',
    FILTER_AND_SORT: 'Filtre & tri',
    NO_RESULT: 'Aucun résultat',
    NO_RESULTS: 'Aucun résultat ne correspond à vos filtres. Vous pouvez les modifier pour voir plus de résultats',
    FILTERS: 'Filtres',
    LOYALTY_CARD_COMPATIBILE: 'Compatible cartes de fidélité',
    PRM_ACCESSIBLE: 'Accessible PMR',
    FILTER: 'Filtrer',
    BY_NAME: 'Par nom',
    BY_PLACE: 'Par lieu',
    BY_DATE: 'Par date',
    BY_STATUS: 'Par statut',
    ALL: 'Tous',
    // COMPLIANCE
    COMPLIANT_FARE: 'Tarif conforme à la politique de voyage',
    NEGOCIATED_FARE: 'Tarif négocié',
    NGO_FARE: 'Tarif ONG',
    NOT_COMPLIANT_FARE: 'Tarif non conforme à la politique de voyage',
    CONFORM: 'Conforme',
    NOT_CONFORM: 'Non Conforme',
    // CAR RENTAL
    CAR_RENTAL: 'location de voiture',
    CAR_RENTALS: 'locations de voitures',
    RENTER: 'Loueur',
    DRIVER: 'Conducteur',
    CAR_RENTALS_IN: 'Locations de voiture à',
    FAVORITE_RENTERS: 'Loueurs préférés',
    VEHICLE: 'véhicule',
    VEHICLE_CATEGORY: 'Catégorie du véhicule',
    VEHICLE_TYPE: 'Type de véhicule',
    VEHICLE_TAKEN: 'Prise du véhicule',
    VEHICLE_GIVEN: 'Dépot du véhicule',
    AGENCY: 'Agence',
    AGENCY_WITHDRAWAL: 'Agence de retrait',
    AGENCY_DEPOSIT: 'Agence de dépot',
    VALET_SERVICE: 'Service voiturier',
    VALET_WITHDRAWAL: 'Service voiturier: mise à disposition',
    VALET_DEPOSIT: 'Service voiturier: récupération',
    TP: 'citadine',
    TM: 'compacte',
    TG: 'SUV',
    UP: 'petit utilitaire',
    UM: 'fourgon 10m3',
    UG: 'fourgon 20m3',
    CAR: 'Voiture',
    COMMERCIAL_VEHICLE: 'Utilitaire',
    I_PICK_THE_VEHICLE_AT: 'Je prends le véhicule à...',
    I_RETURN_VEHICLE_AT: 'Je rends le véhicule à...',
    OF_THE_LOCATION_ON: 'de la location le...',
    CAR_SEARCH_ERROR: 'Erreur lors de la recherche de location de voiture',
    OPENING_SCHEDULE: 'Horaires d\'ouverture',
    // SEARCH
    COMPARE_AND_RESERVE: 'Comparez et réservez',
    COMPARE_AND_RESERVE_TEXT: 'tous les trains, avions, taxis, métros…',
    PAYED_AND_APPROVED: 'Votre voyage payé et approuvé',
    PAYED_AND_APPROVED_TEXT: 'directement par votre entreprise',
    PRESERVE_ENV: 'Préservez l’environnement',
    PRESERVE_ENV_TEXT: 'en participant aux économies de CO2 sur the Treep',
    TOTAL_CO2_ECO: 'Economie totale de CO₂ de the Treep',
    COMPLEMENTARY_SERVICES: 'Services complementaires',
    COMPOSE_YOUR_TRIP: 'Composez votre voyage',
    PRACTICAL_AND_RESPONSIBLE: 'pratique et responsable',
    REDIRECTION_ERROR: 'Une erreur a eu lieu lors de la redirection',
    TRAVEL_AGENT_MODE: 'Mode agent de voyage',
    TRIP: 'Voyage',
    RENTAL: 'Location',
    PRESERVE_PLANET_BY: 'En réservant vos voyages sur the Treep, vous préservez la planète en favorisant les transports doux et en',
    NEUTRALIZING_AUTOMATICALLY: 'neutralisant automatiquement',
    YOUR_EMISSIONS_BY_PLANTING_TREES: 'vos émissions de CO2 par la plantation d\'arbres.',
    CHOSE_OFFER: 'Choisir cette offre',
    NO_MATCHING_RESULTS: 'Aucun résultat correspondant',
    CONNECTIONS: 'corresp.',
    DIRECT: 'direct',
    UNAVAILABLE: 'Indispo.',
    OFFERS_WITH_LUGGAGE: 'Offres avec bagage disponibles',
    UPGRADED: 'Surclassé en 1ère',
    LOGIN_TO_CONTINUE: 'Veuillez vous inscrire ou vous connecter pour continuer.',
    CONNECTION_TIME: 'de correspondance',
    SEARCHING: 'Recherche en cours...',
    LETS_GO: 'Allez c\'est parti !',
    SEARCHING_RETURN: 'Nous cherchons votre retour !',
    SELECT_CATEGORY: 'Selectionnez la catégorie :',
    SELECT_TIME: 'Sélectionnez une heure précise :',
    SELECT_DATE: 'Sélectionnez une date :',
    SELECT_DRIVER: 'Sélectionnez un conducteur :',
    SELECT_PLACE: 'Sélectionnez un lieu :',
    SELECT_THE: 'Sélectionnez le(s)',
    DELETE_RETURN: 'Supprimer retour',
    SEARCH_EMPLOYEE: 'Rechercher un employé ou un invité',
    CREATE_DRIVER: 'Créer un conducteur',
    IN_A_RADIUS: 'Dans un rayon',
    CAN_INFLUENCE_PRICE: 'peut influencer le tarif selon l’âge',
    // CART
    CONFIRM_DELETE: 'Êtes-vous sûr de vouloir supprimer ce(s) billet(s) ?',
    STAY_FROM: 'Sejour du',
    LOCATION_FROM: 'Location du',
    TRAVEL_FROM: 'Trajet du',
    PRICE_AND_AVAILABILITY: 'Disponible',
    VISIT: 'Rendez-vous sur',
    FOR_INFO_ON_TRIP: 'pour toute information sur votre voyage.',
    PRICE_CAN_CHANGE: 'Le prix individuel des billets finaux peut changer, mais le prix total des billets aller et retour avion et train restera identique',
    CART_IS_EMPTY: 'Votre panier est vide',
    BOOK_ECO_TRIP: 'Réserver un voyage éco-responsable',
    MY_CART: 'Mon panier',
    PVE_REMINDER: 'Rappel de la Politique de Voyage',
    TRIP_CARBON_CANCEL: 'Neutralisation carbone des trajets',
    CO2_KG: 'KG de CO2',
    PASSY_CERTIFICATE: 'Certificat de participation au programme de plantation du verger solidaire de Massy',
    TECHNICAL_FEES: '+ Frais techniques de la plateforme',
    BEFORE_TAX_FEES: 'Frais de dossier HT',
    INCLUDING_TAXABLE: 'Dont taxable',
    TOTAL_BEFORE_TAXES: 'Total HT :',
    TAXES_ON_FEES: 'TVA Frais de dossier taxables :',
    PART_PAYED_BY_CNAM: 'Partie prise en charge par l\'Assurance Maladie :',
    SUBVENTION: 'Subvention',
    TOTAL_INCLUDING_TAXES: 'Nouveau total TTC :',
    PRICE_CAN_CHANGE_OF: 'Ce prix peut différer sur votre billet final mais le prix total des billets aller et retour de',
    WILL_STAY_SAME: 'restera identique',
    DELETING_ROUND_TRIP: 'Ce ticket fait parti d\'un aller-retour. Les tickets aller et retour seront tous deux supprimés.',
    // CHECKOUT
    PAYMENT_SUCCESS: 'Paiement réalisé avec succès !',
    SECURED_PAYMENT: 'Paiement sécurisé',
    CARD_NUMBER: 'Numéro de la carte',
    CVC_CODE: 'Code CVC',
    CARD_HOLDER: 'Titulaire de la carte',
    I_ACCEPT_THE: 'J\'accepte les',
    OF_THETREEP: 'de The Treep',
    CGV: 'conditions générales de vente',
    PAYING: 'Paiement en cours...',
    PAY: 'Payer',
    SECURED_BY_STRIPE: 'Le paiement est assuré et sécurisé par Stripe',
    SSL_PAYMENT: 'Le paiement s\'effectue sur un serveur de paiement sécurisé en SSL',
    BOOKING_AND_PAYMENT: 'Réservation et paiement',
    ABOUT_TO_BOOK_COMPANY: 'Nous sommes sur le point de réserver et de payer vos billets de transports par les moyens de paiement de votre entreprise.',
    TRIP_IS_CONFORM_TO: 'Votre voyage est conforme à',
    THE_PVE: 'la politique de voyage de l\'entreprise',
    TRIP_IS_NOT_CONFORM_TO: 'Votre voyage n’est pas conforme à',
    PLEASE_FILL_NON_CONFORM_REASON: 'Veuillez remplir le motif de non respect',
    ANALYTICAL_FIELDS: 'Champs analytiques',
    TO_FILL_BELOW_ASTERISK: 'à remplir ci-dessous (les champs avec une * sont obligatoires)',
    VERIFY_ACCESS_CONDITIONS: 'Vérifiez les conditions d\'accès pour votre voyage international',
    ASK_APPROVAL: 'Demander une approbation',
    CONTINUE_TRAVEL: 'Continuer pour ce voyage',
    CONTINUE_TRAVELS: 'Continuer pour tous les voyages',
    PUT_OPTION: 'Poser une option',
    COMPANY_BOOKING: 'Paiement entreprise',
    BOOKING_ERROR: 'Une erreur s\'est produite pendant la réservation.',
    PLEASE_CONTACT_US_ON: 'Veuillez nous contacter à',
    TO_KNOW_MORE_ABOUT_STATUS: 'pour connaître le statut de votre réservation.',
    WE_VERIFY_YOUR_INFOS: 'Nous vérifions vos informations',
    RESERVED: 'réservé',
    BOOKING_CONFIRMED: 'Réservation confirmée !',
    YOUR_BOOKING_IS_CONFIRMED: 'Votre réservation est confirmée !',
    YOUR_OPTION_IS_SET: 'Votre option est posée !',
    SENDING_APPROBATION_REQUEST: 'Nous envoyons la demande d\'approbation',
    AWAITING_APPROVAL: 'en attente d\'approbation',
    YOUR_REQUEST_WAS_SENT_TO: 'Votre demande de voyage a été envoyée à',
    YOUR_REQUEST_WAS_ACCEPTED_AUTOMATICALLY: 'Votre demande de voyage a été acceptée automatiquement',
    REQUEST_ACCEPTED: 'Demande acceptée !',
    REQUEST_SENT: 'Demande envoyée !',
    PAY_BY_CARD: 'Payer par carte bancaire',
    CONFIRM_BOOKING: 'Confirmer la réservation',
    RETURN_TO_TICKET_LIST: 'Revenir à la liste des billets',
    ALL_WENT_WELL: 'Tout s’est bien passé ?',
    YOU_HAVE_UP_TO: 'Vous avez jusqu\'au',
    TO_VALIDATE_THIS_OPTION: 'pour valider cette option',
    TO_HANDLE_THIS_REQUEST: 'pour traiter cette requête',
    FARE_CONDITIONS_CAN_CHANGE: 'Les conditions tarifaires de votre voyage sont susceptibles d\'évoluer entre le moment de votre pose d\'option et sa validation.',
    SEE_TICKETS: 'Voir mes billets',
    FOR_YOUR_CONTRIBUTION: 'Pour votre contribution concrète à la réduction des émissions de gaz à effet de serre',
    PASS_ANIMATION: 'Passer l\'animation',
    SECOND_UNAVAILABLE: 'Seconde classe non disponible',
    MISSION_ORDER_FINALIZATION: 'Finalisation des ordres de mission',
    MISSION_ORDER_OF(personne) {
      return `Ordre de mission de ${personne}`;
    },
    MISSION_OBJECT: 'Objet de la mission',
    MISSION_PLACE: 'Lieu précis de la mission',
    SEND_APPROVAL_REQUEST: 'Envoyer une demande d\'approbation',
    LINK_TRANSPORT: 'Transport de liaison',
    LINK_TRANSPORTS: 'Transport(s) de liaison',
    TRANSPORT_FIELD: 'transport - optionnel',
    OTHER_REMARKS: 'Autres transports ou dépenses prévues',
    START_LOCATION: 'Lieu de départ',
    END_LOCATION: 'Lieu d\'arrivée',
    MISSION_ORDER_WILL_BE_GENERATED: 'L’ordre de mission sera généré par passager avec l’ensemble des prestations sélectionnées',
    CREATE_MISSION_ORDER: 'Créer un ordre de mission',
    // HEADER
    SEARCH: 'Recherche',
    CART: 'Panier',
    MY_SPACE: 'Mon espace',
    LOG_IN: 'Connexion',
    SIGN_UP: 'Inscription',
    LOG_OUT: 'Déconnexion',
    // HOTEL
    HOTEL: 'hôtel',
    HOTELS: 'hôtels',
    COMFORT: 'Confort',
    BREAKFAST: 'Petit déjeuner',
    COMFORT_AT_LEAST: 'Confort au moins égal à',
    HOTELS_IN: 'Hôtels à',
    SANITARY_NOTE: 'Note sanitaire',
    HAS_IMPLEMENTED_SANITARY_MEASURES: 'a mis en place différentes mesures pour garantir votre sécurité sanitaire',
    NORM: 'Norme',
    LOADING_ADDITIONAL_DATA: 'chargement des informations supplémentaires',
    CHOSING_ROOMS: 'Choix des chambres',
    ROOM: 'Chambre',
    ROOMS: 'Chambres',
    MORE_PRICES: 'Plus de tarifs',
    DISTRIBUTING_PEOPLE_IN_ROOMS: 'Placement des personnes dans les chambres',
    PEOPLE_TO_PLACE: 'Personnes à placer',
    PRICE_FROM: 'Tarif à partir de',
    ENV_FRIENDLY_HOTEL: 'Hôtel écoresponsable',
    HOTEL_NAME: 'Nom de l\'hôtel',
    EQUALS_AT_LEAST: 'au moins égal à',
    HOTEL_SEARCH_ERROR: 'Erreur lors de la recherche de l\'hôtel',
    I_SLEEP_IN: 'Je dors à...',
    THE_STAY_ON: 'du séjour le...',
    ANY_HOTEL: 'N\'importe quel hôtel',
    NAME_CONTAINS: 'Le nom contient',
    STAR: 'étoile',
    STARS: 'étoiles',
    LOGIN_IN_TO_ADD_HOTEL_TO_CART: 'Vous devez être connecté pour ajouter un hôtel au panier.',
    TAX_INCLUDED: 'Taxe de séjour comprise',
    TAX_NOT_INCLUDED: 'Taxe de séjour non comprise',
    // MODALS
    ACCESS_TO_BOOKING_PORTAL_CHANGED: 'L’accès au portail de réservation a changé',
    WARNING_ACCOUNT_MOVED: 'Attention, votre compte a été migré sur l\'URL',
    PLEASE_USE_THIS_URL: ', merci d\'utiliser cette nouvelle URL pour vous connecter.',
    GET_THERE: 'M\'y rendre',
    ARE_YOU_SURE_YOU_APPROVE: 'Êtes vous sûr de vouloir approuver',
    ARE_YOU_SURE_YOU_TRANSFER: 'A qui souhaitez-vous transférer',
    ADD_APPROVER: 'Ajouter un approbateur',
    CHOSE_APPROVER: 'Choix de l\'approbateur',
    PLEASE_CHOSE_APPROVER: 'Veuillez choisir un approbateur',
    SPARE_APPROVER: 'Approbateur de secours',
    SPARE_APPROVER_INFO: 'L’approbateur de secours est contacté si l’approbateur principal n’a pas répondu à la demande avant la moitié du délai indiqué. Le voyage sera confirmé ou annulé dès que l’un des approbateurs répond.',
    EMAIL_ACTIVATION: 'Activation de l\'email',
    ERROR_DURING_ACCOUNT_ACTIVATION: 'Une erreur est survenue lors de l\'activation de votre compte.',
    GET_NEW_ACTIVATION_LINK: 'Recevoir un nouveau lien d\'activation',
    TREATING_YOUR_REQUEST: 'Nous allons traiter votre demande au plus vite !',
    VERIFY_CANCEL_CONDITIONS: 'Nous vérifions la flexibilité tarifaire de vos billets, les conditions de remboursement et si besoin vous contactons sur votre email :',
    HERE_ARE_CONDITIONS: 'Voici les conditions tarifaires de vos billets qui vont être appliquées',
    WE_CAN_CHECK_CONDITIONS: 'Nous pouvons vérifier la flexibilité tarifaire de vos billets, les conditions de remboursement et vous comuniquer les frais éventuels applicables en cas d’annulation par email',
    IF_YOU_CANCEL: 'Si vous annulez',
    THE_RESERVATIONS: 'les réservations',
    ALL_TRAVELERS_WILL_BE_CONCERNED: 'tous les voyageurs avec vous seront concernés',
    CONFIRM_CANCEL_REQUEST: 'Confirmer la demande d\'annulation',
    ABANDON_CANCEL_REQUEST: 'Abandonner la demande d\'annulation',
    UPDATE_DOCUMENT: 'Modifier le document',
    UPDATE_DRIVER: 'Modifier le conducteur',
    EMITTED: 'émis',
    TO_NEUTRALIZE: 'à neutraliser',
    BY_A_REFORESTATION_PROGRAM: 'par un programme de reforestation',
    SAVED: 'épargné',
    COMPARED_TO_CAR: 'par rapport à une voiture classique',
    UPDATE_TRAVELER: 'Modifier ce voyageur',
    UPDATE_MY_CARDS: 'Mettre à jour mes cartes',
    LOG_YOURSELF_IN: 'Connectez-vous',
    FORGOTTEN_PASSWORD: 'Mot de passe oublié',
    UPDATE_USER: 'Modifier l\'utilisateur',
    CREATE_ACCOUNT: 'Créer un compte',
    USERNAME_NOT_RECOGNIZED: 'Nom d\'utilisateur non reconnu.',
    ENTER_YOUR_USERNAME: 'Entrez votre nom d\'utilisateur',
    UPDATE_PASSWORD: 'Mise à jour du mot de passe',
    UPDATE_PASSENGER: 'Modifier ce passager',
    ADD_PASSENGER: 'Ajouter un passager',
    CREATE_COMPLETE_PROFILE: 'Créer un profil complet',
    AN_EMAIL_HAS_BEEN_SENT: 'Un mail vous a été envoyé',
    SEE_YOU: 'À tout de suite !',
    SENDING: 'Envoi en cours...',
    ACCOUNT_CREATED_VALIDATE_EMAIL: 'Votre compte est créé. Pour valider votre inscription, cliquez sur le lien dans l\'email que nous venons de vous envoyer sur',
    I_GOT_IT: 'J\'ai compris',
    REGISTER: 'Inscrivez-vous',
    CONSULT_PRIVACY_POLICY: 'Consultez notre politique de confidentialité',
    OFFLINE_CANCEL: 'L\'annulation en ligne n\'est pas autorisée sur ce voyage, veuillez contacter directement le service après-vente sur les heures d\'ouverture. Si vous disposez d\'un contrat après-vente particulier, veuillez contacter directement le numéro 24/7 fourni',
    // ONBOARDING
    INFOS_DELETED: 'Vos informations ont été supprimées avec succès',
    INFOS_SAVED: 'Vos informations ont été enregistrées avec succès',
    ACTIVATION_LINK_EXPIRED: 'Ce lien pour activer votre compte n\'est plus valide',
    WELCOME_ABOARD: 'Bienvenue sur the Treep',
    CONGRATULATIONS_FIRST_LOGIN: 'Félicitations, c’est votre première connexion et vous êtes invité.e à vérifier l’exactitude, corriger ou compléter les informations obligatoires pour créer un compte sur the Treep.',
    ERROR_HAPPENED: 'Une erreur a eu lieu',
    FINALIZE_YOUR_SIGNUP: 'Finalisez votre inscription',
    CHOSE_TO_STORE_DATA: 'Vous avez choisi de stocker sur the Treep vos informations personnelles utiles à vos prochains voyages. Vous pouvez en vérifier l’exactitude, les corriger ou les compléter.',
    // TRIP
    TRAIN: 'train',
    TRAINS: 'trains',
    FLIGHT: 'vol',
    FLIGHTS: 'vols',
    MAIN_TRIP: 'Transport principal',
    SEE_OUTWARD_TICKET: 'Voir mon billet aller',
    AVAILABLE_OFFERS: 'Offres disponibles',
    HOLD_LUGGAGE: 'bagage en soute',
    CABIN_LUGGAGE: 'bagage cabine',
    CONNECTIONS_FULL: 'Correspondances',
    WITH_LUGGAGE: 'Avec bagages',
    NO_FLEX: 'No-Flex',
    SEMI_FLEX: 'Semi-Flex',
    FULL_FLEX: 'Full-Flex',
    DEPARTURE_TIME: 'Heure de départ',
    COMPANIES: 'Compagnies',
    OUTWARD: 'Aller',
    RETURN: 'Retour',
    MY_TRIP_ON_THE: 'Mon Voyage du',
    I_LEAVE_FROM: 'Je pars de ...',
    I_GO_TO: 'Je vais à ...',
    LEAVE_AFTER: 'Partir après',
    ARRIVE_BEFORE: 'Arriver avant',
    NO_CARDS: 'Sans carte',
    NO_SPECIAL_FARES: 'Aucun tarif spécial',
    FIRST_CLASS: '1ère',
    SECOND_CLASS: '2nde',
    ECONOMY_CLASS: 'Eco',
    PREMIUM_ECONOMY_CLASS: 'Eco Premium',
    LONG_TRAIN: 'Intercité',
    SHUTTLE: 'Navette',
    THE_STATION: 'la station',
    BUS_STOP: 'l\'arrêt de bus',
    TOTAL_DURATION_CONTAINS: 'La durée totale comprend :',
    TOTAL_DURATION_1: 'La meilleure durée du transport principal',
    TOTAL_DURATION_2: 'Une estimation des temps nécessaires aux vérifications, embarquement et débarquement',
    TOTAL_DURATION_3: 'Une estimation du temps de trajet en voiture entre le point d\'origine et la gare ou l\'aéroport concerné',
    TOTAL_DURATION_4: 'Une estimation du temps de trajet en voiture entre la gare ou l\'aéroport et le point de destination',
    PRICE_CALCULATION: 'Le prix affiché est le meilleur prix trouvé sur le transport principal, cela ne comprend pas le tarif du transport secondaire entre le point d’origine et la gare ou l’aéroport ni le tarif du transport secondaire entre la gare ou l’aéroport et le point de destination',
    CO2_PODIUM_TOOLTIP_1: 'Le classement tient compte des trajets qui induisent le moins d’émissions de gaz à effet de serre (en CO2e) pour chaque transport principal. Ces émissions comprennent :',
    CO2_PODIUM_TOOLTIP_2: 'La production et la combustion des carburants fossiles.',
    CO2_PODIUM_TOOLTIP_3: 'La production de l’électricité pour le fonctionnement des trains.',
    CO2_PODIUM_TOOLTIP_4: 'Les trainées de condensation et émissions non carbonées associées pour les vols.',
    CO2_PODIUM_TOOLTIP_5: 'Les émissions de gaz à effet de serre de la production des équipements et des infrastructures sont exclues.',
    // MY SPACE
    STATUS: 'Statut',
    TYPE: 'Type',
    DATES: 'Dates',
    PLACES: 'Lieux',
    SCHEDULES: 'Horaires',
    PASSENGER_S: 'Passager(s)',
    CONDITIONS: 'Conditons',
    PNR_FILE: 'PNR/ Dossier',
    APPROVER_S: 'Approbateur(s)',
    DELAY: 'Délais',
    COMPLIANCE: 'Conformité',
    COMPLETED_REQUESTS: 'Demandes traitées',
    NO_APPROBATION_REQUESTS: 'Vous n\'avez aucune demande en attente d\'approbation.',
    SURE_DELETE_CARD: 'Êtes-vous sûr de vouloir supprimer cette carte ?',
    MY_CARDS: 'Mes cartes',
    CO2_SUMMARY_OF_YOUR_TRIPS: 'Bilan CO2 de vos Treeps',
    ST: 'er',
    JANUARY_TO_TODAY: 'janvier - aujourd’hui',
    CO2_ECO: 'Economie de co2',
    CO2_EMISSIONS: 'Emissions de CO2',
    COMPENSATION: 'Compensation',
    KM_SUMMARY_OF_YOUR_TRIPS: 'Bilan kilométrique de vos Treeps',
    TRIPS_COUNT: 'Nombre de treeps',
    DISTANCE_COVERED: 'Distance parcourue',
    DELELTE_OPTIONAL_DATA: 'Supprimer mes informations optionnelles',
    DELETE_ACCOUNT: 'Supprimer mon compte',
    UPDATE: 'Mettre à jour',
    MY_DATA: 'Mes informations',
    FAILOVER: 'de secours',
    BOOKINGS_AWAITING_APPROVAL: 'Réservations en attente d\'approbation',
    REMIND_APPROVER: 'Relancer l\'approbateur',
    ASK_ANOTHER_APPROVER: 'Demander à un autre approbateur',
    NO_RECENT_TICKETS: 'Vous n\'avez aucune réservation récente.',
    ARCHIVED_BOOKINGS: 'Réservations archivées',
    NO_ARCHIVE: 'Vous n\'avez aucune archive',
    NEW_APPROVER: 'Nouvel approbateur',
    ERROR_LOADING_BOOKINGS: 'Un problème a eu lieu lors du chargement de vos réservations',
    ERROR_LOADING_APPROVALS: 'Un problème a eu lieu lors du chargement de vos approbations',
    APPROVAL_REQUEST_SENT_TO: 'Demande d\'approbation envoyée avec succès à',
    PROBLEM_DURING_APPROVAL: 'Un problème a eu lieu lors de la demande d\'approbation',
    SURE_REMIND_APPROVER: 'Êtes vous sûr de vouloir relancer votre approbateur ?',
    APPROVER_REMINDED: 'Votre approbateur a été relancé avec succès.',
    TICKETS_DELETED: 'Billet(s) supprimé(s) avec succès.',
    PROBLEM_DELETING: 'Un problème a eu lieu lors de la demande de suppression.',
    ARE_YOU_SURE_TO_DENY: 'Êtes vous sûr de vouloir refuser',
    DENIAL_REASON: 'Raison de votre refus',
    REASON: 'Raison...',
    REFUSE: 'Refuser',
    DELETE_REQUEST_SENT: 'Demande d\'annulation effectuée avec succès',
    PROBLEM_DURING_DELETING: 'Un problème a eu lieu lors de la demande d\'annulation',
    DOCUMENTS_UPDATED: 'Documents bien mis à jour',
    ERROR_DURING_SAVE: 'Une erreur a eu lieu lors de l\'enregistrement',
    SNCF_CARDS: 'Cartes de réduction SNCF',
    SNCF_CARDS_STARTING_FROM: 'A compter du 12/10/2020, il est nécessaire de préciser le numéro de vos cartes de réduction SNCF.',
    SNCF_CARDS_VISIT: 'A compter du 12/10/2020, il est nécessaire de préciser le numéro de vos cartes de réduction SNCF.',
    CARD_UPDATED: 'Carte bien mise à jour',
    CONFIRM_DATA_DELETE_MESSAGE: 'La suppression de vos données vous obligera à renseigner manuellement vos données de voyage à chaque réservation. Êtes-vous sûr de vouloir supprimer vos données ?',
    DATA_DELETED: 'Vos informations ont bien été supprimés.',
    CONFIRM_ACCOUNT_DELETE_MESSAGE: 'La suppression de votre compte est définitive, vous perdrez toutes vos données de voyage et ne pourrez revenir en arrière. Êtes-vous sûr de vouloir supprimer votre compte ?',
    ACCOUNT_DELETED: 'Votre compte a bien été supprimé.',
    MY_BOOKINGS: 'Mes réservations',
    MY_ARCHIVES: 'Mes archives',
    PERSONAL_DATA: 'Infos perso',
    LOYALTY_AND_SUBSCRIBTION_CARDS: 'Cartes de réduction ou d\'abonnement',
    APPROVAL_REQUESTS: 'Demandes d\'approbation',
    DASHBOARD: 'Dashboard',
    ERRORED: 'En erreur',
    CANCELLED: 'Annulé',
    PENDING_CANCEL: 'En cours d\'annulation',
    PARTIALLY_CANCELLED: 'Partiellement annulé',
    PENDING_CONFIRMATION: 'Options à confirmer',
    PENDING_APPROVAL: 'En attente d\'approbation',
    IN_CART: 'Au panier',
    PARTIALLY_BOOKED: 'Réservé partiellement',
    ISSUED: 'Réservé billet émis',
    PARTIALLY_CONFIRMED: 'Confirmé partiellement',
    PENDING_TICKETING: 'En attente d\'émission',
    PARTIALLY_TICKETED: 'Emis partiellement',
    PENDING_PAYMENT: 'En attente du paiement',
    PAID: 'Payé',
    PENDING_REFUND: 'En attente du remboursement',
    PARTIALLY_REFUNDED: 'Partiellement remboursé',
    REFUNDED: 'Remboursé',
    REMOVED: 'Supprimé',
    MODIFIED: 'Modifié',
    ARCHIVED: 'Archivé',
    UNKNOWN_STATUS: 'Statut inconnu',
    PASSPORT: 'Passeport',
    ID_CARD: 'Carte d\'identité',
    DRIVER_LICENSE: 'Permis de conduire',
    NO_DOCUMENTS: 'Vous n\'avez aucun document.',
    APP_LANG: 'Langue',
    FRENCH: 'Français',
    ENGLISH: 'Anglais',
    CANCEL_REQUIRES_AGENT: 'Cette annulation nécessite l\'intervention de l\'un de nos agents de voyage et ne peut être effectuée que sur les heures d\'ouverture de l\'agence. Nous traiterons votre demande dans les plus brefs délais. Si toutefois vous disposez d\'un contrat de support 24/7, nous vous invitons à contacter directement le numéro dédié.',
    ANSWERED: 'Répondues',
    REQUESTED_BY: 'Demande envoyée par',
    NOT_CONFORM_REASON: 'Motif de non respect de la politique de voyage de l\'entreprise',
    // UTILS
    SESSION_EXPIRED: 'Votre session a expiré. Merci de vous reconnecter.',
    USER_ALREADY_EXISTS: 'Un compte existe déjà avec cette adresse e-mail.',
    INVALID_SUBSIDY_NUMBER: 'Numéro de demande invalide',
    ERROR_TRAIN_FULL: 'Votre réservation n\'a pas pu aboutir car le train que vous désirez est malheureusement complet.',
    ERROR_INVALID_CARD: 'Votre réservation n\'a pas pu aboutir car votre carte de fidélité n\'est pas valide.',
    ERROR_LASTNAME: 'Votre réservation n\'a pas pu aboutir car le nom de famille n\'est pas valide.',
    ERROR_EMPTY_BIRTHDATE: 'Votre profil est incomplet pour pouvoir réserver (votre date de naissance est vide)',
    ERROR_AIRPLANE_FULL: 'Votre réservation n\'a pu aboutir car le vol que vous désirez est malheureusement complet.',
    BOAT: 'bateau',
    // HANDICAP
    MEDA: 'MEDA: Accord médical - civière uniquement',
    BLND: 'BLND: non voyant ou malvoyant',
    DEAF: 'DEAF: non entendant ou malentendant',
    WCH: 'WCH: fauteuil roulant',
    WCHR: 'WCHR : fauteuil roulant - peut se déplacer seul au sol et en cabine, monter et descendre des escaliers',
    WCHS: 'WCHS: fauteuil roulant - peut se déplacer seul au sol et en cabine, mais pas d’escaliers',
    WCHC: 'WCHC: fauteuil roulant - ne peut pas se déplacer seul, doit être porté jusqu´à son siège en cabine.',
    STCR: 'STCR: présence de civières.',
    EXST: 'EXST: besoin d´un ou de sièges supplémentaires pour raison médicale ou confort personnel.',
    DPNA: 'DPNA: affecté d´une déficience mentale ayant besoin d´assistance',
    // ERROR
    SORRY_ERROR: 'Désolé une erreur est survenue !',
    BACK_TO_LIGHT: 'Retour à la lumière',
    SHOW_DETAILS: 'Afficher les détails',
    // MISC
    COVID_INFO: 'Info COVID-19',
    COVID_INFO_TEXT: 'Vérifiez les modalités pour voyager à l\'internationnal',
    YOU_ARE_GOING_TO: 'Vous souhaitez partir pour',
    YOU_ARE_GOING_BACK_TO: 'Vous souhaitez retourner à',
    BARRIER_GESTURES: 'Pendant votre voyage, nous vous recommandons d\'adopter les bons réflexes pour votre santé :',
    DISTANCE_AWAY(distance) {
      return `à ${distance}`;
    },
    UPDATE_AVAILABLE: 'Une nouvelle version de the Treep est disponible !',
    TRIGGER_UPDATE: 'Mettre à jour the Treep',
    DENY: 'Refuser',
    ESCALATE: 'Transferer',
    DEMO_MODE: 'Mode démo',
  },
  en: {
    // ERRORS
    ERROR_GENERIC: 'Service is momently unvailable, please reach support of your travel agency',
    CONFIG_ERROR_NO_APPROVERS: 'In order to create a mission order, you should have at least 1 approbator.',
    // GENERIC WORDS
    LANGUAGE: 'Language',
    FROM: 'from',
    FROM_THE: 'from the',
    TO_THE: 'to the',
    AT: 'at',
    YOU: 'you',
    OTHERS: 'Others',
    CONFIRM: 'Validate',
    SORTING: 'Sort',
    SORT: 'Sort',
    PRICE: 'Price',
    DISTANCE: 'Distance',
    FOUND: 'found',
    FOUND_PLURAL: 'found',
    DAY: 'day',
    DAYS: 'days',
    NIGHT: 'night',
    NIGHTS: 'nights',
    EMAIL: 'Email',
    PHONE: 'Phone',
    IDENTICAL: 'identical',
    TOTAL: 'Total',
    START: 'Start',
    THE: 'the',
    THE_PLURAL: 'the',
    THE_CUT: 'the ',
    ON: 'on',
    END: 'End',
    UNKNOWN: 'unknown',
    EXPIRED: 'expired',
    NEXT: 'Next',
    PREVIOUS: 'Previous',
    BETWEEN: 'Enter',
    AND: 'and',
    OR: 'or',
    PERSON: 'person',
    PERSONS: 'people',
    SEE: 'See',
    LESS: 'less',
    MORE: 'more',
    NONE: 'None',
    TRAVELER: 'traveler',
    PASSENGER: 'passenger',
    PASSENGERS: 'passengers',
    CONTAINS: 'contains',
    INCLUDED: 'included',
    ACCESSIBLE: 'Accessible',
    IN: 'in',
    TICKET: 'Ticket',
    TICKETS: 'Tickets',
    APPROVED: 'approved',
    APPROVED_FEM: 'approved',
    APPROVED_PLURAL: 'approved',
    CANCEL: 'Cancel',
    THIS: 'this',
    THIS_FEM: 'this',
    THESE: 'these',
    SEND: 'Send',
    WARNING: 'ATTENTION',
    INFORMATION: 'Information',
    WELCOME: 'Welcome',
    CARD: 'Card',
    CARDS: 'Cards',
    UNKNOWN_CARD: 'Unknown card',
    YES: 'yes',
    DELETE: 'Delete',
    DENIED: 'refused',
    DENIED_PLURAL: 'refused',
    WALKING: 'Walk',
    TRANSFER: 'Change line',
    WAITING: 'Wait',
    TO_BIKE: 'Pedal',
    RIDE: 'Slide',
    DRIVE: 'Drive',
    BUS: 'Bus',
    RER: 'RER',
    TRAMWAY: 'Tramway',
    GO: 'Go',
    TAXI: 'Taxi',
    WALK: 'Walk',
    SECTION: 'Section',
    BAD: 'Bad',
    GOOD: 'Good',
    GREAT: 'Excellent',
    FOOTPRINT: 'Footprint',
    CARBON: 'carbon',
    SAVE: 'Save',
    NUMBER: 'Number',
    MON: 'Mon',
    TUE: 'Mar',
    WED: 'Wed',
    THU: 'Game',
    FRI: 'Fri',
    SAT: 'Sam',
    SUN: 'Sun',
    INTO: 'in',
    CLOSE: 'Close',
    THANKS: 'Thank you',
    ADD: 'Add',
    REMOVE: 'Remove',
    RESET: 'Reset',
    MORNING: 'Morning',
    AFTERNOON: 'Afternoon',
    EMPLOYEE: 'Employee',
    A_MASC: 'a',
    A_FEM: 'a',
    ADULT: 'Adult',
    ACC: 'Acc.',
    CREATE: 'Create',
    EDIT: 'Edit',
    RADIUS: 'radius',
    REQUEST: 'request',
    TRANSFERED: 'transfered',
    ACCEPT: 'Accept',
    ESCALATE: 'Escalate',
    REFUSED: 'Refused',
    EXPIRED_FEM: 'Expired',
    ESCALATED: 'Escalated',
    DEPARTURE: 'Departure',
    ARRIVAL: 'Arrival',
    CHECKIN: 'Check-in',
    CHECKOUT: 'Check-out',
    DATE: 'Date',
    BY: 'by',
    // GENERIC PHRASES
    RATE: 'Price',
    IN_TOTAL: 'in total',
    TOTAL_FOR: 'Total for',
    DEPARTURE_ON: 'Departing on',
    RETURN_ON: 'Return on',
    PRICE_DETAIL: 'Price details',
    INCLUDED_SERVICES: 'Included services',
    ON_THE_ACCOUNT_OF: 'On behalf of ...',
    NO_RESULTS_SEARCH: 'No results for your search ...',
    ERROR_OCCURED_TRY_AGAIN: 'An error has occurred, please try again.',
    GARANTEED_UP_TO: 'until',
    WITHOUT_TAXES: 'HT',
    TOTAL_WITH_TAXES: 'Total TTC',
    TO_PRM: 'to people with reduced mobility',
    SUCCESSFULLY: 'successfuly',
    AT_THE_TREEP: 'at the Treep',
    STARTING_FROM: 'from',
    PUBLIC_TRANSPORT: 'Public transport',
    CARBON_FOOTPRINT: 'carbon footprint',
    POOR: 'mediocre',
    POOR_FOOTPRINT() {
      return [this.FOOTPRINT, `${this.CARBON} ${this.MEDIOCRE}`];
    },
    YOUR_PASSWORD: 'Your password',
    TRAVEL_POLICY: 'Travel Policy',
    TO_APPROVE: 'to approve',
    ONLY: 'More than',
    ROUND_TRIP: 'A / R',
    CARDS_EXPIRATION_DATES: 'Your cards\' expiration dates',
    // ACTIONS
    EDIT_SEARCH: 'Edit search',
    RETURN_TO_LIST: 'Return to list',
    DISPLAY_ON_MAP: 'Show on map',
    TO_COMPLETE: 'To be completed',
    ADD_TO_CART: 'Add to cart',
    SEARCH_BTN: 'Go!',
    RENT_A_CAR: 'Rent a car',
    BOOK_A_ROOM: 'Book a room',
    BOOK_A_TRIP: 'Book a trip',
    ADD_SERVICE: 'Add a service',
    EMPTY_CART: 'Empty cart',
    SEE_MY_TICKETS: 'See the list of my tickets',
    RETURN_TO_RESULTS_LIST: 'Return to the list of results',
    GO_BACK: 'Go back',
    ADD_CARD: 'Add a card',
    ADD_ROOM: 'Add a room',
    SEE_DETAILS: 'See details',
    ADD_DOCUMENT: 'Add a document',
    PRECISE_TIME: 'Specify the time',
    SEE_TICKET_RECAP: 'SEE RECAP TICKET',
    VIEW_DETAILS: 'View details',
    // LOADING
    CART_LOADING: 'We are loading your cart',
    FREEING_OPTIONS: 'We free the options',
    LOADING: 'Loading',
    PUTTING_OPTION: 'Setting an option ...',
    // LOGIN SIGNUP
    AUTH_PROBLEM: 'There was a problem with your authentication. Try Again.',
    ACCOUNT_ACTIVATED: 'Account successfully activated!',
    NEW_LINK_SENT_TO_ACTIVATE_ACCOUNT: 'A new link to activate your account has been sent to you by email',
    ERROR_ACTIVATION_LINK: 'Error when requesting the activation link',
    INFORMATIONS_SAVED: 'Your information has been saved',
    PASSWORD_UPDATED: 'Your password has been successfully updated',
    // FORM
    PLEASE_FILL: 'Please fill in this field',
    PHONE_NUMBER_FORMAT: 'Your number must be in the format 0XXXXXXXXX',
    NOT_THAT_OLD: 'You are not so old',
    TOO_YOUNG: 'You are a little too young!',
    ENTER_A_DATE: 'Please enter a date',
    CHOSE_ENTITY: 'Please choose an entity',
    FORMAT_NOT_RECOGNIZED: 'The format is not recognized',
    ENTER_DATE_NOT_PASSED: 'Please enter a date that has not passed',
    ENTER_RETURN_DATE: 'Please enter a return date',
    ENTER_OUTWARD_PLACE: 'Please fill in the starting address',
    ENTER_DESTINATION_PLACE: 'Please fill in the departure address',
    ENTER_RETURN_PLACE: 'Please fill in the return address',
    ENTER_DRIVER: 'Please enter a driver',
    AGGREE_FORM_RESPONSABILITY: 'By checking this box, I certify the accuracy of the data recorded and I am responsible for the consequences of incorrect data entry.',
    I_ACCEPT_MY_DATA_WILL_BE_USED: 'By submitting this form, I agree that the information entered will be used as part of my trip',
    PLEASE_CHECK: 'This box must be checked',
    VALID_DATE: 'Please enter a valid date.',
    ENTER_TIME_NOT_PASSED: 'Please enter a time that has not passed',
    ENTER_DATE_TIME_OUTWARD: 'Please enter a return date / time after the departure date',
    ENTER_ONE_PASSENGER_AT_LEAST: 'Please enter at least one passenger',
    CANT_ENTER_MORE_THAN_FOUR_PASSENGERS: 'You cannot enter more than 4 passengers',
    CARD_NUMBER_REQUIRED: 'A number is required to add a card of this type',
    TYPE_IN_CARD_NAME: 'Enter the name of your card',
    ENTER_PASSED_DATE: 'Please enter an earlier date',
    ENTER_FUTURE_DATE: 'Please enter a future date',
    ENTER_CARD_TYPE: 'Please indicate the type of card',
    ENTER_NUMBERS_ONLY: 'This field only accepts numbers',
    // LABELS
    PASSWORD: 'Password',
    FIRSTNAME: 'First name',
    LASTNAME: 'Name',
    USERNAME: 'Username',
    NO_PREFER_TYPING_INFO: 'no, I prefer to enter my personal information on each trip',
    CHECK_CONFIRM_MY_DATA: 'by checking this box, I certify the accuracy of the data recorded and I am responsible for the consequences of incorrect data entry.',
    MADAM: 'Madam',
    SIR: 'Sir',
    BIRTHDATE: 'Date of birth',
    PHONE_NUMBER: 'Mobile phone number',
    HANDICAP: 'Handicap',
    HAS_HANDICAP: 'In a situation of handicap',
    FILL_YOUR_HANDICAP: 'Enter your handicap',
    WHEELCHAIR: 'Wheelchair',
    WHEELCHAIR_DIMENSIONS: 'Chair dimensions (folded) width x long x high',
    WHEELCHAIR_WEIGHT: 'Wheelchair weight (kg)',
    WHEELS_WIDTH: 'Wheels thickness (cm)',
    INVALIDITY_CARD: 'Disability card with third party mention',
    CONSENT_STORAGE: 'the Treep, in its capacity as data controller, collects and stores your data. If you agree, your information will be used to prevent you from re-entering it each time you request a trip. You can withdraw your consent at any time from your personal space. ',
    DOCUMENTS: 'Documents',
    LOYALTY_AND_REDUCTION_CARDS: 'Loyalty and reduction cards',
    CARD_NAME: 'Card name:',
    CARD_TYPE: 'Card type',
    EXPIRATION_DATE: 'Expiration date',
    DOCUMENT_NAME: 'Document name',
    DOCUMENT_TYPE: 'Document type',
    MAN: 'Man',
    WOMAN: 'Woman',
    NATIONALITY: 'Nationality',
    EFFECTIVE_DATE: 'Date of issue',
    COUNTRY_OF_ISSUE: 'Country of issue',
    ADDRESS: 'Address',
    BIRTH_CITY: 'City of birth',
    BIRTH_COUNTRY: 'Country of birth',
    REASON: 'Reason',
    // FARE CONDITIONS
    CANCELLABLE: 'Cancelable',
    NOT_CANCELLABLE: 'Non cancellable',
    BREAKFAST_INCLUDED: 'Breakfast included',
    BREAKFAST_NOT_INCLUDED: 'Breakfast not included',
    LUNCH_INCLUDED: 'Lunch included',
    DINNER_INCLUDED: 'Dinner included',
    // APPROVAL
    DID_APPROVE: 'have approved',
    DID_NOT_APPROVE: 'did not approve',
    THE_TRAVEL_REQUEST: 'the travel request',
    APPROVAL_VALIDATION_ERROR: 'Sorry, an error occurred and the travel request could not be approved:',
    APPROVAL_VALIDATION_EXPIRED_ERROR: 'Your validation could not be performed because its validity date has expired',
    DISCOVER_THE_TREEP: 'DISCOVER THE TREEP',
    APPROVER: 'Approver',
    // FILTERS AND SORTING
    UNLIMITED_DISTANCE: 'Unlimited distance',
    COMPATIBLE_WITH_CARDS: 'Compatible with loyalty cards',
    PRICE_RANGE: 'Price range',
    SELECT_ALL: 'Select all',
    FILTER_AND_SORT: 'Filter & sort',
    NO_RESULT: 'No result',
    NO_RESULTS: 'No results match your filters. You can edit them to see more results',
    FILTERS: 'Filters',
    LOYALTY_CARD_COMPATIBILE: 'Compatible loyalty cards',
    PRM_ACCESSIBLE: 'Accessible PMR',
    FILTER: 'Filter',
    BY_NAME: 'By name',
    BY_PLACE: 'By place',
    BY_DATE: 'By date',
    BY_STATUS: 'By status',
    ALL: 'All',
    // COMPLIANCE
    COMPLIANT_FARE: 'Price compliant with the travel policy',
    NEGOCIATED_FARE: 'Negotiated price',
    NGO_FARE: 'NGO price',
    NOT_COMPLIANT_FARE: 'Price not compliant with the travel policy',
    CONFORM: 'Compliant',
    NOT_CONFORM: 'Not Compliant',
    // CAR RENTAL
    CAR_RENTAL: 'car rental',
    CAR_RENTALS: 'car rentals',
    RENTER: 'Renter',
    DRIVER: 'Driver',
    CAR_RENTALS_IN: 'Car rentals in',
    FAVORITE_RENTERS: 'Preferred rental companies',
    VEHICLE: 'vehicle',
    VEHICLE_CATEGORY: 'Vehicle category',
    VEHICLE_TYPE: 'Vehicle type',
    VEHICLE_TAKEN: 'Vehicle taken',
    VEHICLE_GIVEN: 'Vehicle given back',
    AGENCY: 'Agency',
    AGENCY_WITHDRAWAL: 'Collection agency',
    AGENCY_DEPOSIT: 'Deposit agency',
    VALET_SERVICE: 'Valet service',
    VALET_WITHDRAWAL: 'Valet service: provision',
    VALET_DEPOSIT: 'Valet service: recovery',
    TP: 'urban car',
    TM: 'compact',
    TG: 'SUV',
    UP: 'small utility',
    UM: '10m3 van',
    UG: '20m3 van',
    CAR: 'Car',
    COMMERCIAL_VEHICLE: 'Utility',
    I_PICK_THE_VEHICLE_AT: 'I take the vehicle in ...',
    I_RETURN_VEHICLE_AT: 'I am returning the vehicle in ...',
    OF_THE_LOCATION_ON: 'of the rental on ...',
    CAR_SEARCH_ERROR: 'Error while searching for car rental',
    OPENING_SCHEDULE: 'Opening hours',
    // SEARCH
    COMPARE_AND_RESERVE: 'Compare and reserve',
    COMPARE_AND_RESERVE_TEXT: 'all trains, planes, taxis, subways…',
    PAYED_AND_APPROVED: 'Your trip paid and approved',
    PAYED_AND_APPROVED_TEXT: 'directly by your company',
    PRESERVE_ENV: 'Preserve the environment',
    PRESERVE_ENV_TEXT: 'by participating in the CO2 savings on the Treep',
    TOTAL_CO2_ECO: 'Total CO₂ savings of the Treep',
    COMPLEMENTARY_SERVICES: 'Complementary services',
    COMPOSE_YOUR_TRIP: 'Compose your trip',
    PRACTICAL_AND_RESPONSIBLE: 'practically and responsibly',
    REDIRECTION_ERROR: 'An error occurred during the redirection',
    TRAVEL_AGENT_MODE: 'Travel agent mode',
    TRIP: 'Trip',
    RENTAL: 'Rental',
    PRESERVE_PLANET_BY: 'By booking your trips on the Treep, you are preserving the planet by promoting environmentally friendly transport and',
    NEUTRALIZING_AUTOMATICALLY: 'automatically neutralizing',
    YOUR_EMISSIONS_BY_PLANTING_TREES: 'your CO2 emissions by planting trees.',
    CHOSE_OFFER: 'Choose this offer',
    NO_MATCHING_RESULTS: 'No matching result',
    CONNECTIONS: 'corresp.',
    DIRECT: 'direct',
    UNAVAILABLE: 'Indispo.',
    OFFERS_WITH_LUGGAGE: 'Offers with luggage available',
    UPGRADED: 'Upgraded to 1st',
    LOGIN_TO_CONTINUE: 'Please register or login to continue.',
    CONNECTION_TIME: 'match',
    SEARCHING: 'Searching ...',
    LETS_GO: 'Come on, let\'s go!',
    SEARCHING_RETURN: 'We are looking for your feedback!',
    SELECT_CATEGORY: 'Select category:',
    SELECT_TIME: 'Select a specific time:',
    SELECT_DATE: 'Select a date:',
    SELECT_DRIVER: 'Select a driver:',
    SELECT_PLACE: 'Select a place:',
    SELECT_THE: 'Select the (s)',
    DELETE_RETURN: 'Delete return',
    SEARCH_EMPLOYEE: 'Search for an employee or a guest',
    CREATE_DRIVER: 'Create a driver',
    IN_A_RADIUS: 'In a radius',
    CAN_INFLUENCE_PRICE: 'may influence the rate according to age',
    // CART
    CONFIRM_DELETE: 'Are you sure you want to delete this ticket?',
    STAY_FROM: 'Stay of',
    LOCATION_FROM: 'Rental from the',
    TRAVEL_FROM: 'Route of',
    PRICE_AND_AVAILABILITY: 'Available',
    VISIT: 'Go to',
    FOR_INFO_ON_TRIP: 'for any information about your trip.',
    PRICE_CAN_CHANGE: 'The individual price of the final tickets may change, but the total price of the return plane and train tickets will remain the same',
    CART_IS_EMPTY: 'Your basket is empty',
    BOOK_ECO_TRIP: 'Book an eco-responsible trip',
    MY_CART: 'My cart',
    PVE_REMINDER: 'Travel Policy Reminder',
    TRIP_CARBON_CANCEL: 'Carbon neutralization of journeys',
    CO2_KG: 'KG of CO2',
    PASSY_CERTIFICATE: 'Certificate of participation in the Massy solidarity orchard planting program',
    TECHNICAL_FEES: '+ Technical costs of the platform',
    BEFORE_TAX_FEES: 'Administrative fees HT',
    INCLUDING_TAXABLE: 'Including taxable',
    TOTAL_BEFORE_TAXES: 'Total HT:',
    TAXES_ON_FEES: 'VAT Taxable administration fees:',
    PART_PAYED_BY_CNAM: 'Part covered by Health Insurance:',
    GRANT: 'Grant',
    TOTAL_INCLUDING_TAXES: 'New total including tax:',
    PRICE_CAN_CHANGE_OF: 'This price may differ on your final ticket but the total price of the outward and return tickets of',
    WILL_STAY_SAME: 'will remain the same',
    DELETING_ROUND_TRIP: 'This ticket is part of a round trip. Both the outward and return tickets will be deleted.',
    // CHECKOUT
    PAYMENT_SUCCESS: 'Payment successfully completed!',
    SECURED_PAYMENT: 'Secure payment',
    CARD_NUMBER: 'Card number',
    CVC_CODE: 'CVC code',
    CARD_HOLDER: 'Card holder',
    I_ACCEPT_THE: 'I accept the',
    OF_THETREEP: 'from The Treep',
    CGV: 'general conditions of sale',
    PAYING: 'Payment in progress ...',
    PAY: 'Pay',
    SECURED_BY_STRIPE: 'Payment is insured and secured by Stripe',
    SSL_PAYMENT: 'Payment is made on a secure SSL payment server',
    BOOKING_AND_PAYMENT: 'Reservation and payment',
    ABOUT_TO_BOOK_COMPANY: 'We are about to book and pay for your transport tickets using your company\'s payment methods.',
    TRIP_IS_CONFORM_TO: 'Your trip complies with',
    THE_PVE: 'the company\'s travel policy',
    TRIP_IS_NOT_CONFORM_TO: 'Your trip does not comply with',
    PLEASE_FILL_NON_CONFORM_REASON: 'Please fill in the reason for non-compliance',
    ANALYTICAL_FIELDS: 'Analytical fields',
    TO_FILL_BELOW_ASTERISK: 'to be completed below (fields with an * are required)',
    VERIFY_ACCESS_CONDITIONS: 'Check the access conditions for your international trip',
    ASK_APPROVAL: 'Request approval',
    CONTINUE_TRAVEL: 'Continue with this travel',
    CONTINUE_TRAVELS: 'Continue with all travels',
    PUT_OPTION: 'Set an option',
    COMPANY_BOOKING: 'Company payment',
    BOOKING_ERROR: 'An error occurred during the reservation.',
    PLEASE_CONTACT_US_ON: 'Please contact us at',
    TO_KNOW_MORE_ABOUT_STATUS: 'to know the status of your reservation.',
    WE_VERIFY_YOUR_INFOS: 'We are verifying your information',
    RESERVED: 'reserved',
    BOOKING_CONFIRMED: 'Booking confirmed!',
    YOUR_BOOKING_IS_CONFIRMED: 'Your reservation is confirmed!',
    YOUR_OPTION_IS_SET: 'Your option is set!',
    SENDING_APPROBATION_REQUEST: 'We are sending the approval request',
    AWAITING_APPROVAL: 'pending approval',
    YOUR_REQUEST_WAS_SENT_TO: 'Your travel request has been sent to',
    YOUR_REQUEST_WAS_ACCEPTED_AUTOMATICALLY: 'Your travel request has been accepted automatically',
    REQUEST_ACCEPTED: 'Request accepted!',
    REQUEST_SENT: 'Request sent !',
    PAY_BY_CARD: 'Pay by credit card',
    CONFIRM_BOOKING: 'Confirm reservation',
    RETURN_TO_TICKET_LIST: 'Return to ticket list',
    ALL_WENT_WELL: 'Everything went well?',
    YOU_HAVE_UP_TO: 'You have until',
    TO_VALIDATE_THIS_OPTION: 'to validate this option',
    TO_HANDLE_THIS_REQUEST: 'to handle this request',
    FARE_CONDITIONS_CAN_CHANGE: 'The price conditions of your trip are likely to change between the time of your option installation and its validation.',
    SEE_TICKETS: 'View my tickets',
    FOR_YOUR_CONTRIBUTION: 'For your contribution to the reduction of greenhouse gas emissions',
    PASS_ANIMATION: 'Skip the animation',
    SECOND_UNAVAILABLE: 'Second class not available',
    MISSION_ORDER_FINALIZATION: 'Mission orders finalization',
    MISSION_ORDER_OF(person) {
      return `${person}'s mission order`;
    },
    MISSION_OBJECT: 'Purpose of the mission',
    MISSION_PLACE: 'Precise location of the mission',
    SEND_APPROVAL_REQUEST: 'Send an approval request',
    LINK_TRANSPORT: 'Link transport',
    LINK_TRANSPORTS: 'Link transport(s)',
    TRANSPORT_FIELD: 'transport - optional',
    OTHER_REMARKS: 'Other transportation or planned expenses',
    START_LOCATION: 'Place of departure',
    END_LOCATION: 'Place of arrival',
    MISSION_ORDER_WILL_BE_GENERATED: 'The mission order will be generated per passenger with all the selected services',
    CREATE_MISSION_ORDER: 'Create a mission order',
    // HEADER
    SEARCH: 'Search',
    CART: 'Cart',
    MY_SPACE: 'My space',
    LOG_IN: 'Connection',
    SIGN_UP: 'Registration',
    LOG_OUT: 'Logout',
    // HOTEL
    HOTEL: 'hotel',
    HOTELS: 'hotels',
    COMFORT: 'Comfort',
    BREAKFAST: 'Breakfast',
    COMFORT_AT_LEAST: 'Comfort at least equal to',
    HOTELS_IN: 'Hotels in',
    SANITARY_NOTE: 'Sanitary note',
    HAS_IMPLEMENTED_SANITARY_MEASURES: 'has implemented various measures to guarantee your health security',
    NORM: 'Standard',
    LOADING_ADDITIONAL_DATA: 'loading additional information',
    CHOSING_ROOMS: 'Choice of rooms',
    ROOM: 'Room',
    ROOMS: 'Rooms',
    MORE_PRICES: 'More prices',
    DISTRIBUTING_PEOPLE_IN_ROOMS: 'Placement of people in rooms',
    PEOPLE_TO_PLACE: 'People to place',
    PRICE_FROM: 'Price from',
    ENV_FRIENDLY_HOTEL: 'Eco-responsible hotel',
    HOTEL_NAME: 'Hotel name',
    EQUALS_AT_LEAST: 'at least equal to',
    HOTEL_SEARCH_ERROR: 'Error while searching for the hotel',
    I_SLEEP_IN: 'I sleep in ...',
    THE_STAY_ON: 'of the stay on ...',
    ANY_HOTEL: 'Any hotel',
    NAME_CONTAINS: 'Name contains',
    STAR: 'star',
    STARS: 'stars',
    LOGIN_IN_TO_ADD_HOTEL_TO_CART: 'You must be logged in order to add a hotel to your cart.',
    TAX_INCLUDED: 'Sojourn tax included',
    TAX_NOT_INCLUDED: 'Sojourn tax not included',
    // MODALS
    ACCESS_TO_BOOKING_PORTAL_CHANGED: 'Access to the booking portal has changed',
    WARNING_ACCOUNT_MOVED: 'Warning, your account has been migrated to the URL',
    PLEASE_USE_THIS_URL: ', please use this new URL to login.',
    GET_THERE: 'Go there',
    ARE_YOU_SURE_YOU_APPROVE: 'Are you sure you want to approve',
    ARE_YOU_SURE_YOU_TRANSFER: 'Are you sure you want to transfer',
    ADD_APPROVER: 'Add an approver',
    CHOSE_APPROVER: 'Approver\'s choice',
    PLEASE_CHOSE_APPROVER: 'Please choose an approver',
    SPARE_APPROVER: 'Backup approver',
    SPARE_APPROVER_INFO: 'The back-up approver is contacted if the lead approver has not responded to the request within half of the specified time. The trip will be confirmed or canceled as soon as one of the approvers responds. ',
    EMAIL_ACTIVATION: 'Email activation',
    ERROR_DURING_ACCOUNT_ACTIVATION: 'An error occurred while activating your account.',
    TREATING_YOUR_REQUEST: 'We will process your request as soon as possible! ',
    VERIFY_CANCEL_CONDITIONS: 'We check the price flexibility of your tickets, the reimbursement conditions and if necessary contact you on your email:',
    HERE_ARE_CONDITIONS: 'Here are the price conditions of your tickets which will be applied',
    WE_CAN_CHECK_CONDITIONS: 'We can check the price flexibility of your tickets, the reimbursement conditions and communicate to you any applicable charges in the event of cancellation by email',
    IF_YOU_CANCEL: 'If you cancel',
    THE_RESERVATIONS: 'reservations',
    ALL_TRAVELERS_WILL_BE_CONCERNED: 'all travelers with you will be affected',
    CONFIRM_CANCEL_REQUEST: 'Confirm cancellation request',
    ABANDON_CANCEL_REQUEST: 'Abandon the cancellation request',
    UPDATE_DOCUMENT: 'Edit document',
    UPDATE_DRIVER: 'Modify driver',
    EMITTED: 'emitted',
    TO_NEUTRALIZE: 'to neutralize',
    BY_A_REFORESTATION_PROGRAM: 'by a reforestation program',
    SAVED: 'spared',
    COMPARED_TO_CAR: 'compared to a classic car',
    UPDATE_TRAVELER: 'Edit this traveler',
    UPDATE_MY_CARDS: 'Update my cards',
    LOG_YOURSELF_IN: 'Log in',
    FORGOTTEN_PASSWORD: 'Forgotten password',
    UPDATE_USER: 'Modify user',
    CREATE_ACCOUNT: 'Create an account',
    USERNAME_NOT_RECOGNIZED: 'Username not recognized.',
    ENTER_YOUR_USERNAME: 'Enter your username',
    UPDATE_PASSWORD: 'Update password',
    UPDATE_PASSENGER: 'Edit this passenger',
    ADD_PASSENGER: 'Add a passenger',
    CREATE_COMPLETE_PROFILE: 'Create a complete profile',
    AN_EMAIL_HAS_BEEN_SENT: 'An email has been sent to you',
    SEE_YOU: 'See you soon!',
    SENDING: 'Sending ...',
    ACCOUNT_CREATED_VALIDATE_EMAIL: 'Your account is created. To validate your registration, click on the link in the email we just sent you on',
    I_GOT_IT: 'I got it',
    REGISTER: 'Register',
    CONSULT_PRIVACY_POLICY: 'Consult our privacy policy',
    OFFLINE_CANCEL: 'Online cancellation is not allowed for this trip, please contact the customer service directly during business hours. If you have a specific after sales contract, please contact the 24/7 number provided directly',
    // ON BOARDING
    INFOS_DELETED: 'Your information has been deleted successfully',
    INFOS_SAVED: 'Your information has been saved successfully',
    ACTIVATION_LINK_EXPIRED: 'This link to activate your account is no longer valid',
    WELCOME_ABOARD: 'Welcome to the Treep',
    CONGRATULATIONS_FIRST_LOGIN: 'Congratulations, this is your first login and you are invited to verify the accuracy, correct or complete the required information to create an account on the Treep.',
    ERROR_HAPPENED: 'An error has occurred',
    FINALIZE_YOUR_SIGNUP: 'Complete your registration',
    CHOSE_TO_STORE_DATA: 'You have chosen to store on the Treep your personal information useful for your future trips. You can check their accuracy, correct or complete them. ',
    // TRIP
    TRAIN: 'train',
    TRAINS: 'trains',
    FLIGHT: 'flight',
    FLIGHTS: 'flights',
    MAIN_TRIP: 'Main transport',
    SEE_OUTWARD_TICKET: 'See my outbound ticket',
    AVAILABLE_OFFERS: 'Available offers',
    HOLD_LUGGAGE: 'checked baggage',
    CABIN_LUGGAGE: 'cabin baggage',
    CONNECTIONS_FULL: 'Connections',
    WITH_LUGGAGE: 'With luggage',
    NO_FLEX: 'No-Flex',
    SEMI_FLEX: 'Semi-Flex',
    FULL_FLEX: 'Full-Flex',
    DEPARTURE_TIME: 'Departure time',
    COMPANIES: 'Companies',
    OUTWARD: 'Go',
    RETURN: 'Return',
    MY_TRIP_ON_THE: 'My Trip from',
    I_LEAVE_FROM: 'I\'m leaving ...',
    I_GO_TO: 'I\'m going to ...',
    LEAVE_AFTER: 'Leave after',
    ARRIVE_BEFORE: 'Arrive before',
    NO_CARDS: 'Without card',
    NO_SPECIAL_FARES: 'No special rate',
    FIRST_CLASS: '1st',
    SECOND_CLASS: '2nd',
    ECONOMY_CLASS: 'Eco',
    PREMIUM_ECONOMY_CLASS: 'Eco Premium',
    LONG_TRAIN: 'Intercity',
    SHUTTLE: 'Shuttle',
    THE_STATION: 'the station',
    BUS_STOP: 'the bus stop',
    TOTAL_DURATION_CONTAINS: 'The total duration includes:',
    TOTAL_DURATION_1: 'The best duration of the main transport',
    TOTAL_DURATION_2: 'An estimate of the time required for checks, embarkation and disembarkation',
    TOTAL_DURATION_3: 'An estimate of the travel time by car between the point of origin and the station or airport concerned',
    TOTAL_DURATION_4: 'An estimate of the travel time by car between the station or the airport and the destination point',
    PRICE_CALCULATION: 'The price displayed is the best price found on the main transport, this does not include the price of the secondary transport between the point of origin and the station or the airport nor the price of the secondary transport between the station or the airport and point of destination ',
    CO2_PODIUM_TOOLTIP_1: 'The ranking considers the journeys that induce the lowest greenhouse gas emissions (in CO2e) for each main transport. These emissions include:',
    CO2_PODIUM_TOOLTIP_2: 'The production and combustion of fossil fuels.',
    CO2_PODIUM_TOOLTIP_3: 'The production of electricity for train operation.',
    CO2_PODIUM_TOOLTIP_4: 'Condensation trails and associated non-CO2 emissions for flights.',
    CO2_PODIUM_TOOLTIP_5: 'Greenhouse gas emissions from the production of equipment and infrastructure are excluded.',
    // MY SPACE
    STATUS: 'Status',
    TYPE: 'Type',
    DATES: 'Dates',
    PLACES: 'Places',
    SCHEDULES: 'Schedules',
    PASSENGER_S: 'Passenger (s)',
    CONDITIONS: 'Conditons',
    PNR_FILE: 'PNR / Folder',
    APPROVER_S: 'Approver (s)',
    DELAY: 'Deadlines',
    COMPLIANCE: 'Compliance',
    COMPLETED_REQUESTS: 'Requests processed',
    NO_APPROBATION_REQUESTS: 'You have no requests pending approval.',
    SURE_DELETE_CARD: 'Are you sure you want to delete this card?',
    MY_CARDS: 'My cards',
    CO2_SUMMARY_OF_YOUR_TRIPS: 'CO2 balance of your Treeps',
    ST: 'st',
    JANUARY_TO_TODAY: 'January - today',
    CO2_ECO: 'CO2 savings',
    CO2_EMISSIONS: 'CO2 emissions',
    COMPENSATION: 'Compensation',
    KM_SUMMARY_OF_YOUR_TRIPS: 'Mileage report of your Treeps',
    TRIPS_COUNT: 'Number of treeps',
    DISTANCE_COVERED: 'Distance traveled',
    DELELTE_OPTIONAL_DATA: 'Delete my optional information',
    DELETE_ACCOUNT: 'Delete my account',
    UPDATE: 'Update',
    MY_DATA: 'My information',
    FAILOVER: 'emergency',
    BOOKINGS_AWAITING_APPROVAL: 'Reservations pending approval',
    REMIND_APPROVER: 'Relaunch the approver',
    ASK_ANOTHER_APPROVER: 'Ask another approver',
    NO_RECENT_TICKETS: 'You have no recent reservations.',
    ARCHIVED_BOOKINGS: 'Archived reservations',
    NO_ARCHIVE: 'You have no archive',
    NEW_APPROVER: 'New approver',
    ERROR_LOADING_BOOKINGS: 'There was a problem loading your reservations',
    ERROR_LOADING_APPROVALS: 'There was a problem loading your approvals',
    APPROVAL_REQUEST_SENT_TO: 'Approval request successfully sent to',
    PROBLEM_DURING_APPROVAL: 'A problem occurred while requesting approval',
    SURE_REMIND_APPROVER: 'Are you sure you want to relaunch your approver?',
    APPROVER_REMINDED: 'Your approver has been successfully relaunched.',
    TICKETS_DELETED: 'Ticket (s) deleted successfully.',
    PROBLEM_DELETING: 'A problem occurred while requesting deletion.',
    ARE_YOU_SURE_TO_DENY: 'Are you sure you want to refuse',
    DENIAL_REASON: 'Reason of your refusal',
    REASON: 'Reason...',
    REFUSE: 'Refuse',
    DELETE_REQUEST_SENT: 'Cancellation request successfully completed',
    PROBLEM_DURING_DELETING: 'A problem occurred during the cancellation request',
    DOCUMENTS_UPDATED: 'Documents well updated',
    ERROR_DURING_SAVE: 'An error occurred while saving',
    SNCF_CARDS: 'SNCF reduction cards',
    SNCF_CARDS_STARTING_FROM: 'From 12/10/2020, it is necessary to specify the number of your SNCF reduction cards.',
    SNCF_CARDS_VISIT: 'From 12/10/2020, it is necessary to specify the number of your SNCF reduction cards.',
    CARD_UPDATED: 'Cards updated successfuly',
    CONFIRM_DATA_DELETE_MESSAGE: 'Deleting your data will require you to manually enter your travel data with each booking. Are you sure you want to delete your data? ',
    DATA_DELETED: 'Your information has been deleted successfully.',
    CONFIRM_ACCOUNT_DELETE_MESSAGE: 'The deletion of your account is permanent, you will lose all your travel data and cannot go back. Are you sure you want to delete your account? ',
    ACCOUNT_DELETED: 'Your account has been deleted successfully.',
    MY_BOOKINGS: 'My reservations',
    MY_ARCHIVES: 'My archives',
    PERSONAL_DATA: 'Personal data',
    LOYALTY_AND_SUBSCRIBTION_CARDS: 'Discount or subscription cards',
    APPROVAL_REQUESTS: 'Approval requests',
    DASHBOARD: 'Dashboard',
    ERRORED: 'In error',
    CANCELLED: 'Canceled',
    PENDING_CANCEL: 'In the process of being canceled',
    PARTIALLY_CANCELLED: 'Partially canceled',
    PENDING_CONFIRMATION: 'Options to be confirmed',
    PENDING_APPROVAL: 'Pending approval',
    IN_CART: 'To cart',
    PARTIALLY_BOOKED: 'Partially reserved',
    ISSUED: 'Reserved ticket issued',
    PARTIALLY_CONFIRMED: 'Partially confirmed',
    PENDING_TICKETING: 'Waiting for transmission',
    PARTIALLY_TICKETED: 'Partially issued',
    PENDING_PAYMENT: 'Pending payment',
    PAID: 'Paid',
    PENDING_REFUND: 'Pending reimbursement',
    PARTIALLY_REFUNDED: 'Partially refunded',
    REFUNDED: 'Refunded',
    REMOVED: 'Deleted',
    MODIFIED: 'Modified',
    ARCHIVED: 'Archived',
    UNKNOWN_STATUS: 'Status unknown',
    PASSPORT: 'Passport',
    ID_CARD: 'Identity card',
    DRIVER_LICENSE: 'Driver\'s license',
    NO_DOCUMENTS: 'You have no document.',
    APP_LANG: 'Language',
    FRENCH: 'French',
    ENGLISH: 'English',
    CANCEL_REQUIRES_AGENT: 'This cancellation requires the intervention of one of our travel agents and can only be done during the opening hours of the agency. We will process your request as quickly as possible. However, if you have a 24/7 support contract, we invite you to contact the dedicated number directly.',
    ANSWERED: 'Answered',
    REQUESTED_BY: 'Requested by',
    NOT_CONFORM_REASON: 'Reason for non-compliance with the company\'s travel policy',
    // USES
    SESSION_EXPIRED: 'Your session has expired. Please reconnect. ',
    USER_ALREADY_EXISTS: 'An account already exists with this email address.',
    INVALID_SUBSIDY_NUMBER: 'Invalid request number',
    ERROR_TRAIN_FULL: 'Your reservation could not be completed because the train you want is unfortunately full.',
    ERROR_INVALID_CARD: 'Your reservation could not be completed because your loyalty card is not valid.',
    ERROR_LASTNAME: 'Your reservation could not be completed because the last name is not valid.',
    ERROR_EMPTY_BIRTHDATE: 'Your profile is incomplete to be able to reserve (your date of birth is empty)',
    ERROR_AIRPLANE_FULL: 'Your reservation could not be completedbecause the flight you want is unfortunately full. ',
    BOAT: 'boat',
    // DISABILITY
    MEDA: 'MEDA: Medical agreement - stretcher only',
    BLND: 'BLND: blind or visually impaired',
    DEAF: 'DEAF: hearing or hearing impaired',
    WCH: 'WCH: wheelchair',
    WCHR: 'WCHR: wheelchair - can move around alone on the ground and in the cabin, up and down stairs',
    WCHS: 'WCHS: wheelchair - can move around alone on the floor and in the cabin, but no stairs',
    WCHC: 'WCHC: wheelchair - cannot move alone, must be carried to its seat in the cabin.',
    STCR: 'STCR: presence of stretchers.',
    EXST: 'EXST: need one or more additional seats for medical reasons or personal comfort.',
    DPNA: 'DPNA: affected by a mental disability requiring assistance',
    // ERROR
    SORRY_ERROR: 'Sorry an error occured!',
    BACK_TO_LIGHT: 'Back to the light',
    SHOW_DETAILS: 'Show details',
    // MISC
    COVID_INFO: 'COVID-19 Info',
    COVID_INFO_TEXT: 'Check the terms for international travel',
    YOU_ARE_GOING_TO: 'You want to leave for',
    YOU_ARE_GOING_BACK_TO: 'You want to go back to',
    BARRIER_GESTURES: 'During your trip, we recommend that you adopt the right reflexes for your health:',
    DISTANCE_AWAY(distance) {
      return `${distance} away`;
    },
    UPDATE_AVAILABLE: 'A new version of the Treep is available !',
    TRIGGER_UPDATE: 'Update the Treep',
    DENY: 'Deny',
    ESCALATE: 'Transfer',
    DEMO_MODE: 'Demo mode',
  },
};

export default text;
