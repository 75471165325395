import React from 'react';
import { Link } from 'react-router-dom';
import UserMenu from './UserMenu';

class Profile extends React.Component {
    handleLogout = () => (
      this.props.logout()
    )

    render() {
      if (!this.props.user) {
        return (
          <div className="profile">
            <div className="profil-title">
              {this.props.loginLink
                ? (
                  <Link data-testid="login-button" className="home-profile" to={this.props.loginLink}>
                    {' '}
                    {window.i18('LOG_IN')}
                    {' '}
                  </Link>
                )
                : (
                  <a data-testid="login-button" className="home-profile" onClick={this.props.openLoginModal}>
                    {' '}
                    {window.i18('LOG_IN')}
                    {' '}
                  </a>
                )}
              /
              {this.props.signupLink
                ? (
                  <Link data-testid="register-button" className="home-profile" to={this.props.signupLink}>
                    {' '}
                    {window.i18('SIGN_UP')}
                    {' '}
                  </Link>
                )
                : (
                  <a data-testid="register-button" className="home-profile" onClick={this.props.openSignupModal}>
                    {' '}
                    {window.i18('SIGN_UP')}
                    {' '}
                  </a>
                )}

            </div>
          </div>
        );
      }

      return (
        <div className="nav-section profile" onClick={this.handleClick}>
          <a className="profile-name header-link">
            <span data-testid="header-user-firstname" className="link-label">{this.props.user.firstname}</span>
          </a>
          <UserMenu
            onLogout={this.handleLogout}
          />
        </div>
      );
    }
}

export default Profile;
