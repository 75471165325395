import { combineReducers } from 'redux';
import moment from 'moment';

import {
  SET_PICK_UP_LOCATION,
  SET_PICK_UP_LOCATION_TOUCHED,
  SET_RETURN_LOCATION,
  SET_RETURN_LOCATION_TOUCHED,
  SET_PICK_UP_DATE,
  SET_PICK_UP_DATE_TOUCHED,
  SET_RETURN_DATE,
  SET_RETURN_DATE_TOUCHED,
  SET_CAR_RENTAL_TIME,
  EDIT_DRIVER,
  ADD_DRIVER,
  SELECT_DRIVER,
  SET_FORM_ERRORS,
  FILL_USER_INFO,
  SET_CAR_TYPES,
  ADD_LAST_TRAVELERS,
  SET_ENTITY_TOUCHED,
  SET_SEARCH_PARAMS,
} from './constants';
import { SET_USER_DOCUMENTS_SUCCESS } from '../UserAccount/constants';

function pickUpLocationReducer(state = { location: null }, { type, location }) {
  switch (type) {
    case SET_PICK_UP_LOCATION:
    case SET_SEARCH_PARAMS:
      return {
        ...state,
        location,
      };
    default: return state;
  }
}

function returnLocationReducer(state = { location: null }, { type, location }) {
  switch (type) {
    case SET_RETURN_LOCATION:
    case SET_SEARCH_PARAMS:
      return {
        ...state,
        location,
      };
    default: return state;
  }
}

// Each trip gets a key (a & r), with the same reducer
function dateReducer(
  state = {
    a: {
      date: moment().set({ hour: 11, minute: 0, seconds: 0 }),
      time: '090000',
    },
    r: {
      date: null,
      time: '090000',
    },
  },
  action,
) {
  switch (action.type) {
    case SET_PICK_UP_DATE:
      return {
        ...state,
        a: {
          ...state.a,
          date: action.date,
        },
      };
    case SET_RETURN_DATE:
      return {
        ...state,
        r: {
          ...state.r,
          date: action.date,
        },
      };
    case SET_CAR_RENTAL_TIME:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          time: action.time,
        },
      };
    case SET_SEARCH_PARAMS:
      return {
        a: {
          time: action.date.a.time || state.a.time,
          date: action.date.a.date,
        },
        r: {
          time: action.date.r.time || state.r.time,
          date: action.date.r.date,
        },
      };
    default:
      return state;
  }
}

const formatDriver = (driver, id, selected = false) => ({
  birthdate: driver.birthdate,
  email: driver.email,
  firstname: driver.firstname,
  gender: driver.gender,
  lastname: driver.lastname,
  phone: driver.phone,
  uid: driver.uid ?? undefined,
  address: driver.address,
  birth_country: driver.birth_country,
  city_of_birth: driver.city_of_birth,
  id: id !== undefined ? id : driver.id,
  license: (driver.travel_documents && driver.travel_documents.find((doc) => doc.type === 'driver_license')) ?? null,
  selected,
});

function driversReducer(
  state = [],
  action,
) {
  switch (action.type) {
    case ADD_DRIVER: {
      const existingIndex = state.findIndex((driver) => driver.uid && driver.uid === action.driver.uid);
      if (existingIndex > -1) {
        state.splice(existingIndex, 1);
      }
      return [
        formatDriver(action.driver, state.length, action.select),
        ...state,
      ];
    }
    case EDIT_DRIVER:
      return state.map((driver) => {
        if (driver.id === action.driver.id) {
          return action.driver;
        }
        return driver;
      });
    case FILL_USER_INFO:
      return [
        formatDriver(action.driver, state.length, true),
        ...state.filter((driver) => driver.uid !== action.driver.uid).map((driver) => {
          driver.selected = false;
          return driver;
        }),
      ];
    case ADD_LAST_TRAVELERS:
      action.travelers.forEach((traveler) => {
        state.push(formatDriver(traveler, state.length));
      });
      return state;
    case SET_USER_DOCUMENTS_SUCCESS:
      return state.map((driver) => {
        const document = action.documents.find((doc) => doc.type === 'driver_license');
        if (action.uid === driver.uid) {
          return {
            ...driver,
            license: document,
          };
        }
        return driver;
      });
    case SELECT_DRIVER:
      return state.map((driver) => ({
        ...driver,
        selected: action.id === driver.id,
      }));
    case SET_SEARCH_PARAMS:
      return action.drivers;
    default:
      return state;
  }
}

function carTypesReducer(state = ['TP'], action) {
  switch (action.type) {
    case SET_CAR_TYPES:
      return action.carTypes;
    default:
      return state;
  }
}

function errorsReducer(state = null, action) {
  switch (action.type) {
    case SET_FORM_ERRORS:
      return action.errors;
    default:
      return state;
  }
}

function touchedReducer(state = {}, { type }) {
  switch (type) {
    case SET_PICK_UP_LOCATION_TOUCHED:
      return {
        ...state,
        pickUpLocation: true,
      };
    case SET_RETURN_LOCATION_TOUCHED:
      return {
        ...state,
        returnLocation: true,
      };
    case SET_PICK_UP_DATE_TOUCHED:
      return {
        ...state,
        pickUpDate: true,
      };
    case SET_RETURN_DATE_TOUCHED:
      return {
        ...state,
        returnDate: true,
      };
    case SET_ENTITY_TOUCHED:
      return {
        ...state,
        entityID: true,
      };
    default:
      return state;
  }
}

const defaultActionsState = {
  userFilled: false,
  lastTravelersAdded: false,
};

function actionsReducer(state = defaultActionsState, action) {
  switch (action.type) {
    case FILL_USER_INFO:
      return {
        ...state,
        userFilled: true,
      };
    case ADD_LAST_TRAVELERS:
      return {
        ...state,
        lastTravelersAdded: true,
      };
    default: return state;
  }
}

function travelIDReducer(state = null, action) {
  switch (action.type) {
    case SET_SEARCH_PARAMS:
      return action.travelID;
    default:
      return state;
  }
}

const carRentalSearchReducer = combineReducers({
  travelID: travelIDReducer,
  pickUpLocation: pickUpLocationReducer,
  returnLocation: returnLocationReducer,
  date: dateReducer,
  drivers: driversReducer,
  carTypes: carTypesReducer,
  errors: errorsReducer,
  touched: touchedReducer,
  actions: actionsReducer,
});

export default carRentalSearchReducer;
