export default [
  { value: 'ad', label: 'Andorre' },
  { value: 'ae', label: 'Émirats Arabes Unis' },
  { value: 'af', label: 'Afghanistan' },
  { value: 'ag', label: 'Antigua-Et-Barbuda' },
  { value: 'ai', label: 'Anguilla' },
  { value: 'al', label: 'Albanie' },
  { value: 'am', label: 'Arménie' },
  { value: 'ao', label: 'Angola' },
  { value: 'ap', label: 'Région Asie/Pacifique' },
  { value: 'aq', label: 'Antarctique' },
  { value: 'ar', label: 'Argentine' },
  { value: 'as', label: 'Samoa Américaines' },
  { value: 'at', label: 'Autriche' },
  { value: 'au', label: 'Australie' },
  { value: 'aw', label: 'Aruba' },
  { value: 'ax', label: 'Îles Åland' },
  { value: 'az', label: 'Azerbaïdjan' },
  { value: 'ba', label: 'Bosnie-Herzégovine' },
  { value: 'bb', label: 'Barbad' },
  { value: 'bd', label: 'Bangladesh' },
  { value: 'be', label: 'Belgique' },
  { value: 'bf', label: 'Burkina Faso' },
  { value: 'bg', label: 'Bulgarie' },
  { value: 'bh', label: 'Bahreïn' },
  { value: 'bi', label: 'Burundi' },
  { value: 'bj', label: 'Bénin' },
  { value: 'bl', label: 'Saint-Barthélemy' },
  { value: 'bm', label: 'Bermudes' },
  { value: 'bn', label: 'Brunei Darussalam' },
  { value: 'bo', label: 'État Plurinational De Bolivie' },
  { value: 'bq', label: 'Bonaire, Saint-Eustache Et Saba' },
  { value: 'br', label: 'Brésil' },
  { value: 'bs', label: 'Bahamas' },
  { value: 'bt', label: 'Bhoutan' },
  { value: 'bv', label: 'Île Bouvet' },
  { value: 'bw', label: 'Botswana' },
  { value: 'by', label: 'Biélorussie' },
  { value: 'bz', label: 'Belize' },
  { value: 'ca', label: 'Canada' },
  { value: 'cc', label: 'Îles Cocos' },
  { value: 'cd', label: 'République Démocratique Du Congo' },
  { value: 'cf', label: 'République Centrafricaine' },
  { value: 'cg', label: 'Congo' },
  { value: 'ch', label: 'Suisse' },
  { value: 'ci', label: "Côte D'Ivoire" },
  { value: 'ck', label: 'Îles Cook' },
  { value: 'cl', label: 'Chili' },
  { value: 'cm', label: 'Cameroun' },
  { value: 'cn', label: 'Chine' },
  { value: 'co', label: 'Colombie' },
  { value: 'cr', label: 'Costa Rica' },
  { value: 'cu', label: 'Cuba' },
  { value: 'cv', label: 'Cap-Vert' },
  { value: 'cw', label: 'Curaçao' },
  { value: 'cx', label: 'Île Christmas' },
  { value: 'cy', label: 'Chypre' },
  { value: 'cz', label: 'République Tchèque' },
  { value: 'de', label: 'Allemagne' },
  { value: 'dj', label: 'Djibouti' },
  { value: 'dk', label: 'Denmark' },
  { value: 'dm', label: 'Dominique' },
  { value: 'do', label: 'République Dominicaine' },
  { value: 'dz', label: 'Algérie' },
  { value: 'ec', label: 'Équateur' },
  { value: 'ee', label: 'Estonie' },
  { value: 'eg', label: 'Égypte' },
  { value: 'eh', label: 'Sahara Occidental' },
  { value: 'er', label: 'Érythrée' },
  { value: 'es', label: 'Espagne' },
  { value: 'et', label: 'Éthiopie' },
  { value: 'eu', label: 'Europe' },
  { value: 'fi', label: 'Finlande' },
  { value: 'fj', label: 'Fidji' },
  { value: 'fk', label: 'Îles Malouines' },
  { value: 'fm', label: 'États Fédérés De Micronésie' },
  { value: 'fo', label: 'Îles Féroé' },
  { value: 'fr', label: 'France' },
  { value: 'ga', label: 'Gabon' },
  { value: 'gb', label: 'Royaume-Uni' },
  { value: 'gd', label: 'Grenade' },
  { value: 'ge', label: 'Géorgie' },
  { value: 'gf', label: 'Guyane' },
  { value: 'gg', label: 'Guernesey' },
  { value: 'gh', label: 'Ghana' },
  { value: 'gi', label: 'Gibraltar' },
  { value: 'gl', label: 'Groenland' },
  { value: 'gm', label: 'Gambie' },
  { value: 'gn', label: 'Guinée' },
  { value: 'gp', label: 'Guadeloupe' },
  { value: 'gq', label: 'Guinée Équatoriale' },
  { value: 'gr', label: 'Grèce' },
  { value: 'gs', label: 'Géorgie Du Sud-Et-Les Îles Sandwich Du Sud' },
  { value: 'gt', label: 'Guatemala' },
  { value: 'gu', label: 'Guam' },
  { value: 'gw', label: 'Guinée-Bissau' },
  { value: 'gy', label: 'Guyana' },
  { value: 'hk', label: 'Hong Kong' },
  { value: 'hm', label: 'Îles Heard-Et-MacDonald' },
  { value: 'hn', label: 'Honduras' },
  { value: 'hr', label: 'Croatie' },
  { value: 'ht', label: 'Haïti' },
  { value: 'hu', label: 'Hongrie' },
  { value: 'id', label: 'Indonésie' },
  { value: 'ie', label: 'Irlande' },
  { value: 'il', label: 'Israël' },
  { value: 'im', label: 'Île De Man' },
  { value: 'in', label: 'Inde' },
  { value: 'io', label: "Territoire Britannique De L'océan Indien" },
  { value: 'iq', label: 'Irak' },
  { value: 'ir', label: "République Islamique D'Iran" },
  { value: 'is', label: 'Islande' },
  { value: 'it', label: 'Italie' },
  { value: 'je', label: 'Jersey' },
  { value: 'jm', label: 'Jamaïque' },
  { value: 'jo', label: 'Jordanie' },
  { value: 'jp', label: 'Japon' },
  { value: 'ke', label: 'Kenya' },
  { value: 'kg', label: 'Kirghizistan' },
  { value: 'kh', label: 'Cambodge' },
  { value: 'ki', label: 'Kiribati' },
  { value: 'km', label: 'Comores' },
  { value: 'kn', label: 'Saint-Christophe-et-Niévès' },
  { value: 'kp', label: 'République Populaire Démocratique De Corée' },
  { value: 'kr', label: 'République De Corée' },
  { value: 'kw', label: 'Koweït' },
  { value: 'ky', label: 'Îles Caïmans' },
  { value: 'kz', label: 'Kazakhstan' },
  { value: 'la', label: 'République Démocratique Populaire Lao' },
  { value: 'lb', label: 'Liban' },
  { value: 'lc', label: 'Sainte-Lucie' },
  { value: 'li', label: 'Liechtenstein' },
  { value: 'lk', label: 'Sri Lanka' },
  { value: 'lr', label: 'Liberia' },
  { value: 'ls', label: 'Lesotho' },
  { value: 'lt', label: 'Lituanie' },
  { value: 'lu', label: 'Luxembourg' },
  { value: 'lv', label: 'Lettonie' },
  { value: 'ly', label: 'Libye' },
  { value: 'ma', label: 'Maroc' },
  { value: 'mc', label: 'Monaco' },
  { value: 'md', label: 'République De Moldavie' },
  { value: 'me', label: 'Monténégro' },
  { value: 'mf', label: 'Saint-Martin (Partie Française)' },
  { value: 'mg', label: 'Madagascar' },
  { value: 'mh', label: 'Îles Marshall' },
  { value: 'mk', label: 'Macédoine' },
  { value: 'ml', label: 'Mali' },
  { value: 'mm', label: 'Birmanie' },
  { value: 'mn', label: 'Mongolie' },
  { value: 'mo', label: 'Macao' },
  { value: 'mp', label: 'Îles Mariannes Du Nord' },
  { value: 'mq', label: 'Martinique' },
  { value: 'mr', label: 'Mauritanie' },
  { value: 'ms', label: 'Montserrat' },
  { value: 'mt', label: 'Malte' },
  { value: 'mu', label: 'Maurice' },
  { value: 'mv', label: 'Maldives' },
  { value: 'mw', label: 'Malawi' },
  { value: 'mx', label: 'Mexique' },
  { value: 'my', label: 'Malaisie' },
  { value: 'mz', label: 'Mozambique' },
  { value: 'na', label: 'Namibie' },
  { value: 'nc', label: 'Nouvelle-Calédonie' },
  { value: 'ne', label: 'Niger' },
  { value: 'nf', label: 'Île Norfolk' },
  { value: 'ng', label: 'Nigéria' },
  { value: 'ni', label: 'Nicaragua' },
  { value: 'nl', label: 'Pays-Bas' },
  { value: 'no', label: 'Norvège' },
  { value: 'np', label: 'Népal' },
  { value: 'nr', label: 'Nauru' },
  { value: 'nu', label: 'Niue' },
  { value: 'nz', label: 'Nouvelle-Zélande' },
  { value: 'om', label: 'Oman' },
  { value: 'pa', label: 'Panama' },
  { value: 'pe', label: 'Pérou' },
  { value: 'pf', label: 'Polynésie Française' },
  { value: 'pg', label: 'Papouasie-Nouvelle-Guinée' },
  { value: 'ph', label: 'Philippines' },
  { value: 'pk', label: 'Pakistan' },
  { value: 'pl', label: 'Pologne' },
  { value: 'pm', label: 'Saint-Pierre-Et-Miquelon' },
  { value: 'pn', label: 'Pitcairn' },
  { value: 'pr', label: 'Porto Rico' },
  { value: 'ps', label: 'Territoires Palestiniens Occupés' },
  { value: 'pt', label: 'Portugal' },
  { value: 'pw', label: 'Palaos' },
  { value: 'py', label: 'Paraguay' },
  { value: 'qa', label: 'Qatar' },
  { value: 're', label: 'Réunion' },
  { value: 'ro', label: 'Roumanie' },
  { value: 'rs', label: 'Serbie' },
  { value: 'ru', label: 'Fédération De Russie' },
  { value: 'rw', label: 'Rwanda' },
  { value: 'sa', label: 'Arabie Saoudite' },
  { value: 'sb', label: 'Îles Salomon' },
  { value: 'sc', label: 'Seychelles' },
  { value: 'sd', label: 'Soudan' },
  { value: 'se', label: 'Suède' },
  { value: 'sg', label: 'Singapour' },
  { value: 'sh', label: 'Sainte-Hélène' },
  { value: 'si', label: 'Slovénie' },
  { value: 'sj', label: 'Svalbard Et Jan Mayen' },
  { value: 'sk', label: 'Slovaquie' },
  { value: 'sl', label: 'Sierra Leone' },
  { value: 'sm', label: 'Saint-Marin' },
  { value: 'sn', label: 'Sénégal' },
  { value: 'so', label: 'Somalie' },
  { value: 'sr', label: 'Suriname' },
  { value: 'ss', label: 'Soudan Du Sud' },
  { value: 'st', label: 'Sao Tomé-Et-Principe' },
  { value: 'sv', label: 'République Du Salvador' },
  { value: 'sx', label: 'Saint-Martin (Partie Néerlandaise)' },
  { value: 'sy', label: 'République Arabe Syrienne' },
  { value: 'sz', label: 'Swaziland' },
  { value: 'tc', label: 'Îles Turks-Et-Caïcos' },
  { value: 'td', label: 'Tchad' },
  { value: 'tf', label: 'Terres Australes Françaises' },
  { value: 'tg', label: 'Togo' },
  { value: 'th', label: 'Thaïlande' },
  { value: 'tj', label: 'Tadjikistan' },
  { value: 'tk', label: 'Tokelau' },
  { value: 'tl', label: 'Timor-Leste' },
  { value: 'tm', label: 'Turkménistan' },
  { value: 'tn', label: 'Tunisie' },
  { value: 'to', label: 'Tonga' },
  { value: 'tr', label: 'Turquie' },
  { value: 'tt', label: 'Trinité-Et-Tobago' },
  { value: 'tv', label: 'Tuvalu' },
  { value: 'tw', label: 'Taïwan' },
  { value: 'tz', label: 'République-Unie De Tanzanie' },
  { value: 'ua', label: 'Ukraine' },
  { value: 'ug', label: 'Ouganda' },
  { value: 'um', label: 'Îles Mineures Éloignées Des États-Unis' },
  { value: 'us', label: 'États-Unis' },
  { value: 'uy', label: 'Uruguay' },
  { value: 'uz', label: 'Ouzbékistan' },
  { value: 'va', label: 'Saint-Siège (État De La Cité Du Vatican)' },
  { value: 'vc', label: 'Saint-Vincent-Et-Les Grenadines' },
  { value: 've', label: 'Venezuela' },
  { value: 'vg', label: 'Îles Vierges Britanniques' },
  { value: 'vi', label: 'Îles Vierges Des États-Unis' },
  { value: 'vn', label: 'Viet Nam' },
  { value: 'vu', label: 'Vanuatu' },
  { value: 'wf', label: 'Wallis Et Futuna' },
  { value: 'ws', label: 'Samoa' },
  { value: 'ye', label: 'Yémen' },
  { value: 'yt', label: 'Mayotte' },
  { value: 'za', label: 'Afrique Du Sud' },
  { value: 'zm', label: 'Zambie' },
  { value: 'zw', label: 'Zimbabwe' },
];
