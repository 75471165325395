import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { sortApproversList } from '../../../utils/approvers-utils';
import Select from '../../../components/Inputs/MaterialSelect';

import {
  requestOptionAndApproval,
  requestApproval,
  triggerCheckout,
} from '../../Checkout/actions';
import { getSvgIcon } from '../../../utils/icons';

function ApproversList(props) {
  const handleSubmit = (values) => {
    const {
      approver,
      emergencyApprover,
      message,
    } = values;
    const {
      travelIDs,
      fields,
      exemption,
      missionOrderData,
    } = props;
    if (props.handleSubmit) { // override handleSubmit (for example when only adding an approver)
      props.handleSubmit(approver);
      return;
    }
    if (props.checkoutAmount) { // not already on checkout page => First trigger checkout next do approval
      props.triggerCheckout(props.tickets, props.checkoutAmount);
    }
    if (props.existingOption != null) {
      props.requestApproval(
        travelIDs, approver, emergencyApprover, [fields], message, exemption,
        missionOrderData,
      );
    } else {
      props.requestOptionAndApproval(null, fields, travelIDs, approver,
        emergencyApprover, message, exemption, missionOrderData);
    }
    props.closeModal();
  };

  return (
    <div className="user-modal">
      <div className="modal-with-tagline">
        <header className="tagline">
          <h1 className="common-title">
            {props.addingNew ? window.i18(
              'ADD_APPROVER',
            ) : window.i18('CHOSE_APPROVER')}
          </h1>
        </header>
      </div>
      <div className="modal-inner approvers">
        <Formik
          initialValues={{
            approver: '',
            emergencyApprover: '',
            message: '',
          }}
          validate={(values) => {
            const errors = {};
            if (values.approver === '') {
              errors.approver = window.i18('PLEASE_CHOSE_APPROVER');
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form className="approvers-form">
              <div className="row">
                <Select
                  error={errors?.approver}
                  touched={touched?.approver}
                  name="approver"
                  value={values.approver}
                  label={window.i18('APPROVER')}
                  options={sortApproversList([...props.approvers])}
                  onChange={(value) => setFieldValue('approver', value)}
                  nullable
                />
              </div>
              {(props.addingNew || props.user.company.config?.approvalType
                === 'cascade' || props.user.company.config?.approvalType
                === 'escalation') && !props.withSecondary ? null : (
                  <>
                    <div className="row m-top">
                      <Select
                        errors={errors}
                        name="emergencyApprover"
                        value={values.emergencyApprover}
                        label={window.i18('SPARE_APPROVER')}
                        options={sortApproversList([...props.approvers]).filter(
                          (option) => option.value !== values.approver,
                        )}
                        onChange={(value) => setFieldValue('emergencyApprover',
                          value)}
                        optional
                        nullable
                      />
                    </div>
                    <div className="modal-info">
                      <div className="modal-info__icon">
                        {getSvgIcon('information', 'ui/')}
                      </div>
                      <p className="modal-info__text">
                        {window.i18(
                          'SPARE_APPROVER_INFO',
                        )}
                      </p>
                    </div>
                  </>
                )}
              <p style={{ fontWeight: 300 }}>
                Message -
                {' '}
                <i>optionnel</i>
              </p>
              <textarea
                name="message"
                id="message"
                onChange={(e) => setFieldValue('message', e.target.value)}
                style={{
                  width: '100%',
                  color: '#4A5875',
                  padding: '8px',
                }}
                rows="5"
              />
              <section className="approvers-form__buttons row">
                <div className="col col-6">
                  <button
                    type="button"
                    className="button-secondary"
                    onClick={props.closeModal}
                  >
                    {window.i18('CANCEL')}
                  </button>
                </div>
                <div className="col col-6">
                  <button
                    className="button-main"
                    type="submit"
                  >
                    {window.i18('SEND')}
                  </button>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  approbation: state.checkout.approbation,
  user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
  requestOptionAndApproval: (
    paymentToken, fields, travelIDs, approverUsername, emergencyApprover,
    message, exemption, missionOrderData,
  ) => dispatch(
    requestOptionAndApproval(paymentToken, fields, travelIDs, approverUsername,
      emergencyApprover, message, exemption, missionOrderData),
  ),
  requestApproval: (
    travelIDs, approverUsername, emergencyApprover, analytics, message,
    exemption, missionOrderData,
  ) => dispatch(
    requestApproval(travelIDs, approverUsername, emergencyApprover, analytics,
      message, exemption, missionOrderData),
  ),
  triggerCheckout: (tickets, amount) => dispatch(
    triggerCheckout(tickets, amount),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApproversList);
