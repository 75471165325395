import React from 'react';
import Leaf from './Leaf';

export default class GreenScore extends React.Component {
  start = null;

  constructor(props) {
    super(props);

    this.state = {
      completion: 0,
    };
  }

  componentDidMount() {
    if (this.props.loader) { this.loop(); }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.requestAnimationFrameID);
  }

    loop = (timestamp) => {
      if (!this.start) { this.start = timestamp; }

      const delta = timestamp - this.start;

      if (delta >= 2000) {
        this.start = timestamp;
        return requestAnimationFrame(this.loop);
      }

      let completion = (delta / 2000) * 100;

      completion = Math.round(completion, 2);

      if (this.state.completion !== completion) {
        this.setState({
          completion,
        });
      }

      this.requestAnimationFrameID = requestAnimationFrame(this.loop);
    }

    generateLeaves(n = 5, completion) {
      completion = completion || this.props.completion;

      let totalCompletion = completion / 100;
      let currentLeafScore = 0;
      const leaves = [];

      for (let i = 0; i < n; i += 1) {
        if (totalCompletion >= 1 / n) { currentLeafScore = 1 / n; } else { currentLeafScore = totalCompletion; }
        totalCompletion -= currentLeafScore;
        currentLeafScore = currentLeafScore.toFixed(2) * n; // Removes useless precision, and convert to /100 to pass to the leaf
        leaves.push(<Leaf key={`leaf-${i}`} completion={currentLeafScore} />);
      }

      return leaves;
    }

    render() {
      let classes = 'green-score';
      if (this.props.loader) { classes += ' loading'; }

      return (
        <div className={classes}>
          {this.generateLeaves(5, this.state.completion)}
        </div>
      );
    }
}
