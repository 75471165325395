import { isCNAM } from '../../utils/functions';

export const LOCAL_STORAGE_USER_KEY = 'user/USER_TOKEN';
export const SET_TOKEN = 'user/SET_TOKEN';
export const SET_USER = 'user/SET_USER';
export const SET_IS_TRAVEL_MANAGER = 'user/SET_IS_TRAVEL_MANAGER';
export const FETCHING_CARDS = 'user/FETCHING_CARDS';
export const SET_CARDS = 'user/SET_CARDS';
export const SET_HANDICAP_TYPES = 'user/SET_HANDICAP_TYPES';
export const SET_WHEELCHAIR_TYPES = 'user/SET_WHEELCHAIR_TYPES';
export const FETCHING_AGE_CATEGORIES = 'user/FETCHING_AGE_CATEGORIES';
export const SET_AGE_CATEGORIES = 'user/SET_AGE_CATEGORIES';
export const FETCH_TRAVELERS_BEGIN = 'user/FETCH_TRAVELERS_BEGIN';
export const FETCH_TRAVELERS_ERROR = 'user/FETCH_TRAVELERS_ERROR';
export const FETCH_TRAVELERS_SUCCESS = 'user/FETCH_TRAVELERS_SUCCESS';
export const FETCH_COMPANIES_BEGIN = 'user/FETCH_COMPANIES_BEGIN';
export const FETCH_COMPANIES_ERROR = 'user/FETCH_COMPANIES_ERROR';
export const FETCH_COMPANIES_SUCCESS = 'user/FETCH_COMPANIES_SUCCESS';
export const CHANGE_COMPANY = 'user/CHANGE_COMPANY';
export const FETCH_CO2_STATS_SUCCESS = 'user/FETCH_CO2_STATS_SUCCESS';
export const FETCH_TREEPS_STATS_SUCCESS = 'user/FETCH_TREEPS_STATS_SUCCESS';
export const SET_MOBILE_DEVICE = 'app/SET_MOBILE_DEVICE';
export const SET_APPROVERS = 'user/SET_APPROVERS';
export const USER_NOT_LOGGED = 'user/USER_NOT_LOGGED';
export const SET_HAS_TRAVELERS_SEARCH = 'user/SET_HAS_TRAVELERS_SEARCH';
export const ACTIVATE_SANDBOX_MODE = 'user/ACTIVATE_SANDBOX_MODE';
export const SET_SANDBOX_PARAMS = 'user/SET_SANDBOX_PARAMS';
export const SET_LANG = 'user/SET_LANG';

// Routes

export const RESET_PASSWORD_TOKEN_PATH = '/password/:token';
export const ACTIVATE_USER_TOKEN_PATH = '/user/activate/:token';
export const TICKET_APPROVAL_PATH = '/ticket/approve/:token';
export const SEARCH_FORM_PATH = isCNAM() ? '/form' : '/';
