import * as yup from 'yup';
import { validationSchemaBirthdateOrHidden } from '../../utils/functions';

export function filterResults(filters, results) {
  return results.filter((res) => {
    // Filter out results out of price range
    const minNotSet = filters.prices.min === filters.priceRange.min;
    const maxNotSet = filters.prices.max === filters.priceRange.max;

    let isInPriceRange;
    if (minNotSet && maxNotSet) {
      isInPriceRange = true;
    } else {
      const price = res.best_price.total / 100;
      const { min, max } = filters.priceRange;
      const moreThanMin = minNotSet || price >= min;
      const lessThanMax = maxNotSet || price <= max;

      isInPriceRange = (moreThanMin && lessThanMax);
    }

    // Filter out results with excluded car types
    const { excludedCarTypes } = filters;
    let isIncludedCarType = true;
    if (excludedCarTypes.includes(res.category)) {
      isIncludedCarType = false;
    }

    // Filter out results with excluded renters
    const { excludedRenters } = filters;
    let isIncludedRenter = true;
    if ((res.renter && excludedRenters.includes(res.renter.name)) || (!res.renter && excludedRenters.includes('Autres'))) {
      isIncludedRenter = false;
    }

    // Filter out results without unlimitedKm
    const { unlimitedKm } = filters;
    let isUnlimited = true;
    if (unlimitedKm && !res.unlimited_distance) {
      isUnlimited = false;
    }

    // Filter out results without negociated fare
    const { negociated } = filters;
    let isNegociated = true;
    if (negociated && res.tags && (res.tags.indexOf('negociated-fare') === -1)) {
      isNegociated = false;
    }

    // Filter out results without loyalty enabled
    const { loyalty } = filters;
    let hasLoyalty = true;
    if (loyalty && res.tags && (res.tags.indexOf('loyalty-enabled') === -1)) {
      hasLoyalty = false;
    }

    return (isInPriceRange && isIncludedCarType && isIncludedRenter && isUnlimited && isNegociated && hasLoyalty);
  });
}

export function sortResults(results, sorting, sortedByPref) {
  if (sorting === 'BY_PRICE') {
    return results.sort((a, b) => a.best_price.total - b.best_price.total);
  }
  if (sorting === 'BY_DISTANCE') {
    return results.sort((a, b) => a.distance - b.distance);
  }
  if (sorting === 'BY_PREF') {
    return sortedByPref.map((id) => results.find((result) => result.id === id)).filter((item) => item !== undefined);
  }
}

export function driverValidationSchema() {
  return yup.object().shape({
    gender: yup.string().required(window.i18('PLEASE_FILL')),
    firstname: yup.string().required(window.i18('PLEASE_FILL')),
    lastname: yup.string().required(window.i18('PLEASE_FILL')),
    email: yup
      .string()
      .email()
      .required(window.i18('PLEASE_FILL')),
    phone: yup
      .string()
      .matches(
        /\b0[0-9]{9}\b|xxxxxxxx/,
        window.i18('PHONE_NUMBER_FORMAT'),
      )
      .required(window.i18('PLEASE_FILL')),
    birthdate: validationSchemaBirthdateOrHidden(),
    birth_country: yup.string().required(window.i18('PLEASE_FILL')),
    city_of_birth: yup.string().required(window.i18('PLEASE_FILL')),
    address: yup.object().shape({
      description: yup.string().required(window.i18('PLEASE_FILL')),
    }),
    license: yup.object().shape({
      country_of_issue: yup.string().required(window.i18('PLEASE_FILL')),
      effective_date: yup
        .date()
        .required(window.i18('PLEASE_FILL'))
        .min('1900-01-01', window.i18('NOT_THAT_OLD'))
        .max(
          new Date().toISOString().slice(0, 10),
          window.i18('TOO_YOUNG'),
        )
        .typeError(window.i18('ENTER_A_DATE')),
      number: yup.string().required(window.i18('PLEASE_FILL')),
    }).required(),
  });
}
