import React from 'react';
import { connect } from 'react-redux';

import addIcon from '../../../../images/icons/ui/add.svg';
import editIcon from '../../../../images/icons/ui/edit.svg';
import removeIcon from '../../../../images/icons/ui/dustbin.svg';

import CardForm from '../../../components/CardForm';

import { fetchUserCards, setUserCards } from '../actions';
import { openModal } from '../../ModalWrapper/actions';

class UserCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: null,
    };
    this.addCard = this.addCard.bind(this);
    this.editCard = this.editCard.bind(this);
    this.resetFocus = this.resetFocus.bind(this);
  }

  formatCardName = (c, i) => {
    if (c.card_name) {
      return c.card_name;
    }
    if (c.card_type) {
      return c.card_type.name;
    }
    return `Carte ${i + 1}`;
  };

  removeCard(card) {
    const props = {
      message: window.i18('SURE_DELETE_CARD'),
      confirm: () => this.props.setUserCards(card, 'remove'),
    };
    this.props.openConfirmModal(props);
  }

  resetFocus() {
    this.setState({
      focused: null,
    });
  }

  addCard() {
    this.setState({
      focused: 'new',
    });
  }

  editCard(card) {
    this.setState({
      focused: card,
    });
  }

  render() {
    const { cards, isMobileDevice } = this.props;

    if (!this.props.user) {
      return <span>{window.i18('LOADING')}</span>;
    }
    if (!this.state.focused) {
      return (
        <div className="common-wrapper documents">
          {isMobileDevice && (<h2>{window.i18('MY_CARDS')}</h2>)}

          {cards && cards.fetching && (
            <p className="document-loading">
              {window.i18('LOADING')}
              ...
            </p>
          )}
          {cards && cards.fetchingError && (
            <p className="document-error">
              {window.i18('ERROR_HAPPENED')}
              .
            </p>
          )}
          {cards
            && !cards.fetching
            && cards.list
            && cards.list.map((c, i) => (
              <div className="document-item" key={`card-${i}`}>
                <p className="document-item-name">{this.formatCardName(c, i)}</p>
                <p className="document-item-number">{c.cardnumber}</p>
                <button
                  className="document-item-edit"
                  onClick={() => this.editCard(c)}
                >
                  <img src={editIcon} alt="Modifier" />
                </button>
                <button
                  className="document-item-remove"
                  onClick={() => this.removeCard(c)}
                >
                  <img src={removeIcon} alt="Supprimer" />
                </button>
              </div>
            ))}
          <div className="documents-add">
            <button className="documents-add-button" onClick={this.addCard}>
              <i className="documents-add-icon">
                <img src={addIcon} alt="" />
              </i>
              {window.i18('ADD_CARD')}
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="common-wrapper documents document-edit">
        <CardForm
          card={this.state.focused}
          user={this.props.user}
          setCards={this.props.setUserCards}
          handleCancel={this.resetFocus}
          cardtypes={this.props.cardtypes}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user,
  cards: state.account.cards,
  cardtypes: state.app.cards,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserCards: () => dispatch(fetchUserCards()),
  setUserCards: (card, action) => dispatch(setUserCards(card, action)),
  openConfirmModal: (props) => dispatch(openModal('Confirm', props)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserCards);
